import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection8 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="theme-bg lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="w-full lg:py-10 md:py-10 py-4 flex flex-col justify-center items-center">
          <h2 id="7837996203" className="dynamicStyle hero_title">
            {data ? data['7837996203'] : 'Lorem Ipsum'}
          </h2>
          <ReactQuill
            id="8008669113"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle card_description"
            value={data ? data['8008669113'] : 'Lorem Ipsum'}
          />

          <div
            id="5386789530"
            ref={elementRef}
            className={`fade-bottom dynamicStyle2 lg:w-4/5 md:w-4/5 w-full lg:mt-12 md:mt-12 mt-6 ${
              isVisible ? 'visiblebottom' : ''
            } `}
          >
            <Image
              src={data ? getImage(data['5386789530']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              imageClassName="w-full h-300px sm-h250px object-cover rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection8;
