import React from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { useSelector } from "react-redux/es/exports";
import { Card } from "primereact/card";

const Jobs1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="zindex9 lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="prime_container">
        <div className="header_align">
          <h2 id="8166071664" className="dynamicStyle section_header">
            {data ? data["8166071664"] : "Lorem Ipsum"}
          </h2>
        </div>

        <div
          id="5277660246"
          className="lg:mt-12 md:mt-10 mt-8 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4 dynamicStyle"
        >
          {data && data["5277660246"]
            ? data["5277660246"].map((e) => {
                return (
                  <Card className="prime_card themeShadow theme-bg">
                    <div className="flex items-center gap-3">
                      <Image
                        src={process.env.REACT_APP_STORAGE_URL + e.field1}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="jobs"
                        imageClassName="lg:w-16 lg:h-16 md:w-16 md:h-16 w-12 h-12 rounded-lg"
                      />
                      <div>
                        <h4 className="card_title_0 lineClamp2">{e.field2}</h4>
                        <p className="lineClamp2 card_description">
                          {e.field3}
                        </p>
                      </div>
                    </div>
                    <div>
                      <h5 className="lineClamp2 card_title_0 text_surface800 mt-3">
                        {e.field4}
                      </h5>
                      <p className="fsize14 text-primary mt-1">{e.field5}</p>
                      <p className="fsize14 text_surface400 mt-1">{e.field6}</p>
                    </div>
                    <div className="lg:mt-4 md:mt-2 flex justify-between items-center">
                      <div className="flex items-center gap-1">
                        <h6 className="card_title_0 text_surface800 lineClamp2">
                          {e.field7}
                        </h6>
                        <p className="fsize13 text_surface400">{e.field8}</p>
                      </div>
                      <Button
                        className="theme-btn fsize14 text_surface400 px-6 py-2"
                        outlined
                      >
                        {e.field9}
                      </Button>
                    </div>
                  </Card>
                );
              })
            : "no-card"}
        </div>
      </div>
    </div>
  );
};

export default Jobs1;
