import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import Rating from "react-rating";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard30 = ({ pageName }) => {
  const [check, SetCheck] = useState(1);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h4 className="section_header dynamicStyle" id="3151485908">
            {data ? data["3151485908"] : "News Arrivals"}
          </h4>
          <ReactQuill
            id="1347020154"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["1347020154"] : "Lorem Ipsum"}
          />
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12">
          <div className="mx-auto lg:w-4/5 md:w-full">
            <Swiper
              spaceBetween={30}
              className="mySwiper"
              breakpoints={{
                1536: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                820: {
                  slidesPerView: 5,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                320: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
              }}
            >
              <SwiperSlide id={1} onClick={() => SetCheck(1)}>
                <p
                  className={
                    check === 1
                      ? "fsize14 bg-primary theme-btn text-white py-2 px-2 text-center dynamicStyle rounded-md"
                      : "theme-btn fsize14 bg_surface100 cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md"
                  }
                >
                  Men's Fashion
                </p>
              </SwiperSlide>
              <SwiperSlide id={2} onClick={() => SetCheck(2)}>
                <p
                  className={
                    check === 2
                      ? "theme-btn fsize14 bg-primary text-white py-2 px-2 text-center dynamicStyle rounded-md"
                      : "theme-btn fsize14 bg_surface100 cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md"
                  }
                >
                  Women's Fashion
                </p>
              </SwiperSlide>
              <SwiperSlide id={3} onClick={() => SetCheck(3)}>
                <p
                  className={
                    check === 3
                      ? "theme-btn fsize14 bg-primary text-white py-2 px-2 text-center dynamicStyle rounded-md"
                      : "theme-btn fsize14 bg_surface100 cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md"
                  }
                >
                  Women Accessories
                </p>
              </SwiperSlide>
              <SwiperSlide id={4} onClick={() => SetCheck(4)}>
                <p
                  className={
                    check === 4
                      ? "theme-btn fsize14 bg-primary text-white py-2 px-2 text-center dynamicStyle rounded-md"
                      : "theme-btn fsize14 bg_surface100 cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md"
                  }
                >
                  Men Accessories
                </p>
              </SwiperSlide>
              <SwiperSlide id={5} onClick={() => SetCheck(5)}>
                <p
                  className={
                    check === 5
                      ? "theme-btn fsize14 bg-primary text-white py-2 px-2 text-center dynamicStyle rounded-md"
                      : "theme-btn fsize14 bg_surface100 cust-textColor py-2 px-2 text-center dynamicStyle rounded-md"
                  }
                >
                  Discount Deals
                </p>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="mt-6">
            {check === 1 ? (
              <>
                <div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Long Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Full Sweater
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            White Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Colorful Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            White Shirt
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="w-full flex justify-center lg:mt-8 md:mt-8 mt-6">
                    <Button
                      fontWeight={400}
                      py={0}
                      id="9923062845"
                      className="dynamicStyle b_button_primary px-6 py-2 fsize14 theme-btn themeShadow"
                      borderRadius={4}
                    >
                      <span onClick={() => handleClick("9923062845")}>
                        {data ? data["9923062845"] : "View More"}
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {check === 2 ? (
              <>
                <div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="w-full flex justify-center lg:mt-8 md:mt-8 mt-6">
                    <Button
                      fontWeight={400}
                      py={0}
                      id="9923062845"
                      className="dynamicStyle b_button_primary px-6 py-2 fsize14 theme-btn themeShadow"
                      borderRadius={4}
                    >
                      <span onClick={() => handleClick("9923062845")}>
                        {data ? data["9923062845"] : "View More"}
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {check === 3 ? (
              <>
                <div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="w-full flex justify-center lg:mt-8 md:mt-8 mt-6">
                    <Button
                      fontWeight={400}
                      py={0}
                      id="9923062845"
                      className="dynamicStyle b_button_primary px-6 py-2 fsize14 theme-btn themeShadow"
                      borderRadius={4}
                    >
                      <span onClick={() => handleClick("9923062845")}>
                        {data ? data["9923062845"] : "View More"}
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {check === 4 ? (
              <>
                <div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="w-full flex justify-center lg:mt-8 md:mt-8 mt-6">
                    <Button
                      fontWeight={400}
                      py={0}
                      id="9923062845"
                      className="dynamicStyle b_button_primary px-6 py-2 fsize14 theme-btn themeShadow"
                      borderRadius={4}
                    >
                      <span onClick={() => handleClick("9923062845")}>
                        {data ? data["9923062845"] : "View More"}
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {check === 5 ? (
              <>
                <div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                    <Card className="w-full cust-shadow themeShadow theme-bg rounded-md p-3">
                      <div
                        id="9733816070"
                        className="dynamicStyle2 w-full rounded-md"
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="Img_Preview"
                          imageClassName="lg:h-56 md:h-52 h-52 w-full object-cover rounded-md"
                        />
                      </div>
                      <div className="mt-2">
                        <div className="flex justify-between">
                          <p className="fsize18 md-fsize16 sm-fsize16 font-medium text_surface800 lineClamp1">
                            Shiny Dress
                          </p>
                          <Rating
                            initialRating={5}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>
                        <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                          Al Karam
                        </p>
                        <p className="fsize14 md:fsize14 sm-fsize12  text_surface500 my-3">
                          (4.1k) Customer Reviews
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <p className="fsize14 md:fsize14 sm-fsize12 text_surface500">
                            $95.50
                          </p>

                          <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                            Almost Sold Out
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="w-full flex justify-center lg:mt-8 md:mt-8 mt-6">
                    <Button
                      fontWeight={400}
                      py={0}
                      id="9923062845"
                      className="dynamicStyle b_button_primary px-6 py-2 fsize14 theme-btn themeShadow"
                      borderRadius={4}
                    >
                      <span onClick={() => handleClick("9923062845")}>
                        {data ? data["9923062845"] : "View More"}
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard30;
