import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';

const AboutSection28 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-2/4 md:w-3/4 w-full mx-auto">
          <h2
            id="9610003350"
            className="dynamicStyle section_header text-center"
          >
            {data
              ? data['9610003350']
              : 'Take A Look At Our Awesome New Product'}
          </h2>
        </div>
        <div className="lg:flex md:flex lg:gap-10 md:gap-6 lg:mt-16 md:mt-16 mt-12">
          <div className="lg:w-1/3 md:w-1/3 w-full lg:pt-20 md:pt-6 lg:px-10">
            <ReactQuill
              id="3550472734"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['3550472734'] : 'Lorem Ipsum'}
            />
          </div>
          <div className="lg:w-1/3 md:w-1/3 w-full">
            <div
              id="5208642586"
              className="dynamicStyle2 h-600px md-h-450px sm-h-310px lg:my-0 md:my-0 my-6 rounded-4xl sm-rounded-3xl"
            >
              <Image
                imageClassName="h-600px md-h-450px sm-h-310px w-full sm-w-70 mx-auto rounded-4xl sm-rounded-3xl object-cover"
                src={data ? getImage(data['5208642586']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="card-image"
              />
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/3 w-full lg:pt-20 md:pt-6 lg:px-10">
            <div className="lg:pb-10 pb-8">
              <p
                id="2387236931"
                className="dynamicStyle font-semibold card_title"
              >
                {data ? data['2387236931'] : 'Lorem Ipsum'}
              </p>
              <ReactQuill
                id="1457323098"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['1457323098'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="lg:pb-10 pb-8">
              <p
                id="4285797570"
                className="dynamicStyle font-semibold card_title"
              >
                {data ? data['4285797570'] : 'Lorem Ipsum'}
              </p>
              <ReactQuill
                id="1216332022"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['1216332022'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="">
              <p
                id="1287398886"
                className="dynamicStyle font-semibold card_title"
              >
                {data ? data['1287398886'] : 'Lorem Ipsum'}
              </p>
              <ReactQuill
                id="7460703022"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['7460703022'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection28;
