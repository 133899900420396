import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const HeroSection28 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end

  return (
    <section className="relative inline-block h-450px sm-h300px w-full img-container" style={{ background: thisComponent.sectionColor }}>
      <div id="3000243784" className="dynamicStyle2">
        <Image
          src={data ? getImage(data["3000243784"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="relative h-450px sm-h300px w-full object-cover"
          alt="hero-section"
        />
      </div>
      <div className="absolute z-10 top-0 left-0 flex w-full items-center h-450px sm-h300px">
        <div className="prime_container">
          <div className="lg:w-1/2 md:w-8/12 w-full lg:pl-20 md:pl-20 pl-10 pr-4">
            <h4 id="1192846446" className="dynamicStyle hero_title clr-fff">
              {data
                ? data["1192846446"]
                : "Lorem ipsum dolor sit amet consectetur."}
            </h4>
            <ReactQuill
              id="1083269149"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["1083269149"] : "Lorem Ipsum"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection28;
