import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';

const SubscriptionPlan6 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <div 
    className={
      colorMode === 'light'
        ? 'bg-dark theme-bg'
        : 'bg-dark theme-bg'
    }
    >
      <div className="container mx-auto lg:py-16 lg:px-12 md:py-12 md:px-12 px-5 py-10">
        <div className="lg:w-8/12 md:w-2/3 w-full mx-auto text-center lg:mb-10 md:mb-12 mb-8">
          <h2
            id="8945804630"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize24 font-semibold text-primary cust-textColor"
          >
            {data ? data['8945804630'] : 'Flagship Programs'}
          </h2>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-4">
          <div className="subscription-radius themeShadow lg:p-10 md:p-8 p-4">
            <p className="sub6-hover cust-textColor text-center fsize16 md-fsize14 sm-fsize14 p-1 uppercase">
              Start
            </p>
            <div className="flex justify-center items-center gap-2 mt-4 lg:mb-3 md:mb-3 mb-4 lg:px-0 md:px-0 px-4">
              <div className="w-2/12 flex justify-end">
                <p className="fsize16 md-fsize14 sm-fsize14 sub6-hover">₹</p>
              </div>
              <h2 className="sub6-hover cust-textColor w-6/12 text-center lg:text-6xl md:text-4xl text-3xl font-semibold">
                1200
              </h2>
              <p className="sub6-hover w-4/12 fsize14 sm-fsize13">
                per user per month
              </p>
            </div>
            <p className="sub6-hover textColor-light text-center fsize16 md-fsize14 sm-fsize14 lg:my-6 md:my-5 my-5">
              All the features you need to keep your personal files safe,
              accessible, and easy to share.
            </p>
            <div className="lg:mt-6 md:mt-6 mt-4">
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
            </div>
            <Button
              id="8308402684"
              borderRadius="20"
              colorScheme="primary"
              outlined
              className={
                selectedStyle
                  ? 'theme-btn2 px-6 py-3 fsize16 md-fsize14 sm-fsize14 sm-fsize14 w-full lg:mt-8 md:mt-2 mt-3 dynamicStyle subtn-hover'
                  : 'theme-btn2 px-6 py-3 fsize16 md-fsize14 sm-fsize14 sm-fsize14  w-full lg:mt-8 md:mt-2 mt-3 dynamicStyle subtn-hover'
              }
            >
              <span onClick={() => handleClick('8308402684')}>
                {data ? data['8308402684'] : 'Flagship Programs'}
              </span>
            </Button>
          </div>
          <div className="subscription-radius themeShadow lg:p-10 md:p-8 p-4">
            <p className="sub6-hover cust-textColor text-center fsize16 md-fsize14 sm-fsize14 p-1 uppercase">
              Start
            </p>
            <div className="flex justify-center items-center gap-2 mt-4 lg:mb-3 md:mb-3 mb-4 lg:px-0 md:px-0 px-4">
              <div className="w-2/12 flex justify-end">
                <p className="fsize16 md-fsize14 sm-fsize14 sub6-hover">₹</p>
              </div>
              <h2 className="sub6-hover cust-textColor w-6/12 text-center lg:text-6xl md:text-4xl text-3xl font-semibold">
                1200
              </h2>
              <p className="sub6-hover w-4/12 fsize14 sm-fsize13">
                per user per month
              </p>
            </div>
            <p className="sub6-hover textColor-light text-center fsize16 md-fsize14 sm-fsize14 lg:my-6 md:my-5 my-5">
              All the features you need to keep your personal files safe,
              accessible, and easy to share.
            </p>
            <div className="lg:mt-6 md:mt-6 mt-4">
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
            </div>
            <Button
              id="3957245316"
              outlined
              className={
                selectedStyle
                  ? 'theme-btn2 px-6 py-3 fsize16 md-fsize14 sm-fsize14 sm-fsize14 w-full lg:mt-8 md:mt-2 mt-3 dynamicStyle subtn-hover'
                  : 'theme-btn2 px-6 py-3 fsize16 md-fsize14 sm-fsize14 sm-fsize14  w-full lg:mt-8 md:mt-2 mt-3 dynamicStyle subtn-hover'
              }
            >
              <span onClick={() => handleClick('3957245316')}>
                {data ? data['3957245316'] : 'Flagship Programs'}
              </span>
            </Button>
          </div>
          <div className="subscription-radius themeShadow lg:p-10 md:p-8 p-4">
            <p className="sub6-hover cust-textColor text-center fsize16 md-fsize14 sm-fsize14 p-1 uppercase">
              Start
            </p>
            <div className="flex justify-center items-center gap-2 mt-4 lg:mb-3 md:mb-3 mb-4 lg:px-0 md:px-0 px-4">
              <div className="w-2/12 flex justify-end">
                <p className="fsize16 md-fsize14 sm-fsize14 sub6-hover">₹</p>
              </div>
              <h2 className="sub6-hover cust-textColor w-6/12 text-center lg:text-6xl md:text-4xl text-3xl font-semibold">
                1200
              </h2>
              <p className="sub6-hover w-4/12 fsize14 sm-fsize13">
                per user per month
              </p>
            </div>
            <p className="sub6-hover textColor-light text-center fsize16 md-fsize14 sm-fsize14 lg:my-6 md:my-5 my-5">
              All the features you need to keep your personal files safe,
              accessible, and easy to share.
            </p>
            <div className="lg:mt-6 md:mt-6 mt-4">
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
              <div className="mb-4 flex justify-center items-center gap-2 ">
                <div className="">
                  <FeatherIcon
                    size={18}
                    icon="check"
                    className="cursor-pointer cust-textColor sub6-hover"
                  />
                </div>
                <div className="">
                  <p className="sub6-hover fsize16 md-fsize14 sm-fsize14 cust-textColor">
                    2 GB of hosting space
                  </p>
                </div>
              </div>
            </div>
            <Button
              id="1649171104"
              outlined
              className={
                selectedStyle
                  ? 'theme-btn2 px-6 py-3 fsize16 md-fsize14 sm-fsize14 sm-fsize14 w-full lg:mt-8 md:mt-2 mt-3 dynamicStyle subtn-hover'
                  : 'theme-btn2 px-6 py-3 fsize16 md-fsize14 sm-fsize14 sm-fsize14  w-full lg:mt-8 md:mt-2 mt-3 dynamicStyle subtn-hover'
              }
            >
              <span onClick={() => handleClick('1649171104')}>
                {data ? data['1649171104'] : 'Flagship Programs'}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan6;
