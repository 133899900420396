import { Button } from "primereact/button";
import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useFormik } from "formik";
import * as Yup from "yup";

import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import {
  Input,
  InputGroup,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const NewsletterSection = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <section className="relative">
      <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
        <div className="prime_container relative zindex9">
          <form
            onSubmit={formik.handleSubmit}
            className="bggradient-mid   lg:rounded-lg md:rounded-lg rounded-md"
          >
            <div className="w-full lg:py-24 md:py-16 py-8 lg:px-0 md:px-4 px-6 text-center">
              <h2
                id="4813626074"
                className="dynamicStyle font-semibold clr-fff section_header uppercase"
              >
                {data ? data["4813626074"] : "lorem Ipsum"}
              </h2>
              <ReactQuill
                id="5746952860"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle lg:mt-0 md:mt-0 mt-2"
                value={data ? data["5746952860"] : "Lorem Ipsum"}
              />
              <div className="lg:w-1/2 lg:mx-auto flex justify-center lg:mt-6 md:mt-6 mt-4 md:mx-20 mx-auto">
                <FormControl
                  isInvalid={!!formik.errors.email && formik.touched.email}
                >
                  <InputGroup className="">
                    <Input
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      className="w-4/5 backdrop-filer  h45px fsize14 sm-fsize12  px-4 py-3 rounded-left-lg "
                      placeholder="enter your email ID"
                    />

                    <Button
                      className="dynamicStyle f-normal uppercase h45px sm-fsize12  b_button_primary px-8 py-2 rounded-right-lg"
                      type="submit"
                      id="5111146348"
                    >
                      {data ? data["5111146348"] : "subscribe"}
                    </Button>
                  </InputGroup>
                  {formik.touched.email && formik.errors.email && (
                    <FormErrorMessage className="textwhite">
                      {formik.errors.email}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
