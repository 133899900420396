import React, { useEffect, useRef, useState } from "react";
import { ArrowRight } from "react-feather";
import { Button } from "primereact/button";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";

const GridCard3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const navigate = useNavigate();
  const link = "https://example.com";

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end
   
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <p id="5569353772" className="dynamicStyle font-semibold text-primary">
          {data ? data["5569353772"] : "Lorem Ipsum"}
        </p>
        <p id="1417110583" className="dynamicStyle section_header">
          {data ? data["1417110583"] : "Lorem Ipsum generated"}
        </p>
        <div className="lg:mt-12 md:mt-10 mt-8 w-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4">
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="card"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14">Enquire</p>
              <ArrowRight className="text-primary ml-2 sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="card"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14">Enquire</p>
              <ArrowRight className="text-primary ml-2 sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="card"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14">Enquire</p>
              <ArrowRight className="text-primary ml-2 sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="card"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14">Enquire</p>
              <ArrowRight className="text-primary ml-2 sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="card"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14">Enquire</p>
              <ArrowRight className="text-primary ml-2 sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="card"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14">Enquire</p>
              <ArrowRight className="text-primary ml-2 sm-arrow" />
            </div>
          </div>
        </div>
        <div className="text-center lg:mt-12 md:mt-10 mt-6">
          <Button
            id="0391086893"
            // className="dynamicStyle sm-fsize14 lg:py-6 sm-py-6"
            className="dynamicStyle b_button_primary px-6 py-2 fsize14 sm-fsize12 btn-mdsize theme-btn2"
          >
            <span onClick={() => handleClick("0391086893")}>
              {data ? data["0391086893"] : "Buy Now"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GridCard3;
