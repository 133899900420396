import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const AboutSection22 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const [check, setcheck] = useState(1);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="w-full flex items-center justify-center text-center theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:px-12 md:px-10 px-5 lg:py-20 md:py-10 py-10 ">
        <div
          ref={elementRef}
          className={`fade-bottom border-about1 border-about2 lg:py-16 md:py-10 py-10 relative ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <div className="flex items-center justify-center">
            <div className="grid grid-cols-2 rounded-full overflow-hidden theme-btn2">
              <div
                // className="px-5 py-3 bg-primary text-dark"
                className={
                  check === 1
                    ? 'px-5 py-3 bg-primary text-dark'
                    : 'px-5 py-3 bg-grays text-dark'
                }
                onClick={() => setcheck(1)}
              >
                <p
                  className="fsize14 sm-fsize13 dynamicStyle theme-textColor"
                  id="9797232488"
                >
                  {data ? data['9797232488'] : 'EDUCATE'}
                </p>
              </div>
              <div
                className={
                  check === 2
                    ? 'px-5 py-3 bg-primary text-dark'
                    : 'px-5 py-3 bg-grays text-dark'
                }
                onClick={() => setcheck(2)}
              >
                <p
                  className="fsize14 sm-fsize13 dynamicStyle theme-textColor"
                  id="2694721714"
                >
                  {data ? data['2694721714'] : 'EDUCATE'}
                </p>
              </div>
            </div>
          </div>
          {check === 1 ? (
            <>
              <div className="lg:mt-10 md:mt-8 mt-5">
                <ReactQuill
                  id="6456519630"
                  theme="bubble"
                  readOnly
                  className="lg:w-8/12 mx-auto px-4 bubble2 card_description dynamicStyle"
                  value={data ? data['6456519630'] : 'Lorem Ipsum'}
                />
              </div>
            </>
          ) : null}
          {check === 2 ? (
            <>
              <div className="lg:mt-10 md:mt-8 mt-5">
                <ReactQuill
                  id="3352536786"
                  theme="bubble"
                  readOnly
                  className="lg:w-8/12 mx-auto px-4 bubble2 card_description dynamicStyle"
                  value={data ? data['3352536786'] : 'Lorem Ipsum'}
                />
              </div>
            </>
          ) : null}
          <div className="border-line"></div>
          <div className="border-line2"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection22;
