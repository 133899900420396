import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard19 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h3 id="3380275699" className="dynamicStyle section_header">
              {data ? data['3380275699'] : 'Lorem ipsum dolor sit'}
            </h3>
          </div>
          <div
            id="3616494697"
            className="dynamicStyle prime_card_container grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12"
          >
            {data && data['3616494697']
              ? data['3616494697'].map((e, index) => {
                  return (
                    <Card className="prime_card">
                      <p className="card_title">{e.field1}</p>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field2}
                      />
                    </Card>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard19;
