import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard47 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <p id="7487873761" className="dynamicStyle section_header">
            {data ? data['7487873761'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="6187404087"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle  section_detail"
            value={data ? data['6187404087'] : 'Welcome to My World'}
          />
        </div>
        <div
          id="7607869666"
          className="dynamicStyle lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4"
        >
          {data && data['7607869666']
            ? data['7607869666'].map(e => {
                return (
                  <Card className="relative prime_card_0" key={e._id}>
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL + e.field1}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="img"
                      imageClassName="h-64 w-full rounded-t-lg object-cover"
                    />
                    <div className="absolute top-4 left-4 bg-black rounded-md py-1 px-2 cust-bgColor">
                      <p className="clr-fff fsize14 sm-fsize12 cust-textColor">
                        07 : 00 PM
                      </p>
                    </div>
                    <div className="rounded-b-lg p-4 themeShadow">
                      <p className="card_title">{e.field2}</p>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field3}
                      />
                      <p className="pt-2 fsize14 sm-fsize12 text-gray textColor-light">
                        2 hours 40 minutes
                      </p>
                      <div className="flex justify-between items-center mt-4">
                        <div className="flex gap-2">
                          <FeatherIcon icon="star" size={14} />
                          <FeatherIcon icon="star" size={14} />
                          <FeatherIcon icon="star" size={14} />
                          <FeatherIcon icon="star" size={14} />
                          <FeatherIcon icon="star" size={14} />
                        </div>
                        <FeatherIcon icon="bookmark" size={22} />
                      </div>
                    </div>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard47;
