import { Card, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';

const EcomProductReview2 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <div className="container mx-auto gap-8 lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="flex items-center gap-2">
        <h2
          id="8022146145"
          className="dynamicStyle fsize30 sm-fsize24 font-semibold"
        >
          {data ? data['8022146145'] : 'Customer Reviews'}
        </h2>
        <p className="fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor">4.5K</p>
      </div>
      <div className="mt-6 flex flex-col gap-y-3">
        <Card>
          <div
          className={
            colorMode === 'light'
              ? 'w-full lg:flex md:flex items-center justify-between lg:p-6 md:p-6 p-4 bg-f4f5f6 md:gap-8 themeShadow theme-bg themeShadow-insert'
              : 'w-full lg:flex md:flex items-center justify-between lg:p-6 md:p-6 p-4  md:gap-8 themeShadow theme-bg'
          }>
            <div className="w-20per sm-w-100 text-center ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-16 h-16 rounded-full mx-auto"
              />
              <div className="mt-2 text-center">
                <h3 className="fsize16 font-medium cust-textColor">Lorem Ipsum</h3>
                <div className="mt-1 flex justify-center">
                  <FeatherIcon icon="star" size={14} className="textColor-light" />
                </div>
              </div>
            </div>
            <div className="w-80 sm-w-100 lg:mt-0 md:mt-0 mt-4">
              <p className="fsize14 lg:mr-20  lg:text-left textColor-light md:text-left text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                ratione! Lorem ipsum dolor sit, amet consectetur adipisicing
                elit. Incidunt neque, perferendis explicabo tempora dolores
                earum delectus unde voluptatem repellendus! Ducimus, sit fugit?
                Voluptas, ipsam? Fuga ad laboriosam vero voluptate earum!
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className={
            colorMode === 'light'
              ? 'w-full lg:flex md:flex items-center justify-between lg:p-6 md:p-6 p-4 bg-f4f5f6 md:gap-8 themeShadow theme-bg themeShadow-insert'
              : 'w-full lg:flex md:flex items-center justify-between lg:p-6 md:p-6 p-4  md:gap-8 themeShadow theme-bg'
          }>
            <div className="w-20per sm-w-100 text-center ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-16 h-16 rounded-full mx-auto"
              />
              <div className="mt-2 text-center">
                <h3 className="fsize16 font-medium cust-textColor">Lorem Ipsum</h3>
                <div className="mt-1 flex justify-center">
                  <FeatherIcon icon="star" className="mr-1 textColor-light" size={14} />
                  <FeatherIcon icon="star" size={14} className="textColor-light" />
                </div>
              </div>
            </div>
            <div className="w-80 sm-w-100 lg:mt-0 md:mt-0 mt-4">
              <p className="fsize14 lg:mr-20  lg:text-left textColor-light md:text-left text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                ratione! Lorem ipsum dolor sit, amet consectetur adipisicing
                elit. Incidunt neque, perferendis explicabo tempora dolores
                earum delectus unde voluptatem repellendus! Ducimus, sit fugit?
                Voluptas, ipsam? Fuga ad laboriosam vero voluptate earum!
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className={
            colorMode === 'light'
              ? 'w-full lg:flex md:flex items-center justify-between lg:p-6 md:p-6 p-4 bg-f4f5f6 md:gap-8 themeShadow theme-bg themeShadow-insert'
              : 'w-full lg:flex md:flex items-center justify-between lg:p-6 md:p-6 p-4 md:gap-8 themeShadow theme-bg'
          }>
            <div className="w-20per sm-w-100 text-center ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="w-16 h-16 rounded-full mx-auto"
              />
              <div className="mt-2 text-center">
                <h3 className="fsize16 font-medium cust-textColor">Lorem Ipsum</h3>
                <div className="mt-1 flex justify-center">
                  <FeatherIcon icon="star" className="mr-1 textColor-light" size={14} />
                  <FeatherIcon icon="star" className="mr-1 textColor-light" size={14} />
                  <FeatherIcon icon="star" size={14} className="textColor-light" />
                </div>
              </div>
            </div>
            <div className="w-80 sm-w-100 lg:mt-0 md:mt-0 mt-4">
              <p className="fsize14 lg:mr-20  lg:text-left textColor-light md:text-left text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                ratione! Lorem ipsum dolor sit, amet consectetur adipisicing
                elit. Incidunt neque, perferendis explicabo tempora dolores
                earum delectus unde voluptatem repellendus! Ducimus, sit fugit?
                Voluptas, ipsam? Fuga ad laboriosam vero voluptate earum!
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EcomProductReview2;
