import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVICE_BASE_URL;

const API = axios.create({
  baseURL,
  headers: {
    'community-name': process.env.REACT_APP_COMMUNITY_NAME,
    accept: 'application/json',
  },
});


API.interceptors.request.use(
  async config => {
    let token = '';

    const cmsToken = localStorage.getItem('cms-session');

    // console.log( await encodeURIComponent(cmsToken),"token")

    if (cmsToken) {
      config.headers['Cms-session'] = `Bearer ${cmsToken}`;
      config.headers['X-Base64-Encoded'] = true;
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  error => Promise.reject(error)
);


export default API;
