import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";


const FeatureCard49 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <p id="8430148878" className="dynamicStyle section_header">
            {data ? data['8430148878'] : 'Lorem ipsum'}
          </p>
          <div className="">
            <ReactQuill
              id="0223330906"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['0223330906'] : 'Lorem Ipsum'}
            />
          </div>
        </div>
        <div
          id="7475851224"
          className="dynamicStyle prime_card_container grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12"
        >
          {data && data['7475851224']
            ? data['7475851224'].map(e => {
                return (
                  <Card key={e._id} className="prime_card themeShadow">
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL + e.field1}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt={e.field3}
                      imageClassName="h-56 w-full rounded-xl mb-2 object-cover"
                    />
                    <div>
                      <div className="py-1 flex justify-between items-center">
                        <div className="flex items-center gap-2 w-4/5">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + e.field2}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                            imageClassName="h-14 w-14 rounded-full object-contain"
                          />
                          <div>
                            <p className="card_title_0">{e.field3}</p>
                            <p className="card_description">{e.field4}</p>
                          </div>
                        </div>
                        <div>
                          <FeatherIcon
                            icon="more-horizontal"
                            size={24}
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard49;
