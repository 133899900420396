import React from "react";
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const StepGuide5 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <h2 className="section_header dynamicStyle" id="8950773499">
            {data ? data["8950773499"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="7847144757"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["7847144757"] : "Lorem Ipsum"}
          />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8">
          <div
            className="w-full dynamicStyle lg:flex md:flex gap-6 "
            id="4344543980"
          >
            {data && data["4344543980"]
              ? data["4344543980"].map((e, index) => {
                  return (
                    <div className="mx-auto text-center relative lg:w-1/3 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-6">
                      <div className="">
                        <div className="flex relative items-end theme-bg themeShadow justify-center mx-auto lg:w-28 lg:h-28 md:w-24 md:h-24 w-24 h-24 bg-ec rounded-full">
                          <p className="fsize13 text_surface400 pb-4">
                            {e.field2}
                          </p>
                          <div className="absolute top-0 right-0">
                            <Image
                              src={e ? getImage(e.field1) : ""}
                              alt="user"
                              imageClassName="w-10 h-10 rounded-full"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 mx-auto lg:w-10/12">
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble2 card_description"
                          value={e.field3}
                        />
                      </div>
                      {index !== 2 ? (
                        <>
                          <div className="absolute top-0 right-0 step5-mrminus lg:block md:block hidden">
                            <div className="bord-dashed"></div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })
              : "no-card"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepGuide5;
