import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard8 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h3
              id="2312621399"
              className="dynamicStyle fsize16 sm-fsize14 font-medium text-primary cust-textColor"
            >
              {data ? data['2312621399'] : 'Lorem Ipsum'}
            </h3>
            <h2
              id="7594297016"
              className="dynamicStyle section_header cust-textColor"
            >
              {data ? data['7594297016'] : 'Lorem Ipsum'}
            </h2>
          </div>
          <div id="1200385485" className="dynamicStyle">
            <div
              ref={elementRef}
              className={`fade-bottom lg:mt-16 md:mt-16 mt-12 prime_card_container grid lg:grid-cols-3 md:grid-cols-2 gap-4 ${
                isVisible ? 'visiblebottom' : ''
              } `}
            >
              {data && data['1200385485']
                ? data['1200385485'].map(e => {
                    return (
                      <Card className="theme_insertShadow theme-bg prime_card">
                        <div className="w-full flex justify-left">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            alt={e.field2}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            imageClassName="object-contain card-img mb-4"
                          />
                        </div>
                        <div className="text-left w-full">
                          <h2 className="card_title_0 cust-textColor">
                            {e.field2}
                          </h2>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble dynamicStyle card_description mt-1"
                            value={e.field3}
                          />
                        </div>
                      </Card>
                    );
                  })
                : 'no-cards'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard8;
