import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const AboutSection13 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:flex md:flex md:gap-6 prime_container">
        <div
          ref={elementRef}
          className={`fade-left lg:w-1/2 md:w-1/2 ${
            isVisible ? 'visibleleft' : ''
          } `}
        >
          <div className=" grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4">
            <div className="dynamicStyle2 w-full" id="7717096584">
              <Image
                src={data ? getImage(data['7717096584']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                imageClassName="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px w-full"
              />
            </div>
            <div className="dynamicStyle2 w-full" id="2462052310">
              <Image
                src={data ? getImage(data['2462052310']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                imageClassName="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px w-full"
              />
            </div>
            <div className="dynamicStyle2 w-full" id="5435108415">
              <Image
                src={data ? getImage(data['5435108415']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                imageClassName="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px w-full"
              />
            </div>
            <div className="dynamicStyle2 w-full" id="9306518570">
              <Image
                src={data ? getImage(data['9306518570']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="banner-image"
                imageClassName="bg-primary object-cover br-16px h-200px md-h-150px sm-h-150px w-full"
              />
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 sm-py-6 ">
          <div className="flex flex-col justify-center h-auto">
            <div className="lg:px-12 h-48">
              <h2
                id="9320302655"
                className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary font-medium pb-1"
              >
                {data ? data['9320302655'] : 'Apple'}
              </h2>
              <h3 id="6960265695" className="dynamicStyle about_title">
                {data ? data['6960265695'] : 'Lorem ipsum dolor sit amet.'}
              </h3>
              <ReactQuill
                id="2168995819"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data['2168995819'] : 'Lorem Ipsum'}
              />

              <Button
                id="1264488637"
                className="dynamicStyle theme-btn b_button_primary px-6 py-2 font-semibold sm-fsize14 lg:mt-8 md:mt-8 mt-6"
              >
                <span onClick={() => handleClick('1264488637')}>
                  {data ? data['1264488637'] : 'Buy Now'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection13;
