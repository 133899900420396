import { Card } from "primereact/card";
import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const CardImage6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}> 
      <div className="prime_container">
        <div className="header_align">
          <p
            id="9626872199"
            className="dynamicStyle text-primary fsize16 sm-fsize14"
          >
            {data ? data["9626872199"] : "Lorem Ipsum"}
          </p>
          <h2 id="1087985855" className="dynamicStyle section_header">
            {data ? data["1087985855"] : "Lorem Ipsum"}
          </h2>
        </div>
        <div
          ref={elementRef}
          className={`lg:mt-16 md:mt-12 mt-8 fade-bottom grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-8 gap-8 w-full ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <Card className="bg_none shadow-none">
            <div id="9817888732" className="dynamicStyle2 w-full mb-2">
              <Image
                src={data ? getImage(data["9817888732"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="card-image"
                imageClassName="h-20 w-20 mx-auto object-cover lg:mb-3 md:mb-2 mb-2"
              />
            </div>
            <div className="w-full">
              <h2
                id="4356728801"
                className="dynamicStyle card_title text-center"
              >
                {data ? data["4356728801"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="1969993537"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle card_description"
                value={data ? data["1969993537"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="bg_none shadow-none">
            <div id="7718335135" className="dynamicStyle2 w-full mb-2">
              <Image
                src={data ? getImage(data["7718335135"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="card-image"
                imageClassName="h-20 w-20 mx-auto object-cover lg:mb-3 md:mb-2 mb-2"
              />
            </div>
            <div className="w-full">
              <h2
                id="5182106385"
                className="dynamicStyle card_title text-center"
              >
                {data ? data["5182106385"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="2904428767"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle card_description"
                value={data ? data["2904428767"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="bg_none shadow-none">
            <div id="4953077910" className="dynamicStyle2 w-full mb-2">
              <Image
                src={data ? getImage(data["4953077910"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="card-image"
                imageClassName="h-20 w-20 mx-auto object-cover lg:mb-3 md:mb-2 mb-2"
              />
            </div>
            <div className="w-full">
              <h2
                id="5317807836"
                className="dynamicStyle card_title text-center"
              >
                {data ? data["5317807836"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="5675908545"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle card_description"
                value={data ? data["5675908545"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="bg_none shadow-none">
            <div id="5048100995" className="dynamicStyle2 w-full mb-2">
              <Image
                src={data ? getImage(data["5048100995"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="card-image"
                imageClassName="h-20 w-20 mx-auto object-cover lg:mb-3 md:mb-2 mb-2"
              />
            </div>
            <div className="w-full">
              <h2
                id="6301805196"
                className="dynamicStyle card_title text-center"
              >
                {data ? data["6301805196"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="2494036995"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle card_description"
                value={data ? data["2494036995"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CardImage6;
