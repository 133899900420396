import React, { useEffect, useRef, useState } from "react";
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from "react-redux/es/exports";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Banner = ({ pageName }) => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const elementRef = useRef(null);
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <div className="relative">
      <img
        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        alt="home-banner"
        className="h-250px filter-b4 w-full"
      />
      <div className="w-full  absolute top-0 left-0 h-250px flex justify-start items-center">
        <div className="container mx-auto lg:px-12 md:px-8 px-6 flex justify-between items-center">
          <div className="text-start mb-8">
            <h3
              id="4935077773"
              className="dynamicStyle fsize36 sm-fsize21 font-semibold text-white"
            >
              {data ? data["4935077773"] : "Help & Support"}
            </h3>
            <ReactQuill
                id="7694513590"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2"
                value={data ? data['7694513590'] : 'Lorem Ipsum'}
              />
          </div>
          <div className="text-start mb-8">
            <Button
              className="dynamicStyle theme-btn b_button_primary px-6 py-2 rounded-full sm-fsize14 buttonAnimate bdr-2px"
              id="3809973485"
            >
              <span onClick={() => handleClick("3809973485")}>
                {data ? data["3809973485"] : "Button"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
