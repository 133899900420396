import { Image } from "primereact/image";
import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { FaFacebookSquare, FaLinkedin, FaTwitter } from "react-icons/fa";
import { useSelector } from "react-redux";

const Team22 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <>
      <div
        className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
        style={{ background: thisComponent.sectionColor }}
      >
        <div className="prime_container">
          <div className="lg:w-2/3 md:w-2/3 w-full mx-auto text-center">
            <p id="9719369160" className="dynamicStyle section_detail_0 mb-2">
              {data ? data["9719369160"] : "Our Team"}
            </p>
            <h2
              id="1895358104"
              dangerouslySetInnerHTML={{
                __html: data ? data["1895358104"] : "Meet the Team",
              }}
              className="dynamicStyle section_header"
            ></h2>
          </div>
          <div
            id="9566487057"
            className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-14 md:mt-12 mt-10 lg:gap-6 md:gap-8 gap-6"
          >
            {data && data["9566487057"]
              ? data["9566487057"].map((e, index) => {
                  return (
                    <Card className="relative bg_none hadow-none shadow-none lg:mx-2 md:mx-2">
                      <div className="theme-border-0 rounded-full lg:p-6 md:p-6 px-6 p-4 main_div themeShadow themeShadow-insert">
                        <div className="flex">
                          <div className="rounded-full overflow-hidden border-4px-fff mr-4 bg-white">
                            <Image
                              src={e ? getImage(e.field1) : ""}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt={e.field2}
                              imageClassName="lg:w-20 md:w-20 w-20 lg:h-20 md:h-20 h-20 object-contain rounded-full"
                            />
                          </div>
                          <div className="flex flex-col justify-between">
                            <div className="">
                              <h4 className="card_title_0 text-dark">
                                {e.field2}
                              </h4>
                              <p className="card_description text-gray">
                                {e.field3}
                              </p>
                            </div>
                            <div className="flex gap-4">
                              <FaTwitter color="white" />
                              <FaFacebookSquare color="white" />
                              <FaLinkedin color="white" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })
              : "no-card"}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team22;
