import React, { useEffect, useState } from 'react';
import { FaAward } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup"; //Optional for grouping
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const AboutSection54 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex gap-6 items-center">
          <div className="lg:w-1/2 md:w-1/2 lg:pr-10 md:pr-6 lg:pb-0 md:pb-0 pb-6">
            <h2 id="0599487512" className="dynamicStyle about_title">
              {data ? data["0599487512"] : "Lorem ipsum"}
            </h2>
            <ReactQuill
              id="2600515495"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["2600515495"] : "Lorem Ipsum"}
            />
            <Button
              className="dynamicStyle b_button_primary px-6 py-2 mt-6 theme-btn sm-fsize14"
              id="7539762984"
            >
              <span onClick={() => handleClick("7539762984")}>
                {data ? data["7539762984"] : "Lorem Ipsum"}
              </span>
            </Button>
          </div>
          <div className="lg:w-1/2 md:w-1/2 relative">
            <div
              id="1773602627"
              className="dynamicStyle2 about54-img mx-auto rounded-3xl my-8"
            >
              <Image
                src={data ? getImage(data["1773602627"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-about"
                imageClassName="about54-img rounded-3xl object-cover"
              />
            </div>
            <div className="absl-about54right lg:w-2/5 md:w-1/2 w-3/5">
              <Card className="prime_card pad-10px themeShadow theme-bg">
                <AvatarGroup>
                  <Avatar
                    label="img"
                    shape="circle"
                    image="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  />
                  <Avatar
                    label="img"
                    shape="circle"
                    image="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  />
                  <Avatar
                    label="img"
                    shape="circle"
                    image="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  />
                  <Avatar
                    label="img"
                    shape="circle"
                    image="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  />
                  <Avatar
                    label="img"
                    shape="circle"
                    image="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  />
                </AvatarGroup>
                <ReactQuill
                  id="6968498324"
                  theme="bubble"
                  readOnly
                  className="card_description pt-2 bubble dynamicStyle"
                  value={data ? data["6968498324"] : "Lorem Ipsum"}
                />
              </Card>
            </div>
            <div className="lg:w-2/5 md:w-1/2 w-3/5 absolute absl-about54left">
              <Card className="prime_card pad-10px themeShadow theme-bg">
                <div className="flex gap-2">
                  <FaAward className="lg:w-10 lg:h-10 md:w-10 md:h-10 w-8 h-8" />
                  <div>
                    <ReactQuill
                      id="1214249691"
                      theme="bubble"
                      readOnly
                      className="card_description bubble dynamicStyle"
                      value={data ? data["1214249691"] : "Lorem Ipsum"}
                    />

                    <p
                      id="4671674710"
                      className="dynamicStyle pt-2 card_title_0"
                    >
                      {data ? data["4671674710"] : "Lorem ipsum"}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection54;
