import { Progress, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';

const Resume = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <div className="w-full bg-white cust-bgColor br-top-20px lg:px-12 md:px-8 px-5 lg:pt-8 md:pt-8 py-8  ">
        <div className="flex">
          <h2 className="fsize32 sm-fsize24 font-semibold cust-textColor ">Resume</h2>
        </div>

        <div className="mt-3">
          {/* <h2 className="fsize24">What I Do!</h2> */}
          <div className="lg:mt-8 md:mt-6 mt-4 lg:flex md:flex w-full gap-6">
            <div className="w-full">
              <h2 className="fsize20 font-medium mb-3 cust-textColor">Educational</h2>
              <div
              className={
                colorMode === 'light'
                  ? 'bg-f3f6f6 lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
                  : 'border-1px lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
              }
              >
                <p className="fsize14 cust-textColor" >2021-23</p>
                <h2 className="fsize18 font-medium cust-textColor">Mumbai University</h2>
                <p className="fsize14 lh24px textColor-light">Mumbai, India</p>
              </div>
              <div className={
                colorMode === 'light'
                  ? 'bg-f3f6f6 lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
                  : 'border-1px lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
              }>
                <p className="fsize14 cust-textColor" >2018-21</p>
                <h2 className="fsize18 font-medium cust-textColor">Mumbai University</h2>
                <p className="fsize14 lh24px textColor-light">Mumbai, India</p>
              </div>
            </div>
            <div className="w-full lg:mt-0 md:mt-0 mt-6">
              <h2 className="fsize20 font-medium mb-3 cust-textColor">Experience</h2>
              <div className={
                colorMode === 'light'
                  ? 'bg-f3f6f6 lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
                  : 'border-1px lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
              }>
                <p className="fsize14 cust-textColor" >2021-23</p>
                <h2 className="fsize18 font-medium cust-textColor">Mumbai University</h2>
                <p className="fsize14 lh24px textColor-light">Mumbai, India</p>
              </div>
              <div className={
                colorMode === 'light'
                  ? 'bg-f3f6f6 lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
                  : 'border-1px lg:p-4 md:p-3 p-3 rounded-lg lg:mb-4 md:mb-4 mb-2'
              }>
                <p className="fsize14 cust-textColor" >2021-23</p>
                <h2 className="fsize18 font-medium cust-textColor">Mumbai University</h2>
                <p className="fsize14 lh24px textColor-light">Mumbai, India</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-e7ebeb cust-bgColor br-bottom-20px lg:px-12 md:px-8 px-5  py-8">
        <div>
          {/* <h2 className="fsize24">What I Do!</h2> */}
          <div className="flex lg:flex-row md:flex-row flex-col w-full gap-6">
            <div className="w-full">
              <h2 className="fsize20 font-medium mb-3 cust-textColor">Working Skills</h2>
              <div className="my-4">
                <div className="flex justify-between mb-1">
                  <p className="fsize14 font-medium cust-textColor">Web Design</p>
                  <p className="fsize14 font-medium cust-textColor">85%</p>
                </div>
                <Progress colorScheme="green" size="sm" value={85} />
              </div>
              <div className="my-4">
                <div className="flex justify-between mb-1">
                  <p className="fsize14 font-medium cust-textColor">Mobile App</p>
                  <p className="fsize14 font-medium cust-textColor">60%</p>
                </div>
                <Progress colorScheme="green" size="sm" value={60} />
              </div>
              <div className="my-4">
                <div className="flex justify-between mb-1">
                  <p className="fsize14 font-medium cust-textColor">Illustrator</p>
                  <p className="fsize14 font-medium cust-textColor">25%</p>
                </div>
                <Progress colorScheme="green" size="sm" value={25} />
              </div>
              <div className="my-4">
                <div className="flex justify-between mb-1">
                  <p className="fsize14 font-medium cust-textColor">Photoshop</p>
                  <p className="fsize14 font-medium cust-textColor">35%</p>
                </div>
                <Progress colorScheme="green" size="sm" value={35} />
              </div>
            </div>
            <div className="w-full">
              <h2 className="fsize20 font-medium mb-3 cust-textColor">Knowledges</h2>
              <div className="flex flex-wrap gap-4">
                <Button className="fsize12 b_button_primary px-6 py-2 border">
                  Digital Design
                </Button>
                <Button className="fsize12 b_button_primary px-6 py-2 border">
                  Marketing Marketing
                </Button>
                <Button className="fsize12 b_button_primary px-6 py-2 border">
                  Communication
                </Button>
                <Button className="fsize12 b_button_primary px-6 py-2 border">
                  Social Media
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;
