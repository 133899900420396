import React from "react";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const TypoStepper1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5  ">
      <div className="text-center lg:pb-4 md:pb-4 pb-2">
        <p
          id="1314052829"
          className="dynamicStyle text-primary fsize20 sm-fsize16 cust-textColor"
        >
          {data ? data["1314052829"] : "Process"}
        </p>
        <p
          id="7904594674"
          className="dynamicStyle fsize34 md-fsize26 sm-fsize22 font-medium lg:pb-2 md:pb-2 pb-3 text-black textColor-light"
        >
          {data ? data["7904594674"] : "How We Work For You"}
        </p>
      </div>
      <div className="relative ">
        <div className="flex lg:gap-2 md:gap-4">
          <div className="lg:w-1/12 md:1/12 w-2/12 pt-1">
            <p className="steps">1</p>
          </div>
          <div className="lg:w-11/12 md:11/12 w-10/12">
            <p
              id="0866091471"
              className="dynamicStyle fsize22 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold clr-0E1317 cust-textColor"
            >
              {data ? data["0866091471"] : "Proposal"}
            </p>
            <ReactQuill
              id="0341980383"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle lg:pb-6 md:pb-6 pb-2"
              value={data ? data["0341980383"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="flex lg:gap-2 md:gap-4">
          <div className="lg:w-1/12 md:1/12 w-2/12 pt-1">
            <p className="steps">2</p>
          </div>
          <div className="lg:w-11/12 md:11/12 w-10/12">
            <p
              id="9023122404"
              className="dynamicStyle fsize22 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold clr-0E1317 cust-textColor"
            >
              {data ? data["9023122404"] : "Agreement"}
            </p>
            <ReactQuill
              id="5968827976"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle lg:pb-6 md:pb-6 pb-2"
              value={data ? data["5968827976"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="flex lg:gap-2 md:gap-4">
          <div className="lg:w-1/12 md:1/12 w-2/12 pt-1">
            <p className="steps">3</p>
          </div>
          <div className="lg:w-11/12 md:11/12 w-10/12">
            <p
              id="5442871110"
              className="dynamicStyle fsize22 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold clr-0E1317 cust-textColor"
            >
              {data ? data["5442871110"] : "Execution"}
            </p>
            <ReactQuill
              id="2489448180"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle lg:pb-6 md:pb-6 pb-2"
              value={data ? data["2489448180"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="vert-line"></div>
      </div>

      {/* <div className="container mx-auto lg:pb-8 md:pb-6 pb-6">
        <p className="fsize22 sm-fsize20 lg:pb-2 md:pb-2 pb-1"> Proposal</p>
        <p className="lg:pb-6 md:pb-6 pb-4 sm-fsize14">
          Once they understand your needs, they will create a proposal outlining
          the scope of work, timeline, and cost.
        </p>
        <p className="fsize22 sm-fsize20 lg:pb-2 md:pb-2 pb-1">Agreement</p>
        <p className="lg:pb-6 md:pb-6 pb-4 sm-fsize14">
          If you agree to the proposal, you will sign a contract or agreement
          outlining the terms of the project.
        </p>
        <p className="fsize22 sm-fsize20 lg:pb-2 md:pb-2 pb-1">Execution</p>
        <p className="lg:pb-6 md:pb-6 pb-4 sm-fsize14">
          The company will begin working on the project and keep you updated on
          progress.
        </p>
      </div> */}
    </section>
  );
};

export default TypoStepper1;
