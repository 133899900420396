import React,{useEffect,useState} from "react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import { Grid, Navigation } from "swiper/modules";

const Testimonial18 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);
  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container test18 custtop18">
        <div class="text-center lg:w-3/4 md:w-3/4 mx-auto g:pb-10 md:pb-10 pb-6">
          <h2 id="1968072225" className="dynamicStyle section_header">
            {data ? data["1968072225"] : "Testimonials"}
          </h2>
        </div>
        {/* <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-14 md:gap-12 gap-8"> */}
        <Swiper
          slidesPerView={2}
          grid={{ rows: 2 }}
          spaceBetween={30}
          modules={[Grid, Navigation]}
          className="mySwiper testimonial18"
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="13px"
              width="13px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {getAllTestimonial
            ? getAllTestimonial?.data?.result.map((testimonial, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="flex lg:p-4 p-2 lg:gap-0 md:gap-2 gap-2">
                      <div className="lg:w-2/12 md:w-3/12 w-3/12">
                        <Image
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                          alt={testimonial.name}
                          imageClassName="lg:w-16 lg:h-16 md:w-16 md:h-16 w-14 h-14 rounded-full object-cover"
                        />
                      </div>
                      <div className="lg:w-10/12 md:w-9/12 w-9/12">
                        <div className="lg:h-36 md:h-36 h-36">
                          <p className="card_description text-justify lineClamp5">
                            {testimonial.testimonial}
                          </p>
                        </div>
                        <h4 className="card_title_0 lg:mt-0 md:mt-0 mt-2">
                          {testimonial.name}
                        </h4>
                        <p className="card_description">Microsoft</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : "No Data"}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial18;
