import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const Founders = ({ pageName }) => {
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  const elementRef3 = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  // console.log(data, 'idcheck');
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    const cleanp2 = handleScroll(elementRef2, setIsVisible2);
    const cleanp3 = handleScroll(elementRef3, setIsVisible3);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-1/2 md:w-1/2 lg:pr-4 md:pr-10">
            <div className="dynamicStyle2 w-full" id="2053287608">
              <Image
                src={data ? getImage(data["2053287608"]) : ""}
                imageClassName="chua-img tb-h280px object-contain w-full rounded-md"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="chua"
              />
            </div>
          </div>

          <div
            ref={elementRef}
            className={`fade-right lg:w-1/2 md:w-1/2 lg:pl-10 md:pl-4 lg:mt-0 mt-6 ${
              isVisible ? "visibleright" : ""
            } `}
          >
            <h2 id="8159973227" className="dynamicStyle hero_title">
              {data ? data["8159973227"] : "no-data"}
            </h2>
            <ReactQuill
              id="4556735013"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["4556735013"] : "Lorem Ipsum"}
            />
            {/* <ReactQuill
              id="4556735013"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle mt-2"
              value={data ? data["4556735013"] : "Lorem Ipsum"}
            /> */}
          </div>
        </div>
        <div className="lg:flex md:flex w-full sm-reverse items-center lg:mt-12 md:mt-10 mt-8">
          <div
            ref={elementRef2}
            className={`fade-left lg:w-1/2 md:w-full lg:pr-10 lg:mt-0 mt-6 ${
              isVisible ? "visibleleft" : ""
            } `}
          >
            <h2 id="9366882168" className="dynamicStyle hero_title">
              {data ? data["9366882168"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="2597076917"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["2597076917"] : "Lorem Ipsum"}
            />
          </div>
          <div className="lg:w-1/2  md:w-full w-full lg:pl-10 md:pl-10">
            <div id="2142586895" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data["2142586895"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="chua-img tb-h280px object-contain w-full rounded-md"
                alt="chua"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
