import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
  return (
    <section className="relative falgship-h">
      <div
        id="9231512252"
        className="dynamicStyle2 w-full falgship-h cus-featuredynamic"
      >
        <Image
          src={data ? getImage(data["9231512252"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="w-full falgship-h"
          alt="falgship"
        />
      </div>
      <div className="absolute top-0 left-0 w-full lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 id="8019995675" className="dynamicStyle section_header clr-fff">
              {data ? data["8019995675"] : "Flagship Programs"}
            </h2>
            <ReactQuill
              id="3779168579"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail clr-fff"
              value={data ? data["3779168579"] : "Welcome to My World"}
            />
          </div>
          <div id="7197292489" className="dynamicStyle lg:mt-12 md:mt-10 mt-8">
            <div
              ref={elementRef}
              className={`fade-bottom  ${isVisible ? "visiblebottom" : ""} `}
            >
              <Swiper
                spaceBetween={30}
                observer={true}
                observeParents={true}
                className="mySwiper"
                breakpoints={{
                  1536: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                  },
                  820: {
                    slidesPerView: 2.5,
                    spaceBetween: 16,
                  },
                  425: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                  },
                  325: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                  },
                }}
              >
                <div class="swiper-button image-swiper-button-prev bg-ffffffa8 rounded-full p-1 cursor-pointer">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div class="swiper-button image-swiper-button-next bg-ffffffa8 rounded-full p-1 cursor-pointer">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>

                {data && data["7197292489"]
                  ? data["7197292489"].map((e) => {
                      return (
                        <SwiperSlide key={e._id}>
                          <Image
                            src={e ? getImage(e.field1) : ""}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            imageClassName="w-70per lg:h-40 md:h-40 h-32 object-contain mx-auto"
                            alt="falgship"
                          />
                        </SwiperSlide>
                      );
                    })
                  : "no-data"}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard4;
