import React, { useEffect, useState } from "react";
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation } from "swiper/modules";
import "swiper/css/effect-cards";
import { useDispatch } from "react-redux";
import {
  getAllPodcasts,
  setSelectedPodcast,
} from "../../../redux/podcast/podcast-action";
import FeatherIcon from "feather-icons-react";

const PodcastSection1 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  const dispatch = useDispatch();
  let allPodcast = useSelector((state) => state.Podcast.Podcasts);
  const handleBlog = async () => {
    try {
      await dispatch(getAllPodcasts());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleBlog();
  }, []);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handlePodcastDetail = (podcastId) => {
    dispatch(setSelectedPodcast(podcastId));
    navigate(`/podcast-detail/${podcastId}`);
  };
  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-5/12 md:w-6/12 w-10/12 mx-auto">
            <Swiper
              effect={"cards"}
              observer={true}
              observeParents={true}
              modules={[EffectCards]}
              className="mySwiper threeD-swiper"
            >
              {allPodcast
                ? allPodcast?.map((item, index) => (
                    <SwiperSlide>
                      <Card
                        onClick={() => handlePodcastDetail(item)}
                        className={
                          index % 2 == 0
                            ? "bg-primary rounded-lg p-2 cursor-pointer"
                            : "bg-secondary rounded-lg p-2 cursor-pointer"
                        }
                      >
                        <div className="overflow-hidden rounded-lg">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + item.image}
                            imageClassName="w-full h-56 object-cover rounded-lg custimghover"
                            alt="image"
                          />
                        </div>
                        <div className="p-2">
                          <div className="py-2">
                            <h4 className="card_title_0 clr-fff lineClamp2 hover:underline">
                              {item.subtitle}
                            </h4>
                            <p
                              className="lineClamp4 card_description clr-fff"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            ></p>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  ))
                : "no-data"}
            </Swiper>
          </div>
          <div className="lg:w-7/12 md:w-6/12 w-full lg:pl-20 md:pl-10 lg:mt-0 md:mt-0 mt-4">
            <div className="text-left">
              <h2 className="section_header dynamicStyle" id="9337443306">
                {data ? data["9337443306"] : "Lorem Ipsum"}
              </h2>
            </div>
            <div className="lg:mt-10 md:mt-6 mt-4">
              {allPodcast
                ? allPodcast?.map((item, index) => (
                    <div className="flex items-center gap-4 lg:pt-4 md:pt-4 pt-3 bordblack lg:mb-4 md:mb-3 mb-2">
                      <div className="flex items-center bg-secondary justify-center lg:w-16 lg:h-16 md:w-12 md:h-12 w-12 h-12 rounded-full">
                        <FeatherIcon
                          icon="play"
                          size={20}
                          className="clr-fff flex"
                        />
                      </div>
                      <div className="lg:w-9/12 md:w-9/12 w-9/12">
                        <p className="card_description text-primary">
                          Episode {item?.episode}
                        </p>
                        <h4
                          onClick={() => handlePodcastDetail(item)}
                          className="card_title_0 lineClamp2 cursor-pointer hover:underline"
                        >
                          {item?.title}
                        </h4>
                      </div>
                    </div>
                  ))
                : "no-data"}
            </div>
            <Button
              id="7977647332"
              className="dynamicStyle fsize16 sm-fsize14 lg:mt-12 md:mt-8 mt-6 text-primary underline"
            >
              {data ? data["7977647332"] : "Explore Course"}{" "}
              <FeatherIcon
                icon="arrow-right"
                size="18"
                className="flex ml-1 text-primary"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastSection1;
