import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Image } from "primereact/image";
import { handleScroll } from "./fadeeffect/FadeEffect";

const Team9 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div
        ref={elementRef}
        className={`fade-bottom prime_container ${
          isVisible ? "visiblebottom" : ""
        } `}
      >
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
          <div className="box-shadow-none p-4  w-full relative">
            <div
              id="3913438921"
              className="dynamicStyle2 flex justify-center w-full "
            >
              <Image
                src={data ? getImage(data["3913438921"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="w-28 h-28 lg:mb-4 mb-2 rounded-full object-contain border p-3"
              />
            </div>
            <h4
              id="8415826951"
              className="dynamicStyle card_title_0 text-center"
            >
              {data ? data["8415826951"] : "Lorem Ipsum"}
            </h4>
            <p
              id="3877447515"
              className="dynamicStyle card_description mt-1 text-center"
            >
              {data ? data["3877447515"] : "Lorem Ipsum"}
            </p>
            <div className="absolute-line"></div>
          </div>
          <div className="box-shadow-none p-4 w-full border-left relative">
            <div
              id="8549625533"
              className="dynamicStyle2 flex justify-center w-full lg:mb-4 md:mb-3 mb-2"
            >
              <Image
                src={data ? getImage(data["8549625533"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="w-28 h-28 lg:mb-4 mb-2 rounded-full object-contain border p-3"
              />
            </div>
            <h4
              id="6079008184"
              className="dynamicStyle card_title_0 text-center"
            >
              {data ? data["6079008184"] : "Lorem Ipsum"}
            </h4>
            <p
              id="1167010974"
              className="dynamicStyle card_description mt-1 text-center"
            >
              {data ? data["1167010974"] : "Lorem Ipsum"}
            </p>
            <div className="absolute-line1"></div>
          </div>
          <div className="box-shadow-none p-4 w-full border-left relative">
            <div
              id="8519070225"
              className="dynamicStyle2 flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data["8519070225"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="w-28 h-28 lg:mb-4 mb-2 rounded-full object-contain border p-3"
              />
            </div>
            <h4
              id="2510640313"
              className="dynamicStyle card_title_0 text-center"
            >
              {data ? data["2510640313"] : "Lorem Ipsum"}
            </h4>
            <p
              id="9737613676"
              className="dynamicStyle card_description mt-1 text-center"
            >
              {data ? data["9737613676"] : "Lorem Ipsum"}
            </p>
            <div className="absolute-line2"></div>
          </div>
          <div className="box-shadow-none p-4 w-full border-left ">
            <div
              id="8628961122"
              className="dynamicStyle2 flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data["8628961122"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="w-28 h-28 lg:mb-4 mb-2 rounded-full object-contain border p-3"
              />
            </div>
            <h4
              id="3077957147"
              className="dynamicStyle card_title_0 text-center"
            >
              {data ? data["3077957147"] : "Lorem Ipsum"}
            </h4>
            <p
              id="6383008461"
              className="dynamicStyle card_description mt-1 text-center"
            >
              {data ? data["6383008461"] : "Lorem Ipsum"}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team9;
