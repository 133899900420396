import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection57 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex block">
          <div className="lg:w-1/2 md:w-1/2">
            <div
              id="2237591659"
              className="dynamicStyle2 mx-auto relative lg:w-6/12 md:w-8/12 w-11/12"
            >
              <Image
                src={data ? getImage(data['2237591659']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-about"
                imageClassName=" lg:h-96 md:h-96 h-80 object-cover rounded-full"
              />
              <div className="absolute bottom-0 right-0 z-10">
                <div id="5639645885" className="dynamicStyle2">
                  <Image
                    src={data ? getImage(data['5639645885']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img-about"
                    imageClassName="w-28 h-28 object-cover rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 flex items-center  lg:mt-0 md:mt-0 mt-8">
            <div className="">
              <h5 id="0997797258" className="dynamicStyle about_title">
                {data ? data['0997797258'] : 'no-data'}
              </h5>
              <ReactQuill
                id="3871216201"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description mb-6"
                value={data ? data['3871216201'] : 'Lorem Ipsum'}
              />

              <Button
                className="theme-btn b_button_primary px-6 py-2 dynamicStyle fsize14 sm-fsize14"
                id="8339948555"
              >
                <span onClick={() => handleClick('8339948555')}>
                  {data ? data['8339948555'] : 'View More'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection57;
