import { useDisclosure, Image, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const EcomProductReview1 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8 lg:mb-20 md:mb-10 md:mt-12" style={{ background: thisComponent.sectionColor }}>
        <div className="w-full flex items-center justify-between lg:mb-12 md:mb-12 mb-6 sm-pt-8">
          <div className="">
            <p
              id="9660733339"
              className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium uppercase cust-textColor text-primary"
            >
              {data ? data['9660733339'] : 'WHAT CUSTOMERS SAYS'}
            </p>
            <h2
              id="7822724793"
              className="dynamicStyle fsize34 md-fsize24 sm-fsize20  uppercase font-semibold cust-textColor"
            >
              {data ? data['7822724793'] : 'RECENT REVIEWS'}
            </h2>
          </div>

          <div className="">
          <Button
            borderRadius="100px"
            onClick={onOpen}
            className='dynamicStyle lg:py-4 md:py-3 py-2 sm-fsize14 sm-rev-26 theme-btn2'
            outlined
            id="2980043853"
          >
            {data ? data['2980043853'] : 'Write a review'}
          </Button>
          </div>

          <Modal isOpen={isOpen} onClose={onClose} className="w-40">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader className="cust-textColor">Add a Review</ModalHeader>
              <ModalCloseButton className="cust-textColor" />
              <ModalBody>
                <div className="">
                  <div className="mb-4 bdr-rev flex items-center relative cursor-pointer">
                    <div className="flex cust-textColor items-center gap-2">
                      <FiUpload className="flex" />
                      <h2 className="fsize16 font-semibold ">Upload</h2>
                    </div>
                    <input
                      type="file"
                      className="h-file file-absl cursor-pointer"
                    ></input>
                  </div>

                  <div className="mb-4">
                    <h2 className="fsize16 font-semibold mb-2 cust-textColor">
                      Rating
                    </h2>
                    <div className="flex items-center gap-2 cust-textColor">
                      <FeatherIcon
                        icon="star"
                        className="cursor-pointer w-4 h-4"
                      />
                      <FeatherIcon
                        icon="star"
                        className="cursor-pointer w-4 h-4"
                      />
                      <FeatherIcon
                        icon="star"
                        className="cursor-pointer w-4 h-4"
                      />
                      <FeatherIcon
                        icon="star"
                        className="cursor-pointer w-4 h-4"
                      />
                      <FeatherIcon
                        icon="star"
                        className="cursor-pointer w-4 h-4"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <h2 className="fsize16 font-semibold mb-2 cust-textColor">
                      Description
                    </h2>
                    <textarea
                      className="w-full border rounded-lg px-4 py-3 fsize14  cust-textColor"
                      placeholder="Type here"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
                <div className="w-full text-center mt-8 mb-4">
                  <Button mr="3" className="b_button_primary px-6 py-2 border-1px">
                    Submit
                  </Button>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <div
            className={
              colorMode === 'light'
                ? 'lg:p-6 md:p-6 p-4 rounded-lg border-1px themeShadow theme-bg themeShadow-insert'
                : 'lg:p-6 md:p-6 p-4 rounded-lg border-1px  themeShadow theme-bg'
            }
          >
            <div className="flex items-center gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="logo"
                className="w-10 h-10  p-1 rounded-full"
              />
              <h5 className="fsize18 font-semibold my-1 cust-textColor">
                title
              </h5>
            </div>
            <div className="p-2">
              <p className="fsize14 themefamily mt-2 textColor-light">
                comments
              </p>
              <div className="flex w-full justify-between items-center mt-3 pb-3 bordb-ececec">
                <div className="flex items-center gap-2 textColor-light">
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                </div>
                <p className="fsize12 textColor-light">22/03/2024</p>
              </div>
            </div>
            <div className="lg:flex md:flex cust-textColor gap-4 p-2">
              <div className="flex gap-1 items-center sm-pb-1">
                <FeatherIcon
                  icon="thumbs-up"
                  className="cursor-pointer w-4 h-4"
                />
                <p className="fsize15 "> Helpful (0)</p>
              </div>
              <div className="flex gap-1 items-center">
                <FeatherIcon
                  icon="thumbs-down"
                  className="cursor-pointer w-4 h-4"
                />
                <p className="fsize15 ">Not Helpful (0)</p>
              </div>
            </div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'lg:p-6 md:p-6 p-4 rounded-lg border themeShadow theme-bg themeShadow-insert'
                : 'lg:p-6 md:p-6 p-4 rounded-lg border-1px lg:p-6 md:p-6 p-4 rounded-lg themeShadow theme-bgthemeShadow theme-bg'
            }
          >
            <div className="flex items-center gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="logo"
                className="w-10 h-10  p-1 rounded-full"
              />
              <h5 className="fsize18 font-semibold my-1 cust-textColor">
                title
              </h5>
            </div>
            <div className="p-2">
              <p className="fsize14 themefamily mt-2 textColor-light">
                comments
              </p>
              <div className="flex w-full justify-between items-center mt-3 pb-3 bordb-ececec">
                <div className="flex items-center gap-2 textColor-light">
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                </div>
                <p className="fsize12 textColor-light">22/03/2024</p>
              </div>
            </div>
            <div className="lg:flex md:flex cust-textColor gap-4 p-2">
              <div className="flex gap-1 items-center sm-pb-1">
                <FeatherIcon
                  icon="thumbs-up"
                  className="cursor-pointer w-4 h-4"
                />
                <p className="fsize15 "> Helpful (0)</p>
              </div>
              <div className="flex gap-1 items-center">
                <FeatherIcon
                  icon="thumbs-down"
                  className="cursor-pointer w-4 h-4"
                />
                <p className="fsize15 ">Not Helpful (0)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EcomProductReview1;
