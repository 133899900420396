import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard20 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="px-5 py-12 lg:px-20 md:px-8 lg:py-20 md:py-16" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h3 id="5749311536" className="dynamicStyle section_header">
              {data ? data['5749311536'] : 'Lorem ipsum dolor sit'}
            </h3>
          </div>
          <div
            id="5890669509"
            className="grid grid-cols-1 gap-4 mt-12 dynamicStyle prime_card_container lg:grid-cols-3 md:grid-cols-2 lg:mt-16 md:mt-16"
          >
            {data && data['5890669509']
              ? data['5890669509'].map((e, index) => {
                  return (
                    <div className="prime_card">
                      <h2 className="card_title">{e.field1}</h2>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="text-center bubble card_description"
                        value={e.field2}
                      />
                      <Button
                        className="mt-6 fsize14 text-primary px-6 py-2"
                        outlined
                      >
                        {e.field3}
                      </Button>
                    </div>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard20;
