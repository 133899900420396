import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import FeatherIcon from "feather-icons-react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const Testimonial15 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const dispatch = useDispatch();
  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);
  const data = useSelector((state) => state.cms.allSections[pageName]);

  useEffect(() => {
    dispatch(getTestimonal());
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        {getAllTestimonial && (
          <section className="">
            <div className="w-full lg:flex md:block sm-reverse lg:gap-12 md:gap-12 gap-4">
              <div className="lg:w-2/3 md:w-full bg_surface100 p-4">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={1}
                  observer={true}
                  observeParents={true}
                  className="py-2"
                  breakpoints={{
                    1536: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1280: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    820: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    425: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    325: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Navigation]}
                  navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                  }}
                >
                  <div className="absolute bottom-8 right-12">
                    <div className="swiper-button image-swiper-button-prev bg_surface50 rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                      </svg>
                    </div>
                    <div className="swiper-button image-swiper-button-next bg_surface50 rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                      </svg>
                    </div>
                  </div>
                  {getAllTestimonial
                    ? getAllTestimonial?.data?.result.map(
                        (testimonial, index) => {
                          return (
                            <SwiperSlide>
                              <div
                                className="lg:flex md:flex gap-6 w-full"
                                key={index}
                              >
                                <div className="lg:w-1/2 md:w-1/2">
                                  <Image
                                    src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                    imageClassName="testimonial16-img sm-h-250px object-cover"
                                    alt={testimonial.name}
                                  />
                                </div>
                                <div className="lg:w-1/2 md:w-1/2 lg:py-8 md:py-0 py-4">
                                  <h4 className="card_title">
                                    {testimonial.name}
                                  </h4>
                                  <div className="lg:mb-2">
                                    <Rating
                                      initialRating={testimonial.rating}
                                      readonly={true}
                                      emptySymbol={
                                        <FeatherIcon
                                          icon="star"
                                          className="mr-1 md-star-18 sm-star-16 "
                                          size={20}
                                        />
                                      }
                                      fullSymbol={
                                        <FeatherIcon
                                          icon="star"
                                          className="mr-1 fillstroke sg"
                                          size={20}
                                        />
                                      }
                                      fractions={2}
                                    />
                                  </div>
                                  <div className="lg:h-36 md:h-36 h-36">
                                    <p className="card_description font-light lineClamp5">
                                      {testimonial.testimonial}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        }
                      )
                    : "No Data"}
                </Swiper>
              </div>
              <div className="lg:w-1/3 md:w-full lg:py-12 md:py-6">
                <h2 id="5930058325" className="dynamicStyle section_header">
                  {data ? data["5930058325"] : "What Our Client Says"}
                </h2>
                <ReactQuill
                  id="2056466865"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle section_detail"
                  value={data ? data["2056466865"] : "Welcome to My World"}
                />
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default Testimonial15;
