import { ColorModeScript, theme } from '@chakra-ui/react';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import '../src/index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import '../src/assets/css/styles/tailwind.css';
import '../src/assets/css/styles/tailwind.generated.css';
import '../src/pages/allSections/extension/dms/icici.scss';
import '../src/assets/css/index.scss';
import '../src/assets/css/animationCSS.scss';
import '../src/assets/css/Font.scss';
import '../src/assets/css/cms.scss';
import '../src/assets/css/swiper.css';

import '../src/pages/allSections/extension/extension.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-calendar/dist/Calendar.css';
import '../src/assets/css/prime.scss';
import 'react-vertical-timeline-component/style.min.css'
import 'primeicons/primeicons.css';
import 'react-vertical-timeline-component/style.min.css';
// import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { HelmetProvider } from 'react-helmet-async';
import { PrimeReactProvider } from 'primereact/api';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const helmetContext = {};
const value = {
  ripple: true,
};

root.render(
  <PrimeReactProvider value={value}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <HelmetProvider context={helmetContext}>
          <App />
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </PrimeReactProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
