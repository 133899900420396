import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import FeatherIcon from "feather-icons-react";
import Rating from "react-rating";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Image } from "primereact/image";

const EcomProductCategory3 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const [heartFill, setHeartFill] = useState(false);
  const handleHeartFill = () => {
    setHeartFill(!heartFill);
  };

  const clothes = [
    {
      id: 1,
      productName: "Men's Casual Shirt",
      actualPrice: 40,
      discount: 10,
      image: "https://example.com/images/mens-casual-shirt.jpg",
      deal: "Summer Sale",
      category: "Shirt",
      subCategory: "Men",
    },
    {
      id: 2,
      productName: "Women's Floral T-Shirt",
      actualPrice: 30,
      discount: 15,
      image: "https://example.com/images/womens-floral-tshirt.jpg",
      deal: "Spring Collection",
      category: "T-Shirt",
      subCategory: "Women",
    },
    {
      id: 3,
      productName: "Kids' Denim Jeans",
      actualPrice: 25,
      discount: 5,
      image: "https://example.com/images/kids-denim-jeans.jpg",
      deal: "Back to School",
      category: "Jeans",
      subCategory: "Kids",
    },
    {
      id: 4,
      productName: "Men's Denim Jeans",
      actualPrice: 60,
      discount: 20,
      image: "https://example.com/images/mens-denim-jeans.jpg",
      deal: "Denim Deals",
      category: "Jeans",
      subCategory: "Men",
    },
    {
      id: 5,
      productName: "Women's Cardigan Jacket",
      actualPrice: 70,
      discount: 25,
      image: "https://example.com/images/womens-cardigan-jacket.jpg",
      deal: "Winter Warmers",
      category: "Jacket",
      subCategory: "Women",
    },
    {
      id: 6,
      productName: "Men's Sports Jacket",
      actualPrice: 100,
      discount: 30,
      image: "https://example.com/images/mens-sports-jacket.jpg",
      deal: "Sportswear Sale",
      category: "Jacket",
      subCategory: "Men",
    },
    {
      id: 7,
      productName: "Women's Sunglasses",
      actualPrice: 45,
      discount: 10,
      image: "https://example.com/images/womens-sunglasses.jpg",
      deal: "Fashion Deals",
      category: "Glasses",
      subCategory: "Women",
    },
    {
      id: 8,
      productName: "Kids' Hoodie Jacket",
      actualPrice: 35,
      discount: 15,
      image: "https://example.com/images/kids-hoodie-jacket.jpg",
      deal: "Winter Collection",
      category: "Jacket",
      subCategory: "Kids",
    },
    {
      id: 9,
      productName: "Men's Formal Shirt",
      actualPrice: 55,
      discount: 20,
      image: "https://example.com/images/mens-formal-shirt.jpg",
      deal: "Business Attire",
      category: "Shirt",
      subCategory: "Men",
    },
    {
      id: 10,
      productName: "Women's Blouse Shirt",
      actualPrice: 35,
      discount: 10,
      image: "https://example.com/images/womens-blouse-shirt.jpg",
      deal: "Office Wear",
      category: "Shirt",
      subCategory: "Women",
    },
  ];
  return (
    <>
      <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <h4
            id="3758548293"
            className="dynamicStyle section_header text-center"
          >
            {data ? data["3758548293"] : "TRENDING"}
          </h4>
          <div className="lg:mt-12 md:mt-10 mt-8">
            <Swiper
              spaceBetween={25}
              slidesPerView={5}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="p-1"
              autoplay={{
                delay: "1000",
              }}
              loop
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                820: {
                  slidesPerView: 3.5,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button  image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {clothes && clothes.length > 0
                ? clothes.map((cat) => {
                    return (
                      <SwiperSlide>
                        <Card className="bg_none shadow-none" key={cat.id}>
                          <div className="themeShadow ">
                            <div className="relative">
                              <Image
                                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                alt="product_image"
                                imageClassName="w-full h-48 object-cover"
                              />
                              <div className="absolute bottom-1 left-1 bg-primary px-2 py-1">
                                <p className="fsize10 clr-fff">{cat.deal}</p>
                              </div>
                              <div className="absolute top-2 right-2 px-1 py-1">
                                <FeatherIcon
                                  icon="heart"
                                  className="cursor-pointer w-4 h-4"
                                  fill={heartFill ? "red" : "transparent"}
                                  color={heartFill ? "red" : "black"}
                                  onClick={() => handleHeartFill()}
                                />
                              </div>
                            </div>
                            <div className="py-2 px-1">
                              <p className="fsize12 text-gray cust-textColor">
                                {cat.subCategory}
                              </p>
                              <p className="card_title_0 lineClamp1">
                                {cat.productName}
                              </p>
                              <div className="flex items-center justify-between mt-1">
                                <div className="flex items-center">
                                  <p className="line-through card_description">
                                    ${cat.actualPrice}
                                  </p>
                                  <p className="pl-2 card_description">
                                    ${cat.discount}
                                  </p>
                                </div>
                              </div>
                              <div className="lg:mt-0 md:mt-0 mt-1 flex items-center gap-1">
                                <Rating
                                  initialRating={4}
                                  readonly={true}
                                  emptySymbol={
                                    <FeatherIcon
                                      icon="star"
                                      className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                                      size={22}
                                    />
                                  }
                                  fullSymbol={
                                    <FeatherIcon
                                      icon="star"
                                      className="star-hw md-star-12 sm-star-16  fillstroke sg"
                                      size={22}
                                    />
                                  }
                                  fractions={2}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : "no-data"}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default EcomProductCategory3;
