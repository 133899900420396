import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard35 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex block w-full">
            <div className="lg:w-6/12 md:w-6/12">
              <h4 className="section_header dynamicStyle" id="3047326662">
                {data ? data['3047326662'] : 'Educate'}
              </h4>
              <ReactQuill
                id="1705674468"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail"
                value={data ? data['1705674468'] : 'Welcome to My World'}
              />
            </div>
            <div className="lg:w-6/12 md:w-6/12 lg:ml-8 md:ml-6 lg:mt-0 md:mt-0 mt-8">
              <div
                className="lg:grid-cols-2 md:grid-cols-2 grid-cols-2 grid gap-4 dynamicStyle"
                id="1075025972"
              >
                {data && data['1075025972']
                  ? data['1075025972'].map((e, index) => {
                      return (
                        <div className="lg:h-40 md:h-32 h-32 w-full">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            imageClassName="lg:h-40 md:h-32 h-32 w-full object-cover"
                            alt="img"
                          />
                        </div>
                      );
                    })
                  : 'no-card'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard35;
