import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Select,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { useSelector } from 'react-redux/es/exports';

const EcomProductSection11 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div className="container mx-auto theme-bg lg:py-16 py-8 lg:px-12 md:px-8 px-5" style={{ background: thisComponent.sectionColor }}>
      <div className="text-center mb-8">
        <h2
          id="0204205267"
          className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor"
        >
          {data ? data['0204205267'] : 'Lorem Ipsum'}
        </h2>
        <p id="7968067561" className={colorMode === 'light' ? 'dynamicStyle' : 'dynamicStyle textColor-light'}>
        {data ? data['7968067561'] : 'Lorem Ipsum'}
        </p>
      </div>
      <div className="flex gap-8">
        <div className="lg:w-1/5 md:w-2/5 mob-hidden">
          <h3 className="fsize20 font-semibold pb-4 cust-textColor">Filters</h3>
          <div className="mb-4">
            <p className="font-semibold pb-2 cust-textColor">Size</p>
            <div className="flex gap-2">
              <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light">
                S
              </p>
              <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light">
                M
              </p>
              <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light">
                L
              </p>
              <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light">
                XL
              </p>
            </div>
          </div>
          
          <div className="mb-4">
            <p className="font-semibold pb-2 cust-textColor">Colors</p>
            <div className="flex gap-2 flex-wrap">
              <div className="bg-black h-6 w-6 rounded-full"></div>
              <div className="bg-red-800 h-6 w-6 rounded-full"></div>
            </div>
          </div>
          <div className="mb-4">
            <p className="font-semibold pb-2 cust-textColor">Prices</p>
            <div className="">
              <p className="text-gray pb-2 textColor-light">$0 - $50</p>
              <p className="text-gray pb-2 textColor-light">$50 - $100</p>
              <p className="text-gray pb-2 textColor-light">$100 - $150</p>
              <p className="text-gray pb-2 textColor-light">$150 - $200</p>
            </div>
          </div>
          <div className="prod13-cust">
            <Accordion>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      className="font-semibold cust-textColor"
                    >
                      Brands
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className="prod13-coll">
                  <div className="flex flex-wrap gap-4">
                    <p className="text-gray textColor-light">Nike</p>
                    <p className="text-gray textColor-light">Levis</p>
                    <p className="text-gray textColor-light">Puma</p>
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="prod13-cust">
            <Accordion>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      className="font-semibold cust-textColor"
                    >
                      Collections
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className="prod13-coll">
                  <div className="text-gray">
                    <p className="pb-2 textColor-light">All Products</p>
                    <p className="pb-2 textColor-light">Best Sellers</p>
                    <p className="pb-2 textColor-light">New Arrivals</p>
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="mb-4">
            <p className="font-semibold pb-2 cust-textColor">Tags</p>
            <div className="flex gap-2 flex-wrap text-gray">
              <p className=" textColor-light">Sandals</p>
              <p className=" textColor-light">Shoes</p>
              <p className=" textColor-light">Bags</p>
              <p className=" textColor-light">Belts</p>
            </div>
          </div>
        </div>
        <div className="lg:4/5 md:3/5">
          <div className="flex justify-between lg:mb-0 md:mb-0 mb-2">
            <div className="lg:hidden md:hidden block filterr">
              <Menu>
                <MenuButton className='theme-bg themeShadow' as={Button} rightIcon={<FiFilter />}></MenuButton>
                <MenuList className="">
                  <div className="lg:w-1/5 md:w-2/5 p-4">
                    <h3 className="fsize20 sm-fsize16 font-semibold pb-4 cust-textColor">
                      Filters
                    </h3>
                    <div className="mb-4">
                      <p className="font-semibold pb-2 sm-fsize14 cust-textColor">
                        Size
                      </p>
                      <div className="flex gap-2">
                        <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray sm-fsize14">
                          S
                        </p>
                        <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray sm-fsize14">
                          M
                        </p>
                        <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray sm-fsize14">
                          L
                        </p>
                        <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray sm-fsize14">
                          XL
                        </p>
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-semibold pb-2 sm-fsize14 cust-textColor">
                        Colors
                      </p>
                      <div className="flex gap-2 flex-wrap">
                        <div className="bg-black h-6 w-6 rounded-full"></div>
                        <div className="bg-red-800 h-6 w-6 rounded-full"></div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <p className="font-semibold pb-2 cust-textColor">
                        Prices
                      </p>
                      <div className="">
                        <p className="text-gray pb-2 sm-fsize14 textColor-light">
                          $0 - $50
                        </p>
                        <p className="text-gray pb-2 sm-fsize14 textColor-light">
                          $50 - $100
                        </p>
                        <p className="text-gray pb-2 sm-fsize14 textColor-light">
                          $100 - $150
                        </p>
                        <p className="text-gray pb-2 sm-fsize14 textColor-light">
                          $150 - $200
                        </p>
                      </div>
                    </div>
                    <div className="prod13-cust">
                      <Accordion>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                className="font-semibold sm-fsize14 cust-textColor"
                              >
                                Brands
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} className="prod13-coll">
                            <div className="flex flex-wrap gap-4">
                              <p className="text-gray sm-fsize14 textColor-light">
                                Nike
                              </p>
                              <p className="text-gray sm-fsize14 textColor-light">
                                Levis
                              </p>
                              <p className="text-gray sm-fsize14 textColor-light">
                                Puma
                              </p>
                            </div>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    <div className="prod13-cust">
                      <Accordion>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                className="font-semibold sm-fsize14 cust-textColor"
                              >
                                Collections
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} className="prod13-coll">
                            <div className="text-gray">
                              <p className="pb-2 sm-fsize14 textColor-light">
                                All Products
                              </p>
                              <p className="pb-2 sm-fsize14 textColor-light">
                                Best Sellers
                              </p>
                              <p className="pb-2 sm-fsize14 textColor-light">
                                New Arrivals
                              </p>
                            </div>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    <div className="mb-4">
                      <p className="font-semibold pb-2 sm-fsize14 cust-textColor">
                        Tags
                      </p>
                      <div className="flex gap-2 flex-wrap text-gray">
                        <p className="pb-2 sm-fsize14 textColor-light">
                          Sandals
                        </p>
                        <p className="pb-2 sm-fsize14 textColor-light">Shoes</p>
                        <p className="pb-2 sm-fsize14 textColor-light">Bags</p>
                        <p className="pb-2 sm-fsize14 textColor-light">Belts</p>
                      </div>
                    </div>
                  </div>
                </MenuList>
              </Menu>
            </div>
            <div className="mb-6 lg:w-3/12 md:w-4/12">
              <Select
                variant="unstyled"
                placeholder="Select option"
                className="cust-textColor"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-4">
          <Card>
            <div className="pb-4 themeShadow">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-80 md:h-72 h-64 lg:mb-4 md:mb-4 mb-2"
              />
              <p className="font-semibold sm-fsize14 cust-textColor pl-1">
                Rounded Red Hat
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'sm-fsize14 pl-1'
                    : 'sm-fsize14 pl-1 textColor-light'
                }
              >
                $8.00
              </p>
              <div className="flex gap-2 flex-wrap pt-2 pl-1">
                <div className="bg-black lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
                <div className="bg-red-800 lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
              </div>
            </div>
            </Card>
            <Card>
            <div className="pb-4 themeShadow">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-80 md:h-72 h-64 lg:mb-4 md:mb-4 mb-2"
              />
              <p className="font-semibold sm-fsize14 cust-textColor pl-1">
                Rounded Red Hat
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'sm-fsize14 pl-1'
                    : 'sm-fsize14 pl-1 textColor-light'
                }
              >
                $8.00
              </p>
              <div className="flex gap-2 flex-wrap pt-2 pl-1">
                <div className="bg-black lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
                <div className="bg-red-800 lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
              </div>
            </div>
            </Card>
            <Card>
            <div className="pb-4 themeShadow">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-80 md:h-72 h-64 lg:mb-4 md:mb-4 mb-2"
              />
              <p className="font-semibold sm-fsize14 cust-textColor pl-1">
                Rounded Red Hat
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'sm-fsize14 pl-1'
                    : 'sm-fsize14 pl-1 textColor-light'
                }
              >
                $8.00
              </p>
              <div className="flex gap-2 flex-wrap pt-2 pl-1">
                <div className="bg-black lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
                <div className="bg-red-800 lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
              </div>
            </div>
            </Card>
            <Card>
            <div className="pb-4 themeShadow">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-80 md:h-72 h-64 lg:mb-4 md:mb-4 mb-2"
              />
              <p className="font-semibold sm-fsize14 cust-textColor pl-1">
                Rounded Red Hat
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'sm-fsize14 pl-1'
                    : 'sm-fsize14 pl-1 textColor-light'
                }
              >
                $8.00
              </p>
              <div className="flex gap-2 flex-wrap pt-2 pl-1">
                <div className="bg-black lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
                <div className="bg-red-800 lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
              </div>
            </div>
            </Card>
            <Card>
            <div className="pb-4 themeShadow">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-80 md:h-72 h-64 lg:mb-4 md:mb-4 mb-2"
              />
              <p className="font-semibold sm-fsize14 cust-textColor pl-1">
                Rounded Red Hat
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'sm-fsize14 pl-1'
                    : 'sm-fsize14 pl-1 textColor-light'
                }
              >
                $8.00
              </p>
              <div className="flex gap-2 flex-wrap pt-2 pl-1">
                <div className="bg-black lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
                <div className="bg-red-800 lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
              </div>
            </div>
            </Card>
            <Card>
            <div className="pb-4 themeShadow">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-80 md:h-72 h-64 lg:mb-4 md:mb-4 mb-2"
              />
              <p className="font-semibold sm-fsize14 cust-textColor pl-1">
                Rounded Red Hat
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'sm-fsize14 pl-1'
                    : 'sm-fsize14 pl-1 textColor-light'
                }
              >
                $8.00
              </p>
              <div className="flex gap-2 flex-wrap pt-2 pl-1">
                <div className="bg-black lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
                <div className="bg-red-800 lg:h-5 lg:w-5 md:h-6 md:w-6 h-4 w-4 rounded-full cursor-pointer"></div>
              </div>
            </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomProductSection11;
