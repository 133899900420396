import React from "react";
import { Button } from "primereact/button";
import UploaderBox from "../../../components/UploaderBox";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";

const CareersDetails2 = () => {
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:flex md:flex gap-4">
          <div className="lg:w-2/3 md:w-3/5 w-full pr-4">
            <h2 className="section_header">Career Details Name</h2>
            <p className="fsize18 md-fsize18 sm-fsize14 text_surface800 lg:mt-3 mt-1 font-medium">
              Career sub title
            </p>
            <p className="fsize18 md-fsize18 sm-fsize14 text_surface800  lg:mt-3 mt-1 font-medium">
              Career sub title 2
            </p>
            <p className="fsize16 md-fsize16 sm-fsize12 font-medium italic mt-3 text_surface800 ">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque molestiae officia reiciendis minima commodi sunt. Quam,
              quia. Dignissimos, ab dolorum.
            </p>
            <p className="fsize16 sm-fsize12 mt-3 text_surface800 ">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque molestiae officia reiciendis minima commodi sunt. Quam,
              quia. Dignissimos, ab dolorum.
            </p>

            <div className="pt-2">
              <p className="fsize16 sm-fsize14 mt-3 font-semibold text_surface800 ">
                In this role, you will get:
              </p>
              <ul className="lg:ml-10 md:ml-10 ml-6 md: mt-2">
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
              </ul>
            </div>
            <div className="pt-2">
              <p className="fsize16 sm-fsize14 mt-3 font-semibold text_surface800 ">
                In this role, you will get:
              </p>
              <ul className="lg:ml-10 md:ml-10 ml-6 md: mt-2">
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text_surface800 ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-2/5 w-full">
            <Card className="prime_card lg:mt-0 md:mt-0 mt-6 themeShadow theme-bg">
              <div className="flex flex-col gap-2">
                <label htmlFor="username">First Name*</label>

                <InputText
                  placeholder="First name"
                  className="sm-fsize12 fsize14"
                />
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <label htmlFor="username">Last Name*</label>

                <InputText
                  placeholder="Last name"
                  className="sm-fsize12 fsize14"
                />
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <label htmlFor="username">Phone*</label>

                <InputText placeholder="Phone" className="sm-fsize12 fsize14" />
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <label htmlFor="username">Email*</label>

                <InputText placeholder="Email" className="sm-fsize12 fsize14" />
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <label htmlFor="username">Location*</label>

                <InputText
                  placeholder="Location"
                  className="sm-fsize12 fsize14"
                />
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <label htmlFor="username">Linkedin Profile</label>

                <InputText
                  placeholder="Linkedin Profile"
                  className="sm-fsize12 fsize14"
                />
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <label htmlFor="username">Employee Referral Code</label>

                <InputText
                  placeholder="Employee Referral Code"
                  className="sm-fsize12 fsize14"
                />
              </div>
              <div className="mt-4">
                <label htmlFor="username">Resume/CV*</label>

                <div className="pt-2">
                  <UploaderBox accept="image/*" />
                </div>
              </div>
              <div className="mt-6 text-center">
                <Button className="b_button_primary px-6 py-2 sm-fsize14 theme-btn themeShadow theme-bg">
                  Save
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersDetails2;
