import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useColorMode } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

function HeroSectionVideo({ pageName }) {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end
  return (
    <div className="bg-secondary" style={{ background: thisComponent.sectionColor }}>
      <div className="relative ">
        <video width="100%" loop autoPlay muted className=" herovideo-height">
          <source
            src="https://nexuscluster.blob.core.windows.net/server01/nhrdn/linkdocument/document-l3eW9wZ_eHKPABUgpIjur-1713259181"
            type="video/mp4"
          />
        </video>

        <div
          ref={elementRef}
          className={`fade-bottom absolute  h-full w-full bckdrop  top-0 left-0 flex sm-reverse sm-align-center lg:px-0 px-5 lg:py-0 md:py-8 py-6 items-center ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <div className=" lg:px-20 md:px-8 lg:mt-0 md:mt-0 mt-4 text-center w-full h-full justify-center flex items-center">
            <div className="lg:w-3/4 md:w-3/4 w-full mx-auto">
              <h2
                id="0901954745"
                className="dynamicStyle fsize35 sm-fsize20  text-white  font-semibold"
              >
                {data ? data['0901954745'] : 'Lorem Ipsum'}
              </h2>
              <ReactQuill
                id="0202637486"
                theme="bubble"
                readOnly
                className={
                  colorMode === 'dark'
                    ? 'dark-bubble bubble2 lg:mt-4 md:mt-4 mt-2 dynamicStyle '
                    : 'bubble2 lg:mt-4 md:mt-4 mt-2 dynamicStyle '
                }
                value={data ? data['0202637486'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionVideo;
