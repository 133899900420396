import React, { useRef, useState } from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const AddBanner8 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="grid lg:grid-cols-3 md:grid-cols-3 lg:gap-6 md:gap-4 gap-2">
          <div
            id="8784798335"
            className="dynamicStyle2 h-500px md-h-450 sm-h200px overflow-hidden gradient rounded-lg relative"
          >
            <Image
              imageClassName="h-500px md-h-450 sm-h200px w-full object-cover"
              src={data ? getImage(data["8784798335"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img_banner"
            />
          </div>
          <div className="">
            <div>
              <div id="6034500434" className="dynamicStyle2 relative">
                <Image
                  imageClassName="h-28 w-full rounded-lg object-cover"
                  src={data ? getImage(data["6034500434"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img_banner"
                />
              </div>
              <h6
                id="1165252649"
                className="dynamicStyle font-semibold text-center section_header uppercase mt-4"
              >
                {data ? data["1165252649"] : "Men"}
              </h6>
              <h2
                id="3112837338"
                className="dynamicStyle font-semibold text-center addbanner-text uppercase"
              >
                {data ? data["3112837338"] : "Men"}
              </h2>
              <p
                id="5514894540"
                className="dynamicStyle md-fsize15 text-center sm-fsize14"
              >
                {data ? data["5514894540"] : "Men"}
              </p>
              <div className="flex justify-center lg:mt-4 md:mt-4 mt-6">
                <Button id="9009793821" className="b_button_primary px-6 py-2">
                  <span onClick={() => handleClick("9009793821")}>
                    {data ? data["9009793821"] : "Discover More"}
                  </span>
                </Button>
              </div>
              <div id="4565399057" className="dynamicStyle2 relative mt-6">
                <Image
                  imageClassName="h-28 w-full rounded-lg object-cover"
                  src={data ? getImage(data["4565399057"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div
            id="1986993098"
            className="dynamicStyle2 h-500px md-h-450 sm-h200px overflow-hidden gradient rounded-lg relative"
          >
            <Image
              imageClassName="h-500px md-h-450 sm-h200px w-full object-cover"
              src={data ? getImage(data["1986993098"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner8;
