import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import Aos from 'aos';
import 'aos/dist/aos.css';
import FeatherIcon from 'feather-icons-react';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

const FeatureCard9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h4
              className="dynamicStyle cust-textColor section_header text-center"
              id="3079268107"
            >
              {data ? data['3079268107'] : 'Meet Aur Professional teams'}
            </h4>
            <ReactQuill
              id="7085612576"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['7085612576'] : 'Lorem Ipsum'}
            />
          </div>
          <div className="lg:mt-12 md:mt-12 mt-6 dynamicStyle" id="2539121293">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
              {data && data['2539121293']
                ? data['2539121293'].map(e => {
                    return (
                      <Card
                        ref={elementRef}
                        className={`fade-bottom w-full rounded-lg relative hover-images themeShadow theme-bg ${
                          isVisible ? 'visiblebottom' : ''
                        } `}
                      >
                        {' '}
                        <div className="lg:h-72 md:h-60 h-52 w-full rounded-lg borderfeature">
                          <Image
                            src={e ? getImage(e.field4) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt={e.field1}
                            imageClassName="lg:h-72 md:h-60 h-52 w-full object-cover rounded-lg filter-b5 featureimage"
                          />
                        </div>
                        <div className="absolute top-0 left-0 lg:p-4 md:p-4 p-3 colorcard">
                          <div className="flex items-center gap-1 ">
                            <FeatherIcon
                              icon="folder"
                              size={15}
                              className="flex text_surface800"
                            />
                            <p className="fsize15 font-medium sm-fsize14 text_surface800">
                              {e.field1}
                            </p>
                          </div>
                          <h2 className="card_title_0 lg:mt-2 mt-1 ">
                            {e.field2}
                          </h2>
                        </div>
                        <div className="absolute bottom-0 left-0 lg:p-4 md:p-4 p-3 colorcard">
                          <div className="flex items-center gap-1 ">
                            <FeatherIcon
                              icon="clock"
                              size={15}
                              className="flex text_surface800"
                            />
                            <p className=" fsize15 font-medium sm-fsize14 text_surface800">
                              {e.field3}
                            </p>
                          </div>
                        </div>
                      </Card>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard9;
