import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Card } from "primereact/card";

const LeadSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="theme-bg themeShadow lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container lg:flex md:flex">
        <div className=" lg:w-1/2 md:w-1/2 w-full">
          <h2 id="2288669571" className="dynamicStyle section_header">
            {data ? data["2288669571"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="6182800367"
            theme="bubble"
            readOnly
            className="section_detail bubble dynamicStyle"
            value={data ? data["6182800367"] : "Lorem Ipsum"}
          />
          <div className="dynamicStyle mt-6" id="8306304389">
            {data && data["8306304389"]
              ? data["8306304389"].map((e) => {
                  return (
                    <div className="lg:w-3/5 md:w-4/5 w-full flex gap-2 lg:mb-6 md:mb-4 mb-2">
                      <FeatherIcon
                        className="text-gray "
                        size={28}
                        icon="check"
                      />
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="card_description bubble"
                        value={e.field1}
                      />
                    </div>
                  );
                })
              : "no-data"}
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full themeShadow theme-bg">
          <Card className="prime_card">
            <div className="grid grid-cols-1 lg:gap-6 md:gap-6 gap-4">
              <div className="flex flex-col gap-2">
                <label
                  id="1600707892"
                  htmlFor=""
                  className="dynamicStyle fsize14"
                >
                  {data ? data["1600707892"] : "First Name"}
                </label>
                <InputText
                  type="text"
                  placeholder="First name"
                  className="theme-border sm-fsize14 w-full"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  id="6887662298"
                  className="dynamicStyle fsize14"
                  htmlFor=""
                >
                  {data ? data["6887662298"] : "Last Name"}
                </label>
                <InputText
                  type="text"
                  placeholder="Last name"
                  className="theme-border sm-fsize14 w-full"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  id="7666349352"
                  className="dynamicStyle fsize14"
                  htmlFor=""
                >
                  {data ? data["7666349352"] : "Email Id"}
                </label>
                <InputText
                  type="text"
                  placeholder="Email Id"
                  className="theme-border sm-fsize14 w-full"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  id="2677367924"
                  className="dynamicStyle fsize14"
                  htmlFor=""
                >
                  {data ? data["2677367924"] : "Phone Number"}
                </label>
                <InputText
                  type="text"
                  placeholder="Phone Number"
                  className="theme-border sm-fsize14 w-full"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  id="3784698258"
                  className="dynamicStyle fsize14"
                  htmlFor=""
                >
                  {data ? data["3784698258"] : "Lorem Ipsum"}
                </label>
                <InputTextarea
                  name="description"
                  row={4}
                  id=""
                  placeholder="Type here"
                  className="theme-border sm-fsize14 w-full"
                />
              </div>
            </div>
            <div className="lg:mt-8 md:mt-8 mt-6">
              <Button
                className="dynamicStyle w-full b_button_primary px-8 py-4 theme-btn sm-fsize10 flex justify-center"
                id="8437079219"
              >
                <span onClick={() => handleClick("8437079219")}>
                  {data ? data["8437079219"] : "Submit"}
                </span>
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default LeadSection2;
