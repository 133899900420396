import { Select, Image } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import FeatherIcon from 'feather-icons-react';

const EditProfile = () => {
  const [selectValue, setSelectvalue] = useState([]);

  const [options] = useState([
    { name: 'Option 1️⃣', id: 1 },
    { name: 'Option 2️⃣', id: 2 },
  ]);

  const onSelect = (selectedList, selectedItem) => {
    // Handle the select event

    setSelectvalue(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    // Handle the remove event

    setSelectvalue(selectedList);
  };
  return (
    <div className="border py-2 px-4">
      <h2 className="fsize20 font-semibold pb-2 pb-1 clr-606060">
        Edit Profile
      </h2>
      <div className="lg:flex md:flex justify-between items-end pb-6">
        <div className="py-2 relative w-max lg:mx-0 md:mx-0 mx-auto">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="profile-pic"
            className="w-32 h-32 rounded-full bg-6f6f6f text-center "
          />
          <FeatherIcon
            icon="edit"
            className=" absolute right-1 bottom-5 w-6 h-6 bg-primary p-1 rounded-full"
          />
        </div>
        <div className="flex justify-center ">
          <Button className="fsize14 b_button_primary px-6 py-2">
            Save changes
          </Button>
        </div>
      </div>
      <div className="py-4">
        <h2 className="fsize18 font-semibold clr-606060">Basic Details</h2>
        <div className="py-4">
          <form>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <label htmlFor="" className="clr-7b7b7b fsize14">
                  First Name
                </label>
                <input
                  type="text"
                  className="w-full border-6d6d6d rounded-lg px-4 py-3 fsize14"
                  placeholder="Enter your first name"
                />
              </div>
              <div className="w-full">
                <label htmlFor="" className="clr-7b7b7b fsize14">
                  Last Name
                </label>
                <input
                  type="text"
                  className="w-full border-6d6d6d rounded-lg px-4 py-3 fsize14"
                  placeholder="Enter your last name"
                />
              </div>
            </div>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <label htmlFor="" className="clr-7b7b7b fsize14">
                  Mobile Number
                </label>
                <input
                  type="text"
                  className="w-full border-6d6d6d rounded-lg px-4 py-3 fsize14"
                  placeholder="number"
                />
              </div>
              <div className="w-full">
                <label htmlFor="" className="clr-7b7b7b fsize14">
                  Email Address
                </label>
                <input
                  type="email"
                  className="w-full border-6d6d6d rounded-lg px-4 py-3 fsize14"
                  placeholder="Enter your email address"
                />
              </div>
            </div>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <label htmlFor="" className="clr-7b7b7b fsize14">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="w-full border-6d6d6d rounded-lg px-4 py-3 fsize14"
                  placeholder="Date"
                />
              </div>
              <div className="w-full">
                <label htmlFor="" className="clr-7b7b7b fsize14">
                  Food Preffer
                </label>
                <Select
                  placeholder="select option"
                  className="w-full rounded-lg fsize14"
                >
                  <option value="option1">Select</option>
                  <option value="option2">Select 2</option>
                  <option value="option3">Select 3</option>
                </Select>
              </div>
            </div>
            <div className="">
              <label htmlFor="" className="clr-7b7b7b fsize14">
                Bio
              </label>
              <textarea
                className="w-full border-6d6d6d rounded-lg px-4 py-3 fsize14"
                placeholder="Type here"
                rows="4"
              ></textarea>
            </div>
            <div className="pt-4">
              <h2 className="fsize18 font-semibold clr-606060 mb-4">
                Your Goals
              </h2>
              <label htmlFor="" className="clr-7b7b7b fsize14 ">
                Select Goals
              </label>
              <Multiselect
                className="w-full  rounded-lg  fsize14  seelect"
                options={options} // Options to display in the dropdown
                selectedValues={selectValue} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
