import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  getAllBlogs,
  getBlogs,
  getAllCategory,
  setSelectedBlog,
  getBlogsLoadMore,
  setBlogsLoadMore,
} from '../../../redux/blogs/blogs-actions';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { handleScroll } from './fadeeffect/FadeEffect';
import FeatherIcon from 'feather-icons-react';

const CONSTANT_PER_PAGE = 9;
const CONSTANT_PAGE = 0;

const Blogs5 = ({ isSideBarOpen, pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  let BlogsLoadMore = useSelector(state => state.BlogDetails.BlogsLoadMore);
  // console.log(Blogs, "checkvalue");
  let allCategory = useSelector(state => state.BlogDetails.Category);
  console.log(allCategory, 'cate');

  const [page, setPage] = useState(CONSTANT_PAGE);
  const [perPage, setPerPage] = useState(CONSTANT_PER_PAGE);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, [
    page,
    // perPage,
    selectedCategory,
  ]);

  const fetchBlogs = async () => {
    let payload = {
      isActive: true,
      offset: page,
      limit: perPage,
    };
    if (selectedCategory && selectedCategory !== 'all') {
      Object.assign(payload, { categoryId: [selectedCategory] });
    }
    let data = await dispatch(getBlogsLoadMore(payload));
    if (data && data.code === 200 && data.data && data.data.totalCount > 0) {
      if (page === 0) {
        dispatch(setBlogsLoadMore(data.data));
      } else {
        dispatch(
          setBlogsLoadMore({
            ...data.data,
            result: [...BlogsLoadMore.result, ...data.data.result],
          })
        );
      }
    } else {
      dispatch(setBlogsLoadMore({}));
    }
    // console.log(Blogs);
    // setFilteredPosts(Blogs)
  };

  const handleLoadMore = () => {
    // setPerPage(perPage + CONSTANT_PER_PAGE);
    setPage(state => state + 1);
  };

  const handleBlogDetail = blogId => {
    dispatch(setSelectedBlog({}));
    navigate(`/blog detail/${blogId}`);
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div>
          <h2 id="8655716821" className="dynamicStyle section_header">
            {data ? data['8655716821'] : 'Useful Pet Knowledge'}
          </h2>
        </div>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {BlogsLoadMore &&
            BlogsLoadMore.result &&
            BlogsLoadMore.result.length > 0 ? (
              <div
                ref={elementRef}
                className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
              >
                <div className="lg:mt-10 md:mt-10 mt-6 shadow-none lg:grid md:grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-4">
                  {BlogsLoadMore.result.map((e, i) => {
                    return (
                      <Card
                        key={e._id}
                        className="cust-shadow p-3 rounded-lg lg:mb-0 md:mb-0 mb-4"
                      >
                        <div className="overflow-hidden rounded-xl relative">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + e.image}
                            alt="heroImage"
                            imageClassName="w-full object-cover rounded-xl custimghover lg:h-60 md:h-60 h-52"
                          />
                          <div className="absolute top-0 right-0 p-2 flex items-center gap-2 z-50">
                            <div className="wid-icon flex items-center justify-center bg-glass rounded-full">
                              <FeatherIcon
                                icon="share-2"
                                className="text-white"
                                size={16}
                              />
                            </div>
                            {/* <div className='wid-icon flex items-center justify-center bg-glass rounded-full'>
                                <FeatherIcon icon="bookmark" className='text-white' size={16} />
                            </div> */}
                          </div>
                        </div>
                        <div className="lg:mt-3 md:mt-3 mt-2">
                          <h4 className="card_title_0 lineClamp2">{e.title}</h4>
                          <p
                            dangerouslySetInnerHTML={{ __html: e.content }}
                            className="card_description lineClamp2"
                          ></p>
                          <div className='flex justify-center w-full lg:mt-4 md:mt-3 mt-2'>
                          <Button
                            onClick={() => handleBlogDetail(e._id)}
                            className="b_button_primary px-6 py-2"
                          >
                            View More
                          </Button>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex justify-center align-center">
                No Blog Found
              </div>
            )}
          </>
        )}
        {BlogsLoadMore &&
          BlogsLoadMore.totalPages &&
          BlogsLoadMore.totalPages - 1 > page && (
            <div className="lg:mt-10 md:mt-10 mt-2  text-center">
              <Button
                className="b_button_primary px-6 py-2"
                onClick={handleLoadMore}
                width="200px"
                height="50px"
              >
                LOAD MORE
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default Blogs5;
