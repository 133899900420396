import { Search2Icon } from '@chakra-ui/icons';
import {
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Checkbox,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';

const Banner = () => {
  const [section, Setsection] = useState('0');
  return (
    <div className="relative ">
      <img
        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        alt="home-banner"
        className="h-business lg:block md:hidden hidden filter-b4 w-full"
      />
      <div className="w-full lg:px-12 md:px-8 px-5 lg:absolute top-0 left-0 h-business flex justify-center items-center">
        <div className="">
          <div className="text-center mb-8">
            <h1 className="fsize36 md-fsize30 sm-fsize20 font-semibold text-white">
              What are you looking for today?
            </h1>
            <p className="lg:w-2/3 md:w-2/3 sm-fsize14 mx-auto text-white">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus,
              quos. Adipisci ullam sunt debitis, modi blanditiis accusamus non
              quasi placeat.
            </p>
          </div>
          <div className="rounded-lg bg-white">
            <div className="lg:px-10 md:px-6 px-4 py-4 flex items-center gap-2 bottom-border-ebebeb">
              <p className="text-primary fsize18 sm-fsize15">
                Current Search Pool:
              </p>
              <div className="border px-2 text-primary fsize12 rounded-full">
                4000
              </div>
            </div>
            <div className="lg:px-10 md:px-6 px-4 py-4">
              <div className="lg:flex md:flex lg:gap-4 md:gap-4 gap-2 w-full mb-4">
                <div className="lg:w-full md:w-8/12 w-full lg:flex md:flex gap-4">
                  <div className="w-full lg:mb-0 md:mb-0 mb-2">
                    <label className="fsize14 text-primary font-medium">
                      Step 1
                    </label>
                    <Button 
                      // variant={section === '1' ? 'solid' : 'outline'}
                      onClick={() => Setsection('1')}
                      borderRadius={30}
                      className="fsize15 b_button_primary px-6 py-2 sm-fsize13 md-fsize13 lg:w-auto md:w-full w-11/12 lg:mt-0 md:mt-0 mt-1"
                    >
                      Select a group
                    </Button>
                  </div>
                  <div className="w-full lg:mb-0 md:mb-0 mb-2">
                    <label className="fsize14 text-primary font-medium">
                      Step 2
                    </label>

                    <Button 
                      // variant={section === '2' ? 'solid' : 'outline'}
                      onClick={() => Setsection('2')}
                      borderRadius={30}
                      className="fsize15 b_button_primary px-6 py-2 sm-fsize13 md-fsize13 lg:w-auto md:w-full w-11/12 lg:mt-0 md:mt-0 mt-1"
                    >
                      Select a subgroup
                    </Button>
                  </div>
                  <div className="w-full lg:mb-0 md:mb-0 mb-2">
                    <label className="fsize14 text-primary font-medium">
                      Step 3
                    </label>
                    <Button 
                      // variant={section === '3' ? 'solid' : 'outline'}
                      onClick={() => Setsection('3')}
                      borderRadius={30}
                      className="fsize15 b_button_primary px-6 py-2 sm-fsize13 md-fsize13 lg:w-auto md:w-full w-11/12 lg:mt-0 md:mt-0 mt-1"
                    >
                      Sort by
                    </Button>
                  </div>
                </div>
                <div className="flex gap-4 items-end lg:w-full md:w-3/12 w-full">
                  <div className="w-full">
                    <label className="fsize14 text-primary font-medium">
                      Step 1
                    </label>
                    <InputGroup size="md">
                      <Input
                        pr="2.5rem"
                        placeholder="Select a group"
                        className="fsize14"
                      />
                      <InputRightElement width="2.5rem">
                        <Search2Icon />
                      </InputRightElement>
                    </InputGroup>
                  </div>
                </div>
                <div className="flex lg:w-full md:w-1/12 w-full items-end lg:mb-3 md:mb-0 mb-0 lg:mt-0 md:mt-0 mt-2">
                  <p
                    className="cursor-pointer lg:w-20 md:w-16 text-danger fsize12 font-medium"
                    onClick={() => Setsection('0')}
                  >
                    Clear All
                  </p>
                </div>
              </div>
            </div>
            {section === '1' ? (
              <>
                <div className="lg:px-10 md:px-8 px-5 h-md-filter lg:pt-8 md:pt-6 pt-4 pb-2 md:pb-9 lg:pb-20 container mx-auto lg:flex md:flex relative">
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <p className="fsize14 text-secondary">Step 1</p>
                    <h5 className="sm-fsize18 fsize20 font-semibold my-2 text-secondary">
                      Select a group
                    </h5>
                    <p className="fsize15 text-gray">
                      Let us first shortlist what group<br></br> of this
                      directory you wish<br></br>
                      to explore.
                    </p>
                  </div>
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <div className="bg-fa lg:p-5 md:p-3 p-2 rounded-lg">
                      <h5 className="sm-fsize18 fsize20 font-semibold py-2 bordb text-secondary">
                        Select a group{' '}
                        <span className="font-normal">(1,000 Files)</span>
                      </h5>
                      <p className="fsize14 text-primary">CLG Use</p>
                      <div className="grid grid-cols-1 gap-4 mt-3">
                        <div className="flex items-center gap-2">
                          <Checkbox>Knowledge Management Portal</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Process</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Information</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Archive</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Misc</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:absolute md:absolute bottom-0 left-0 lg:p-8 md:p-6 p-4 flex justify-end w-full">
                    <Button 
                      onClick={() => Setsection('2')}
                      borderRadius={30}
                      className="sm-fsize13 b_button_primary px-6 py-2"
                    >
                      Apply and proceed
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
            {section === '2' ? (
              <>
                <div className="lg:px-10 md:px-8 px-5 h-md-filter lg:pt-8 md:pt-6 pt-4 pb-2 md:pb-9 lg:pb-20 container mx-auto lg:flex md:flex relative">
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <p className="fsize14 text-secondary">Step 2</p>
                    <h5 className="sm-fsize18 fsize20 font-semibold my-2 text-secondary">
                      Select a subgroup
                    </h5>
                    <p className="fsize15 text-gray">
                      Let us first shortlist what group<br></br> of this
                      directory you wish<br></br>
                      to explore.
                    </p>
                  </div>
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <div className="bg-fa lg:p-5 md:p-3 p-2 rounded-lg">
                      <h5 className="sm-fsize18 fsize20 font-semibold py-2 bordb text-secondary">
                        Select a subgroup{' '}
                        <span className="font-normal">(1,000 Files)</span>
                      </h5>
                      <p className="fsize14 text-primary">CLG Use</p>
                      <div className="grid grid-cols-1 gap-4 mt-3">
                        <div className="flex items-center gap-2">
                          <Checkbox>Assets</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Libraries</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Rulebook </Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Employee</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox>Generic</Checkbox>
                          <div className="flex items-center gap-2">
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              30
                            </div>
                            <div className="border px-2 text-primary fsize12 rounded-full">
                              200
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:absolute md:absolute  bottom-0 left-0 lg:px-8 md:px-6 px-0 lg:py-8 md:py-6 py-4 flex justify-between w-full">
                    <Button 
                      onClick={() => Setsection('1')}
                      borderRadius={30}
                      className='b_button_primary px-6 py-2'
                    >
                      <FeatherIcon icon="arrow-left" className="text-white" />
                    </Button>
                    <Button 
                      onClick={() => Setsection('3')}
                      borderRadius={30}
                      className="sm-fsize13 b_button_primary px-6 py-2"
                    >
                      Apply and proceed
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
            {section === '3' ? (
              <>
                <div className="lg:px-10 md:px-8 px-5 h-md-filter lg:pt-8 md:pt-6 pt-4 pb-2 md:pb-9 lg:pb-20 container mx-auto lg:flex md:flex relative">
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <p className="fsize14 text-secondary">Step 3</p>
                    <h5 className="sm-fsize18 fsize20 font-semibold my-2 text-secondary">
                      Sort based on <br></br>date of updation
                    </h5>
                    <p className="fsize15 text-gray">
                      Sort your search results further<br></br> based on date of
                      updation for<br></br>
                      ease of access
                    </p>
                  </div>
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <div className="bg-fa lg:p-5 md:p-3 p-2 rounded-lg">
                      <h5 className="sm-fsize18 fsize20 font-semibold py-2 bordb text-secondary">
                        Search Pool
                        <span className="font-normal">(1,000 Files)</span>
                      </h5>
                      <p className="fsize14 text-primary">Sort by</p>
                      <div className="grid grid-cols-1 gap-4 mt-3">
                        <div className="">
                          <Checkbox>Oldest to Newest</Checkbox>
                        </div>
                        <div className="">
                          <Checkbox>Newest to Oldest</Checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:absolute md:absolute  bottom-0 left-0 lg:px-8 md:px-6 px-0 lg:py-8 md:py-6 py-4 flex justify-between w-full">
                    <Button 
                      onClick={() => Setsection('2')}
                      borderRadius={30}
                      className='b_button_primary px-6 py-2'
                    >
                      <FeatherIcon icon="arrow-left" className="text-white" />
                    </Button>
                    <Button 
                      className="sm-fsize13 b_button_primary px-6 py-2"
                      borderRadius={30}
                    >
                      Filters Applied
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
