import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Image, useColorMode, Button } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { FiFilter } from 'react-icons/fi';
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useDispatch } from 'react-redux';
import { getAllProducts } from '../../../../redux/ecomProduct/products-action';
import { getAllTags } from '../../../../redux/ecomTag/tags-action';
import { getAllCategorys } from '../../../../redux/ecomCategory/category-action';
import { getAllSubCategorys } from '../../../../redux/ecomSubCategory/subcategory-action';
import ReactPaginate from 'react-paginate';

const ProductList2 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();

  const [heartFill, setHeartFill] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleHeartFill = () => {
    setHeartFill(!heartFill);
  };
  const AllTagsData = useSelector(state => state.tags.Tags);
  console.log(AllTagsData, 'AllTagsData');

  const allEcommProduct = useSelector(state => state.products.Products);
  console.log(allEcommProduct, 'allEcommProduct');

  const AllCategoryData = useSelector(state => state.categorys.Categorys);
  console.log(AllCategoryData, 'AllCategoryData');

  const AllSubCategoryData = useSelector(
    state => state.subCategorys.SubCategorys
  );
  console.log(AllSubCategoryData, 'AllSubCategoryData');

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllTags());
    dispatch(getAllCategorys());
    dispatch(getAllSubCategorys());
  }, []);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const navigate = useNavigate();

  const handleProductOverview = () => {
    navigate('/ecommerce/product-overview');
  };

  const AddToWishlist = () => {
    navigate('/ecommerce/wishlist');
  };
  return (
    <>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8 lg:flex md:flex" style={{ background: thisComponent.sectionColor }}>
        <div
          className={
            colorMode === 'light'
              ? 'filtercard1 lg:w-1/4 md:w-1/4 h-100per mob-hidden themeShadow theme-bg'
              : 'filtercard-dark1  border-1px lg:w-1/4 md:w-1/4 h-100per mob-hidden themeShadow theme-bg'
          }
        >
          <div className="w-full">
            <div className="flex justify-between items-center lg:px-6 px-4 bdr-bottom-e9e9ed cust-bgColor">
              <h2
                id="3071526235"
                className="dynamicStyle fsize18 font-semibold cust-textColor py-3"
              >
                {data ? data['3071526235'] : 'Filter'}
              </h2>
              <div className="pl-4 py-3 bdr-left-f7f6f6">
                <FeatherIcon
                  icon="refresh-ccw"
                  className="cust-textColor "
                  size={20}
                />
              </div>
            </div>

            <div 
             className={
                colorMode === 'light'
                  ? 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed bg-fdf3f3'
                  : 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed cust-bgColor'
              }
              >
              <h2
                id="3537817701"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['3537817701'] : 'Category'}
              </h2>
              <div className="grid grid-cols-1 gap-2 mt-3">
                {AllCategoryData &&
                AllCategoryData.result &&
                AllCategoryData.result.length > 0
                  ? AllCategoryData.result.map(cat => {
                      return (
                        <div className="flex items-center gap-3 textColor-light text-black">
                          <Checkbox size="lg">{cat.name}</Checkbox>
                        </div>
                      );
                    })
                  : 'no-category'}

              </div>
            </div>
            <div 
             className={
                colorMode === 'light'
                  ? 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed bg-fdf3f3'
                  : 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed cust-bgColor'
              }
            >
              <h2
                id="8791857482"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['8791857482'] : 'Sub-Category'}
              </h2>
              <div className="grid grid-cols-1 gap-2 mt-3">
                {AllSubCategoryData &&
                AllSubCategoryData.result &&
                AllSubCategoryData.result.length > 0
                  ? AllSubCategoryData.result.map(sub => {
                      return (
                        <div className="flex items-center gap-3 textColor-light text-black">
                          <Checkbox size="lg">{sub.name}</Checkbox>
                        </div>
                      );
                    })
                  : 'no-sub-category'}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <div className="lg:hidden md:hidden block filterr">
            <Menu>
              <MenuButton as={Button} rightIcon={<FiFilter />}></MenuButton>
              <MenuList>
                <div className="w-full">
                  <div className="flex justify-between items-center lg:px-6 px-4 bdr-bottom-e9e9ed">
                    <h2
                      id="3071526235"
                      className="dynamicStyle fsize16 font-semibold cust-textColor py-3"
                    >
                      {data ? data['3071526235'] : 'Filter'}
                    </h2>
                    <div className="pl-4 py-3 sm-bdr-left-f7f6f6">
                      <FeatherIcon
                        icon="refresh-ccw"
                        className="cust-textColor "
                        size={20}
                      />
                    </div>
                  </div>

                  <div 
                   className={
                    colorMode === 'light'
                      ? 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed bg-fdf3f3'
                      : 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed cust-bgColor'
                  }
                  >
                    <h2
                      id="3537817701"
                      className="dynamicStyle fsize16 font-semibold cust-textColor"
                    >
                      {data ? data['3537817701'] : 'Category'}
                    </h2>
                    <div className="grid grid-cols-1 gap-2 mt-3">
                      {AllCategoryData &&
                      AllCategoryData.result &&
                      AllCategoryData.result.length > 0
                        ? AllCategoryData.result.map(cat => {
                            return (
                              <div className="flex items-center gap-3 textColor-light text-black">
                                <Checkbox size="lg">{cat.name}</Checkbox>
                              </div>
                            );
                          })
                        : 'no-category'}

                    </div>
                  </div>
                  <div 
                   className={
                    colorMode === 'light'
                      ? 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed bg-fdf3f3'
                      : 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed cust-bgColor'
                  }
                  >
                    <h2
                      id="8791857482"
                      className="dynamicStyle fsize16 font-semibold cust-textColor"
                    >
                      {data ? data['8791857482'] : 'Sub-Category'}
                    </h2>
                    <div className="grid grid-cols-1 gap-2 mt-3">
                      {AllSubCategoryData &&
                      AllSubCategoryData.result &&
                      AllSubCategoryData.result.length > 0
                        ? AllSubCategoryData.result.map(sub => {
                            return (
                              <div className="flex items-center gap-3 textColor-light text-black">
                                <Checkbox size="lg">{sub.name}</Checkbox>
                              </div>
                            );
                          })
                        : 'no-sub-category'}
                    </div>
                  </div>
                </div>
              </MenuList>
            </Menu>
          </div>
        </div>
        <div 
         className={
            colorMode === 'light'
              ? 'lg:w-3/4 md:w-3/4 filtercard1 theme-bg theme-border'
              : 'lg:w-3/4 md:w-3/4 filtercard1 cust-bgColor theme-bg theme-border'
          }
        >
          <div className="lg:flex md:flex bdr-bottom-f7f6f6">
            <div className="lg:w-3/4 md:w-3/5 relative">
              <Input
                placeholder="Search"
                size="lg"
                h="52px"
                className="border-0"
              />
              <FeatherIcon
                icon="search"
                className="cust-textColor absolute top-3 right-6 text-secondary"
                size={24}
              />
            </div>
            <div className="lg:w-1/4 md:w-2/5 flex lg:justify-center md:justify-center items-center bdr-left-f7f6f6">
              <p className="font-semibold text-secondary lg:px-2 md:px-2 px-4 lg:py-0 md:py-0 py-3 cust-textColor">
                Listed Product : 200
              </p>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="py-4 px-6 bdr-half-f7f6f6">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-40 lg:h-40 md:w-40 md:h-40 h-32 w-32 mx-auto lg:mb-6 md:mb-6 mb-4"
              />
              <div className="flex justify-between">
                <p className="font-semibold cust-textColor">Basics</p>
                <p className="font-semibold cust-textColor">₹2000</p>
              </div>
              <p className="fsize12 text-gray-400 textColor-light">All Limited Links</p>
            </div>
            <div className="py-4 px-6 bdr-half-f7f6f6">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-40 lg:h-40 md:w-40 md:h-40 h-32 w-32 mx-auto lg:mb-6 md:mb-6 mb-4"
              />
              <div className="flex justify-between">
                <p className="font-semibold cust-textColor">Basics</p>
                <p className="font-semibold cust-textColor">₹2000</p>
              </div>
              <p className="fsize12 text-gray-400 textColor-light">All Limited Links</p>
            </div>
            <div className="py-4 px-6 bdr-half-f7f6f6">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-40 lg:h-40 md:w-40 md:h-40 h-32 w-32 mx-auto lg:mb-6 md:mb-6 mb-4"
              />
              <div className="flex justify-between">
                <p className="font-semibold cust-textColor">Basics</p>
                <p className="font-semibold cust-textColor">₹2000</p>
              </div>
              <p className="fsize12 text-gray-400 textColor-light">All Limited Links</p>
            </div>
            <div className="py-4 px-6 bdr-half-f7f6f6">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-40 lg:h-40 md:w-40 md:h-40 h-32 w-32 mx-auto lg:mb-6 md:mb-6 mb-4"
              />
              <div className="flex justify-between">
                <p className="font-semibold cust-textColor">Basics</p>
                <p className="font-semibold cust-textColor">₹2000</p>
              </div>
              <p className="fsize12 text-gray-400 textColor-light">All Limited Links</p>
            </div>
            <div className="py-4 px-6 bdr-half-f7f6f6">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-40 lg:h-40 md:w-40 md:h-40 h-32 w-32 mx-auto lg:mb-6 md:mb-6 mb-4"
              />
              <div className="flex justify-between">
                <p className="font-semibold cust-textColor">Basics</p>
                <p className="font-semibold cust-textColor">₹2000</p>
              </div>
              <p className="fsize12 text-gray-400 textColor-light">All Limited Links</p>
            </div>
            <div className="py-4 px-6 bdr-half-f7f6f6">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-40 lg:h-40 md:w-40 md:h-40 h-32 w-32 mx-auto lg:mb-6 md:mb-6 mb-4"
              />
              <div className="flex justify-between">
                <p className="font-semibold cust-textColor">Basics</p>
                <p className="font-semibold cust-textColor">₹2000</p>
              </div>
              <p className="fsize12 text-gray-400 textColor-light">All Limited Links</p>
            </div>
          </div>
          <div className="px-4">
            <ReactPaginate
              className="pagination flex items-center gap-1 justify-end cust-textColor"
              breakLabel="..."
              nextLabel=">"
              pageRangeDisplayed={5}
              pageCount={200}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList2;
