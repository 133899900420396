import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, AvatarGroup } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection56 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="relative h-ab56" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:px-20 md:px-8 px-5 lg:py-0 md:py-0 py-12">
        <div className="prime_container">
          <div className="lg:flex md:flex block items-center justify-center h-ab56">
            <div className="lg:w-1/2 md:w-1/2 flex items-center">
              <div className="">
                <h5 id="1277871820" className="dynamicStyle about_title">
                  {data ? data['1277871820'] : 'no-data'}
                </h5>
                <ReactQuill
                  id="0436796438"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description mb-6"
                  value={data ? data['0436796438'] : 'Lorem Ipsum'}
                />

                <Button
                  outlined
                  className="theme-btn dynamicStyle border-primary text-primary px-6 py-2 fsize14 sm-fsize14"
                  id="6295185121"
                >
                  <span onClick={() => handleClick('6295185121')}>
                    {data ? data['6295185121'] : 'View More'}
                  </span>
                </Button>
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0 mt-8">
              <div id="4186497786" className="dynamicStyle2 margin-ab56">
                <Image
                  src={data ? getImage(data['4186497786']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img-about"
                  imageClassName="lg:w-60 lg:h-60 md:w-60 md:h-60 w-full h-40 object-contain relative lg:z-50 md:z-20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute lg:block md:block hidden top-0 right-0 h-full lg:w-4/12 md:w-4/12 bg-light-primary cust-bgColor"></div>
    </div>
  );
};

export default AboutSection56;
