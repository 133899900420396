import React from 'react';
import { FaTwitter, FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Footer3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  let brandProfile = useSelector(state => state.brandDetails.brandProfile);

  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }

  const isDarkMode = useSelector((state) => state.Loader.DarkMode);

  return (
    <section className={isDarkMode ? "bg_surface100" : "bg-secondary"}>
      <div className="container mx-auto lg:px-12 md:px-12 px-4 lg:py-10 py-8">
        <div className="lg:flex md:flex justify-between lg:gap-20 md:gap-6">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <div className="">
              <a href="/">
                <Image
                  src={getSecondaryImage(brandProfile)}
                  alt="footer-logo"
                  className="w-10rem lg:pb-4 md:pb-4 pb-2 sm-mx-auto"
                />
              </a>
              <div className="my-4">
                <div
                  id=""
                  className="lg:pb-4 md:pb-4 pb-0"
                >
                  <ReactQuill
                id="3159097733"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2"
                value={data ? data['3159097733'] : 'Lorem Ipsum'}
              />
              
                  {/* <span className="font-semibold fsize14 mb-4">Read More</span> */}
                </div>
              </div>

              <div className="icons flex gap-6 my-4 sm-center lg:pb-0 md:pb-0 pb-4">
                <a href="/">
                  <FaTwitter className="textColor-light" />
                </a>
                <a href="/">
                  <FaInstagram className="textColor-light" />
                </a>
                <a href="/">
                  <FaFacebook className="textColor-light" />
                </a>
                <a href="/">
                  <FaYoutube className="textColor-light" />
                </a>
              </div>
            </div>
          </div>
          <div className="lg:pl-24 footer-menu flex justify-between lg:w-1/2 md:w-1/2 w-full lg:px-0 md:px-0 px-5">
            <div className="">
              <h3
                id="6763561152"
                className="dynamicStyle fsize16 font-semibold lh24px cust-textColor"
              >
                {data ? data['6763561152'] : 'Lorem ipsum'}
              </h3>
              <ul className="mt-2 mb-4">
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    About us
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Blog
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Carrers
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Jobs
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    In Press
                  </li>{' '}
                </a>
              </ul>
            </div>
            <div className="">
              <h3
                id="6528213795"
                className="dynamicStyle fsize16 font-semibold cust-textColor"
              >
                {data ? data['6528213795'] : 'Lorem ipsum'}
              </h3>
              <ul className="mt-2 mb-4">
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Contact us
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Online Chat
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Whatsapp
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Telegram
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Ticketing
                  </li>{' '}
                </a>
              </ul>
            </div>
            <div className="">
              <h3
                id="5321712238"
                className="dynamicStyle fsize16 font-semibold cust-textColor"
              >
                {' '}
                {data ? data['5321712238'] : 'Lorem ipsum'}
              </h3>
              <ul className="mt-2 mb-4">
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Account
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Manage Deliveries
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Orders
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Payments
                  </li>{' '}
                </a>
                <a href="/">
                  <li className="fsize12 lh24px textColor-light py-1 clr-5a5a5a cursor-pointer">
                    Returns
                  </li>{' '}
                </a>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <span
            id="2077890487"
            className="dynamicStyle clr-5a5a5a textColor-light fsize14"
          >
            {data ? data['2077890487'] : '@2020-2021, All Rights Reserved.'}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Footer3;
