import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

const HeroSection27 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end

  return (
    <div className="herosection22-img lg:relative md:relative overflow-hidden" style={{ background: thisComponent.sectionColor }}>
      <div id="4461070558" className="w-full dynamicStyle2 ">
        <Image
          src={data ? getImage(data["4461070558"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          imageClassName="herosection22-img object-cover lg:block md:block hidden"
        />
      </div>
      <div className="lg:absolute md:absolute top-0 left-0 w-full flex items-center herosection22-img ">
        <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
          <div className="lg:flex md:flex items-center prime_container">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <h3 id="5274940636" className="hero_title dynamicStyle">
                {data ? data["5274940636"] : "Educate"}
              </h3>
              <ReactQuill
                id="7205320312"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["7205320312"] : "Lorem Ipsum"}
              />

              <Button
                outlined
                id="6275795921"
                className="dynamicStyle lg:mt-8 md:mt-8 mt-6 sm-fsize13 px-6 py-3"
              >
                <span onClick={() => handleClick("6275795921")}>
                  {data ? data["6275795921"] : "Lorem ipsum"}
                </span>
                <FeatherIcon icon="arrow-right" size="18" className=" ml-1" />
              </Button>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full lg:pl-10 md:pl-10 pl-0 lg:mt-0 md:mt-0 mt-6">
              <div id="3054584413" className="w-full dynamicStyle2 margin-hero">
                <Image
                  src={data ? getImage(data["3054584413"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="hero"
                  imageClassName="heroside-img object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection27;
