
import React, { useEffect, useRef, useState } from 'react';
import { Facebook, Instagram } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

export const ContactDetails3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
 
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
      <div
        ref={elementRef}
        className={`fade-bottom lg:flex md:flex w-full rounded-lg border-1px  text-center lg:p-16 md:p-12 p-6 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-6">
          <h2
            id="4828765606"
            className="dynamicStyle cust-textColor fsize34 md-fsize26 sm-fsize22 font-medium pb-2 text-primary"
          >
            {data ? data['4828765606'] : 'Connect with Us'}
          </h2>
          <p
            id="4811037063"
            className="dynamicStyle text-center sm-fsize14 text_surface800"
          >
            {data ? data['4811037063'] : '+5985998259+884'}
          </p>
          <p
            id="6497734695"
            className="dynamicStyle text-center sm-fsize14 text_surface800"
          >
            {data ? data['6497734695'] : 'hello@company.com'}
          </p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <h2
            id="7976773743"
            className="dynamicStyle cust-textColor fsize34 md-fsize26 sm-fsize22 font-medium pb-2 text-primary"
          >
            {data ? data['7976773743'] : 'Visit Us'}
          </h2>
          <p
            id="9712330784"
            className="dynamicStyle sm-fsize14 text_surface800 pb-2"
          >
            {data
              ? data['9712330784']
              : '128 southwork Street,London Jetpur,Rajkot,Gujrat'}
          </p>
          <div className="flex justify-center gap-4 ">
            <div>
              <Facebook className="text_surface800" />
            </div>
            <div>
              <Instagram className="text_surface800" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default ContactDetails3;
