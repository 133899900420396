import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import FeatherIcon from 'feather-icons-react';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const AboutSection60 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex block items-center">
          <div className="lg:w-5/12 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-8">
            <div className=" dynamicStyle2" id="2350544820">
              <Image
                src={data ? getImage(data['2350544820']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-about"
                imageClassName="h-450px md-h-450 sm-h-250px object-cover rounded-lg"
              />
            </div>
          </div>
          <div className="lg:w-7/12 md:w-1/2 w-full lg:pl-10 md:pl-5 lg:mt-0 md:mt-0 mt-5">
            <div className="">
              <h5 id="8476741207" className="dynamicStyle about_title">
                {data ? data['8476741207'] : 'no-data'}
              </h5>
              <ReactQuill
                id="1848089537"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description mb-4"
                value={data ? data['1848089537'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="lg:mt-5 md:mt-4 mt-2 dynamicStyle" id="0421953984">
              {data && data['0421953984']
                ? data['0421953984'].map(e => {
                    return (
                      <div key={e._id} className="mb-3">
                        <h5 className="card_title_0">{e.field1}</h5>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="card_description bubble"
                          value={e.field2}
                        />
                      </div>
                    );
                  })
                : 'no-card'}
            </div>

            <Button
              className="theme-btn b_button_primary px-6 py-2 dynamicStyle fsize16 sm-fsize14 mt-6"
              id="1482434252"
            >
              <span onClick={() => handleClick('1482434252')}>
                {data ? data['1482434252'] : 'View More'}
              </span>
              <FeatherIcon icon="arrow-right" size="16" className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection60;
