import {
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  FormControl,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { addNewsletter } from '../../../redux/newsletter/newsletter-actions';

const NewsletterSection3 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const initialValues = {
    email: '',
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, 'Email must be at least 5 characters')
      .max(50, 'Email must be at most 100 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
  });

  const handleOnSubmit = async values => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: 'Newsletter',
          text: `Subscribed to newsletter`,
          icon: 'success',
          timer: '2500',
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="flex lg:static relative">
      <form
        onSubmit={formik.handleSubmit}
        className="lg:static  z-20 absolute top-0 left-0 lg:w-3/5 h-450px sm-h-250px md-h-350px lg:px-28 md:px-24 px-5  flex flex-col justify-center"
      >
        <h2
          id="5759493285"
          className="dynamicStyle sm-clr-fff cust-textColor lg:text-left md:text-center text-center fsize48 md-fsize36 sm-fsize24 font-bold"
        >
          {data
            ? data['5759493285']
            : 'Get notified you can still join the waitist'}
        </h2>
        <div className="lg:mt-12 md:mt-10 mt-8">
          <FormControl
            isInvalid={!!formik.errors.email && formik.touched.email}
          >
            <InputGroup>
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                pr="8.5rem"
                className="cust-textColor sm-clr-fff"
                placeholder="Enter your email id"
                h="3.25rem"
              />
              <InputRightElement width="7.5rem" pt={2} h="2.75rem" right={1}>
                <Button
                  id="2649326842"
                  type="submit"
                  borderRadius={4}
                  className='dynamicStyle b_button_primary px-6 py-2 sm-fsize14'
                     
                >
                  {data ? data['2649326842'] : 'know more'}
                </Button>
              </InputRightElement>
            </InputGroup>
            {formik.touched.email && formik.errors.email && (
              <FormErrorMessage className="textwhite">
                {formik.errors.email}
              </FormErrorMessage>
            )}
          </FormControl>
        </div>
      </form>
      <div id="1817342286" className="dynamicStyle2 lg:w-2/5 mdLw-2/5 w-full">
        <Image
          src={data ? getImage(data['1817342286']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="newsletter"
          className="lg:opacity-100 md:opacity-100 opacity-90 h-450px sm-h-250px md-h-350px w-full"
        />
      </div>
    </div>
  );
};

export default NewsletterSection3;
