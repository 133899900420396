import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React from "react";

const AuthorizeForm = () => {
  return (
    <section className="">
      <div className="mt-12">
        <div className="relative mx-auto">
          <div id="6207814490" className="dynamicStyle2 w-full">
            <Image
              src={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              imageClassName="lg:w-32 h-32 mx-auto"
              alt="women"
            />
          </div>
          <div>
            <h4 className="fsize50 sm-fsize32 text-center text-primary font-semibold z-ind-1">
              Authorized
            </h4>
            <p className="fsize16 sm-fsize20 text-center text-secondary font-semibold z-ind-1">
              Page not found
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <Button className="mt-6 px-6 py-2 sm-fsize14 dynamicStyle" outlined>
            Lorem
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AuthorizeForm;
