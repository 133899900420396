import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const Founders2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  const elementRef3 = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    const cleanp2 = handleScroll(elementRef2, setIsVisible2);
    const cleanp3 = handleScroll(elementRef3, setIsVisible3);

    return () => {
      cleanp1();
      cleanp2();
      cleanp3();
    };
  }, []);

  return (
    <div className="lg:py-16 md:py-12 py-8 lg:px-20 md:px-8 px-5">
      <div className="prime_container">
        <div className="cust-reverse flex items-center">
          <div
            ref={elementRef}
            className={`fade-left lg:w-6/12 md:w-6/12 w-full lg:mt-0 mt-4 lg:pr-10 ${
              isVisible ? "visibleleft" : ""
            } `}
          >
            <h5 id="2955068673" className="dynamicStyle hero_title underline">
              {data ? data["2955068673"] : "Lorem Ipsum"}
            </h5>
            <ReactQuill
              id="2202298290"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["2202298290"] : "Lorem Ipsum"}
            />
            {/* <ul className="mt-5">
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Improving the socio-economic well-being of the society,
                    particularly of the less fortunate and underprivileged
                    members and enable them to live up to the potential that
                    they all possess.
                  </li>
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Creating an inclusive development paradigm that serves all
                    Indians and to deliver societal benefits to all geographical
                    regions of the country without any regional, linguistic,
                    caste, creed, religious or other barriers.
                  </li>
                </ul> */}
          </div>
          <div
            id="9139491074"
            className="dynamicStyle2 lg:w-6/12 md:w-6/12 w-full lg:pl-10 md:pl-6"
          >
            <Image
              src={data ? getImage(data["9139491074"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              imageClassName="foundation-img object-cover rounded-md"
              alt="foundation"
            />
          </div>
        </div>
        <div className="lg:py-16 md:py-12 py-8">
          <div className="lg:flex md:flex items-center">
            <div
              id="4343839496"
              className="dynamicStyle2 lg:w-6/12 md:w-6/12 lg:pr-10"
            >
              <Image
                src={data ? getImage(data["4343839496"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="vision-img object-cover rounded-md"
                alt="vision"
              />
            </div>
            <div
              ref={elementRef2}
              className={`fade-right lg:w-6/12 md:w-6/12 lg:mt-0 mt-4 lg:pl-10 md:pl-6 md:pl-4 ${
                isVisible2 ? "visibleright" : ""
              } `}
            >
              <h5 id="8631068767" className="dynamicStyle hero_title underline">
                {data ? data["8631068767"] : "Lorem Ipsum"}
              </h5>
              <ReactQuill
                id="6209354543"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["6209354543"] : "Lorem Ipsum"}
              />
              {/* <ul className="mt-5">
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Improving the socio-economic well-being of the society,
                    particularly of the less fortunate and underprivileged
                    members and enable them to live up to the potential that
                    they all possess.
                  </li>
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Creating an inclusive development paradigm that serves all
                    Indians and to deliver societal benefits to all geographical
                    regions of the country without any regional, linguistic,
                    caste, creed, religious or other barriers.
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
        <div className="flex cust-reverse items-center">
          <div
            ref={elementRef3}
            className={`fade-left lg:w-6/12 md:w-6/12 w-full lg:mt-0 mt-4 lg:pr-10 ${
              isVisible3 ? "visibleleft" : ""
            } `}
          >
            <h5 id="2731093684" className="dynamicStyle hero_title underline">
              {data ? data["2731093684"] : "Lorem Ipsum"}
            </h5>
            <ReactQuill
              id="0645816329"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["0645816329"] : "Lorem Ipsum"}
            />
            {/* <ul className="mt-5">
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Improving the socio-economic well-being of the society,
                    particularly of the less fortunate and underprivileged
                    members and enable them to live up to the potential that
                    they all possess.
                  </li>
                  <li className="list-dot fsize16 sm-fsize14 col-79">
                    Creating an inclusive development paradigm that serves all
                    Indians and to deliver societal benefits to all geographical
                    regions of the country without any regional, linguistic,
                    caste, creed, religious or other barriers.
                  </li>
                </ul> */}
          </div>
          <div
            id="6371957523"
            className="dynamicStyle2 lg:w-6/12 md:w-6/12 w-full lg:pl-10 md:pl-6"
          >
            <Image
              src={data ? getImage(data["6371957523"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              imageClassName="mission-img object-cover rounded-md"
              alt="mission"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders2;
