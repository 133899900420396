import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { Chip } from 'primereact/chip';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { ArrowRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getAllBlogs } from '../../../redux/blogs/blogs-actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Blogs2 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);

  let allBlog = useSelector(state => state.BlogDetails.Blog);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex block justify-center items-center">
          <div className="lg:w-4/12 md:w-4/12 w-full lg:pr-4 md:pr-2 pr-0">
            <p id="7681552050" className="dynamicStyle section_detail_0 text-primary">
              {data ? data['7681552050'] : 'Lorem ipsum'}
            </p>
            <h5 id="9234542711" className="dynamicStyle section_header">
              {data ? data['9234542711'] : 'Lorem ipsum'}
            </h5>
            <ReactQuill
              id="8201686966"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle section_detail"
              value={data ? data['8201686966'] : 'Welcome to My World'}
            />
          </div>
          <div className="lg:w-8/12 md:w-8/12 w-full lg:mt-0 md:mt-0 mt-4">
            <Swiper
              spaceBetween={25}
              slidesPerView={3.2}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper lg:p-2"
              autoplay={{
                delay: '1000',
              }}
              loop
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                991: {
                  slidesPerView: 2.8,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button  image-swiper-button-prev bg-primary rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {allBlog
                ? allBlog.map(item => (
                    <SwiperSlide>
                      <Card className="shadow-none rounded overflow-hidden">
                        <div className="w-full relative">
                          <div className="overflow-hidden rounded-lg">
                            <Image
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              imageClassName="w-full lg:h-48 h-44 object-cover custimghover"
                              alt="image"
                            />
                          </div>
                          <div className="absolute bottom-0 left-0 m-3 text-center bg-white px-3 py-1 rounded-full">
                            <p className="card_description">
                              {new Date(item.createdAt).toLocaleDateString(
                                'en-US',
                                {
                                  month: 'short',
                                  day: 'numeric',
                                }
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="p-4">
                          <div className="flex justify-between items-center">
                            <p className="card_description">
                              {new Date(item.createdAt).toLocaleTimeString(
                                'en-US',
                                {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                }
                              )}
                            </p>

                            <div className="">
                              <Chip
                                label={item.categoryID[0].categoryName}
                                className="fsize12 text-white bg-primary"
                              />
                            </div>
                          </div>
                          <div className="py-2">
                            <p className="card_title_0 lineClamp2">
                              {item.title}
                            </p>
                            {/* <p
                        className="fsize14 lineClamp3 textColor-light"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></p> */}
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  ))
                : 'no-data'}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs2;
