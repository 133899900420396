import {
  Input,
  Textarea,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Select,
  useColorMode,
  Radio,
  RadioGroup,
  Stack,
  HStack,
} from '@chakra-ui/react';
import {Image} from "primereact/image"
import { Button } from 'primereact/button';
// import Select from "react-select";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const CustomHeroSectionForm1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  const [formField, setFormField] = useState({});

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    unregister,
    trigger,
    reset,
  } = useForm({
    formField,
    shouldUnregister: true,
  });
  const [stepSchema, setStepSchema] = useState({
    0: [
      'firstName',
      'lastName',
      'email',
      'phone',
      // "DOB",
      'city',
      'state',
      'pincode',
      'address',
      // "educationQualification",
    ],

    1: [
      'locationFranchisee',
      'franchiseeAddress',
      'franchiseePincode',
      'franchiseeArea',
      'locationOwnedOrRented',
      'WhenDoYouStartFranchisee',
      // "netWorth",
      'financeBusiness',
      'soloSourceIncome',
      'partner',
    ],
  });
  const [validations, setValidations] = useState({});
  const steps = [
    { description: 'Personal Details' },

    { description: 'Franchise details' },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const handleChange = (e, inputName, type) => {
    console.log(e);
    console.log('maxcall', inputName, type);
    let fields = {};
    if (type === 'input') {
      fields = { ...formField, [inputName]: e.target.value };
    } else if (type === 'select') {
      fields = { ...formField, [inputName]: e.target.value };
    } else if (type === 'radio') {
      fields = { ...formField, [inputName]: e };
      console.log(fields, 'fielddd');
    }

    if (e.target.value == 'No' && inputName == 'locationFranchisee') {
      unregister(['franchiseeArea', 'franchiseePincode', 'franchiseeAddress']);
    }
    if (e.target.value != 'No' && inputName == 'locationFranchisee') {
      let temp = {};
      stepSchema[activeStep].forEach(element => {
        temp[element] = {
          ...register(element, {
            required: {
              value: true,
              message: `${element} is required`,
            },
          }),
        };
      });
      setValidations({ ...validations, ...temp });
    }

    setFormField(fields);

    console.log(formField, 'formField');
  };

  useEffect(() => {
    let temp = {};
    stepSchema[activeStep].forEach(element => {
      temp[element] = {
        ...register(element, {
          required: {
            value: true,
            message: `${element} is required`,
          },
        }),
      };
    });
    if (activeStep === 0) {
      unregister([
        'locationFranchisee',
        'franchiseeArea',
        'franchiseePincode',
        'franchiseeAddress',
        'locationOwnedOrRented',
        'WhenDoYouStartFranchisee',
        'financeBusiness',
        'soloSourceIncome',
        'partner',
      ]);
    }
    setValidations({ ...validations, ...temp });
  }, [activeStep]);

  useEffect(() => {}, [errors]);
  const onSubmit = async () => {
    console.log('submit');
    try {
      let payload = {
        ...formField,
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}custom/form/franchisee/create`,
        payload,
        {
          headers: {
            'community-name': process.env.REACT_APP_COMMUNITY_NAME,
          },
        }
      );

      if (data && data.code === 200) {
        Swal.fire({
          title: 'Success',
          text: `Form Submitted`,
          icon: 'success',
          confirmButtonColor: '#2bc370',
        });
        reset();
        setFormField({});
        setActiveStep(0);
        let fields = formField;
      }
    } catch (error) {
      if (error.response.data.code !== 500) {
        Swal.fire({
          title: error.response.data.name,
          text: error.response.data.message,
          icon: 'error',
          confirmButtonColor: '#2bc370',
        });
      } else {
        Swal.fire({
          title: 'error',
          text: 'Something went wrong!',
          icon: 'error',
          confirmButtonColor: '#2bc370',
        });
      }
    }
  };

  const checkValidataions = async () => {
    console.log(errors);
    const result = await trigger();
    const currentStep = console.log(
      result,
      Object.keys(stepSchema).length - 1,
      'checkErrors'
    );

    if (result && Object.keys(stepSchema).length - 1 != activeStep)
      setActiveStep(activeStep + 1);
    else if (result && Object.keys(stepSchema).length - 1 === activeStep)
      onSubmit();

    // if (!errorsCheck.length) setActiveStep(activeStep + 1);
  };

  // console.log(activeStep, formField, validations, 'ddd');

  // select-options
  const netWorthOption = [
    '5-10 Lakhs',
    '10-20 Lakhs',
    '20-50 Lakhs',
    '50 Lakhs and Above',
  ];

  const locationOwnedOrRentedOption = ['Owned', 'Rented', 'Other'];
  const locationFranchiseeOption = ['Yes', 'No'];
  const WhenDoYouStartFranchiseeOption = [
    'A year',
    '6 Months',
    '3 Months',
    'Immediately',
    'Only Enquiring',
  ];

  const educationQualificationOption = [
    '10th Pass',
    '12th Pass',
    'Graduate',
    'Post Graduate',
    'MBA',
  ];

  const soloSourceIncomeOption = ['Yes', 'No'];
  const financeBusinessOption = ['Yes', 'No'];
  const PartnerOption = ['Yes', 'No'];

  return (
    <div className="">
      <div id="6458969435" className="dynamicStyle2 relative h-400px">
        <Image
          src={data ? getImage(data['6458969435']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          imageClassName="h-400px w-full object-cover"
        />
        <div className="cust-absl lg:w-4/5 md:w-4/5 w-full text-center">
          <h3
            id="3372782684"
            className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold clr-fff pb-2"
          >
            {data ? data['3372782684'] : 'Lorem Ipsum'}
          </h3>
          <ReactQuill
                id="1575954283"
                theme="bubble"
                readOnly
                className="card_description bubble2 dynamicStyle"
                value={data ? data['1575954283'] : 'Lorem Ipsum'}
              />
        </div>
      </div>
      <div className="lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6 w-11/12 cust-shadow mx-auto cust-heroform1 bg-fff">
        <div className="lg:pb-10 md:pb-10 pb-6 custom-desc">
          <Stepper
            size="md"
            index={activeStep}
            className="custom overflow-x-auto ruby-text custom-touch"
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                className="block sm-min-w-max p-2 sm-fsize14"
                onClick={async () => {
                  if (index > activeStep) {
                    const result = await trigger();
                    console.log(result, errors, 'step');
                    if (result) {
                      setActiveStep(activeStep + 1);
                    }
                  } else {
                    setActiveStep(index);
                  }
                }}
              >
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </div>

        {activeStep === 0 ? (
          <>
            <div>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 pb-6">
                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Input
                      type="text"
                      name={'firstName'}
                      value={formField['firstName']}
                      {...register('firstName', {
                        required: `First name is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={e => handleChange(e, 'firstName', 'input')}
                      placeholder="Enter here"
                      className="sm-fsize14"
                    />
                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      First Name <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>

                  {errors['firstName'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'firstName'}-validation-error`}
                    >
                      {errors['firstName']?.message}
                    </span>
                  )}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Input
                      type="text"
                      name={'lastName'}
                      value={formField['lastName']}
                      {...register('lastName', {
                        required: `Last name is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={e => handleChange(e, 'lastName', 'input')}
                      placeholder="Enter here"
                      className="sm-fsize14"
                    />
                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Last Name <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>

                  {errors['lastName'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'lastName'}-validation-error`}
                    >
                      {errors['lastName']?.message}
                    </span>
                  )}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Input
                      type="text"
                      placeholder="Enter here"
                      value={formField['email']}
                      {...register('email', {
                        required: `email is required`,
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: `email is invalid`,
                        },
                      })}
                      onChange={e => handleChange(e, 'email', 'input')}
                      className="sm-fsize14"
                    />
                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Email Id
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>

                  {errors['email'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'email'}-validation-error`}
                    >
                      {errors['email']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Input
                      type="tel"
                      placeholder="Enter here"
                      // {...validations["phone"]}
                      {...register('phone', {
                        required: `Phone number is required`,
                        pattern: {
                          value: /^\d{10}$/,
                          message: `Phone number is invalid`,
                        },
                      })}
                      className="sm-fsize14"
                      id="phone"
                      onChange={e => {
                        if (e.target.value.length > 10) {
                          return;
                        }
                        handleChange(e, 'phone', 'input');
                      }}
                      value={formField['phone']}
                    />
                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Phone Number
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['phone'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'phone'}-validation-error`}
                    >
                      {errors['phone']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Input
                      type="date"
                      placeholder="Enter here"
                      value={formField['DOB']}
                      onChange={e => handleChange(e, 'DOB', 'input')}
                      className="sm-fsize14"
                    />
                    <p className="fsize12 px-1 abslform1 bg-fff ">
                      Date of Birth
                    </p>
                  </div>

                  {/* {errors["DOB"] && (
                      <span
                        className="fsize10 mt-1 text-danger"
                        id={`${"DOB"}-validation-error`}
                      >
                        {errors["DOB"]?.message}
                      </span>
                    )} */}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    <Input
                      type="text"
                      placeholder="Enter here"
                      className="sm-fsize14"
                      value={formField['city']}
                      {...register('city', {
                        required: `City is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={e => handleChange(e, 'city', 'input')}
                    />

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      City
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['city'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'city'}-validation-error`}
                    >
                      {errors['city']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Input
                      type="text"
                      placeholder="Enter here"
                      className="sm-fsize14"
                      value={formField['state']}
                      {...register('state', {
                        required: `State is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={e => handleChange(e, 'state', 'input')}
                    />

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      State
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['state'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'state'}-validation-error`}
                    >
                      {errors['state']?.message}
                    </span>
                  )}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Input
                      type="text"
                      value={formField['pincode']}
                      {...register('pincode', {
                        required: `Pincode is required`,
                        pattern: {
                          value: /^\d{6}$/,
                          message: `Invalid pincode`,
                        },
                      })}
                      onChange={e => {
                        if (e.target.value.length > 6) {
                          return;
                        }
                        handleChange(e, 'pincode', 'input');
                      }}
                      placeholder="Enter here"
                      className="sm-fsize14"
                    />
                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      PinCode
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['pincode'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'pincode'}-validation-error`}
                    >
                      {errors['pincode']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Input
                      type="text"
                      placeholder="Enter here"
                      className="sm-fsize14"
                      value={formField['address']}
                      {...register('address', {
                        required: `Address is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={e => handleChange(e, 'address', 'input')}
                    />

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Address
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['address'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'address'}-validation-error`}
                    >
                      {errors['address']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField['educationQualification']}
                      className="fsize14"
                      onChange={e => {
                        handleChange(e, 'educationQualification', 'select');
                      }}
                    >
                      {educationQualificationOption.map(i => {
                        return (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        );
                      })}
                    </Select>
                    <p className="fsize12 px-1 abslform1 bg-fff">
                      Education Qualififcation
                    </p>
                  </div>
                  {/* {errors["educationQualification"] && (
                      <span
                        className="fsize10 mt-1 text-danger"
                        id={`${"educationQualification"}-validation-error`}
                      >
                        {errors["educationQualification"]?.message}
                      </span>
                    )} */}
                </div>
              </div>

              <div className="flex justify-end lg:mt-10 md:mt-10 mt-6">
                <Button
                  onClick={() => checkValidataions()}
                  type="submit"
                  px={8}
                  className="b_button_primary px-6 py-2 theme-btn fsize14 sm-fsize10"
                  id=""
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        ) : null}
        {activeStep === 1 ? (
          <>
            <div>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 pb-6">
                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField['locationFranchisee']}
                      className="fsize14"
                      {...validations['locationFranchisee']}
                      onChange={e => {
                        handleChange(e, 'locationFranchisee', 'select');
                      }}
                    >
                      {locationFranchiseeOption.map(i => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Have you selected the location for the franchisee?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['locationFranchisee'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'locationFranchisee'}-validation-error`}
                    >
                      {errors['locationFranchisee']?.message}
                    </span>
                  )}
                </div>

                {formField['locationFranchisee'] === 'Yes' ? (
                  <>
                    <div className="relative ">
                      <div className="inputArrange">
                        <Input
                          type="text"
                          placeholder="Enter here"
                          className="sm-fsize14"
                          value={formField['franchiseeAddress']}
                          {...validations['franchiseeAddress']}
                          onChange={e =>
                            handleChange(e, 'franchiseeAddress', 'input')
                          }
                        />

                        <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                          Address
                          <span className="text-danger fsize18"> *</span>
                        </p>
                      </div>
                      {errors['franchiseeAddress'] && (
                        <span
                          className="fsize10 mt-1 text-danger"
                          id={`${'addrefranchiseeAddressss'}-validation-error`}
                        >
                          {errors['franchiseeAddress']?.message}
                        </span>
                      )}
                    </div>

                    <div className="relative ">
                      <div className="inputArrange">
                        <Input
                          type="text"
                          placeholder="Enter here"
                          className="sm-fsize14"
                          value={formField['franchiseePincode']}
                          {...register('franchiseePincode', {
                            required: `Pincode is required`,
                            pattern: {
                              value: /^\d{6}$/,
                              message: `Invalid pincode`,
                            },
                          })}
                          onChange={e => {
                            if (e.target.value.length > 6) {
                              return;
                            }
                            handleChange(e, 'franchiseePincode', 'input');
                          }}
                        />

                        <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                          Pincode
                          <span className="text-danger fsize18"> *</span>
                        </p>
                      </div>
                      {errors['franchiseePincode'] && (
                        <span
                          className="fsize10 mt-1 text-danger"
                          id={`${'franchiseePincode'}-validation-error`}
                        >
                          {errors['franchiseePincode']?.message}
                        </span>
                      )}
                    </div>
                    <div className="relative ">
                      <div className="inputArrange">
                        {' '}
                        <Input
                          type="text"
                          value={formField['franchiseeArea']}
                          {...validations['franchiseeArea']}
                          onChange={e =>
                            handleChange(e, 'franchiseeArea', 'input')
                          }
                          placeholder="Enter here"
                          className="sm-fsize14"
                        />
                        <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                          Area
                          <span className="text-danger fsize18"> *</span>
                        </p>
                      </div>
                      {errors['franchiseeArea'] && (
                        <span
                          className="fsize10 mt-1 text-danger"
                          id={`${'franchiseeArea'}-validation-error`}
                        >
                          {errors['franchiseeArea']?.message}
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}

                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Select
                      placeholder="Please Select..."
                      value={formField['locationOwnedOrRented']}
                      className="fsize14"
                      {...validations['locationOwnedOrRented']}
                      onChange={e => {
                        handleChange(e, 'locationOwnedOrRented', 'select');
                      }}
                    >
                      {locationOwnedOrRentedOption.map(i => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>
                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Whether the location would be owned or rented?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['locationOwnedOrRented'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'locationOwnedOrRented'}-validation-error`}
                    >
                      {errors['locationOwnedOrRented']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField['WhenDoYouStartFranchisee']}
                      className="fsize14"
                      {...validations['WhenDoYouStartFranchisee']}
                      onChange={e => {
                        handleChange(e, 'WhenDoYouStartFranchisee', 'select');
                      }}
                    >
                      {WhenDoYouStartFranchiseeOption.map(i => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      When do you want to start the franchise?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['WhenDoYouStartFranchisee'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'WhenDoYouStartFranchisee'}-validation-error`}
                    >
                      {errors['WhenDoYouStartFranchisee']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    {' '}
                    <Select
                      placeholder="Please Select..."
                      value={formField['netWorth']}
                      className="fsize14"
                      onChange={e => {
                        handleChange(e, 'netWorth', 'select');
                      }}
                    >
                      {netWorthOption.map(i => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>
                    <p className="fsize10 px-1 abslform1 bg-fff ">
                      What is your net worth?
                    </p>
                  </div>
                  {/* {errors["netWorth"] && (
                      <span
                        className="fsize10 mt-1 text-danger"
                        id={`${"netWorth"}-validation-error`}
                      >
                        {errors["netWorth"]?.message}
                      </span>
                    )} */}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField['financeBusiness']}
                      className="fsize14"
                      {...validations['financeBusiness']}
                      onChange={e => {
                        handleChange(e, 'financeBusiness', 'select');
                      }}
                    >
                      {financeBusinessOption.map(i => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      How would you finance your business?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['financeBusiness'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'financeBusiness'}-validation-error`}
                    >
                      {errors['financeBusiness']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField['soloSourceIncome']}
                      className="fsize14"
                      {...validations['soloSourceIncome']}
                      onChange={e => {
                        handleChange(e, 'soloSourceIncome', 'select');
                      }}
                    >
                      {soloSourceIncomeOption.map(i => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Would these business be your sole source of income?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['soloSourceIncome'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'soloSourceIncome'}-validation-error`}
                    >
                      {errors['soloSourceIncome']?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField['partner']}
                      className="fsize14"
                      {...validations['partner']}
                      onChange={e => {
                        handleChange(e, 'partner', 'select');
                      }}
                    >
                      {PartnerOption.map(i => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>

                    <p className="fsize12 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Will you have a partner?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors['partner'] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${'partner'}-validation-error`}
                    >
                      {errors['partner']?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-end lg:mt-10 md:mt-10 mt-6">
                <Button
                  onClick={() => checkValidataions()}
                  px={8}
                  className="b_button_primary px-6 py-2 theme-btn fsize14 sm-fsize10"
                  id=""
                >
                  Submit
                </Button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CustomHeroSectionForm1;
