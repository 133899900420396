import { Input, Textarea } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux/es/exports';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const SurveyForms = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  const [value, setValue] = useState();
  useEffect(() => {}, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div className="lg:flex md:flex gap-8">
        <div className="lg:w-1/2 md:w-1/2">
          <p id="8693981163"
            className="dynamicStyle text-gray-400 font-medium">{data ? data['8693981163'] : 'Lorem Ipsum'}</p>
          <h2 id="7330636285"
            className="dynamicStyle fsize32 md-fsize28 sm-fsize20 font-semibold">
            {data ? data['7330636285'] : 'Lorem Ipsum'}
          </h2>
          <ReactQuill
                id="5179800782"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle lg:pt-4 md:pt-4 pt-2 lg:pb-0 md:pb-0 pb-4"
                value={data ? data['5179800782'] : 'Lorem Ipsum'}
              />
        </div>
        <div className="lg:w-1/2 md:w-1/2 cust-shadow p-4 lg:mx-8 md:mx-8 rounded-lg">
          <Input placeholder="First Name" className="w-full" />
          <Input placeholder="Email Address" className="w-full my-4" />
          <div className="">
            <Input
              placeholder="Mobile Number"
              type="number"
              className="w-full mb-4"
            />
          </div>
          <Textarea
            placeholder="Enter text"
            type="textarea"
            className="w-full mb-4"
          />
          <Button id="5485589359" className="w-full b_button_primary px-6 py-2">
          <span onClick={() => handleClick('5485589359')}>
                          {data ? data['5485589359'] : 'Lorem ipsum'}
                        </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SurveyForms;
