import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard60 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h2 id="9076052153" className="dynamicStyle section_header">
            {data ? data['9076052153'] : 'Lorem Ipsum'}
          </h2>
          <ReactQuill
            id="5527213042"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['5527213042'] : 'Lorem Ipsum'}
          />
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
          <Card className="prime_card bg-e1e1e1 themeShadow theme-bg">
            <p className="fsize14 sm-fsize12 clr-fff px-2 py-1 rounded-md bg-black inline-block theme-bg theme-textColor themeShadow">
              07:00 PM
            </p>
            <p className="card_title pt-2">Lorem ipsum dolor sit amet.</p>
            <p className="lg:mb-16 md:mb-14 mb-12 card_description theme-textColor">
              2 hours 40 minutes
            </p>
            <Button
              py={6}
              className="p-1 bg-primary theme-btn sm-fsize14 clr-fff rounded-lg w-full flex justify-center"
            >
              Apply
            </Button>
          </Card>
          <Card className="prime_card bg-e1e1e1 themeShadow theme-bg">
            <p className="fsize14 sm-fsize12 clr-fff px-2 py-1 rounded-md bg-black inline-block theme-bg theme-textColor themeShadow">
              07:00 PM
            </p>
            <p className="card_title pt-2">Lorem ipsum dolor sit amet.</p>
            <p className="lg:mb-16 md:mb-14 mb-12 card_description theme-textColor">
              2 hours 40 minutes
            </p>
            <Button
              py={6}
              className="p-1 bg-primary theme-btn sm-fsize14 clr-fff rounded-lg w-full flex justify-center"
            >
              Apply
            </Button>
          </Card>
          <Card className="prime_card bg-e1e1e1 themeShadow theme-bg">
            <p className="fsize14 sm-fsize12 clr-fff px-2 py-1 rounded-md bg-black inline-block theme-bg theme-textColor themeShadow">
              07:00 PM
            </p>
            <p className="card_title pt-2">Lorem ipsum dolor sit amet.</p>
            <p className="lg:mb-16 md:mb-14 mb-12 card_description theme-textColor">
              2 hours 40 minutes
            </p>
            <Button
              py={6}
              className="p-1 bg-primary theme-btn sm-fsize14 clr-fff rounded-lg w-full flex justify-center"
            >
              Apply 
            </Button>
          </Card>
          <Card className="prime_card bg-e1e1e1 themeShadow theme-bg">
            <p className="fsize14 sm-fsize12 clr-fff px-2 py-1 rounded-md bg-black inline-block theme-bg theme-textColor themeShadow">
              07:00 PM
            </p>
            <p className="card_title pt-2">Lorem ipsum dolor sit amet.</p>
            <p className="lg:mb-16 md:mb-14 mb-12 card_description theme-textColor">
              2 hours 40 minutes
            </p>
            <Button
              py={6}
              className="p-1 bg-primary theme-btn sm-fsize14 clr-fff rounded-lg w-full flex justify-center"
            >
              Apply
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard60;
