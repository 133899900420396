import { Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const VideoSection2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <>
      <div className="container mx-auto lg:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="">
          <h4
            id="8094174744"
            className="text-dark fsize34 md-fsize24 sm-fsize20 font-semibold  cust-textColor dynamicStyle"
          >
            {data ? data['8094174744'] : 'Lorem'}
          </h4>
          <ReactQuill
                id="9014864018"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2 lg:w-7/12 md:w-7/12"
                value={data ? data['9014864018'] : 'Lorem Ipsum'}
              />
        </div>
        <div className="lg:flex md:flex gap-6 w-full lg:mt-12 md:mt-12 mt-6">
          <div className="lg:w-/12 md:w-1/2 w-full">
            <iframe
              src="https://www.youtube-nocookie.com/embed/kkipHKSh3i8?si=b3_X-QHhyJlp-mhn&amp;controls=0"
              title="Example Iframe"
              height="370"
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              refreerepolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{ border: 'none' }}
            />
          </div>
          <div className=" lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-6">
            <div className="flex w-full lg:gap-4 md:gap-4 gap-4 bg-fa p-4 rounded-lg mb-4">
              <div className="lg:w-2/12">
                <Image
                  src=""
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="video image"
                  className="object-cover w-full h-20 rounded-lg"
                />
              </div>
              <div className="w-10/12">
                <h4 className="fsize20 font-semibold text-dark">fdfcds</h4>
                <p className="fsize16 md-fsize18 sm-fsize16 font-normal text-gray cust-textColor">
                  fds
                </p>
              </div>
            </div>
            <div className="flex w-full lg:gap-4 md:gap-4 gap-4 bg-fa p-4 rounded-lg mb-4">
              <div className="lg:w-2/12">
                <Image
                  src=""
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="video image"
                  className="object-cover w-full h-20 rounded-lg"
                />
              </div>
              <div className="w-10/12">
                <h4 className="fsize20 font-semibold text-dark">fdfcds</h4>
                <p className="fsize16 md-fsize18 sm-fsize16 font-normal text-gray cust-textColor">
                  fds
                </p>
              </div>
            </div>
            <div className="flex w-full lg:gap-4 md:gap-4 gap-4 bg-fa p-4 rounded-lg mb-4">
              <div className="lg:w-2/12">
                <Image
                  src=""
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="video image"
                  className="object-cover w-full h-20 rounded-lg"
                />
              </div>
              <div className="w-10/12">
                <h4 className="fsize20 font-semibold text-dark">fdfcds</h4>
                <p className="fsize16 md-fsize18 sm-fsize16 font-normal text-gray cust-textColor">
                  fds
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoSection2;
