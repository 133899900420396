import React, { useEffect, useRef, useState } from "react";
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const GallerySection9 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:grid md:grid lg:grid-cols-3 md:grid-cols-3 sm-rev-col lg:gap-8 md:gap-6">
          <div className="lg:mb-0 md:mb-0 mb-4">
            <div
              id="0433324239"
              className="dynamicStyle2 lg:h-60 md:h-52 h-48 w-full lg:mb-6 md:mb-6 mb-4"
            >
              <Image
                imageClassName="lg:h-60 md:h-52 h-48 w-full rad-ltop-4rem sm-bdr-0px object-cover"
                src={data ? getImage(data['0433324239']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
            <div
              id="1263525038"
              className="dynamicStyle2 h-480px md-h-400px sm-h200px w-full"
            >
              <Image
                imageClassName="h-480px md-h-400px sm-h200px w-full rad-lbot-4rem sm-bdr-0px object-cover"
                src={data ? getImage(data['1263525038']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
          </div>
          <div className="lg:mb-0 md:mb-0 mb-4">
            <div
              id="8162710981"
              className="dynamicStyle2 h-480px md-h-400px sm-h200px w-full lg:mb-6 md:mb-6 mb-4"
            >
              <Image
                imageClassName="h-480px md-h-400px sm-h200px w-full rad-rtop-4rem sm-bdr-0px object-cover"
                src={data ? getImage(data['8162710981']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
            <div
              id="1609549658"
              className="dynamicStyle2 lg:h-60 md:h-52 h-48 w-full"
            >
              <Image
                imageClassName="lg:h-60 md:h-52 h-48 w-full rad-rbot-4rem sm-bdr-0px object-cover"
                src={data ? getImage(data['1609549658']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
          </div>
          <div className="lg:mb-0 md:mb-0 mb-4">
            <div className="mb-6 px-4 lg:pt-10 md:pt-6 pt-4">
              <p
                id="4372016994"
                className="section_header dynamicStyle text-center"
              >
                {data ? data['4372016994'] : 'Gallery'}
              </p>
              <ReactQuill
                id="8118240941"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle section_detail_0"
                value={data ? data['8118240941'] : 'Lorem Ipsum'}
              />
            </div>
            <div
              id="7396932642"
              className="dynamicStyle2 h-480px md-h-400px sm-h200px w-full"
            >
              <Image
                imageClassName="h-480px md-h-400px sm-h200px w-full rad-rbot-4rem sm-bdr-0px object-cover"
                src={data ? getImage(data['7396932642']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection9;
