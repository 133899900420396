import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import Stories from "react-insta-stories";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";

const StorySection2 = ({ pageName }) => {
  const [visible, setVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  const CustomStory = ({ story }) => {
    return (
      <div
        style={{
          color: "white",
          backgroundColor: "#333",
          height: "100%",
          width: "100%",
        }}
        className="relative"
      >
        <div style={{ marginBottom: 20 }}>
          <img
            src={
              "https://nexuscluster.blob.core.windows.net/server01/" +
              story.image
            }
            imageClassName="object-cover "
            alt="story"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="absolute bottom-0 left-0 p-6 glass1 z-index-9999999 ">
          <div style={{ marginBottom: 20 }}>
            <h2>{story.categoryname}</h2>
          </div>
          <div style={{ marginBottom: 20 }}>
            <h2>{story.description}</h2>
          </div>

          {/* <Link>   <button
              className="cursor-pointer w-full text-left"
             
              style={{
                padding: "10px 20px",
                backgroundColor: brandProfile.primaryColor,
                color: "white",
                border: "none",
                borderRadius: "4px",
              }}
            >
              {story.categoryname}
            </button></Link> */}

          {story.urllink &&
          story.urllink !== "" &&
          story.urllink.includes("https") ? (
            <Link to={`${story.urllink}`} target="_blank">
              {" "}
              <button
                className="cursor-pointer w-full text-left"
                style={{
                  padding: "10px 20px",
                  backgroundColor: brandProfile.primaryColor,
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Read More
              </button>
            </Link>
          ) : story.urllink && story.urllink !== "" ? (
            <Link to={`https://${story.urllink}`} target="_blank">
              {" "}
              <button
                className="cursor-pointer w-full text-left"
                style={{
                  padding: "10px 20px",
                  backgroundColor: brandProfile.primaryColor,
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Read More
              </button>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const [stories, setStories] = useState([]);

  useEffect(() => {
    let payload = {
      projectName: "seedtoscale",
      isDeleted: false,
      paginated: true,
      offset: 0,
      limit: 10,
    };
    axios
      .post(
        "https://seedtoscale-api.brained.in/api/forms/formGet/stories",
        payload
      )
      .then((response) => {
        const data = response.data.data;
        console.log(data, "data");
        const mappedStories = data.result.map((story) => ({
          title: story.title,
          image: story.image,
          description: story.description,
          categoryname: story.categoryname,
          urllink: story.urllink,
          thumbnailimage: story.thumbnailimage,
        }));
        setStories(mappedStories);
      })

      .catch((error) => console.log(error, "error"));
  }, []);

  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <Dialog
          visible={visible}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
          content={({ hide }) => (
            <div
              style={{
                borderRadius: "12px",
                backgroundImage:
                  "radial-gradient(circle at left top, var(--primary-400), var(--primary-700))",
              }}
              className="relative"
            >
              <i
                className="pi pi-times absolute top-6 right-4 clr-fff z-index9999 cursor-pointer"
                onClick={(e) => hide(e)}
              ></i>
              {stories.length > 0 ? (
                <Stories
                  stories={stories.map((story) => ({
                    content: () => <CustomStory story={story} />,
                  }))}
                  defaultInterval={20000}
                  width={432}
                />
              ) : (
                ""
              )}
            </div>
          )}
        ></Dialog>
        <div className="lg:flex md:flex items-center gap-4">
          <div className="">
            <p
              id="3801136752"
              className="dynamicStyle font-medium fsize16 md-fsize16 sm-fsize14 text-primary"
            >
              {data ? data["3801136752"] : "Latest"}
            </p>
            <h2 id="1494670502" className="dynamicStyle section_header">
              {data ? data["1494670502"] : "Highlights"}
            </h2>
          </div>
          <div className="w-full grid lg:grid-cols-7 grid-cols-2 gap-4 lg:mt-0 md:mt-0 mt-6">
            {stories.map((story) => {
              return (
                <div
                  className="flex flex-col gap-2 items-center"
                  onClick={() => setVisible(true)}
                >
                  <div className="img-80px rounded-full overflow-hidden">
                    <Image
                      src={`https://nexuscluster.blob.core.windows.net/server01/${story.thumbnailimage}`}
                      imageClassName="w-full object-cover "
                    />
                  </div>
                  <p className="fsize14 sm-fsize12 text_surface600">
                    {story.categoryname}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorySection2;
