import { Button } from 'primereact/button';
import { useSelector } from 'react-redux/es/exports';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';

const AboutSection39 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="px-5 py-12 lg:px-20 md:px-8 lg:py-20 md:py-16" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="items-center gap-10 lg:flex md:flex">
          <div className="relative w-full lg:w-1/2 md:w-1/2">
            <div id="7462683715" className="dynamicStyle2">
              <Image
                imageClassName="h-400px mdh-300px sm-h-250px lg:w-9/12 w-full mx-auto rounded-lg object-cover"
                src={data ? getImage(data['7462683715']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-cover"
              />
            </div>
            <div className="absolute top-0 left-0 lg:mt-6 md:mt-6 mt-4">
              <div className="flex items-center gap-2 bg-white shadow theme_insertShadow rounded-lg p-3">
                <div id="6766906375" className="dynamicStyle2">
                  <Image
                    imageClassName="w-10 h-10 rounded-full object-contain"
                    src={data ? getImage(data['6766906375']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img-cover"
                  />
                </div>
                <div>
                  <h5
                    id="3122413847"
                    className="dynamicStyle fsize16 sm-fsize14 my-0 font-semibold"
                  >
                    {data ? data['3122413847'] : 'no-data'}
                  </h5>
                  <p
                    id="8267637788"
                    className="dynamicStyle fsize12 my-0 font-medium text-gray"
                  >
                    {data ? data['8267637788'] : 'no-data'}
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 flex justify-center aboutminus-mb w-full mt-4">
              <div className="lg:w-7/12 md:w-10/12 w-11/12 bg-white theme_insertShadow shadow rounded-lg p-3 relative">
                <div className="w-10/12">
                  <h5
                    id="9932385299"
                    className="dynamicStyle fsize16 sm-fsize14 my-0 font-semibold text-dark"
                  >
                    {data ? data['9932385299'] : 'no-data'}
                  </h5>
                  <p
                    id="3137447020"
                    className="dynamicStyle fsize12 my-0 font-medium text-gray"
                  >
                    {data ? data['3137447020'] : 'no-data'}
                  </p>
                </div>
                <div className="aboutminus-mt mr-4 absolute top-0 right-0">
                  <div id="0992172131" className="dynamicStyle2">
                    <Image
                      imageClassName="w-10 h-10 rounded-full "
                      src={data ? getImage(data['0992172131']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="img-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-12 lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0">
            <div className="flex items-center gap-2 p-2 rounded-lg bg-glass2 theme_insertShadow w-max">
              <div
                className={
                  check === 1
                    ? 'bg-white cursor-pointer text-primary px-4 py-2 rounded-lg'
                    : 'cursor-pointer px-4 py-2'
                }
                onClick={() => setcheck(1)}
              >
                <p
                  id="1763659957"
                  className="dynamicStyle md-fsize13 sm-fsize12 fsize14"
                >
                  {data ? data['1763659957'] : 'no-data'}
                </p>
              </div>
              <div
                className={
                  check === 2
                    ? 'bg-white cursor-pointer text-primary px-4 py-2 rounded-lg'
                    : 'cursor-pointer px-4 py-2'
                }
                onClick={() => setcheck(2)}
              >
                <p
                  id="1504186873"
                  className="dynamicStyle md-fsize13 sm-fsize12 fsize14"
                >
                  {data ? data['1504186873'] : 'no-data'}
                </p>
              </div>
            </div>
            {check === 1 ? (
              <>
                <div className="mt-4">
                  <h5 id="2712029709" className="dynamicStyle about_title">
                    {data ? data['2712029709'] : 'no-data'}
                  </h5>
                  <p id="0560010909" className="dynamicStyle card_description">
                    {data ? data['0560010909'] : 'no-data'}
                  </p>
                  <Button
                    outlined
                    id="5052612289"
                    className="dynamicStyle border-primary sm-fsize12 theme-btn mt-6 px-6 py-2"
                  >
                    <span onClick={() => handleClick('5052612289')}>
                      {data ? data['5052612289'] : 'lorem'}
                    </span>
                  </Button>
                </div>
              </>
            ) : null}

            {check === 2 ? (
              <>
                <div className="mt-4">
                  <h5 id="5399009899" className="dynamicStyle about_title">
                    {data ? data['5399009899'] : 'no-data'}
                  </h5>
                  <p id="7027784758" className="dynamicStyle card_description">
                    {data ? data['7027784758'] : 'no-data'}
                  </p>
                  <Button
                    outlined
                    id="5186453922"
                    className="dynamicStyle border-primary sm-fsize12 theme-btn mt-6 px-6 py-2"
                  >
                    <span onClick={() => handleClick('5186453922')}>
                      {data ? data['5186453922'] : 'lorem'}
                    </span>
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection39;
