import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Team24 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:w-2/3 md:w-2/3 w-full text-center mx-auto">
          <h4 id="6743239737" className="dynamicStyle section_header">
            {data ? data["6743239737"] : "Lorem, ipsum"}
          </h4>
          <ReactQuill
            id="9454761234"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["9454761234"] : "Lorem Ipsum"}
          />
        </div>
        <div className="grid grid-cols-1 lg:gap-8 md:gap-8 gap-6 mt-12">
          <div className="lg:flex md:flex items-center">
            <div
              id="2213721362"
              className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
            >
              <Image
                src={data ? getImage(data["2213721362"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="h-300px sm-h-250px w-full object-contain rounded-2xl"
              />
            </div>
            <div className="lg:w-1/2 md:w-1/2 lg:px-8 md:px-8 px-4 lg:py-6 md:py-6 py-4 text-center">
              <p id="4807424691" className="dynamicStyle card_title_0">
                {data ? data["4807424691"] : "Lorem, ipsum"}
              </p>
              <p
                id="2982564657"
                className="dynamicStyle card_description text_surface400"
              >
                {" "}
                {data ? data["2982564657"] : "Lorem, ipsum"}
              </p>

              <ReactQuill
                id="7262442346"
                theme="bubble"
                readOnly
                className="dynamicStyle bubble2 card_description mt-1"
                value={data ? data["7262442346"] : "Lorem Ipsum"}
              />
              <div className="flex justify-center gap-4 mt-4">
                <FeatherIcon
                  icon="twitter"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="facebook"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="linkedin"
                  size={18}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="lg:flex md:flex sm-reverse items-center">
            <div className="lg:w-1/2 md:w-1/2 lg:px-8 md:px-8 px-4 lg:py-6 md:py-6 py-4 text-center">
              <p id="1145790669" className="dynamicStyle card_title_0">
                {data ? data["1145790669"] : "Lorem, ipsum"}
              </p>
              <p
                id="4650432600"
                className="dynamicStyle card_description text_surface400"
              >
                {" "}
                {data ? data["4650432600"] : "Lorem, ipsum"}
              </p>
              <ReactQuill
                id="8781133941"
                theme="bubble"
                readOnly
                className="dynamicStyle bubble2 card_description mt-1"
                value={data ? data["8781133941"] : "Lorem Ipsum"}
              />
              <div className="flex justify-center gap-4 mt-4">
                <FeatherIcon
                  icon="twitter"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="facebook"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="linkedin"
                  size={18}
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div
              id="1194290620"
              className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
            >
              <Image
                src={data ? getImage(data["1194290620"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="h-300px sm-h-250px w-full object-contain rounded-2xl"
              />
            </div>
          </div>
          <div className="lg:flex md:flex items-center">
            <div
              id="4348056649"
              className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
            >
              <Image
                src={data ? getImage(data["4348056649"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="h-300px sm-h-250px w-full object-contain rounded-2xl"
              />
            </div>
            <div className="lg:w-1/2 md:w-1/2 lg:px-8 md:px-8 px-4 lg:py-6 md:py-6 py-4 text-center">
              <p id="2640229359" className="dynamicStyle card_title_0">
                {data ? data["2640229359"] : "Lorem, ipsum"}
              </p>
              <p
                id="7797272232"
                className="dynamicStyle card_description text_surface400"
              >
                {" "}
                {data ? data["7797272232"] : "Lorem, ipsum"}
              </p>
              <ReactQuill
                id="4869973789"
                theme="bubble"
                readOnly
                className="dynamicStyle bubble2 card_description mt-1"
                value={data ? data["4869973789"] : "Lorem Ipsum"}
              />
              <div className="flex justify-center gap-4 mt-4">
                <FeatherIcon
                  icon="twitter"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="facebook"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="linkedin"
                  size={18}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="lg:flex md:flex sm-reverse items-center">
            <div className="lg:w-1/2 md:w-1/2 lg:px-8 md:px-8 px-4 lg:py-6 md:py-6 py-4 text-center">
              <p id="4508191924" className="dynamicStyle card_title_0">
                {data ? data["4508191924"] : "Lorem, ipsum"}
              </p>
              <p
                id="0618694301"
                className="dynamicStyle card_description text_surface400"
              >
                {" "}
                {data ? data["0618694301"] : "Lorem, ipsum"}
              </p>
              <ReactQuill
                id="0247560016"
                theme="bubble"
                readOnly
                className="dynamicStyle bubble2 card_description mt-1"
                value={data ? data["0247560016"] : "Lorem Ipsum"}
              />

              <div className="flex justify-center gap-4 mt-4">
                <FeatherIcon
                  icon="twitter"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="facebook"
                  size={18}
                  className="cursor-pointer"
                />
                <FeatherIcon
                  icon="linkedin"
                  size={18}
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div
              id="5267557480"
              className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
            >
              <Image
                src={data ? getImage(data["5267557480"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="h-300px sm-h-250px w-full object-contain rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team24;
