import { EditIcon } from '@chakra-ui/icons';
import { Button, Card, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { FaEdit } from 'react-icons/fa';

const MyProfile = () => {
  const { colorMode } = useColorMode();
  return (
    <div className="">
      <p
        className={
          colorMode === 'light'
            ? 'fsize20 md-fsize20 sm-fsize20 font-semibold text-black theme-textColor'
            : 'fsize20 md-fsize20 sm-fsize20 font-semibold cust-textColor theme-textColor'
        }
      >
        My Booking
      </p>
      <Card
        className={
          colorMode === 'light'
            ? 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative theme-bg themeShadow'
            : 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative border-4e5765 theme-border-0 theme-bg themeShadow'
        }
      >
        <div className="w-full flex items-center">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="profile-img"
            className="lg:w-20 md:w-16 w-12 lg:h-20 md:h-16 h-12 rounded-full object-contain"
          />
          <div className="ml-3">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black theme-textColor'
                  : 'fsize20 md-fsize18 sm-fsize16 font-medium  cust-textColor theme-textColor'
              }
            >
              Ronald Richards
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'fsize14 sm-fsize12 theme-textColor'
                  : 'fsize14 sm-fsize12 textColor-light theme-textColor'
              }
            >
              Mumbai, India.
            </p>
          </div>
        </div>
        {/* <div className="absolute top-3 right-3">
          <Button
            height="fit-content"
            py={2}
            px={4}
            className={
              colorMode === "light"
                ? "bg-primary sm-edit-btn clr-fff flex items-center gap-2"
                : "sm-edit-btn clr-fff flex items-center gap-2"
            }
          >
            <span className={colorMode === "light" ? "fsize14" : "fsize14"}>
              Edit
            </span>
            <FaEdit size={14} />
          </Button>
        </div> */}
      </Card>
    </div>
  );
};

export default MyProfile;
