import React, { useEffect } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { FacebookEmbed } from 'react-social-media-embed';
import { useDispatch } from 'react-redux';
const SocialFeeds = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <div className="container m-auto lg:pt-28 md:pt-20 pt-16 lg:px-12 md:px-8 px-5 lg:pb-12 md:pb-12 pb-6">
      <h2
        id="9010682333"
        className="dynamicStyle  fsize30 sm-fsize24 font-semibold cust-textColor"
      >
        {data ? data['9010682333'] : 'Lorem Ipsum'}
      </h2>

      <div className="lg:pt-8 md:pt-8 pt-6">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div 
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary rounded-xl p-4'
                : 'dynamicStyle cust-bgColor rounded-xl p-4'
            }>
              <FacebookEmbed className="mb-2 sm-h-350px" url="" />
              <div className="flex gap-2 items-center">
                <img
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="w-6 h-6 rounded-full"
                />
                <p className="fsize14 sm-fsize12 cust-textColor">Facebook</p>
              </div>
              <p className="sm-fsize14 text-white pt-2 textColor-light">
                Posted on 31 Jan,13:50
              </p>
              <p className="fsize20 sm-fsize18 font-semibold textColor-light">Post</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div 
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary rounded-xl p-4'
                : 'dynamicStyle cust-bgColor rounded-xl p-4'
            }>
              <FacebookEmbed className="mb-2 sm-h-350px" url="" />
              <div className="flex gap-2 items-center">
                <img
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="w-6 h-6 rounded-full"
                />
                <p className="fsize14 sm-fsize12 cust-textColor">Facebook</p>
              </div>
              <p className="sm-fsize14 text-white pt-2 textColor-light">
                Posted on 31 Jan,13:50
              </p>
              <p className="fsize20 sm-fsize18 font-semibold textColor-light">Post</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div 
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary rounded-xl p-4'
                : 'dynamicStyle cust-bgColor rounded-xl p-4'
            }>
              <FacebookEmbed className="mb-2 sm-h-350px" url="" />
              <div className="flex gap-2 items-center">
                <img
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="w-6 h-6 rounded-full"
                />
                <p className="fsize14 sm-fsize12 cust-textColor">Facebook</p>
              </div>
              <p className="sm-fsize14 text-white pt-2 textColor-light">
                Posted on 31 Jan,13:50
              </p>
              <p className="fsize20 sm-fsize18 font-semibold textColor-light">Post</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div 
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary rounded-xl p-4'
                : 'dynamicStyle cust-bgColor rounded-xl p-4'
            }>
              <FacebookEmbed className="mb-2 sm-h-350px" url="" />
              <div className="flex gap-2 items-center">
                <img
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="w-6 h-6 rounded-full"
                />
                <p className="fsize14 sm-fsize12 cust-textColor">Facebook</p>
              </div>
              <p className="sm-fsize14 text-white pt-2 textColor-light">
                Posted on 31 Jan,13:50
              </p>
              <p className="fsize20 sm-fsize18 font-semibold textColor-light">Post</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div className=" flex justify-center">
        <Button
          size="lg"
          colorScheme="primary"
          className="dynamicStyle rounded-full text-white cursor-pointer"
          id="5445323610"
        >
          {data ? data['5445323610'] : 'View All'}
        </Button>
      </div> */}
    </div>
  );
};

export default SocialFeeds;
