import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';
import { handleScroll } from './fadeeffect/FadeEffect';

const GallerySection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-1/2 md:w-1/2 w-full mx-auto">
          <p
            id="1934690016"
            className="dynamicStyle section_detail_0 text-center text-primary"
          >
            {data ? data['1934690016'] : 'Our Work'}
          </p>
          <h2
            id="3170971433"
            className="section_header dynamicStyle text-center"
          >
            {data ? data['3170971433'] : 'SBIF Jivanam Initiatives'}
          </h2>
        </div>

        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
        >
          <div
            id="3523188942"
            className="dynamicStyle lg:px-10 md:px-8 px-2 lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-6 md:grid-cols-3 grid-cols-1 gap-6"
          >
            {data && data['3523188942']
              ? data['3523188942'].map(e => {
                  return (
                    <Card
                      className=' bg_none shadow-none'
                    >
                      <div className="cus-hbanner w-full">
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          imageClassName="w-full mx-auto h-40 object-contain"
                          alt={e.field2}
                        />
                      </div>

                      <p className="card_title mt-2 text-center">
                        {e.field2}
                      </p>
                    </Card>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection3;
