import React, { useEffect, useRef, useState } from 'react';
import { FaLongArrowAltRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

const HeroSection30 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end

  return (
    <>
      <div className="relative w-full h-500px md-h-400px sm-h-400px" style={{ background: thisComponent.sectionColor }}>
        <div className="dynamicStyle2" id="1546004139">
          <Image
            src={data ? getImage(data["1546004139"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            imageClassName="w-full h-500px md-h-400px sm-h-400px object-cover"
            alt="hero_alt"
          />
        </div>
        <div className="h-500px md-h-400px sm-h-400px flex flex-col justify-center lg:pl-20 md:pl-12 px-5 absolute top-0 left-0 lg:w-1/2 md:w-1/2 w-full">
          <p id="7375843169" className="dynamicStyle hero_title clr-fff">
            {data ? data["7375843169"] : "Lorem Ipsum"}
          </p>
          <ReactQuill
            id="2742587260"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle card_description clr-fff"
            value={data ? data["2742587260"] : "Lorem Ipsum"}
          />
          <div className="mt-8">
            <Button outlined className="dynamicStyle px-6 py-2" id="5993517895">
              <span onClick={() => handleClick("5993517895")}>
                {data ? data["5993517895"] : "Find Coach"}
              </span>
              <FaLongArrowAltRight className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="lg:w-7/12 md:w-3/4 bg-272b3f lg:py-6 lg:px-16 md:py-4 md:px-8 flex">
          <div className="w-1/3 flex flex-col justify-center p-2">
            <p
              id="5911842676"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold clr-fff "
            >
              {data ? data["5911842676"] : "no"}
            </p>
            <ReactQuill
              id="6502104771"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["6502104771"] : "Lorem Ipsum"}
            />
          </div>
          <div className="verticalLine"></div>
          <div className="w-1/3 flex flex-col justify-center p-2">
            <p
              id="3548764222"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold clr-fff"
            >
              {data ? data["3548764222"] : "no"}
            </p>
            <ReactQuill
              id="2220244549"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["2220244549"] : "Lorem Ipsum"}
            />
          </div>
          <div className="verticalLine"></div>
          <div className="w-1/3 flex flex-col justify-center p-2">
            <p
              id="2626292616"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold clr-fff"
            >
              {data ? data["2626292616"] : "no"}
            </p>
            <ReactQuill
              id="5022318566"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["5022318566"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="lg:w-5/12 md:w-1/4 bg-secondary lg:py-6 lg:px-16 md:py-4 md:px-8">
          <div className="p-2">
            <p id="8771321010" className="dynamicStyle card_title">
              {data ? data["8771321010"] : "Lorem Ipsum"}
            </p>
            <p
              id="3121954239"
              className="dynamicStyle text-primary fsize16 sm-fsize14 flex items-center"
            >
              <span onClick={() => handleClick("3121954239")}>
                {data ? data["3121954239"] : "Find Coach"}
              </span>
              <FaLongArrowAltRight className="ml-2" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection30;
