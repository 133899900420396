import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard36 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 className="section_header dynamicStyle" id="3312352312">
              {data ? data['3312352312'] : 'Lorem Ipsum'}
            </h2>
            <ReactQuill
              id="0891245141"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['0891245141'] : 'Welcome to My World'}
            />
          </div>
          <div className="lg:mt-24 md:mt-20 mt-16">
            <div
              className="prime_card_container lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid lg:gap-4 gap-12 dynamicStyle"
              id="9798517613"
            >
              {data && data['9798517613']
                ? data['9798517613'].map((e, index) => {
                    return (
                      <div className="relative">
                        <Card className="prime_card w-full ">
                          <div className="lg:mt-12 md:mt-10 mt-8 text-center ">
                            <h4 className="card_title lineClamp2 ">
                              {e.field2}
                            </h4>
                            <ReactQuill
                              theme="bubble"
                              readOnly
                              className="bubble2 card_description"
                              value={e.field3}
                            />
                          </div>
                        </Card>
                        <div className="feature-absminus">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + e.field1}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            imageClassName="w-20 h-20 rounded-full object-contain"
                            alt="cards"
                          />
                        </div>
                      </div>
                    );
                  })
                : 'no-card'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard36;
