import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import UserEffect from './usereeffect/UserEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const AboutSection24 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center gap-4 sm-custdirection">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <p
              id="0925517395"
              className="dynamicStyle fsize16 sm-fsize14 font-medium text-primary"
            >
              {data ? data['0925517395'] : 'Call for more'}
            </p>
            <h2
              id="5986235996"
              className="dynamicStyle lg:w-2/3 md:w-4/5 w-full about_title"
            >
              {data
                ? data['5986235996']
                : 'Go Travel. Discover. Remember Us !!'}
            </h2>
            <ReactQuill
              id="1247637144"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data['1247637144'] : 'Lorem Ipsum'}
            />

            <Button
              className="dynamicStyle b_button_primary px-6 py-2 lg:mt-8 md:mt-8 mt-6 theme-btn fsize16 sm-fsize14"
              id="5722627836"
            >
              <span onClick={() => handleClick('5722627836')}>
                {data ? data['5722627836'] : 'Know More'}
              </span>
            </Button>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-6">
            <div
              ref={elementRef}
              className={`fade-right relative w-full h-400px sm-h300px ${
                isVisible ? 'visibleright' : ''
              } `}
            >
              <div className=" absolute top-0 lg:right-16 right-8 flex items-center h-400px sm-h300px">
                <div id="4562242879" className="dynamicStyle2">
                  <Image
                    src={data ? getImage(data['4562242879']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="hero"
                    imageClassName="h-300px sm-h250px w-400px sm-wid-90per ml-auto rounded-sm object-cover"
                    style={{ transform: transform }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              </div>
              <div className="bg-primary ml-auto w-200px h-400px sm-h300px rounded-sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection24;
