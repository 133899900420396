import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { FaInstagram, FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { FiYoutube } from "react-icons/fi";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Footer6 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  const isDarkMode = useSelector((state) => state.Loader.DarkMode);

  return (
    <section className={isDarkMode ? "bg_surface100" : "bg-secondary"}>
      <div className="container mx-auto lg:px-12 md:px-12 px-4 lg:py-10 py-4">
        <div className="lg:flex md:block justify-between gap-20">
          <div className="lg:w-1/2 md:w-full w-full">
            <div className="">
              <a href="/">
                <Image
                  src={getSecondaryImage(brandProfile)}
                  alt="footer-logo"
                  className="w-10rem  sm-mx-auto lg:pb-4 md:pb-4 pb-2"
                />
              </a>
              <div className="my-4">
                <div className=" pb-4">
                  <ReactQuill
                    id="9674233100"
                    theme="bubble"
                    readOnly
                    className="card_description bubble dynamicStyle"
                    value={data ? data["9674233100"] : "Lorem Ipsum"}
                  />
                  {/* <span className="font-semibold fsize14 mb-4">Read More</span> */}
                </div>
              </div>

              <div className="">
                <div className="flex gap-4 my-6">
                  <a href="/">
                    <div className="bg-d9d9d9 p-4 rounded-full">
                      <FiPhoneCall className="w-4 h-4 text-secondary" />
                    </div>
                  </a>
                  <div className="">
                    <p
                      id="2629225125"
                      className="theme-textColor dynamicStyle font-semibold fsize16"
                    >
                      {data ? data["2629225125"] : "Lorem ipsum"}
                    </p>
                    <a href="tel:123-456-7890">
                      <span
                        id="7498286922"
                        className=" theme-textColor dynamicStyle text-white fsize16 sm-fsize14"
                      >
                        {data ? data["7498286922"] : "Lorem ipsum"}
                      </span>
                    </a>
                  </div>
                </div>
                <div className="flex gap-4 my-6">
                  <a href="/">
                    <div className="bg-d9d9d9 p-4 rounded-full">
                      <AiOutlineMail className="w-4 h-4 text-secondary" />
                    </div>
                  </a>
                  <div className="">
                    <p
                      id="6467288113"
                      className="dynamicStyle theme-textColor font-semibold fsize16"
                    >
                      {data ? data["6467288113"] : "Lorem ipsum"}
                    </p>
                    <span
                      href="/"
                      id="0998029806"
                      className="dynamicStyle theme-textColor text-white fsize16 sm-fsize14"
                    >
                      {data ? data["0998029806"] : "unreal@outlook.com"}
                    </span>
                  </div>
                </div>
                <div className="flex gap-4 my-6">
                  <a href="/">
                    <div className="bg-d9d9d9 p-4 rounded-full ">
                      <FaMapMarkerAlt className="w-4 h-4 text-secondary" />
                    </div>
                  </a>
                  <div className="">
                    <p
                      id="5666190428"
                      className="dynamicStyle theme-textColor font-semibold fsize16"
                    >
                      {data ? data["5666190428"] : "unreal@outlook.com"}
                    </p>
                    <span
                      href="/"
                      id="3025676443"
                      className="dynamicStyle theme-textColor text-white fsize16 sm-fsize14"
                    >
                      {data
                        ? data["3025676443"]
                        : "706 Comfire Ave. Meriden, CT 06450"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-full lg:pl-12 ">
            <div className="footer-menu lg:flex  justify-between w-full lg:px-0 md:px-0 px-5">
              <div className="">
                <h3
                  id="0825199499"
                  className="dynamicStyle theme-textColor fsize18 font-semibold lh24px"
                >
                  {data ? data["0825199499"] : "Lorem ipsum"}
                </h3>
                <ul className="lg:mt-4 md:mt-4 mt-2 lg:mb-4 md:mb-4 mb-2">
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      About us
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Blog
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Carrers
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Jobs
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      In Press
                    </li>{" "}
                  </a>
                </ul>
              </div>
              <div className="">
                <h3
                  id="9843879610"
                  className="dynamicStyle theme-textColor fsize18 font-semibold"
                >
                  {data ? data["9843879610"] : "Lorem ipsum"}
                </h3>
                <ul className="lg:mt-4 md:mt-4 mt-2 lg:mb-4 md:mb-4 mb-2">
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Contact us
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Online Chat
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Whatsapp
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Telegram
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Ticketing
                    </li>{" "}
                  </a>
                </ul>
              </div>
              <div className="">
                <h3
                  id="5292048526"
                  className="dynamicStyle theme-textColor fsize18 font-semibold"
                >
                  {data ? data["5292048526"] : "Lorem ipsum"}
                </h3>
                <ul className="lg:mt-4 md:mt-4 mt-2 lg:mb-4 md:mb-4 mb-2">
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Account
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Manage Deliveries
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Orders
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Payments
                    </li>{" "}
                  </a>
                  <a href="/">
                    <li className="fsize14 theme-textColor lh24px py-2 text-white cursor-pointer">
                      Returns
                    </li>{" "}
                  </a>
                </ul>
              </div>
            </div>
            <div className="lg:flex md:flex justify-between items-center mt-4">
              <div className="icons flex gap-2 my-4 sm-center">
                <a href="/">
                  <div className="bg-d9d9d9 lg:p-4 md:p-4 p-2 rounded-full">
                    <FaWhatsapp className="w-4 h-4 text-secondary" />
                  </div>
                </a>
                <a href="/">
                  <div className="bg-d9d9d9 lg:p-4 md:p-4 p-2 rounded-full">
                    <FaInstagram className="w-4 h-4 text-secondary" />
                  </div>
                </a>
                <a href="/">
                  <div className="bg-d9d9d9 lg:p-4 md:p-4 p-2 rounded-full">
                    <TiSocialFacebookCircular className="w-4 h-4 text-secondary" />{" "}
                  </div>
                </a>
                <a href="/">
                  <div className="bg-d9d9d9 lg:p-4 md:p-4 p-2 rounded-full">
                    <FiYoutube className="w-4 h-4 text-secondary" />
                  </div>
                </a>
              </div>
              <span
                id="7579152721"
                className="dynamicStyle text-white fsize14 sm-center"
              >
                {data ? data["7579152721"] : "@2020-2021, All Rights Reserved."}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer6;
