import { Image } from '@chakra-ui/react';
import { Button } from "primereact/button";
import React from 'react';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const Services2 = () => {
  return (
    <section className="lg:flex md:flex sm-reverse w-full ">
      <div className="lg:w-1/2 md:w-1/2 sm-reverse">
        <div className="lg:px-20 lg:px-20 px-5 lg:py-16 md:py-16 py-10">
          <h2 className="fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold">
            Services
          </h2>
          <p className="lg:mt-20 md:mt-20 mt-8 lg:mb-6 md:mb-6 mb-4 fsize16 md-fsize16 sm-fsize14 font-medium cust-textColor lineClamp3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Consequuntur doloremque nostrum soluta assumenda, ipsam obcaecati
            fugiat dignissimos a expedita quasi. Consequuntur doloremque nostrum
            soluta assumenda, ipsam obcaecati fugiat dignissimos a expedita
            quasi.
          </p>
          <Button className="theme-btn2 b_button_primary px-6 py-2">
            Know More <FaLongArrowAltRight className="ml-2" />
          </Button>
        </div>
        <div className="flex items-center w-full">
          <div className="w-1/2 flex items-center justify-center bg-primary lg:py-8 md:py-8 py-4">
            <FaLongArrowAltLeft className="clr-fff fsize42" />
          </div>
          <div className="w-1/2 flex items-center justify-center bg-secondary lg:py-8 md:py-8 py-4">
            <FaLongArrowAltRight className="clr-fff fsize42" />
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 md:w-1/2 h-500px sm-h-250px">
        <Swiper
          spaceBetween={25}
          slidesPerView={3.2}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className=""
          autoplay={{
            delay: '1000',
          }}
          loop
          navigation={{
            nextEl: '',
            prevEl: '',
          }}
          breakpoints={{
            1536: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1280: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            991: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            767: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
        >
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/auto1.png"
              alt=""
              className="w-full h-500px sm-h-250px object-cover"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/auto2.png"
              alt=""
              className="w-full h-500px sm-h-250px object-cover"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/auto3.png"
              alt=""
              className="w-full h-500px sm-h-250px object-cover"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/auto4.png"
              alt=""
              className="w-full h-500px sm-h-250px object-cover"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Services2;
