import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection25 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-4 md:gap-6 pt-20">
          <div
            id="1648726064"
            className="dynamicStyle2 h-450px sm-h300px relative lg:pr-10"
          >
            <Image
              imageClassName="h-full w-full rounded-2xl object-cover"
              src={data ? getImage(data['1648726064']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
            <div className="bg-f4f4f4 absolute-mid w-80 p-4 rounded-lg ">
              <p id="5786856920" className="dynamicStyle card_title">
                {data ? data['5786856920'] : 'Architect'}
              </p>
              <ReactQuill
                id="6476984792"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data['6476984792'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
          <div className="lg:pl-10 lg:mt-0 md:mt-0 mt-6">
            <h2
              id="6663936773"
              className="dynamicStyle about_title text-primary"
            >
              {data ? data['6663936773'] : 'Lorem ipsum dolor sit amet.'}
            </h2>
            <ReactQuill
              id="1017950859"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['1017950859'] : 'Lorem Ipsum'}
            />
            <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-10 md:gap-6 gap-6 lg:py-4 md:py-4 py-4">
              <div>
                <p id="4802281203" className="dynamicStyle card_title_0">
                  {data ? data['4802281203'] : '10+'}
                </p>
                <p id="3132396579" className="dynamicStyle card_description">
                  {data ? data['3132396579'] : 'Lorem ipsum dolor sit amet.'}
                </p>
              </div>
              <div>
                <p id="5406390175" className="dynamicStyle card_title_0">
                  {data ? data['5406390175'] : '5+'}
                </p>
                <p id="4911598353" className="dynamicStyle card_description">
                  {data ? data['4911598353'] : 'Lorem ipsum dolor sit amet.'}
                </p>
              </div>
              <div>
                <p id="1676174657" className="dynamicStyle card_title_0">
                  {data ? data['1676174657'] : '500+'}
                </p>
                <p id="6076903550" className="dynamicStyle card_description">
                  {data ? data['6076903550'] : 'Lorem ipsum dolor sit amet.'}
                </p>
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <Button
                id="8646420910"
                className="dynamicStyle b_button_primary px-6 py-2 theme-btn sm-fsize14"
              >
                <span onClick={() => handleClick('8646420910')}>
                  {data ? data['8646420910'] : 'Button'}
                </span>
              </Button>
              <Button
                outlined
                id="6111495158"
                className="dynamicStyle border-primary theme-btn sm-fsize14 px-6 py-2"
              >
                <span onClick={() => handleClick('6111495158')}>
                  {data ? data['6111495158'] : 'Button'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection25;
