import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import {Image} from 'primereact/image'
import { useDispatch } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';

const Testimonial2 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'getAllTestimonial');
  useEffect(() => {
    dispatch(getTestimonal());

    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <>
      {getAllTestimonial ? (
        <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:w-3/5 md:w-3/4 mx-auto text-center w-full flex flex-col justify-center lg:pt-8 md:pt-8  lg:mb-12 md:mb-12 mb-8 ">
            <h3
              id="3637674290"
              className="dynamicStyle fsize16 md-fsize14 sm-fsize16 font-medium text-primary cust-textColor"
            >
              {data ? data['3637674290'] : 'Lorem Ipsum'}
            </h3>

            <h2 id="2333017546" className="dynamicStyle section_header">
              {data ? data['2333017546'] : 'Lorem Ipsum'}
            </h2>
            <div className="">
              <ReactQuill
                id="6834492840"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle section_detail"
                value={data ? data['6834492840'] : 'Welcome to My World'}
              />
            </div>
          </div>

          <div
            ref={elementRef}
            className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
          >
            <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 lg:gap-4 md:gap-4 gap-3 ">
              {getAllTestimonial
                ? getAllTestimonial?.data?.result.map(testimonial => {
                    return (
                      <Card className="prime_card themeShadow-insert theme-radius themeShadow">
                        <div className="w-full lg:text-left md:text-left text-center flex ">
                          <div className="testimonial-div1 ">
                            <Image
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                              alt={testimonial.name}
                              imageClassName="testimonial-img rounded-full object-cover"
                            />
                          </div>
                          <div className="w-full text-left lg:mt-0 md:mt-0 mt-2">
                            {/* <h2 className="fsize24 sm-fsize16 font-semibold ">
                              {`${
                                testimonial.firstName.charAt(0).toUpperCase() +
                                testimonial.firstName.slice(1)
                              }` +
                                "\n" +
                                `${testimonial.lastName}`}
                            </h2> */}
                            <h4 className="card_title_0">
                              {testimonial.name.charAt(0).toUpperCase() +
                                testimonial.name.slice(1)}
                            </h4>
                            <div className="flex justify-between items-center ">
                              {/* <p className="fsize18 sm-fsize14">
                                CEO & Co Founder
                              </p> */}
                              <Rating
                                initialRating={4}
                                readonly={true}
                                emptySymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1 textColor-light"
                                    size={22}
                                  />
                                }
                                fullSymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1 fillstroke"
                                    size={22}
                                  />
                                }
                                fractions={2}
                              />
                            </div>
                          </div>
                        </div>
                        <p className="lg:text-left md:text-justify text-center card_description lineClamp5">
                          {testimonial.testimonial}
                        </p>
                      </Card>
                    );
                  })
                : 'No Data'}
            </div>
          </div>
        </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial2;
