import React from "react";
import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button } from 'primereact/button';
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const ViewCoachPopUp = ({ isOpen, onClose }) => {
  return (
    <div>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div className="">
              <div className="w-full relative">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="user-background"
                  className="lg:rounded-t-lg md:rounded-t-lg w-full object-cover h-200px "
                />
                <ModalCloseButton className="cursor-pointer absolute right-0 top-0 w-6 h-6 edit_icon  p-1 rounded-full" />
              </div>
              <div className="lg:flex md:flex justify-between items-center pb-6 minus3rem-mtop">
                <div className="flex items-center gap-2">
                  <div className="py-2 relative w-max lg:mx-0 md:mx-0 mx-auto lg:ml-6">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="profile-pic"
                      className="w-32 h-32 rounded-full bg-6f6f6f text-center border3px-fff"
                    />
                  </div>
                  <div className="mt-4">
                    <h2 className="fsize16 font-semibold uppercase">
                      manish sharma
                    </h2>
                    <p className="fsize14 font-semibold text-gray uppercase">
                      Woodland
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 justify-center mt-4 mr-4">
                  <Button height={8} className="b_button_primary px-6 py-2">
                    Accept
                  </Button>
                </div>
              </div>
              <div className="">
                <div className="py-4 flex justify-between border-t-1 border-b-1 w-full">
                  <h2 className="fsize16 sm-fsize12 font-semibold w-20">
                    Biography
                  </h2>
                  <p className="fsize14 sm-fsize12 w-70">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Suscipit sit unde iusto quisquam dolorem ullam eveniet
                    natus. Excepturi suscipit nostrum reprehenderit? Harum
                    inventore amet dolore a recusandae labore sunt totam in
                    minus expedita officiis eligendi, suscipit accusantium odit
                    qui quidem!
                  </p>
                </div>
                <div className="py-4 flex justify-between border-b-1 w-full">
                  <h2 className="fsize16 sm-fsize12 font-semibold w-20">
                    Education
                  </h2>
                  <div className="fsize14 sm-fsize12 w-70">
                    <p className="fsize14 sm-fsize12">
                      Hardward University, M.S. in Pyshics
                    </p>
                    <p className="fsize14 sm-fsize12">
                      Hardward University, M.S. in Pyshics
                    </p>
                  </div>
                </div>
                <div className="py-4 flex justify-between border-b-1 w-full">
                  <h2 className="fsize16 sm-fsize12 font-semibold w-20">
                    Subjects
                  </h2>
                  <div className="fsize14 flex gap-4 w-70">
                    <p className="text-gray bg-d9d9d9 py-1 px-2 fsize11 font-medium rounded-full">
                      subject 1
                    </p>
                    <p className="text-gray bg-d9d9d9 py-1 px-2 fsize11 font-medium rounded-full">
                      subject 2
                    </p>
                  </div>
                </div>
                <div className="py-4 flex justify-between border-b-1 w-full">
                  <h2 className="fsize16 sm-fsize12 font-semibold w-20">
                    Experience
                  </h2>
                  <div className="fsize14 sm-fsize12 w-70">
                    <p className="fsize14 sm-fsize12">
                      Hardward University, M.S. in Pyshics
                    </p>
                    <p className="fsize14 sm-fsize12">
                      Hardward University, M.S. in Pyshics
                    </p>
                  </div>
                </div>
                <div className="py-4 flex justify-between border-b-1 w-full">
                  <h2 className="fsize16 sm-fsize12 font-semibold w-20">
                    Rate
                  </h2>
                  <div className="fsize14 sm-fsize12 w-70">
                    <p className="fsize14 sm-fsize12">$100 per hour</p>
                  </div>
                </div>
                <div className="py-4 flex justify-between border-b-1 w-full">
                  <h2 className="fsize16 sm-fsize12 font-semibold w-20">
                    Location
                  </h2>
                  <div className="fsize14 sm-fsize12 w-70">
                    <p className="fsize14 sm-fsize12">Line 1,</p>
                    <p className="fsize14 sm-fsize12">Line 2,</p>
                    <p className="fsize14 sm-fsize12">Line 3</p>
                    <Button
                      variant="link"
                      className="fsize14 mt-1 b_button_primary px-6 py-2"
                    >
                      Get Directions
                    </Button>
                  </div>
                </div>
                <div className="py-4 flex justify-between items-center border-b-1 w-full">
                  <h2 className="fsize16 sm-fsize12 font-semibold w-20">
                    Social Media
                  </h2>
                  <div className="fsize14 flex gap-2 w-70">
                    <FaInstagram />
                    <FaFacebookF />
                  </div>
                </div>
                <div className="py-4 flex justify-end  w-full">
                  <Button 
                    variant="link"
                    className="fsize14 mt-1 b_button_primary px-6 py-2"
                  >
                    View full profile
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ViewCoachPopUp;
