import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';

const Boardofdirectors1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <h2
          id="5301296068"
          className="dynamicStyle header_align section_header"
        >
          {data ? data['5301296068'] : 'board of directors'}
        </h2>

        <div
          ref={elementRef}
          className={`fade-bottom lg:mt-16 md:mt-16 mt-12 lg:grid-cols-4 md:grid-cols-3 grid-cols-1 grid gap-4 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="6646800574"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['6646800574']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName=" lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="6618227786"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['6618227786'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="0106369722"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['0106369722']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="5373088643"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['5373088643']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="3184116159"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['3184116159'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="2722677220"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['2722677220']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="1472962995"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['1472962995']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="6632170342"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['6632170342'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="0629784039"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['0629784039']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="4785675785"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['4785675785']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="1916580931"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['1916580931'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="1561618838"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['1561618838']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="5588355484"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['5588355484']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="5191643203"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['5191643203'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="7719084108"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['7719084108']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="0100818139"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['0100818139']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="9990358302"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['9990358302'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="5308582803"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['5308582803']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="8384277288"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['8384277288']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="9675401558"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['9675401558'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="2084962983"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['2084962983']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card className="mx-auto box-shadow-none themeShadow theme-bg">
            <div
              id="1991125682"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['1991125682']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="4057918763"
              className="dynamicStyle mt-3 card_title_0 text-center"
            >
              {data ? data['4057918763'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="8203854310"
              className="dynamicStyle text-center card_description mb-2"
            >
              {data
                ? data['8203854310']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Boardofdirectors1;
