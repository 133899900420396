import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard11 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const data = useSelector(state => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-14 md:py-16 py-8" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2
              id="6672311445"
              className="dynamicStyle cust-textColor section_header relative"
            >
              {data ? data['6672311445'] : 'Welcome to my world'}
            </h2>
            <ReactQuill
              id="0870439583"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['0870439583'] : 'Welcome to My World'}
            />
          </div>
          <div id="3303680949" className="dynamicStyle">
            <div
              ref={elementRef}
              className={`fade-bottom lg:mt-16 md:mt-16 mt-12 w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 ${
                isVisible ? 'visiblebottom' : ''
              } `}
            >
              {data && data['3303680949']
                ? data['3303680949'].map(e => {
                    return (
                      <Card className="item-card1 item-card11 cust-shadow theme_insertShadow theme-bg">
                        <div className="">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt={e.field2}
                            imageClassName="lg:w-20 lg:w-20 w-12 h-12 lg:mb-3 md:mb-3 mb-2 object-contain"
                          />
                        </div>

                        <div className="">
                          <h3 className="card_title_0">{e.field2}</h3>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble card_description mt-1"
                            value={e.field3}
                          />
                        </div>
                      </Card>
                    );
                  })
                : 'no-card'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard11;
