import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard48 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end


  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <p id="2489294176" className="dynamicStyle section_header">
            {data ? data['2489294176'] : 'Lorem ipsum'}
          </p>
          
            <ReactQuill
              id="9041954857"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['9041954857'] : 'Welcome to My World'}
            />
        </div>
        <div
          id="1998911765"
          className="dynamicStyle lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4"
        >
          {data && data['1998911765']
            ? data['1998911765'].map(e => {
                return (
                  <div
                    key={e._id}
                    className="relative lg:h-72 md:h-72 h-64 w-full gradient overflow-hidden rounded-lg"
                  >
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL + e.field1}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="img"
                      imageClassName="lg:h-72 md:h-72 h-64 w-full rounded-lg object-cover"
                    />
                    <div className="py-4 px-2 flex justify-between gap-2 items-center z-20 absolute left-0 bottom-0 rounded-b-lg w-full">
                      <div className="flex items-center gap-2 w-11/12">
                       <div className='w-2/12'>
                         <Image
                          src={process.env.REACT_APP_STORAGE_URL + e.field2}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img"
                          imageClassName="h-10 w-10 rounded-full object-contain"
                        />
                       </div>
                        <div className='w-10/12'>
                          <p className="card_title_0 clr-fff">{e.field3}</p>
                          <p className="card_description clr-fff lineClamp1">
                            {e.field4}
                          </p>
                          <p className="fsize14 sm-fsize12 clr-fff">{e.field5}</p>
                        </div>
                      </div>
                      <div className='w-1/12'>
                        <FeatherIcon
                          icon="more-horizontal"
                          size={24}
                          className='cursor-pointer clr-fff'
                              
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard48;
