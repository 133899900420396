import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection9 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="flex cust-reverse items-center">
          <div className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4 lg:pr-10">
            <h2 className="about_title dynamicStyle" id="3725827689">
              {data ? data['3725827689'] : 'Lorem Ipsum'}
            </h2>
            <ReactQuill
              id="0414522803"
              theme="bubble"
              readOnly
              className="card-description bubble dynamicStyle"
              value={data ? data['0414522803'] : 'Lorem Ipsum'}
            />
          </div>
          <div className="lg:w-6/12 md:w-6/12 w-full lg:pl-10">
            <div
              ref={elementRef}
              className={`fade-right cus-hbanner w-full ${
                isVisible ? 'visibleright' : ''
              } `}
            >
              <div id="6162638972" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data['6162638972']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="creating"
                  imageClassName="creating-img rounded-lg object-cover"
                  style={{ transform: transform }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection9;
