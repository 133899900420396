import React from 'react';
import {
  FormErrorMessage,
  FormControl,
  Input,
  InputRightElement,
  InputGroup,
  useColorMode,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { addNewsletter } from '../../../redux/newsletter/newsletter-actions';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function NewsletterSection2({ pageName }) {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();
  const initialValues = {
    email: '',
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, 'Email must be at least 5 characters')
      .max(50, 'Email must be at most 100 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
  });

  const handleOnSubmit = async values => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: 'Newsletter',
          text: `Subscribed to newsletter`,
          icon: 'success',
          timer: '2500',
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className={
          colorMode === 'light'
            ? 'text-center lg:py-10 md:py-10 py-8 bg-primary'
            : 'text-center lg:py-10 md:py-10 py-8 bg-darkprimary'
        }
      >
        <div class="text-center lg:w-3/5 md:w-4/5 w-full mx-auto lg:px-0 md:px-0 px-5">
          <h2
            id="6486326374"
            className="dynamicStyle text-2xl font-semibold cust-textColor"
          >
            {data ? data['6486326374'] : 'Newsletter'}
          </h2>
          <ReactQuill
              id="8753265741"
              theme="bubble"
              readOnly
              className='bubble2-w dynamicStyle mt-2'
              value={data ? data['8753265741'] : 'Lorem Ipsum'}
            />
        </div>

        {/* <div class="relative lg:w-3/5 md:w-3/5 mx-auto mt-8">
          <div class="absolute top-4 lg:left-6 md:left-6 left-4">
            <feather-icon icon="MailIcon" svgClasses="w-5 h-5" />
          </div>
          <Input
            placeholder="Enter Email for exclusive investment deals and offers."
            class="bg-secondary button-h50px lg:pl-16 md:pl-16 pl-12 text-xs"
          ></Input>

          <Button
            variant="primary"
            size="sm"
            class="font-normal lg:absolute lg:mt-0 md:mt-0 mt-2 md:absolute top-0 lg:right-0 right-0 lg:px-10 md:px-8 px-8 lg:py-2 md:py-2 py-3"
          >
            Join Now
          </Button>
        </div> */}
        <div className="lg:w-3/5 md:w-4/5 w-full mx-auto py-6 lg:px-0 md:px-0 px-5">
          <FormControl
            isInvalid={!!formik.errors.email && formik.touched.email}
          >
            <InputGroup size="md">
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                pr="4.5rem"
                placeholder="Enter Email for exclusive investment deals and offers."
                _placeholder={{ opacity: 1, color: 'white' }}
              />
              <InputRightElement width="6rem">
                <Button
                  h="2.4rem"
                  size="md"
                  type="submit"
                  className='b_button_secondary px-6 py-2 rounded-lg fsize14 theme-btn dynamicStyle'
                     
                  id="7109993935"
                >
                  {data ? data['7109993935'] : 'Register to Join'}
                </Button>
              </InputRightElement>
            </InputGroup>
            {formik.touched.email && formik.errors.email && (
              <FormErrorMessage className="textwhite">
                {formik.errors.email}
              </FormErrorMessage>
            )}
          </FormControl>
        </div>
      </form>
    </div>
  );
}

export default NewsletterSection2;
