import React, { useEffect, useRef, useState } from "react";
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux/es/exports';

const GallerySection12 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex block gap-4">
          <div className="lg:w-6/12 md:w-6/12 w-full lg:flex md:flex block gap-2">
            <div className="lg:w-1/12 md:w-1/12 w-full">
              <h4
                id="6465652270"
                className="section_header dynamicStyle rotate-text h-gallery12"
              >
                {data ? data['6465652270'] : 'Lorem Ipsum'}
              </h4>
            </div>
            <div
              id="2634852265"
              className="dynamicStyle2 lg:w-11/12 md:w-11/12 w-full lg:mt-0 md:mt-0 mt-2"
            >
              <Image
                src={data ? getImage(data['2634852265']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-gallery12 w-full object-cover"
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 lg:w-6/12 md:w-6/12 w-full lg:mt-0 md:mt-0 mt-4">
            <div id="3555364007" className="dynamicStyle2 h-minigallery12">
              <Image
                src={data ? getImage(data['3555364007']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-minigallery12 w-full object-cover"
              />
            </div>
            <div
              id="0176194854"
              className="dynamicStyle2 relative h-minigallery12"
            >
              <Image
                src={data ? getImage(data['0176194854']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-minigallery12 w-full object-cover"
              />
              <div className="absolute top-0 left-0 mt-4">
                <div className="bg-primary cust-bgColor px-4 py-1">
                  <p className="fsize12 clr-fff uppercase cust-textColor">
                    Sale
                  </p>
                </div>
              </div>
            </div>
            <div id="9959928993" className="dynamicStyle2 h-minigallery12">
              <Image
                src={data ? getImage(data['9959928993']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-minigallery12 w-full object-cover"
              />
            </div>
            <div id="6878013588" className="dynamicStyle2 h-minigallery12">
              <Image
                src={data ? getImage(data['6878013588']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-minigallery12 w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection12;
