import React, { useEffect, useRef, useState } from "react";
import { FaQuoteRight } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const Testimonial17 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);

  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);

  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div class="text-center w-70 mx-auto">
          <p
            id="0091393439"
            className="dynamicStyle sm-fsize14 fsize16 text-primary"
          >
            {data ? data["0091393439"] : "Collections"}
          </p>
          <h2 id="9986348712" class="dynamicStyle section_header">
            {data ? data["9986348712"] : "Collections"}
          </h2>
        </div>
        <div>
          {" "}
          {getAllTestimonial ? (
            <div className="lg:flex md:grid md:grid-cols-2 grid grid-cols-1 w-full testimonial17 justify-center flex-wrap gap-4">
              {getAllTestimonial
                ? getAllTestimonial?.data?.result.map((testimonial, index) => {
                    return (
                      <Card
                        className="prime_card lg:mt-10 md:mt-16 mt-10 lg:mb-12 md:mb-6 mb-4 relative themeShadow-insert theme-bg lg:w-1/4 md:w-full"
                        key={index}
                      >
                        <ReactStars count={5} size={24} activeColor="#ffd700" />
                        <div className="pt-3">
                          <div className="flex gap-2">
                            <div className="lg:h-32 md:h-32 h-32 w-90per">
                              <p className=" card_description mt-1 lineClamp5">
                                {testimonial.testimonial}
                              </p>
                            </div>

                            <FaQuoteRight
                              size="23"
                              className="text-primary cust-textColor"
                            />
                          </div>
                          <h4 className="card_title mt-3 lg:mb-10 md:mb-8 cust-textColor">
                            {testimonial.name}
                          </h4>
                        </div>
                        <div
                          key={index}
                          className={
                            index % 2 === 0 ? "reviewcard" : "reviewcard2"
                          }
                        >
                          <Image
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                            alt={testimonial.name}
                            imageClassName="rounded-full img-wh-80 object-cover"
                          />
                        </div>
                      </Card>
                    );
                  })
                : "No Data"}
            </div>
          ) : (
            <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
              <div className=" lg:flex md:flex item-center lg:gap-8 md:gap-8 gap-4">
                <h2>No Data</h2>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};
export default Testimonial17;
