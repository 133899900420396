import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";

const HeroSectionSwiper3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    const cleanp2 = handleScroll(elementRef2, setIsVisible2);

    return () => {
      cleanp1();
      cleanp2();
    };
  }, []);
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end
  return (
    <div className="" style={{ background: thisComponent.sectionColor }}>
      <Swiper
        observer={true}
        observeParents={true}
        modules={[Navigation]}
        navigation={{
          nextEl: ".image-swiper-button-next",
          prevEl: ".image-swiper-button-prev",
        }}
        className="mySwiper"
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
        }}
      >
        <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </div>
        <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
          </svg>
        </div>
        <SwiperSlide>
          <div className="w-full flex sm-col-rev">
            <div className="lg:w-3/5 md:w-3/5">
              <div className="flex items-center h-580px">
                <div
                  ref={elementRef}
                  className={`fade-left lg:px-28 md:px-6 px-5 ${
                    isVisible ? "visibleleft" : ""
                  } `}
                >
                  <h2
                    id="0233986691"
                    className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary sm-pb-1"
                  >
                    {data ? data["0233986691"] : "Apple"}
                  </h2>
                  <h2 id="2009855363" className="dynamicStyle hero_title">
                    {data ? data["2009855363"] : "Lorem ipsum dolor sit amet."}
                  </h2>
                  <ReactQuill
                    id="7453192840"
                    theme="bubble"
                    readOnly
                    className="bubble card_description dynamicStyle"
                    value={data ? data["7453192840"] : "Lorem Ipsum"}
                  />

                  <div className="lg:mt-8 md:mt-8 mt-6">
                    <Button
                      className="dynamicStyle theme-btn sm-fsize14 font-semibold b_button_primary px-6 py-2"
                      id="2657911507"
                    >
                      <span onClick={() => handleClick("2657911507")}>
                        {data ? data["2657911507"] : "Explore more"}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-2/5 md:w-2/5">
              <div id="7373745778" className="dynamicStyle2 h-580px w-full">
                <Image
                  src={data ? getImage(data["7373745778"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="banner-image"
                  imageClassName="h-580px bg-primary object-cover sm-w-full"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full flex sm-col-rev">
            <div className="lg:w-3/5 md:w-3/5">
              <div className="flex items-center h-580px">
                <div
                  ref={elementRef2}
                  className={`fade-left lg:px-28 md:px-6 px-5 ${
                    isVisible2 ? "visibleleft" : ""
                  } `}
                >
                  <h2
                    id="4072300141"
                    className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary sm-pb-1"
                  >
                    {data ? data["4072300141"] : "Apple"}
                  </h2>
                  <h2 id="0546314992" className="dynamicStyle hero_title">
                    {data
                      ? data["0546314992"]
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
                  </h2>
                  <ReactQuill
                    id="7966591813"
                    theme="bubble"
                    readOnly
                    className="bubble card_description dynamicStyle"
                    value={data ? data["7966591813"] : "Lorem Ipsum"}
                  />

                  <div className="lg:mt-8 md:mt-8 mt-6">
                    <Button
                      className="dynamicStyle px-6 py-2 theme-btn sm-fsize14 font-semibold b_button_primary"
                      id="9523888296"
                    >
                      <span onClick={() => handleClick("9523888296")}>
                        {data ? data["9523888296"] : "Explore more"}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-2/5 md:w-2/5">
              <div id="6702210812" className="dynamicStyle2 h-580px w-full">
                <Image
                  src={data ? getImage(data["6702210812"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="banner-image"
                  imageClassName="h-580px bg-primary object-cover sm-w-full"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeroSectionSwiper3;
