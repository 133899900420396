import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';
import Aos from "aos";
import "aos/dist/aos.css";


const FeatureCard40 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
      <div className="">
        <div className="header_align">
          <h2 className="section_header dynamicStyle" id="0700591822">
            {data ? data['0700591822'] : 'Lorem Ipsum'}
          </h2>
          <ReactQuill
            id="8752039412"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['8752039412'] : 'Welcome to My World'}
          />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8">
          <div
            className="lg:grid-cols-2 md:grid-cols-2 grid-cols-1 grid gap-4 dynamicStyle lg:mt-16 md:mt-16 mt-12"
            id="2647627700"
          >
            {data && data['2647627700']
              ? data['2647627700'].map((e, index) => {
                  return (
                    <Card className="p-5 rounded-lg cust-shadow">
                      <div className="flex items-center gap-2">
                        <Image
                          src={process.env.REACT_APP_STORAGE_URL + e.field1}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          imageClassName="lg:w-16 lg:h-16 w-12 h-12 rounded-lg object-contain"
                          alt="img"
                        />
                        <h4 className="fsize26 md-fsize22 sm-fsize20 cust-textColor lineClamp2 font-semibold text-primary text-center">
                          {e.field2}
                        </h4>
                      </div>
                      <div className="mt-4">
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble"
                          value={e.field3}
                        />
                      </div>
                    </Card>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FeatureCard40;
