import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

const AddBanner10 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  return (
    <section
      className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="relative h-400px">
          <div className="dynamicStyle2 w-full" id="9214898694">
            <Image
              src={data ? getImage(data["9214898694"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              imageClassName="w-full h-400px object-cover rounded-xl"
              alt="application"
            />
          </div>

          <div className="absolute top-0 left-0 flex items-center h-400px w-full">
            <div className="lg:px-20 md:px-10 px-5 lg:w-3/4 md:w-3/4 w-4/5">
              <div className=" ">
                <h3
                  className="hero_title text-left dynamicStyle clr-fff"
                  id="1017892177"
                >
                  {data ? data["1017892177"] : "Lorem Ipsum"}
                </h3>

                <ul>
                  <li className="ml-8 clr-fff">
                    <span
                      id="3546649822"
                      className="dynamicStyle fsize24 sm-fsize18 font-medium clr-fff"
                    >
                      {data ? data["3546649822"] : "Lorem Ipsum"}
                    </span>
                  </li>
                  <li className="ml-8 clr-fff">
                    <span
                      id="2545835504"
                      className="dynamicStyle fsize24 sm-fsize18 font-medium clr-fff"
                    >
                      {data ? data["2545835504"] : "Lorem Ipsum"}
                    </span>
                  </li>
                </ul>
                <div className="lg:mt-10 md:mt-10 mt-6">
                  <Button
                    className="sm-fsize14 dynamicStyle b_button_primary px-6 py-4 font-semibold"
                    id="4254629048"
                  >
                    {data ? data["4254629048"] : "Lorem Ipsum"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBanner10;
