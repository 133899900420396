import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import React, { useEffect, useRef, useState } from 'react';
import { FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { FiMail, FiMapPin } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'sweetalert2/src/sweetalert2.scss';
import { createContact } from '../../../redux/marcom/marcom-action';

const ContactDetails4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const initialValues = {
    name: '',
    email: '',
    description: '',
  };

  const validationRules = Yup.object({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .max(25, 'Name must be at most 25 characters')
      .required('Name is required')
      .matches(/^[a-zA-Z ]*$/, 'Only alphabets is allowed'),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, 'Email must be at most 50 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    description: Yup.string()
      .trim()
      .min(3, 'Message must be at least 3 characters')
      .max(100, 'Message must be at most 100 characters')
      .required('Message is required'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      description: values.description,
    };
    try {
      let data = await dispatch(createContact(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: `Will get back to you shortly`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Will get back to you shortly`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error('Something went wrong!');
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end
   
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h2
            id="9692829694"
            className="dynamicStyle section_header relative inline-block"
          >
            {data ? data['9692829694'] : 'Get in touch'}
          </h2>
          <ReactQuill
            id="2664572945"
            theme="bubble"
            readOnly
            className="section_detail bubble2 dynamicStyle "
            value={data ? data['2664572945'] : 'Lorem Ipsum'}
          />
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12">
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 lg:px-20 md:px-12 pr-4">
            <div className="flex">
              <div className="bg-primary icon-box cust-bgColor flex items-center justify-center">
                <FiMapPin />
              </div>

              <div className="">
                <h4 id="8870648199" className="dynamicStyle card_title_0">
                  {data ? data['8870648199'] : 'Lorem Ipsum'}
                </h4>

                <p
                  id="1333994894"
                  className="dynamicStyle card_description16px"
                >
                  {data ? data['1333994894'] : 'Lorem Ipsum'}
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="bg-primary icon-box cust-bgColor flex items-center justify-center">
                <FaPhoneAlt className="" />
              </div>

              <div className="">
                <h4 id="2188533937" className="dynamicStyle card_title_0">
                  {data ? data['2188533937'] : 'Lorem Ipsum'}
                </h4>

                <a href="tel:123 456 7890">
                  <p className="card_description16px">123 456 7890</p>
                </a>
              </div>
            </div>

            <div className="flex">
              <div className="bg-primary icon-box cust-bgColor flex items-center justify-center">
                <FiMail />
              </div>

              <div className="">
                <h4 id="7103505330" className="dynamicStyle card_title_0">
                  {data ? data['7103505330'] : 'Lorem Ipsum'}
                </h4>

                <a href="mailto:abcd@abcd.com">
                  <p className="card_description16px">abcd@abcd.com</p>
                </a>
              </div>
            </div>
          </div>

          <div className="w-full lg:flex md:flex gap-6 lg:pt-20 md:pt-16 pt-12">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.8095320612306!2d72.85291797495573!3d19.116009950715732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7170e07b235%3A0xb59deaaa5b1ce0e6!2sBRAINED!5e0!3m2!1sen!2sin!4v1712748084423!5m2!1sen!2sin"
                className="iframe-size"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div
              ref={elementRef}
              className={`fade-right lg:w-1/2 md:w-1/2 w-full lg:pt-0 md:pt-0 pt-6 ${
                isVisible ? 'visibleright' : ''
              } `}
            >
              <h3
                id="0226066121"
                className="dynamicStyle fsize36 sm-fsize24 font-bold lg:mb-8 md:mb-6 mb-4 text_surface800"
              >
                {data ? data['0226066121'] : 'Lorem Ipsum'}
              </h3>

              <form onSubmit={formik.handleSubmit}>
                <div className="w-full lg:flex md:flex gap-6 mb-4">
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <InputText
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      invalid={!!formik.errors.name && formik.touched.name}
                      placeholder="Name"
                      className="form-control theme-bg themeShadow bg_surface50"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <p className="text-danger fsize12 mt-2">
                        {formik.errors.name}
                      </p>
                    )}
                  </div>

                  <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-4">
                    <InputText
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      invalid={!!formik.errors.email && formik.touched.email}
                      placeholder="Email"
                      className="form-control theme-bg themeShadow bg_surface50"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p className="text-danger fsize12 mt-2">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-6">
                  <InputTextarea
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    invalid={
                      !!formik.errors.description && formik.touched.description
                    }
                    placeholder="Here is a sample placeholder"
                    className="form-control theme-bg themeShadow bg_surface50"
                  />
                  {formik.touched.description && formik.errors.description && (
                    <p className="text-danger fsize12 mt-2">
                      {formik.errors.description}
                    </p>
                  )}
                </div>

                <div id="0431613367" className="dynamicStyle mt-8">
                  <Button
                    type="submit"
                    className="theme-btn2 w-full b_button_primary py-3 font-semibold flex justify-center sm-fsize14"
                  >
                    {data ? data['0431613367'] : 'Send Message'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails4;
