import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard15 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section
      className='theme-bg'
    >
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 id="9059741550" className="dynamicStyle section_header">
              {data ? data['9059741550'] : 'Welcome to My World'}
            </h2>
            <ReactQuill
              id="5140663073"
              theme="bubble"
              readOnly
              className='bubble2 dynamicStyle section_detail'
                  
              value={data ? data['5140663073'] : 'Lorem Ipsum'}
            />
          </div>
          <div
            ref={elementRef}
            className={`fade-bottom grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:mt-16 md:mt-16 mt-12 ${
              isVisible ? 'visiblebottom' : ''
            } `}
          >
            <div
              className='box-shadow-none p-4  w-full relative'
                  
            >
              <div
                id="2809162205"
                className="dynamicStyle2 flex justify-center w-full "
              >
                <Image
                  src={data ? getImage(data['2809162205']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-24 h-24 lg:mb-3 md:mb-2 mb-2 rounded-full border  theme-border p-3 relative object-contain"
                />
                {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
              </div>
              <h2
                id="5896305252"
                className="dynamicStyle card_title text-center"
              >
                {data ? data['5896305252'] : 'Welcome to My World'}
              </h2>

              <div className="absolute-line"></div>
            </div>
            <div
              className='box-shadow-none p-4 w-full border-left relative'
                 
            >
              <div
                id="7508598544"
                className="dynamicStyle2 flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['7508598544']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-24 h-24 lg:mb-3 md:mb-2 mb-2 rounded-full border theme-border  p-3 relative object-contain"
                />
                {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
              </div>
              <h2
                id="9962896322"
                className="dynamicStyle card_title text-center"
              >
                {data ? data['9962896322'] : 'Welcome to My World'}
              </h2>
              <div className="absolute-line1"></div>
            </div>
            <div
              className='box-shadow-none p-4 w-full border-left relative'
                 
            >
              <div
                id="3541683835"
                className="dynamicStyle flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['3541683835']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-24 h-24 lg:mb-3 md:mb-2 mb-2 rounded-full border theme-border  p-3 relative object-contain"
                />
                {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
              </div>
              <h2
                id="1817480977"
                className="dynamicStyle card_title text-center"
              >
                {data ? data['1817480977'] : 'Welcome to My World'}
              </h2>
              <div className="absolute-line2"></div>
            </div>
            <div
              className='box-shadow-none p-4 w-full border-left relative'
                  
            >
              <div
                id="8118999094"
                className="dynamicStyle flex justify-center w-full"
              >
                <Image
                  src={data ? getImage(data['8118999094']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-24 h-24 lg:mb-3 md:mb-2 mb-2 rounded-full border theme-border  p-3 relative object-contain"
                />
                {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
              </div>
              <h2
                id="4655462936"
                className="dynamicStyle card_title text-center"
              >
                {data ? data['4655462936'] : 'Welcome to My World'}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard15;
