import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";

const CardText = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <h2 id="4976480268" className="dynamicStyle section_header uppercase">
            {data ? data["4976480268"] : "Lorem Ipsum"}
          </h2>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-12 md:mt-10 mt-8 lg:gap-4 md:gap-4 gap-4 ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <Card className="prime_card themeShadow theme-bg">
            <h2
              id="1213524592"
              className="dynamicStyle card_title font-semibold"
            >
              {data ? data["1213524592"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="0111982049"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["0111982049"] : "Lorem Ipsum"}
            />
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <h2
              id="2415028268"
              className="dynamicStyle card_title font-semibold"
            >
              {data ? data["2415028268"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="6787662183"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["6787662183"] : "Lorem Ipsum"}
            />
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <h2
              id="9289452004"
              className="dynamicStyle card_title font-semibold"
            >
              {data ? data["9289452004"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="2638899178"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["2638899178"] : "Lorem Ipsum"}
            />
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <h2
              id="1213524592"
              className="dynamicStyle card_title font-semibold"
            >
              {data ? data["1213524592"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="0111982049"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["0111982049"] : "Lorem Ipsum"}
            />
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <h2
              id="9289452004"
              className="dynamicStyle card_title font-semibold"
            >
              {data ? data["9289452004"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="2638899178"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data["2638899178"] : "Lorem Ipsum"}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CardText;
