import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const SubscriptionPlan9 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="lg:w-3/5 md:w-3/5 mx-auto text-center">
        <h3
          id="7332285501"
          className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor"
        >
          {data ? data['7332285501'] : 'Flagship Programs'}
        </h3>
        <ReactQuill
            id="1990353039"
            theme="bubble"
            readOnly
            className={
              colorMode === 'dark'
                ? 'dark-bubble bubble2 dynamicStyle mt-1'
                : 'bubble2 dynamicStyle mt-1'
            }
            value={data ? data['1990353039'] : 'Welcome to My World'}
          />
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6 lg:mt-12 md:mt-10 mt-8">
        <div className="bdr-1px-dcdcdc py-6 px-4 cust-bgColor theme-border">
          <div className="text-center">
            <p className="fsize24 sm-fsize16 font-semibold cust-textColor">
              Lorem Ipsum
            </p>
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="lg:w-16 md:w-16 w-14 lg:h-16 md:h-16 h-14 mx-auto rounded-full lg:my-4 md:my-4 my-3"
            />
            <div className="flex items-end justify-center">
              <p className="fsize22 font-medium cust-textColor">0 </p>
              <p className="fsize12 mb-1 text-gray-400 textColor-light">
                {' '}
                /Month
              </p>
            </div>
          </div>
          <div className="lg:my-6 md:my-6 my-4">
            <div className="mb-3 flex items-center gap-2">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
            <div className="mb-3 flex items-center gap-2 ">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
            <div className="mb-3 flex items-center gap-2 ">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className='theme-btn2 lg:py-3 md:py-3 py-2 b_button_primary px-8 fsize14 sm-fsize14 lg:mt-2 md:mt-2 mt-3'
                
            >
              Select
            </Button>
          </div>
        </div>
        <div className="bdr-1px-dcdcdc py-6 px-4 cust-bgColor theme-border">
          <div className="text-center">
            <p className="fsize24 sm-fsize16 font-semibold cust-textColor">
              Lorem Ipsum
            </p>
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="lg:w-16 md:w-16 w-14 lg:h-16 md:h-16 h-14 mx-auto rounded-full lg:my-4 md:my-4 my-3"
            />
            <div className="flex items-end justify-center">
              <p className="fsize22 font-medium cust-textColor">0 </p>
              <p className="fsize12 mb-1 text-gray-400 textColor-light">
                {' '}
                /Month
              </p>
            </div>
          </div>
          <div className="lg:my-6 md:my-6 my-4">
            <div className="mb-3 flex items-center gap-2">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
            <div className="mb-3 flex items-center gap-2 ">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
            <div className="mb-3 flex items-center gap-2 ">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className='theme-btn2 lg:py-3 md:py-3 py-2 b_button_primary px-8 fsize14 sm-fsize14 lg:mt-2 md:mt-2 mt-3'
                 
            >
              Select
            </Button>
          </div>
        </div>
        <div className="bdr-1px-dcdcdc py-6 px-4 cust-bgColor theme-border">
          <div className="text-center">
            <p className="fsize24 sm-fsize16 font-semibold cust-textColor">
              Lorem Ipsum
            </p>
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="lg:w-16 md:w-16 w-14 lg:h-16 md:h-16 h-14 mx-auto rounded-full lg:my-4 md:my-4 my-3"
            />
            <div className="flex items-end justify-center">
              <p className="fsize22 font-medium cust-textColor">0 </p>
              <p className="fsize12 mb-1 text-gray-400 textColor-light">
                {' '}
                /Month
              </p>
            </div>
          </div>
          <div className="lg:my-6 md:my-6 my-4">
            <div className="mb-3 flex items-center gap-2">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
            <div className="mb-3 flex items-center gap-2 ">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
            <div className="mb-3 flex items-center gap-2 ">
              <div className="">
                <FeatherIcon
                  size={18}
                  icon="check"
                  className="cursor-pointer cust-textColor"
                />
              </div>
              <div className="">
                <p className="fsize16 md-fsize14 sm-fsize14 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className='theme-btn2 lg:py-3 md:py-3 py-2 b_button_primary px-8 fsize14 sm-fsize14 lg:mt-2 md:mt-2 mt-3'
                  
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan9;
