import React, { useEffect, useState } from 'react';
import SpeakText from './Core/SpeakText';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import SendToOpenAi from './Core/SendToOpenAi';
import FeatherIcon from 'feather-icons-react';
const Mic = ({ section, button, pause, setpause, setsection, setbutton }) => {
  const dispatch = useDispatch();
  let recognizer = useSelector(state => state.Aiassist.recognizer);
  let useGpt = useSelector(state => state.Aiassist.useGpt);
  let synthensizer = useSelector(state => state.Aiassist.synthensizer);
  let emotion = useSelector(state => state.Aiassist.emotion);
  let selectedModel = useSelector(state => state.Aiassist.selectedModel);
  let inputText = useSelector(state => state.Aiassist.inputText);
  let isGPTResponseReceived = useSelector(
    state => state.Aiassist.isGPTResponseReceived
  );
  let ConversationHistory = useSelector(
    state => state.Aiassist.ConversationHistory
  );
  const startSpeechRecognition = () => {
    if (recognizer) {
      recognizer.startContinuousRecognitionAsync();
    }
  };

  const stopSpeechRecognition = () => {
    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync();
    }
  };
  useEffect(() => {
    if (useGpt) {
      SendToOpenAi(dispatch, ConversationHistory);
    }
  }, [useGpt]);

  useEffect(() => {
    if (isGPTResponseReceived) {
      SpeakText(dispatch, synthensizer, emotion, selectedModel, inputText);
    }
  }, [isGPTResponseReceived]);
  return (
    <div
      className={
        section === true
          ? 'h-chat hidden lg:w-5/12 md:w-5/12 w-full justify-center items-center'
          : 'h-chat lg:flex md:flex hidden lg:w-5/12 md:w-5/12 w-full justify-center items-center'
      }
    >
      <div>
        <div className="">
          {button === false ? (
            <div
              className={
                pause === false
                  ? 'circle  flex bg-primary cust-bgColor items-center cursor-pointer justify-center mx-auto'
                  : 'circle pulse flex bg-primary items-center cursor-pointer justify-center mx-auto'
              }
            >
              <FeatherIcon icon="mic" size="39" className="text-white" />
            </div>
          ) : (
            <div
              className={
                pause === false
                  ? 'circle-2 pulse2 flex bg-fa items-center cursor-pointer justify-center mx-auto'
                  : 'circle  flex bg-fa items-center cursor-pointer justify-center mx-auto'
              }
            >
              <div
                className={
                  pause === false
                    ? 'circle pulse flex bg-primary cust-bgColor items-center cursor-pointer justify-center mx-auto'
                    : 'circle  flex bg-primary cust-bgColor items-center cursor-pointer justify-center mx-auto'
                }
              >
                <FeatherIcon icon="mic" size="39" className="text-white" />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-center mt-4">
          {button === true ? (
            <>
              <div className="flex gap-2">
                {pause === true ? (
                  <>
                    <Button
                      outlined
                      className="text-primary px-6 py-2"
                      mt={12}
                      onClick={() => {
                        startSpeechRecognition();
                        setpause(false);
                      }}
                    >
                      <FeatherIcon
                        icon="play"
                        size="19"
                        className="flex mr-1"
                      />
                      Resume
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      
                      outlined
                      className="text-primary px-6 py-2"
                      mt={12}
                      onClick={() => {
                        stopSpeechRecognition();
                        setpause(true);
                      }}
                    >
                      <FeatherIcon
                        icon="pause-circle"
                        size="19"
                        className="flex mr-1"
                      />
                      Pause
                    </Button>
                  </>
                )}
                <Button
                    className="b_button_primary px-6 py-2"
                  mt={12}
                  onClick={() => {
                    setsection(true);
                    stopSpeechRecognition();
                  }}
                >
                  <FeatherIcon icon="x" size="19" className="flex mr-1" />
                  End
                </Button>
              </div>
            </>
          ) : (
            <>
              <Button
              
                outlined
                className="text-primary px-6 py-2"
                px={6}
                mt={12}
                onClick={() => {
                  setbutton(true);
                  startSpeechRecognition();
                }}
              >
                <FeatherIcon icon="play" size="19" className="flex mr-1" />
                Start
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        className={section === false ? 'lg:hidden md:hidden block' : 'hidden'}
      >
        <div className="flex justify-around items-center">
          {pause === false ? (
            <>
              <Button
                className="b_button_primary px-6 py-2"
                borderRadius={50}
                size="lg"
                p={1}
                mt={6}
                onClick={() => setpause(true)}
              >
                <FeatherIcon
                  icon="pause"
                  fill="white"
                  stroke="none"
                  size="29"
                />
              </Button>
            </>
          ) : (
            <>
              <Button
                className="b_button_primary px-6 py-2"
                borderRadius={50}
                size="lg"
                pr={1}
                pl={2}
                mt={6}
                onClick={() => setpause(false)}
              >
                <FeatherIcon icon="play" fill="white" stroke="none" size="25" />
              </Button>
            </>
          )}
          <div className="">
            {button === false ? (
              <div
                className={
                  pause === false
                    ? 'circle  flex bg-primary cust-bgColor items-center cursor-pointer justify-center mx-auto mt-4'
                    : 'circle pulse flex bg-primary cust-bgColor items-center cursor-pointer justify-center mx-auto mt-4'
                }
              >
                <FeatherIcon icon="mic" size="20" className="text-white" />
              </div>
            ) : (
              <div
                className={
                  pause === false
                    ? 'circle-2 pulse2 flex bg-fa items-center cursor-pointer justify-center mx-auto mt-4'
                    : 'circle  flex bg-fa items-center cursor-pointer justify-center mx-auto mt-4'
                }
              >
                <div
                  className={
                    pause === false
                      ? 'circle pulse flex bg-primary cust-bgColor items-center cursor-pointer justify-center mx-auto mt-4'
                      : 'circle  flex bg-primary items-center cust-bgColor cursor-pointer justify-center mx-auto mt-4'
                  }
                >
                  <FeatherIcon icon="mic" size="20" className="text-white" />
                </div>
              </div>
            )}
          </div>
          <Button
          outlined
            className="text-primary px-6 py-2"
            borderRadius={50}
            size="lg"
            p={1}
            mt={6}
            onClick={() => setsection(true)}
          >
            <FeatherIcon icon="x" size="29" />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Mic;
