import { Image } from "primereact/image";
import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";

const AddBanner7 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div
      id="7948346829"
      className="dynamicStyle2 h-350px sm-h250px relative w-full"
    >
      <Image
        src={data ? getImage(data["7948346829"]) : ""}
        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        alt="image"
        imageClassName="h-350px sm-h250px w-full"
      />
      <div className="absl7-text h-350px sm-h250px flex items-center">
        <div>
          <h4 id="1512673988" className="dynamicStyle section_header">
            {data ? data["1512673988"] : "Lorem Ipsum"}
          </h4>
          <Button
            className="dynamicStyle b_button_primary px-6 py-2 lg:mt-8 md:mt-8 mt-6"
            id="3145371714"
          >
            <span onClick={() => handleClick("3145371714")}>
              {data ? data["3145371714"] : "View More"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddBanner7;
