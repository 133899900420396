import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection17 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:flex md:flex sm-reverse h-full items-center">
        <div
          ref={elementRef}
          className={`fade-left lg:w-3/5 md:w-3/5 w-full lg:px-20 md:px-8 px-5 lg:py-0 md:py-8 py-6 ${
            isVisible ? 'visibleleft' : ''
          } `}
        >
          <div className="flex flex-wrap items-center gap-4">
            <p
              id="2993923750"
              className="text-primary fsize16 md-fsize16 sm-fsize14 dynamicStyle"
            >
              {data ? data['2993923750'] : 'Lorem'}
            </p>
            <div className="flex items-center gap-3">
              <span className="block h-2 w-2 bg-primary rounded-full"></span>
              <p
                id="5447826286"
                className="text-primary fsize16 md-fsize16 sm-fsize14 dynamicStyle"
              >
                {data ? data['5447826286'] : 'Lorem'}
              </p>
            </div>
            <div className="flex items-center gap-3">
              <span className="block h-2 w-2 bg-primary rounded-full"></span>
              <p
                id="8265697376"
                className="text-primary fsize16 md-fsize16 sm-fsize14 dynamicStyle"
              >
                {data ? data['8265697376'] : 'Lorem'}
              </p>
            </div>
          </div>

          <div className=" lg:mt-4 md:mt-3 mt-2">
            <h2
              id="9553655522"
              className="theme-textColor hero_title dynamicStyle"
            >
              {data ? data['9553655522'] : 'Lorem Ipsum'}
            </h2>
            <ReactQuill
              id="7701515500"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['7701515500'] : 'Lorem Ipsum'}
            />
          </div>
          <div className="flex lg:gap-6 md:gap-6 gap-4 lg:pt-8 md:pt-8 pt-6">
            <Button
              id="7169376012"
              className="dynamicStyle theme-btn b_button_primary px-6 py-2 sm-fsize14 clr-fff"
            >
              <span onClick={() => handleClick('7169376012')}>
                {data ? data['7169376012'] : 'Lorem ipsum'}
              </span>
            </Button>
            <Button
              outlined
              id="5764594541"
              className="dynamicStyle theme-btn border-primary text-primary sm-fsize14 px-6 py-2"
            >
              <span onClick={() => handleClick('5764594541')}>
                {data ? data['5764594541'] : 'Lorem ipsum'}
              </span>
            </Button>
          </div>
        </div>
        <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
          <div id="7060135456" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['7060135456']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              imageClassName="object-cover w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection17;
