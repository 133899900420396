import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section
      className="dynamicStyle2 bg-primary theme-bg relative h-580px md-h-400px"
      id="5004290428"
      style={{ background: thisComponent.sectionColor }}
    >
      <Image
        src={data ? getImage(data['5004290428']) : ''}
        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        alt="hero"
        imageClassName="w-full h-580px sm-h-auto object-cover filter-b6"
      />

      <div
        ref={elementRef}
        className={`fade-bottom absolute top-0 w-full h-580px md-h-400px lg:flex md:flex  flex justify-center lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-4 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <div className="w-full flex flex-col justify-center items-center">
          <h2 id="7362576958" className="dynamicStyle hero_title clr-fff">
            {data ? data['7362576958'] : 'Lorem ipsum generated'}
          </h2>
          <p
            id="1833731917"
            className="dynamicStyle card_description theme-textColor clr-fff"
          >
            {data ? data['1833731917'] : 'Lorem ipsum generated'}
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection9;
