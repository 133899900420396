import { Card, Image, useColorMode } from "@chakra-ui/react";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";
import { useSelector } from 'react-redux';

const EcomProductSection8 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const { colorMode, toggleColorMode } = useColorMode();

  const clothes = [
    {
      id: 1,
      productName: "Men's Casual Shirt",
      actualPrice: 40,
      discount: 10,
      image: "https://example.com/images/mens-casual-shirt.jpg",
      deal: "Summer Sale",
      category: "Shirt",
      subCategory: "Men",
    },
    {
      id: 2,
      productName: "Women's Floral T-Shirt",
      actualPrice: 30,
      discount: 15,
      image: "https://example.com/images/womens-floral-tshirt.jpg",
      deal: "Spring Collection",
      category: "T-Shirt",
      subCategory: "Women",
    },
    {
      id: 3,
      productName: "Kids' Denim Jeans",
      actualPrice: 25,
      discount: 5,
      image: "https://example.com/images/kids-denim-jeans.jpg",
      deal: "Back to School",
      category: "Jeans",
      subCategory: "Kids",
    },
    {
      id: 4,
      productName: "Men's Denim Jeans",
      actualPrice: 60,
      discount: 20,
      image: "https://example.com/images/mens-denim-jeans.jpg",
      deal: "Denim Deals",
      category: "Jeans",
      subCategory: "Men",
    },
    {
      id: 5,
      productName: "Women's Cardigan Jacket",
      actualPrice: 70,
      discount: 25,
      image: "https://example.com/images/womens-cardigan-jacket.jpg",
      deal: "Winter Warmers",
      category: "Jacket",
      subCategory: "Women",
    },
    {
      id: 6,
      productName: "Men's Sports Jacket",
      actualPrice: 100,
      discount: 30,
      image: "https://example.com/images/mens-sports-jacket.jpg",
      deal: "Sportswear Sale",
      category: "Jacket",
      subCategory: "Men",
    },
    {
      id: 7,
      productName: "Women's Sunglasses",
      actualPrice: 45,
      discount: 10,
      image: "https://example.com/images/womens-sunglasses.jpg",
      deal: "Fashion Deals",
      category: "Glasses",
      subCategory: "Women",
    },
    {
      id: 8,
      productName: "Kids' Hoodie Jacket",
      actualPrice: 35,
      discount: 15,
      image: "https://example.com/images/kids-hoodie-jacket.jpg",
      deal: "Winter Collection",
      category: "Jacket",
      subCategory: "Kids",
    },
    {
      id: 9,
      productName: "Men's Formal Shirt",
      actualPrice: 55,
      discount: 20,
      image: "https://example.com/images/mens-formal-shirt.jpg",
      deal: "Business Attire",
      category: "Shirt",
      subCategory: "Men",
    },
    {
      id: 10,
      productName: "Women's Blouse Shirt",
      actualPrice: 35,
      discount: 10,
      image: "https://example.com/images/womens-blouse-shirt.jpg",
      deal: "Office Wear",
      category: "Shirt",
      subCategory: "Women",
    },
  ];

  console.log(clothes, "clothes");

  const categories = [...new Set(clothes.map((item) => item.category))];
  console.log(categories, "categories");

  const [selectedCategory, setSelectedCategory] = useState("All Products");

  const handleClick = (category) => {
    setSelectedCategory(category);
    console.log(category, "category");
  };

  const filterData = clothes.filter((item) => item.category);
  console.log(filterData, "filterData");

  return (
    <section>
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5" style={{ background: thisComponent.sectionColor }}>
        <div className="">
          <Tabs>
            <div className="flex justify-between w-full">
              <TabList borderBottom={0}>
                <Tab
                  className={
                    colorMode === "light"
                      ? "fsize16 sm-fsize14 font-medium cust-textColor theme-textColor theme-btn2 themeShadow"
                      : "fsize16 sm-fsize14 font-medium cust-textColor theme-textColor theme-btn2 themeShadow"
                  }
                >
                  All Products
                </Tab>
                {categories
                  ? categories.map((category) => {
                      return (
                        <Tab
                          key={category}
                          onClick={() => handleClick(category)}
                          className={
                            colorMode === "light"
                              ? "fsize16 sm-fsize14 font-medium cust-textColor theme-textColor theme-btn2 themeShadow"
                              : "fsize16 sm-fsize14 font-medium cust-textColor theme-textColor theme-btn2 themeShadow"
                          }
                        >
                          {category}
                        </Tab>
                      );
                    })
                  : "no-categories"}
              </TabList>
              <Button
                className="b_button_primary px-6 py-2 fsize12 theme-btn themeShadow"
                borderRadius={2}
              >
                <FaFilter className="fsize10 mr-1" /> Filter
              </Button>
            </div>
            <div className="lg:mt-12 md:mt-8 mt-4">
              <TabPanels>
                <TabPanel padding={0}>
                  <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
                    {filterData && filterData.length > 0
                      ? filterData.map((cat) => {
                          return (
                            <Card
                              className={
                                colorMode === "light" ? "theme-bg" : "theme-bg"
                              }
                              borderRadius={4}
                              key={cat.id}
                            >
                              <div className="themeShadow ">
                                <div className="relative">
                                  <Image
                                    borderTopRadius={4}
                                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                    alt="product_image"
                                    className="w-full h-60 object-cover"
                                  />
                                  <div
                                    className={
                                      colorMode === "light"
                                        ? "absolute top-4 left-0 bg-primary theme-bg px-2 py-1"
                                        : "absolute top-4 left-0 cust-bgColor theme-bg px-2 py-1"
                                    }
                                  >
                                    <p className="fsize10 clr-fff">
                                      {cat.deal}
                                    </p>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                                    {cat.productName}
                                  </p>
                                  <div className="flex items-center justify-between mt-1">
                                    <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                                      {cat.subCategory}
                                    </p>
                                    <div className="flex">
                                      <p className="fsize14 md:fsize14 sm-fsize12 font-medium text-black textColor-light">
                                        ${cat.actualPrice}
                                      </p>
                                      <p className="pl-2 text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                                        ${cat.discount}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        })
                      : "no-data"}
                  </div>
                </TabPanel>
                <TabPanel padding={0}>
                  {selectedCategory === "Shirt" && (
                    <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
                      {filterData.map((cat) => {
                        if (cat.category === "Shirt") {
                          return (
                            <Card
                              className={
                                colorMode === "light" ? "theme-bg" : "theme-bg"
                              }
                              borderRadius={4}
                              key={cat.id}
                            >
                              <div className="themeShadow ">
                                <div className="relative">
                                  <Image
                                    borderTopRadius={4}
                                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                    alt="product_image"
                                    className="w-full h-60 object-cover"
                                  />
                                  <div
                                    className={
                                      colorMode === "light"
                                        ? "absolute top-4 left-0 bg-primary px-2 py-1"
                                        : "absolute top-4 left-0 theme-bg px-2 py-1"
                                    }
                                  >
                                    <p className="fsize10 clr-fff">
                                      {cat.deal}
                                    </p>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                                    {cat.productName}
                                  </p>
                                  <div className="flex items-center justify-between mt-1">
                                    <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                                      {cat.subCategory}
                                    </p>
                                    <div className="flex">
                                      <p className="fsize14 md:fsize14 sm-fsize12 font-medium text-black textColor-light">
                                        ${cat.actualPrice}
                                      </p>
                                      <p className="pl-2 text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                                        ${cat.discount}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        }
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel padding={0}>
                  {selectedCategory === "T-Shirt" && (
                    <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
                      {filterData.map((cat) => {
                        if (cat.category === "T-Shirt") {
                          return (
                            <Card
                              className={
                                colorMode === "light" ? "theme-bg" : "theme-bg"
                              }
                              borderRadius={4}
                              key={cat.id}
                            >
                              <div className="themeShadow ">
                                <div className="relative">
                                  <Image
                                    borderTopRadius={4}
                                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                    alt="product_image"
                                    className="w-full h-60 object-cover"
                                  />
                                  <div
                                    className={
                                      colorMode === "light"
                                        ? "absolute top-4 left-0 bg-primary px-2 py-1"
                                        : "absolute top-4 left-0 theme-bg px-2 py-1"
                                    }
                                  >
                                    <p className="fsize10 clr-fff">
                                      {cat.deal}
                                    </p>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                                    {cat.productName}
                                  </p>
                                  <div className="flex items-center justify-between mt-1">
                                    <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                                      {cat.subCategory}
                                    </p>
                                    <div className="flex">
                                      <p className="fsize14 md:fsize14 sm-fsize12 font-medium text-black textColor-light">
                                        ${cat.actualPrice}
                                      </p>
                                      <p className="pl-2 text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                                        ${cat.discount}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        }
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel padding={0}>
                  {selectedCategory === "Jeans" && (
                    <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
                      {filterData.map((cat) => {
                        if (cat.category === "Shirt") {
                          return (
                            <Card
                              className={
                                colorMode === "light" ? "theme-bg" : "theme-bg"
                              }
                              borderRadius={4}
                              key={cat.id}
                            >
                              <div className="themeShadow ">
                                <div className="relative">
                                  <Image
                                    borderTopRadius={4}
                                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                    alt="product_image"
                                    className="w-full h-60 object-cover"
                                  />
                                  <div
                                    className={
                                      colorMode === "light"
                                        ? "absolute top-4 left-0 bg-primary px-2 py-1"
                                        : "absolute top-4 left-0 theme-bg px-2 py-1"
                                    }
                                  >
                                    <p className="fsize10 clr-fff">
                                      {cat.deal}
                                    </p>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                                    {cat.productName}
                                  </p>
                                  <div className="flex items-center justify-between mt-1">
                                    <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                                      {cat.subCategory}
                                    </p>
                                    <div className="flex">
                                      <p className="fsize14 md:fsize14 sm-fsize12 font-medium text-black textColor-light">
                                        ${cat.actualPrice}
                                      </p>
                                      <p className="pl-2 text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                                        ${cat.discount}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        }
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel padding={0}>
                  {selectedCategory === "Jacket" && (
                    <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
                      {filterData.map((cat) => {
                        if (cat.category === "Shirt") {
                          return (
                            <Card
                              className={
                                colorMode === "light" ? "theme-bg" : "theme-bg"
                              }
                              borderRadius={4}
                              key={cat.id}
                            >
                              <div className="themeShadow ">
                                <div className="relative">
                                  <Image
                                    borderTopRadius={4}
                                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                    alt="product_image"
                                    className="w-full h-60 object-cover"
                                  />
                                  <div
                                    className={
                                      colorMode === "light"
                                        ? "absolute top-4 left-0 bg-primary px-2 py-1"
                                        : "absolute top-4 left-0 theme-bg px-2 py-1"
                                    }
                                  >
                                    <p className="fsize10 clr-fff">
                                      {cat.deal}
                                    </p>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                                    {cat.productName}
                                  </p>
                                  <div className="flex items-center justify-between mt-1">
                                    <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                                      {cat.subCategory}
                                    </p>
                                    <div className="flex">
                                      <p className="fsize14 md:fsize14 sm-fsize12 font-medium text-black textColor-light">
                                        ${cat.actualPrice}
                                      </p>
                                      <p className="pl-2 text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                                        ${cat.discount}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        }
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel padding={0}>
                  {selectedCategory === "Glasses" && (
                    <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
                      {filterData.map((cat) => {
                        if (cat.category === "Shirt") {
                          return (
                            <Card
                              className={
                                colorMode === "light" ? "theme-bg" : "theme-bg"
                              }
                              borderRadius={4}
                              key={cat.id}
                            >
                              <div className="themeShadow ">
                                <div className="relative">
                                  <Image
                                    borderTopRadius={4}
                                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                    alt="product_image"
                                    className="w-full h-60 object-cover"
                                  />
                                  <div
                                    className={
                                      colorMode === "light"
                                        ? "absolute top-4 left-0 bg-primary px-2 py-1"
                                        : "absolute top-4 left-0 theme-bg px-2 py-1"
                                    }
                                  >
                                    <p className="fsize10 clr-fff">
                                      {cat.deal}
                                    </p>
                                  </div>
                                </div>
                                <div className="p-3">
                                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                                    {cat.productName}
                                  </p>
                                  <div className="flex items-center justify-between mt-1">
                                    <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                                      {cat.subCategory}
                                    </p>
                                    <div className="flex">
                                      <p className="fsize14 md:fsize14 sm-fsize12 font-medium text-black textColor-light">
                                        ${cat.actualPrice}
                                      </p>
                                      <p className="pl-2 text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                                        ${cat.discount}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        }
                      })}
                    </div>
                  )}
                </TabPanel>
              </TabPanels>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default EcomProductSection8;
