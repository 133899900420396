import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useRef, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { Chart } from 'primereact/chart';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import { Galleria } from 'primereact/galleria';
import { Editor } from 'primereact/editor';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';

const ReactPrime = () => {
  const [selectedCities, setSelectedCities] = useState(null);
  const [visible, setVisible] = useState(false);
  const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' },
  ];

  const toast = useRef(null);

  const accept = () => {
    toast.current.show({
      severity: 'info',
      summary: 'Confirmed',
      detail: 'You have accepted',
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: 'Rejected',
      detail: 'You have rejected',
      life: 3000,
    });
  };

  const confirm1 = () => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept,
      reject,
    });
  };

  const confirm2 = () => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept,
      reject,
    });
  };

  const confirm3 = event => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept,
      reject,
    });
  };

  const confirm4 = event => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Do you want to delete this record?',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept,
      reject,
    });
  };

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const data = {
      labels: ['Q1', 'Q2', 'Q3', 'Q4'],
      datasets: [
        {
          label: 'Sales',
          data: [540, 325, 702, 620],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
          ],
          borderColor: [
            'rgb(255, 159, 64)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: [540, 325, 702],
          backgroundColor: [
            documentStyle.getPropertyValue('--blue-500'),
            documentStyle.getPropertyValue('--yellow-500'),
            documentStyle.getPropertyValue('--green-500'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--blue-400'),
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--green-400'),
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const getSeverity = product => {
    switch (product.inventoryStatus) {
      case 'INSTOCK':
        return 'success';

      case 'LOWSTOCK':
        return 'warning';

      case 'OUTOFSTOCK':
        return 'danger';

      default:
        return null;
    }
  };

  const ProductService = [
    {
      id: '1000',
      code: 'f230fh0g3',
      name: 'Bamboo Watch',
      description: 'Product Description',
      image:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      price: 65,
      category: 'Accessories',
      quantity: 24,
      inventoryStatus: 'INSTOCK',
      rating: 5,
    },
    {
      id: '1001',
      code: 'f230fh0g3',
      name: 'Bamboo Watch 1',
      description: 'Product Description',
      image:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      price: 56,
      category: 'Accessories',
      quantity: 29,
      inventoryStatus: 'INSTOCK',
      rating: 4,
    },
    {
      id: '1002',
      code: 'f230fh0g3',
      name: 'Bamboo Watch 1',
      description: 'Product Description',
      image:
        'https://nexuscluster.blob.core.windows.net/server01/Cera/Assets/cera-logo.png',
      price: 56,
      category: 'Accessories',
      quantity: 29,
      inventoryStatus: 'INSTOCK',
      rating: 4,
    },
    {
      id: '1003',
      code: 'f230fh0g3',
      name: 'Bamboo Watch 1',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 56,
      category: 'Accessories',
      quantity: 29,
      inventoryStatus: 'INSTOCK',
      rating: 4,
    },
    {
      id: '1004',
      code: 'f230fh0g3',
      name: 'Bamboo Watch 4',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 56,
      category: 'Accessories',
      quantity: 29,
      inventoryStatus: 'INSTOCK',
      rating: 4,
    },
  ];

  const [products, setProducts] = useState(ProductService);
  const [images, setImages] = useState(null);
  let SsetImages = ProductService.map(item => item.image);
  console.log(SsetImages, 'ProductService');

  const itemTemplate = item => {
    return <img src={item} alt={item} style={{ width: '100%' }} />;
  };

  const thumbnailTemplate = item => {
    return <img src={item} alt={item} />;
  };

  console.log(thumbnailTemplate, 'thumbnailTemplate');

  const [text, setText] = useState('');
  const [value, setValue] = useState('');

  const productTemplate = product => {
    return (
      <div className="border surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          <img
            src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`}
            alt={product.name}
            className="w-1/2"
          />
        </div>
        <div>
          <h4 className="mb-1">{product.name}</h4>
          <h6 className="mt-0 mb-3">${product.price}</h6>
          <Tag
            value={product.inventoryStatus}
            severity={getSeverity(product)}
          ></Tag>
          <div className="mt-5 flex flex-wrap gap-2 justify-center">
            <Button icon="pi pi-search" className="p-button p-button-rounded" />
            <Button
              icon="pi pi-star-fill"
              className="p-button-success p-button-rounded"
            />
          </div>
        </div>
      </div>
    );
  };

  const [ingredient, setIngredient] = useState('');
  const stepperRef = useRef(null);
  return (
    <div className="container mx-auto">
      <div className="mt-3">
        <p className="bg-primary" style={{ color: 'var(--primary-color)' }}>
          check{' '}
        </p>{' '}
        <Button label="Secondary" severity="secondary" raised />
        <Button label="Success" severity="success" raised />
        <Button label="Info" severity="info" raised />
        <p>Stepper</p>
        <div className="card flex justify-center">
          <Stepper ref={stepperRef} style={{ flexBasis: '100%' }}>
            <StepperPanel header="Header I">
              <div className="flex flex-column h-48">
                <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                  Content I
                </div>
              </div>
              <div className="flex pt-4 justify-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => stepperRef.current.nextCallback()}
                />
              </div>
            </StepperPanel>
            <StepperPanel header="Header II">
              <div className="flex flex-column h-48">
                <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                  Content II
                </div>
              </div>
              <div className="flex pt-4 justify-between">
                <Button
                  label="Back"
                  severity="secondary"
                  icon="pi pi-arrow-left"
                  onClick={() => stepperRef.current.prevCallback()}
                />
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => stepperRef.current.nextCallback()}
                />
              </div>
            </StepperPanel>
            <StepperPanel header="Header III">
              <div className="flex flex-column h-12rem">
                <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                  Content III
                </div>
              </div>
              <div className="flex pt-4 justify-content-start">
                <Button
                  label="Back"
                  severity="secondary"
                  icon="pi pi-arrow-left"
                  onClick={() => stepperRef.current.prevCallback()}
                />
              </div>
            </StepperPanel>
          </Stepper>
        </div>
      </div>
      <div className="mt-3">
        <p>Table</p>
        <DataTable value={products} tableStyle={{ minWidth: '50rem' }}>
          <Column field="code" header="Code"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="category" header="Category"></Column>
          <Column field="quantity" header="Quantity"></Column>
        </DataTable>
      </div>
      <div className="mt-3">
        <p>Radio Button</p>
        <div className="card flex justify-content-center">
          <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="ingredient1"
                name="pizza"
                value="Cheese"
                onChange={e => setIngredient(e.value)}
                checked={ingredient === 'Cheese'}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Cheese
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="ingredient2"
                name="pizza"
                value="Mushroom"
                onChange={e => setIngredient(e.value)}
                checked={ingredient === 'Mushroom'}
              />
              <label htmlFor="ingredient2" className="ml-2">
                Mushroom
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="ingredient3"
                name="pizza"
                value="Pepper"
                onChange={e => setIngredient(e.value)}
                checked={ingredient === 'Pepper'}
              />
              <label htmlFor="ingredient3" className="ml-2">
                Pepper
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="ingredient4"
                name="pizza"
                value="Onion"
                onChange={e => setIngredient(e.value)}
                checked={ingredient === 'Onion'}
              />
              <label htmlFor="ingredient4" className="ml-2">
                Onion
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <p>Floating Label</p>
        <div className="card">
          <FloatLabel className="mt-6">
            <InputText
              id="username"
              value={value}
              onChange={e => setValue(e.target.value)}
              className="border"
            />
            <label for="username">Username</label>
          </FloatLabel>
        </div>
      </div>
      <div className="mt-3">
        <p>Quill</p>
        <Editor
          value={text}
          onTextChange={e => setText(e.htmlValue)}
          className="h-400px sm-h-200px"
        />
      </div>
      <div className="mt-6">
        <p>Gallery</p>
        <Galleria
          value={SsetImages}
          responsiveOptions={responsiveOptions}
          numVisible={5}
          style={{ maxWidth: '640px' }}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
        />
      </div>
      <div className="mt-3">
        <p>Carousel</p>
        <div className="card">
          <Carousel
            value={products}
            numScroll={1}
            numVisible={3}
            responsiveOptions={responsiveOptions}
            itemTemplate={productTemplate}
          />
        </div>
      </div>
      <div className="mt-3">
        <p>Button:</p>
        <Button className="p-ripple">Buttom Ripple</Button>
      </div>

      <div className="mt-3">
        <p>MultiSelect</p>
        <MultiSelect
          value={selectedCities}
          onChange={e => setSelectedCities(e.value)}
          options={cities}
          optionLabel="name"
          dispatchlay="chip"
          placeholder="Select Cities"
          maxSelectedLabels={3}
          className="lg:w-1/3 md:w-1/3 w-full "
        />
      </div>

      <div className="mt-3">
        <p>Confirm Dailog</p>
        <Toast ref={toast} />
        <ConfirmDialog />
        <div className="card flex flex-wrap gap-2 justify-center">
          <Button
            onClick={confirm1}
            icon="pi pi-check"
            label="Confirm"
            className="bg-primary px-6 py-3 clr-fff"
          ></Button>
          <Button
            onClick={confirm2}
            icon="pi pi-times"
            label="Delete"
            className="clr-fff bg-secondary"
          ></Button>
        </div>
      </div>

      <div className="mt-3">
        <p>Confirm Popup</p>
        <Toast ref={toast} />
        <ConfirmPopup />
        <div className="card flex flex-wrap gap-2 justify-center">
          <Button
            onClick={confirm3}
            icon="pi pi-check"
            label="Confirm"
            className="bg-primary px-6 py-3 clr-fff"
          ></Button>
          <Button
            onClick={confirm4}
            icon="pi pi-times"
            label="Delete"
            className="clr-fff bg-secondary"
          ></Button>
        </div>
      </div>

      <div className="mt-3">
        <p>Dailog</p>
        <div className="card flex justify-content-center">
          <Button
            label="Show"
            icon="pi pi-external-link"
            onClick={() => setVisible(true)}
          />
          <Dialog
            header="Header"
            visible={visible}
            onHide={() => {
              if (!visible) return;
              setVisible(false);
            }}
            style={{ width: '50vw' }}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
          >
            <p className="m-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Dialog>
        </div>
      </div>
      <div className="mt-3">
        <p>Chart</p>
        <div className="grid grid-cols-3 gap-5">
          <div className="card">
            <Chart type="bar" data={chartData} options={chartOptions} />
          </div>
          <div className="card">
            <Chart type="pie" data={chartData} options={chartOptions} />
          </div>
          <div className="card">
            <Chart type="doughnut" data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactPrime;
