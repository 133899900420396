import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const Testimonial12 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const getAllTestimonial = useSelector(
    (state) => state.testimonial.Testimonial
  );

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <>
      {getAllTestimonial && (
        <div className="" style={{ background: thisComponent.sectionColor }}>
          <div
            id="9715257624"
            className="dynamicStyle2 h-650px sm-h600px relative"
          >
            <Image
              src={data ? getImage(data["9715257624"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              imageClassName="h-650px sm-h600px w-full object-cover sm-hidden"
            />

            <div className="lg:absolute md:absolute top-0 lg:w-full md:w-full left-0 h-650px sm-h600px flex justify-center items-center ">
              <div className="container mx-auto lg:px-12 md:px-8 px-5">
                <h2
                  id="2291987393"
                  className="dynamicStyle section_header lg:px-10 md:px-6 px-1"
                >
                  {data ? data["2291987393"] : "Our Clients"}
                </h2>

                <div className="lg:mt-8 md:mt-8 mt-6">
                  <Swiper
                    spaceBetween={15}
                    slidesPerView={3}
                    observer={true}
                    observeParents={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    loop
                    navigation={{
                      nextEl: ".image-swiper-button-next",
                      prevEl: ".image-swiper-button-prev",
                    }}
                    breakpoints={{
                      1536: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      1280: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      991: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                      425: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                    }}
                  >
                    <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        color="white"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                      </svg>
                    </div>
                    <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        color="white"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                      </svg>
                    </div>
                    {getAllTestimonial?.data?.result.map(
                      (testimonial, index) => {
                        return (
                          <SwiperSlide>
                            <Card
                              className="relative prime_card lg:mx-2 md:mx-2"
                              key={index}
                            >
                              <div className=" mb-4">
                                <Image
                                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                  src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                  alt={testimonial.name}
                                  imageClassName="h-200px w-full rounded object-cover"
                                />
                              </div>
                              <div>
                                <h3 className="card_title">
                                  {testimonial.name}
                                </h3>
                                <div className="lg:h-36 md:h-36 h-36">
                                  <p className="card_description lineClamp5">
                                    {testimonial.testimonial}
                                  </p>
                                </div>
                              </div>
                            </Card>
                          </SwiperSlide>
                        );
                      }
                    )}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonial12;
