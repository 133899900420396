import React from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  Image,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  FormControl,
  useColorMode,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { addNewsletter } from '../../../redux/newsletter/newsletter-actions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const NewsletterSection10 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();
  const initialValues = {
    email: '',
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, 'Email must be at least 5 characters')
      .max(50, 'Email must be at most 100 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
  });

  const handleOnSubmit = async values => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: 'Newsletter',
          text: `Subscribed to newsletter`,
          icon: 'success',
          timer: '2500',
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="overflow-hidden w-full lg:flex md:flex lg:h-screen sm-h-screen md-h-600px ">
      <div className="lg:w-2/5 md:w-2/5 dynamicStyle2" id="5215212120">
        <Image
          src={data ? getImage(data['2344189220']) : '5215212120'}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="object-cover lg:rounded-r-3xl md:rounded-r-3xl h-full"
        />
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="lg:w-3/5 md:w-3/5 lg:px-24 relative h-full sm-h-350px  flex items-center lg:px-0 md:px-8 px-5"
      >
        <div className="w-full">
          <p
            className="fsize40 md-fsize28 sm-fsize20 font-semibold text-black cust-textColor dynamicStyle"
            id="5353362141"
          >
            {data ? data['5353362141'] : 'Dont Miss Out!'}
          </p>
          <ReactQuill
          id="5159140067"
          theme="bubble"
          readOnly
          className={
            colorMode === 'dark'
              ? 'dark-bubble bubble dynamicStyle mb-2'
              : 'bubble dynamicStyle mb-2'
          }
          value={data ? data['5159140067'] : 'Lorem Ipsum'}
        />
          
          <div className="flex gap-2 mt-8 lg:pr-16">
            <FormControl
              isInvalid={!!formik.errors.email && formik.touched.email}
            >
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                borderRadius={30}
                className="text-black cust-textColor"
                placeholder="Enter your email address"
              />
              {formik.touched.email && formik.errors.email && (
                <FormErrorMessage className="textwhite">
                  {formik.errors.email}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
              type="submit"
              id="9330400741"
              className='dynamicStyle b_button_primary px-6 py-2 theme-bg themeShadow newsletter10-button'
                
            >
              {data ? data['9330400741'] : 'Subscribe'}
            </Button>
          </div>
        </div>
        <div className="bg-primary cust-bgcolor theme-bg themeShadow newsletter10-circle absolute rounded-full w-56 h-56 mob-hidden"></div>
      </form>
    </div>
  );
};

export default NewsletterSection10;
