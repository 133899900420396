import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  Input,
  InputGroup,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";

const NewsletterSection8 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
      <div className="prime_container ">
        <form
          onSubmit={formik.handleSubmit}
          className="lg:flex md:flex items-center"
        >
          <div className="lg:w-3/12 md:w-3/12">
            <div id="4385194010" className="dynamicStyle2 relative">
              <Image
                imageClassName="lg:h-96 md:h-72 h-48 w-full rounded-lg object-contain"
                src={data ? getImage(data["4385194010"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img_alt"
              />
            </div>
          </div>
          <div className="lg:w-6/12 lg:px-12 md:px-5 px-4 md:w-6/12 w-full mx-auto text-center lg:py-0 md:py-0 py-6">
            <h5 id="0523987505" className="dynamicStyle section_header">
              {data ? data["0523987505"] : "Lorem"}
            </h5>
            <p id="5148623604" className="dynamicStyle section_detail">
              {data ? data["5148623604"] : "Lorem"}
            </p>

            <InputText
              invalid={!!formik.errors.email && formik.touched.email}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={handleTrim}
              placeholder="Enter your email address"
              className="lg:mt-6 md:mt-6 mt-3 w-full"
            />

            {formik.touched.email && formik.errors.email && (
              <p className="text-danger fsize12 mt-2">{formik.errors.email}</p>
            )}

            <div className="flex justify-center lg:mt-4 md:mt-3 mt-2">
              <Button
                type="submit"
                id="4089375841"
                className="theme-btn dynamicStyle b_button_primary px-6 py-2 lg:mt-4 md:mt-4 mt-2 sm-fsize14"
              >
                {data ? data["4089375841"] : "Discover More"}
              </Button>
            </div>
          </div>
          <div className="lg:w-3/12 md:w-3/12">
            <div id="6847549849" className="dynamicStyle2 relative">
              <Image
                imageClassName="lg:h-96 md:h-72 h-48 w-full rounded-lg object-contain"
                src={data ? getImage(data["6847549849"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-alt"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsletterSection8;
