import React from "react";
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const StepGuide4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="">
          <div className="text-left">
            <ReactQuill
              id="2499948459"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle mt-1"
              value={data ? data["2499948459"] : "Lorem Ipsum"}
            />
            <h2 className="section_header dynamicStyle" id="9943023365">
              {data ? data["9943023365"] : "Lorem Ipsum"}
            </h2>
          </div>
          <div className="lg:mt-12 md:mt-10 mt-8">
            <div
              className="w-full dynamicStyle grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6"
              id="9762434845"
            >
              {data && data["9762434845"]
                ? data["9762434845"].map((e, index) => {
                    return (
                      <div key={index}>
                        <div className="flex items-center theme-bg themeShadow justify-center lg:w-16 lg:h-16 md:w-12 md:h-12 w-12 h-12 bg-ec rounded-full">
                          <p className="fsize22 sm-fsize14 font-semibold text-dark">
                            {e.field1}
                          </p>
                        </div>
                        <div className="mt-2">
                          <h4 className="card_title">{e.field2}</h4>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble card_description"
                            value={e.field3}
                          />
                        </div>
                      </div>
                    );
                  })
                : "no-card"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepGuide4;
