import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";

const AddBanner1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="lg:pt-0 pt-8">
      <div className="discover-h bg-white lg:px-0 px-4 lg:pt-12">
        <div className="lg:w-2/3 md:3/4 w-full mx-auto">
          <p
            id="3261002709"
            className="dynamicStyle section_detail text-center text-primary"
          >
            {data ? data["3261002709"] : "Lorem Ipsum"}
          </p>
          <h3
            id="9562756771"
            className="dynamicStyle section_header text-center font-semibold"
          >
            {data ? data["9562756771"] : "Lorem Ipsum"}
          </h3>
        </div>

        <div
          id="8774217206"
          className="dynamicStyle2 mt-6 lg:mt-12 md:mt-12 w-full"
        >
          <Image
            src={data ? getImage(data["8774217206"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            imageClassName="lg:w-6/12 mx-auto iframe-h rounded-2xl object-cover"
            alt="home"
          />
        </div>
      </div>
      <div className="bg-secondary theme-bg lg:h-40 h-24"></div>
    </section>
  );
};

export default AddBanner1;
