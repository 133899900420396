import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard24 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <div>
      <div className="lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div id="8684765639" className="dynamicStyle ">
          <Swiper
            spaceBetween={0}
            slidesPerView={4}
            observer={true}
            observeParents={true}
            className="mySwiper pad-none"
            autoplay={{
              delay: '1000',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              991: {
                slidesPerView: 2.3,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2.3,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
          >
            {data && data['8684765639']
              ? data['8684765639'].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card className="cursor-pointer">
                        <div className="relative ">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt={e.field2}
                            imageClassName="lg:h-96 md:h-80 h-60 w-full object-cover filter-b4"
                          />
                          <div className="absolute top-0 left-0 lg:h-96 md:h-80 h-60 w-full lg:p-6 mg:p-4 p-3 flex items-center justify-center">
                            <div className="">
                              <h3 className="card_title text-center clr-fff">
                                {e.field2}
                              </h3>
                              <div className="">
                                <ReactQuill
                                  theme="bubble"
                                  readOnly
                                  className="bubble2-w card_description clr-fff text-center"
                                  value={e.field3}
                                />
                                <div className="w-12 h-12 mx-auto mt-4  bg-primary rounded-full flex items-center justify-center">
                                  <FeatherIcon
                                    icon="arrow-right"
                                    className="clr-fff flex"
                                    size="18"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : 'no-card'}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard24;
