import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection25 = ({ pageName }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section className="bg-primary lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12 theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div id="2912694211" className="dynamicStyle newSwiper">
          <Swiper
            // loop={true}
            spaceBetween={20}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            className="mySwiper"
          >
            {data && data["2912694211"]
              ? data["2912694211"].map((e) => {
                  return (
                    <SwiperSlide key={e._id}>
                      <Image
                        src={e ? getImage(e.field1) : ""}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img_hero"
                        imageClassName="h-500px md-h-400px sm-h250px w-full relative filter-b4 object-cover"
                      />
                      <div className="h-500px md-h-400px sm-h250px w-full absolute top-0 left-0 flex justify-start items-center">
                        <div className="lg:px-12 md:px-8 px-5">
                          <h3 className="hero_title clr-fff">{e.field2}</h3>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble card_description lineclamp-quill2"
                            value={e.field3}
                          />

                          <Button
                            outlined
                            className="lg:mt-8 md:mt-8 mt-6 theme-btn sm-fsize14 px-6 py-2"
                          >
                            {e.field4}
                          </Button>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : "no-data"}

            <div className="justify-center lg:pt-12 md:pt-12 pt-8 flex">
              {/* <FeatherIcon icon="arrow-left" className="w-10 h-10  " />
              <FeatherIcon icon="arrow-right" className="w-10 h-10  " /> */}

              <div className="image-swiper-button-prev p-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65"
                  height="65"
                  viewBox="0 0 65 65"
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  className="sm-w-2rem"
                >
                  <path
                    d="M16.2501 43.3334V35.2084L59.5834 35.2084V29.7917L16.2501 29.7917V21.6667L5.41673 32.5L16.2501 43.3334Z"
                    fill="#232323"
                  />
                </svg>
              </div>
              <div className="image-swiper-button-next p-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65"
                  height="65"
                  viewBox="0 0 65 65"
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  className="sm-w-2rem"
                >
                  <path
                    d="M48.7499 21.6666V29.7916H5.41663V35.2083H48.7499V43.3333L59.5833 32.5L48.7499 21.6666Z"
                    fill="#232323"
                  />
                </svg>
              </div>
            </div>
          </Swiper>
        </div>

        <div className="minusMargin-swiper lg:w-1/2 md:w-2/3 w-2/3 ml-auto">
          <Swiper
            onSwiper={setThumbsSwiper}
            // loop={true}
            spaceBetween={20}
            slidesPerView={3}
            FreeMode={true}
            // watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
            breakpoints={{
              1536: {
                spaceBetween: 25,
              },
              1280: {
                spaceBetween: 25,
              },
              1024: {
                spaceBetween: 25,
              },
              820: {
                spaceBetween: 20,
              },
              768: {
                spaceBetween: 20,
              },
              640: {
                spaceBetween: 10,
              },
              425: {
                spaceBetween: 10,
              },
              320: {
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img_hero"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                alt="img_hero"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
                alt="img_hero"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img_hero"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default HeroSection25;
