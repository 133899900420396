import React, { useEffect, useRef, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-14 md:py-16 py-8" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <p
            id="4874395738"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary  cust-textColor"
          >
            {data ? data['4874395738'] : 'Fresh Arrivals'}
          </p>
          <h2 id="6594863381" className="dynamicStyle section_header">
            {data ? data['6594863381'] : 'Discover New Fabric Prints'}
          </h2>
          <div
            ref={elementRef}
            className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
          >
            <div
              id="1006455019"
              className="dynamicStyle lg:mt-16 md:mt-16 mt-12 prime_card_container w-full grid lg:grid-cols-3 md:grid-cols-2 gap-4"
            >
              {data && data['1006455019']
                ? data['1006455019'].map(e => {
                    return (
                      <Card className="prime_card_0 themeShadow theme-bg">
                        <div className=" w-full overflow-hidden bradius-card-top">
                          <Image
                            imageClassName="lg:h-60 h-52 w-full sm-w-full custimghover object-cover"
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt={e.field2}
                          />
                        </div>
                        <div className="px-4 py-3">
                          <p className="card_title">{e.field2}</p>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble card_description"
                            value={e.field3}
                          />
                        </div>
                        <hr className="w-full bdr-btm1" />
                        <div className="px-4 py-3 flex lg:w-1/2  cursor-pointer text-primary cust-textColor">
                          <p className=" sm-fsize14">{e.field4}</p>
                          <ArrowRight className=" ml-2 sm-arrow" />
                        </div>
                      </Card>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard6;
