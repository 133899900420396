import React, { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const AddBanner5 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:block md:block hidden">
          <div className="grid lg:grid-cols-3 md:grid-cols-3 lg:gap-6 md:gap-4 gap-2">
            <div
              id="0586371485"
              className="dynamicStyle2 h-500px md-h-450 sm-h200px relative"
            >
              <div className="gradients">
                <Image
                  imageClassName="h-500px md-h-450 sm-h200px w-full object-cover gradients"
                  src={data ? getImage(data["0586371485"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>

              <p
                id="4682463194"
                className="dynamicStyle w-4/5 absolute bottom-10 z-50 left-6 card_title_0 clr-fff"
              >
                {data ? data["4682463194"] : "Lorem"}
              </p>
            </div>
            <div className="h-500px md-h-450 sm-h300px text-center p-4 flex items-center bg-c2bfbe">
              <div>
                <h2 id="0498098842" className="dynamicStyle hero_title">
                  {data ? data["0498098842"] : "Lorem Ipsum"}
                </h2>
                <ReactQuill
                  id="2317224053"
                  theme="bubble"
                  readOnly
                  className="bubble2 dynamicStyle card_description"
                  value={data ? data["2317224053"] : "Lorem Ipsum"}
                />
                <Button
                  id="0853790589"
                  className="b_button_primary px-6 py-2 mt-6"
                >
                  <span onClick={() => handleClick("0853790589")}>
                    {data ? data["0853790589"] : "Lorem Ipsum"}
                  </span>
                </Button>
              </div>
            </div>
            <div
              id="5725866387"
              className="dynamicStyle2 h-500px md-h-450 sm-h200px relative"
            >
              <div className="gradients">
                <Image
                  imageClassName="h-500px md-h-450 sm-h200px w-full gradients"
                  src={data ? getImage(data["5725866387"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>

              <p
                id="9120182365"
                className="dynamicStyle w-4/5 absolute bottom-10 z-50 left-6 card_title_0 clr-fff"
              >
                {data ? data["9120182365"] : "Men"}
              </p>
            </div>
          </div>
        </div>
        <div className="lg:hidden md:hidden block">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div id="0586371485" className="dynamicStyle2 sm-h250px relative">
              <div className=" gradients">
                <Image
                  imageClassName="sm-h250px w-full object-cover"
                  src={data ? getImage(data["0586371485"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>

              <p
                id="4682463194"
                className="dynamicStyle absolute bottom-8 z-50 left-4 card_title_0 clr-fff"
              >
                {data ? data["4682463194"] : "Women"}
              </p>
            </div>
            <div
              id="5725866387"
              className="dynamicStyle2 h-500px md-h-450 sm-h250px relative"
            >
              <div className=" gradients">
                <Image
                  imageClassName="sm-h250px w-full object-cover"
                  src={data ? getImage(data["5725866387"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img-banner"
                />
              </div>

              <p
                id="9120182365"
                className="dynamicStyle absolute bottom-8 z-50 left-4 card_title_0 clr-fff"
              >
                {data ? data["9120182365"] : "Men"}
              </p>
            </div>
          </div>
          <div className="sm-h200px text-center p-4 flex items-center bg-c2bfbe">
            <div>
              <h2 id="0498098842" className="dynamicStyle hero_title">
                {data ? data["0498098842"] : "Shop Your Favourite Designers"}
              </h2>
              <ReactQuill
                id="2317224053"
                theme="bubble"
                readOnly
                className="card_description bubble2 dynamicStyle"
                value={data ? data["2317224053"] : "Lorem Ipsum"}
              />
              <Button
                id="0853790589"
                className="theme-btn dynamicStyle lg:mt-6 md:mt-6 mt-4 sm-fsize14 b_button_primary px-6 py-2"
              >
                <span onClick={() => handleClick("0853790589")}>
                  {data ? data["0853790589"] : "Discover More"}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner5;
