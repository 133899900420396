import { Card, Image, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EcomProductSection6 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div className="container mx-auto lg:py-16 lg:px-12 md:py-12 md:px-12 px-5 py-6" style={{ background: thisComponent.sectionColor }}>
      
      
      
      <div className="w-full flex justify-between items-center">
        <h2
          id="2757623620"
          className="dynamicStyle font-semibold fsize34 md-fsize24 sm-fsize20 text-primary cust-textColor"
        >
          {data ? data['2757623620'] : 'Product'}
        </h2>
        <div className="">
          <Button
            id='8850337733'
            className="dynamicStyle fsize16 theme-btn2 b_button_primary px-8 py-3 border-1px"
          >
            <span onClick={() => handleClick('8850337733')}>{data ? data['8850337733'] : 'Explore All'}</span> 
          </Button>
        </div>
      </div>
      <div className="lg:mt-8 md:mt-8 mt-4">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper px-2"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Card className="rounded-lg theme-bg themeShadow">
              <div className="relative">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt=""
                  className="rounded-t-lg w-full h-48"
                />
                <div className="absolute category-angle ">
                  <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize10 font-semibold clr-fff cust-textColor">
                      bedroom
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'category-angle-left'
                        : 'category-angle-left-dark'
                    }
                  ></div>
                </div>
              </div>
              <div className="p-3">
                <div className="flex justify-between">
                  <p className="fsize20 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                    Lorem Ipsum
                  </p>
                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize16 md-fsize16 sm-fsize14 font-medium cust-textColor">
                  15LK
                  <span className="fsize10 clr-f4f5f6 textColor-light">
                    /For 700 sq fit
                  </span>
                </p>
                <p className="fsize13 clr-f4f5f6 textColor-light">
                  Green Valley, So Road, MH
                </p>
                <div className="border-1px-vertical py-3 mt-2">
                  <div className="grid grid-cols-3 gap-2">
                    <div
                      className={
                        colorMode === 'light'
                          ? 'bg-d9d9d9 flex justify-center items-center gap-1 py-2 rounded-full'
                          : 'border-1px flex justify-center items-center gap-1 py-2 rounded-full'
                      }
                    >
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt=""
                        className="objext-contain w-6 h-6"
                      />
                      <p className="fsize10 font-semibold cust-textColor">
                        bedroom
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-3">
                  <Button
                   outlined
                    className='w-full px-6 theme-btn2 lg:py-3 md:py-3 py-2'
                  >
                    <div className="flex justify-center items-center gap-1 ">
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt=""
                        className="objext-contain w-6 h-6"
                      />
                      <p className="fsize10 font-semibold cust-textColor">
                        Open Tommorrow 9:45pm
                      </p>
                    </div>
                  </Button>
                </div>
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default EcomProductSection6;
