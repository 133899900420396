import { Card, Image, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import GoogleMap from "google-maps-react-markers"; // Import the GoogleMap component
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const MapSection3 = ({ pageName }) => {
  const coordinates = [
    { lat: 45.4046987, lng: 12.2472504, name: "Place 1" },
    { lat: 46.2046987, lng: 13.2472504, name: "Place 2" },
    // Add more coordinates as needed
  ];
  const mapOptions = {
    // Define your map options here
  };
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const [markers, setMarkers] = useState([]);
  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
    // Create markers
    const newMarkers = coordinates.map(({ lat, lng, name }) => {
      const marker = new maps.Marker({
        position: { lat, lng },
        map,
        title: name,
      });
      marker.addListener("click", () =>
        onMarkerClick({ markerId: name, lat, lng })
      );
      return marker;
    });
    setMarkers(newMarkers);
  };

  const onMarkerClick = ({ markerId, lat, lng }) => {
    console.log("This is ->", markerId);
    // inside the map instance you can call any google maps method
    mapRef.current.setCenter({ lat, lng });
  };

  useEffect(() => {
    // Clean up markers on unmount
    return () => {
      markers.forEach((marker) => marker.setMap(null));
    };
  }, [markers]);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <Card boxShadow={0}>
          <div className="w-full flex rounded-lg border-1px lg:p-4 md:p-3 theme-border-0 p-2 theme-bg themeShadow">
            <div className="w-3/5">
              <div className="flex items-center w-full">
                <h2
                  id="4721585867"
                  className="dynamicStyle w-2/5 fsize20 md-fsize20 sm-fsize16 text_surface600 font-semibold"
                >
                  {data ? data["4721585867"] : "Search by location"}
                </h2>

                <div className="p-inputgroup flex-1">
                  <InputText placeholder="Enter" />
                  <Button icon="pi pi-search" className="bg-primary" />
                </div>
              </div>
              <div className="pt-4">
                <GoogleMap
                  apiKey="AIzaSyBaGHp3hW_TgoyCcbkuUogkIQqzolYdpmc"
                  defaultCenter={{ lat: 45.4046987, lng: 12.2472504 }}
                  defaultZoom={5}
                  options={mapOptions}
                  mapMinHeight="450px"
                  onGoogleApiLoaded={onGoogleApiLoaded}
                  onChange={(map) => console.log("Map moved", map)}
                />
              </div>
            </div>
            <div className="w-2/5 pt-7 ">
              <p
                id="7590393639"
                className="dynamicStyle text-right text_surface600"
              >
                {data ? data["7590393639"] : "Results"}
              </p>
              <div className="overflow-auto h-450px sm-h-350px  py-4 pl-4 pr-2">
                <Card className="mb-4 cursor-pointer border theme-bg themeShadow">
                  <div className="w-full flex p-3">
                    <div className="w-1/4">
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt=""
                        className="w-24 h-20 object-cover rounded-md"
                      />
                    </div>

                    <div className="w-3/4">
                      <div className="flex items-end w-full">
                        <h2 className="w-3/4 card_title_0 font-semibold lineClamp1 text_surface600">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Tenetur, rem?
                        </h2>
                        <p className="w-1/4 fsize10 pb-1 text-gray textColor-light">
                          3 days ago
                        </p>
                      </div>
                      <p className="mb-2 fsize14 md-fsize14 sm-fsize12 text-primary">
                        Applied
                      </p>
                      <div className="flex items-center gap-2">
                        <FeatherIcon
                          icon="map-pin"
                          className="cust-textColor"
                          size={16}
                        />
                        <p className="textColor-light">Mumbai, India</p>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-4 cursor-pointer border theme-bg themeShadow">
                  <div className="w-full flex p-3">
                    <div className="w-1/4">
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt=""
                        className="w-24 h-20 object-cover rounded-md"
                      />
                    </div>

                    <div className="w-3/4">
                      <div className="flex items-end w-full">
                        <h2 className="w-3/4 card_title_0 font-semibold lineClamp1 text_surface600">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Tenetur, rem?
                        </h2>
                        <p className="w-1/4 fsize10 pb-1 text-gray textColor-light">
                          3 days ago
                        </p>
                      </div>
                      <p className="mb-2 fsize14 md-fsize14 sm-fsize12 text-primary">
                        Applied
                      </p>
                      <div className="flex items-center gap-2">
                        <FeatherIcon
                          icon="map-pin"
                          className="cust-textColor"
                          size={16}
                        />
                        <p className="textColor-light">Mumbai, India</p>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-4 cursor-pointer border theme-bg themeShadow">
                  <div className="w-full flex p-3">
                    <div className="w-1/4">
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt=""
                        className="w-24 h-20 object-cover rounded-md"
                      />
                    </div>

                    <div className="w-3/4">
                      <div className="flex items-end w-full">
                        <h2 className="w-3/4 card_title_0 font-semibold lineClamp1 text_surface600">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Tenetur, rem?
                        </h2>
                        <p className="w-1/4 fsize10 pb-1 text-gray textColor-light">
                          3 days ago
                        </p>
                      </div>
                      <p className="mb-2 fsize14 md-fsize14 sm-fsize12 text-primary">
                        Applied
                      </p>
                      <div className="flex items-center gap-2">
                        <FeatherIcon
                          icon="map-pin"
                          className="cust-textColor"
                          size={16}
                        />
                        <p className="textColor-light">Mumbai, India</p>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-4 cursor-pointer border theme-bg themeShadow">
                  <div className="w-full flex p-3">
                    <div className="w-1/4">
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt=""
                        className="w-24 h-20 object-cover rounded-md"
                      />
                    </div>

                    <div className="w-3/4">
                      <div className="flex items-end w-full">
                        <h2 className="w-3/4 card_title_0 font-semibold lineClamp1 text_surface600">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Tenetur, rem?
                        </h2>
                        <p className="w-1/4 fsize10 pb-1 text-gray textColor-light">
                          3 days ago
                        </p>
                      </div>
                      <p className="mb-2 fsize14 md-fsize14 sm-fsize12 text-primary">
                        Applied
                      </p>
                      <div className="flex items-center gap-2">
                        <FeatherIcon
                          icon="map-pin"
                          className="cust-textColor"
                          size={16}
                        />
                        <p className="textColor-light">Mumbai, India</p>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-4 cursor-pointer border theme-bg themeShadow">
                  <div className="w-full flex p-3">
                    <div className="w-1/4">
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt=""
                        className="w-24 h-20 object-cover rounded-md"
                      />
                    </div>

                    <div className="w-3/4">
                      <div className="flex items-end w-full">
                        <h2 className="w-3/4 card_title_0 font-semibold lineClamp1 text_surface600">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Tenetur, rem?
                        </h2>
                        <p className="w-1/4 fsize10 pb-1 text-gray textColor-light">
                          3 days ago
                        </p>
                      </div>
                      <p className="mb-2 fsize14 md-fsize14 sm-fsize12 text-primary">
                        Applied
                      </p>
                      <div className="flex items-center gap-2">
                        <FeatherIcon
                          icon="map-pin"
                          className="cust-textColor"
                          size={16}
                        />
                        <p className="textColor-light">Mumbai, India</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default MapSection3;
