import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";

const Team20 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:w-2/3 md:w-2/3 w-full text-center mx-auto">
          <p id="4337120032" className="dynamicStyle section_detail_0">
            {data ? data["4337120032"] : "Lorem, ipsum"}
          </p>
          <h2 id="3824287231" className="dynamicStyle section_header">
            {data ? data["3824287231"] : "Lorem, ipsum"}
          </h2>
        </div>
        <div
          id="5891977503"
          className="dynamicStyle grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 lg:mt-16 md:mt-12 mt-8  gap-4"
        >
          {data && data["5891977503"]
            ? data["5891977503"].map((e) => {
                return (
                  <Card className="text-center p-6 border1px-black theme-border">
                    <Image
                      src={e ? getImage(e.field1) : ""}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt={e.field2}
                      imageClassName="lg:w-28 lg:h-28 md:w-28 md:h-28 w-24 h-24 rounded-full object-contain mx-auto lg:mb-4 md:mb-3 mb-2"
                    />
                    <h4 className="card_title_0">{e.field2}</h4>
                    <p className="card_description mt-1">{e.field3}</p>
                    <div className="flex justify-center gap-4 lg:mt-6 md:mt-6 mt-4">
                      <FeatherIcon
                        icon="twitter"
                        size={18}
                        className="cursor-pointer"
                      />
                      <FeatherIcon
                        icon="facebook"
                        size={18}
                        className="cursor-pointer"
                      />
                      <FeatherIcon
                        icon="linkedin"
                        size={18}
                        className="cursor-pointer"
                      />
                    </div>
                  </Card>
                );
              })
            : "no-data"}
        </div>
      </div>
    </div>
  );
};

export default Team20;
