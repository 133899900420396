import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection42 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h2 id="1473009227" className="dynamicStyle section_header">
            {data ? data['1473009227'] : 'Lorem ipsum dolor sit amet'}
          </h2>
          <ReactQuill
            id="0547102792"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['0547102792'] : 'Lorem Ipsum'}
          />
        </div>
        <div
          id="3636683722"
          className="dynamicStyle2 h-600px w-full relative lg:mt-16 md:mt-16 mt-12"
        >
          <Image
            src={data ? getImage(data['3636683722']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img-about"
            imageClassName="h-600px w-full object-cover"
          />
          <div className="flex justify-between gap-4 lg:w-3/5 md:w-3/5  about42-top-absl">
            <div className="lg:w-4/12 md:w-4/12">
              <p id="4315624109" className="dynamicStyle card_title">
                {data ? data['4315624109'] : 'Lorem ipsum'}
              </p>
              <ReactQuill
                id="5080357692"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['5080357692'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="lg:w-4/12 md:w-4/12">
              <p id="4149707008" className="dynamicStyle card_title">
                {data ? data['4149707008'] : 'Lorem ipsum'}
              </p>
              <ReactQuill
                id="6016473830"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['6016473830'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
          <div className="flex justify-between gap-4 lg:w-3/5 md:w-3/5  about42-bot-absl">
            <div className="lg:w-4/12 md:w-4/12">
              <p id="9157659619" className="dynamicStyle card_title">
                {data ? data['9157659619'] : 'Lorem ipsum'}
              </p>
              <ReactQuill
                id="7999881989"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['7999881989'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="lg:w-4/12 md:w-4/12">
              <p id="0936366308" className="dynamicStyle card_title">
                {data ? data['0936366308'] : 'Lorem ipsum'}
              </p>
              <ReactQuill
                id="0950808745"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['0950808745'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection42;
