import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection12 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="h-580px md-h-450 sm-h-auto" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container ">
        <div
          ref={elementRef}
          className={`fade-bottom h-580px md-h-450 sm-h-auto w-60 md-w-80 sm-wfull mx-auto flex flex-col justify-center items-center lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-8 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <p
            id="7389880150"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 letter-spacing4px font-medium pb-2 text-primary"
          >
            {data ? data['7389880150'] : 'SBI Foundation'}
          </p>
          <h2
            id="4230572427"
            className="dynamicStyle fsize64 sm-fsize22 font-bold lg:mb-4 md:mb-4 mb-2 text-center sm-lh-32px"
          >
            {data ? data['4230572427'] : 'SBI Foundation'}
          </h2>
          <ReactQuill
            id="6246076629"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle card_description"
            value={data ? data['6246076629'] : 'Lorem Ipsum'}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection12;
