import React, { useEffect } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { Button, Image, useColorMode } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const StepGuide3 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="">
      <div className="">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-4/12 md:w-5/12 w-full">
            <div id="2248739875" className="dynamicStyle2">
              <Image
                src={data ? getImage(data['2248739875']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="w-full h-580px md-h-600px sm-h-250px object-cover"
              />
            </div>
          </div>
          <div className="lg:w-8/12 md:w-7/12 w-full lg:px-6 md:px-6 px-4 lg:mt-0 md:mt-0 mt-4">
            <div className="text-left">
              <ReactQuill
                id="2753662098"
                theme="bubble"
                readOnly
                className={
                  colorMode === 'dark'
                    ? 'dark-bubble bubble dynamicStyle mt-1'
                    : 'bubble dynamicStyle mt-1'
                }
                value={data ? data['2753662098'] : 'Lorem Ipsum'}
              />

              <h2
                className="fsize34 md-fsize28 sm-fsize26 cust-textColor font-semibold text-primary dynamicStyle"
                id="7872375101"
              >
                {data ? data['7872375101'] : 'Lorem Ipsum'}
              </h2>
            </div>
            <div className="lg:mt-6 md:mt-6 mt-4 dynamicStyle" id="3113540464">
              {data && data['3113540464']
                ? data['3113540464'].map((e, index) => {
                    return (
                      <div className="flex items-center gap-4 mb-6">
                        <div className="flex items-center theme-bg themeShadow cust-bgColor justify-center lg:w-20 lg:h-20 md:w-18 md:h-18 w-12 h-12 bg-ec rounded-full">
                          <p className="fsize22 sm-fsize14 font-semibold text-dark cust-textColor">
                            {e.field1}
                          </p>
                        </div>
                        <div className="lg:w-9/12 md:w-10/12 w-9/12">
                          <h4 className="fsize24 md-fsize20 sm-fsize18 text-dark font-semibold cust-textColor">
                            {e.field2}
                          </h4>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className={
                              colorMode === 'dark'
                                ? 'dark-bubble bubble'
                                : 'bubble'
                            }
                            value={e.field3}
                          />
                        </div>
                      </div>
                    );
                  })
                : 'no-card'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepGuide3;
