import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';

const AboutSection31 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex md-reverse gap-6 w-full">
          <div className="w-full">
            <p
              className="uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-primary dynamicStyle"
              id="9186888589"
            >
              {data ? data['9186888589'] : 'Who we are'}
            </p>
            <p className="about_title dynamicStyle" id="3211784218">
              {data
                ? data['3211784218']
                : 'Take you to a better world where everyone drinks Fruit Juice.'}
            </p>
            <ReactQuill
              id="9137511693"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['9137511693'] : 'Lorem Ipsum'}
            />

            <div
              id="4987262265"
              className="dynamicStyle w-full lg:mt-6 md:mt-6 mt-3 lg:mb-0 md:mb-0 mb-6 grid grid-cols-1 gap-y-6"
            >
              {data && data['4987262265']
                ? data['4987262265'].map(e => {
                    return (
                      <Card className="prime_card cust-shadow1 themeShadow theme-bg">
                        <div className="flex items-center w-full">
                          <div className="lg:w-1/5 md:w-1/5 w-1/4">
                            <Image
                              src={e ? getImage(e.field1) : ''}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt={e.field2}
                              imageClassName="lg:h-24 md:h-24 h-16 w-full object-contain rounded-sm"
                            />
                          </div>
                          <div className="ml-4 lg:w-4/5 md:w-4/5 w-3/4">
                            <h5 className="card_title_0 font-semibold">
                              {e.field2}
                            </h5>
                            <ReactQuill
                              id="8410324712"
                              theme="bubble"
                              readOnly
                              className="bubble card_description lineclamp-quill"
                              value={e.field3}
                            />
                          </div>
                        </div>
                      </Card>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
          <div className="w-full dynamicStyle2" id="9920771934">
            <Image
              src={data ? getImage(data['9920771934']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="about"
              imageClassName="rounded-md md-h-400px w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection31;
