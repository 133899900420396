import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection17 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container lg:flex gap-12">
        <div
          ref={elementRef}
          className={`fade-left overflow-hidden w-full h-400px md-h-300px sm-h250px ${
            isVisible ? 'visibleleft' : ''
          } `}
        >
          <div id="3825243837" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['3825243837']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="section-image"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              imageClassName="w-full h-400px md-h-300px rounded-lg sm-h250px object-cover"
            />
          </div>
        </div>
        <div className="w-full lg:mt-0 md:mt-6 mt-6">
          <h2 id="4120609481" className="dynamicStyle uppercase about_title">
            {data ? data['4120609481'] : 'Lorem ipsum'}
          </h2>
          <ReactQuill
            id="8827429270"
            theme="bubble"
            readOnly
            className="card_description bubble dynamicStyle"
            value={data ? data['8827429270'] : 'Lorem Ipsum'}
          />
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-3 mt-6">
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="8189757965"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['8189757965'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="2512327359"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['2512327359'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="3971120611"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['3971120611'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="9487186103"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['9487186103'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="9067442285"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['9067442285'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="2510214038"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['2510214038'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="7335828967"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['7335828967'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="7033624005"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['7033624005'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="7583333711"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['7583333711'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="0662837064"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['0662837064'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="7627721866"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['7627721866'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="5165770746"
                className="dynamicStyle fsize14 textdark textColor-light"
              >
                {data ? data['5165770746'] : 'Lorem ipsum'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection17;
