import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection26 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex gap-10">
          <div className="lg:w-2/5 md:w-2/5 flex items-center lg:text-left md:text-left text-center">
            <div className="">
              <h2
                id="3099981839"
                className="dynamicStyle fsize56 md-fsize40 sm-fsize24 font-semibold text-primary"
              >
                {data ? data['3099981839'] : 'Our Clients'}
              </h2>
              <ReactQuill
                id="6114708870"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle pt-2 card_description sm-textcenter"
                value={data ? data['6114708870'] : 'Lorem Ipsum'}
              />

              <hr className="lg:my-6 md:my-4 my-4 bdr-bottom-line md-bdr-bottom-line sm-bdr-bottom-line" />
              <Button
                outlined
                id="5544481482"
                className="dynamicStyle text-primary border-primary sm-fsize14 theme-btn lg:mt-2 md:mt-2 px-6 py-2"
              >
                <span onClick={() => handleClick('5544481482')}>
                  {data ? data['5544481482'] : 'View More'}
                </span>
              </Button>
            </div>
          </div>
          <div className="lg:w-3/5 md:w-3/5 grid grid-cols-2 lg:gap-12 md:gap-6 gap-4 lg:pt-0 md:pt-0 pt-10">
            <div
              id="6922415526"
              className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
            >
              <Image
                imageClassName="h-180px sm-h-150px w-full rounded-3xl object-cover"
                src={data ? getImage(data['6922415526']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="right-image"
              />
            </div>
            <div
              id="7392460626"
              className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
            >
              <Image
                imageClassName="h-180px sm-h-150px w-full rounded-3xl object-cover"
                src={data ? getImage(data['7392460626']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="right-image"
              />
            </div>
            <div
              id="9091188328"
              className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
            >
              <Image
                imageClassName="h-180px sm-h-150px w-full rounded-3xl object-cover"
                src={data ? getImage(data['9091188328']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="right-image"
              />
            </div>
            <div
              id="8900573488"
              className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
            >
              <Image
                imageClassName="h-180px sm-h-150px w-full rounded-3xl object-cover"
                src={data ? getImage(data['8900573488']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="right-img"
              />
            </div>
            <div
              id="2633222516"
              className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
            >
              <Image
                imageClassName="h-180px sm-h-150px w-full rounded-3xl object-cover"
                src={data ? getImage(data['2633222516']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="right-img"
              />
            </div>
            <div
              id="1819437098"
              className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
            >
              <Image
                imageClassName="h-180px sm-h-150px w-full rounded-3xl object-cover"
                src={data ? getImage(data['1819437098']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="right-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection26;
