import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getData } from "../../../redux/forms/forms-action";
import FeatherIcon from "feather-icons-react";

const CareersDetails = () => {
  const [params, setParams] = useSearchParams();
  const dispatch = useDispatch();
  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    async function mount() {
      if (params) {
        let jobId = params.get("ji");

        let payload = {
          projectId: process.env.REACT_APP_ID,
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
          _id: jobId,
        };
        let data = await dispatch(getData("careers-job_postings", payload));
        if (data) setJobDetails(data.result[0]);
      }
    }
    mount();
  }, [dispatch]);

  return (
    <div className="lg:px-20 md:px-8 px-5">
      <div className="prime_container">
        <div className="w-full mx-auto pt-6">
          <h2 className="font-bold fsize32">{jobDetails.jobtitle}</h2>
          <div className="flex items-center gap-2 fsize20 pt-6">
            <FeatherIcon
              icon="briefcase"
              size={18}
              className="cursor-pointer"
            />
            <p className="">{jobDetails.departmentname}</p>
          </div>
          <div className="flex items-center gap-2 fsize20 pt-6">
            <FeatherIcon icon="map-pin" size={18} className="cursor-pointer" />
            <p className="">{jobDetails.joblocation}</p>
          </div>
          <div className="  pt-10">
            <h2 className="font-bold fsize24 sm-fsize20">Requirements:</h2>
            <p className="text_surface500  fsize16  leading-7 pt-2 ">
              {jobDetails.requirements}
            </p>
          </div>

          <div className="pt-8">
            <h2 className="font-bold fsize24 sm-fsize20">Responsibilities:</h2>
            <p className="text_surface500  fsize16 leading-7 pt-2 ">
              {jobDetails.responsibilities}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersDetails;
