import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Button } from "primereact/button";
import { Image } from "primereact/image";

const CardLayout2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h3 id="3661559307" className="section_header dynamicStyle">
            {data ? data["3661559307"] : "Lorem Ipsum"}
          </h3>
          <hr className="hr-layout2" />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 grid grid-cols-1 gap-6">
          <div className="lg:flex md:flex sm-reverse cust-shadow rounded-3xl overflow-hidden">
            <div className="flex items-center lg:w-1/2 md:w-1/2 w-full lg:px-14 md:px-8 px-6 py-6 theme-bg bg_surface50 ">
              <div className="">
                <h4 className="card_title">Projects</h4>
                <p className="card_description text_surface800">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quasi, accusamus tenetur animi voluptatem necessitatibus
                  inventore.
                </p>

                <Button className="mt-6 px-6 py-2 fsize14 theme-btn" outlined>
                  View Project
                </Button>
              </div>
            </div>
            <div className="h-400px md-h-350px sm-h250px lg:w-1/2 md:w-1/2 w-full">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img-card"
                imageClassName="h-400px md-h-350px sm-h250px w-full object-cover"
              />
            </div>
          </div>
          <div className="lg:flex md:flex cust-shadow rounded-3xl overflow-hidden">
            <div className="h-400px md-h-350px sm-h250px lg:w-1/2 md:w-1/2 w-full">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img-card"
                imageClassName="h-400px md-h-350px sm-h250px w-full object-cover"
              />
            </div>
            <div className="flex items-center lg:w-1/2 md:w-1/2 w-full lg:px-14 md:px-8 px-6 py-6 bg_surface50 theme-bg">
              <div className="">
                <h4 className="card_title text_surface800">Projects</h4>
                <p className="card_description text_surface500">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quasi, accusamus tenetur animi voluptatem necessitatibus
                  inventore.
                </p>

                <Button className="mt-6 px-6 py-2 fsize14 theme-btn" outlined>
                  View Project
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLayout2;
