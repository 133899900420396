import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
    Aos.init();
  }, [selectedSection]);
  //sectioncolor end

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:w-2/3 ms:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6 header_align">
          <h2 id="4745715827" className="dynamicStyle section_header">
            {data ? data["4745715827"] : "Lorem Ipsum"}
          </h2>
          <p id="2752292551" className="dynamicStyle section_detail">
            {data
              ? data["2752292551"]
              : "Tailored Programs to Meet Your Unique Goals"}
          </p>
        </div>
        <div id="6326492275" className="dynamicStyle lg:mt-16 md:mt-16 mt-12">
          <div
            ref={elementRef}
            className={`fade-bottom grid prime_card_container lg:grid-cols-3  md:grid-cols-2 grid-cols-1 gap-4 ${
              isVisible ? "visiblebottom" : ""
            } `}
          >
            {data && data["6326492275"]
              ? data["6326492275"].map((e) => {
                  return (
                    <div className="relative">
                      <Card className="prime_card theme_insertShadow theme-bg">
                        <div className="text-center">
                          <h2 className="card_title">{e.field1}</h2>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble2 card_description"
                            value={e.field2}
                          />
                        </div>
                      </Card>
                    </div>
                  );
                })
              : "no-data"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
