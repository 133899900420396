import apis from '../../apis/client';
export const getBrandProfile = payload => {
  return async dispatch => {
    try {
      let payload = {
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      // let payload = {
      //   projectId: "64143e473a34d024e3482036",
      //   projectName: "nhrdn",
      // };

      let { data } = await apis.brandprofile.getBrandProfile(payload);

      if (data) dispatch(get_brandDetails(data.data[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateBrandProfile = payload => {
  return async dispatch => {
    try {
      // let payload = {
      //   projectId: process.env.REACT_APP_ID,
      //   projectName: process.env.REACT_APP_COMMUNITY_NAME,
      // };
      // let payload = {
      //   projectId: "64143e473a34d024e3482036",
      //   projectName: "nhrdn",
      // };

      let { data } = await apis.brandprofile.updateBrandProfile(payload);

      if (data) dispatch(update_brandDetails(data.data[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const get_brandDetails = data => {
  return {
    type: 'getBrandDetails',
    payload: data,
  };
};
export const update_brandDetails = data => {
  return {
    type: 'updateBrandDetails',
    payload: data,
  };
};
