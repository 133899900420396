import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const Team1 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className="px-5 py-12 lg:px-20 md:px-8 lg:py-20 md:py-16"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="mx-auto text-center lg:w-2/3 md:w-2/3">
          <h2 id="2284418816" className="dynamicStyle section_header">
            {data ? data["2284418816"] : "Meet Aur Professional teams"}
          </h2>
          <ReactQuill
            id="7743809322"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["7743809322"] : "Lorem Ipsum"}
          />
        </div>

        <div
          id="2309352983"
          className="mt-12 lg:mt-16 md:mt-16 prime_card_container dynamicStyle"
        >
          <Swiper
            spaceBetween={15}
            slidesPerView={3}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 2.8,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2.4,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data["2309352983"]
              ? data["2309352983"].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className="relative pb-2 shadow-none bg_none lg:mx-2 md:mx-2"
                        key={index}
                      >
                        <div className="flex justify-center w-full mb-2 lg:mb-4 md:mb-3">
                          <Image
                            src={e ? getImage(e.field1) : ""}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt={e.field2}
                            imageClassName="teams2-img rounded-full object-contain"
                          />
                        </div>
                        <h3 className="text-center card_title_0">{e.field2}</h3>
                        <p className="mt-1 text-center card_description">
                          {e.field3}
                        </p>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : "no-card"}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Team1;
