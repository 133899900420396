import {
  Card,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';

const ManageAccount3 = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <p
        className={
          colorMode === 'light'
            ? 'fsize20 md-fsize20 sm-fsize20 font-semibold text-black theme-textColor'
            : 'fsize20 md-fsize20 sm-fsize20 font-semibold cust-textColor theme-textColor'
        }
      >
        Manage Account
      </p>

      <div className="w-full lg:flex md:flex gap-4 mt-2">
        <Card
          className={
            colorMode === 'light'
              ? 'lg:w-full px-3 py-2 lg:mb-0 md:mb-0 mb-4 cursor-pointer theme-bg themeShadow'
              : 'lg:w-full border-4e5765 px-3 py-2 lg:mb-0 md:mb-0 mb-4 cursor-pointer theme-border-0 theme-bg themeShadow'
          }
        >
          <p className="fsize16 font-medium text-black cust-textColor">
            Activite/Deactivate account
          </p>
          <p class="fsize12 text-black my-1 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
            ratione perspiciatis eveniet itaque eos voluptates nihil reiciendis
            quod alias velit!
          </p>
          <div className="mt-4">
            <p className="cursor-pointer text-primary cust-textColor fsize12 font-semibold">
              Deactivate
            </p>
          </div>
        </Card>

        <Card
          className={
            colorMode === 'light'
              ? 'lg:w-full px-3 py-2 lg:mb-0 md:mb-0 mb-4 cursor-pointer theme-bg themeShadow'
              : 'lg:w-full border-4e5765 px-3 py-2 lg:mb-0 md:mb-0 mb-4 cursor-pointer theme-border-0 theme-bg themeShadow'
          }
        >
          <p className="fsize16 font-medium cust-textColor">Delete account</p>
          <p class="fsize12 text-black my-1 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
            ratione perspiciatis eveniet itaque eos voluptates nihil reiciendis
            quod alias velit!
          </p>
          <div className="mt-4">
            <p className="cursor-pointer text-primary fsize12 font-semibold cust-textColor">
              Delete
            </p>
          </div>
        </Card>
      </div>

      <div className="pt-6 bottom-border-ebebeb">
        <p
          className={
            colorMode === 'light'
              ? 'md:mb-2 mb-1 fsize20 md-fsize18 sm-fsize18 font-semibold text-black theme-textColor'
              : 'md:mb-2 mb-1 fsize20 md-fsize18 sm-fsize18 font-semibold cust-textColor theme-textColor'
          }
        >
          Manage Password
        </p>
      </div>
      <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 lg:py-6 md:py-4 py-2">
        <FormControl>
          <FormLabel
            className={
              colorMode === 'light'
                ? 'fsize14 font-medium text-black theme-textColor'
                : 'fsize14 font-medium cust-textColor theme-textColor'
            }
          >
            Enter Current Password
          </FormLabel>
          <Input
            type="password"
            placeholder="Enter current password here"
            className={
              colorMode === 'light'
                ? 'bg-fff fsize14 theme-bg themeShadow'
                : 'cust-textColor fsize14 theme-bg themeShadow'
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel
            className={
              colorMode === 'light'
                ? 'fsize14 font-medium text-black theme-textColor'
                : 'fsize14 font-medium cust-textColor theme-textColor'
            }
          >
            Enter New Password
          </FormLabel>
          <Input
            type="password"
            placeholder="Enter new password here"
            className={
              colorMode === 'light'
                ? 'bg-fff fsize14 theme-bg themeShadow'
                : 'cust-textColor fsize14 theme-bg themeShadow'
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel
            className={
              colorMode === 'light'
                ? 'fsize14 font-medium text-black theme-textColor'
                : 'fsize14 font-medium cust-textColor theme-textColor'
            }
          >
            Confirm New Password
          </FormLabel>
          <Input
            type="password"
            placeholder="Enter new password here"
            className={
              colorMode === 'light'
                ? 'bg-fff fsize14 theme-bg themeShadow'
                : 'cust-textColor fsize14 theme-bg themeShadow'
            }
          />
        </FormControl>
        <div className="flex items-center lg:pt-6 md:pt-6">
          <p className="fsize14 cust-textColor fweight-500 mr-1">
            forgot your password?
          </p>
          <span className="text-primary textColor-light font-medium fsize14 cursor-pointer">
            Click here
          </span>
        </div>
      </div>
      <div className="mt-2 mb-6">
        <Button
          fontWeight={500}
          className='sm-update-button fsize16 b_button_primary px-6 py-2 theme-btn2'
             
        >
          Update Password
        </Button>
      </div>
    </>
  );
};

export default ManageAccount3;
