import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";

const HeroSection32 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end

  return (
    <div className="relative" style={{ background: thisComponent.sectionColor }}>
      <div className="dynamicStyle2" id="0406501127">
        <Image
          src={data ? getImage(data["0406501127"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="elearning-banner"
          imageClassName="h-400px sm-h300px filter-b4 w-full object-cover"
        />
      </div>
      <div className="absolute top-0 left-0 h-400px sm-h300px w-full flex justify-start items-center">
        <div className="lg:w-6/12 md:w-7/12 w-full">
          <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12 ">
            <h2 className="hero_title clr-fff dynamicStyle" id="4867612140">
              {data ? data["4867612140"] : "Lorem Ipsum"}
            </h2>
            <p
              id="9069879141"
              className="dynamicStyle clr-fff card_description"
            >
              {data ? data["9069879141"] : "Lorem Ipsum"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection32;
