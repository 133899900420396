import React, { Suspense, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux/es/exports';
import { Button, Tooltip } from '@chakra-ui/react';
import Select from 'react-select';
import FeatherIcon from 'feather-icons-react';
import ErrorBoundary from './ErrorBoundary';
import {
  setConversationHistory,
  setConversationHistoryTo,
} from '../../../../redux/Aiassist/Aiassist-actions';
import {
  AiConv,
  studentConv,
  TeacherConv,
  CustomerServ,
  MarketingExec,
  BuyerConv,
  SellerConv,
  CustomerConv,
  ContentCreatorConv,
  DeveloperConv,
  SubscriberConv,
  PartnerConv,
} from './PreConvs/Convjsons';
import {
  AiConvhindi,
  studentConvhindi,
  TeacherConvhindi,
  CustomerServhindi,
  MarketingExechindi,
  BuyerConvhindi,
  SellerConvhindi,
  CustomerConvhindi,
  ContentCreatorConvhindi,
  DeveloperConvhindi,
  SubscriberConvhindi,
  PartnerConvhindi,
} from './PreConvs/Convjsonshindi';
import {
  AiConvspanish,
  studentConvspanish,
  TeacherConvspanish,
  CustomerServspanish,
  MarketingExecspanish,
  BuyerConvspanish,
  SellerConvspanish,
  CustomerConvspanish,
  ContentCreatorConvspanish,
  DeveloperConvspanish,
  SubscriberConvspanish,
  PartnerConvspanish,
} from './PreConvs/Convjsonspan';

import { setTargetLanguage } from '../../../../redux/Aiassist/Aiassist-actions';
import { ExporttoPdf } from './Core/ExporttoPdf';
import { JsonEditor } from 'json-edit-react';
const Chat = ({ section, onOpen, onOpenEdit }) => {
  const dispatch = useDispatch();
  const conversationRef = useRef();
  const scrollRef = useRef();
  const [SectionIndex, setSectionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState({
    value: 'en-US',
    label: 'English',
  });
  const [roleOption, setroleOption] = useState({
    value: 'assistant',
    label: 'Ai assistant',
  });
  let ConversationHistory = useSelector(
    state => state.Aiassist.ConversationHistory
  );
  let targetLanguage = useSelector(state => state.Aiassist.targetLanguage);
  let options = [
    { value: 'en-US', label: 'English' },
    { value: 'es-MX', label: 'Spanish' },
    { value: 'hi-IN', label: 'Hindi' },
  ];
  let roleoptions = [
    {
      value: 'assistant',
      label: 'Ai assistant',
    },
    {
      value: 'Student',
      label: 'Student',
    },
    {
      value: 'Teacher',
      label: 'Teacher',
    },
    {
      value: 'Customer Service',
      label: 'Customer Service',
    },
    {
      value: 'Customer',
      label: 'Customer',
    },
    {
      value: 'Marketing Executive',
      label: 'Marketing Executive',
    },
    {
      value: 'Buyer',
      label: 'Buyer',
    },
    {
      value: 'Seller',
      label: 'Seller',
    },
    {
      value: 'Content Creator',
      label: 'Content Creator',
    },
    {
      value: 'Developer',
      label: 'Developer',
    },
    {
      value: 'Member',
      label: 'Member',
    },
    {
      value: 'Partner',
      label: 'Partner',
    },
  ];
  const handleChange = selectedOption => {
    console.log(selectedOption);
    setSelectedOption(selectedOption);
    dispatch(setTargetLanguage(selectedOption.value));
  };
  const handleroleChange = selectedOption => {
    setroleOption(selectedOption);
  };

  const SettingConv = role => {
    switch (role) {
      case 'assistant':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(AiConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(AiConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(AiConvspanish));
        }

        break;
      case 'Student':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(studentConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(studentConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(studentConvspanish));
        }

        break;
      case 'Teacher':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(TeacherConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(TeacherConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(TeacherConvspanish));
        }

        break;
      case 'Customer Service':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(CustomerServ));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(CustomerServhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(CustomerServspanish));
        }

        break;
      case 'Customer':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(CustomerConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(CustomerConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(CustomerConvspanish));
        }

        break;
      case 'Marketing Executive':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(MarketingExec));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(MarketingExechindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(MarketingExecspanish));
        }

        break;
      case 'Buyer':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(BuyerConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(BuyerConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(BuyerConvspanish));
        }

        break;
      case 'Seller':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(SellerConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(SellerConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(SellerConvspanish));
        }

        break;
      case 'Content Creator':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(ContentCreatorConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(ContentCreatorConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(ContentCreatorConvspanish));
        }

        break;
      case 'Developer':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(DeveloperConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(DeveloperConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(DeveloperConvspanish));
        }

        break;
      case 'Member':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(SubscriberConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(SubscriberConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(SubscriberConvspanish));
        }

        break;
      case 'Partner':
        if (targetLanguage == 'en-US') {
          dispatch(setConversationHistoryTo(PartnerConv));
        }
        if (targetLanguage == 'hi-IN') {
          dispatch(setConversationHistoryTo(PartnerConvhindi));
        }
        if (targetLanguage == 'es-MX') {
          dispatch(setConversationHistoryTo(PartnerConvspanish));
        }

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    SettingConv(roleOption.value);
  }, [roleOption, targetLanguage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
    });
  }, [ConversationHistory]);

  return (
    <div
      className={
        section === true
          ? 'bg-f4f5f6 cust-bgColor h-chat lg:p-5 p-3 w-full rounded-lg relative'
          : 'bg-f4f5f6 cust-bgColor h-chat lg:p-5 p-3 lg:w-7/12 md:w-7/12 w-full rounded-lg relative'
      }
    >
      <div className="flex justify-between items-center w-full">
        <div className="lg:w-4/12 w-6/12">
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
          />
        </div>
        <div className="lg:w-8/12 w-6/12 flex items-center justify-end gap-3">
          <Tooltip label="Chat" className="bg-slate-500">
            <Button
              colorScheme="white"
              className={
                SectionIndex == 0
                  ? 'shadow-inner shadow-black text-primary'
                  : 'shadow-lg shadow-black text-primary'
              }
              onClick={() => {
                setSectionIndex(0);
              }}
              px={2}
            >
              <FeatherIcon icon="message-circle" size="17" />
            </Button>
          </Tooltip>
          <Tooltip label="Settings" className="">
            <Button
              colorScheme="white"
              className={
                SectionIndex == 1
                  ? 'shadow-inner shadow-black text-primary'
                  : 'shadow-lg shadow-black text-primary'
              }
              px={2}
              onClick={() => {
                setSectionIndex(1);
              }}
            >
              <div className="flex flex-col items-center space-y-0.5">
                <FeatherIcon icon="settings" size="17" />
                {/* <FeatherIcon icon="circle" size="5" /> */}
              </div>
            </Button>
          </Tooltip>

          <Tooltip label="Download transcript">
            <Button
              colorScheme="white"
              className={
                SectionIndex == 2
                  ? 'shadow-inner shadow-black text-primary'
                  : 'shadow-lg shadow-black text-primary'
              }
              px={2}
              onClick={() => {
                ExporttoPdf(conversationRef);
              }}
            >
              <FeatherIcon icon="download" size="17" />
            </Button>
          </Tooltip>
          {section === true ? (
            <>
              <div className="lg:flex md:flex hidden">
                <Button className="b_button_primary px-6 py-2" px={5} onClick={onOpen}>
                  <FeatherIcon icon="mic" size="16" className="mr-1 flex" />{' '}
                  Start new
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {SectionIndex == 0 ? (
        <Suspense fallback={<ErrorBoundary />}>
          <div ref={conversationRef} className="h-chatscroll pt-5 lg:pt-10">
            {ConversationHistory.map((bubble, index) => {
              if (index >= 1) {
                if (bubble && bubble.role == 'user') {
                  return (
                    <div className="lg:mt-6 mt-4 text-right flex justify-end">
                      <div className="">
                        <p className="fsize12 text-dark">You</p>
                        <div className="cust-bgf5f5f5 rounded-lg w-max max-chat px-4 py-2 my-1">
                          <p className="whitespace-pre-wrap fsize16 sm-fsize14 text-dark">
                            {bubble.content}
                          </p>
                        </div>
                        <p className="fsize12 text-dark">{bubble.timestamp}</p>
                      </div>
                    </div>
                  );
                } else if (bubble) {
                  return (
                    <div className="lg:mt-6 mt-4 text-left flex justify-start">
                      <div>
                        <p className="fsize12 text-dark">Admin</p>
                        <div className="bg-primary rounded-lg w-max max-chat2 px-4 py-2 my-1">
                          <p className="whitespace-pre-wrap fsize16 sm-fsize14 text-white">
                            {bubble.content}
                          </p>
                        </div>
                        <p className="fsize12 text-dark">{bubble.timestamp}</p>
                      </div>
                    </div>
                  );
                }
              }
            })}
            <div ref={scrollRef}></div>
          </div>
        </Suspense>
      ) : (
        <div className="h-chatscroll pt-5 lg:pt-10">
          <div className="flex flex-col space-y-7">
            <div>
              <div>Select the role that the Ai will play</div>
              <Select
                value={roleOption}
                onChange={handleroleChange}
                options={roleoptions}
              />
            </div>
            <div className="mt-6 ">
              <div>Pre Conversation Context</div>
              <JsonEditor
                rootName="Conversation"
                enableClipboard={false}
                data={ConversationHistory}
                onUpdate={({ newData }) => {
                  dispatch(setConversationHistoryTo(newData));
                  console.log(newData, 'newdata');
                }}
              />
            </div>

            {/* <pre className="w-full  mt-6 bg-orange-500 border border-4 border-dotted">
              <code>{JSON.stringify(ConversationHistory, undefined, 2)}</code>
            </pre> */}
            {/* <div
              onClick={onOpenEdit}
              className="rounded-lg bg-primary  mt-2 p-1 px-6 text-white w-min cursor-pointer"
            >
              Edit
            </div> */}
          </div>
        </div>
      )}

      <div
        className={
          section === true ? '  p-3 absolute bottom-0 left-0 w-full' : 'hidden'
        }
      >
        <div className="bg-ffefd0  rounded-lg p-3">
          <p className="text-center  fsize14">
            You voice chat has been ended. You can
            <span className="text-primary mx-1">email or download</span>
            the transcript. <br></br>Please click on the start button to start a
            new voice chat.
          </p>
        </div>
        <div className="lg:hidden md:hidden block">
          <Button
            colorScheme="primary"
            className="w-full"
            px={6}
            mt={2}
            onClick={onOpen}
          >
            Start New
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Chat;
