import React from 'react';
import { Box, Skeleton, SkeletonText } from '@chakra-ui/react';

const SkeletonCard4ImageRoundedandText2 = () => {
  return (
    <div>
      <div className="lg:w-2/3 md:w-2/3 text-center container mx-auto flex flex-col justify-center">
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
        </Box>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-6 gap-8 lg:mt-12 md:mt-12 mt-6">
        <Box padding="6" boxShadow="lg" bg="white">
          <Skeleton
            width="120px"
            height="120px"
            margin="auto"
            borderRadius={60}
          />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
        </Box>
        <Box padding="6" boxShadow="lg" bg="white">
          <Skeleton
            width="120px"
            height="120px"
            margin="auto"
            borderRadius={60}
          />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
        </Box>
        <Box padding="6" boxShadow="lg" bg="white">
          <Skeleton
            width="120px"
            height="120px"
            margin="auto"
            borderRadius={60}
          />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
        </Box>
        <Box padding="6" boxShadow="lg" bg="white">
          <Skeleton
            width="120px"
            height="120px"
            margin="auto"
            borderRadius={60}
          />
          <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
        </Box>
      </div>
    </div>
  );
};

export default SkeletonCard4ImageRoundedandText2;
