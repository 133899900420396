import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

const AboutSection37 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex  items-center gap-4">
          <div className="lg:w-1/2 md:w-1/2 lg:py-0 md:py-8 py-4">
            <div>
              <h2 id="9307698155" className="dynamicStyle about_title">
                {data ? data['9307698155'] : 'Lorem Ipsum'}
              </h2>
              <ReactQuill
                id="5810008863"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description lg:w-5/6 md:w-5/6"
                value={data ? data['5810008863'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 grid grid-cols-2 lg:gap-8 md:gap-6 gap-4 justify-end w-full lg:py-10 md:py-6 py-4">
            <div className="w-full">
              <Card className="relative cursor-pointer theme-bg detailing-card-shadow about37 lg:h-60 md:h-48 h-40">
                <div className="w-full lg:p-8 md:p-6 p-4 lg:h-60 md:h-48 h-40 flex flex-col items-center justify-center">
                  <p
                    id="0571283944"
                    className="dynamicStyle about_title tracking-wide"
                  >
                    {data ? data['0571283944'] : ' Lorem ipsum'}
                  </p>

                  <ReactQuill
                    id="1512023915"
                    theme="bubble"
                    readOnly
                    className="bubble2 dynamicStyle card_description lineclamp-quill"
                    value={data ? data['1512023915'] : 'Lorem Ipsum'}
                  />
                </div>
                
              </Card>

              <div className=" lg:mt-8 md:mt-6 mt-4">
                {' '}
                <Card className="relative cursor-pointer theme-bg detailing-card-shadow about37 lg:h-60 md:h-48 h-40">
                  <div className="w-full lg:p-8 md:p-6 p-4 lg:h-60 md:h-48 h-40 flex flex-col items-center justify-center">
                    <p
                      id="9738543696"
                      className="dynamicStyle about_title tracking-wide"
                    >
                      {data ? data['9738543696'] : 'Lorem Ipsum'}
                    </p>

                    <ReactQuill
                      id="0897693696"
                      theme="bubble"
                      readOnly
                      className="bubble2 dynamicStyle card_description lineclamp-quill"
                      value={data ? data['0897693696'] : 'Lorem Ipsum'}
                    />
                  </div>
                 
                </Card>
              </div>
            </div>
            <div className="w-full mt-6">
              <div className="">
                {' '}
                <Card className="relative cursor-pointer theme-bg detailing-card-shadow about37 lg:h-60 md:h-48 h-40">
                  <div className="w-full lg:p-8 md:p-6 p-4 lg:h-60 md:h-48 h-40 flex flex-col items-center justify-center">
                    <p
                      id="2671494337"
                      className="dynamicStyle about_title tracking-wide"
                    >
                      {data ? data['2671494337'] : 'Lorem Ipsum'}
                    </p>

                    <ReactQuill
                      id="8715637661"
                      theme="bubble"
                      readOnly
                      className="bubble2 dynamicStyle card_description lineclamp-quill"
                      value={data ? data['8715637661'] : 'Lorem Ipsum'}
                    />
                  </div>
                 
                </Card>
              </div>

              <div className=" lg:mt-8 md:mt-6 mt-4">
                {' '}
                <Card className="relative cursor-pointer theme-bg detailing-card-shadow about37 lg:h-60 md:h-48 h-40">
                  <div className="w-full lg:p-8 md:p-6 p-4 lg:h-60 md:h-48 h-40 flex flex-col items-center justify-center">
                    <p
                      id="0130102364"
                      className="dynamicStyle about_title tracking-wide"
                    >
                      {data ? data['0130102364'] : 'Lorem Ipsum'}
                    </p>

                    <ReactQuill
                      id="5038786761"
                      theme="bubble"
                      readOnly
                      className="bubble2 dynamicStyle card_description lineclamp-quill"
                      value={data ? data['5038786761'] : 'Lorem Ipsum'}
                    />
                  </div>
                 
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection37;
