import React, { useEffect, useRef, useState } from 'react';
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard26 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex lg:px-5 relative">
            <div id="3844731678" className="dynamicStyle2 lg:w-7/12 md:w-7/12">
              <Image
                src={data ? getImage(data["3844731678"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="lg:h-96 md:h-72 h-52 w-full object-cover"
              />
            </div>
            <div className="lg:absolute md:absolute bottom-0 right-0 lg:w-6/12 md:w-6/12 lg:pl-6">
              <h3
                id="7123646034"
                className="dynamicStyle bg_surface50 text_surface800 fsize50 md-fsize32 sm-fsize22 sm-bg-fas font-semibold lg:w-max md:w-max px-2 theme_insertShadow theme-bg cust-shadow1"
              >
                {data ? data["7123646034"] : "Lorem Ipsum"}
              </h3>
              <div className="bg_surface50 cust-shadow1 sm-bg-fas lg:p-8 md:p-4 p-4 lg:mt-12 md:mt-4 mt-2 ">
                <div className="">
                  <p
                    id="9648480547"
                    className="dynamicStyle text_surface500 font-semibold fsize18 md-fsize14 sm-fsize13"
                  >
                    {data ? data["9648480547"] : "Lorem Ipsum"}
                  </p>
                  <h6
                    id="3120868598"
                    className="dynamicStyle card_title lg:mt-3 md:mt-1 mt-2"
                  >
                    {data ? data["3120868598"] : "Lorem Ipsum"}
                  </h6>
                  <ReactQuill
                    id="5942335758"
                    theme="bubble"
                    readOnly
                    className="bubble dynamicStyle card_description"
                    value={data ? data["5942335758"] : "Welcome to My World"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="dynamicStyle lg:mt-8 md:mt-6 mt-4" id="5977237698">
            <Swiper
              spaceBetween={15}
              slidesPerView={3}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper"
              autoplay={{
                delay: "1000",
              }}
              loop
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                991: {
                  slidesPerView: 2.8,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 2.4,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {data && data["5977237698"]
                ? data["5977237698"].map((e, index) => {
                    return (
                      <SwiperSlide>
                        <Card
                          className="relative prime_card_0 overflow-hidden lg:mx-2 md:mx-2"
                          key={index}
                        >
                          <div className="relative feature26 ">
                            <Image
                              src={e ? getImage(e.field1) : ""}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt={e.field2}
                              imageClassName="lg:h-80 md:h-72 h-60 w-full rounded-lg object-cover"
                            />

                            <div className="flex justify-center feature26absolute">
                              <div className="featurehover26 shadow bg_surface50 lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2">
                                <h4 id="" className="dynamicStyle card_title_0">
                                  {e.field2}
                                </h4>
                                <div className="">
                                  <p
                                    id=""
                                    className="dynamicStyle card_description"
                                  >
                                    {e.field3}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : "no-card"}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard26;
