import React, { useEffect, useRef, useState } from "react";
import {
  FormErrorMessage,
  FormControl,
  Input,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Button } from "primereact/button";

const NewsletterSection4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <form
          onSubmit={formik.handleSubmit}
          className="lg:w-3/5 md:w-4/5 w-full mx-auto text-center"
        >
          <h3
            id="3796575135"
            className="dynamicStyle text-primary fsize16 sm-fsize14"
          >
            {data ? data["3796575135"] : "Lorem Ipsum"}
          </h3>
          <p id="9396210770" className="dynamicStyle section_header">
            {data ? data["9396210770"] : "Lorem Ipsum"}
          </p>
          <ReactQuill
            id="9479444568"
            theme="bubble"
            readOnly
            className="bubble bubble2 dynamicStyle section_detail"
            value={data ? data["9479444568"] : "Lorem Ipsum"}
          />

          <FormControl
            isInvalid={!!formik.errors.email && formik.touched.email}
          >
            <InputGroup
              size="lg"
              width=""
              className="mx-auto my-6 lg:w-4/5 w-full"
            >
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                pr="4.5rem"
                placeholder="Enter your email address"
              />
              <InputRightElement width="8rem" className="">
                <Button
                  type="submit"
                  id="8163489221"
                  className="dynamicStyle b_button_primary fsize16 px-2 py-1"
                >
                  {data ? data["8163489221"] : "Submit"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {formik.touched.email && formik.errors.email && (
              <FormErrorMessage className="textwhite">
                {formik.errors.email}
              </FormErrorMessage>
            )}
          </FormControl>
          <ReactQuill
            id="6780829985"
            theme="bubble"
            readOnly
            className="card_description bubble2 dynamicStyle mt-2"
            value={data ? data["6780829985"] : "Lorem Ipsum"}
          />
        </form>
      </div>
    </div>
  );
};

export default NewsletterSection4;
