import { Image, useColorMode } from "@chakra-ui/react";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TopSellingProduct1 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const { colorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <section>
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5" style={{ background: thisComponent.sectionColor }}>
        <div className="flex items-center justify-between w-full">
          <p
            id="9079619542"
            className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold text-black cust-textColor"
          >
            {data ? data["9079619542"] : "Top Selling Products"}
          </p>
          <Button
            id="4832942236"
            className="b_button_primary px-6 py-2 theme-btn dynamicStyle"
          >
            <span onClick={() => handleClick("4832942236")}>
              {data ? data["4832942236"] : "View All"}
            </span>
          </Button>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 lg:mt-12 md:mt-12 mt-8">
          <div
            className={
              colorMode === "light"
                ? "relative cust-shadow border-0 rounded-md themeShadow theme-bg"
                : "relative cust-shadow border1 rounded-md themeShadow theme-bg"
            }
          >
            <div className="rounded-lg bg-red lg:p-4 md:p-3 p-2 flex items-center w-full gap-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/caregiversaathi/CG/JoinUs/About-the-company.jpeg"
                  alt="product_image"
                  className="w-full lg:h-44 md:h-44 h-40 object-cover"
                />
              </div>
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Rating
                  initialRating={5}
                  readonly={true}
                  emptySymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                      size={22}
                    />
                  }
                  fullSymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 sm-star-16  fillstroke sg"
                      size={22}
                    />
                  }
                  fractions={2}
                />
                <p className="fsize24 md-fsize20 sm-fsize18 font-semibold text-black cust-textColor">
                  Title
                </p>
                <div className="flex gap-3">
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium strikethrough text-gray textColor-light">
                    $19.00
                  </p>
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-primary cust-textColor">
                    $19.00
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute cursor-pointer bg-primary bottom-0  right-0 py-2 pl-6 pr-4 rounded-tl-3xl rounded-br-md cust-bgColor">
              <p className="fsize16 font-semibold clr-fff">Add to Cart</p>
            </div>
          </div>
          <div
            className={
              colorMode === "light"
                ? "relative cust-shadow border-0 rounded-md themeShadow theme-bg"
                : "relative cust-shadow border1 rounded-md themeShadow theme-bg"
            }
          >
            <div className="rounded-lg bg-red lg:p-4 md:p-3 p-2 flex items-center w-full gap-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/caregiversaathi/CG/JoinUs/About-the-company.jpeg"
                  alt="product_image"
                  className="w-full lg:h-44 md:h-44 h-40 object-cover"
                />
              </div>
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Rating
                  initialRating={5}
                  readonly={true}
                  emptySymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                      size={22}
                    />
                  }
                  fullSymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 sm-star-16  fillstroke sg"
                      size={22}
                    />
                  }
                  fractions={2}
                />
                <p className="fsize24 md-fsize20 sm-fsize18 font-semibold text-black cust-textColor">
                  Title
                </p>
                <div className="flex gap-3">
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium strikethrough text-gray textColor-light">
                    $19.00
                  </p>
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-primary cust-textColor">
                    $19.00
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute cursor-pointer bg-primary bottom-0  right-0 py-2 pl-6 pr-4 rounded-tl-3xl rounded-br-md cust-bgColor">
              <p className="fsize16 font-semibold clr-fff">Add to Cart</p>
            </div>
          </div>
          <div
            className={
              colorMode === "light"
                ? "relative cust-shadow border-0 rounded-md themeShadow theme-bg"
                : "relative cust-shadow border1 rounded-md themeShadow theme-bg"
            }
          >
            <div className="rounded-lg bg-red lg:p-4 md:p-3 p-2 flex items-center w-full gap-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/caregiversaathi/CG/JoinUs/About-the-company.jpeg"
                  alt="product_image"
                  className="w-full lg:h-44 md:h-44 h-40 object-cover"
                />
              </div>
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Rating
                  initialRating={5}
                  readonly={true}
                  emptySymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                      size={22}
                    />
                  }
                  fullSymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 sm-star-16  fillstroke sg"
                      size={22}
                    />
                  }
                  fractions={2}
                />
                <p className="fsize24 md-fsize20 sm-fsize18 font-semibold text-black cust-textColor">
                  Title
                </p>
                <div className="flex gap-3">
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium strikethrough text-gray textColor-light">
                    $19.00
                  </p>
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-primary cust-textColor">
                    $19.00
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute cursor-pointer bg-primary bottom-0  right-0 py-2 pl-6 pr-4 rounded-tl-3xl rounded-br-md cust-bgColor">
              <p className="fsize16 font-semibold clr-fff">Add to Cart</p>
            </div>
          </div>
          <div
            className={
              colorMode === "light"
                ? "relative cust-shadow border-0 rounded-md themeShadow theme-bg"
                : "relative cust-shadow border1 rounded-md themeShadow theme-bg"
            }
          >
            <div className="rounded-lg bg-red lg:p-4 md:p-3 p-2 flex items-center w-full gap-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/caregiversaathi/CG/JoinUs/About-the-company.jpeg"
                  alt="product_image"
                  className="w-full lg:h-44 md:h-44 h-40 object-cover"
                />
              </div>
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Rating
                  initialRating={5}
                  readonly={true}
                  emptySymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                      size={22}
                    />
                  }
                  fullSymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 sm-star-16  fillstroke sg"
                      size={22}
                    />
                  }
                  fractions={2}
                />
                <p className="fsize24 md-fsize20 sm-fsize18 font-semibold text-black cust-textColor">
                  Title
                </p>
                <div className="flex gap-3">
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium strikethrough text-gray textColor-light">
                    $19.00
                  </p>
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-primary cust-textColor">
                    $19.00
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute cursor-pointer bg-primary bottom-0  right-0 py-2 pl-6 pr-4 rounded-tl-3xl rounded-br-md cust-bgColor">
              <p className="fsize16 font-semibold clr-fff">Add to Cart</p>
            </div>
          </div>
          <div
            className={
              colorMode === "light"
                ? "relative cust-shadow border-0 rounded-md themeShadow theme-bg"
                : "relative cust-shadow border1 rounded-md themeShadow theme-bg"
            }
          >
            <div className="rounded-lg bg-red lg:p-4 md:p-3 p-2 flex items-center w-full gap-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/caregiversaathi/CG/JoinUs/About-the-company.jpeg"
                  alt="product_image"
                  className="w-full lg:h-44 md:h-44 h-40 object-cover"
                />
              </div>
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Rating
                  initialRating={5}
                  readonly={true}
                  emptySymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                      size={22}
                    />
                  }
                  fullSymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 sm-star-16  fillstroke sg"
                      size={22}
                    />
                  }
                  fractions={2}
                />
                <p className="fsize24 md-fsize20 sm-fsize18 font-semibold text-black cust-textColor">
                  Title
                </p>
                <div className="flex gap-3">
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium strikethrough text-gray textColor-light">
                    $19.00
                  </p>
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-primary cust-textColor">
                    $19.00
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute cursor-pointer bg-primary bottom-0  right-0 py-2 pl-6 pr-4 rounded-tl-3xl rounded-br-md cust-bgColor">
              <p className="fsize16 font-semibold clr-fff">Add to Cart</p>
            </div>
          </div>
          <div
            className={
              colorMode === "light"
                ? "relative cust-shadow border-0 rounded-md themeShadow theme-bg"
                : "relative cust-shadow border1 rounded-md themeShadow theme-bg"
            }
          >
            <div className="rounded-lg bg-red lg:p-4 md:p-3 p-2 flex items-center w-full gap-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/caregiversaathi/CG/JoinUs/About-the-company.jpeg"
                  alt="product_image"
                  className="w-full lg:h-44 md:h-44 h-40 object-cover"
                />
              </div>
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <Rating
                  initialRating={5}
                  readonly={true}
                  emptySymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                      size={22}
                    />
                  }
                  fullSymbol={
                    <FeatherIcon
                      icon="star"
                      className="star-hw md-star-12 sm-star-16  fillstroke sg"
                      size={22}
                    />
                  }
                  fractions={2}
                />
                <p className="fsize24 md-fsize20 sm-fsize18 font-semibold text-black cust-textColor">
                  Title
                </p>
                <div className="flex gap-3">
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium strikethrough text-gray textColor-light">
                    $19.00
                  </p>
                  <p className="fsize18 md:fsize18 sm-fsize16 font-medium text-primary cust-textColor">
                    $19.00
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute cursor-pointer bg-primary bottom-0  right-0 py-2 pl-6 pr-4 rounded-tl-3xl rounded-br-md cust-bgColor">
              <p className="fsize16 font-semibold clr-fff">Add to Cart</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopSellingProduct1;
