import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card, Image, useColorMode } from "@chakra-ui/react";
import { Button } from "primereact/button";
import { useSelector } from "react-redux/es/exports";
import UserEffect from "../../../allSections/components/usereeffect/UserEffect";
import { handleScroll } from "../../../allSections/components/fadeeffect/FadeEffect";
import { useNavigate } from "react-router-dom";
const EcomProductSection10 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section className="container mx-auto theme-bg lg:py-16 py-8 lg:px-12 md:px-8 px-5" style={{ background: thisComponent.sectionColor }}>
      <hr class="bdr-btm lg:my-4 md:my-4 my-2 w-full"></hr>
      <h2
        className="fsize34 md-fsize24 sm-fsize20 w-full text-center font-semibold  cust-textColor dynamicStyle"
        id="1607368935"
      >
        {data ? data["1607368935"] : "Enjoy our feature products"}
      </h2>
      <hr class="bdr-btm lg:my-4 md:my-4 my-2 w-full"></hr>
      <div
        ref={elementRef}
        className={`fade-bottom ${isVisible ? "visiblebottom" : ""} `}
      >
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          className="pb-4 px-2 lg:mt-12 md:mt-12 mt-6"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2.3,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          }}
        >
          <SwiperSlide>
            <Card
              className={
                colorMode === "light"
                  ? "box-shadow-none themeShadow theme-bg rem-rounded "
                  : "themeShadow theme-bg rem-rounded"
              }
            >
              <div className="w-full overflow-hidden">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  className="lg:w-full lg:h-76 w-full md:h-72 h-56"
                  alt="women"
                />
              </div>

              <div
                className={
                  colorMode === "light"
                    ? "flex justify-between py-3"
                    : "flex justify-between py-3 px-2"
                }
              >
                <p className="fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor">
                  NATIVE IRON CHAIR
                </p>
                <p class="text-gray textColor-light fsize16 md-fsize16 sm-fsize14 ">
                  $4,990
                </p>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === "light"
                  ? "box-shadow-none themeShadow theme-bg rem-rounded"
                  : "themeShadow theme-bg rem-rounded"
              }
            >
              <div className="w-full overflow-hidden">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  className="lg:w-full lg:h-76 w-full md:h-72 h-56"
                  alt="women"
                />
              </div>
              <div
                className={
                  colorMode === "light"
                    ? "flex justify-between py-3"
                    : "flex justify-between py-3 px-2"
                }
              >
                <p className="fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor">
                  CENTURY DASHE
                </p>
                <p class="text-gray textColor-light fsize16 md-fsize16 sm-fsize14 ">
                  $2,799
                </p>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === "light"
                  ? "box-shadow-none themeShadow theme-bg rem-rounded"
                  : "themeShadow theme-bg rem-rounded"
              }
            >
              <div className="w-full overflow-hidden">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  className="lg:w-full lg:h-76 w-full md:h-72 h-56"
                  alt="women"
                />
              </div>
              <div
                className={
                  colorMode === "light"
                    ? "flex justify-between py-3"
                    : "flex justify-between py-3 px-2"
                }
              >
                <p
                  className=" fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor"
                  id=""
                >
                  NATIVE LIGHT CHAIR
                </p>
                <p class="text-gray textColor-light fsize16 md-fsize16 sm-fsize14 ">
                  $1,799
                </p>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
              className={
                colorMode === "light"
                  ? "box-shadow-none themeShadow theme-bg rem-rounded"
                  : "themeShadow theme-bg rem-rounded"
              }
            >
              <div className="w-full overflow-hidden">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  className="lg:w-full lg:h-76 w-full md:h-72 h-56"
                  alt="women"
                />
              </div>
              <div
                className={
                  colorMode === "light"
                    ? "flex justify-between py-3"
                    : "flex justify-between py-3 px-2"
                }
              >
                <p
                  className="fsize16 md-fsize16 sm-fsize14 text-center text-secondary font-semibold dynamicStyle cust-textColor"
                  id=""
                >
                  PAOLA WOOD LAMP
                </p>
                <p class="text-gray textColor-light fsize16 md-fsize16 sm-fsize14">
                  $1,799
                </p>
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>

      <hr class="bdr-btm lg:my-4 md:my-4 my-2 w-full"></hr>
      <Button
        className="dynamicStyle font-medium theme-btn b_button_primary px-6 py-2 sm-fsize14 theme-btn buttonAnimate"
        id="4114038041"
      >
        <span
          className="flex items-center"
          onClick={() => handleClick("4114038041")}
        >
          {data ? data["4114038041"] : "SHOP ALL"}
        </span>
      </Button>
      <hr class="bdr-btm lg:my-4 md:my-4 my-2 w-full"></hr>
    </section>
  );
};

export default EcomProductSection10;
