import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const AboutSection30 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:grid md:grid lg:grid-rows-3 md:grid-rows-2 grid-flow-col lg:gap-16 md:gap-10">
          <div
            id="1814681852"
            className="dynamicStyle2 row-span-2-3 md-row-span-1-1 lg:mb-0 md:mb-0 mb-4"
          >
            <Image
              imageClassName="w-full h-500px md-h-300px sm-h-200px object-cover"
              src={data ? getImage(data['1814681852']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="about"
            />
          </div>
          <div className="lg:mb-0 md:mb-0 mb-6">
            <p id="3713759620" className="dynamicStyle about_title">
              {data ? data['3713759620'] : 'Lorem Ipsum'}
            </p>

            <ReactQuill
              id="5445938947"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['5445938947'] : 'Lorem Ipsum'}
            />
            <Button
              id="0992184667"
              className="dynamicStyle b_button_primary px-6 py-2 mt-6 sm-fsize13 theme-btn "
            >
              <span onClick={() => handleClick('0992184667')}>
                {data ? data['0992184667'] : 'Know More'}
              </span>

              <FeatherIcon
                icon="arrow-right"
                size="18"
                className="clr-fff ml-2"
              />
            </Button>
          </div>
          <div className="col-span-1 md-col-span-2-1 lg:mb-0 md:mb-0 mb-4">
            <p id="7916056085" className="dynamicStyle about_title">
              {data ? data['7916056085'] : 'Lorem Ipsum'}
            </p>
            <ReactQuill
              id="4411134104"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['4411134104'] : 'Lorem Ipsum'}
            />
            <Button
              id="9961921801"
              className="dynamicStyle b_button_primary px-6 py-2 mt-6 sm-fsize13 theme-btn "
            >
              <span onClick={() => handleClick('9961921801')}>
                {data ? data['9961921801'] : 'Know More'}
              </span>
              <FeatherIcon
                icon="arrow-right"
                size="18"
                className="clr-fff ml-2"
              />
            </Button>
          </div>
          <div
            id="9288377155"
            className="dynamicStyle2 row-span-2 col-span-1 md-col-span-2-1 md-row-span-1-1"
          >
            <Image
              imageClassName="w-full h-500px md-h-300px sm-h-200px object-cover"
              src={data ? getImage(data['9288377155']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection30;
