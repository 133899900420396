import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const CardImage7 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h3 id="8831206802" className="dynamicStyle section_header">
            {data ? data["8831206802"] : "Lorem Ipsum"}
          </h3>
          <ReactQuill
            id="5113732478"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["5113732478"] : "Lorem Ipsum"}
          />
        </div>
        <div
          className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-12 md:mt-10 mt-8"
          id="2616813828"
        >
          {data && data["2616813828"]
            ? data["2616813828"].map((e) => {
                return (
                  <div class="image-block" key={e._id}>
                    <h2 className="text-hover hero_title clr-fff">
                      {e.field2}
                    </h2>
                    <Image
                      src={e ? getImage(e.field1) : ""}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="blogcard"
                      imageClassName="images-blog filter-b4 object-cover w-full"
                    />
                    <div className="figcap">
                      <h3 className="card_title_0 clr-fff">{e.field2}</h3>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble-w card_description mt-1 dynamicStyle"
                        value={e.field3}
                      />

                      <Button className="b_button_primary mt-3 px-4 py-2">
                        {e.field4}
                      </Button>
                    </div>
                  </div>
                );
              })
            : "no-data"}
        </div>
      </div>
    </div>
  );
};

export default CardImage7;
