import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";

const Statistics1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="bg-primary lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8 relative">
      <div className="prime_container">
        <div className="lg:flex items-start w-full">
          <div className="lg:w-4/12 lg:pr-20">
            <h5
              className="section_header clr-fff font-semibold dynamicStyle"
              id="6275260856"
            >
              {data ? data["6275260856"] : "Lorem Ipsum"}
            </h5>
          </div>
          <div className="lg:w-8/12 lg:mt-0 mt-5 grid lg:grid-cols-2 grid-cols-1 gap-10">
            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2" id="3424711024">
                <Image
                  src={data ? getImage(data["3424711024"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  imageClassName="lg:w-20 md:w-20 w-12 lg:h-20 md:h-20 h-12"
                />
              </div>
              <div>
                <h6 className="card_title clr-fff dynamicStyle" id="4734919980">
                  {data ? data["4734919980"] : "Lorem Ipsum"}
                </h6>
                <p
                  className="card_description clr-fff dynamicStyle"
                  id="4401976311"
                >
                  {data ? data["4401976311"] : "Lorem Ipsum"}
                </p>
              </div>
            </div>

            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2" id="5671251216">
                <Image
                  src={data ? getImage(data["5671251216"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  imageClassName="lg:w-20 md:w-20 w-12 lg:h-20 md:h-20 h-12"
                />
              </div>
              <div>
                <h6 className="card_title clr-fff dynamicStyle" id="2506377484">
                  {data ? data["2506377484"] : "Lorem Ipsum"}
                </h6>
                <p
                  className="card_description clr-fff dynamicStyle"
                  id="5249490255"
                >
                  {data ? data["5249490255"] : "Lorem Ipsum"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics1;
