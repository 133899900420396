import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import FeatherIcon from "feather-icons-react";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Testimonial14 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const getAllTestimonial = useSelector(
    (state) => state.testimonial.Testimonial
  );

  const [load, setLoad] = useState();

  useEffect(() => {
    if (getAllTestimonial) {
      setLoad(false);
    } else {
      setLoad(true);
    }
  }, [getAllTestimonial]);

  return (
    <>
      {getAllTestimonial && (
        <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
          <div className="prime_container">
            <div className="lg:w-2/3 md:w-2/3 w-full mx-auto text-center">
              <h2 id="0369742141" className="dynamicStyle section_header">
                {data ? data["0369742141"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="4654501833"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle section_detail"
                value={data ? data["4654501833"] : "Welcome to My World"}
              />
            </div>
            <div className="lg:mt-16 md:mt-16 mt-12">
              <Swiper
                spaceBetween={15}
                slidesPerView={1.3}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 1.8,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 1.8,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {getAllTestimonial?.data?.result.map((testimonial, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className="relative prime_card lg:mx-2 md:mx-2 lg:mb-12 md:mb-12 mb-10"
                        key={index}
                      >
                        <div className="lg:pb-4 md:pb-4 pb-2">
                          <div className="">
                            <Rating
                              initialRating={3}
                              className="flex_rating"
                              readonly={true}
                              emptySymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1"
                                  size={18}
                                />
                              }
                              fullSymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1 fillstroke"
                                  size={18}
                                />
                              }
                              fractions={2}
                            />
                          </div>
                          <div className="lg:h-28 md:h-32 h-32">
                          <p className="mt-4 lineClamp5 card_description">
                            {testimonial.testimonial}
                          </p>
                          </div>
                        </div>
                        <div className="mt-2">
                          <p className="card_title_0">{testimonial.name}</p>
                          <p className="fsize12">
                            {new Date(testimonial.createdAt).toDateString()} • 6
                            min read
                          </p>
                        </div>
                        <Image
                          imageClassName="lg:w-20 lg:h-20 w-12 h-12 minus-absolute rounded-full absolute bottom-0 right-0 lg:mx-6 md:mx-4 mx-2 object-cover"
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                          alt={testimonial.name}
                        />
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonial14;
