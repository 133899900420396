import React, { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const LeftTextRightImg = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
      <div className="prime_container">
        <div className="lg:flex md:flex sm-reverse">
          <div className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-6 relative zindex9 flex flex-col justify-center">
            <h2 id="2491847836" className="dynamicStyle about_title">
              {data ? data["2491847836"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="8410324712"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["8410324712"] : "User Agreement"}
            />
            <div className="dynamicStyle" id="8752237435">
              <Button
                className=" w-30 b_button_primary px-6 py-2 lg:mt-8 md:mt-6 mt-4 theme-btn bdr-2px flex justify-center"
                onClick={() => handleClick("8752237435")}
              >
                <span>{data ? data["8752237435"] : "Lorem"}</span>
              </Button>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 lg:pl-12 md:pl-12">
            <div
              ref={elementRef}
              className={`fade-right relative zindex9 w-full ${
                isVisible ? "visibleright" : ""
              } `}
            >
              <div id="8498811174" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data["8498811174"]) : ""}
                  alt="heroImage"
                  style={{ transform: transform }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-full sm-h250px minh450px object-contain rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftTextRightImg;
