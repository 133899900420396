import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const FAQSection6 = ({ pageName }) => {
          //section color start
          const [fileName, setFileName] = useState("");
          const [thisComponent, setThisComponent] = useState({});
          const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
          const selectedSection = useSelector((state) => state.cms.sectionNames);
          //sectioncolor end
          //sectioncolor start
          useEffect(() => {
            const name = new URL(import.meta.url);
            const filename = name.pathname.split("/").pop();
            console.log(filename);
            const componentName = filename.split(".")[0];
            setFileName(componentName);
        
            for (let i = 0; i < selectedSection[pageName].length; i++) {
              if (
                selectedSection[pageName][i].path.includes(componentName.split(".")[0])
              ) {
                setThisComponent(selectedSection[pageName][i]);
              }
            }
          }, [selectedSection]);
          //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="relative h-580px sm-h-600px" style={{ background: thisComponent.sectionColor }}>
      <div id="0949662539" className="dynamicStyle2 h-580px sm-h-600px w-full">
        <Image
          src={data ? getImage(data['0949662539']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          imageClassName="h-580px sm-h-600px w-full object-cover"
        />
      </div>
      <div className="absl-faq6 top-0 left-0 w-full h-580px sm-h-600px flex items-center justify-center">
        <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
          <div className="prime_container">
            <div className="lg:w-2/3 md:w-3/4 w-full mx-auto text-center">
              <h2
                id="0927075628"
                className="dynamicStyle textwhite section_header"
              >
                {data
                  ? data['0927075628']
                  : 'Questions Thats Comes in your Mind'}
              </h2>
              <ReactQuill
                id="6192491141"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle section_detail"
                value={data ? data['6192491141'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
          <div
            id="5153191935"
            className="dynamicStyle h-350px sm-h-250px md-h-300px overflow-auto lg:mt-6 md:mt-6 mt-4 lg:px-16 md:px-6 px-3 relative "
          >
            <Accordion multiple activeIndex={[0]}>
              {data && data['5153191935']
                ? data['5153191935'].map(e => {
                    return (
                      <AccordionTab
                        header={<h4 className="card_title_0"> {e.field1}</h4>}
                      >
                        <ReactQuill
                          id="7461373914"
                          theme="bubble"
                          readOnly
                          className="bubble card_description"
                          value={e.field2}
                        />
                      </AccordionTab>
                    );
                  })
                : 'no-data'}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection6;
