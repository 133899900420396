import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';
import { Image } from 'primereact/image';

const Clients11 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container ">
        <Marquee
          loop={0}
          autoFill={true}
          id="8963738307"
          className="dynamicStyle"
        >
          {data && data['8963738307']
            ? data['8963738307'].map((e, index) => {
                return (
                  <Image
                    src={e ? getImage(e.field1) : ''}
                    alt="img"
                    imageClassName="lg:h-40 md:h-32 h-24 lg:w-40 md:w-32 w-24 mx-auto object-contain"
                  />
                );
              })
            : 'no-card'}
        </Marquee>
      </div>
    </section>
  );
};

export default Clients11;
