import API from "./repositoryFactory";

//cms
const GET_CLIENT_CMS = "getClientCms";
const CREATE_OTP = "createOtp";
const VERIFY_OTP = "verifyOtp";
const VERIFY_SESSION = "verifySession";
const CLOSE_SESSION = "closeSession";
const UPDATE_CLIENT = "updateClient";

//sections
const GET_SECTIONS = "getSections";
const UPDATE_SECTIONS_DATA = "updateSection";
const ADD_SECTIONS = "addSections";
const DELETE_SECTIONS = "delete";
const UPDATE_INDEX = "updateIndex";

//project
const GET_USER = "getUser";

//SEO
const GET_METABYPAGENAME = "getMetaByPageName";

export default {
  getClientCms(payload, headers) {
    return API.post(apiBaseRouteCMS(GET_CLIENT_CMS), payload, headers);
  },
  createOtp(payload, headers) {
    return API.post(apiBaseRouteCMS(CREATE_OTP), payload, headers);
  },
  verifyOtp(payload, headers) {
    return API.post(apiBaseRouteCMS(VERIFY_OTP), payload, headers);
  },
  verifySession(payload, headers) {
    return API.post(apiBaseRouteCMS(VERIFY_SESSION), payload, headers);
  },
  closeSession(payload, headers) {
    return API.post(apiBaseRouteCMS(CLOSE_SESSION), payload, headers);
  },
  updateClient(payload, headers) {
    return API.post(apiBaseRouteCMS(UPDATE_CLIENT), payload, headers);
  },

  getSections(payload, headers) {
    return API.post(apiBaseRouteSections(GET_SECTIONS), payload, headers);
  },
  addSections(payload, headers) {
    return API.post(apiBaseRouteSections(ADD_SECTIONS), payload, headers);
  },
  delete(payload, headers) {
    return API.post(apiBaseRouteSections(DELETE_SECTIONS), payload, headers);
  },
  updateIndex(payload, headers) {
    return API.post(apiBaseRouteSections(UPDATE_INDEX), payload, headers);
  },

  getUser(payload, headers) {
    return API.post(apiBaseRouteProject(GET_USER), payload, headers);
  },

  //SEO
  getMetaByPageName(payload) {
    return API.post(apiBaseSEOProject(GET_METABYPAGENAME), payload);
  },
  updateSectionData(payload, headers) {
    return API.post(`cms/${UPDATE_SECTIONS_DATA}`, payload);
  },
};

const apiBaseRouteCMS = (route) => {
  return `cms/${route}`;
};

const apiBaseRouteSections = (route) => {
  return `sections/${route}`;
};

const apiBaseRouteProject = (route) => {
  return `project/${route}`;
};

//SEO
const apiBaseSEOProject = (route) => {
  return `seo/${route}`;
};
