import React, { useEffect, useRef, useState } from 'react';
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard29 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex gap-4 justify-between">
            <div className="lg:w-2/5 md:w-1/2">
              <h3 id="1388858038" className="dynamicStyle section_header">
                {data ? data["1388858038"] : "Lorem Ipsum"}
              </h3>
            </div>
            <div className="lg:w-2/5 md:w-1/2">
              <ReactQuill
                id="7546980133"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail_0"
                value={data ? data["7546980133"] : "Welcome to My World"}
              />
            </div>
          </div>
          <div
            className="dynamicStyle w-full lg:mt-16 md:mt-16 mt-12 pb-8"
            id="9454021530"
          >
            <Swiper
              spaceBetween={15}
              slidesPerView={3}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper"
              autoplay={{
                delay: "1000",
              }}
              loop
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                991: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {data && data["9454021530"]
                ? data["9454021530"].map((e, index) => {
                    return (
                      <SwiperSlide>
                        <Card
                          className="relative prime_card overflow-hidden card lg:mx-2 md:mx-2"
                          key={index}
                        >
                          <div className="flex lg:gap-2 md:gap-2">
                            <div className="lg:w-1/6 md:w-1/4 w-1/4">
                              <Image
                                src={e ? getImage(e.field1) : ""}
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                alt={e.field2}
                                imageClassName="lg:h-16 lg:w-16 w-12 h-12 rounded-full object-contain"
                              />
                            </div>
                            <div className="lg:w-5/6 md:w-3/4 w-3/4">
                              <p className="card_title">{e.field2}</p>
                              <ReactQuill
                                theme="bubble"
                                readOnly
                                className="bubble card_description"
                                value={e.field3}
                              />
                            </div>
                          </div>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : "no-card"}
            </Swiper>
          </div>

          <div className="bdr-bot2-e5e5e5"></div>
          <div className="lg:py-10 lg:px-16 md:py-8 py-4 md:px-8 flex">
            <div className="w-1/3 flex flex-col justify-center text-center">
              <p
                id="1275886712"
                className="dynamicStyle fsize32 sm-fsize20 font-semibold text-black"
              >
                {data ? data["1275886712"] : "Lorem"}
              </p>
              <p id="2299300016" className="dynamicStyle card_title">
                {data ? data["2299300016"] : "Lorem Ipsum"}
              </p>
            </div>
            <div className="verticalLine1"></div>
            <div className="w-1/3 flex flex-col justify-center text-center">
              <p
                id="4633370156"
                className="dynamicStyle fsize32 sm-fsize20 font-semibold text-black theme-textColor"
              >
                {data ? data["4633370156"] : "Lorem"}
              </p>
              <p id="5263240078" className="dynamicStyle card_title">
                {data ? data["5263240078"] : "Lorem Ipsum"}
              </p>
            </div>
            <div className="verticalLine1"></div>
            <div className="w-1/3 flex flex-col justify-center text-center">
              <p
                id="8377525562"
                className="dynamicStyle fsize32 sm-fsize20 font-semibold text-black theme-textColor"
              >
                {data ? data["8377525562"] : "Lorem"}
              </p>
              <p id="3932793028" className="dynamicStyle card_title">
                {data ? data["3932793028"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard29;
