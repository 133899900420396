import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";

const GallerySection5 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <h4 id="8393915850" className="dynamicStyle section_header text-center">
          {data ? data["8393915850"] : "Lorem Ipsum"}
        </h4>

        <div className="lg:mt-12 md:mt-10 mt-8 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
          <div class="effect-lily w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img12"
              className="w-full h-full"
            />

            <div className="effectone">
              <h2 className="fsize21 md-fsize18 sm-fsize16 text-left">
                {" "}
                Lorem ipsum may be
              </h2>

              <p className="fsize14 sm-fsize13 text-left">
                Lorem ipsum may be used as a placeholder before the final copy
                is available.
              </p>
            </div>
          </div>

          <div class="effect-honey w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img04"
              className="w-full h-full"
            />

            <div className="effecttwo">
              <h2 className="fsize21 md-fsize18 sm-fsize16 text-left">
                Lorem ipsum may be
              </h2>
            </div>
          </div>

          <div class="effect-oscar w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img06"
              className="w-full h-full"
            />

            <div className="effectthird lg:h-80 h-52">
              <h2 className="fsize20 md-fsize18 sm-fsize16">
                Lorem ipsum may be
              </h2>

              <p className="fsize14 sm-fsize13">
                When Layla appears, she brings an eternal summer along.
              </p>
            </div>
          </div>

          <div class="effect-layla w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img06"
              className="w-full h-full"
            />

            <div className="effectforth lg:h-80 h-52 flex items-center">
              <p className="fsize16 md-fsize14 sm-fsize13">
                When Layla appears, she brings an eternal summer along.
              </p>
            </div>
          </div>

          <div class="effect-chico w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img15"
              className="w-full h-full"
            />

            <div className="effectfifth lg:h-80 h-52 w-full">
              <h2 className="fsize20 md-fsize18 sm-fsize16">Silly Chico</h2>

              <p className="fsize14 sm-fsize13">
                Chico's main fear was missing the morning bus.
              </p>
            </div>
          </div>

          <div class="effect-sarah w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img13"
              className="w-full h-full"
            />

            <div className="effectsixth lg:h-80 h-52 w-full">
              <h2 className="fsize20 md-fsize18 sm-fsize16">Free Sarah</h2>

              <p className="fsize14 sm-fsize13">
                Sarah likes to watch clouds. She's quite depressed.
              </p>
            </div>
          </div>

          <div class="effect-roxy w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img15"
              className="w-full h-full"
            />

            <div className="effectseven lg:h-80 h-52">
              <h2 className="fsize20 md-fsize18 sm-fsize16">Charming Roxy</h2>

              <p className="fsize14 sm-fsize13">
                Roxy was my best friend. She'd cross any border for me.
              </p>
            </div>
          </div>

          <div class="effect-bubba w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img02"
              className="w-full h-full"
            />

            <div className="effecteight lg:h-80 h-52 w-full">
              <h2 className="fsize20 md-fsize18 sm-fsize16">Fresh Bubba</h2>

              <p className="fsize14 sm-fsize13">
                Bubba likes to appear out of thin air.
              </p>
            </div>
          </div>

          <div class="effect-marley w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img11"
              className="w-full h-full"
            />

            <div className="effectnine lg:h-80 h-52 w-full">
              <h2 className="fsize20 md-fsize18 sm-fsize16">Sweet Marley</h2>

              <p className="fsize14 sm-fsize13">
                Marley tried to convince her but she was not interested.
              </p>
            </div>
          </div>

          <div class="effect-zoe w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img25"
              className="w-full h-full"
            />

            <div className="effectten w-full lg:h-80 h-52">
              <h2 className="fsize20 md-fsize18 sm-fsize16">Creative Zeo</h2>

              <p class="description">
                Zoe never had the patience of her sisters. She deliberately
                punched the bear in his face.
              </p>
            </div>
          </div>

          <div class="effect-dexter w-full lg:h-80 h-52">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="img19"
              className="w-full h-full"
            />

            <div className="effecteleven w-full lg:h-80 h-52">
              <h2 className="fsize20 md-fsize18 sm-fsize16">Strange Dexter</h2>

              <p className="fsize14 sm-fsize13">
                Dexter had his own strange way. You could watch him training
                ants.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection5;
