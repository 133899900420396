import React, { useRef, useState } from "react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";

const BlogCategories1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8 theme-bg">
      <div className="prime_container">
        <div className="lg:flex md:flex gap-8 sm-reverse">
          <div className="lg:w-3/4 md:w-3/4 w-full">
            <p id="1864459522" className="dynamicStyle section_header">
              {data ? data["1864459522"] : "Lorem ipsum dolor sit amet"}
            </p>

            <div className="lg:pt-6 md:pt-6 pt-2">
              <div className="lg:flex md:flex w-full gap-6 items-center">
                <div className="lg:h-64 md:h-64 h-52 lg:w-1/3 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-3">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img-blog"
                    imageClassName="lg:h-64 md:h-64 h-52 w-full rounded-xl object-cover"
                  />
                </div>
                <div className="lg:w-2/3 md:w-2/3 w-full">
                  <p className="card_title lineClamp2">Lorem Ipsum</p>
                  <div className="flex gap-4 items-center pb-2">
                    <p className="bg-primary sm-fsize14 fsize16 clr-fff rounded-full py-1 px-4">
                      2020
                    </p>
                    <p className="text_surface500 fsize16 sm-fsize14">
                      Dashboard
                    </p>
                  </div>
                  <p className="lineClamp3 sm-fsize14 card_description">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempora quisquam dignissimos sit soluta doloremque earum,
                    praesentium blanditiis id odio maxime.
                  </p>
                </div>
              </div>
              <hr className="bdr-bottom-d9d8d8 w-full my-6" />
            </div>
            <div className="">
              <div className="lg:flex md:flex w-full gap-6 items-center">
                <div className="lg:h-64 md:h-64 h-52 lg:w-1/3 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-3">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    imageClassName="lg:h-64 md:h-64 h-52 w-full rounded-xl object-cover"
                  />
                </div>
                <div className="lg:w-2/3 md:w-2/3 w-full">
                  <p className="card_title lineClamp2">Lorem Ipsum</p>
                  <div className="flex gap-4 items-center pb-2">
                    <p className="bg-primary sm-fsize14 fsize16 clr-fff rounded-full py-1 px-4">
                      2020
                    </p>
                    <p className="text_surface500 fsize16 sm-fsize14">
                      Dashboard
                    </p>
                  </div>
                  <p className="lineClamp3 sm-fsize14 card_description">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempora quisquam dignissimos sit soluta doloremque earum,
                    praesentium blanditiis id odio maxime.
                  </p>
                </div>
              </div>
              <hr className="bdr-bottom-d9d8d8 w-full my-6" />
            </div>
            <div className="">
              <div className="lg:flex md:flex w-full gap-6 items-center">
                <div className="lg:h-64 md:h-64 h-52 lg:w-1/3 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-3">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    imageClassName="lg:h-64 md:h-64 h-52 w-full rounded-xl object-cover"
                  />
                </div>
                <div className="lg:w-2/3 md:w-2/3 w-full">
                  <p className="card_title lineClamp2">Lorem Ipsum</p>
                  <div className="flex gap-4 items-center pb-2">
                    <p className="bg-primary sm-fsize14 fsize16 clr-fff rounded-full py-1 px-4">
                      2020
                    </p>
                    <p className="text_surface500 fsize16 sm-fsize14">
                      Dashboard
                    </p>
                  </div>
                  <p className="lineClamp3 sm-fsize14 card_description">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempora quisquam dignissimos sit soluta doloremque earum,
                    praesentium blanditiis id odio maxime.
                  </p>
                </div>
              </div>
              {/* <hr className="bdr-bottom-d9d8d8 w-full my-6" /> */}
            </div>
          </div>
          <div className="lg:w-1/4 md:w-1/4  w-full">
            <div>
              <p
                id="7382778855"
                className="dynamicStyle fsize24 font-semibold mt-4 mb-5"
              >
                {data ? data["7382778855"] : "Lorem ipsum dolor sit amet"}
              </p>
              <Card className="prime_card_0 p-3 h-300px sm-h-150px themeShadow">
                <div className="flex flex-wrap gap-2 p-4 ">
                  <p className="themeShadow-insert themeShadow shadow px-4 py-2 rounded-full font-semibold text_surface500 fsize12">
                    Sports
                  </p>

                  <p className="themeShadow-insert themeShadow shadow px-4 py-2 rounded-full font-semibold text_surface500 fsize12">
                    Sports Analyst
                  </p>

                  <p className="themeShadow-insert themeShadow shadow px-4 py-2 rounded-full font-semibold text_surface500 fsize12">
                    Football
                  </p>

                  <p className="themeShadow-insert themeShadow shadow px-4 py-2 rounded-full font-semibold text_surface500 fsize12">
                    Rugby
                  </p>

                  <p className="themeShadow-insert themeShadow shadow px-4 py-2 rounded-full font-semibold text_surface500 fsize12">
                    Rug
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCategories1;
