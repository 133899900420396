import { Button } from "primereact/button";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const DownloadSection1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:flex md:flex items-center w-full">
          <div
            id="5774799724"
            className="dynamicStyle lg:w-1/2 md:w-1/2 w-full"
          >
            {data && data["5774799724"]
              ? data["5774799724"].map((e) => {
                  return (
                    <div className="lg:mb-12 md:mb-12 mb-6">
                      <h2 className="section_header">{e.field1}</h2>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble lg:w-3/4 card_description mt-1"
                        value={e.field2}
                      />

                      <div className="lg:mt-6 md:mt-6 mt-4 flex gap-4 items-center">
                        <Button className="bdr-2px theme-btn b_button_primary px-6 py-2 rounded-lg">
                          {e.field3}
                        </Button>
                        <p className="fsize14 font-medium cust-textColor cursor-pointer">
                          {e.field4}
                        </p>
                      </div>
                    </div>
                  );
                })
              : "no-data"}
          </div>
          <div
            id="4084707970"
            className="dynamicStyle2 lg:mt-0 md:mt-0 mt-8 lg:w-1/2 md:w-1/2 w-full lg:pl-12 md:pl-8"
          >
            <Image
              src={data ? getImage(data["4084707970"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="download"
              imageClassName="w-full h-550px md-h-450px sm-h-350px rounded-sm object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection1;
