import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import ImageGallery from 'react-image-gallery';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import { useSelector } from 'react-redux';

const EcomProductDetails6 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const images = [
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex flex sm-reverse gap-6">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <div>
              <div className="flex items-center justify-between mt-2">
                <div className="">
                  <h6 className="section_header text-dark">Product</h6>
                  <div className="flex items-center gap-2">
                    <Rating
                      initialRating={3}
                      className="flex_rating"
                      readonly={true}
                      emptySymbol={
                        <FeatherIcon
                          icon="star"
                          className="textColor-light"
                          size={18}
                        />
                      }
                      fullSymbol={
                        <FeatherIcon
                          icon="star"
                          className="fillstroke"
                          size={18}
                        />
                      }
                      fractions={2}
                    />
                    <p className="card_description text-gray">4.0 review</p>
                  </div>
                </div>
                <h5 className="section_detail text-dark">$2,400</h5>
              </div>
              <p className="card_description lg:mt-4 md:mt-4 mt-3">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
              <div className="lg:mt-6 md:mt-4 mt-4">
                <div className="flex gap-4">
                  <Button
                    className="dynamicStyle bg-primary w-full flex justify-center clr-fff"
                    id="3692437210"
                  >
                    <span onClick={() => handleClick('3692437210')}>
                      {data ? data['3692437210'] : 'Add to cart'}
                    </span>
                  </Button>
                  <Button
                    outlined
                    className="dynamicStyle w-full flex justify-center"
                    id="1513051692"
                  >
                    <span onClick={() => handleClick('1513051692')}>
                      {data ? data['1513051692'] : 'Wishlist'}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <ImageGallery items={images} />
          </div>
        </div>
        <div className="lg:mt-8 md:mt-6 mt-5">
          <h6 id="6538727245" className="dynamicStyle text-dark section_header">
            {data ? data['6538727245'] : 'Lorem Ipsum'}
          </h6>
          <div className="lg:mt-4 md:mt-2 mt-2">
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              className=""
              breakpoints={{
                1536: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                820: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                325: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              <SwiperSlide>
                <div className="lg:mx-0 md:mx-0 mx-1">
                  <Image
                    className="w-full h-40"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <h5 className="card_title mt-2">Motion Graphics</h5>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mx-0 md:mx-0 mx-1">
                  <Image
                    className="w-full h-40"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <h5 className="card_title mt-2">Motion Graphics</h5>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mx-0 md:mx-0 mx-1">
                  <Image
                    className="w-full h-40"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <h5 className="card_title mt-2">Motion Graphics</h5>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mx-0 md:mx-0 mx-1">
                  <Image
                    className="w-full h-40"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <h5 className="card_title mt-2">Motion Graphics</h5>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mx-0 md:mx-0 mx-1">
                  <Image
                    className="w-full h-40"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <h5 className="card_title mt-2">Motion Graphics</h5>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mx-0 md:mx-0 mx-1">
                  <Image
                    className="w-full h-40"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <h5 className="card_title mt-2">Motion Graphics</h5>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="lg:mx-0 md:mx-0 mx-1">
                  <Image
                    className="w-full h-40"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <h5 className="card_title mt-2">Motion Graphics</h5>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomProductDetails6;
