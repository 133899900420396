import React, { useEffect, useRef, useState } from "react";
import { FaQuoteRight } from "react-icons/fa";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";
import { Image } from "primereact/image";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Testimonial9 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);
  console.log(getAllTestimonial, "check-test");
  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div class="text-center lg:pb-12 md:pb-8 pb-6 lg:w-2/3 md:w-3/4 mx-auto">
          <h2 id="6867065781" class="dynamicStyle section_header">
            {data ? data["6867065781"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="2583623679"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["2583623679"] : "Welcome to My World"}
          />
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? "visiblebottom" : ""} `}
        >
          <Swiper
            spaceBetween={15}
            slidesPerView={1.3}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {getAllTestimonial?.data?.result.map((testimonial, index) => {
              return (
                <SwiperSlide>
                  <Card
                    className="relative prime_card_0 card lg:mx-2 md:mx-2 mt-10"
                    key={testimonial._id}
                  >
                    <div className="pt-10 pb-2">
                      <div className="p-4 themeShadow theme-bg">
                        <div className="lg:h-36 md:h-36 h-36">
                          <p className="text-center card_description lineClamp5">
                            {testimonial.testimonial}
                          </p>
                        </div>
                        <div className="flex gap-4 justify-center items-center lg:pt-4 md:pt-4 pt-2">
                          <div className="">
                            <Image
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                              alt={testimonial.name}
                              imageClassName="img-wh-70 rounded-full object-cover"
                            />
                          </div>
                          <div>
                            <h4 className="card_title_0">{testimonial.name}</h4>
                            {/* <p className="fsize14 sm-fsize12">Developer</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="topc-abs z-40 flex justify-center w-full">
                        <div className="img-wh-65 rounded-full flex items-center justify-center bg-primary cust-bgColor">
                          <FaQuoteRight size="23" className="text-white" />
                        </div>
                      </div>
                    </div>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default Testimonial9;
