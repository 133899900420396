import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection58 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="relative overflow-hidden" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
        <div className="prime_container">
          <div className="lg:flex md:flex block items-center gap-6">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <div className="">
                <h5 id="3508582243" className="dynamicStyle about_title">
                  {data ? data['3508582243'] : 'no-data'}
                </h5>
                <ReactQuill
                  id="5307122872"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_descrition mb-6"
                  value={data ? data['5307122872'] : 'Lorem Ipsum'}
                />

                <Button
                  className="theme-btn b_button_primary px-6 py-2 dynamicStyle fsize14 sm-fsize14"
                  id="1326342140"
                >
                  <span onClick={() => handleClick('1326342140')}>
                    {data ? data['1326342140'] : 'View More'}
                  </span>
                </Button>
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-8">
              <div id="9071423236" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data['9071423236']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img-about"
                  imageClassName="relative z-20 lg:h-96 md:h-72 h-60 object-cover w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="top-0 lg:block md:block hidden right-0 w-96 h-96 bg-ec rounded-full absolute ab-58mtminus cust-bgColor"></div>
    </div>
  );
};

export default AboutSection58;
