import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection8 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div
            ref={elementRef}
            className={`fade-left lg:w-6/12 md:w-6/12 ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <div id="5824890590" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['5824890590']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="scholarship"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                imageClassName="scholarship-img rounded-lg object-cover"
              />
            </div>
          </div>
          <div className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4 md:pl-5 lg:pl-10">
            <p
              className="fsize16 sm-fsize14 textprimary font-medium cust-textColor dynamicStyle"
              id="0617189122"
            >
              {data ? data['0617189122'] : 'Lorem Ipsum'}
            </p>
            <h5 className="about_title dynamicStyle" id="3247560565">
              {data ? data['3247560565'] : 'Lorem Ipsum'}
            </h5>
            <ReactQuill
              id="7112740983"
              theme="bubble"
              readOnly
              className="card-description bubble dynamicStyle"
              value={data ? data['7112740983'] : 'Lorem Ipsum'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection8;
