import { Card } from "primereact/card";
import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Team16 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:w-2/3 md:w-2/3 w-full text-center mx-auto">
          <h2
            id="6582852817"
            className="dynamicStyle section_header text-center"
          >
            {data ? data["6582852817"] : "WHAT PEOPLE SAY ABOUT US"}
          </h2>
          <ReactQuill
            id="3197152449"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["3197152449"] : "Lorem Ipsum"}
          />
        </div>
        <div
          id="2831011237"
          className="dynamicStyle lg:mt-16 md:mt-12 mt-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-x-12 lg:gap-y-16 md:gap-x-12 md:gap-y-16 gap-8 lg:px-8"
        >
          {data && data["2831011237"]
            ? data["2831011237"].map((e) => {
                return (
                  <Card className="bg_none shadow-none">
                    <div className="flex w-full">
                      <div className="w-1/4">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field3}
                          imageClassName="lg:w-24 md:w-16 w-16 lg:h-24 md:h-16 h-16 object-contain rounded-full"
                        />
                      </div>
                      <div className="w-3/4 lg:pl-0 md:pl-0">
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble card_description"
                          value={e.field2}
                        />
                        <div className="lg:pt-3 md:pt-3 pt-2">
                          <h4 className="card_title_0">{e.field3}</h4>
                          <p className="card_description">{e.field4}</p>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })
            : "no-data"}
        </div>
      </div>
    </div>
  );
};

export default Team16;
