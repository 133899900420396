import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard27 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h3
              id="1539466056"
              className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 section_header"
            >
              {data ? data['1539466056'] : 'Lorem Ipsum'}
            </h3>
          </div>
          <div
            id="3984532065"
            className="dynamicStyle lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4"
          >
            {data && data['3984532065']
              ? data['3984532065'].map(e => {
                  return (
                    <Card className="cursor-pointer prime_card_0">
                      <div
                        className="relative feature26 lg:h-80 md:h-72 h-60 rounded-lg"
                      >
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="lg:h-80 md:h-72 h-60 w-full rounded-lg object-cover"
                        />

                        <div className="flex justify-center feature27absolute">
                          <div className="bg_surface50 lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 rounded-t-md theme-bg">
                            <p className="card_title_0">{e.field2}</p>
                            <div className="">
                              <ReactQuill
                                theme="bubble"
                                readOnly
                                className="bubble mt-1 card_description"
                                value={e.field3}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard27;
