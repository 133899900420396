import {
  Card,
  Image,
  useColorMode,
} from '@chakra-ui/react';
import {  useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';

const Category = ({pageName}) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <>
      {/* category */}
      <section className="container mx-auto  lg:px-12 md:px-8 px-5 py-8" style={{ background: thisComponent.sectionColor }}>
        <div className="text-center lg:w-1/2 md:w-2/3 w-full mx-auto lg:mb-12 md:mb-12 mb-6">
          <h3
            id=""
            className="fsize36 md-fsize28 sm-fsize20 font-semibold text-primary cust-textColor mb-2 "
          >
            Popular Categories
          </h3>
          <p id="" className=" fsize16 sm-fsize14 textColor-light">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <div
          id=""
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
        >
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper lg:p-4 md:p-0 p-2"
            breakpoints={{
              1536: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              820: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              505: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              425: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              325: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>

            <SwiperSlide>
              <Card
                className={
                  colorMode === 'light'
                    ? 'borderall box-shadow-none w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-6 md:py-6 py-4'
                    : 'w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-12 md:py-10 py-6'
                }
              >
                <div className="w-full flex justify-center">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="imagee"
                    className="object-cover card-img mb-3 mt-1"
                  />
                </div>

                <p className="fsize18 text-center sm-fsize16 font-medium cust-textColor">
                  Designing
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                className={
                  colorMode === 'light'
                    ? 'borderall box-shadow-none w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-6 md:py-6 py-4'
                    : 'w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-12 md:py-10 py-6'
                }
              >
                <div className="w-full flex justify-center">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="imagee"
                    className="object-cover card-img mb-3 mt-1"
                  />
                </div>

                <p className="fsize18 text-center sm-fsize16 font-medium cust-textColor">
                  Designing
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                className={
                  colorMode === 'light'
                    ? 'borderall box-shadow-none w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-6 md:py-6 py-4'
                    : 'w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-12 md:py-10 py-6'
                }
              >
                <div className="w-full flex justify-center">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="imagee"
                    className="object-cover card-img mb-3 mt-1"
                  />
                </div>

                <p className="fsize18 text-center sm-fsize16 font-medium cust-textColor">
                  Designing
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                className={
                  colorMode === 'light'
                    ? 'borderall box-shadow-none w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-6 md:py-6 py-4'
                    : 'w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-12 md:py-10 py-6'
                }
              >
                <div className="w-full flex justify-center">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="imagee"
                    className="object-cover card-img mb-3 mt-1"
                  />
                </div>

                <p className="fsize18 text-center sm-fsize16 font-medium cust-textColor">
                  Designing
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                className={
                  colorMode === 'light'
                    ? 'borderall box-shadow-none w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-6 md:py-6 py-4'
                    : 'w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-12 md:py-10 py-6'
                }
              >
                <div className="w-full flex justify-center">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="imagee"
                    className="object-cover card-img mb-3 mt-1"
                  />
                </div>

                <p className="fsize18 text-center sm-fsize16 font-medium cust-textColor">
                  Designing
                </p>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                className={
                  colorMode === 'light'
                    ? 'borderall box-shadow-none w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-6 md:py-6 py-4'
                    : 'w-full flex flex-col items-center card lg:px-8 md:px-8 px-5 lg:py-12 md:py-10 py-6'
                }
              >
                <div className="w-full flex justify-center">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="imagee"
                    className="object-cover card-img mb-3 mt-1"
                  />
                </div>

                <p className="fsize18 text-center sm-fsize16 font-medium cust-textColor">
                  Designing
                </p>
              </Card>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="">
          <Button
            className="fsize14 font-normal rounded-100px b_button_primary px-6 py-2"
          >
            View Categories
          </Button>
        </div>
      </section>
    </>
  );
};

export default Category;
