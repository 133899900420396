import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { InputText } from "primereact/inputtext";

const NewsletterSection14 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:w-3/5 md:w-3/5 w-full mx-auto text-center">
          <p id="9443669284" className="dynamicStyle section_header">
            {data ? data["9443669284"] : "Lorem Ipsum"}
          </p>
          <ReactQuill
            id="6581398084"
            theme="bubble"
            readOnly
            className="section_detail bubble2 dynamicStyle"
            value={data ? data["6581398084"] : "Lorem Ipsum"}
          />
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex gap-2 mt-6"
          >
            <div className="w-9/12">
              <InputText
                name="email"
                value={formik.values.email}
                invalid={!!formik.errors.email && formik.touched.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type="text"
                size="lg"
                placeholder="Email"
                className="w-full bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-danger text-left fsize12 mt-2">
                  {formik.errors.email}
                </p>
              )}
            </div>

            <div className="w-3/12">
              <Button
                type="submit"
                className="dynamicStyle b_button_primary flex justify-center lg:px-6 md:px-6 px-2 py-3 theme-btn sm-fsize14 md-fsize14"
                id="1825620230"
              >
                {data ? data["1825620230"] : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection14;
