import React, { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Button } from "primereact/button";
import Aos from "aos";
import "aos/dist/aos.css";
const LeftImgRightText = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
    Aos.init();
  }, [selectedSection]);
  //sectioncolor end

  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  console.log(sectionColor, "check section");

  const elementRef = useRef(null);
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div
      className="lg:flex md:flex container mx-auto  lg:py-16 md:py-10 py-8 lg:px-12 md:px-8 px-5 relative"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-8 md:pr-8 relative zindex9">
        <div id="3787124660" className="dynamicStyle2 w-full">
          {" "}
          <Image
            src={data ? getImage(data["3787124660"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="heroImage"
            className="w-full sm-h250px minh450px object-contain"
          />
        </div>
      </div>
      <div
        className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-4 zindex9 flex flex-col justify-center"
        data-aos="fade-up"
        data-aos-once="true"
      >
        <h2
          id="8001616167"
          className="dynamicStyle lg:text-4xl md:text-2xl  text-xl cust-textColor font-bold"
        >
          {data ? data["8001616167"] : "no-data"}
        </h2>

        <ReactQuill
          id="3526496342"
          theme="bubble"
          readOnly
          className="bubble dynamicStyle mt-2"
          value={data ? data["3526496342"] : "User Agreement"}
        />

        <div className="zindex9 relative">
          <Button
            width="180px"
            height="50px"
            colorScheme="primary"
            className="dynamicStyle lg:mt-8 md:mt-8 mt-6 theme-btn b_button_primary px-6 py-2 rounded-full sm-fsize14 buttonAnimate bdr-2px"
            id="8700048552"
          >
            <span onClick={() => handleClick("8700048552")}>
              {data ? data["8700048552"] : "Find Coach"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeftImgRightText;
