import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard46 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
      <div className="">
        <div className="text-left">
          <ReactQuill
            id="5475488498"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle section_detail_0"
            value={data ? data['5475488498'] : 'Welcome to My World'}
          />

          <h2 className="section_header dynamicStyle" id="1017933531">
            {data ? data['1017933531'] : 'Lorem Ipsum'}
          </h2>
        </div>
        <div className="lg:mt-8 md:mt-6 mt-4 ">
          <div
            className=" w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 dynamicStyle"
            id="1577255523"
          >
            {data && data['1577255523']
              ? data['1577255523'].map((e,index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center gap-4 lg:mb-6 md:mb-4 mb-3"
                    >
                      <div className="flex items-center theme-bg themeShadow cust-bgColor justify-center lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 bg-ec rounded-full">
                        <Image
                          src={process.env.REACT_APP_STORAGE_URL + e.field1}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="lg:w-10 lg:h-10 w-8 h-8 object-contain"
                        />
                      </div>
                      <div className="lg:w-9/12 md:w-10/12 w-9/12">
                        <h4 className="card_title">{e.field2}</h4>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble card_description"
                          value={e.field3}
                        />
                      </div>
                    </div>
                  );
                })
              : 'no-card'}
          </div>
          <div className="w-full lg:mt-6 md:mt-4 mt-4">
            <div id="9210200633" className="dynamicStyle2">
              <Image
                src={data ? getImage(data['9210200633']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="w-full lg:h-96 md:h-60 h-40 object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FeatureCard46;
