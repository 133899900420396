import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard56 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="text-left">
          <ReactQuill
            id="6232007401"
            theme="bubble"
            readOnly
            className="dynamicStyle bubble section_detail_0"
            value={data ? data['6232007401'] : 'no-data'}
          />

          <h2 className="section_header dynamicStyle" id="7101439610">
            {data ? data['7101439610'] : 'Lorem Ipsum'}
          </h2>
        </div>

        <div
          className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 dynamicStyle lg:mt-16 md:mt-16 mt-12"
          id="6504959120"
        >
          {data && data['6504959120']
            ? data['6504959120'].map((e, index) => {
                return (
                  <Card className="prime_card_0">
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL + e.field1}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt={e.field2}
                      imageClassName="w-full lg:h-60 rounded-lg md:h-40 h-40 object-cover"
                    />
                    <div className="p-4">
                      <h4 className="card_title_0">{e.field2}</h4>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="card_description bubble"
                        value={e.field3}
                      />
                    </div>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard56;
