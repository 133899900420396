import { Image } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useState, useRef, useEffect } from 'react';
import GoogleMap from 'google-maps-react-markers'; // Import the GoogleMap component
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const MapSection2 = () => {
  const coordinates = [
    { lat: 45.4046987, lng: 12.2472504, name: 'Place 1' },
    { lat: 46.2046987, lng: 13.2472504, name: 'Place 2' },
    // Add more coordinates as needed
  ];
  const mapOptions = {
    // Define your map options here
  };
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const [markers, setMarkers] = useState([]);
  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
    // Create markers
    const newMarkers = coordinates.map(({ lat, lng, name }) => {
      const marker = new maps.Marker({
        position: { lat, lng },
        map,
        title: name,
      });
      marker.addListener('click', () =>
        onMarkerClick({ markerId: name, lat, lng })
      );
      return marker;
    });
    setMarkers(newMarkers);
  };

  const onMarkerClick = ({ markerId, lat, lng }) => {
    console.log('This is ->', markerId);
    // inside the map instance you can call any google maps method
    mapRef.current.setCenter({ lat, lng });
  };

  useEffect(() => {
    // Clean up markers on unmount
    return () => {
      markers.forEach(marker => marker.setMap(null));
    };
  }, [markers]);

  return (
    <div className="w-full">
      <>
        <GoogleMap
          className="h-450px sm-h-350px"
          apiKey="AIzaSyBaGHp3hW_TgoyCcbkuUogkIQqzolYdpmc"
          defaultCenter={{ lat: 45.4046987, lng: 12.2472504 }}
          defaultZoom={5}
          options={mapOptions}
          mapMinHeight="400px"
          onGoogleApiLoaded={onGoogleApiLoaded}
          onChange={map => console.log('Map moved', map)}
        />
      </>
      <div className="lg:px-20 md:px-8 px-4 lg:py-10 md:py-8 py-6">
        <h4 className="fsize26 md-fsize24 sm-fsize22 font-semibold text-dark cust-textColor">
          Search Result
        </h4>
        <p className="fsize14 text-gray textColor-light mt-1">
          Lorem ipsum is a placeholder text commonly used to demonstrate.
        </p>

        <div className="lg:mt-6 md:mt-2 mt-1">
          <Swiper
            spaceBetween={20}
            className="mySwiper lg:py-2 md:py-1 mapswiper"
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 2.4,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.4,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="rounded-lg border overflow-hidden">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-32"
                />
                <div className="p-3">
                  <h6 className="fsize18 font-semibold text-dark textColor-light">
                    Bright Position
                  </h6>
                  <p className="fsize14 text-gray textColor-light mt-1 lineClamp1">
                    Lorem ipsum is
                  </p>
                  <div className="flex items-start gap-1 mt-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="text-dark flex cust-textColor mt-1"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FeatherIcon
                      icon="star"
                      className="text-dark flex cust-textColor"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      4.3{' '}
                      <span className="font-semibold text-dark textColor-light">
                        45 Reviews
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <Button
                      className="fsize12 mini-btn b_button_primary px-6 py-2"
                      borderRadius={50}
                    >
                      Direction
                    </Button>
                    <div className="border-dark rounded-full p-1">
                      <FeatherIcon
                        icon="share-2"
                        size={16}
                        className="cust-textColor"
                      />
                    </div>
                    <div className="border-dark rounded-full p-1">
                      <FeatherIcon
                        icon="heart"
                        size={16}
                        className="cust-textColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="rounded-lg border overflow-hidden">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-32"
                />
                <div className="p-3">
                  <h6 className="fsize18 font-semibold text-dark textColor-light">
                    Bright Position
                  </h6>
                  <p className="fsize14 text-gray textColor-light mt-1 lineClamp1">
                    Lorem ipsum is
                  </p>
                  <div className="flex items-start gap-1 mt-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="text-dark flex cust-textColor mt-1"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FeatherIcon
                      icon="star"
                      className="text-dark flex cust-textColor"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      4.3{' '}
                      <span className="font-semibold text-dark textColor-light">
                        45 Reviews
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="rounded-lg border overflow-hidden">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-32"
                />
                <div className="p-3">
                  <h6 className="fsize18 font-semibold text-dark textColor-light">
                    Bright Position
                  </h6>
                  <p className="fsize14 text-gray textColor-light mt-1 lineClamp1">
                    Lorem ipsum is
                  </p>
                  <div className="flex items-start gap-1 mt-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="text-dark flex cust-textColor mt-1"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FeatherIcon
                      icon="star"
                      className="text-dark flex cust-textColor"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      4.3{' '}
                      <span className="font-semibold text-dark textColor-light">
                        45 Reviews
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="rounded-lg border overflow-hidden">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-32"
                />
                <div className="p-3">
                  <h6 className="fsize18 font-semibold text-dark textColor-light">
                    Bright Position
                  </h6>
                  <p className="fsize14 text-gray textColor-light mt-1 lineClamp1">
                    Lorem ipsum is
                  </p>
                  <div className="flex items-start gap-1 mt-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="text-dark flex cust-textColor mt-1"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FeatherIcon
                      icon="star"
                      className="text-dark flex cust-textColor"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      4.3{' '}
                      <span className="font-semibold text-dark textColor-light">
                        45 Reviews
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="rounded-lg border overflow-hidden">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-32"
                />
                <div className="p-3">
                  <h6 className="fsize18 font-semibold text-dark textColor-light">
                    Bright Position
                  </h6>
                  <p className="fsize14 text-gray textColor-light mt-1 lineClamp1">
                    Lorem ipsum is
                  </p>
                  <div className="flex items-start gap-1 mt-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="text-dark flex cust-textColor mt-1"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FeatherIcon
                      icon="star"
                      className="text-dark flex cust-textColor"
                      size={14}
                    />
                    <p className="fsize12 text-gray cust-textColor">
                      4.3{' '}
                      <span className="font-semibold text-dark textColor-light">
                        45 Reviews
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default MapSection2;
