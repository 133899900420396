import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard17 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 id="8860505146" className="dynamicStyle section_header">
              {data ? data['8860505146'] : 'Lorem Ipsum'}
            </h2>
            <ReactQuill
              id="1121610911"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['1121610911'] : 'Welcome to My World'}
            />
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:mt-16 md:mt-16 mt-12">
            <div className="box-shadow-none p-2  w-full sm-bdr-leftright">
              <div
                id="1597857281"
                className="dynamicStyle2 flex justify-center w-full "
              >
                <Image
                  src={data ? getImage(data['1597857281']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-full lg:h-28 h-24 p-3 object-cover"
                />
              </div>
            </div>
            <div className="box-shadow-none p-2 border-left-1px sm-bdr-leftright  w-full ">
              <div
                id="7576587922"
                className="dynamicStyle2 flex justify-center w-full "
              >
                <Image
                  src={data ? getImage(data['7576587922']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-full lg:h-28 h-24 p-3 object-cover"
                />{' '}
              </div>
            </div>
            <div className="box-shadow-none p-2 border-left-1px sm-bdr-leftright w-full ">
              <div
                id="3057823580"
                className="dynamicStyle2 flex justify-center w-full "
              >
                <Image
                  src={data ? getImage(data['3057823580']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-full lg:h-28 h-24 p-3 object-cover"
                />
              </div>
            </div>
            <div className="box-shadow-none p-2 border-left-1px sm-bdr-leftright w-full ">
              <div
                id="4280523213"
                className="dynamicStyle2 flex justify-center w-full "
              >
                <Image
                  src={data ? getImage(data['4280523213']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  imageClassName="w-full lg:h-28 h-24 p-3 object-cover "
                />
              </div>
            </div>
          </div>
          <div
            id="6071100912"
            className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pt-16"
          >
            {data && data['6071100912']
              ? data['6071100912'].map((e, index) => {
                  return (
                    <Card className="prime_card_0">
                      <div className="w-full">
                        <Image
                          imageClassName="lg:h-60 h-52 w-full sm-w-full bradius-card-top object-cover"
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                        />
                      </div>
                      <div className="text-center py-4 px-5">
                        <h4 className="card_title">{e.field2}</h4>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble2 card_description pb-6"
                          value={e.field3}
                        />
                      </div>
                    </Card>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard17;
