import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux/es/exports";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Autoplay, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

export const HeroSectionSwiper2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end
  return (
    <>
    <div style={{ background: thisComponent.sectionColor }}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="relative">
            <div
              id="7544886159"
              className="dynamicStyle2 lg:flex md:flex justify-end w-full"
            >
              {/* src={require("../../../assets/images/Award-Banner.jpg")} */}
              <Image
                imageClassName="img-h object-cover"
                src={data ? getImage(data["7544886159"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img_hero"
              />
            </div>
            <div className="lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl bg-secondary theme-bg">
              <p id="5880825648" className="dynamicStyle hero_title">
                {data ? data["5880825648"] : "Lorem Ipsum"}
              </p>
              <ReactQuill
                id="6902025237"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["6902025237"] : "Lorem Ipsum"}
              />

              <div className="lg:mt-8 md:mt-8 mt-6">
                <Button
                  id="6042777790"
                  className="dynamicStyle theme-btn lg:px-4 md:px-4 lg:py-2 md:py-2 px-3 py-1 b_button_primary"
                >
                  <span onClick={() => handleClick("6042777790")}>
                    {data ? data["6042777790"] : "Explore Now"}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <div
              id="9322866124"
              className="dynamicStyle2 lg:flex md:flex justify-end w-full"
            >
              {/* src={require("../../../assets/images/Award-Banner.jpg")} */}
              <Image
                imageClassName="img-h object-cover"
                src={data ? getImage(data["9322866124"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img_hero"
              />
            </div>
            <div className="lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl bg-secondary theme-bg">
              <p id="0948837614" className="dynamicStyle hero_title">
                {data ? data["0948837614"] : "Lorem Ipsum"}
              </p>
              <ReactQuill
                id="4164428297"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["4164428297"] : "Lorem Ipsum"}
              />

              <div className="lg:mt-8 md:mt-8 mt-6">
                <Button
                  id="5976995170"
                  className="dynamicStyle theme-btn lg:px-4 md:px-4 lg:py-2 md:py-2 px-3 py-1 b_button_primary"
                >
                  <span onClick={() => handleClick("5976995170")}>
                    {data ? data["5976995170"] : "Explore Now"}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <div
              id="5316912018"
              className="dynamicStyle2 lg:flex md:flex justify-end w-full"
            >
              {/* src={require("../../../assets/images/Award-Banner.jpg")} */}
              <Image
                imageClassName="img-h object-cover"
                src={data ? getImage(data["5316912018"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img_hero"
              />
            </div>
            <div className="lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl bg-secondary theme-bg">
              <p id="9603027520" className="dynamicStyle hero_title">
                {data
                  ? data["9603027520"]
                  : "Exclusive Deals of Fabric Collection"}
              </p>
              <ReactQuill
                id="8596515534"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["8596515534"] : "Lorem Ipsum"}
              />

              <div className="lg:mt-8 md:mt-8 mt-6">
                <Button
                  id="2867093589"
                  className="dynamicStyle theme-btn lg:px-4 md:px-4 lg:py-2 md:py-2 px-3 py-1 b_button_primary"
                >
                  <span onClick={() => handleClick("2867093589")}>
                    {data ? data["2867093589"] : "Explore Now"}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      </div>
    </>
  );
};
export default HeroSectionSwiper2;
