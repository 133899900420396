import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';
import Select from 'react-select';

const EcomProductDetails1 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const countryPlans = [
    { value: 'IN', label: 'India' },
    { value: 'SG', label: 'Singapore' },
    { value: 'USD', label: 'USA' },
  ];
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const images = [
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];

  const AddToCart = () => {
    navigate('/ecommerce/my-cart');
  };

  const AddToWishlist = () => {
    navigate('/ecommerce/wishlist');
  };

  const data2 = [
    {
      title: 'Display',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
    {
      title: 'Display1',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
  ];

  const data1 = [
    {
      title: 'Display',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
    {
      title: 'Display1',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
  ];

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
      <div className="w-full lg:flex md:block gap-6 lg:mb-16 md:mb-10 mb-6">
        <div className="lg:w-full md:w-full cust-gall">
          <ImageGallery items={images} />
        </div>
        <div className="lg:w-full md:w-full lg:mt-0 md:mt-20 mt-20">
          <h2 className="section_header">Apple Iphone 14 Pro</h2>
          <div className="flex items-center gap-2 lg:mt-4 md:mt-2 mt-2">
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
          </div>
          <div className="flex flex-wrap items-center lg:gap-2 md:gap-2 mt-2 lg:mb-4 md:mb-4 mb-2 cust-textColor">
            <p className="section_detail_0 text-dark">₹123456</p>
            <p className="section_detail_0 strikethrough">₹123456</p>
            <p className="section_detail_0 text-dark">
              (10% off) inclusive all taxes
            </p>
          </div>
          <p className="card_description lg:mb-4 md:mb-4 mb-4">
            The iPhone 14 models come in blue, purple, midnight, starlight, and
            (PRODUCT)RED, plus Apple added a new yellow color in March. Unlike
            the iPhone 14 Pro models, the iPhone 14 and 14 Plus continue to have
            a notch at the top of the display that houses the TrueDepth camera.
            Like the iPhone 13 models, the iPhone 14 and iPhone 14 Plus include
            Super Retina XDR OLED displays that support 1200 nits peak
            brightness, a 2,000,000:1 contrast ratio, Dolby Vision, and True
            Tone support for matching the white balance of the display to the
            lighting in the room.
          </p>
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
            <div className="lg:w-full">
              <h2 id="1502223203" className="dynamicStyle text-dark card_title">
                {data ? data['1502223203'] : 'Color:'}
              </h2>
              <div className="flex">
                <div className="lg:mr-2">
                  <div className="chips-select fsize14 theme-btn2 sm-fsize12">
                    <p>Deep purple</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-full">
              <h2 id="0413592705" className="dynamicStyle text-dark card_title">
                {data ? data['0413592705'] : 'Size:'}
              </h2>
              <div className="w-full">
              <Select
                        className="w-full"
                        options={countryPlans}
                      />
              </div>
            </div>
            <div className="lg:w-full">
            <h2 id="6448951598" className="dynamicStyle text-dark card_title">
              {data ? data['6448951598'] : 'Quantity:'}
            </h2>
            <div className="counter">
              <div className="flex items-center">
                <span className="cursor-pointer p-2 cust-textColor">-</span>
                <input
                  type="text"
                  className="counter-input cust-textColor"
                  value="1"
                />
                <span className="cursor-pointer p-2 cust-textColor">+</span>
              </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:mt-8 md:mt-8 mt-4 flex lg:gap-6 md:gap-6 gap-4">
            <div className="w-full">
              <Button
                className="dynamicStyle bg-primary w-full flex justify-center clr-fff"
                id="3692437210"
                onClick={AddToCart}
              >
                <span onClick={() => handleClick('3692437210')}>
                  {data ? data['3692437210'] : 'Add to cart'}
                </span>
              </Button>
            </div>
            <div className=" w-full">
              <Button
                outlined
                className="dynamicStyle w-full flex justify-center"
                id="1513051692"
                onClick={AddToWishlist}
              >
                <span onClick={() => handleClick('1513051692')}>
                  {data ? data['1513051692'] : 'Wishlist'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="lg:w-3/4 md:w-11/12">
          <p
            id="7595406848"
            className="dynamicStyle text-primary section_detail"
          >
            {data ? data['7595406848'] : 'Product Information'}
          </p>
          <h2 className="section_header">Apple Iphone 14 Pro</h2>
          <div className="">
            <p
              id="4590260616"
              className="dynamicStyle text-dark card_description mt-2"
            >
              {data ? data['4590260616'] : 'Description'}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-6 lg:mt-8 md:mt-6 mt-5">
        <div className="lg:w-full">
          <h2 id="2552648406" className="dynamicStyle card_title text-dark">
            {data ? data['2552648406'] : 'Technical Specifications'}
          </h2>
          {data2 && data2.length > 0
            ? data2.map((item, index) => (
                <div key={index} className="border-1px lg:w-full grid grid-cols-2">
                  <div className="border-r-1px p-4">
                    <p className="card_title_0">{item.title}</p>
                  </div>
                  <div className="p-4">
                    <p className="card_description">{item.content}</p>
                  </div>
                </div>
              ))
            : 'no-data'}
        </div>
        <div className="lg:w-full">
          <h2 id="8456911288" className="dynamicStyle card_title text-dark">
            {data ? data['8456911288'] : 'Additional Information'}
          </h2>
          {data1
            ? data1.map((item, index) => (
                <div key={index} className="border-1px lg:w-full grid grid-cols-2">
                  <div className="border-r-1px p-4">
                    <p className="card_title_0">{item.title}</p>
                  </div>
                  <div className="p-4">
                    <p className="card_description">{item.content}</p>
                  </div>
                </div>
              ))
            : 'no-data'}
        </div>
      </div>
    </div>
    </div>
  );
};

export default EcomProductDetails1;
