import React, { useEffect, useRef, useState } from "react";
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const FeatureCard50 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:flex md:flex" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:w-5/12 md:w-5/12">
        <div id="1527462115" className="dynamicStyle2">
          <Image
            src={data ? getImage(data['1527462115']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            imageClassName="w-full h-600px md-h-550 sm-h-250px object-cover"
          />
        </div>
      </div>
      <div className="lg:w-7/12 md:w-7/12 lg:px-10 md:px-4 px-5 lg:py-10 md:py-8 py-8">
        <div className="text-left">
          <ReactQuill
            id="6265403594"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle section_detail_0"
            value={data ? data['6265403594'] : 'Lorem'}
          />

          <h2 className="section_header dynamicStyle" id="9067603864">
            {data ? data['9067603864'] : 'Lorem Ipsum'}
          </h2>
        </div>
        <div
          className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-4 dynamicStyle lg:mt-6 md:mt-5 mt-4"
          id="7790541022"
        >
          {data && data['7790541022']
            ? data['7790541022'].map((e, index) => {
                return (
                  <div className="flex items-center gap-4 lg:mb-4 md:mb-3 mb-3">
                    <div className="flex items-center theme-bg themeShadow justify-center">
                      <Image
                        src={process.env.REACT_APP_STORAGE_URL + e.field1}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt={e.field2}
                        imageClassName="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 object-contain rounded-full"
                      />
                    </div>
                    <div className="lg:w-9/12 md:w-9/12 w-9/12">
                      <h4 className="card_title">{e.field2}</h4>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field3}
                      />
                    </div>
                  </div>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard50;
