import { Image } from "primereact/image";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Team14 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:flex md:flex items-center gap-10">
          <div className="lg:w-1/5 md:w-1/5 w-full">
            <div id="0199467272" className="dynamicStyle2 lg:mb-4 md:mb-3 mb-2">
              <Image
                src={data ? getImage(data["0199467272"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="lg:h-52 lg:w-52 md:w-32 md:h-32 w-28 h-28 mx-auto rounded-full object-contain"
              />
            </div>
            <h4
              id="4460342497"
              className="dynamicStyle card_title_0 text-center"
            >
              {data ? data["4460342497"] : "lorem ipsum"}
            </h4>
            <div className="theme-textColor flex justify-center gap-4 mt-2">
              <FeatherIcon
                icon="linkedin"
                size={20}
                className="cursor-pointer"
              />
              <FeatherIcon
                icon="twitter"
                size={20}
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="lg:w-3/5 md:w-3/5 w-full cust-textColor text-center lg:py-0 md:py-0 py-8">
            <h2
              id="9052421694"
              className="dynamicStyle section_header text-center"
            >
              {data ? data["9052421694"] : "lorem ipsum"}
            </h2>
            <ReactQuill
              id="4382491530"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data["4382491530"] : "Lorem Ipsum"}
            />
            <Button
              className="lg:mt-6 md:mt-4 mt-3 mx-auto b_button_primary px-6 py-2"
              id="9318549936"
            >
              <span onClick={() => handleClick("9318549936")}>
                {data ? data["9318549936"] : "Button"}
              </span>
            </Button>
          </div>
          <div className="lg:w-1/5 md:w-1/5 w-full">
            <div id="2226894602" className="dynamicStyle2 lg:mb-4 md:mb-3 mb-2">
              <Image
                src={data ? getImage(data["2226894602"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="Img_Preview"
                imageClassName="lg:h-52 lg:w-52 md:w-32 md:h-32 w-28 h-28 mx-auto rounded-full object-contain"
              />
            </div>
            <h4
              id="6115359619"
              className="dynamicStyle card_title_0 text-center"
            >
              {data ? data["6115359619"] : "lorem ipsum"}
            </h4>
            <div className="theme-textColor flex justify-center gap-4 mt-2">
              <FeatherIcon
                icon="linkedin"
                size={20}
                className="cursor-pointer"
              />
              <FeatherIcon
                icon="twitter"
                size={20}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team14;
