import React from "react";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Bullets1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <p
          id="4981079605"
          className="dynamicStyle fsize16 sm-fsize14 text-primary font-semibold"
        >
          {data ? data["4981079605"] : "Lorem Ipsum"}
        </p>
        <h5 id="7119205092" className="dynamicStyle section_header">
          {data ? data["7119205092"] : "Lorem Ipsum"}
        </h5>
        <ReactQuill
          id="4926007979"
          theme="bubble"
          readOnly
          className="card_description bubble dynamicStyle"
          value={data ? data["4926007979"] : "Lorem Ipsum"}
        />

        <ul className="mt-6">
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Primary Healthcare:
            </span>
            Emphasizing preventive care and equal access to essential services
            for lasting well-being.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Cancer Care:
            </span>
            Providing support for cancer awareness, diagnosis, and financial
            assistance to enhance the lives of patients and families.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Palliative Care:
            </span>
            Alleviating suffering and promoting dignity for those facing serious
            illnesses to improve overall quality of life.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Cancer Care:
            </span>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before final copy is available.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Eye Care:
            </span>
            Addressing the need for accessible eye care through awareness, early
            detection, and affordable services for a better quality of life.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Infrastructural Support:
            </span>
            Upgrading hospitals, equipment, and establishing healthcare
            infrastructure in underserved areas to ensure quality services.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Mental Health:
            </span>
            Promoting awareness, providing support, and combating stigma
            associated with mental health issues for overall well-being.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Organ Donation:
            </span>
            Advocating and facilitating organ donation processes to support
            individuals involved in transplantation.
          </li>
          <li className="list-dot fsize14 sm-fsize14 clr-797979 mt-2">
            <span className="font-semibold fsize16 clr-141414 mr-2">
              Technology and Innovation Support:
            </span>
            Backing research initiatives for new treatments and technologies to
            advance healthcare practices for sustainable community impact.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Bullets1;
