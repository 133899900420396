import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
// import { AspectRatio } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const ContactDetails1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <section className="relative form-img" style={{ background: thisComponent.sectionColor }}>
      <div id="3163284879" className="dynamicStyle2 cus-hbanner w-full">
        <Image
          src={data ? getImage(data['3163284879']) : ''}
          imageClassName="form-img"
          alt="form"
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        />
      </div>

      <div className="absolute top-0 left-0 w-full flex items-center form-img">
        <div className="lg:px-20 md:px-8 px-5">
          <div className="prime_container">
            <div className="lg:flex md:flex items-center">
              <div
                ref={elementRef}
                className={`fade-left lg:w-6/12 md:w-6/12 lg:pr-32 md:pr-8 ${
                  isVisible ? 'visibleleft' : ''
                } `}
              >
                <div className="flex items-start lg:gap-0 md:gap-6 gap-4">
                  <div className="lg:w-1/12 ">
                    <div className="contact-dot bg-primary cust-bgColor">
                      <FeatherIcon
                        icon="map-pin"
                        className="text-white"
                        size={18}
                      />
                    </div>
                  </div>
                  <div className="lg:w-11/12 lg:ml-5">
                    <h5
                      className="fsize20 sm-fsize16 font-semibold text-primary cust-textColor
                     dynamicStyle"
                      id="8178921044"
                    >
                      {data ? data['8178921044'] : 'Lorem Ipsum'}
                    </h5>

                    <ReactQuill
                      id="1234443268"
                      theme="bubble"
                      readOnly
                      className="card_description16px bubble dynamicStyle mt-2"
                      value={data ? data['1234443268'] : 'Lorem Ipsum'}
                    />
                  </div>
                </div>
                <div className="flex items-start lg:gap-0 md:gap-6 gap-4 lg:mt-10 mt-4">
                  <div className="lg:w-1/12 ">
                    <div className="contact-dot bg-primary cust-bgColor">
                      <FeatherIcon
                        icon="phone"
                        className="text-white"
                        size={18}
                      />
                    </div>
                  </div>
                  <div className="lg:w-11/12 lg:ml-5">
                    <h5
                      className="fsize20 sm-fsize16 font-semibold text-primary
                     cust-textColor dynamicStyle"
                      id="1565958090"
                    >
                      {data ? data['1565958090'] : 'Lorem Ipsum'}
                    </h5>

                    <ReactQuill
                      id="0174859434"
                      theme="bubble"
                      readOnly
                      className="card_description16px bubble dynamicStyle mt-2"
                      value={data ? data['0174859434'] : 'Lorem Ipsum'}
                    />
                  </div>
                </div>
                <div className="flex items-start lg:gap-0 md:gap-6 gap-4 lg:mt-10 mt-4">
                  <div className="lg:w-1/12 ">
                    <div className="contact-dot bg-primary cust-bgColor">
                      <FeatherIcon
                        icon="mail"
                        className="text-white"
                        size={18}
                      />
                    </div>
                  </div>
                  <div className="lg:w-11/12 lg:ml-5">
                    <h5
                      className="fsize20 sm-fsize16 font-semibold text-primary cust-textColor dynamicStyle"
                      id="7791685749"
                    >
                      {data ? data['7791685749'] : 'Lorem Ipsum'}
                    </h5>

                    <ReactQuill
                      id="6432296175"
                      theme="bubble"
                      readOnly
                      className="card_description16px bubble dynamicStyle mt-2"
                      value={data ? data['6432296175'] : 'Lorem Ipsum'}
                    />
                  </div>
                </div>
              </div>
              <div className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4">
                {/* <AspectRatio ratio={16 / 9} className="address-img shadow"> */}
                <iframe
                  className="address-img shadow"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.952912260219!2d3.375295414770757!3d6.5276316452784755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1567723392506!5m2!1sen!2sng"
                />
                {/* </AspectRatio> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetails1;
