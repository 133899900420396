import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { useSelector } from "react-redux/es/exports";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const EventCardSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex justify-between lg:pb-12 md:pb-12 pb-6">
          {/* <div className="w-1/2 relative">
          <Input className="cust-textColor" placeholder="Search" />
          <FeatherIcon
            className="cust-textColor search-abs"
            size={20}
            icon="search"
          />
        </div>
        <div className="sm-wid-30 cust-textColor">
          <Select className=" sm-fsize14" placeholder="Select">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
        </div> */}
          <div className="lg:w-1/2 md:w-1/2">
            <div className="p-inputgroup flex-1">
              <InputText placeholder="Search" className="p-2 bg-fff" />
              <Button icon="pi pi-search" className="bg-primary" />
            </div>
          </div>
          <div className="lg:mt-0 md:mt-0 mt-2 flex justify-end">
            <Dropdown
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.value)}
              options={cities}
              optionLabel="name"
              placeholder="Select a City"
              className="sm-fsize14 md:w-14rem bg_surface50 cust-shadow1"
            />
          </div>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <Card className="cursor-pointer box-shadow-none themeShadow  themeShadow theme-bg overflow-hidden">
            <div
              id="7211895100"
              className="dynamicStyle2 w-full overflow-hidden "
            >
              <Image
                src={data ? getImage(data["7211895100"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="w-full h-52 custimghover"
                alt="img"
              />
            </div>

            <div className="lg:p-5 md:p-4 p-3">
              <div className="flex items-center">
                <FeatherIcon
                  icon="map-pin"
                  className="text-primary cust-textColor"
                  size={15}
                />
                <p
                  className="text_surface800 cust-textColor fsize13 font-semibold ml-1 dynamicStyle"
                  id="9404324487"
                >
                  {data ? data["9404324487"] : "Lorem Ipsum"}
                </p>
              </div>
              <h2
                className="text-primary cust-textColor fsize20 sm-fsize16 font-semibold dynamicStyle"
                id="6365584655"
              >
                {data ? data["6365584655"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="1637595106"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2"
                value={data ? data["1637595106"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="cursor-pointer box-shadow-none themeShadow  themeShadow theme-bg overflow-hidden">
            <div
              id="4386184349"
              className="dynamicStyle2 w-full overflow-hidden "
            >
              <Image
                src={data ? getImage(data["4386184349"]) : ""}
                imageClassName="w-full h-52 custimghover"
                alt="img"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="lg:p-5 md:p-4 p-3">
              <div className="flex items-center">
                <FeatherIcon
                  icon="map-pin"
                  className="cust-textColor text-primary"
                  size={15}
                />
                <p
                  className="text_surface800 fsize13 cust-textColor font-semibold ml-1 dynamicStyle"
                  id="0369050241"
                >
                  {data ? data["0369050241"] : "Lorem Ipsum"}
                </p>
              </div>
              <h2
                className="text-primary fsize20 sm-fsize16 font-semibold dynamicStyle cust-textColor"
                id="4789212787"
              >
                {data ? data["4789212787"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="8558828548"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2"
                value={data ? data["8558828548"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="cursor-pointer box-shadow-none themeShadow  themeShadow theme-bg overflow-hidden">
            <div
              id="4293448999"
              className="dynamicStyle2 w-full overflow-hidden "
            >
              <Image
                src={data ? getImage(data["4293448999"]) : ""}
                imageClassName="w-full h-52 custimghover"
                alt="img"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="lg:p-5 md:p-4 p-3">
              <div className="flex items-center">
                <FeatherIcon
                  icon="map-pin"
                  className="cust-textColor text-primary"
                  size={15}
                />
                <p
                  className="text_surface800 fsize13 cust-textColor font-semibold ml-1 dynamicStyle"
                  id="7038784785"
                >
                  {data ? data["7038784785"] : "Lorem Ipsum"}
                </p>
              </div>
              <h2
                className="text-primary fsize20 sm-fsize16 font-semibold dynamicStyle cust-textColor"
                id="4107097126"
              >
                {data ? data["4107097126"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="6535371802"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2"
                value={data ? data["6535371802"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EventCardSection2;
