import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { ArrowRight } from "react-feather";
import { Image } from "primereact/image";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const AddBanner3 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="lg:px-20 md:px-8 px-5">
      <div className="prime_container lg:flex md:flex h-750">
        <div
          id="4613632026"
          className="dynamicStyle2 lg:w-1/2 md:w-5/12 sm-pb-4 w-full"
        >
          <Image
            imageClassName="h-750 object-cover"
            src={data ? getImage(data["4613632026"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img-banner"
          />
        </div>
        <div className="lg:w-1/2 md:w-7/12 ">
          <div className="lg:flex md:flex h-1/2 sm-pb-4">
            <div className="lg:w-1/2 md:w-1/2 lg:px-10 md:px-4 px-4 lg:py-16 md:py-4 py-6 bg-fffaf0">
              <p id="8100811620" className="dynamicStyle card_title">
                {data ? data["8100811620"] : "African Wax Fabrics"}
              </p>
              <ReactQuill
                id="3648425937"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description lineclamp-quill"
                value={data ? data["3648425937"] : "Lorem Ipsum"}
              />

              <div
                id="1755782686"
                className="dynamicStyle flex lg:w-1/2 items-center cursor-pointer text-primary mt-3"
              >
                <p className="md-fsize14 sm-fsize14">
                  {data ? data["1755782686"] : "View All"}
                </p>
                <ArrowRight className="ml-2 vw-arrow" />
              </div>
            </div>

            <div
              id="7394577531"
              className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
            >
              <Image
                imageClassName="h-full object-cover"
                src={data ? getImage(data["7394577531"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
          </div>
          <div className="flex h-1/2 flex-rev">
            <div
              id="3979729733"
              className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
            >
              <Image
                imageClassName="h-full object-cover"
                src={data ? getImage(data["3979729733"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
            <div className="lg:w-1/2 md:w-1/2 lg:px-10 md:px-4 px-4 lg:py-16 md:py-4 py-6 bg-fffaf0">
              <p id="8948716338" className="dynamicStyle card_title">
                {data ? data["8948716338"] : "African Wax Fabrics"}
              </p>
              <ReactQuill
                id="4761726601"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description lineclamp-quill"
                value={data ? data["4761726601"] : "Lorem Ipsum"}
              />

              <div
                id="1067489719"
                className="dynamicStyle flex lg:w-1/2 items-center cursor-pointer text-primary mt-3"
              >
                <p className="md-fsize14 sm-fsize14">
                  {data ? data["1067489719"] : "View All"}
                </p>
                <ArrowRight className="ml-2 vw-arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner3;
