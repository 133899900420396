import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";
import { useDispatch } from "react-redux";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Testimonial3 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(
    (state) => state.testimonial.Testimonial
  );
  console.log(getAllTestimonial, "wddefe");
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    dispatch(getTestimonal());
    return () => {
      cleanp1();
    };
  }, []);

  return (
    <>
      {getAllTestimonial && (
        <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
          <div className="prime_container lg:flex md:flex block items-center ">
            <div
              ref={elementRef}
              className={`fade-left w-40 sm-wfull ${
                isVisible ? "visibleleft" : ""
              } `}
            >
              <p
                id="1841169736"
                className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary"
              >
                {data ? data["1841169736"] : "Lorem Ipsum"}
              </p>
              <h2 id="4446525163" className="section_header dynamicStyle">
                {data ? data["4446525163"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="6116504466"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail"
                value={data ? data["6116504466"] : "Welcome to My World"}
              />
            </div>
            <div className="md:ml-8 w-60 sm-wfull lg:mt-0 md:mt-0 mt-6">
              <Swiper
                spaceBetween={15}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {getAllTestimonial?.data?.result.map((testimonial, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className="relative prime_card overflow-hidden card lg:mx-2 md:mx-2"
                        key={index}
                      >
                        <div className="">
                          <div className="flex justify-center w-full text-center">
                            <div className="">
                              <div className="w-full flex items-center justify-center h-28">
                                <Image
                                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                  src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                  alt={testimonial.name}
                                  imageClassName="w-24 h-24 mx-auto rounded-full object-cover"
                                />
                              </div>
                              <div className="">
                                {/* <h2 className="fsize24 sm-fsize18 font-semibold lg:mb-2">
                                  {`${testimonial.firstName}` +
                                    '\n' +
                                    `${testimonial.lastName}`}
                                </h2> */}
                                <h4 className="testimonial_title">
                                  {testimonial.name}
                                </h4>
                                {/* <p className="fsize18 sm-fsize16">CEO</p> */}
                                <div className="lg:h-28 md:h-32 h-32">
                                  <p className="card_description lineClamp5 lg:mb-6">
                                    {testimonial.testimonial}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonial3;
