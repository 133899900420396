import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection7 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor endv

  return (
    <div className="theme-bg lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container ">
        <div className="text-center lg:my-8">
          <h2 id="1070219600" className="dynamicStyle section_header">
            {data ? data['1070219600'] : 'Lorem Ipsum'}
          </h2>
          <p
            id="1331589393"
            className="dynamicStyle section_detail textColor-light theme-textColor"
          >
            {data ? data['1331589393'] : 'Lorem Ipsum'}
          </p>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom lg:mt-12 md:mt-12 mt-8 w-80 sm-wfull mx-auto ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <div id="8917586080" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['8917586080']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              imageClassName="br-40px w-full h-450px sm-h250px object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection7;
