import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from 'primereact/image';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Gallery = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-3/4 md:w-4/5 w-full mx-auto">
          <h3
            id="1134054994"
            className="section_header dynamicStyle text-center"
          >
            {data ? data['1134054994'] : '  What is our goals'}
          </h3>
          <ReactQuill
            id="6297891318"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['6297891318'] : 'Lorem Ipsum'}
          />
        </div>

        <div
          ref={elementRef}
          className={`fade-bottom grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:px-10 md:px-8 px-2 lg:mt-16 md:mt-16 mt-12 gap-4 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <div className="">
            <div
              id="0497833108"
              className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data['0497833108']) : ''}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="sections"
              />
            </div>
            <div
              id="5735483205"
              className="dynamicStyle2 lg:mb-0 md:mb-0 mb-0 lg:mt-4 md:mt-8 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data['5735483205']) : ''}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="sections"
              />
            </div>
          </div>
          <div className="lg:mt-12">
            <div
              id="1129031166"
              className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data['1129031166']) : ''}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="sections"
              />
            </div>
            <div
              id="8803829542"
              className="dynamicStyle2 lg:mb-0 md:mb-0 mb-0 lg:mt-4 md:mt-8 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data['8803829542']) : ''}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="sections"
              />
            </div>
          </div>
          <div className="">
            <div
              id="5867196401"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg lg:mb-0 md:mb-0 mb-6"
            >
              <Image
                src={data ? getImage(data['5867196401']) : ''}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="sections"
              />
            </div>
            <div
              id="1487085918"
              className="dynamicStyle2 lg:mt-4 md:mt-8 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data['1487085918']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                alt="sections"
              />
            </div>
          </div>
          <div className="lg:mt-12">
            <div
              id="3353631293"
              className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data['3353631293']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                alt="sections"
              />
            </div>
            <div
              id="4843289427"
              className="dynamicStyle2 lg:mb-0 md:mb-0 mb-6 lg:mt-4 md:mt-8 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data['4843289427']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="lg:h-80 w-full h-72 rounded-lg sm-h250px tb-h280px object-cover custimghover"
                alt="sections"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
