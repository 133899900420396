import React from "react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";

const CategoriesSection3 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <section className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="4296811316"
          className="dynamicStyle fsize42 md-fsize36 sm-fsize24 font-semibold"
        >
          {data ? data["4296811316"] : "Lorem Ipsum"}
        </h3>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-8 md:mt-8 mt-6 collection_component rounded-lg overflow-hidden">
          <div className="p-6 collection_grid_item relative category3_img1">
            <div className="z-ind-1">
              <p className="pb-2 fsize24 sm-fsize20">Agri tech</p>
              <p className="">Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="category3_img">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="h-20 w-20 absolute bottom-0 right-0 mr-4"
              />
            </div>
          </div>
          <div className="p-6 collection_grid_item relative category3_img1">
            <div className="z-ind-1">
              <p className="pb-2 fsize24 sm-fsize20 ">Agri tech</p>
              <p className="">Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="category3_img">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="h-20 w-20 absolute bottom-0 right-0 mr-4"
              />
            </div>
          </div>
          <div className="p-6 collection_grid_item relative category3_img1">
            <div className="z-ind-1">
              <p className="pb-2 fsize24 sm-fsize20 ">Agri tech</p>
              <p className="">Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="category3_img">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="h-20 w-20 absolute bottom-0 right-0 mr-4"
              />
            </div>
          </div>
          <div className="p-6 collection_grid_item relative category3_img1">
            <div className="z-ind-1">
              <p className="pb-2 fsize24 sm-fsize20 ">Agri tech</p>
              <p className="">Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="category3_img">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="h-20 w-20 absolute bottom-0 right-0 mr-4"
              />
            </div>
          </div>
          <div className="p-6 collection_grid_item relative category3_img1">
            <div className="z-ind-1">
              <p className="pb-2 fsize24 sm-fsize20 ">Agri tech</p>
              <p className="">Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="category3_img">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="h-20 w-20 absolute bottom-0 right-0 mr-4"
              />
            </div>
          </div>
          <div className="p-6 collection_grid_item relative category3_img1">
            <div className="z-ind-1">
              <p className="pb-2 fsize24 sm-fsize20 ">Agri tech</p>
              <p className="">Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="category3_img">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="h-20 w-20 absolute bottom-0 right-0 mr-4"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoriesSection3;
