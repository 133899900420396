import apis from '../../apis/cera/Pages';
export const getAllPages = () => {
  // let record;
  return async dispatch => {
    try {
      let payload = {
        getDistinctData: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        // isDeleted:false

      };
      let { data } = await apis.getAllPages(payload);

      dispatch(pages_reducer(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};
export const pages_reducer = data => {
  return {
    type: 'setPagesData',
    payload: data,
  };
};
