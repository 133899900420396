import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";


const FeatureCard52 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-5/12 md:w-6/12">
            <div className="text-left">
              <ReactQuill
                id="4963387869"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail_0"
                value={data ? data['4963387869'] : 'Welcome to My World'}
              />

              <h2 className="section_header dynamicStyle" id="1487205619">
                {data ? data['1487205619'] : 'Lorem Ipsum'}
              </h2>
            </div>
            <div className="grid grid-cols-2 gap-4 lg:mt-4 md:mt-3 mt-2">
              <div id="0476677651" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data['0476677651']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  imageClassName="w-full lg:h-52 md:h-40 h-32 object-cover rounded-lg"
                />
              </div>
              <div id="6001872135" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data['6001872135']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  imageClassName="w-full lg:h-52 md:h-40 h-32 object-cover rounded-lg"
                />
              </div>
              <div id="5794626943" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data['5794626943']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  imageClassName="w-full lg:h-52 md:h-40 h-32 object-cover rounded-lg"
                />
              </div>
              <div id="5020715117" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data['5020715117']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  imageClassName="w-full lg:h-52 md:h-40 h-32 object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
          <div className="lg:w-7/12 md:w-6/12 lg:px-8 md:px-4 px-1">
            <div
              className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-6 dynamicStyle lg:mt-12 md:mt-5 mt-4"
              id="3738430531"
            >
              {data && data['3738430531']
                ? data['3738430531'].map((e, index) => {
                    return (
                      <div className="flex items-center gap-4 lg:mb-4 md:mb-3 mb-3">
                        <div className="flex items-center theme-bg themeShadow cust-bgColor justify-center lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 bg-ec rounded-full">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + e.field1}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                            imageClassName="lg:w-10 lg:h-10 w-8 h-8 object-contain"
                          />
                        </div>
                        <div className="lg:w-9/12 md:w-9/12 w-9/12">
                          <h4 className="fsize24 md-fsize20 sm-fsize18 text-dark font-semibold cust-textColor">
                            {e.field2}
                          </h4>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble"
                            value={e.field3}
                          />
                        </div>
                      </div>
                    );
                  })
                : 'no-card'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard52;
