import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";

const PollSection6 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");

  useEffect(() => {
    const progress = document.querySelector(".progress-at");
    progress.style.width = progress.getAttribute("data-done") + "%";
    progress.style.opacity = 1;
    progress.style.textAlign = "end";
    progress.style.height = "20px";
    progress.style.margin = "0";
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h2
          id="1158681852"
          className="dynamicStyle inline-block fsize20 md-fsize18 sm-fsize16 font-semibold text_surface600"
        >
          {data ? data["1158681852"] : "Question"}
        </h2>
        <div className="pt-6">
          <h3 className="card_title pb-2 text_surface600">
            1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
            deleniti?
          </h3>
          <div className="flex gap-4">
            <div className="flex items-center">
              <RadioButton
                inputId="ingredient1"
                name="pizza"
                value="First"
                onChange={(e) => setValue(e.value)}
                checked={value === "First"}
              />
              <label htmlFor="ingredient1" className="ml-2">
                First
              </label>
            </div>
            <div className="flex items-center">
              <RadioButton
                inputId="ingredient2"
                name="pizza"
                value="Second"
                onChange={(e) => setValue(e.value)}
                checked={value === "Second"}
              />
              <label htmlFor="ingredient2" className="ml-2">
                Second
              </label>
            </div>
            <div className="flex items-center">
              <RadioButton
                inputId="ingredient1"
                name="pizza"
                value="Third"
                onChange={(e) => setValue(e.value)}
                checked={value === "Third"}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Third
              </label>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <h3 className="card_title pb-2 text_surface600">
            2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
            deleniti?
          </h3>

          <div className="flex gap-4">
            <div className="flex items-center">
              <RadioButton
                inputId="ingredient1"
                name="pizza"
                value="First"
                onChange={(e) => setValue1(e.value)}
                checked={value1 === "First"}
              />
              <label htmlFor="ingredient1" className="ml-2">
                First
              </label>
            </div>
            <div className="flex items-center">
              <RadioButton
                inputId="ingredient2"
                name="pizza"
                value="Second"
                onChange={(e) => setValue1(e.value)}
                checked={value1 === "Second"}
              />
              <label htmlFor="ingredient2" className="ml-2">
                Second
              </label>
            </div>
          </div>
          {/* <Radio>
            <span className="text_surface600">First</span>
          </Radio>
          <Radio value="2">
            <span className="text_surface600">Second</span>
          </Radio> */}
        </div>
        <div className="pt-6">
          <h3 className="card_title pb-2 text_surface600">
            3. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
            deleniti?
          </h3>

          <div className="flex gap-3">
            <div className="flex gap-2 mb-1">
              <Checkbox></Checkbox>
              <span className="text_surface600">Checkbox1</span>
            </div>
            <div className="flex gap-2">
              <Checkbox></Checkbox>
              <span className="text_surface600">Checkbox2</span>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <h3 className="card_title pb-2 text_surface600">
            4. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
            deleniti?
          </h3>
          <div className="ml-4">
            <Rating
              initialRating={0}
              readonly={true}
              emptySymbol={
                <FeatherIcon
                  icon="star"
                  className="text_surface600 sm-star-16 mr-1"
                  size={22}
                />
              }
              fullSymbol={
                <FeatherIcon
                  icon="star"
                  className="fillstroke mr-1"
                  size={22}
                />
              }
              fractions={2}
            />
          </div>
        </div>
        <div className="pt-6">
          <h3 className="card_title pb-2 text_surface600">
            5. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
            deleniti?
          </h3>
          <div className="ml-4">
            <div className="main_div_poll">
              <div className="progress">
                <div className="progress-done">
                  <p data-done="25" className="progress-at text_surface600">
                    *
                  </p>
                </div>

                <div className="progress-number">
                  {Array.from({ length: 10 }, (_, i) => (
                    <div className="line_div" key={i + 1}>
                      <div className="line"></div>
                      <p className="text_surface600">{i + 1}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollSection6;
