import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection48 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:flex md:flex sm-reverse" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:w-1/2 md:w-1/2 flex items-center">
        <div className="lg:px-16 md:px-8 px-5 py-6">
          <ReactQuill
            id="6384046807"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle mb-6"
            value={data ? data['6384046807'] : 'Lorem Ipsum'}
          />

          <Button
            className="theme-btn dynamicStyle b_button_primary px-6 py-2 fsize14 sm-fsize14"
            id="7809391776"
          >
            <span onClick={() => handleClick('7809391776')}>
              {data ? data['7809391776'] : 'View More'}
            </span>
          </Button>
        </div>
      </div>
      <div className="lg:w-1/2 md:w-1/2 relative">
        <div
          id="8198003730"
          className="dynamicStyle2 w-full h-500px sm-h-250px"
        >
          <Image
            src={data ? getImage(data['8198003730']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img-about"
            imageClassName="w-full h-500px sm-h-250px object-cover"
          />
        </div>
        <div className="car-round3 absolute top-0 right-0 z-10"></div>
        <div className="absolute lg:bottom-20 lg:right-14 bottom-12 right-8 z-20">
          <p id="6009430812" className="dynamicStyle about_title clr-fff">
            {data ? data['6009430812'] : 'Our Clients'}
          </p>
          <p id="3338373802" className="dynamicStyle about_title clr-fff">
            {data ? data['3338373802'] : 'Our Clients'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection48;
