import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const AboutSection16 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleErorrImage = e => {
    e.target.src =
      'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg';
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="px-5 py-12 lg:px-20 md:px-8 lg:py-20 md:py-16" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="w-full mx-auto text-center lg:w-2/3 md:w-3/4">
          <p
            id="4384588611"
            className="font-medium dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary "
          >
            {data ? data['4384588611'] : 'Lorem ipsum'}
          </p>
          <h4
            id="7231049814"
            className="dynamicStyle section_header"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['7231049814']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
          ></h4>
        </div>
        <div>
          <div className="items-center gap-8 mt-12 lg:mt-16 md:mt-16 lg:flex md:flex lg:px-12">
            <div className="py-4 lg:w-1/2 md:w-1/2 lg:py-0 md:py-8">
              <div className=" lg:mt-8 md:mt-8">
                <h2 id="7156060658" className="about_title dynamicStyle">
                  {data
                    ? data['7156060658']
                    : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
                </h2>
                <ReactQuill
                  id="1641654251"
                  theme="bubble"
                  readOnly
                  className="card_description bubble dynamicStyle"
                  value={data ? data['1641654251'] : 'Lorem Ipsum'}
                />
              </div>
              <div className="pt-6">
                <Button
                  className="dynamicStyle theme-btn b_button_primary px-6 py-2"
                  id="9042320843"
                >
                  <span onClick={() => handleClick('9042320843')}>
                    {data ? data['9042320843'] : 'Lorem ipsum'}
                  </span>
                </Button>
              </div>
            </div>
            <div
              ref={elementRef}
              className={`fade-right lg:w-1/2 md:w-1/2 grid grid-cols-2 lg:gap-8 md:gap-6 gap-4 justify-end w-full ${
                isVisible ? 'visibleright' : ''
              } `}
            >
              <div className="w-full ">
                <div id="9856898056" className="dynamicStyle2 ">
                  <Image
                    src={data ? getImage(data['9856898056']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="heroImage"
                    imageClassName="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg"
                  />
                </div>
                <div id="6634269119" className="dynamicStyle2 ">
                  <Image
                    src={data ? getImage(data['6634269119']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="heroImage"
                    imageClassName="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg mt-6"
                  />
                </div>
              </div>
              <div className="w-full mt-6">
                <div id="0535198992" className="dynamicStyle2 ">
                  <Image
                    src={data ? getImage(data['0535198992']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="heroImage"
                    imageClassName="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg"
                  />{' '}
                </div>
                <div id="1159401137" className="dynamicStyle2 ">
                  <Image
                    src={data ? getImage(data['1159401137']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="heroImage"
                    imageClassName="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg mt-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection16;
