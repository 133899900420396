import React from 'react';
import { Image, InputGroup, Input, InputRightElement, useColorMode } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { Search2Icon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux/es/exports';

const EcomWishlist1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <div className="container m-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div>
        <div className="w-full flex justify-between lg:gap-16 md:gap-12 gap-4">
          <h2
            id="6212668990"
            className="dynamicStyle w-full  fsize24 sm-fsize20 cust-textColor  font-semibold"
          >
            {data ? data['6212668990'] : 'Lorem Ipsum'}
          </h2>
          <div className="w-full">
            <InputGroup width="100%">
              <Input
                pr="4.5rem"
                placeholder="Search courses"
                className="rounded-100px cust-textColor"
              />
              <InputRightElement width="3.5rem">
                <Search2Icon className="cust-textColor" />
              </InputRightElement>
            </InputGroup>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:pt-12 md:pt-10 pt-8 lg:pb-12 md:pb-10 pb-8">
          <div
            className={
              (colorMode === 'light'
                ? 'rounded-lg cursor-pointer  themeShadow'
                : 'cust-bgColor rounded-lg  cursor-pointer') +
              ' ' +
              (selectedStyle === 'Default' && colorMode !== 'dark'
                ? 'cust-shadow'
                : '')
            }
          >
            <div className="relative image-product overflow-hidden rounded-t">
              <Image
                className="img-h-220 w-full opct"
                // fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                // src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
              />
              <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                <div className="">
                  <p className="fsize14 textColor-light">4.5/5</p>
                </div>
                <div>
                  <FeatherIcon className="filled-blk " icon="star" size={18} />
                </div>
              </div>
            </div>

            <div className="p-4">
              <div className="flex justify-between  text-b2b2b2">
                <p className="fsize14 font-medium">12 Lessons</p>
                <p className=" fsize14  font-medium">3 hours</p>
              </div>
              <div className="py-3">
                <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1">
                  name
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                  ₹ 7850
                </p>
                <div className="flex gap-4">
                  <FeatherIcon
                    className=" cust-textColor"
                    icon="heart"
                    size={20}
                  />
                  <FeatherIcon
                    className="cust-textColor "
                    icon="shopping-cart"
                    size={20}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomWishlist1;
