import React, { useEffect, useRef, useState } from 'react';
import { Input, Textarea, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const LeadSection4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="">
      <div className="bg-primary cust-bgColor lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5 themeShadow theme-bg">
        <div className="lg:w-3/5 md:w-3/5 w-full mx-auto text-center">
          <p
            id="6922703546"
            className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor"
          >
            {data ? data['6922703546'] : 'Lorem Ipsum'}
          </p>
          <ReactQuill
                id="9527359217"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2"
                value={data ? data['9527359217'] : 'Lorem Ipsum'}
              />
        </div>
      </div>
      <div className="lg:w-4/5 md:w-4/5 w-full mx-auto lg:py-16 md:py-12 py-6 lg:px-12 md:px-8 px-5">
        <div className="grid grid-cols-1 lg:gap-6 md:gap-6 gap-4">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-6 gap-4">
            <div className="w-full">
              <p
                id="0890545044"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['0890545044'] : 'First Name'}
              </p>
              <Input
                type="text"
                placeholder="First name"
                className="theme-border sm-fsize14 bdr-gray-1px"
              />
            </div>
            <div className="">
              <p
                id="6378335482"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['6378335482'] : 'Last Name'}
              </p>
              <Input
                type="text"
                placeholder="Last name"
                className="theme-border sm-fsize14 bdr-gray-1px"
              />
            </div>
          </div>
          <div className="">
            <p
              id="7030178902"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data['7030178902'] : 'Email Id'}
            </p>
            <Input
              type="text"
              placeholder="Email Id"
              className="theme-border sm-fsize14 bdr-gray-1px"
            />
          </div>
          <div className="">
            <p
              id="0559107444"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data['0559107444'] : 'Phone Number'}
            </p>
            <Input
              type="text"
              placeholder="Phone Number"
              className="theme-border sm-fsize14 bdr-gray-1px"
            />
          </div>
          <div>
            <p
              id="0355977545"
              className="dynamicStyle dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data['0355977545'] : 'Lorem Ipsum'}
            </p>
            <Textarea
              name="description"
              row={4}
              id=""
              placeholder="Type here"
              className="theme-border sm-fsize14 bdr-gray-1px"
            />
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <Button
            className='dynamicStyle w-full b_button_primary px-6 py-2 theme-btn sm-fsize10 '
                
            id="4831708546"
          >
            <span onClick={() => handleClick('4831708546')}>
              {data ? data['4831708546'] : 'Submit'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeadSection4;
