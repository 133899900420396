import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from 'react-icons/fa';
import { useSelector } from 'react-redux/es/exports';
const Footer13 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  const isDarkMode = useSelector((state) => state.Loader.DarkMode);
  
  return (
    <div 
    // className="bg-secondary cust-bgColor text-white"
    className={isDarkMode ? "bg_surface100" : "bg-secondary"}
    >
      <div className="lg:px-12 md:px-10 px-4 lg:pt-14 md:pt-12 pt-6 lg:pb-8 md:pb-8 pb-6">
        <div className="lg:flex md:flex w-full items-start">
          <div className="lg:w-1/4 md:w-1/4 w-full flex lg:justify-start md:justify-start justify-center">
            <a href="/">
              <Image
                src={getSecondaryImage(brandProfile)}
                alt="footer-logo"
                className="lg:h-20 md:h-20 h-16 lg:w-40 md:w-20 w-12 object-contain"
              />
            </a>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-3 lg:grid-cols-3 lg:gap-8 md:gap-8 gap-4 lg:mt-0 md:mt-0 mt-4 lg:w-3/4 md:w-full w-full">
            <div>
              {' '}
              <p
                id="0927434393"
                className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14"
              >
                {data ? data['0927434393'] : 'SELL A HOME'}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Request an offer{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Pricing{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Reviews{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Stories{' '}
              </p>
            </div>
            <div>
              {' '}
              <p
                id="6783361430"
                className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14"
              >
                {data ? data['6783361430'] : 'BUY, RENT AND SELL'}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Buy and sell properties{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Rent home{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Builder trade-up{' '}
              </p>
            </div>
            <div>
              {' '}
              <p
                id="3155861818"
                className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14"
              >
                {data ? data['3155861818'] : 'About'}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Company{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                How it works{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Contact{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Investors{' '}
              </p>
            </div>
            <div>
              {' '}
              <p
                id="4941758013"
                className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14"
              >
                {data ? data['4941758013'] : 'BUY A HOME'}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Buy{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Finance{' '}
              </p>
            </div>

            <div>
              {' '}
              <p id="0518336852"
                className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14">
                {data ? data['0518336852'] : 'TERMS & PRIVACY'} {''}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Trust & Safety
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Terms of Service{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Privacy Policy{' '}
              </p>
            </div>

            <div>
              {' '}
              <p  id="0847095135"
                      className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14">
                 {data ? data['3898609804'] : 'RESOURCES'} {''}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Blog
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Guides{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                FAQ{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Help Center{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-start justify-between lg:px-12 md:px-10 px-4 lg:py-6 md:py-6 py-4 border-t border-white">
        {' '}
        <p id="7738025183"
                      className="dynamicStyle lg:px-10 text-white fsize16 md-fsize14 sm-fsize12">
          {data ? data['7738025183'] : '&#169; 2021 Estatery.All rights reserved'}
        </p>
        <div className="flex">
          <div className="lg:w-16 md:w-12 w-8 cursor-pointer">
          <a href="/">
            <FaFacebookF className="clr-fff" />
            </a>
          </div>
          <div className="lg:w-16 md:w-12 w-8 cursor-pointer">
          <a href="/">
            <FaInstagram className="clr-fff" />
            </a>
          </div>
          <div className="lg:w-16 md:w-12 w-8 cursor-pointer">
          <a href="/">
            <FaTwitter className="clr-fff" />
            </a>
          </div>
          <div className="lg:w-16 md:w-12 w-8 cursor-pointer">
          <a href="/">
            <FaLinkedin className="clr-fff" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer13;
