import React from "react";
import { useSelector } from "react-redux/es/exports";
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const NoPageFound1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className=" h-100vh relative flex items-center ">
      <div className="container mx-auto lg:px-12 px-4 lg:py-20 py-8">
        <div className="relative error-404 mx-auto lg:mb-6 md:mb-6 mb-4">
          <div id="6207814490" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data["6207814490"]) : ""}
              imageClassName="error-404 z-ind-1"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="absolute error-404  top-0 flex left-0 justify-center mt-32">
            <div>
              <h4 className="fsize50 sm-fsize32 text-center text-white font-semibold z-ind-1">
                404
              </h4>
              <p className="fsize16 sm-fsize20 text-center text-white font-semibold z-ind-1">
                Page not found
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto lg:w-7/12 md:w-7/12">
          <ReactQuill
            id="3046051906"
            theme="bubble"
            readOnly
            className="card_description bubble2 dynamicStyle mt-2"
            value={data ? data["3046051906"] : "Lorem Ipsum"}
          />
        </div>
        <div className="flex justify-center z-ind-1">
          <Button
            className="mt-6 px-6 py-2 sm-fsize14 dynamicStyle"
            id="3049055996"
            outlined
          >
            {data ? data["3049055996"] : "Lorem Ipsum"}
          </Button>
        </div>
      </div>

      <div className="absolute top-0 right-0 mar-right">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi49.png"
          imageClassName="error-img"
          alt="error-left"
        />
      </div>
      <div className="absolute bottom-0 left-0 mar-left">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi49.png"
          imageClassName="error-img"
          alt="error-left"
        />
      </div>
    </section>
  );
};

export default NoPageFound1;
