import {
  Card,
  FormControl,
  FormLabel,
  Image,
  Input,
  Textarea,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const MyProfile = () => {
  const { colorMode } = useColorMode();

  const {
    isOpen: isOpenUser,
    onOpen: onOpenUser,
    onClose: onCloseUser,
  } = useDisclosure();

  const [formData, setFormData] = useState({
    firstname: 'TestName',
    lastname: 'TestLastName',
    email: 'test@test.com',
    phone: '1234567890',
    address: '123, Test Street, Test',
    city: 'Test',
    landmark: 'TestLandmark',
    zipcode: '658749',
  });

  // const [editData, setEditData] = useState({
  //   firstname: "",
  //   lastname: "",
  //   email: "",
  //   phone: "",
  //   address: "",
  //   city: "",
  //   landmark: "",
  //   zipcode: "",
  // });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // e.preventDefault();
    console.log(formData, 'SACHIN');
    onCloseUser();
  };

  return (
    <>
      <Modal isOpen={isOpenUser} onClose={onCloseUser}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="clr-fff cust-textColor">
            User Profile Details
          </ModalHeader>
          <ModalCloseButton className="clr-fff" />
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">
                First name
              </FormLabel>
              <Input
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                placeholder="First name"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">
                Last name
              </FormLabel>
              <Input
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                placeholder="Last name"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">Email</FormLabel>
              <Input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="First name"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">Phone</FormLabel>
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Last name"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">
                Address
              </FormLabel>
              <Textarea
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Location"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">
                City/State
              </FormLabel>
              <Input
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Location"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">
                Landmark
              </FormLabel>
              <Input
                name="landmark"
                value={formData.landmark}
                onChange={handleInputChange}
                placeholder="Location"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel className="text-black cust-textColor">
                Zip Code
              </FormLabel>
              <Input
                name="zipcode"
                value={formData.zipcode}
                onChange={handleInputChange}
                placeholder="Location"
                fontWeight={400}
                className="text-black cust-textColor fsize14"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent="center" pt={2}>
            <Button
              type="submit"
              onClick={handleSubmit}
              className="b_button_primary px-6 py-2 mr-3"
              mr={3}
            >
              Save
            </Button>
            {/* <Button onClick={onCloseUser}>Cancel</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <p
        className={
          colorMode === 'light'
            ? 'fsize20 md-fsize20 sm-fsize20 font-semibold text-black theme-textColor'
            : 'fsize20 md-fsize20 sm-fsize20 font-semibold cust-textColor theme-textColor'
        }
      >
        My Profile
      </p>
      <Card
        className={
          colorMode === 'light'
            ? 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative theme-bg themeShadow'
            : 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative border-4e5765 theme-border-0 theme-bg themeShadow'
        }
      >
        <div className="w-full flex items-center">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="profile-img"
            className="lg:w-20 md:w-16 w-12 lg:h-20 md:h-16 h-12 rounded-full object-contain"
          />
          <div className="ml-3">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black theme-textColor'
                  : 'fsize20 md-fsize18 sm-fsize16 font-medium  cust-textColor theme-textColor'
              }
            >
              {`${formData.firstname} ${formData.lastname}`}
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'fsize14 sm-fsize12 theme-textColor'
                  : 'fsize14 sm-fsize12 textColor-light theme-textColor'
              }
            >
              {formData.city}
            </p>
          </div>
        </div>
        <div className="absolute top-3 right-3">
          <Button
            onClick={onOpenUser}
            height="fit-content"
            className='b_button_primary px-4 py-2 sm-edit-btn flex items-center gap-2 theme-btn2'
                
          >
            <span
              className='fsize14 theme-textColor'
                  
            >
              Edit
            </span>
            <FaEdit size={14} />
          </Button>
        </div>
      </Card>
      <Card
        className={
          colorMode === 'light'
            ? 'mt-6 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative theme-bg themeShadow'
            : 'mt-6 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative border-4e5765 theme-bg themeShadow theme-border-0'
        }
      >
        <div className="">
          <p
            className={
              colorMode === 'light'
                ? 'fsize20 md-fsize18 sm-fsize18 font-semibold text-black cust-textColor theme-textColor'
                : 'fsize20 md-fsize18 sm-fsize18 font-semibold text-black cust-textColor theme-textColor'
            }
          >
            Personal Information
          </p>
          <div className="lg:mt-8 md:mt-8 mt-6 w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-8 md:gap-6 gap-6">
            <div className="">
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize14 theme-textColor'
                    : 'fsize14 textColor-light theme-textColor'
                }
              >
                First name
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium cust-textColor theme-textColor'
                }
              >
                {formData.firstname}
              </p>
            </div>
            <div className="">
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize14 theme-textColor'
                    : 'fsize14 textColor-light theme-textColor'
                }
              >
                Last name
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                }
              >
                {formData.lastname}
              </p>
            </div>
            <div className="">
              <p
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
                Email Address
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                }
              >
                {formData.email}
              </p>
            </div>
            <div className="">
              <p
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
                Phone
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                }
              >
                {formData.phone}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="absolute top-3 right-3">
          <Button
            height="fit-content"
            py={2}
            px={4}
            className={
              colorMode === "light"
                ? "bg-primary sm-edit-btn clr-fff flex items-center gap-2"
                : "sm-edit-btn clr-fff flex items-center gap-2"
            }
          >
            <span className={colorMode === "light" ? "fsize14" : "fsize14"}>
              Edit
            </span>
            <FaEdit size={14} />
          </Button>
        </div> */}
      </Card>
      <Card
        className={
          colorMode === 'light'
            ? 'mt-6 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative theme-bg themeShadow'
            : 'mt-6 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative border-4e5765 theme-bg themeShadow theme-border-0'
        }
      >
        <div className="">
          <p
            className={
              colorMode === 'light'
                ? 'fsize20 md-fsize18 sm-fsize18 font-semibold text-black cust-textColor theme-textColor'
                : 'fsize20 md-fsize18 sm-fsize18 font-semibold text-black cust-textColor theme-textColor'
            }
          >
            Address
          </p>
          <div className="lg:mt-8 md:mt-8 mt-6 w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-8 md:gap-6 gap-6">
            <div className="">
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize14 theme-textColor'
                    : 'fsize14 textColor-light theme-textColor'
                }
              >
                Address
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium cust-textColor theme-textColor'
                }
              >
                {formData.address}
              </p>
            </div>
            <div className="">
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize14 theme-textColor'
                    : 'fsize14 textColor-light theme-textColor'
                }
              >
                City/State
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                }
              >
                {formData.city}
              </p>
            </div>
            <div className="">
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize14 theme-textColor'
                    : 'fsize14 textColor-light theme-textColor'
                }
              >
                Landmark
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                }
              >
                {formData.landmark}
              </p>
            </div>
            <div className="">
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize14 theme-textColor'
                    : 'fsize14 textColor-light theme-textColor'
                }
              >
                Zip code
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                    : 'fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor'
                }
              >
                {formData.zipcode}
              </p>
            </div>
          </div>
        </div>
        <div className="absolute top-3 right-3">
          {/* <Button
            height="fit-content"
            py={2}
            px={4}
            className={
              colorMode === "light"
                ? "bg-primary sm-edit-btn clr-fff flex items-center gap-2"
                : "sm-edit-btn clr-fff flex items-center gap-2"
            }
          >
            <span className={colorMode === "light" ? "fsize14" : "fsize14"}>
              Edit
            </span>
            <FaEdit size={14} />
          </Button> */}
        </div>
      </Card>
    </>
  );
};

export default MyProfile;
