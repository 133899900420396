import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VanillaTilt from 'vanilla-tilt';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard13 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.shake'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });

    VanillaTilt.init(document.querySelectorAll('.shakeDark'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-14 md:py-16 py-8 theme-bg" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container theme-bg">
          <div className="header_align">
            <h2
              id="0927925577"
              className="dynamicStyle section_header relative inline-block"
            >
              {data ? data['0927925577'] : 'Welcome to my world'}
            </h2>
            <ReactQuill
              id="8390909776"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['8390909776'] : 'Welcome to My World'}
            />
          </div>
          <div
            id="7628062516"
            className="dynamicStyle w-full items-start grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-x-8 md:gap-x-8 lg:gap-y-0 md:gap-y-0 gap-y-6 lg:mt-16 md:mt-16 mt-12"
          >
            {data && data['7628062516']
              ? data['7628062516'].map((e, index) => {
                  return (
                    <Card
                      className={`${
                        index % 2 === 0
                          ? 'themeShadow theme-bg prime_card_0 lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5 bg-white rounded-xl shake lg:mb-12 md:mb-8'
                          : 'theme_insertShadow theme-bg prime_card_0 lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5 bg-white rounded-xl shake lg:mt-12 md:mt-8'
                      }`}
                      data-tilt
                    >
                      <div className="w-12 h-12 mb-6">
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="lg:w-20 lg:h-20 w-16 h-16 lg:mb-3 md:mb-3 mb-2 object-contain"
                        />
                      </div>

                      <div className="">
                        <h3 className="card_title_0">{e.field2}</h3>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble card_description mt-1"
                          value={e.field3}
                        />
                      </div>
                    </Card>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard13;
