export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const SET_DARKMODE = 'SET_DARKMODE';

export const startLoading = () => ({
  type: START_LOADING,
});

export const stopLoading = () => ({
    type: STOP_LOADING,
  });

  export const setIsDarkMode = (data) => ({
    type: SET_DARKMODE,
    payload: data,
  });
  