import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux/es/exports';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard18 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div>
            <h4 id="8061976685" className="dynamicStyle section_header">
              {data ? data['8061976685'] : ' Our Services'}
            </h4>
          </div>
          <div
            id="5828551284"
            className="dynamicStyle grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-10 lg:mt-16 md:mt-16 mt-12"
          >
            {data && data['5828551284']
              ? data['5828551284'].map((e, index) => {
                  return (
                    <div className="bg-e9e7e7 p-6 relative rad-rev mt-6 cust-bgColor themeShadow theme-bg">
                      <p className="pt-8 card_title_0">{e.field1}</p>
                      <div className="absl-rad w-16 h-16">
                        <Image
                          imageClassName="w-16 h-16 object-contain"
                          src={e ? getImage(e.field2) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field1}
                        />
                      </div>
                    </div>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard18;
