import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { Button } from "primereact/button";
import React from "react";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BookAppointment1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="4781424345"
          className="dynamicStyle mb-8 inline-block section_header"
        >
          {data ? data["4781424345"] : "Lorem Ipsum"}
        </h3>
        <form>
          <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1  gap-6 ">
            <FormControl>
              <p className="ml-1 mb-1 fsize14 md-fsize12 sm-fsize12 font-medium text-black">
                First Name
              </p>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Enter First Name"
                  className="fsize14 text-black theme-bg themeShadow theme-border-0"
                />
                <InputRightElement pointerEvents="none">
                  <FaUser />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <p className="ml-1 mb-1 fsize14 md-fsize12 sm-fsize12 font-medium text-black">
                Last Name
              </p>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Enter Last Name"
                  className="fsize14 text-black theme-bg themeShadow theme-border-0"
                />
                <InputRightElement pointerEvents="none">
                  <FaUser />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <p className="ml-1 mb-1 fsize14 md-fsize12 sm-fsize12 font-medium text-black">
                Phone
              </p>
              <InputGroup>
                <Input
                  type="tel"
                  placeholder="Enter Phone"
                  className="fsize14 text-black theme-bg themeShadow theme-border-0"
                />
                <InputRightElement pointerEvents="none">
                  <PhoneIcon />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <p className="ml-1 mb-1 fsize14 md-fsize12 sm-fsize12 font-medium text-black">
                Email
              </p>
              <InputGroup>
                <Input
                  type="email"
                  placeholder="Enter Email"
                  className="fsize14 text-black theme-bg themeShadow theme-border-0"
                />
                <InputRightElement pointerEvents="none">
                  <EmailIcon />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <p className="ml-1 mb-1 fsize14 md-fsize12 sm-fsize12 font-medium text-black">
                Specialization
              </p>
              <Select
                placeholder="Select country"
                className="fsize14 text-black theme-bg themeShadow theme-border-0"
              >
                <option>United Arab Emirates</option>
                <option>Nigeria</option>
              </Select>
            </FormControl>
            <FormControl>
              <p className="ml-1 mb-1 fsize14 md-fsize12 sm-fsize12 font-medium text-black">
                Date / Time
              </p>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="datetime-local"
                className="fsize14 text-black theme-bg themeShadow theme-border-0"
              />
            </FormControl>
          </div>
          <div className="pt-6">
            <FormControl>
              <p className="ml-1 mb-1 fsize14 md-fsize12 sm-fsize12 font-medium text-black">
                Reason to visit
              </p>
              <Textarea
                placeholder="Enter Reason"
                className="fsize14 text-black theme-bg themeShadow theme-border-0"
              />
            </FormControl>
          </div>
          <div className="lg:mt-12 md:mt-12 mt-8 text-center">
            <Button
              id="5691921305"
              className="dynamicStyle theme-bg themeShadow theme-btn2 b_button_primary px-6 py-2"
            >
              <span onClick={() => handleClick("5691921305")}>
                {data ? data["5691921305"] : "Book Now"}
              </span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookAppointment1;
