import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import Marquee from "react-fast-marquee";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux/es/exports";

const Clients12 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section
      className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container ">
        <div className="lg:flex md:flex justify-between items-center gap-2 bdr-bot2-e5e5e5 pb-5">
          <div className="flex items-center w-70per sm-wfull">
            <FeatherIcon icon="lock" size={20} className="flex" />
            <ReactQuill
              id="3767101894"
              theme="bubble"
              readOnly
              className="dynamicStyle section_detail_0"
              value={data ? data["3767101894"] : "no-data"}
            />
          </div>
          <div className="w-30per flex justify-end sm-wfull">
            <Button className="b_button_primary px-6 py-2">Powered by</Button>
          </div>
        </div>
        <div id="7562575524" className="dynamicStyle lg:mt-10 md:mt-10 mt-8">
          <Marquee loop={0} autoFill={true}>
            {data && data["7562575524"]
              ? data["7562575524"].map((e) => {
                  return (
                    <div className="">
                      <Image
                        src={e ? getImage(e.field1) : ""}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                        imageClassName="w-32 h-24 rounded-lg lg:mx-4 md:mx-3 mx-2 object-contain"
                      />
                    </div>
                  );
                })
              : "no-data"}
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default Clients12;
