import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard22 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div
            id="3325196387"
            className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8"
          >
            {data && data['3325196387']
              ? data['3325196387'].map(e => {
                  return (
                    <div className="feature-card22">
                      <span>{e.field1}</span>
                      <h2>{e.field2}</h2>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble-w"
                        value={e.field3}
                      />
                      <a href="">Read More</a>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard22;
