
const initialState = {
    headerBackgroundColor: "#f4f5f6",
}

const headerSetting = (state=initialState, action) => {
    switch (action.type) {
        case 'SET_HEADER_BACKGROUND_COLOR':
            return { ...state, headerBackgroundColor: action.payload };
       
        

        default:
            return state;
    }

}

export default headerSetting;