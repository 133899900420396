import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'sweetalert2/src/sweetalert2.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import { createContact } from '../../../redux/marcom/marcom-action';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const ContactDetails5 = ({ pageName }) => {
  const dispatch = useDispatch();

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  const initialValues = {
    name: '',
    email: '',
    mobile: '',
    countryCode: '',
  };

  const validationRules = Yup.object({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .max(25, 'Name must be at most 25 characters')
      .required('Name is required')
      .matches(/^[a-zA-Z ]*$/, 'Only alphabets is allowed'),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, 'Email must be at most 50 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    mobile: Yup.string()
      // .min(10, 'Mobile number must be 10 digits')
      // .max(10, 'Mobile number must be 10 digits')
      .required('Mobile number is required'),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),

    //  description: Yup.string()
    //    .trim()
    //    .min(3, 'Message must be at least 3 characters')
    //    .max(100, 'Message must be at most 100 characters')
    //    .required('Message is required'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      mobile: values.mobile,
      //  description: values.description,
      countryCode: values.countryCode,
    };
    try {
      let data = await dispatch(createContact(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: `Will get back to you shortly`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Will get back to you shortly`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error('Something went wrong!');
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, '');
    await formik.setFieldValue('mobile', phoneNumber);
    await formik.setFieldValue('countryCode', dialCountryCode);
  };


  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
  ];
  
   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="w-full flex relative h-100vh sm-h100per md-h-650px " style={{ background: thisComponent.sectionColor }}>
      <div className="w-70 h-40 h-100vh md-h-650px  lg:block md:block hidden"></div>
      <div className="w-30 h-40 bg-primary h-100vh md-h-650px  lg:block md:block hidden"></div>

      <div className="lg:absolute md:absolute top-0 left-0 w-full h-100vh md-h-650px sm-h100per flex items-center justify-center">
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
      <div className="prime_container">
          <div className="lg:flex md:flex items-center lg:gap-16 md:gap-8 w-full">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <h2
                id="1282566755"
                className="dynamicStyle fsize48 md-fsize28 sm-fsize20 font-bold text-primary cust-textColor lg:mb-4 md:mb-3 mb-2"
              >
                {data ? data['1282566755'] : 'Get in touch'}
              </h2>
              <ReactQuill
                id="9338429176"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle lg:pr-8 section_detail"
                value={data ? data['9338429176'] : 'Lorem Ipsum'}
              />

              <div className="lg:mt-12 md:mt-12 mt-8">
                <form onSubmit={formik.handleSubmit}>
                  <InputText
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    invalid={!!formik.errors.name && formik.touched.name}
                    placeholder="Name *"
                    className="form-control theme-bg themeShadow bg_surface50"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <p className="text-danger fsize12 mt-2">
                      {formik.errors.name}
                    </p>
                  )}

                  <InputText
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    invalid={!!formik.errors.email && formik.touched.email}
                    placeholder="Email"
                    className="form-control theme-bg themeShadow mt-6 bg_surface50"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="text-danger fsize12 mt-2">
                      {formik.errors.email}
                    </p>
                  )}
                  {/* <FormControl
                    className="lg:mb-6 md:mb-6 mb-4"
                    isInvalid={!!formik.errors.mobile && formik.touched.mobile}
                  > */}
                  <div className="lg:mb-6 md:mb-6 mb-4">
                    <PhoneInput
                      name="mobile"
                      country={'sg'}
                      height="3rem"
                      enableSearch={true}
                      placeholder="Phone number *"
                      borderRadius="2px"
                      invalid={!!formik.errors.mobile && formik.touched.mobile}
                      className="contactdetails5 mt-6 bg_surface50"
                      onChange={handlePhoneChange}
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                      <p className="text-danger fsize12 mt-2">
                        {formik.errors.mobile}
                      </p>
                      
                    )}
                    </div>
                  {/* </FormControl> */}
                  {/* <Select
                    height="3rem"
                    borderRadius="2px"
                    placeholder="Select option"
                    className="text-gray textColor-light"
                  >
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select> */}
                  <Dropdown
                    value={selectedCity}
                    onChange={e => setSelectedCity(e.value)}
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a City"
                    className="w-full md:w-14rem cust-shadow1 bg_surface50"
                  />

                  <Button
                    id="0825047576"
                    type="submit"
                    className="dynamicStyle theme-btn2 w-full b_button_primary py-3 font-semibold flex justify-center sm-fsize14 mt-6"
                  >
                    <span
                      className="flex items-center"
                      onClick={() => handleClick('0825047576')}
                    >
                      {data ? data['0825047576'] : 'Send'}
                    </span>
                  </Button>
                </form>
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-8">
              <iframe
                className="w-full h-550px md-h-450 sm-h-350px"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7709023962652!2d103.90097507499006!3d1.3128967616934797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da180e0fb5888d%3A0xfffd54b242c84074!2s47%20Joo%20Chiat%20Ln%2C%20Singapore%20428106!5e0!3m2!1sen!2sin!4v1705597718184!5m2!1sen!2sin"
                width="100%"
                allowFullScreen=""
                loading="lazy"
                title="map"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails5;
