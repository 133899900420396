import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  getAllBlogs,
  getBlogs,
  getAllCategory,
  setSelectedBlog,
  getBlogsLoadMore,
  setBlogsLoadMore,
} from '../../../redux/blogs/blogs-actions';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import ReactQuill from 'react-quill';

const CONSTANT_PER_PAGE = 9;
const CONSTANT_PAGE = 0;

const CardLayout1 = ({ isSideBarOpen, pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  let BlogsLoadMore = useSelector(state => state.BlogDetails.BlogsLoadMore);
  let allCategory = useSelector(state => state.BlogDetails.Category);
  console.log(allCategory, 'cate');

  const [page, setPage] = useState(CONSTANT_PAGE);
  const [perPage, setPerPage] = useState(CONSTANT_PER_PAGE);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  console.log(selectedCategory,'selectedlog');
  


  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, [
    page,
    // perPage,
    selectedCategory,
  ]);

  const fetchBlogs = async () => {
    let payload = {
      isActive: true,
      offset: page,
      limit: perPage,
    };
    if (selectedCategory && selectedCategory !== 'all') {
      Object.assign(payload, { categoryId: [selectedCategory] });
    }
    let data = await dispatch(getBlogsLoadMore(payload));
    if (data && data.code === 200 && data.data && data.data.totalCount > 0) {
      if (page === 0) {
        dispatch(setBlogsLoadMore(data.data));
      } else {
        dispatch(
          setBlogsLoadMore({
            ...data.data,
            result: [...BlogsLoadMore.result, ...data.data.result],
          })
        );
      }
    } else {
      dispatch(setBlogsLoadMore({}));
    }
    // setFilteredPosts(Blogs)
  };

  const handleLoadMore = () => {
    // setPerPage(perPage + CONSTANT_PER_PAGE);
    setPage(state => state + 1);
  };

  const handleBlogDetail = blogId => {
    dispatch(setSelectedBlog({}));
    navigate(`/blog detail/${blogId}`);
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);



  const categoryOption = [
  {label:'All', value:'all'},
  ...allCategory?.category?.map(item=> ({
    label:item?.categoryName || 'Unnamed',
    value:item?._id || ''
  })) || []
];
  console.log(categoryOption,'prieselect');
  

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container relative zindex9">
        <div className="lg:flex md:flex justify-between">
          <h2
            id="6706999526"
            className="dynamicStyle lg:text-3xl text-end md:text-2xl text-xl text-primary font-semibold"
          >
            {data ? data['6706999526'] : 'Lorem ipsum'}
          </h2>
          <div className="card flex justify-content-center">
            <Dropdown 
            value={selectedCategory}  
              onChange={e => {
                setSelectedCategory(e.value);

                setPage(CONSTANT_PAGE);
              }}
               options={categoryOption} 
                className="w-full md:w-14rem bg_surface50 cust-shadow1" />
        </div>
          
        </div>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {BlogsLoadMore &&
            BlogsLoadMore.result &&
            BlogsLoadMore.result.length > 0 ? (
              <div
                ref={elementRef}
                className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
              >
                <div className="lg:mt-16 md:mt-16 mt-12 shadow-none grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                  {BlogsLoadMore.result.map((e, i) => {
                    return (
                      <Card
                        key={e._id}
                        p="2"
                        className="cursor-pointer themeShadow theme-bg prime_card_0"
                        onClick={() => handleBlogDetail(e._id)}
                      >
                        <div className="overflow-hidden rounded-xl">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + e.image}
                            alt="Image"
                            imageClassName="w-full object-cover rounded-xl custimghover sm-h250px md-h200px h-280px"
                          />
                        </div>
                        <div className="p-2 lg:mt-4 md:mt-4">
                          <div className="flex items-center justify-between mb-4">
                            {e.categoryID &&
                              e.categoryID.length > 0 &&
                              e.categoryID[0].categoryName && (
                                <Badge
                                className='bg-primary'
                                  value={e.categoryID[0].categoryName}
                                ></Badge>
                              )}
                            <p className="text_surface500 text-sm">
                              {new Date(e.createdAt).toDateString()}
                            </p>
                          </div>

                          <h4 className="font-semibold lg:text-xl md:text-xl text-lg line-clamp-2">
                            {e.title}
                          </h4>
                          
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble mt-2 text_surface400 line-clamp-1 lg:text-sm md:text-sm text-sm"
                            value={e.content}
                          />
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex justify-center align-center">
                No Blog Found
              </div>
            )}
          </>
        )}
        {BlogsLoadMore &&
          BlogsLoadMore.totalPages &&
          BlogsLoadMore.totalPages - 1 > page && (
            <div className="lg:mt-10 md:mt-10 mt-2  text-center">
              <Button
                className="b_button_primary px-6 py-2"
                onClick={handleLoadMore}
                width="200px"
                height="50px"
              >
                LOAD MORE
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default CardLayout1;
