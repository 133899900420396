import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const Services8 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <p
            id="8484529452"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary font-semibold"
          >
            {data ? data["8484529452"] : "Lorem Ipsum"}
          </p>
          <p id="9370133272" className="dynamicStyle section_header">
            {data ? data["9370133272"] : "Lorem Ipsum"}
          </p>
          <ReactQuill
            id="0800768574"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["0800768574"] : "Lorem Ipsum"}
          />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 grid lg:grid-cols-3 grid-cols-1 gap-6">
          <div id="7320179864" className="dynamicStyle">
            {data && data["7320179864"]
              ? data["7320179864"].map((e) => {
                  return (
                    <div className="flex lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
                      <div className="lg:w-3/12 md:w-2/12 w-2/12">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img-alt"
                          imageClassName="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
                        />
                      </div>
                      <div className="lg:w-9/12 md:w-10/12 w-10/12">
                        <p className="card_title">{e.field2}</p>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble card_description"
                          value={e.field3}
                        />
                      </div>
                    </div>
                  );
                })
              : "no-data"}
          </div>
          <div id="8266198447" className="dynamicStyle2 lg:mb-0 mb-6">
            <Image
              src={data ? getImage(data["8266198447"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="w-full h-400px md-h-300px sm-h-250px object-contain"
            />
          </div>
          <div id="4124060467" className="dynamicStyle">
            {data && data["4124060467"]
              ? data["4124060467"].map((e) => {
                  return (
                    <div className="flex lg:items-start items-center lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
                      <div className="lg:w-3/12 md:w-1/4 w-1/4">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img"
                          className="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
                        />
                      </div>
                      <div className="lg:w-9/12 md:w-3/4 w-3/4 lg:ml-0 md:ml-4 ml-0">
                        <p className="card_title">{e.field2}</p>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble card_description"
                          value={e.field3}
                        />
                      </div>
                    </div>
                  );
                })
              : "no-data"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services8;
