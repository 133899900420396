import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const DownloadSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:flex md:flex items-center lg:gap-8 gap-6">
          <div
            id="0101956877"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-4"
          >
            <Image
              src={data ? getImage(data["0101956877"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img-download"
              imageClassName="h-400px md-h-300px sm-h250px w-full object-contain rounded-sm"
            />
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <p id="4055220626" className="dynamicStyle hero_title">
              {data ? data["4055220626"] : "Lorem Ipsum"}
            </p>
            <ReactQuill
              id="7067874531"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["7067874531"] : "Lorem Ipsum"}
            />

            <Button
              className="lg:mt-8 md:mt-8 mt-6 dynamicStyle b_button_primary px-6 py-2 theme-btn sm-fsize14 md-fsize14"
              id="7516724899"
            >
              <span onClick={() => handleClick("7516724899")}>
                {data ? data["7516724899"] : "Submit"}
              </span>

              <FeatherIcon className="ml-3" size={18} icon="download" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection2;
