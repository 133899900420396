import { useSelector } from "react-redux/es/exports";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";


const AboutSection = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const data2 = useSelector((state) => state.cms.sectionNames[pageName]);
  console.log(data2, "aboutsectiondata");

  const CTALink = useSelector((state) => state.cms.links[pageName]);
  // let selectedStyle = useSelector(
  //   state => state.brandDetails.brandProfile.businessThemeVariation
  // );

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div
      className="px-5 py-12 lg:px-20 md:px-8 lg:py-20 md:py-16"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="mx-auto text-center lg:w-3/5 md:w-3/4 ">
          <h3
            id="9392653432"
            className="font-medium dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary"
          >
            {data ? data["9392653432"] : "Lorem Ipsum"}
          </h3>
          <h3 id="2809571636" className="dynamicStyle section_header">
            {data ? data["2809571636"] : "Lorem Ipsum"}
          </h3>

          <ReactQuill
            id="8263046497"
            theme="bubble"
            readOnly
            className="section_detail bubble2 dynamicStyle"
            value={data ? data["8263046497"] : "Lorem Ipsum"}
          />
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom lg:mt-16 md:mt-16 mt-12  ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <div
            id="6824273241"
            className="w-full dynamicStyle2 h-400px sm-h300px"
          >
            <Image
              src={data ? getImage(data["6824273241"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="section2"
              imageClassName="br-28px bg-ddd9d8 w-full h-full object-cover"
            />
          </div>

          <div className="flex justify-center mt-6 lg:mt-12 md:mt-8">
            <Button
              id="6662290131"
              className="rounded-md b_button_primary px-6 py-2 fsize16 dynamicStyle theme-btn"
            >
              <span onClick={() => handleClick("6662290131")}>
                {data ? data["6662290131"] : "Enquire Now"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
