import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';

const HelpSupport3 = () => {
  const { colorMode } = useColorMode();
  return (
    <div>
      <p
        className={
          colorMode === 'light'
            ? 'fsize20 md-fsize20 sm-fsize20 font-semibold text-black pb-2 theme-textColor'
            : 'cust-textColor fsize20 md-fsize20 sm-fsize20 font-semibold pb-2 theme-textColor'
        }
      >
        Help & Support
      </p>
      <div>
        <Accordion allowToggle>
          <AccordionItem mb={4} className="border-accordion">
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  className="cust-textColor"
                >
                  Section 1{' '}
                </Box>
                <AccordionIcon className="cust-textColor" />
              </AccordionButton>
            </h2>
            <AccordionPanel className="cust-textColor fsize15" pb={4}>
              <p>Lorem ipsum dolor sit amet.</p>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem mb={4} className="border-accordion">
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  className="cust-textColor "
                >
                  Section 2{' '}
                </Box>
                <AccordionIcon className="cust-textColor" />
              </AccordionButton>
            </h2>
            <AccordionPanel className="cust-textColor fsize15" pb={4}>
              <p>Lorem ipsum dolor sit amet.</p>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="flex items-center pt-2">
        <p className="fsize14 fweight-500 mr-1 cust-textColor">
          If you have any further queries{' '}
        </p>
        <span className="text-primary font-semibold textColor-light fsize14 cursor-pointer">
          Connect Us
        </span>
      </div>
    </div>
  );
};

export default HelpSupport3;
