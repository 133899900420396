import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";

const AboutSection23 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  let selectedStyle = useSelector(
    (state) => state.brandDetails.brandProfile.businessThemeVariation
  );
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:flex md:flex lg:gap-12 md:gap-12 gap-4 sm-reverse prime_container">
        <div className="lg:w-1/2 md:w-1/2">
          <div
            ref={elementRef}
            className={`fade-left w-full relative zindex9 ${
              isVisible ? "visibleleft" : ""
            } `}
          >
            <div id="0106312261" className="dynamicStyle2 ">
              <Image
                src={data ? getImage(data["0106312261"]) : ""}
                alt="heroImage"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="w-full sm-h250px md-h-350px minh450px object-cover rounded-lg"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-6 relative zindex9 flex flex-col justify-center">
          <h3 id="1715991546" className="dynamicStyle about_title">
            {data ? data["1715991546"] : "Lorem Ipsum"}
          </h3>
          <ReactQuill
            id="7586928637"
            theme="bubble"
            readOnly
            className="card_description bubble dynamicStyle"
            value={data ? data["7586928637"] : "Lorem Ipsum"}
          />
          <div className="w:1/3">
            <Button
              className="dynamicStyle bg-primary lg:mt-6 md:mt-6 mt-4 theme-btn sm-fsize14 b_button_primary px-6 py-2"
              id="8289171605"
            >
              <span onClick={() => handleClick("8289171605")}>
                {data ? data["8289171605"] : "Lorem Ipsum"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection23;
