import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EcomProductCategory4 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="w-full lg:flex md:flex justify-between items-center">
          <h3 id="9919625743" className="dynamicStyle section_header">
            {data ? data["9919625743"] : "Our Category"}
          </h3>
          <Button id="8924631184" className="b_button_primary py-2 px-4 lg:mt-0 md:mt-0 mt-2">
            <span onClick={() => handleClick("8924631184")}>
              {data ? data["8924631184"] : "View All"}
            </span>
          </Button>
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-4">
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
          <Card className="box-shadow-none bg-light-primary theme-bg themeShadow-insert themeShadow">
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                imageClassName="w-24 h-24 mx-auto object-cover lg:mb-3 mb-2"
              />
              <p className="lineClamp2 card_title_0 text-center">Title</p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EcomProductCategory4;
