import React from "react";
import { Button } from 'primereact/button';
import { useSelector } from "react-redux/es/exports";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Confirmation3 = ({ pageName, durationInMinutes }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  

  const minutes = 10;
  const durationInSeconds = minutes * 60;

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  return (
    <div className="flex items-center justify-center h-screen ">
      <div>
        <div className="w-1/2 mx-auto flex justify-center fsize42 sm-fsize40 font-semibold text-primary">
          <CountdownCircleTimer
            isPlaying
            duration={durationInSeconds}
            colors={["#626262"]}
            //   colorsTime={[7, 5, 2, 0]}
            // colorsTime={[
            //   durationInSeconds,
            //   durationInSeconds - 20,
            //   durationInSeconds - 40,
            //   0,
            // ]}
          >
            {/* {({ remainingTime }) => remainingTime} */}
            {({ remainingTime }) => formatTime(remainingTime)}
          </CountdownCircleTimer>
        </div>
        <p
          id="2107372496"
          className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:w-3/5 md:w-3/5 mx-auto my-6 text-center "
        >
          {data ? data["2107372496"] : "Lorem ipsum"}
        </p>
        <div className="flex gap-2 justify-center">
          <Button
            id="2231630111"
            className="dynamicStyle theme-btn b_button_primary px-6 py-2 font-semibold"
          >
            {data ? data["2231630111"] : "Lorem ipsum"}
          </Button>
          <Button
            id="6481564270"
            className="dynamicStyle text-primary theme-btn font-semibold px-6 py-2"
            outlined
          >
            {data ? data["6481564270"] : "Lorem ipsum"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation3;
