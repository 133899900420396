import React, { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const CardImage5 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="mx-auto text-center lg:w-2/3 md:w-2/3">
          <p
            id="0007414156"
            className="dynamicStyle text-primary section_detail_0"
          >
            {data ? data["0007414156"] : "About us"}
          </p>
          <h4
            id="6656700922"
            className="dynamicStyle section_header"
          >
            {data ? data["6656700922"] : "Lorem Ipsum"}
          </h4>
        </div>
        <div className=" w-full text-center">
          <ReactQuill
            id="4294615986"
            theme="bubble"
            readOnly
            className='bubble2 dynamicStyle section_detail'
            value={data ? data["4294615986"] : "Lorem Ipsum"}
          />
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom lg:flex md:flex lg:gap-10 md:gap-5 justify-evenly lg:mt-16 md:mt-16 mt-12 prime_card_container ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <div
            id="2720286230"
            className="dynamicStyle2 sm-pb-4 w-full overflow-hidden"
          >
            <Image
              imageClassName="fab-wh md-fab-wh custimghover object-contain"
              src={data ? getImage(data["2720286230"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image"
            />
          </div>
          <div
            id="2328975408"
            className="dynamicStyle2 sm-pb-4 w-full overflow-hidden"
          >
            <Image
              imageClassName="fab-wh md-fab-wh custimghover object-contain"
              src={data ? getImage(data["2328975408"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image"
            />
          </div>
          <div
            id="0338463378"
            className="dynamicStyle2 sm-pb-4 w-full overflow-hidden"
          >
            <Image
              imageClassName="fab-wh md-fab-wh custimghover object-contain"
              src={data ? getImage(data["0338463378"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImage5;
