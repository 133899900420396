import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Team5 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");
      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section
      className="relative chairman-h"
      style={{ background: thisComponent.sectionColor }}
    >
      <div id="8329931294" className="dynamicStyle2 w-full">
        <Image
          src={data ? getImage(data["8329931294"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="w-full chairman-h lg:block hidden"
          alt="chairman-h"
        />
      </div>
      <div className=" lg:absolute top-0 left-0 w-full">
        <div className="lg:flex md:flex lg:gap-0 md:gap-6 items-center container mx-auto lg:px-12 md:px-8 lg:py-28 px-5 py-12">
          <div className="lg:w-7/12 md:w-7/12 lg:pr-12">
            <h2 id="3476048990" className="dynamicStyle about_title">
              {data ? data["3476048990"] : "Lorem Ipsum"}
            </h2>
            <p
              id="6548547145"
              className="dynamicStyle text-gray fsize18 md-fsize16 sm-fsize14"
            >
              {data ? data["6548547145"] : "Lorem Ipsum"}
            </p>
            <ReactQuill
              id="8393953154"
              theme="bubble"
              readOnly
              className="bubble card_description dynamicStyle mt-1"
              value={data ? data["8393953154"] : "Lorem Ipsum"}
            />
            <Button
              outlined
              className="dynamicStyle px-6 py-2 sm-fsize14 lg:mt-4 md:mt-4 mt-3"
              id="2124959190"
            >
              <span
                className="flex items-center"
                onClick={() => handleClick("2124959190")}
              >
                {data ? data["2124959190"] : "Lorem Ipsum"}
                <FeatherIcon
                  className="lg:ml-3 ml-2"
                  size={18}
                  icon="arrow-right"
                />
              </span>
            </Button>
          </div>
          <div className="lg:w-5/12 md:w-5/12 lg:mt-0 md:mt-0 mt-8">
            <div
              ref={elementRef}
              className={`fade-right cards-about relative rounded-2xl bg-primary cust-bgColor ${
                isVisible ? "visibleright" : ""
              } `}
            >
              <div id="7982718256" className="dynamicStyle2 w-full">
                <Image
                  src={data ? getImage(data["7982718256"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="cards2-img sm-cards2-img absolute2-cards absolute object-cover z-10 rounded-2xl"
                  alt="Img_Preview"
                  style={{ transform: transform }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
                imageClassName="w-24 h-20 absolute bottom-0 left-0 object-contain"
                alt="side"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team5;
