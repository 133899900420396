import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection50 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex block gap-4">
          <div className="lg:w-1/2 md:w-1/2 flex items-center">
            <div className="">
              <h5 id="5352673750" className="dynamicStyle about_title">
                {data ? data['5352673750'] : 'no-data'}
              </h5>
              <ReactQuill
                id="2642419661"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description mb-2"
                value={data ? data['2642419661'] : 'Lorem Ipsum'}
              />
              <p
                id="1048663160"
                className="dynamicStyle fsize14 mt-2 underline text-primary"
              >
                {data ? data['1048663160'] : 'no-data'}
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0 mt-6 relative">
            <div className="bg-primary cust-bgColor lg:w-32 lg:h-32 md:w-28 md:h-28 w-16 h-16 rounded-lg absolute top-0 left-0"></div>
            <div className="bg-primary cust-bgColor lg:w-32 lg:h-32 md:w-28 md:h-28 w-16 h-16 rounded-lg absolute bottom-0 right-0"></div>
            <div
              id="8267370508"
              className="dynamicStyle2 w-11/12 mx-auto relative z-50 lg:py-6 md:py-4 py-2"
            >
              <Image
                src={data ? getImage(data['8267370508']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-about"
                imageClassName="w-full lg:h-96 md:h-60 h-52 object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection50;
