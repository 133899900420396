import React, { useEffect, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Image } from "primereact/image";

const Testimonial16 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const [currenttestimonial, settestimonials] = useState({});
  const dispatch = useDispatch();
  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);
  const data = useSelector((state) => state.cms.allSections[pageName]);

  useEffect(() => {
    dispatch(getTestimonal());
    settestimonials(getAllTestimonial?.data?.result?.[0]);
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="text-center lg:w-2/3 md:w-3/4 mx-auto">
          <h2 id="2522474431" class="dynamicStyle section_header">
            {data ? data["2522474431"] : "What our clients says"}
          </h2>
          <ReactQuill
            id="5052574322"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["5052574322"] : "Welcome to My World"}
          />
        </div>
        <div className="lg:w-7/12 md:w-9/12 mx-auto lg:mt-16 md:mt-16 mt-12">
          <Card className="prime_card text-center">
            <div className="lg:h-32 md:h-32 h-36">
              <p className="card_description lineClamp5">
                {currenttestimonial?.testimonial}
              </p>
            </div>
            <h4 className="card_title_0 lg:mt-4 mt-2">
              {currenttestimonial?.name}
            </h4>
          </Card>
          <div className="lg:pt-10 md:pt-6 pt-4 lg:w-1/2 md:w-8/12 w-full mx-auto">
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              // effect={'coverflow'}
              modules={[Navigation]}
              className="mySwiper"
              autoplay={{
                delay: "1000",
              }}
              // coverflowEffect={{
              //   rotate: -15,
              //   stretch: 0,
              //   depth: 100,
              //   modifier: 1,
              //   slideShadows: false,
              // }}
              disableOnInteraction={true}
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                767: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
              }}
            >
              {getAllTestimonial
                ? getAllTestimonial?.data?.result.map((testimonial, index) => {
                    return (
                      <SwiperSlide onClick={() => settestimonials(testimonial)}>
                        <Image
                          imageClassName={
                            currenttestimonial?._id === testimonial?._id
                              ? "lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full cursor-pointer borderwid border-primary object-cover"
                              : "lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full cursor-pointer object-cover"
                          }
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                          alt={testimonial.name}
                        />
                      </SwiperSlide>
                    );
                  })
                : "No Data"}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial16;
