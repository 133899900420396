import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import 'sweetalert2/src/sweetalert2.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { createContact } from '../../../redux/marcom/marcom-action';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const ContactForm3 = ({ pageName }) => {
  const dispatch = useDispatch();

  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  const initialValues = {
    name: '',
    email: '',
    description: '',
  };

  const validationRules = Yup.object({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .max(25, 'Name must be at most 25 characters')
      .required('Name is required')
      .matches(/^[a-zA-Z ]*$/, 'Only alphabets is allowed'),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, 'Email must be at most 50 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    // mobile: Yup.string()
    // .min(10, 'Mobile number must be 10 digits')
    // .max(10, 'Mobile number must be 10 digits')
    // .required('Mobile number is required'),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),

    description: Yup.string()
      .trim()
      .min(3, 'Message must be at least 3 characters')
      .max(100, 'Message must be at most 100 characters')
      .required('Message is required'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      //  mobile: values.mobile,
      description: values.description,
      //  countryCode: values.countryCode,
    };
    try {
      let data = await dispatch(createContact(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: `Will get back to you shortly`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Will get back to you shortly`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error('Something went wrong!');
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end
   
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-6 md:gap-6 gap-4 mb-10">
        <div className="bdr-d5d2d2-1px bg_surface100 cust-bgColor p-4 flex justify-center items-center">
          <div className="">
            <FeatherIcon
              icon="phone"
              className="mx-auto fill-icon lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
            />
            <p className="pt-4 card_description16px">9865457856</p>
          </div>
        </div>
        <div className="bdr-d5d2d2-1px bg_surface100 cust-bgColor p-4 flex justify-center items-center">
          <div className="">
            <FeatherIcon
              icon="mail"
              className="mx-auto fill-icon lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
            />
            <p className="pt-4 card_description16px">abc@gmail.com</p>
          </div>
        </div>
        <div className="bdr-d5d2d2-1px bg_surface100 cust-bgColor p-4 flex justify-center items-center">
          <div className="text-center">
            <FeatherIcon
              icon="map-pin"
              className="mx-auto fill-icon lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
            />
            <p className="pt-4 card_description16px">
              SBI Foundation, No. 35, Ground FloorThe Arcade, World Trade
              Center, Cuffe Parade Mumbai 400035
            </p>
          </div>
        </div>
      </div>
      <div className="lg:flex md:flex gap-10">
        <div className="lg:w-1/2 md:w-1/2 sm-wfull lg:mb-0 md:mb-0 mb-10">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <p id="7156014336" className="dynamicStyle pb-2 cust-textColor">
                {data ? data['7156014336'] : 'Lorem Ipsum'}
              </p>

              <InputText
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                invalid={!!formik.errors.name && formik.touched.name}
                placeholder="Enter your name"
                className="form-control theme-bg themeShadow bg_surface50"
              />
              {formik.touched.name && formik.errors.name && (
                <p className="text-danger fsize12 mt-2">{formik.errors.name}</p>
              )}
            </div>
            <div className="mb-4">
              <p id="2200954228" className="dynamicStyle pb-2 cust-textColor">
                {data ? data['2200954228'] : 'Lorem Ipsum'}
              </p>
             
                <InputText
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  invalid={!!formik.errors.email && formik.touched.email}
                  placeholder="Enter your email id"
                  className="form-control theme-bg themeShadow bg_surface50"
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-danger fsize12 mt-2">{formik.errors.email}</p>
                )}
            </div>
            <div className="mb-4">
              <p id="1943437306" className="dynamicStyle pb-2 cust-textColor">
                {data ? data['1943437306'] : 'Lorem Ipsum'}
              </p>
              
                <InputTextarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  invalid={
                    !!formik.errors.description && formik.touched.description
                  }
                  placeholder="Enter your message"
                  className="form-control theme-bg themeShadow bg_surface50"
                />
                {formik.touched.description && formik.errors.description && (
                  <p className="text-danger fsize12 mt-2">
                    {formik.errors.description}
                  </p>
                )}
            </div>

            <Button
              id="6317987363"
              type="submit"
             className="theme-btn2 w-full b_button_primary py-3 font-semibold flex justify-center sm-fsize14 lg:mt-6 md:mt-6 mt-4"
            >
              <span onClick={() => handleClick('6317987363')}>
                {data ? data['6317987363'] : 'Send'}
              </span>
            </Button>
          </form>
        </div>
        <div className="lg:w-1/2 md:w-1/2 sm-wfull">
          <div className="mb-4">
            <h3
              id="2284481732"
              className="dynamicStyle section_header"
            >
              {data ? data['2284481732'] : 'Lorem Ipsum'}
            </h3>
            <ReactQuill
                id="1534225896"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail_0 sm-fsize14"
                value={data ? data['1534225896'] : 'Lorem Ipsum'}
              />
          </div>
          <div className="mb-4">
            <p
              id="3161120496"
              className="dynamicStyle text-primary font-semibold fsize18 sm-fsize16 pb-2 cust-textColor"
            >
              {data ? data['3161120496'] : 'Lorem Ipsum'}
            </p>
            <p
              id="5622044910"
              className="dynamicStyle pb-1 sm-fsize14 text_surface800"
            >
              {data ? data['5622044910'] : 'Lorem Ipsum'}
            </p>
            <p
              id="6793819052"
              className="dynamicStyle pb-1 sm-fsize14 text_surface800"
            >
              {data ? data['6793819052'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="mb-4">
            <p
              id="7980021519"
              className="dynamicStyle text-primary font-semibold fsize18 sm-fsize16 pb-2 cust-textColor"
            >
              {data ? data['7980021519'] : 'Lorem Ipsum'}
            </p>
            <p
              id="8267311900"
              className="dynamicStyle pb-1 sm-fsize14 text_surface800t"
            >
              {data ? data['8267311900'] : 'Lorem Ipsum'}
            </p>
            <p
              id="5166659352"
              className="dynamicStyle pb-1 sm-fsize14 text_surface800"
            >
              {data ? data['5166659352'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div className="mb-4">
            <p
              id="5799308128"
              className="dynamicStyle text-primary font-semibold fsize18 sm-fsize16 pb-4 cust-textColor"
            >
              {data ? data['5799308128'] : 'Lorem Ipsum'}
            </p>
            <div className="flex gap-4">
              <div className="bdr-d5d2d2-1px rounded-full p-3">
                <FeatherIcon
                  icon="linkedin"
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                />
              </div>
              <div className="bdr-d5d2d2-1px rounded-full p-3">
                <FeatherIcon
                  icon="instagram"
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                />
              </div>
              <div className="bdr-d5d2d2-1px rounded-full p-3">
                <FeatherIcon
                  icon="facebook"
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                />
              </div>
              <div className="bdr-d5d2d2-1px rounded-full p-3">
                <FeatherIcon
                  icon="twitter"
                  className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactForm3;
