import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Card } from "primereact/card";
import { Image } from "primereact/image";

const Team4 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:w-2/3 md:w-2/3 mx-auto text-center">
          <h2 id="4425122477" className="dynamicStyle section_header">
            {data ? data["4425122477"] : "Meet Aur Professional teams"}
          </h2>

          <p id="4132953528" className="dynamicStyle section_detail">
            {data ? data["4132953528"] : "Lorem Ipsum"}
          </p>
        </div>
        <div
          id="5798453603"
          className="grid dynamicStyle lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-6 gap-8 lg:mt-16 md:mt-16 mt-12 prime_card_container"
        >
          {data && data["5798453603"]
            ? data["5798453603"].map((e) => {
                return (
                  <Card className="relative shadow-none bg_none lg:mx-2 md:mx-2">
                    <div className="h-200px overflow-hidden rounded-lg w-full lg:mb-4 md:mb-3 mb-2">
                      <Image
                        src={e ? getImage(e.field1) : ""}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt={e.field2}
                        imageClassName="h-200px zoom w-full object-contain rounded-lg"
                      />
                    </div>

                    <h4 className="text-center card_title_0">{e.field2}</h4>
                    <p className="card_description mt-1 text-center">
                      {e.field3}
                    </p>
                  </Card>
                );
              })
            : "No Data"}
        </div>
      </div>
    </section>
  );
};

export default Team4;
