import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const AboutSection50 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex block">
          <div className="lg:w-7/12 md:w-1/2 flex items-center">
            <div className="py-4">
              <h5 id="7077628475" className="dynamicStyle about_title">
                {data ? data['7077628475'] : 'no-data'}
              </h5>
              <ReactQuill
                id="1218793645"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description mb-6"
                value={data ? data['1218793645'] : 'Lorem Ipsum'}
              />
              <div className="flex items-center gap-4">
                <Button
                  className="theme-btn dynamicStyle b_button_primary px-6 py-2 fsize14 sm-fsize14"
                  id="5152155290"
                >
                  <span onClick={() => handleClick('5152155290')}>
                    {data ? data['5152155290'] : 'View More'}
                  </span>
                </Button>
                <Button
                  outlined
                  className="theme-btn dynamicStyle text-primary fsize14 sm-fsize14 px-6 py-2"
                  id="2354654484"
                >
                  <span onClick={() => handleClick('2354654484')}>
                    {data ? data['2354654484'] : 'View More'}
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 md:w-1/2 lg:mt-0 md:mt-0 mt-6 relative">
            <div className="bg-primary lg:w-60 lg:h-60 md:w-40 md:h-40 w-32 h-32 rounded-lg absolute top-0 right-0"></div>
            <div className="bg-primary lg:w-32 lg:h-32 md:w-28 md:h-28 w-16 h-16 rounded-lg absolute bottom-0 left-0 z-50"></div>
            <div
              id="7373018965"
              className="dynamicStyle2 lg:w-9/12 md:w-10/12 w-11/12 mx-auto relative z-40 lg:py-12 md:py-8 py-4"
            >
              <Image
                src={data ? getImage(data['7373018965']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-about"
                imageClassName="w-full h-450px md-h-350px sm-h-250px object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection50;
