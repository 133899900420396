import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const FAQSection5 = ({ pageName }) => {
          //section color start
          const [fileName, setFileName] = useState("");
          const [thisComponent, setThisComponent] = useState({});
          const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
          const selectedSection = useSelector((state) => state.cms.sectionNames);
          //sectioncolor end
          //sectioncolor start
          useEffect(() => {
            const name = new URL(import.meta.url);
            const filename = name.pathname.split("/").pop();
            console.log(filename);
            const componentName = filename.split(".")[0];
            setFileName(componentName);
        
            for (let i = 0; i < selectedSection[pageName].length; i++) {
              if (
                selectedSection[pageName][i].path.includes(componentName.split(".")[0])
              ) {
                setThisComponent(selectedSection[pageName][i]);
              }
            }
          }, [selectedSection]);
          //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-3/4 md:w-4/5 w-full mx-auto text-center">
          <p
            id="3888162991"
            className="dynamicStyle text-primary section_detail_0"
          >
            {data ? data["3888162991"] : "Start With The Basics"}
          </p>
          <p id="5729410541" className="dynamicStyle section_header">
            {data ? data["5729410541"] : "Frequently Asked Questions"}
          </p>
        </div>

        <div
          className="lg:mt-12 md:mt-10 mt-8 lg:px-16 md:px-6 px-3 dynamicStyle"
          id="5792463375"
        >
          <Accordion multiple activeIndex={[0]}>
            {data && data["5792463375"]
              ? data["5792463375"].map((e) => {
                  return (
                    <AccordionTab
                      key={e._id}
                      header={<h4 className="card_title_0"> {e.field1}</h4>}
                    >
                      <ReactQuill
                        id="7461373914"
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field2}
                      />
                    </AccordionTab>
                  );
                })
              : "no-data"}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQSection5;
