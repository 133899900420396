import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const FAQSection3 = ({ pageName }) => {
          //section color start
          const [fileName, setFileName] = useState("");
          const [thisComponent, setThisComponent] = useState({});
          const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
          const selectedSection = useSelector((state) => state.cms.sectionNames);
          //sectioncolor end
          //sectioncolor start
          useEffect(() => {
            const name = new URL(import.meta.url);
            const filename = name.pathname.split("/").pop();
            console.log(filename);
            const componentName = filename.split(".")[0];
            setFileName(componentName);
        
            for (let i = 0; i < selectedSection[pageName].length; i++) {
              if (
                selectedSection[pageName][i].path.includes(componentName.split(".")[0])
              ) {
                setThisComponent(selectedSection[pageName][i]);
              }
            }
          }, [selectedSection]);
          //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-3/4 md:w-4/5 w-full mx-auto">
          <h2
            id="1304142799"
            className="section_header dynamicStyle text-center"
          >
            {data ? data['1304142799'] : 'Questions Thats Comes in your Mind'}
          </h2>
          <ReactQuill
            id="4590447378"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['4590447378'] : 'Lorem Ipsum'}
          />
        </div>
      </div>
      <div
        id="4621206821"
        className="lg:mt-16 md:mt-16 mt-12 dynamicStyle lg:py-8 md:py-6 py-4 lg:px-16 md:px-6 px-4 border-about2 border-about1 relative"
      >
        <Accordion multiple activeIndex={[0]}>
          {data && data['4621206821']
            ? data['4621206821'].map(e => {
                return (
                  <AccordionTab
                    header={<h4 className="card_title_0"> {e.field1}</h4>}
                  >
                    <ReactQuill
                      id="7461373914"
                      theme="bubble"
                      readOnly
                      className="bubble card_description"
                      value={e.field2}
                    />
                  </AccordionTab>
                );
              })
            : 'no-data'}
        </Accordion>
        <div className="border-line"></div>
        <div className="border-line2"></div>
      </div>
    </div>
  );
};

export default FAQSection3;
