import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Search2Icon } from '@chakra-ui/icons';
import {
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  useColorMode,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import GoogleMap from 'google-maps-react-markers';
import { useSelector } from 'react-redux';

const MapSection4 = ({ pageName }) => {
  const coordinates = [
    { lat: 45.4046987, lng: 12.2472504, name: 'Place 1' },
    { lat: 46.2046987, lng: 13.2472504, name: 'Place 2' },
    // Add more coordinates as needed
  ];
  const mapOptions = {
    // Define your map options here
  };
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const [markers, setMarkers] = useState([]);
  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
    // Create markers
    const newMarkers = coordinates.map(({ lat, lng, name }) => {
      const marker = new maps.Marker({
        position: { lat, lng },
        map,
        title: name,
      });
      marker.addListener('click', () =>
        onMarkerClick({ markerId: name, lat, lng })
      );
      return marker;
    });
    setMarkers(newMarkers);
  };

  const onMarkerClick = ({ markerId, lat, lng }) => {
    console.log('This is ->', markerId);
    // inside the map instance you can call any google maps method
    mapRef.current.setCenter({ lat, lng });
  };

  useEffect(() => {
    // Clean up markers on unmount
    return () => {
      markers.forEach(marker => marker.setMap(null));
    };
  }, [markers]);

  const { colorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <div>
      <div className="lg:flex md:flex sm-reverse">
        <div className="lg:w-1/2 md:w-1/2 lg:px-10 md:px-8 lg:px-20 md:px-6 px-5 lg:py-10 md:py-8 py-4">
          <p
            id="6738038037"
            className="dynamicStyle fsize20 sm-fsize18 font-semibold text-primary lg:mb-3 md:mb-3 mb-2 cust-textColor"
          >
            {data ? data['6738038037'] : 'Lorem ipsum'}
          </p>
          <InputGroup className="mb-6">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              type="tel"
              placeholder="Service Search"
              className="bdr-1px-dcdcdc"
            />
          </InputGroup>
          <div>
            <div className="flex gap-2 items-center radio mb-4">
              <Radio value="1"></Radio>
              <div className="w-full flex gap-2 px-3 py-2 bdr-1px-dcdcdc rounded-lg">
                <div className="">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="lg:w-24 md:w-24 w-20 lg:h-24 md:h-24 h-20 object-cover rounded-md"
                  />
                </div>

                <div className="w-3/4">
                  <div className="flex gap-2 items-center">
                    <p className="fsize18 sm-fsize16 font-semibold text-primary cust-textColor">
                      TDP
                    </p>
                    <FeatherIcon
                      icon="star"
                      className="cust-textColor ml-2"
                      size={18}
                    />
                    <p className="fsize18 sm-fsize16 cust-textColor">4.5</p>
                  </div>
                  <div className="flex gap-2 items-center my-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="cust-textColor"
                      size={18}
                    />
                    <p className="fsize17 sm-fsize15 text-gray cust-textColor">
                      Akurli Road
                    </p>
                  </div>
                  <p className="fsize17 sm-fsize15 text-gray-400 textColor-light">
                    • 10Km
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center radio mb-4">
              <Radio value="1"></Radio>
              <div className="w-full flex gap-2 px-3 py-2 bdr-1px-dcdcdc rounded-lg">
                <div className="">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="lg:w-24 md:w-24 w-20 lg:h-24 md:h-24 h-20 object-cover rounded-md"
                  />
                </div>

                <div className="w-3/4">
                  <div className="flex gap-2 items-center">
                    <p className="fsize18 sm-fsize16 font-semibold text-primary">
                      TDP
                    </p>
                    <FeatherIcon
                      icon="star"
                      className="cust-textColor ml-2"
                      size={18}
                    />
                    <p className="fsize18 sm-fsize16">4.5</p>
                  </div>
                  <div className="flex gap-2 items-center my-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="cust-textColor"
                      size={18}
                    />
                    <p className="fsize17 sm-fsize15 text-gray">Akurli Road</p>
                  </div>
                  <p className="fsize17 sm-fsize15 text-gray-400">• 10Km</p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center radio mb-4">
              <Radio value="1"></Radio>
              <div className="w-full flex gap-2 px-3 py-2 bdr-1px-dcdcdc rounded-lg">
                <div className="">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="lg:w-24 md:w-24 w-20 lg:h-24 md:h-24 h-20 object-cover rounded-md"
                  />
                </div>

                <div className="w-3/4">
                  <div className="flex gap-2 items-center">
                    <p className="fsize18 sm-fsize16 font-semibold text-primary">
                      TDP
                    </p>
                    <FeatherIcon
                      icon="star"
                      className="cust-textColor ml-2"
                      size={18}
                    />
                    <p className="fsize18 sm-fsize16">4.5</p>
                  </div>
                  <div className="flex gap-2 items-center my-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="cust-textColor"
                      size={18}
                    />
                    <p className="fsize17 sm-fsize15 text-gray">Akurli Road</p>
                  </div>
                  <p className="fsize17 sm-fsize15 text-gray-400">• 10Km</p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center radio mb-4">
              <Radio value="1"></Radio>
              <div className="w-full flex gap-2 px-3 py-2 bdr-1px-dcdcdc rounded-lg">
                <div className="">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="lg:w-24 md:w-24 w-20 lg:h-24 md:h-24 h-20 object-cover rounded-md"
                  />
                </div>

                <div className="w-3/4">
                  <div className="flex gap-2 items-center">
                    <p className="fsize18 sm-fsize16 font-semibold text-primary">
                      TDP
                    </p>
                    <FeatherIcon
                      icon="star"
                      className="cust-textColor ml-2"
                      size={18}
                    />
                    <p className="fsize18 sm-fsize16">4.5</p>
                  </div>
                  <div className="flex gap-2 items-center my-1">
                    <FeatherIcon
                      icon="map-pin"
                      className="cust-textColor"
                      size={18}
                    />
                    <p className="fsize17 sm-fsize15 text-gray">Akurli Road</p>
                  </div>
                  <p className="fsize17 sm-fsize15 text-gray-400">• 10Km</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2">
          <div className="h-full">
            <GoogleMap
              apiKey="AIzaSyBaGHp3hW_TgoyCcbkuUogkIQqzolYdpmc"
              defaultCenter={{ lat: 45.4046987, lng: 12.2472504 }}
              defaultZoom={5}
              options={mapOptions}
              mapMinHeight="350px"
              onGoogleApiLoaded={onGoogleApiLoaded}
              onChange={map => console.log('Map moved', map)}
              className="sm-h-250px"
            />
          </div>
        </div>
      </div>
      <div className="lg:flex md:flex gap-8 justify-end items-center cust-shadow1 py-4 px-4">
        <div className="flex justify-center gap-4 lg:mb-0 md:mb-0 mb-2">
          <FeatherIcon
            icon="shopping-cart"
            className="cust-textColor "
            size={24}
          />
          <p className="sm-fsize14 cust-textColor">5 Added</p>
        </div>
        <div className="flex justify-center">
          <Button
            height="50px"
            className="b_button_primary px-6 py-2 theme-btn rounded-full sm-fsize14 rounded-lg"
            px={10}
          >
            Send Request
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MapSection4;
