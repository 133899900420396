import React from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const Jobs4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="flex items-center justify-between">
          <h2
            id="1051990722"
            className="dynamicStyle inline-block section_header theme-textColor"
          >
            {data ? data["1051990722"] : "Feature Jobs"}
          </h2>
          <p
            id="5482862315"
            className="dynamicStyle cursor-pointer fsize16 md-fsize16 sm-fsize14 font-medium text_surface800"
          >
            <span onClick={() => handleClick("5482862315")}>
              {data ? data["5482862315"] : "View More"}
            </span>
          </p>
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 w-full grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4">
          <Card className="theme-bg prime_card_0">
            <div className="relative themeShadow theme-bg rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-300px sm-h-250px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 ">
                <div className="flex items-center gap-2 pb-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface800"
                    size={16}
                  />
                  <p className="fsize12 text_surface800">Mumbai, India</p>
                </div>
                <h2 className="fsize24 md-fsize20 sm-fsize18 font-semibold text_surface800">
                  Relation Manager Analyst
                </h2>
                <p className="fsize10 text_surface800">Duration: 6 months</p>
              </div>
            </div>
          </Card>
          <Card className="theme-bg prime_card_0">
            <div className="relative themeShadow theme-bg rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-300px sm-h-250px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 ">
                <div className="flex items-center gap-2 pb-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface800"
                    size={16}
                  />
                  <p className="fsize12 text_surface800">Mumbai, India</p>
                </div>
                <h2 className="fsize24 md-fsize20 sm-fsize18 font-semibold text_surface800">
                  Relation Manager Analyst
                </h2>
                <p className="fsize10 text_surface800">Duration: 6 months</p>
              </div>
            </div>
          </Card>
          <Card className="theme-bg prime_card_0">
            <div className="relative themeShadow theme-bg rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-300px sm-h-250px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 ">
                <div className="flex items-center gap-2 pb-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface800"
                    size={16}
                  />
                  <p className="fsize12 text_surface800">Mumbai, India</p>
                </div>
                <h2 className="fsize24 md-fsize20 sm-fsize18 font-semibold text_surface800">
                  Relation Manager Analyst
                </h2>
                <p className="fsize10 text_surface800">Duration: 6 months</p>
              </div>
            </div>
          </Card>
          <Card className="theme-bg prime_card_0">
            <div className="relative themeShadow theme-bg rounded-lg">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-300px sm-h-250px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 ">
                <div className="flex items-center gap-2 pb-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface800"
                    size={16}
                  />
                  <p className="fsize12 text_surface800">Mumbai, India</p>
                </div>
                <h2 className="fsize24 md-fsize20 sm-fsize18 font-semibold text_surface800">
                  Relation Manager Analyst
                </h2>
                <p className="fsize10 text_surface800">Duration: 6 months</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Jobs4;
