import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard7 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const [cards, setCards] = useState([]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }
  function errorImage(e) {
    e.target.src = `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
  }
  const getCardDetails = async () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
      paginated: true,
      limit: 3,
      offset: 0,
    };
    // let data = await dispatch(getData("startup_registration", payload));
    console.log(data, 'cards');
    setCards(data.result);
  };
  useEffect(() => {
    getCardDetails();
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex gap-4 lg:pb-12 md:pb-12 pb-6">
            <div className="lg:w-1/2 md:w-1/2">
              <h4
                className="text-primary fsize16 md-fsize16 sm-fsize14 cust-textColor font-medium dynamicStyle"
                id="1013185111"
              >
                {data ? data['1013185111'] : 'Deals'}
              </h4>
              <h2 className="section_header dynamicStyle" id="0286698457">
                {data
                  ? data['0286698457']
                  : 'We are the best Startup raise company'}
              </h2>
            </div>
            <div className="lg:w-1/2 md:w-1/2">
              <ReactQuill
                id="2296737000"
                theme="bubble"
                readOnly
                className="section_detail_0 bubble dynamicStyle"
                value={data ? data['2296737000'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
          <div
            ref={elementRef}
            className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2  lg:gap-10 md:gap-10 gap-6 ${
              isVisible ? 'visiblebottom' : ''
            } `}
          >
            <Card className="box-shdw rounded-2xl p-4 lg:pt-10 md:pt-10 pt-4 themeShadow theme-bg">
              <div className=" flex justify-center">
                <Image
                  imageClassName="lg:h-24 lg:w-24 w-20 h-20 lg:mb-4 md:mb-4 mb-2"
                  // src={getImage(card['company logo'])}
                  src={data ? getImage(data['']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="Img_Preview"
                  onError={event => errorImage(event)}
                />
              </div>
              <div className="flex justify-evenly items-center ">
                {/* <p className="fsize20 font-semibold cust-textColor">{card['company name']}</p>
                
                Company Name*/}
                <p className="card_title_0">Company Name</p>
                {/* <p className="text-gray cust-textColor">{card["typeOfFunding"]}</p> */}
              </div>
              <div className="mt-1">
                <p className="card_description text-center">
                  {/* {card['companydescription']} */} company Description
                </p>
              </div>
              <div className="flex justify-center gap-8 mt-4">
                <div className="text-center">
                  <p className="fsize14 text-gray cust-textColor mb-1">Stage</p>
                  {/* <p className="fsize20 font-semibold cust-textColor">{card['stageOfStartup']}</p> */}
                  <p className="fsize20 sm-fsize18 font-semibold cust-textColor">
                    Test
                  </p>
                </div>
                <div className="text-center">
                  <p className="fsize14 text-gray cust-textColor mb-1">
                    Round Size
                  </p>
                  {/* <p className="fsize20 font-semibold cust-textColor">{card['fundingamount']}</p> */}
                  <p className="fsize20 sm-fsize18 font-semibold cust-textColor">
                    45
                  </p>
                </div>
                <div className="text-center">
                  <p className="fsize14 text-gray cust-textColor mb-1">Team</p>
                  <AvatarGroup>
                    <Avatar
                      image="/images/avatar/amyelsner.png"
                      shape="circle"
                    />
                    <Avatar
                      image="/images/avatar/asiyajavayant.png"
                      shape="circle"
                    />

                    <Avatar label="+2" shape="circle" />
                  </AvatarGroup>
                </div>
              </div>
              <div className="pt-6 pb-4 flex justify-center gap-8">
                <Button className="fsize14 text-primary outline-btn px-6 py-2" outlined>
                  {/* {card['companylocation']} */} Company Location
                </Button>
              </div>
            </Card>
          </div>
          <div className="flex justify-center lg:pt-10 md:pt-10 pt-6">
            <Button
              id="0955757696"
              className="dynamicStyle b_button_primary theme-btn sm-fsize14 px-6 py-2"
            >
              {/* <Link to="/Portfolio Page"> */}
              <span onClick={() => handleClick('0955757696')}>
                {' '}
                {data ? data['0955757696'] : ' View More'}
                {/* </Link> */}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard7;
