import React from "react";
import { useState } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import ScrollTrigger from "react-scroll-trigger";

const CounterSection5 = ({ pageName }) => {
  const [counterOn, setCounterOn] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <section className="bg-primary lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="lg:flex md:flex items-center justify-between prime_container">
        <div className="">
          <h2
            id="3215290451"
            className="dynamicStyle section_header font-semibold clr-fff"
          >
            {data ? data["3215290451"] : "Lorem Ipsum"}
          </h2>
          <p
            id="4746605990"
            className="dynamicStyle fsize20 md-fsize18 sm-fsize16 font-medium text-secondary lg:mt-3 md:mt-3 mt-2"
          >
            {data ? data["4746605990"] : "Lorem Ipsum"}
          </p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-12 gap-4 lg:mt-0 md:mt-0 mt-6">
          <div className=" flex justify-center items-center">
            <div>
              <div className="flex items-center justify-center text-primary">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-bold fsize50 md-fsize30 sm-fsize24 clr-fff"
                      start={0}
                      end={200}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 clr-fff">
                  +
                </p>
              </div>
              <p
                id="2382535317"
                className="dynamicStyle text-center card_title_0 text-secondary"
              >
                {data ? data["2382535317"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div>
              <div className="flex items-center justify-center text-primary">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-bold fsize50 md-fsize30 sm-fsize24 clr-fff"
                      start={0}
                      end={100}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 clr-fff">
                  +
                </p>
              </div>
              <p
                id="4757730704"
                className="dynamicStyle text-center card_title_0 text-secondary"
              >
                {data ? data["4757730704"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div>
              <div className="flex items-center justify-center text-primary">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-bold fsize50 md-fsize30 sm-fsize24 clr-fff"
                      start={0}
                      end={83}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 clr-fff">
                  +
                </p>
              </div>
              <p
                id="3943088763"
                className="dynamicStyle text-center card_title_0 text-secondary"
              >
                {data ? data["3943088763"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection5;
