import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import Aos from "aos";
import "aos/dist/aos.css";


const FeatureCard66 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex">
          <div className="lg:w-1/2 md:w-1/2 lg:mb-0 md:mb-0 mb-4">
            <h3 id="5427258226" className="dynamicStyle section_header">
              {data ? data["5427258226"] : "no-data"}
            </h3>
            <Button
              className="dynamicStyle theme-btn b_button_primary dynamicStyle fsize16 sm-fsize14 mt-6 px-6 py-2"
              id="8753017405"
            >
              <span onClick={() => handleClick("8753017405")}>
                {data ? data["8753017405"] : "View More"}
              </span>
            </Button>
          </div>
          <div className="lg:w-1/2 md:w-1/2">
            <ReactQuill
              id="5861598084"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle section_detail_0"
              value={data ? data["5861598084"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12">
          <div>
            <div id="3078520317" className="dynamicStyle2 w-full mb-4 relative">
              <Image
                src={data ? getImage(data["3078520317"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-300px md-h-250px sm-h-250px w-full rounded-2xl object-cover"
              />
              <p
                id="3944257932"
                className="dynamicStyle clr-fff font-semibold absl-faq6 bottom-0 left-0 w-full fsize20 sm-fsize16 pb-4 text-center"
              >
                {data ? data["3944257932"] : "no-data"}
              </p>
            </div>
            <div id="9990474707" className="dynamicStyle2 w-full mb-4 relative">
              <Image
                src={data ? getImage(data["9990474707"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-450px md-h-380px sm-h-350px w-full rounded-2xl object-cover"
              />
              <p
                id="6573372388"
                className="dynamicStyle clr-fff font-semibold absl-faq6 bottom-0 left-0 w-full fsize20 sm-fsize16 pb-4 text-center"
              >
                {data ? data["6573372388"] : "no-data"}
              </p>
            </div>
          </div>
          <div>
            <div id="0628959987" className="dynamicStyle2 w-full mb-4 relative">
              <Image
                src={data ? getImage(data["0628959987"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-450px md-h-380px sm-h-350px w-full rounded-2xl object-cover"
              />
              <p
                id="1463538746"
                className="dynamicStyle clr-fff font-semibold absl-faq6 bottom-0 left-0 w-full fsize20 sm-fsize16 pb-4 text-center"
              >
                {data ? data["1463538746"] : "no-data"}
              </p>
            </div>
            <div id="7005942186" className="dynamicStyle2 w-full mb-4 relative">
              <Image
                src={data ? getImage(data["7005942186"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-300px md-h-250px sm-h-250px w-full rounded-2xl object-cover"
              />
              <p
                id="1147904530"
                className="dynamicStyle clr-fff font-semibold absl-faq6 bottom-0 left-0 w-full fsize20 sm-fsize16 pb-4 text-center"
              >
                {data ? data["1147904530"] : "no-data"}
              </p>
            </div>
          </div>
          <div>
            <div id="8083231373" className="dynamicStyle2 w-full mb-4 relative">
              <Image
                src={data ? getImage(data["8083231373"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-300px md-h-250px sm-h-250px w-full rounded-2xl object-cover"
              />
              <p
                id="4531095772"
                className="dynamicStyle clr-fff font-semibold absl-faq6 bottom-0 left-0 w-full fsize20 sm-fsize16 pb-4 text-center"
              >
                {data ? data["4531095772"] : "no-data"}
              </p>
            </div>
            <div id="4836595124" className="dynamicStyle2 w-full mb-4 relative">
              <Image
                src={data ? getImage(data["4836595124"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="h-450px md-h-380px sm-h-350px w-full rounded-2xl object-cover"
              />
              <p
                id="5523533500"
                className="dynamicStyle clr-fff font-semibold absl-faq6 bottom-0 left-0 w-full fsize20 sm-fsize16 pb-4 text-center"
              >
                {data ? data["5523533500"] : "no-data"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard66;
