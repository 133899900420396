import { Image } from "primereact/image";
import { Card } from "primereact/card";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard67 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-14 md:py-16 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h2
            id="5729923090"
            className="dynamicStyle section_header cust-textColor relative "
          >
            {data ? data["5729923090"] : "Welcome to My World"}
          </h2>
          <ReactQuill
            id="6690059723"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["6690059723"] : "Welcome to My World"}
          />
        </div>
        <div id="8394321191" className="dynamicStyle lg:mt-16 md:mt-16 mt-12">
          <div
            ref={elementRef}
            className={`fade-bottom prime_card_container w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-16 md:gap-8 gap-4 ${
              isVisible ? "visiblebottom" : ""
            } `}
          >
            {data && data["8394321191"]
              ? data["8394321191"].map((e) => {
                  return (
                    <Card className="bg_none shadow-none">
                      <div className="">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="lg:w-20 lg:w-20 w-12 h-12 lg:mb-3 md:mb-3 mb-2 object-contain"
                        />
                      </div>

                      <div className="">
                        <h3 className="card_title_0 cust-textColor">
                          {e.field2}
                        </h3>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble card_description mt-2 lineclamp-quill2"
                          value={e.field3}
                        />
                      </div>
                    </Card>
                  );
                })
              : "no-card"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard67;
