import React, { useEffect, useState } from "react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import {
  getAllBlogs,
  setSelectedBlog,
} from "../../../redux/blogs/blogs-actions";
import { useDispatch } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";

const Blogs7 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  let navigate = useNavigate();

  const data = useSelector((state) => state.cms.allSections[pageName]);
  let allBlog = useSelector((state) => state.BlogDetails.Blog);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);

  const dispatch = useDispatch();

  const handleBlogDetail = (blogId) => {
    dispatch(setSelectedBlog({}));
    console.log(blogId, "blogId");
    navigate(`/blog detail/${blogId}`);
  };

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h2 id="9893547861" className="dynamicStyle section_header">
            {data ? data["9893547861"] : "Lorem ipsum"}
          </h2>
          <ReactQuill
            id="5045829295"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["5045829295"] : "Lorem ipsum"}
          />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6">
          {/* {allBlog?.length > 0
            ? allBlog.map((item) => (
                <Card className="prime_card themeShadow theme-bg">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img-blog"
              imageClassName="w-full lg:h-72 md:h-60 h-60 mb-4 rounded-lg"
            />
            <div>
              <p className="fsize14 sm-fsize12">Weight Loss</p>
              <p className="card_title">title</p>
              <p className="card_description">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo,
                fugit? amet consectetur adipisicing elit.
              </p>
            </div>
            <Card className="prime_card mt-4">
              <div className="lg:flex justify-between theme_insertShadow theme-bg">
                <div className="flex items-center gap-2 lg:w-3/5 lg:mb-0 mb-3">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img-blog"
                    imageClassName="lg:h-14 lg:w-14 md:h-14 md:w-14 h-10 w-10 rounded-md"
                  />
                  <div>
                    <p className="card_title_0">Lorem</p>
                    <p className="card_description">23 May 2024 - min read</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <FeatherIcon
                    icon="heart"
                    size={44}
                    className="cursor-pointer bdr-1px-eef8d3 p-3 rounded-md themeShadow theme-bg"
                  />
                  <FeatherIcon
                    icon="bookmark"
                    size={44}
                    className="cursor-pointer bdr-1px-eef8d3 p-3 rounded-md themeShadow theme-bg"
                  />
                </div>
              </div>
            </Card>
          </Card>
              ))
            : "no-data"} */}
          <Card className="prime_card_0 py-4 lg:px-4 px-3 themeShadow theme-bg">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img-blog"
              imageClassName="w-full lg:h-72 md:h-60 h-60 mb-4 rounded-lg"
            />
            <div>
              <p className="fsize14 sm-fsize12">Weight Loss</p>
              <p className="card_title">Lorem</p>
              <p className="card_description">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo,
                fugit? amet consectetur adipisicing elit.
              </p>
            </div>
            <Card className="prime_card_0 lg:p-4 md:p-3 p-3 mt-4">
              <div className="flex justify-between theme_insertShadow theme-bg">
                <div className="flex items-center gap-2 lg:w-3/5 w-9/12">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img-blog"
                    imageClassName="lg:h-14 lg:w-14 md:h-12 md:w-12 h-10 w-10 rounded-md"
                  />
                  <div>
                    <p className="card_title_0">Lorem</p>
                    <p className="card_description">23 May 2024 - min read</p>
                  </div>
                </div>
                <div className="flex items-center justify-end lg:w-2/5 w-3/12 gap-2">
                  <div className="cursor-pointer bdr-1px-eef8d3 lg:p-3 md:p-1 p-1 rounded-md themeShadow theme-bg">
                    <FeatherIcon icon="heart" />
                  </div>
                  <div className="cursor-pointer bdr-1px-eef8d3 lg:p-3 md:p-1 p-1 rounded-md themeShadow theme-bg">
                    <FeatherIcon icon="bookmark" />
                  </div>
                </div>
              </div>
            </Card>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Blogs7;
