import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const AddBanner11 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div id="0464460323" className="dynamicStyle2 relative">
          <Image
            src={data ? getImage(data["0464460323"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            imageClassName="w-full h-450px md-h-400px sm-h-250px object-cover rounded-2xl"
          />
          <div className="absolute bottom-0 right-0 lg:pr-10 md:pr-10 pr-6 lg:pb-14 md:pb-14 pb-8 text-right lg:w-3/5 md:w-3/5 w-5/6">
            <p
              id="7301420973"
              className="dynamicStyle fsize42 md-fsize36 sm-fsize24 clr-fff font-semibold pb-2"
            >
              {data ? data["7301420973"] : "no-data"}
            </p>
            <ReactQuill
              id="9928680830"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle sm-fsize14 clr-fff"
              value={data ? data["9928680830"] : "Lorem Ipsum"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner11;
