import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const AboutSection36 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <p
          id="2102793610"
          className="dynamicStyle inline-block fsize16 md-fsize16 sm-fsize14 text-primary"
        >
          {data ? data["2102793610"] : "ALL YOUR"}
        </p>
        <h2 id="4939906257" className="dynamicStyle about_title tracking-wide ">
          {data ? data["4939906257"] : " BEAUTY FAVOURITES"}
        </h2>
        <ReactQuill
          id="1811562938"
          theme="bubble"
          readOnly
          className="bubble dynamicStyle card_description"
          value={data ? data["1811562938"] : "Lorem Ipsum"}
        />
        <div className="lg:flex md:flex items-center lg:py-8 md:py-10 py-5">
          <div className="lg:w-1/2 md:w-1/2">
            <div
              id="8140952482"
              className="dynamicStyle grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-10 md:gap-10 gap-4"
            >
              {data && data["8140952482"]
                ? data["8140952482"].map((e) => {
                    return (
                      <div className="flex items-center lg:gap-3 md:gap-3 gap-2">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          imageClassName="lg:w-16 md:w-12 w-12 lg:h-16 md:h-12 h-12 object-contain"
                          alt={e.field2}
                        />
                        <p className="fsize18 md-fsize14 sm-fsize12">
                          {e.field2}
                        </p>
                      </div>
                    );
                  })
                : "no-data"}
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 lg:pl-12 md:pl-12 lg:mt-0 md:mt-0 mt-10">
            <div>
              <div id="5465481239" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data["5465481239"]) : ""}
                  alt="aboutImage"
                  imageClassName="w-full sm-h250px minh350px object-cover rounded-lg"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection36;
