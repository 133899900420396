import React, { useEffect, useRef, useState } from 'react';
import { Checkbox } from "primereact/checkbox";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useDispatch, useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';
import 'sweetalert2/src/sweetalert2.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import { createContact } from '../../../redux/marcom/marcom-action';

const ContactForm1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const initialValues = {
    name: '',
    email: '',
    mobile: '',
    description: '',
    countryCode: '',
  };

  const validationRules = Yup.object({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .max(25, 'Name must be at most 25 characters')
      .required('Name is required')
      .matches(/^[a-zA-Z ]*$/, 'Only alphabets is allowed'),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, 'Email must be at most 50 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    mobile: Yup.string()
      // .min(10, 'Mobile number must be 10 digits')
      // .max(10, 'Mobile number must be 10 digits')
      .required('Mobile number is required'),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),

    description: Yup.string()
      .trim()
      .min(3, 'Message must be at least 3 characters')
      .max(100, 'Message must be at most 100 characters')
      .required('Message is required'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      mobile: values.mobile,
      description: values.description,
      countryCode: values.countryCode,
    };
    try {
      let data = await dispatch(createContact(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: `Will get back to you shortly`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Will get back to you shortly`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error('Something went wrong!');
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, '');
    await formik.setFieldValue('mobile', phoneNumber);
    await formik.setFieldValue('countryCode', dialCountryCode);
  };

  const [ingredients, setIngredients] = useState([]);

    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }

     //section color start
     const [fileName, setFileName] = useState("");
     const [thisComponent, setThisComponent] = useState({});
     const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
     const selectedSection = useSelector((state) => state.cms.sectionNames);
     //sectioncolor end
     //sectioncolor start
     useEffect(() => {
       const name = new URL(import.meta.url);
       const filename = name.pathname.split("/").pop();
       console.log(filename);
       const componentName = filename.split(".")[0];
       setFileName(componentName);
   
       for (let i = 0; i < selectedSection[pageName].length; i++) {
         if (
           selectedSection[pageName][i].path.includes(componentName.split(".")[0])
         ) {
           setThisComponent(selectedSection[pageName][i]);
         }
       }
     }, [selectedSection]);
     //sectioncolor end
     
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex lg:justify-between gap-4 w-full">
          <div
            ref={elementRef}
            className={`fade-left lg:w-1/2 lg:mb-0 md:mb-6 ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <p
              id="2815266821"
              className="dynamicStyle lg:w-11/12 fsize86 md-fsize42 md-lh-48 sm-fsize24 font-semibold sm-lead "
            >
              {data ? data['2815266821'] : 'Lorem Ipsum generate'}
            </p>
          </div>
          <Card className="lg:w-1/2 contactcss prime_card_0 themeShadow theme-bg lg:mt-0 md:mt-0 mt-4">
            <p
              id="7038901551"
              className="dynamicStyle lg:mb-4 md:mb-4 mb-2 letter-spacing4px fsize24 sm-fsize18 font-semibold clr-ddd9d8"
            >
              {data ? data['7038901551'] : 'Lorem Ipsum generate'}
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="lg:mb-10 mb-3">
                <InputText
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  invalid={!!formik.errors.name && formik.touched.name}
                  type="text"
                  placeholder="Full Name*"
                  className="inputFeild theme-bg themeShadow form1"
                />
                {formik.touched.name && formik.errors.name && (
                  <p className="text-danger fsize12 mt-2">
                    {formik.errors.name}
                  </p>
                )}
              </div>

              <div className="lg:mb-10 mb-3">
                <PhoneInput
                  name="mobile"
                  country={'sg'}
                  enableSearch={true}
                  placeholder="Phone number*"
                  className="inputFeild theme-bg themeShadow w-full contactForm1 contactbg1"
                  invalid={!!formik.errors.mobile && formik.touched.mobile}
                  onChange={handlePhoneChange}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <p className="text-danger fsize12 mt-2">
                    {formik.errors.mobile}
                  </p>
                )}
              </div>

              <div className="lg:mb-10 mb-3">
                <InputText
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  invalid={!!formik.errors.email && formik.touched.email}
                  type="text"
                  placeholder="Email Id*"
                  className="inputFeild theme-bg themeShadow form1"
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-danger fsize12 mt-2">
                    {formik.errors.email}
                  </p>
                )}
              </div>

              <div className="lg:mb-6 mb-3">
                <InputTextarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  invalid={
                    !!formik.errors.description && formik.touched.description
                  }
                  type="text"
                  placeholder="Your Message*"
                  className="inputFeild theme-bg themeShadow form1"
                />
                {formik.touched.description && formik.errors.description && (
                  <p className="text-danger fsize12 mt-2">
                    {formik.errors.description}
                  </p>
                )}
              </div>
              <div className="lg:mb-10 mb-4">
                {/* <Checkbox>
                  <span className="fsize14 text-dark cust-textColor">
                    Check to subscribe to our Newsletter
                  </span>
                </Checkbox> */}
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="ingredient1"
                    name="pizza"
                    value="Check to subscribe to our Newsletter"
                    onChange={onIngredientsChange}
                    checked={ingredients.includes('Check to subscribe to our Newsletter')}
                    className=''
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                  Check to subscribe to our Newsletter
                  </label>
                </div>
              </div>
              <Button
                id="7398466773"
                type="submit"
                className="theme-btn2 w-full b_button_primary py-3 font-semibold flex justify-center sm-fsize14"
              >
                {data ? data['7398466773'] : 'Enquire Now'}
              </Button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ContactForm1;
