import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import ImageGallery from 'react-image-gallery';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';

const EcomProductDetails4 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const colors = [
    {
      color: 'red',
    },
    {
      color: 'green',
    },
    {
      color: 'blue',
    },
    {
      color: 'orange',
    },
  ];
  const sizes = [
    {
      size: 'X',
    },
    {
      size: 'XL',
    },
    {
      size: 'XXL',
    },
    {
      size: 'XXXL',
    },
  ];
  const images = [
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex gap-8">
          <div className="lg:w-1/2 md:w-full w-full cust-gall">
            <ImageGallery items={images} />
          </div>
          <div className="lg:w-1/2 md:w-full w-full lg:mt-0 mt-20">
            <div className="w-full flex items-center justify-between">
              <h3 className="section_detail_0">
                Loungewear
              </h3>
              <p className="card_description flex gap-1 items-center">
                <FeatherIcon icon="star" size={12} /> (101)
              </p>
            </div>
            <h2 className="section_header">Lorem ipsum dolor sit amet.</h2>
            <div className="lg:mt-4 md:mt-4 mt-2 flex flex-wrap items-center lg:gap-2 md:gap-2 mb-2 cust-textColor">
              <p className="section_detail_0 text-dark">₹123456</p>
              <p className="section_detail_0 strikethrough">₹123456</p>
              <p className="section_detail_0 text-dark">
                (10% off) inclusive all taxes
              </p>
            </div>
            <div className="">
              <p className="card_description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                amet dolorem veniam autem accusantium sequi eveniet,
                necessitatibus ad nihil, maxime excepturi? Aliquid atque
                dignissimos cupiditate ullam quibusdam ex. Neque autem libero
                nulla distinctio perspiciatis ipsa, cupiditate porro quo
                temporibus doloremque?
              </p>
            </div>
            <div className="mt-3 grid gap-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
              <div className="">
                <p className="card_description text-dark">Select Color*</p>
                <div className="flex gap-2 mt-1">
                  {colors
                    ? colors.map(col => {
                        return (
                          <p className="border-1px px-3 py-1 rounded-sm fsize14 cursor-pointer textColor-light">
                            {col.color}
                          </p>
                        );
                      })
                    : 'no-data'}
                </div>
              </div>
              <div className="">
                <p className="card_description text-dark">Select Color*</p>
                <div className="flex gap-2 mt-1">
                  {sizes
                    ? sizes.map(siz => {
                        return (
                          <p className="border-1px px-5 py-1 rounded-sm fsize14 cursor-pointer textColor-light">
                            {siz.size}
                          </p>
                        );
                      })
                    : 'no-data'}
                </div>
              </div>
            </div>

            <div className="lg:mt-6 md:mt-6 mt-4">
            <div className="w-full">
              <Button
                className="dynamicStyle bg-primary w-full flex justify-center clr-fff"
                id="3692437210"
              >
                <span onClick={() => handleClick('3692437210')}>
                  {data ? data['3692437210'] : 'Add to cart'}
                </span>
              </Button>
            </div>
            <div className="lg:mt-4 md:mt-4 mt-2 w-full">
              <Button
                outlined
                className="dynamicStyle w-full flex justify-center"
                id="1513051692"
              >
                <span onClick={() => handleClick('1513051692')}>
                  {data ? data['1513051692'] : 'Wishlist'}
                </span>
              </Button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomProductDetails4;
