import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection11 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="relative h-450px sm-h250px" style={{ background: thisComponent.sectionColor }}>
      <div id="8936215976" className="dynamicStyle2 w-full">
        <Image
          src={data ? getImage(data['8936215976']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          imageClassName="w-full h-450px sm-h-350px sm-h250px object-cover"
        />
      </div>
      <div className="absolute sm-wfull w-full top-0 left-0 h-450px sm-h250px lg:px-0 md:px-0 px-5 flex items-center">
        <div
          ref={elementRef}
          className={`fade-bottom w-60 md-w-70 sm-wfull mx-auto flex flex-col justify-center items-center ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <p
            id="5873626118"
            className="fsize16 md-fsize16 sm-fsize14 letter-spacing4px font-medium text-primary mb-2 dynamicStyle text-center"
          >
            {data ? data['5873626118'] : 'About Us'}
          </p>
          <h1 id="5823783444" className="hero_title text-center dynamicStyle">
            {data ? data['5823783444'] : 'Lorem ipsum'}
          </h1>
          <ReactQuill
            id="0500945071"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle card_description"
            value={data ? data['0500945071'] : 'Lorem Ipsum'}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection11;
