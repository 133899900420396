import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';
import FeatherIcon from 'feather-icons-react';

const SearchByLocation = () => {
  return (
    <div className="flex w-full">
      <div className="w-full lg:block md:block hidden lg:h-screen md:h-full">
        <img
          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          alt="banner"
          className="h-screen md:h-1/2  w-full"
        />
      </div>
      <div className="w-full lg:px-8 md:px-8 px-5 py-8 md:h-full lg:h-screen">
        <div className="">
          <h1 className="fsize48 font-bold md-fsize32 sm-fsize24">
            Looking for a turf?
          </h1>
          <p className="clr-7c7c7c fsize16 md-fsize14 sm-fsize14">
            Search for cricket, football, hockey turf nearby you.
          </p>
          <div className="relative w-full lg:mt-8 md:mt-6 mt-4">
            <InputGroup size="md">
              <Input
                py="0.75rem"
                pr="4.5rem"
                height="fit-content"
                placeholder="search for a turf nearby"
                className="fsize14"
              />
              <InputRightElement width="5.5rem" my="0.25rem">
                <Button size="md" mr={1} px="1rem" className='b_button_primary px-6 py-2'>
                  Search
                </Button>
              </InputRightElement>
            </InputGroup>
          </div>
          <div className="borderall rounded-lg mt-6 p-4">
            <h2 className="fsize24 md-fsize18 fsize16 font-semibold">
              Popular turf & ground nearby you
            </h2>
            <div className="mt-4 grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-4 turf_scroller">
              <div className="borderall w-full rounded-lg">
                <div className="p-4 ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="turf-name"
                    className="activites_image rounded-lg object-cover"
                  />
                  <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                  <div className="flex items-center">
                    <FeatherIcon
                      className="text-primary mr-1"
                      size={12}
                      icon="map-pin"
                    />
                    <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                  </div>
                  <p className="fsize12 lineClamp2 clr-7c7c7c mt-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illum in nobis accusantium
                  </p>
                  <p className="text-primary fsize14 mt-1">
                    Rs. 1500{' '}
                    <span className="clr-7c7c7c  fsize12">onwards</span>
                  </p>
                  <div className="mt-1">
                    <Button className="w-100per fsize14 b_button_primary px-6 py-2">
                      Participate
                    </Button>
                  </div>
                </div>
              </div>
              <div className="borderall w-full rounded-lg">
                <div className="p-4 ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="turf-name"
                    className="activites_image rounded-lg object-cover"
                  />
                  <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                  <div className="flex items-center">
                    <FeatherIcon
                      className="text-primary mr-1"
                      size={12}
                      icon="map-pin"
                    />
                    <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                  </div>
                  <p className="fsize12 lineClamp2 clr-7c7c7c mt-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illum in nobis accusantium
                  </p>
                  <p className="text-primary fsize14 mt-1">
                    Rs. 1500{' '}
                    <span className="clr-7c7c7c  fsize12">onwards</span>
                  </p>
                  <div className="mt-1">
                    <Button className="w-100per fsize14 b_button_primary px-6 py-2">
                      Participate
                    </Button>
                  </div>
                </div>
              </div>
              <div className="borderall w-full rounded-lg">
                <div className="p-4 ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="turf-name"
                    className="activites_image rounded-lg object-cover"
                  />
                  <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                  <div className="flex items-center">
                    <FeatherIcon
                      className="text-primary mr-1"
                      size={12}
                      icon="map-pin"
                    />
                    <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                  </div>
                  <p className="fsize12 lineClamp2 clr-7c7c7c mt-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illum in nobis accusantium
                  </p>
                  <p className="text-primary fsize14 mt-1">
                    Rs. 1500{' '}
                    <span className="clr-7c7c7c  fsize12">onwards</span>
                  </p>
                  <div className="mt-1">
                    <Button className="w-100per fsize14 b_button_primary px-6 py-2">
                      Participate
                    </Button>
                  </div>
                </div>
              </div>
              <div className="borderall w-full rounded-lg">
                <div className="p-4 ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="turf-name"
                    className="activites_image rounded-lg object-cover"
                  />
                  <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                  <div className="flex items-center">
                    <FeatherIcon
                      className="text-primary mr-1"
                      size={12}
                      icon="map-pin"
                    />
                    <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                  </div>
                  <p className="fsize12 lineClamp2 clr-7c7c7c mt-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illum in nobis accusantium
                  </p>
                  <p className="text-primary fsize14 mt-1">
                    Rs. 1500{' '}
                    <span className="clr-7c7c7c  fsize12">onwards</span>
                  </p>
                  <div className="mt-1">
                    <Button className="w-100per fsize14 b_button_primary px-6 py-2">
                      Participate
                    </Button>
                  </div>
                </div>
              </div>
              <div className="borderall w-full rounded-lg">
                <div className="p-4 ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="turf-name"
                    className="activites_image rounded-lg object-cover"
                  />
                  <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                  <div className="flex items-center">
                    <FeatherIcon
                      className="text-primary mr-1"
                      size={12}
                      icon="map-pin"
                    />
                    <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                  </div>
                  <p className="fsize12 lineClamp2 clr-7c7c7c mt-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illum in nobis accusantium
                  </p>
                  <p className="text-primary fsize14 mt-1">
                    Rs. 1500{' '}
                    <span className="clr-7c7c7c  fsize12">onwards</span>
                  </p>
                  <div className="mt-1">
                    <Button className="w-100per fsize14 b_button_primary px-6 py-2">
                      Participate
                    </Button>
                  </div>
                </div>
              </div>
              <div className="borderall w-full rounded-lg">
                <div className="p-4 ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="turf-name"
                    className="activites_image rounded-lg object-cover"
                  />
                  <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                  <div className="flex items-center">
                    <FeatherIcon
                      className="text-primary mr-1"
                      size={12}
                      icon="map-pin"
                    />
                    <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                  </div>
                  <p className="fsize12 lineClamp2 clr-7c7c7c mt-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illum in nobis accusantium
                  </p>
                  <p className="text-primary fsize14 mt-1">
                    Rs. 1500{' '}
                    <span className="clr-7c7c7c  fsize12">onwards</span>
                  </p>
                  <div className="mt-1">
                    <Button className="w-100per fsize14 b_button_primary px-6 py-2">
                      Participate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchByLocation;
