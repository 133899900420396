import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:flex md:flex sm-reverse h-full items-center">
        <div
          ref={elementRef}
          className={`fade-left lg:w-3/5 md:w-2/4 lg:px-20 md:px-8 px-5 lg:py-0 md:py-8 py-6 ${
            isVisible ? 'visibleleft' : ''
          } `}
        >
          <div className="flex items-center gap-4">
            <p
              id="8588175253"
              className="text-primary fsize16 sm-fsize14 dynamicStyle"
            >
              {data ? data['8588175253'] : 'Lorem Ipsum'}
            </p>
            <span className="block h-2 w-2 bg-primary rounded-full"> </span>
            <p
              id="1659103740"
              className="text-primary fsize16 sm-fsize14 dynamicStyle"
            >
              {data ? data['1659103740'] : 'Lorem Ipsum'}
            </p>
            <span className="block h-2 w-2 bg-primary rounded-full"> </span>
            <p
              id="2552274841"
              className="text-primary fsize16 sm-fsize14 dynamicStyle"
            >
              {data ? data['2552274841'] : 'Lorem Ipsum'}
            </p>
          </div>

          <div className="lg:mt-6 md:mt-4 mt-2">
            <h2
              id="5298456299"
              className="theme-textColor hero_title dynamicStyle"
            >
              {data ? data['5298456299'] : 'Lorem Ipsum'}
            </h2>

            <ReactQuill
              id="3430366058"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['3430366058'] : 'Lorem Ipsum'}
            />
          </div>
        </div>
        <div className="lg:w-2/5 md:w-2/4 lg:flex md:flex justify-end w-full">
          <div id="7473290310" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['7473290310']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              imageClassName="object-cover w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection2;
