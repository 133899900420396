import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Image } from 'primereact/image';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import ImageGallery from 'react-image-gallery';
import { SelectButton } from 'primereact/selectbutton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';

const EcomProductDetails5 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const [value, setValue] = useState(null);
  const items = [
    { name: 'S', value: 1 },
    { name: 'M', value: 2 },
    { name: 'XL', value: 3 },
    { name: '2XL', value: 4 },
  ];
  const images = [
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex gap-6">
          <div className="lg:w-1/2 md:w-full w-full">
            <ImageGallery items={images} />
          </div>
          <div className="lg:w-1/2 md:w-full w-full">
            <div>
              <p className="section_detail_0">Lorem</p>
              <div className="flex justify-between items-center">
                <h4 className="section_header">Lorem Ipsum</h4>
                <FeatherIcon
                  icon="star"
                  className="cursor-pointer p-1 bdr-gray-1px rounded-full"
                  size={26}
                />
              </div>
              <div className="flex gap-2 pb-4 mt-2 textColor-light">
                <FeatherIcon icon="star" className="cursor-pointer" size={18} />
                <FeatherIcon icon="star" className="cursor-pointer" size={18} />
                <FeatherIcon icon="star" className="cursor-pointer" size={18} />
                <FeatherIcon icon="star" className="cursor-pointer" size={18} />
                <FeatherIcon icon="star" className="cursor-pointer" size={18} />
              </div>
              <div className="flex items-center gap-2">
                <p className="card_title_0 text-dark">$39.00</p>
                <p className="card_description strikethrough">$59.00</p>
                <Chip
                  label="save 50%"
                  className="fsize12 bg_danger text-white"
                />
              </div>
              <div className="flex items-center gap-2 pt-4">
                <FeatherIcon icon="eye" className="flex" size={20} />
                <p className="card_description">
                  24 people are viewing this right now
                </p>
              </div>
              <div className="flex justify-between rounded-sm bg-red-100 gap-2 my-6 py-2 px-4 theme-bg themeShadow">
                <p className="text-danger font-semibold card_description">
                  Hurry up sale ends in:
                </p>
                <p className="text-danger font-semibold card_description">
                  00 : 05 : : 45 : 54
                </p>
              </div>
              <div className="mb-6">
                <p className="mb-2 card_description">Only 9 left in stock</p>
                <ProgressBar value={50} color="red"></ProgressBar>
              </div>
              <div className="my-4 grid lg:grid-cols-2 md:grid-cols-2 gap-4">
                <div className="">
                  <p className="mb-2 text-dark card_title">Size: M</p>
                  <SelectButton
                    value={value}
                    onChange={e => setValue(e.value)}
                    optionLabel="name"
                    options={items}
                    className="fsize12"
                  />
                </div>
                <div className="">
                  <p className="font-semibold pb-2 cust-textColor">Colors</p>
                  <div className="flex gap-2">
                    <div className="bg-black h-7 w-7 rounded-full"></div>
                    <div className="bg-red-800 h-7 w-7 rounded-full"></div>
                  </div>
                </div>
                <div className="">
                  <p className="font-semibold pb-2 cust-textColor">Quantity</p>
                  <div className="counter">
                    <div className="flex items-center">
                      <span className="cursor-pointer p-2 cust-textColor">
                        -
                      </span>
                      <input
                        type="text"
                        className="counter-input cust-textColor"
                        value="1"
                      />
                      <span className="cursor-pointer p-2 cust-textColor">
                        +
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full mt-2">
                <Button
                  className="dynamicStyle bg-primary w-full flex justify-center clr-fff"
                  id="3692437210"
                >
                  <span onClick={() => handleClick('3692437210')}>
                    {data ? data['3692437210'] : 'Add to cart'}
                  </span>
                </Button>
              </div>
              <div className="flex flex-wrap lg:gap-6 md:gap-4 gap-2 lg:mt-4 md:mt-3 mt-2 cust-textColor">
                <div className="flex items-center gap-2">
                  <div className="flex items-center">
                    <FeatherIcon icon="arrow-up" className="flex" size={16} />
                    <FeatherIcon icon="arrow-down" className="flex" size={16} />
                  </div>
                  <p className="card_description text-dark">Compare</p>
                </div>
                <div className="flex gap-2 items-center">
                  <FeatherIcon icon="help-circle" className="flex" size={16} />

                  <p className="card_description text-dark">Ask a Question</p>
                </div>
                <div className="flex gap-2 items-center">
                  <FeatherIcon icon="share-2" className="flex" size={16} />

                  <p className="card_description text-dark">share</p>
                </div>
              </div>
              <hr className="bdr-bottom my-3" />
              <div className="flex lg:items-center md:items-center gap-2 lg:mt-4 md:mt-3 mt-2">
                <FeatherIcon
                  icon="truck"
                  className="flex lg:mt-0 md:mt-0 mt-1"
                  size={16}
                />

                <p className="card_description text-gray">
                  <span className="text-dark font-semibold">
                    Estimated delivery
                  </span>{' '}
                  jul 30 - Aug 03{' '}
                </p>
              </div>
              <div className="flex lg:items-center md:items-center gap-2 mt-2">
                <FeatherIcon
                  icon="calendar"
                  className="flex lg:mt-0 md:mt-0 mt-1"
                  size={16}
                />

                <p className="card_description text-gray">
                  <span className="text-dark font-semibold">
                    Free Shipping & Returns:{' '}
                  </span>{' '}
                  on all orders over $75{' '}
                </p>
              </div>
              <div className="py-4 px-6 lg:mt-4 md:mt-3 mt-2 bg-gray-100 rounded-lg theme-bg themeShadow">
                <div className="flex gap-2 justify-center">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="h-10 w-14 mb-4"
                  />
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="h-10 w-14 mb-4"
                  />
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="h-10 w-14 mb-4"
                  />
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="h-10 w-14 mb-4"
                  />
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="h-10 w-14 mb-4"
                  />
                </div>
                <p className="card_description text-center">
                  Guarantee safe and secure checkout
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomProductDetails5;
