import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";

const PollSection2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="0952397629"
          className="dynamicStyle inline-block section_header"
        >
          {data ? data["0952397629"] : "Poll Question 2"}
        </h3>
        <ReactQuill
          id="3378803519"
          theme="bubble"
          readOnly
          className="section_detail bubble dynamicStyle lg:w-2/3"
          value={data ? data["3378803519"] : "Lorem Ipsum"}
        />
        <div className="lg:py-12 md:py-12 py-6 lg:w-1/3 md:w-1/2">
          <div className="border1 py-2 px-6 rounded-full mb-4">
            <p className="fsize14 md-fsize14 fsize14 text_surface600 font-medium">
              2022-23
            </p>
          </div>
          <div className="border1 py-2 px-6 rounded-full mb-4">
            <p className="fsize14 md-fsize14 fsize14 text_surface600 font-medium">
              2022-23
            </p>
          </div>
          <div className="border1 py-2 px-6 rounded-full mb-0">
            <p className="fsize14 md-fsize14 fsize14 text_surface600 font-medium">
              2022-23
            </p>
          </div>
        </div>
        <div className="mt-0 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup>
              <Avatar
                name="Ryan Florence"
                shape="circle"
                image="https://bit.ly/ryan-florence"
              />
              <Avatar
                name="Segun Adebayo"
                shape="circle"
                image="https://bit.ly/sage-adebayo"
              />
              <Avatar
                name="Kent Dodds"
                shape="circle"
                image="https://bit.ly/kent-c-dodds"
              />
              <Avatar
                name="Prosper Otemuyiwa"
                shape="circle"
                image="https://bit.ly/prosper-baba"
              />
              <Avatar
                name="Christian Nwamba"
                shape="circle"
                image="https://bit.ly/code-beast"
              />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text_surface600 textColor-light dynamicStyle"
                id="7105565325"
              >
                {data ? data["7105565325"] : "Total votes:"}
              </p>
              <p className="fsize12 text_surface600 textColor-light">24</p>
            </div>
          </div>
          {/* <Button
            className="bg-primary cust-bgColor theme-btn clr-fff"
            fontWeight={400}
            borderRadius={2}
          >
            Vote
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default PollSection2;
