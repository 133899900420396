import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
// import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";

const HeroSection31 = ({ pageName }) => {
  const elementRef = useRef(null);
  // eslint-disable-next-line
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  // const CTALink = useSelector((state) => state.cms.links[pageName]);
  // const navigate = useNavigate();
  // const link = "https://example.com";

  // const handleClick = (id) => {
  //   const isHttpsLink =
  //     CTALink[id].includes("https") || CTALink[id].includes("www");
  //   if (isHttpsLink) {
  //     window.open(`${CTALink[id]}`, "_blank");

  //     console.log(link, "cmsdatacheck");
  //   } else {
  //     navigate(`/${CTALink[id]}`);
  //   }
  // };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex sm-reverse">
          <div className="lg:w-1/2 md:w-1/2 w-full flex items-center lg:px-8 md:px-8 lg:mt-0 md:mt-0 mt-4">
            <div>
              <h2 id="1315994008" className="dynamicStyle hero_title">
                {data ? data["1315994008"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="1664075201"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["1664075201"] : "Lorem Ipsum"}
              />
              <Button
                className="dynamicStyle theme-btn lg:mt-8 md:mt-8 mt-6 sm-fsize14 b_button_primary px-6 py-2"
                id="4372587003"
              >
                {data ? data["4372587003"] : "Lorem"}
              </Button>
            </div>
          </div>
          <div
            id="9718287763"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
          >
            <Image
              src={data ? getImage(data["9718287763"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img_hero"
              imageClassName="lg:h-96 lg:w-96 md:h-80 md:w-80 h-56 w-56 rounded-full mx-auto object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection31;
