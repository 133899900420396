import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection13 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");
      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="theme-bg" style={{ background: thisComponent.sectionColor }}>
        <div className="lg:flex md:flex sm-reverse lg:px-0 md:px-8 px-5 items-center lg:py-0 md:py-10 py-6">
          <div
            ref={elementRef}
            className={`fade-left lg:w-3/5 md:w-2/4 lg:px-20 md:pr-4 md:pl-0 lg:mt-0 md:mt-0 mt-6 ${
              isVisible ? "visibleleft" : ""
            } `}
          >
            <div ref={elementRef} className="flex items-center gap-4 mb-2">
              <p
                className="fsize12 uppercase dynamicStyle theme-textColor"
                id="4708772321"
              >
                {data ? data["4708772321"] : "Lorem"}
              </p>
              <div className="red-circle"></div>
              <p
                className="fsize12 uppercase dynamicStyle theme-textColor"
                id="9794799088"
              >
                {data ? data["9794799088"] : "Lorem"}
              </p>
              <div className="red-circle"></div>
              <p
                className="fsize12 uppercase dynamicStyle theme-textColor"
                id="2420461269"
              >
                {data ? data["2420461269"] : "Lorem"}
              </p>
            </div>
            <h2
              id="6085812859"
              className="fsize30 md-fsize24 sm-fsize20 uppercase text-end text-primary font-semibold dynamicStyle"
            >
              {data ? data["6085812859"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="2298368083"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle lg:mt-4 md:mt-4 mt-2"
              value={data ? data["2298368083"] : "Lorem Ipsum"}
            />

            <div className="flex items-center flex-wrap lg:gap-4 md:gap-4 gap-2 mt-8">
              <Button
                outlined
                className=" py-2 px-4 rounded-full theme-btn2 fsize16 sm-fsize12 dynamicStyle"
                id="5917121726"
              >
                <span onClick={() => handleClick("5917121726")}>
                  {data ? data["5917121726"] : "Enquire Now"}
                </span>
              </Button>
              <Button
                outlined
                className=" py-2 px-4 rounded-full theme-btn2 fsize16 sm-fsize12 dynamicStyle"
                id="1213118231"
              >
                <span onClick={() => handleClick("1213118231")}>
                  {data ? data["1213118231"] : "Enquire Now"}
                </span>
              </Button>
              <Button
                outlined
                className=" py-2 px-4 rounded-full theme-btn2 fsize16 sm-fsize12 dynamicStyle"
                id="4332201590"
              >
                <span onClick={() => handleClick("4332201590")}>
                  {data ? data["4332201590"] : "Enquire Now"}
                </span>
              </Button>
              <Button
                outlined
                className=" py-2 px-4 rounded-full theme-btn2 fsize16 sm-fsize12 dynamicStyle"
                id="4408655741"
              >
                <span onClick={() => handleClick("4408655741")}>
                  {data ? data["4408655741"] : "Enquire Now"}
                </span>
              </Button>
            </div>
          </div>

          <div
            className="lg:w-2/5 md:w-2/4 lg:flex md:flex justify-end w-full dynamicStyle2"
            id="7795915857"
          >
            <Image
              src={data ? getImage(data["7795915857"]) : ""}
              alt="heroImage"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              imageClassName="object-cover w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection13;
