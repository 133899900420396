import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";


const FeatureCard61 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex gap-4 items-center">
          <p
            id="8976974546"
            className="dynamicStyle lg:w-1/2 md:w-1/2 section_header"
          >
            {data ? data['8976974546'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="0330487753"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle lg:w-1/2 md:w-1/2 section_detail_0"
            value={data ? data['0330487753'] : 'Lorem Ipsum'}
          />
        </div>
        <div
          id="2356361084"
          className="dynamicStyle grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12"
        >
          {data && data['2356361084']
            ? data['2356361084'].map(e => {
                return (
                  <div
                    key={e._id}
                    className="relative h-60 w-full "
                  >
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL + e.field1}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt={e.field3}
                      imageClassName="h-60 w-full rounded-lg object-cover"
                    />
                    <div className="p-4 bg_surface50 absolute left-0 bottom-0 rounded-b-md w-full themeShadow theme-bg bdr">
                      <p className="card_description">{e.field2}</p>
                      <h3 className="card_title_0 pb-1 lineClamp1">{e.field3}</h3>
                      <p className="fsize14 sm-fsize12 text_surface400">
                        2 hours 40 minutes
                      </p>
                    </div>
                  </div>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard61;
