import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard16 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 id="4388230550" className="dynamicStyle section_header">
              {data ? data['4388230550'] : 'Welcome to My World'}
            </h2>
            <ReactQuill
              id="1127523224"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['1127523224'] : 'Welcome to My World'}
            />
          </div>
          <div
            ref={elementRef}
            className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
          >
            <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:mt-16 md:mt-16 mt-12">
              <div
                className='box-shadow-none py-10 px-4  w-full text-center relative'
                   
              >
                <p id="3666242522" className="dynamicStyle card_title">
                  {data ? data['3666242522'] : 'Welcome to My World'}
                </p>
                <ReactQuill
                  id="1484777357"
                  theme="bubble"
                  readOnly
                  className="bubble2 dynamicStyle card_description"
                  value={data ? data['1484777357'] : 'Welcome to My World'}
                />
                <div className="absolute-line3"></div>
              </div>
              <div
                className='box-shadow-none py-10 px-4  w-full text-center border-lft relative'
                    
              >
                <p id="9345680175" className="dynamicStyle card_title">
                  {data ? data['9345680175'] : 'Welcome to My World'}
                </p>
                <ReactQuill
                  id="6526663743"
                  theme="bubble"
                  readOnly
                  className="bubble2 dynamicStyle card_description"
                  value={data ? data['6526663743'] : 'Welcome to My World'}
                />
                <div className="absolute-line4"></div>
              </div>
              <div
                className='box-shadow-none py-10 px-4  w-full text-center border-lft relative'
                   
              >
                <p id="9749662362" className="dynamicStyle card_title">
                  {data ? data['9749662362'] : 'Welcome to My World'}
                </p>
                <ReactQuill
                  id="6750099659"
                  theme="bubble"
                  readOnly
                  className="bubble2 dynamicStyle card_description"
                  value={data ? data['6750099659'] : 'Welcome to My World'}
                />
              </div>
            </div>
            <div className="relative">
              <hr className="my-10 mx-auto wid-70 sm-w-70 bdr-ffffffa1" />
              <div className="absolute-line7"></div>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
              <div
                className='box-shadow-none py-10 px-4  w-full text-center relative'
                   
              >
                <p id="9489821608" className="dynamicStyle card_title">
                  {data ? data['9489821608'] : 'Welcome to My World'}
                </p>
                <ReactQuill
                  id="3145891950"
                  theme="bubble"
                  readOnly
                  className="bubble2 dynamicStyle card_description"
                  value={data ? data['3145891950'] : 'Welcome to My World'}
                />
                <div className="absolute-line6"></div>
              </div>
              <div
                className='box-shadow-none py-10 px-4  w-full text-center border-lft relative'
                    
              >
                <p id="1006843756" className="dynamicStyle card_title">
                  {data ? data['1006843756'] : 'Welcome to My World'}
                </p>
                <ReactQuill
                  id="2807698129"
                  theme="bubble"
                  readOnly
                  className="bubble2 dynamicStyle card_description"
                  value={data ? data['2807698129'] : 'Welcome to My World'}
                />
                <div className="absolute-line4"></div>
              </div>
              <div
                className='box-shadow-none py-10 px-4  w-full text-center border-lft relative'
                    
              >
                <p id="0691188053" className="dynamicStyle card_title">
                  {data ? data['0691188053'] : 'Welcome to My World'}
                </p>
                <ReactQuill
                  id="6181219647"
                  theme="bubble"
                  readOnly
                  className="bubble2 dynamicStyle card_description"
                  value={data ? data['6181219647'] : 'Welcome to My World'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard16;
