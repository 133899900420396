import { Button } from "primereact/button";
import React from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const Jobs3 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  // function getImage(image) {
  //   if (image && image.includes("blob:")) {
  //     return image;
  //   } else {
  //     return process.env.REACT_APP_STORAGE_URL + image;
  //   }
  // }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h2
          id="5666688213"
          className="dynamicStyle inline-block section_header theme-textColor"
        >
          {data ? data["5666688213"] : "Feature Jobs"}
        </h2>
        <div className="lg:mt-12 md:mt-10 mt-8 w-full grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 lg:gap-6 md:gap-4 gap-4">
          <Card className="themeShadow theme-bg prime_card_0">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-200px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 lg:w-1/2 md:w-2/3">
                <h2 className="card_title_0">Relation Manager</h2>
                <div className="flex items-center gap-2 pt-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface600"
                    size={16}
                  />
                  <p className="card_description">Mumbai, India</p>
                </div>
              </div>
            </div>
            <div className="p-2">
              <h2 className="card_title_0 text-center">Title</h2>
            </div>
          </Card>
          <Card className="themeShadow theme-bg rounded-lg prime_card_0">
            <div className="relative ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-200px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 lg:w-1/2 md:w-2/3">
                <h2 className="card_title_0">Relation Manager</h2>
                <div className="flex items-center gap-2 pt-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface600"
                    size={16}
                  />
                  <p className="card_description">Mumbai, India</p>
                </div>
              </div>
            </div>
            <div className="p-2">
              <h2 className="card_title_0 text-center">Title</h2>
            </div>
          </Card>
          <Card className="themeShadow theme-bg rounded-lg prime_card_0">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-200px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 lg:w-1/2 md:w-2/3">
                <h2 className="card_title_0">Relation Manager</h2>
                <div className="flex items-center gap-2 pt-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface600"
                    size={16}
                  />
                  <p className="card_description">Mumbai, India</p>
                </div>
              </div>
            </div>
            <div className="p-2">
              <h2 className="card_title_0 text-center">Title</h2>
            </div>
          </Card>
          <Card className="themeShadow theme-bg rounded-lg prime_card_0">
            <div className="relative ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="job"
                imageClassName="w-full h-200px rounded-lg object-cover"
              />
              <div className="absolute bottom-3 left-3 lg:w-1/2 md:w-2/3">
                <h2 className="card_title_0">Relation Manager</h2>
                <div className="flex items-center gap-2 pt-2">
                  <FeatherIcon
                    icon="map-pin"
                    className="text_surface600"
                    size={16}
                  />
                  <p className="card_description">Mumbai, India</p>
                </div>
              </div>
            </div>
            <div className="p-2">
              <h2 className="card_title_0 text-center">Title</h2>
            </div>
          </Card>
        </div>
        <div className="text-center lg:mt-12 md:mt-10 mt-6">
          <Button
            id="4710765888"
            className="b_button_primary px-6 py-2 dynamicStyle"
          >
            <span onClick={() => handleClick("4710765888")}>
              {data ? data["4710765888"] : "View More"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Jobs3;
