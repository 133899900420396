import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const CardImage3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <section className="prime_container">
        <div className="text-center lg:w-1/2 md:w-3/4 w-full mx-auto">
          <h3
            id="2632409156"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-semibold text-primary"
          >
            {data ? data["2632409156"] : "Lorem Ipsum"}
          </h3>
          <h2 id="4015122783" className="dynamicStyle section_header">
            {data ? data["4015122783"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="6941527127"
            theme="bubble"
            readOnly
            className="section_detail bubble2 dynamicStyle"
            value={data ? data["6941527127"] : "Lorem Ipsum"}
          />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            ref={elementRef}
            className={`fade-bottom mySwiper ${
              isVisible ? "visiblebottom" : ""
            } `}
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div id="3314586501" className="dynamicStyle2 w-full p-2">
                <Image
                  src={data ? getImage(data["3314586501"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="cardimage"
                  imageClassName="object-cover card-img2"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="1046183491" className="dynamicStyle2 w-full p-2">
                <Image
                  src={data ? getImage(data["1046183491"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="cardimage"
                  imageClassName="object-cover card-img2"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="6156641127" className="dynamicStyle2 w-full p-2">
                <Image
                  src={data ? getImage(data["6156641127"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="cardimage"
                  imageClassName="object-cover card-img2"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="7759433649" className="dynamicStyle2 w-full p-2">
                <Image
                  src={data ? getImage(data["7759433649"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="cardimage"
                  imageClassName="object-cover card-img2"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default CardImage3;
