import React, { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import Rating from "react-rating";
import FeatherIcon from "feather-icons-react";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";

const Testimonial6 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(
    (state) => state.testimonial.Testimonial
  );

  useEffect(() => {
    dispatch(getTestimonal());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <>
      {getAllTestimonial ? (
        <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
          <div className="prime_container">
            <div className="text-center">
              <p
                id="8854398588"
                className="dynamicStyle text-primary fsize16 sm-fsize14 font-medium  cust-textColor"
              >
                {data ? data["8854398588"] : "Testimonial"}
              </p>
              <h2 id="8799852098" className="dynamicStyle section_header">
                {data ? data["8799852098"] : "Reviews from Customers"}
              </h2>
            </div>
            <div
              ref={elementRef}
              className={`fade-bottom  ${
                isVisible
                  ? "visiblebottom lg:mt-16 md:mt-16 mt-12"
                  : "lg:mt-16 md:mt-16 mt-12"
              } `}
            >
              <Swiper
                spaceBetween={15}
                slidesPerView={1.3}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {getAllTestimonial?.data?.result.map((item, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className="relative prime_card overflow-hidden card lg:mx-2 md:mx-2"
                        key={index}
                      >
                        <div className="lg:pb-4 md:pb-4 pb-2 lg:h-36 md:h-36 h-36">
                          <p className="lineClamp5 card_description">
                            “{item.testimonial}”
                          </p>
                        </div>
                        <div className="flex gap-4 ">
                          <div>
                            <Image
                              imageClassName="cust-hw rounded-full object-cover bg-d9d9d9"
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                              alt={item.name}
                            />
                          </div>
                          <div className="">
                            <h4 className="card_title_0 ">
                              {item.name.charAt(0).toUpperCase() +
                                item.name.slice(1)}{" "}
                            </h4>
                            <Rating
                              initialRating={4}
                              className="flex_rating"
                              readonly={true}
                              emptySymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1"
                                  size={18}
                                />
                              }
                              fullSymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1 fillstroke"
                                  size={18}
                                />
                              }
                              fractions={2}
                            />
                            {/* <p className="fsize14 sm-fsize12">CEO & Founder</p> */}
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        "No Data"
      )}
    </>
  );
};

export default Testimonial6;
