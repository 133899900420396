import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h3
            id="6159809925"
            className="dynamicStyle text-primary fsize16 md-fsize16 sm-fsize14 font-medium"
          >
            {data ? data["6159809925"] : "Lorem Ipsum"}
          </h3>
          <h2 id="0370346088" className="dynamicStyle section_header">
            {data ? data["0370346088"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="3287910140"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["3287910140"] : "Lorem Ipsum"}
          />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 lg:flex lg:flex-col lg:px-12 md:px-8 px-5">
          <div
            id="6654293493"
            className="dynamicStyle lg:flex lg:flex-col lg:px-12 md:px-0 px-5"
          >
            {data && data["6654293493"]
              ? data["6654293493"].map((e, index) => {
                  return (
                    <div
                      key={e._id}
                      className={`${
                        index % 2 === 0
                          ? "lg:flex md:flex  items-center lg:gap-6 hover-6f6f6f mb-6 w-full lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px sm-h-250px rounded-lg box-shadow-inset bg-d2d2d2  cust-bgColor br-ddd9d8 themeShadow-alternate"
                          : "lg:flex md:flex items-center lg:gap-6 hover-ddd9d8 mb-6 box-shadow-outer br-ddd9d8 w-full sm-h-250px lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px rounded-lg theme_insertShadow"
                      }`}
                    >
                      <div className=" flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2 md:gap-4 ">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img-Card"
                          imageClassName="object-cover lg:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
                        />
                        <p className="card_title">{e.field2}</p>
                      </div>
                      <div className="w-9/12 sm-wfull">
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="card_description bubble sm-lineclamp-4"
                          value={e.field3}
                        />
                      </div>
                    </div>
                  );
                })
              : "no-data"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard2;
