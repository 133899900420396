import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection27 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center sm-custdirection lg:gap-6 md:gap-5 gap-3">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <h2 id="1144884637" className="dynamicStyle about_title">
              {data ? data['1144884637'] : 'Tips in travelling in Winter'}
            </h2>
            <ReactQuill
              id="6116938696"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description lg:mr-12"
              value={data ? data['6116938696'] : 'Lorem Ipsum'}
            />

            <Button
              className="dynamicStyle b_button_primary px-6 py-2 lg:mt-8 md:mt-8 mt-6 theme-btn fsize16 sm-fsize14"
              id="8815748058"
            >
              <span onClick={() => handleClick('8815748058')}>
                {data ? data['8815748058'] : 'Know More'}
              </span>
            </Button>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full flex lg:gap-6 md:gap-5 gap-3 items-center lg:pb-0 md:pb-0 pb-6">
            <div id="2510623361" className="w-1/2 dynamicStyle2">
              <Image
                src={data ? getImage(data['2510623361']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="about"
                imageClassName="rounded-md h-450px md-h-350px sm-h250px object-cover"
              />
            </div>
            <div className="w-1/2">
              <div id="8592943833" className="w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data['8592943833']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="about"
                  imageClassName="rounded-md h-350px md-h-250px sm-h-200px object-cover"
                />
              </div>
              <div id="9403361015" className=" w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data['9403361015']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="about"
                  imageClassName="rounded-md h-350px md-h-250px sm-h-200px mt-4 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection27;
