import { Image } from "primereact/image";
import React from "react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const Login2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div className="lg:px-20 md:px-8 px-5">
      <div className="prime_container h-80vh flex justify-center items-center">
        <div
          id="2344189220"
          className="dynamicStyle2 lg:w-5/12 md:w-2/3 w-full mx-auto lg:px-0 md:px-0 px-5"
        >
          <Image
            src={data ? getImage(data["2344189220"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            imageClassName="w-24 h-24 mx-auto rounded-full object-contain"
          />
          <div className="lg:mt-8 md:mt-6 mt-4">
            <h2
              id="3890795803"
              className="dynamicStyle text-center section_header"
            >
              {data ? data["3890795803"] : "Lorem Ipsum"}
            </h2>
            <p
              id="8677672691"
              className="dynamicStyle text-center fsize14 mt-1"
            >
              {data ? data["8677672691"] : "Lorem Ipsum"}
            </p>
            <div className="mt-8">
              <InputText
                placeholder="Enter New Password"
                className="mb-4 w-full"
              />
              <InputText
                placeholder="Confirm New Password"
                className="mb-8 w-full"
              />
            </div>

            <Button
              className="dynamicStyle w-full b_button_primary px-6 py-2 flex justify-center"
              id="7579033606"
            >
              <span onClick={() => handleClick("7579033606")}>
                {data ? data["7579033606"] : "Lorem Ipsum"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login2;
