import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button } from 'primereact/button';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import UploaderBox from '../../../../../../../components/UploaderBox';

const BreadCumb = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div className="bg-primary lg:px-28 lg:flex md:flex justify-between items-center md:px-8 px-5 lg:py-8 md:py-6 py-8">
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Add Folder</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div className="py-3 px-1">
              <div className="">
                <label className="text-primary">File Name</label>
                <Input placeholder="Enter" className="mt-2" />
              </div>
              <div className="mt-3">
                <label className="text-primary">
                  Select Approver (Select Maximum 2)
                </label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <label className="text-primary">
                  Select Reviewer (Optional)
                </label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <label className="text-primary">Select Co-author</label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-5">
                <UploaderBox accept="image/*" />
              </div>
              <div className="flex items-center gap-3 mt-5">
                <Button className="w-full b_button_primary px-6 py-2">
                  Submit
                </Button>
                <Button className="w-full b_button_secondary px-6 py-2">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="#" className="textwhite sm-fsize13">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" className="textwhite sm-fsize13">
              About
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#" className="textwhite sm-fsize13">
              Contact
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="lg:mt-0 md:mt-0 mt-3">
        <Button className="b_button_primary px-6 py-2 sm-fsize13" onClick={onOpen}>
          <FeatherIcon icon="download" size="16" className="mr-1" />
          Upload
        </Button>
      </div>
    </div>
  );
};

export default BreadCumb;
