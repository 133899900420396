import { Select } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection23 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      Navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const TripOptions = [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Goa", label: "Goa" },
  ];

  const PickupOptions = [
    { value: "Feb18, 2023", label: "Feb18, 2023" },
    { value: "Feb22, 2023", label: "Feb22, 2023" },
    { value: "March24, 2023", label: "March24, 2023" },
  ];

  const PickupTimeOptions = [
    { value: "3:00 PM", label: "3:00 PM" },
    { value: "2:10 AM", label: "2:10 AM" },
    { value: "5:00 PM", label: "5:00 PM" },
  ];

  const [trip, setTrip] = useState(TripOptions[0].value);
  const [pickup, setPickup] = useState(PickupOptions[0].value);
  const [pickupTime, setPickupTime] = useState(PickupTimeOptions[0].value);

  const handleTrip = (e) => {
    setTrip(e.target.value);
  };

  const handlePickup = (e) => {
    setPickup(e.target.value);
  };

  const handlePickupTime = (e) => {
    setPickupTime(e.target.value);
  };

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="relative w-full h-700px md-h-600px sm-h-500px" style={{ background: thisComponent.sectionColor }}>
      <div id="6588151096" className="dynamicStyle2">
        <Image
          src={data ? getImage(data["6588151096"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img-hero"
          imageClassName="w-full h-700px md-h-600px sm-h-500px object-cover"
        />
      </div>
      <div className="h-700px md-h-600px sm-h-500px absolute top-0 left-0 w-full flex justify-center items-center">
        <div className="prime_container lg:px-0 px-5">
          <div className="lg:w-1/2 md:w-2/3  mx-auto text-center">
            <h2
              id="1779672352"
              className="dynamicStyle fsize86 md-cust-fsize40 sm-fsize28 sm-lh-46 text-primary font-semibold"
            >
              {data ? data["1779672352"] : "Travel around the World"}
            </h2>
            <ReactQuill
              id="0346996659"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle lg:mt-0 md:mt-0 mt-2 card_description"
              value={data ? data["0346996659"] : "Lorem Ipsum"}
            />
          </div>
          <Card className="prime_card lg:w-2/3 mx-auto lg:mt-8 md:mt-8 mt-6 themeShadow">
            <div className="lg:flex gap-6 justify-between items-center ">
              <div className="w-full lg:gap-6 md:gap-6 gap-4 grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2">
                <div className="">
                  <Dropdown
                    placeholder="Trip Type"
                    value={trip}
                    onChange={handleTrip}
                    options={TripOptions}
                  ></Dropdown>
                  <p className="card_description font-medium mt-2">{trip}</p>
                </div>
                <div className="">
                  <p className="h-2rem flex items-center fsize14">
                    Pick-up Location
                  </p>

                  <p className="card_description font-medium mt-2">Pune</p>
                </div>
                <div className="">
                  <Dropdown
                    placeholder="Pick Up Date"
                    value={pickup}
                    onChange={handlePickup}
                    options={PickupOptions}
                  ></Dropdown>
                  <p className="card_description font-medium mt-2">{pickup}</p>
                </div>
                <div className="">
                  <Dropdown
                    placeholder="Pick Up Date"
                    value={pickupTime}
                    onChange={handlePickupTime}
                    className="fsize12"
                    options={PickupTimeOptions}
                  ></Dropdown>
                  <p className="card_description font-medium mt-2">{pickupTime}</p>
                </div>
              </div>
              <div className="flex lg:justify-end justify-center lg:mt-0 mt-6">
                <Button
                  id="4940700635"
                  className="dynamicStyle theme-btn sm-fsize14 b_button_primary px-4 py-2"
                >
                  <span onClick={() => handleClick("4940700635")}>
                    {data ? data["4940700635"] : "Search"}
                  </span>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default HeroSection23;
