import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';
import 'swiper/css/effect-cards';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection59 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex block items-center gap-6">
          <div className="lg:w-7/12 md:w-1/2 w-full lg:pr-10">
            <div className="">
              <h5 id="8717131563" className="dynamicStyle about_title">
                {data ? data['8717131563'] : 'no-data'}
              </h5>
              <ReactQuill
                id="3968661542"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle mb-6"
                value={data ? data['3968661542'] : 'Lorem Ipsum'}
              />

              <Button
                className="theme-btn b_button_primary px-6 py-2 dynamicStyle fsize14 sm-fsize14"
                id="1326342140"
              >
                <span onClick={() => handleClick('1326342140')}>
                  {data ? data['1326342140'] : 'View More'}
                </span>
              </Button>
            </div>
            <div
              className="lg:mt-8 md:mt-6 mt-4 flex items-center lg:gap-12 md:gap-10 gap-8 flex-wrap dynamicStyle"
              id="7637556665"
            >
              {data && data['7637556665']
                ? data['7637556665'].map(e => {
                    return (
                      <div key={e._id}>
                        <h5 className="card_title_0">{e.field1}</h5>
                        <p className="card_description">{e.field2}</p>
                      </div>
                    );
                  })
                : 'no-card'}
            </div>
          </div>
          <div
            className="lg:w-5/12 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-8 dynamicStyle2"
            id="5848363083"
          >
            <Swiper
              effect={'cards'}
              observer={true}
              observeParents={true}
              modules={[EffectCards, Navigation]}
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              className="mySwiper "
            >
              <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {data && data['5848363083']
                ? data['5848363083'].map(e => {
                    return (
                      <SwiperSlide key={e._id}>
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img-card"
                          imageClassName="lg:h-96 md:h-72 h-60 object-cover rounded-lg"
                        />
                      </SwiperSlide>
                    );
                  })
                : 'no-card'}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection59;
