import React from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import FeatherIcon from 'feather-icons-react';

const ScoreBoard = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <div className="flex lg:flex-row md:flex-row flex-col-reverse justify-between w-full">
            <div className="flex">
              <Tab>
                <p className="sm-fsize14">Fixtures</p>
              </Tab>
              <Tab>
                <p className="sm-fsize14">Score</p>
              </Tab>
              <Tab>
                <p className="sm-fsize14">Request</p>
              </Tab>
              <Tab>
                <p className="sm-fsize14">Discussion</p>
              </Tab>
            </div>
            <div className="flex justify-end w-full lg:mb-0 md:mb-0 mb-2">
              <Button className="fsize14 b_button_primary px-6 py-2">
                + Add Fixtures
              </Button>
            </div>
          </div>
        </TabList>
        <p></p>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel className="sm-py-4">
            <div className="mt-2">
              <div className="mb-4">
                <div className="flex w-full justify-between items-center mb-1">
                  <p className="text-primary fsize12">12/02/2024 06.20 PM</p>
                  <div className="flex gap-1">
                    <EditIcon color="green" />
                    <DeleteIcon color="red" />
                  </div>
                </div>
                <div className="bg-f8f8f8 rounded-lg py-4 lg:px-6 md:px-6 px-3 w-full flex justify-between items-center">
                  <div className="lg:w-full md:w-1/3 w-1/4 lg:flex md:flex items-center gap-4">
                    <img
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="fixture_image"
                      className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-16 h-16 object-cover rounded-lg"
                    />
                    <p className="fsize16 sm-fsize14 font-medium lineClamp2">
                      Squad 1 sdfsfgsgs gedgdsfgd
                    </p>
                  </div>
                  <div className="lg:w-full md:w-1/3 w-2/4 flex items-center justify-center">
                    <div className=" arrow-left"></div>
                    <p className="bg-primary px-6 py-1 text-white font-medium fsize16">
                      0 - 1
                    </p>
                    <div className=" arrow-right"></div>
                  </div>
                  <div className="lg:w-full md:w-1/3 w-1/4  flex lg:flex-row md:flex-row flex-col-reverse items-center lg:gap-4 md:gap-4 justify-end">
                    <p className="fsize16 sm-fsize14 font-medium">Squad 2</p>
                    <img
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="fixture_image"
                      className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-16 h-16 object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex w-full justify-between items-center mb-1">
                  <p className="text-primary fsize12">12/02/2024 06.20 PM</p>
                  <div className="flex gap-1">
                    <EditIcon color="green" />
                    <DeleteIcon color="red" />
                  </div>
                </div>
                <div className="bg-f8f8f8 rounded-lg py-4 lg:px-6 md:px-6 px-3 w-full flex justify-between items-center">
                  <div className="lg:w-full md:w-1/3 w-1/4 lg:flex md:flex items-center gap-4">
                    <img
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="fixture_image"
                      className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-16 h-16 object-cover rounded-lg"
                    />
                    <p className="fsize16 sm-fsize14 font-medium lineClamp2">
                      Squad 1 sdfsfgsgs gedgdsfgd
                    </p>
                  </div>
                  <div className="lg:w-full md:w-1/3 w-2/4 flex items-center justify-center">
                    <div className=" arrow-left"></div>
                    <p className="bg-primary px-6 py-1 text-white font-medium fsize16">
                      0 - 1
                    </p>
                    <div className=" arrow-right"></div>
                  </div>
                  <div className="lg:w-full md:w-1/3 w-1/4  flex lg:flex-row md:flex-row flex-col-reverse items-center lg:gap-4 md:gap-4 justify-end">
                    <p className="fsize16 sm-fsize14 font-medium">Squad 2</p>
                    <img
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="fixture_image"
                      className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-16 h-16 object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex w-full justify-between items-center mb-1">
                  <p className="text-primary fsize12">12/02/2024 06.20 PM</p>
                  <div className="flex gap-1">
                    <EditIcon color="green" />
                    <DeleteIcon color="red" />
                  </div>
                </div>
                <div className="bg-f8f8f8 rounded-lg py-4 lg:px-6 md:px-6 px-3 w-full flex justify-between items-center">
                  <div className="lg:w-full md:w-1/3 w-1/4 lg:flex md:flex items-center gap-4">
                    <img
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="fixture_image"
                      className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-16 h-16 object-cover rounded-lg"
                    />
                    <p className="fsize16 sm-fsize14 font-medium lineClamp2">
                      Squad 1 sdfsfgsgs gedgdsfgd
                    </p>
                  </div>
                  <div className="lg:w-full md:w-1/3 w-2/4 flex items-center justify-center">
                    <div className=" arrow-left"></div>
                    <p className="bg-primary px-6 py-1 text-white font-medium fsize16">
                      0 - 1
                    </p>
                    <div className=" arrow-right"></div>
                  </div>
                  <div className="lg:w-full md:w-1/3 w-1/4  flex lg:flex-row md:flex-row flex-col-reverse items-center lg:gap-4 md:gap-4 justify-end">
                    <p className="fsize16 sm-fsize14 font-medium">Squad 2</p>
                    <img
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="fixture_image"
                      className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-16 h-16 object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="sm-py-4">
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr className="bg-primary mb-2 rounded-lg">
                    <Th isNumeric className="txt-center clr-white">
                      Rank
                    </Th>
                    <Th className="clr-white">Team</Th>
                    <Th isNumeric className="txt-center clr-white">
                      Win
                    </Th>
                    <Th isNumeric className="txt-center clr-white">
                      Loose
                    </Th>
                    <Th isNumeric className="txt-center clr-white">
                      Draw
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td isNumeric className="txt-center bg-primary">
                      1
                    </Td>
                    <Td>Team Name</Td>
                    <Td isNumeric className="txt-center">
                      2
                    </Td>
                    <Td isNumeric className="txt-center">
                      2
                    </Td>
                    <Td isNumeric className="txt-center">
                      0
                    </Td>
                  </Tr>
                  <Tr>
                    <Td isNumeric className="txt-center bg-primary">
                      1
                    </Td>
                    <Td>Team Name</Td>
                    <Td isNumeric className="txt-center">
                      2
                    </Td>
                    <Td isNumeric className="txt-center">
                      2
                    </Td>
                    <Td isNumeric className="txt-center">
                      0
                    </Td>
                  </Tr>
                  <Tr>
                    <Td isNumeric className="txt-center bg-primary">
                      1
                    </Td>
                    <Td>Team Name</Td>
                    <Td isNumeric className="txt-center">
                      2
                    </Td>
                    <Td isNumeric className="txt-center">
                      2
                    </Td>
                    <Td isNumeric className="txt-center">
                      0
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className="sm-py-4">
            <div className="mt-2">
              <p className="fsize14 md-fsize14 sm-fsize12">
                62 pending request's
              </p>
              <div className="mt-8">
                <div className="mb-8 lg:flex md:flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="mr-3">
                      <img
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="request_image"
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    </div>
                    <div className="">
                      <div className="flex items-center gap-2">
                        <h2 className="fsize16 font-semibold">Manish Sharma</h2>
                        <p className="fsize12">5 minute ago</p>
                      </div>
                      <p className="fsize14">members of 5 teams</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 lg:mt-0 md:mt-0 mt-2">
                    <Button 
                      className="fsize14 w-100per b_button_primary px-6 py-2" 
                    >
                      Accept
                    </Button>
                    <Button 
                    outlined
                      className="fsize14 text-primary w-100per px-6 py-2" 
                    >
                      Reject
                    </Button>
                  </div>
                </div>
                <div className="mb-8 lg:flex md:flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="mr-3">
                      <img
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="request_image"
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    </div>
                    <div className="">
                      <div className="flex items-center gap-2">
                        <h2 className="fsize16 font-semibold">Manish Sharma</h2>
                        <p className="fsize12">5 minute ago</p>
                      </div>
                      <p className="fsize14">members of 5 teams</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 lg:mt-0 md:mt-0 mt-2">
                    <Button 
                      className="fsize14 w-100per b_button_primary px-6 py-2" 
                    >
                      Accept
                    </Button>
                    <Button 
                      className="fsize14 text-primary w-100per px-6 py-2" 
                      outlined
                    >
                      Reject
                    </Button>
                  </div>
                </div>
                <div className="mb-8 lg:flex md:flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="mr-3">
                      <img
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="request_image"
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    </div>
                    <div className="">
                      <div className="flex items-center gap-2">
                        <h2 className="fsize16 font-semibold">Manish Sharma</h2>
                        <p className="fsize12">5 minute ago</p>
                      </div>
                      <p className="fsize14">members of 5 teams</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 lg:mt-0 md:mt-0 mt-2">
                    <Button 
                      className="fsize14 w-100per b_button_primary px-6 py-2" 
                    >
                      Accept
                    </Button>
                    <Button 
                      className="fsize14 text-primary w-100per px-6 py-2" 
                      outlined
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="sm-py-4">
            <div className="mt-4">
              <div className="">
                <div className="bottom-border-ebebeb py-4">
                  <div className="flex w-full items-start">
                    <div className="mr-3 w-30 md-w-100 ">
                      <img
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="request_image"
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    </div>
                    <div className="sm-w-100">
                      <p className="fsize12 lineClamp3 mb-1">
                        <span className="fsize16 text-primary font-semibold mr-1">
                          Manish Sharma
                        </span>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Inventore praesentium obcaecati maiores, qui
                        dolores placeat ea consectetur accusantium. Sequi quasi
                        voluptas quibusdam aliquam tempora ipsam maxime
                        inventore, nulla quam aliquid mollitia dolor quo harum
                        voluptate facere hic quisquam nesciunt fugit ullam sit
                        libero perspiciatis ducimus nemo! Placeat numquam animi
                        aliquam dolor autem et officiis temporibus,
                        exercitationem, enim tempore debitis laborum accusamus
                        necessitatibus ea, odit rerum veritatis accusantium sed
                        vitae assumenda ipsum commodi aliquid? Perspiciatis ut
                        praesentium incidunt nemo eum facilis quos obcaecati
                        repellendus autem aperiam impedit, ipsum doloremque,
                        laboriosam distinctio eius consectetur quis porro esse
                        mollitia accusamus. Ducimus, omnis est!
                      </p>
                      <div className="flex mt-3 gap-1">
                        <FeatherIcon
                          icon="heart"
                          size="14px"
                          className="cursor-pointer"
                        />
                        <FeatherIcon
                          icon="trash-2"
                          size="14px"
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-border-ebebeb py-4">
                  <div className="flex w-full items-start">
                    <div className="mr-3 w-30 md-w-100 ">
                      <img
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="request_image"
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    </div>
                    <div className="sm-w-100">
                      <p className="fsize12 lineClamp3 mb-1">
                        <span className="fsize16 text-primary font-semibold mr-1">
                          Manish Sharma
                        </span>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Inventore praesentium obcaecati maiores, qui
                        dolores placeat ea consectetur accusantium. Sequi quasi
                        voluptas quibusdam aliquam tempora ipsam maxime
                        inventore, nulla quam aliquid mollitia dolor quo harum
                        voluptate facere hic quisquam nesciunt fugit ullam sit
                        libero perspiciatis ducimus nemo! Placeat numquam animi
                        aliquam dolor autem et officiis temporibus,
                        exercitationem, enim tempore debitis laborum accusamus
                        necessitatibus ea, odit rerum veritatis accusantium sed
                        vitae assumenda ipsum commodi aliquid? Perspiciatis ut
                        praesentium incidunt nemo eum facilis quos obcaecati
                        repellendus autem aperiam impedit, ipsum doloremque,
                        laboriosam distinctio eius consectetur quis porro esse
                        mollitia accusamus. Ducimus, omnis est!
                      </p>
                      <div className="flex mt-3 gap-1">
                        <FeatherIcon
                          icon="heart"
                          size="14px"
                          className="cursor-pointer"
                        />
                        <FeatherIcon
                          icon="trash-2"
                          size="14px"
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-border-ebebeb py-4">
                  <div className="flex w-full items-start">
                    <div className="mr-3 w-30 md-w-100 ">
                      <img
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="request_image"
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    </div>
                    <div className="sm-w-100">
                      <p className="fsize12 lineClamp3 mb-1">
                        <span className="fsize16 text-primary font-semibold mr-1">
                          Manish Sharma
                        </span>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Inventore praesentium obcaecati maiores, qui
                        dolores placeat ea consectetur accusantium. Sequi quasi
                        voluptas quibusdam aliquam tempora ipsam maxime
                        inventore, nulla quam aliquid mollitia dolor quo harum
                        voluptate facere hic quisquam nesciunt fugit ullam sit
                        libero perspiciatis ducimus nemo! Placeat numquam animi
                        aliquam dolor autem et officiis temporibus,
                        exercitationem, enim tempore debitis laborum accusamus
                        necessitatibus ea, odit rerum veritatis accusantium sed
                        vitae assumenda ipsum commodi aliquid? Perspiciatis ut
                        praesentium incidunt nemo eum facilis quos obcaecati
                        repellendus autem aperiam impedit, ipsum doloremque,
                        laboriosam distinctio eius consectetur quis porro esse
                        mollitia accusamus. Ducimus, omnis est!
                      </p>
                      <div className="flex mt-3 gap-1">
                        <FeatherIcon
                          icon="heart"
                          size="14px"
                          className="cursor-pointer"
                        />
                        <FeatherIcon
                          icon="trash-2"
                          size="14px"
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:mt-8 md:mt-8 mt-4 flex items-center w-full">
                <div className="mr-3 lg:w-20 md:w-24 w-32">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="request_image"
                    className="w-16 h-16 rounded-full object-cover"
                  />
                </div>
                <Input placeholder="add a comment ..." />
                <div className="ml-3 fsize14">
                  <Button className="fsize14 b_button_primary px-6 py-2">
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default ScoreBoard;
