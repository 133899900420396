import { Button } from "primereact/button";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Image } from "primereact/image";

const DocumentSection2 = () => {
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="flex items-center w-full mb-6">
          <div className="bg-primary lg:px-5 px-3 py-2 rounded-lg">
            <p className=" text-white my-0 fsize16 sm-fsize12">2022 - 2023</p>
          </div>
          <hr className="lg:w-10/12 md:w-9/12 w-8/12 ml-5"></hr>
        </div>
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                imageClassName="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text_surface800 sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                imageClassName="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text_surface800 sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                imageClassName="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text_surface800 sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                imageClassName="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text_surface800 sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                imageClassName="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text_surface800 sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                See Results
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                imageClassName="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text_surface800 sm-fsize14">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                See Results
              </Button>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="lg:my-16 md:my-12 my-8">
          <div className="flex items-center w-full mb-6">
            <div className="bg-primary lg:px-5 px-3 py-2 rounded-lg">
              <p className="text-white my-0 fsize16 sm-fsize12">2022 - 2023</p>
            </div>
            <hr className="lg:w-10/12 md:w-9/12 w-8/12 ml-5"></hr>
          </div>
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              820: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div>
          <div className="flex items-center w-full mb-6">
            <div className="bg-primary lg:px-5 px-3 py-2 rounded-lg">
              <p className="text-white my-0 fsize16 sm-fsize12">2022 - 2023</p>
            </div>
            <hr className="lg:w-10/12 md:w-9/12 w-8/12 ml-5"></hr>
          </div>
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              820: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cursor-pointer border-primary rounded-lg p-4">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
                <p className="mt-4 text-center text_surface800 sm-fsize14">
                  SBI Foundation Anual Report 2022 - 2023
                </p>
                <Button className="b_button_primary px-6 py-2 w-full mt-2 flex justify-center">
                  See Results
                </Button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default DocumentSection2;
