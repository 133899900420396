import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux/es/exports';
const ModalStartnew = ({ isOpen, onClose, reset }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <div className="p-3">
            <h4 className="text-center fsize20 font-semibold cust-textColor">
              Start New AI Conversion
            </h4>
            <p className="mt-2 text-gray text-center fsize15 textColor-light">
              All your previous chats will be deleted. Are you sure you want to
              proceed?
            </p>
            <div className="flex gap-2 mt-4">
              <Button
                
                className="w-full b_button_primary px-6 py-2"
                px={6}
                onClick={() => reset()}
              >
                Start New
              </Button>
              <Button
                outlined
                className="text-primary w-full"
                px={8}
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ModalStartnew;
