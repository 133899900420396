import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import { Button } from 'primereact/button';

import { useDispatch, useSelector } from 'react-redux/es/exports';
const EditModal = ({ isOpen, onClose, reset }) => {
  let ConversationHistory = useSelector(
    state => state.Aiassist.ConversationHistory
  );
  return (
    <Modal isOpen={isOpen} size={'full'} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <div className="p-3 ">
            <div className="min-h-full bg-red-500">
              <h4 className="text-center fsize20 font-semibold">
                Train your model
              </h4>
              <p className="mt-2 text-gray text-center fsize15">
                All your previous chats will be deleted. Are you sure you want
                to proceed?
              </p>
            </div>

            <div className="flex gap-2 mt-4">
              <Button
                className="w-full b_button_primary px-6 py-2"
                px={6}
                onClick={() => reset()}
              >
                Save
              </Button>
              <Button
              outlined
                className="text-primary w-full px-8 py-2"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EditModal;
