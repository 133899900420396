import apis from "../../apis/client/Forms/podcast";

export const getAllPodcasts = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        isDeleted: false,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      let { data } = await apis.getAllPodcasts(payload2);
      if (data) {
        await dispatch(setPodcast(data.data.result));
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
// export const getSinglePodcast = (payload) => {
//   let record;
//   return async (dispatch) => {
//     try {
//       let payload2 = {
//         projectName: process.env.REACT_APP_COMMUNITY_NAME,
//         ...payload,
//       };
//       //   let { data } = await apis.user.getProject(payload);
//       let { data } = await apis.getBlogs(payload2);
//       if (data) {
//         record = data;
//       }
//     } catch (error) {
//       console.log(console);
//       return error.response.data;
//     }
//     return record;
//   };
// };
export const getBlogsLoadMore = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getBlogs(payload2);
      // dispatch(setBlogsLoadMore(data.data));

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
export const setPodcast = (data) => {
  return {
    type: "setPodcast",
    payload: data,
  };
};
export const setSelectedPodcast = (data) => {
  return {
    type: "setSelectedPodcast",
    payload: data,
  };
};
export const setPodcastLoadMore = (data) => {
  return {
    type: "setPodcastLoadMore",
    payload: data,
  };
};
