import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";

const CategoriesOption1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <section className="prime_container">
        <h2
          id="1500315733"
          className="dynamicStyle section_header inline-block"
        >
          {data ? data["1500315733"] : "Lorem Ipsum"}
        </h2>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4 lg:mt-12 md:mt-10 mt-8">
          <Link to="/african-fabrics" className="relative">
            <Image
              imageClassName="cat-wh w-full object-cover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="category"
            />
            <div className="txt-absl">
              <p className="card_title_0 font-semibold text-center">
                African Wax Fabrics
              </p>
            </div>
          </Link>
          <Link to="/printed-cotton" className="relative">
            <Image
              imageClassName="cat-wh w-full object-cover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="category"
            />
            <div className="txt-absl">
              <p className="card_title_0 font-semibold text-center">
                Printed Cotton Fabric
              </p>
            </div>
          </Link>
          <Link to="/polyester" className="relative">
            <Image
              imageClassName="cat-wh w-full object-cover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="category"
            />
            <div className="txt-absl">
              <p className="card_title_0 font-semibold text-center">
                Polyester Print Fabric
              </p>
            </div>
          </Link>
          <Link to="/tie-dye" className="relative">
            <Image
              imageClassName="cat-wh w-full object-cover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="category"
            />
            <div className="txt-absl">
              <p className="card_title_0 font-semibold text-center">
                Tie Dye
              </p>
            </div>
          </Link>
          <Link to="/nighty-fabrics" className="relative">
            <Image
              imageClassName="cat-wh w-full object-cover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="category"
            />
            <div className="txt-absl">
              <p className="card_title_0 font-semibold text-center">
                Nighty Printed Cotton Fabric
              </p>
            </div>
          </Link>
          <Link to="/nighty-fabrics" className="relative">
            <Image
              imageClassName="cat-wh w-full object-cover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="category"
            />
            <div className="txt-absl">
              <p className="card_title_0 font-semibold text-center">
                Nighty Printed Cotton Fabric
              </p>
            </div>
          </Link>
          <Link to="/real-wax" className="relative">
            <Image
              imageClassName="cat-wh w-full object-cover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="category"
            />
            <div className="txt-absl">
              <p className="card_title_0 font-semibold text-center">
                Lungi Cotton Fabrics
              </p>
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default CategoriesOption1;
