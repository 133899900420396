import React,{useEffect,useState} from "react";
import FeatherIcon from "feather-icons-react";
import { Image } from "primereact/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";

const Testimonial20 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container test18 custtop18">
        <Swiper
          spaceBetween={15}
          slidesPerView={1}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper"
          autoplay={{
            delay: "1000",
          }}
          loop
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1280: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            991: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              color="white"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              color="white"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {getAllTestimonial?.data?.result.map((testimonial, index) => {
            return (
              <SwiperSlide>
                <div
                  className="relative lg:mx-2 md:mx-2 lg:mt-10 md:mt-10 mt-10"
                  key={testimonial._id}
                >
                  <div className="text-center relative py-10">
                    <div className="lg:h-32 md:h-32 h-28 lg:w-28 md:w-28 w-24 mx-auto mb-4">
                      <Image
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                        alt={testimonial.name}
                        imageClassName="lg:h-28 md:h-28 h-24 lg:w-28 md:w-28 w-24 mx-auto object-cover rounded-full"
                      />
                    </div>
                    <p className="card_title">{testimonial.name}</p>
                    <div className="flex justify-center gap-2 mb-4">
                      <Rating
                        initialRating={3}
                        className="flex_rating"
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="mr-1 textColor-light"
                            size={18}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="mr-1 fillstroke"
                            size={18}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <div className="lg:w-2/3 md:w-2/3 w-2/3 text-center mx-auto">
                      <div className="lg:h-36 md:h-36 h-36">
                        <p className="card_description lineClamp5">
                          {testimonial.testimonial}
                        </p>
                      </div>
                    </div>
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/taskmanager/Group%2086.png"
                      alt="img"
                      imageClassName="lg:w-12 lg:h-10 md:w-12 md:h-10 w-9 h-9 absolute absl-test20 transform-x"
                    />
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/taskmanager/Subtraction%201.png"
                      alt="img"
                      imageClassName="lg:w-44 lg:h-44 md:w-36 md:h-36 w-28 h-28 absolute top-0 left-0"
                    />
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/taskmanager/Subtraction%202.png"
                      alt="img"
                      imageClassName="lg:w-44 lg:h-44 md:w-36 md:h-36 w-28 h-28 absolute bottom-0 right-0"
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial20;
