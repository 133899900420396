import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import {Image} from 'primereact/image'

const Testimonial19 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);

  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);

  useEffect(() => {
    dispatch(getTestimonal());
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
          {getAllTestimonial
            ? getAllTestimonial?.data?.result.map((testimonial, index) => {
                return (
                  <div>
                    {index === 0 ? (
                      <>
                        <div className="mb-3">
                          <h2
                            id="0369742141"
                            className="dynamicStyle section_header"
                          >
                            {data ? data['0369742141'] : 'Lorem Ipsum'}
                          </h2>
                          <ReactQuill
                            id="4654501833"
                            theme="bubble"
                            readOnly
                            className="dynamicStyle bubble section_detail_0 pb-2"
                            value={
                              data ? data['4654501833'] : 'Welcome to My World'
                            }
                          />
                        </div>
                      </>
                    ) : null}

                    <div
                      key={index}
                      className="shadow-none cust-bgColor theme-radius themeShadow-insert theme-radius themeShadow theme-bg"
                    >
                      <Image
                        imageClassName={
                          index === 0
                            ? 'w-full h-review19 object-cover'
                            : 'w-full h-review20 object-cover'
                        }
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                        alt="image"
                      />
                      <div className="">
                        <p className="card_description mt-4 lineClamp3">
                          {testimonial.testimonial}
                        </p>
                        <h4 className="card_title_0 lg:mt-4 md:mt-4 mt-2">
                          {testimonial.name}
                        </h4>
                        <p className="fsize12 textColor-light">
                          {new Date(testimonial.createdAt).toDateString()} • 6
                          min read
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            : 'No Data'}
        </div>
      </div>
    </div>
  );
};

export default Testimonial19;
