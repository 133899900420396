import { Card } from "primereact/card";
import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Team16 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="w-full">
          <h2 id="1217169313" className="dynamicStyle section_header">
            {data ? data["1217169313"] : "Lorem Ipsum"}
          </h2>
          <p id="5605712789" className="dynamicStyle section_detail_0">
            {data ? data["5605712789"] : "Lorem Ipsum"}
          </p>
        </div>
        <div
          id="4611313015"
          className="dynamicStyle lg:mt-10 md:mt-8 mt-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8"
        >
          {data && data["4611313015"]
            ? data["4611313015"].map((e) => {
                return (
                  <Card className="relative bg_none shadow-none lg:mx-2 md:mx-2">
                    <div className="flex items-center gap-2 w-full">
                      <div className="w-1/4">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="lg:w-20 md:w-16 w-16 lg:h-20 md:h-16 h-16 object-contain rounded-full"
                        />
                      </div>
                      <div className="w-3/4 lg:pl-0 md:pl-0">
                        <h4 className="card_title_0">{e.field2}</h4>
                        <div className="">
                          <p className="lineClamp3 card_description">
                            {e.field3}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })
            : "no-data"}
        </div>
      </div>
    </div>
  );
};

export default Team16;
