import React from 'react';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { TiSocialFacebookCircular } from 'react-icons/ti';
import { FiYoutube } from 'react-icons/fi';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const Footer4 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  let brandProfile = useSelector(state => state.brandDetails.brandProfile);

  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  
  const isDarkMode = useSelector((state) => state.Loader.DarkMode);

  return (
    <section className={isDarkMode ? "bg_surface100" : "bg-secondary"}>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-10 py-8">
        <div className="flex justify-between cust-grid4 lg:px-0 md:px-0 px-5">
          <div className="">
            <h3
              id="3178382098"
              className="dynamicStyle fsize16 font-semibold lh24px cust-textColor"
            >
              {data ? data['3178382098'] : 'Lorem ipsum'}
            </h3>
            <ul className="mt-2 mb-4">
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  About us
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Blog
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Carrers
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Jobs
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  In Press
                </li>{' '}
              </a>
            </ul>
          </div>
          <div className="">
            <h3
              id="9640757103"
              className="dynamicStyle fsize16 font-semibold cust-textColor"
            >
              {data ? data['9640757103'] : 'Lorem ipsum'}
            </h3>
            <ul className="mt-2 mb-4">
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Contact us
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Online Chat
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Whatsapp
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Telegram
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Ticketing
                </li>{' '}
              </a>
            </ul>
          </div>
          <div className="">
            <h3
              id="2065317706"
              className="dynamicStyle fsize16 font-semibold cust-textColor"
            >
              {data ? data['2065317706'] : 'Lorem ipsum'}
            </h3>
            <ul className="mt-2 mb-4">
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Account
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Manage Deliveries
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Orders
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Payments
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Returns
                </li>{' '}
              </a>
            </ul>
          </div>
          <div className="">
            <h3
              id="1515376885"
              className="dynamicStyle fsize16 font-semibold lh24px cust-textColor"
            >
              {data ? data['1515376885'] : 'Lorem ipsum'}
            </h3>
            <ul className="mt-2 mb-4">
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  About us
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Blog
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Carrers
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Jobs
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  In Press
                </li>{' '}
              </a>
            </ul>
          </div>
          <div className="">
            <h3
              id="6993597718"
              className="dynamicStyle fsize16 font-semibold cust-textColor"
            >
              {data ? data['6993597718'] : 'Lorem ipsum'}
            </h3>
            <ul className="mt-2 mb-4">
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Contact us
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Online Chat
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Whatsapp
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Telegram
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Ticketing
                </li>{' '}
              </a>
            </ul>
          </div>
          <div className="">
            <h3
              id="0288998964"
              className="dynamicStyle fsize16 font-semibold cust-textColor"
            >
              {data ? data['0288998964'] : 'Lorem ipsum'}
            </h3>
            <ul className="mt-2 mb-4">
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Account
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Manage Deliveries
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Orders
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Payments
                </li>{' '}
              </a>
              <a href="/">
                <li className="fsize12 lh24px py-1 clr-5a5a5a cursor-pointer textColor-light">
                  Returns
                </li>{' '}
              </a>
            </ul>
          </div>
        </div>

        <div className="lg:flex md:flex block justify-between items-center lg:mt-0 md:mt-0 mt-4">
          <a href="/">
            <Image
              src={getSecondaryImage(brandProfile)}
              alt="footer-logo"
              className="w-10rem  sm-mx-auto"
            />
          </a>
          <div className="icons flex gap-2 my-4 sm-center">
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <FaWhatsapp className="w-4 h-4" />
              </div>
            </a>
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <FaInstagram className="w-4 h-4" />
              </div>
            </a>
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <TiSocialFacebookCircular className="w-4 h-4" />{' '}
              </div>
            </a>
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <FiYoutube className="w-4 h-4" />
              </div>
            </a>
          </div>
          <span
            id="5537919277"
            className="dynamicStyle clr-5a5a5a fsize14 sm-center textColor-light"
          >
            {data ? data['5537919277'] : '@2020-2021, All Rights Reserved.'}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Footer4;
