import React from 'react';
import { Box, Skeleton, SkeletonText } from '@chakra-ui/react';

const SkeletonCard4ImageandText2 = () => {
  return (
   <div>
    <div className="lg:mb-12 md:mb-12 mb-6">
    <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
    </div>
     <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-6 gap-8 ">
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="160" />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="160" />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="160" />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="160" />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
    </div>
   </div>
  );
};

export default SkeletonCard4ImageandText2;
