import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const HeroSection26 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end

  return (
    <section className="relative h-700px sm-h-500px w-full" style={{ background: thisComponent.sectionColor }}>
      <div id="0253032284" className="dynamicStyle2">
        <Image
          src={data ? getImage(data["0253032284"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="filter-b4 relative h-700px sm-h-500px w-full object-cover"
          alt="hero-section"
        />
      </div>
      <div className="absolute top-0 left-0 w-full flex justify-center items-center h-700px sm-h-500px">
        <div className="text-center">
          <p id="6414838372" className="dynamicStyle hero_title">
            {data ? data["6414838372"] : "Travel"}
          </p>

          <ReactQuill
            id="0881226179"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle card_description lg:py-8 md:py-6 py-4 letter-spacing8px sm-letter-spacing4px"
            value={data ? data["0881226179"] : "Lorem Ipsum"}
          />
          <p id="9359747912" className="dynamicStyle tranparent-hero-title">
            {data ? data["9359747912"] : "With Us"}
          </p>
          <Button
            id="8559895089"
            outlined
            className="dynamicStyle lg:mt-16 md:mt-12 mt-8 px-6 py-2"
          >
            <span onClick={() => handleClick("8559895089")}>
              {data ? data["8559895089"] : "Enquire Now"}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection26;
