import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useSelector } from "react-redux/es/exports";
import React, { useEffect, useRef, useState } from "react";
import { handleScroll } from "./fadeeffect/FadeEffect";

const Cardtext2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <section className="prime_container">
        <div className="header_align">
          <h2 className="section_header">What We Do</h2>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-4 lg:mt-12 md:mt-10 mt-8 ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <Card className="prime_card themeShadow theme-bg">
            <h5 className="card_title text-center dynamicStyle" id="6714256754">
              {data ? data["6714256754"] : "Lorem Ipsum"}
            </h5>
            <div className="flex justify-center mt-2">
              <Button
                className="px-6 py-2 fsize14 b_button_primary theme-btn dynamicStyle"
                id="8399951329"
              >
                {data ? data["8399951329"] : "Lorem Ipsum"}
              </Button>
            </div>
          </Card>

          <Card className="prime_card themeShadow theme-bg">
            <h5 className="card_title text-center dynamicStyle" id="1321629097">
              {data ? data["1321629097"] : "Lorem Ipsum"}
            </h5>
            <div className="flex justify-center mt-2">
              <Button
                className="px-6 py-2 fsize14 b_button_primary theme-btn dynamicStyle"
                id="3715346425"
              >
                {data ? data["3715346425"] : "Lorem Ipsum"}
              </Button>
            </div>
          </Card>

          <Card className="prime_card themeShadow theme-bg">
            <h5 className="card_title text-center dynamicStyle" id="0974332954">
              {data ? data["0974332954"] : "Lorem Ipsum"}
            </h5>
            <div className="flex justify-center mt-2">
              <Button
                className="px-6 py-2 fsize14 b_button_primary theme-btn dynamicStyle"
                id="3335115058"
              >
                {data ? data["3335115058"] : "Lorem Ipsum"}
              </Button>
            </div>
          </Card>
        </div>
      </section>
    </div>
  );
};

export default Cardtext2;
