import React from "react";
import { useSelector } from "react-redux";

const CategoriesSection4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <section className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="7516700575"
          className="dynamicStyle fsize42 md-fsize36 sm-fsize24 font-semibold"
        >
          {data ? data["7516700575"] : "Lorem Ipsum"}
        </h3>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-8 md:mt-8 mt-6 collection_component rounded-lg overflow-hidden">
          <div className="p-6 collection_grid_item1">
            <p className="pb-2 fsize24 sm-fsize20">Agri tech</p>
          </div>
          <div className="p-6 collection_grid_item1">
            <p className="pb-2 fsize24 sm-fsize20">Agri tech</p>
          </div>
          <div className="p-6 collection_grid_item1">
            <p className="pb-2 fsize24 sm-fsize20">Agri tech</p>
          </div>
          <div className="p-6 collection_grid_item1">
            <p className="pb-2 fsize24 sm-fsize20">Agri tech</p>
          </div>
          <div className="p-6 collection_grid_item1">
            <p className="pb-2 fsize24 sm-fsize20">Agri tech</p>
          </div>
          <div className="p-6 collection_grid_item1">
            <p className="pb-2 fsize24 sm-fsize20">Agri tech</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoriesSection4;
