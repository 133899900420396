import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard14 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h4 id="6817001878" className="dynamicStyle section_header">
              {data ? data['6817001878'] : 'Welcome to My World'}
            </h4>
          </div>

          <div className="lg:pt-16 md:pt-16 pt-12 overflow-x-hidden lg:px-12 md:px-8 px-5 ">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
              <div className="box-shadow-none lg:p-4 p-2 w-full border-side">
                <div
                  id="5669989720"
                  className="dynamicStyle flex justify-center w-full"
                >
                  <Image
                    src={data ? getImage(data['5669989720']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="Img_Preview"
                    imageClassName="lg:w-20 lg:h-20 w-16 h-16 lg:mb-3 md:mb-3 mb-2 rounded-full border theme-border p-2 object-contain"
                  />
                </div>
                <h2
                  id="5133048696"
                  className="dynamicStyle card_title text-center"
                >
                  {data ? data['5133048696'] : 'Welcome to My World'}
                </h2>
                <p
                  id="4298231803"
                  className="dynamicStyle card_description text-center"
                >
                  {data ? data['4298231803'] : 'Welcome to My World'}
                </p>
              </div>
              <div className="box-shadow-none lg:p-4 p-2 w-full border-side1">
                <div
                  id="7434461036"
                  className="dynamicStyle flex justify-center w-full"
                >
                  <Image
                    src={data ? getImage(data['7434461036']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="Img_Preview"
                    imageClassName="lg:w-20 lg:h-20 w-16 h-16 lg:mb-3 md:mb-3 mb-2 rounded-full border theme-border p-2 object-contain"
                  />
                </div>
                <h2
                  id="1025943301"
                  className="dynamicStyle card_title text-center"
                >
                  {data ? data['1025943301'] : 'Welcome to My World'}
                </h2>
                <p
                  id="1471258871"
                  className="dynamicStyle card_description text-center"
                >
                  {data ? data['1471258871'] : 'Welcome to My World'}
                </p>
              </div>
              <div className="box-shadow-none lg:p-4 p-2 w-full border-side">
                <div
                  id="5562643848"
                  className="dynamicStyle flex justify-center w-full"
                >
                  <Image
                    src={data ? getImage(data['5562643848']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="Img_Preview"
                    imageClassName="lg:w-20 lg:h-20 w-16 h-16 lg:mb-3 md:mb-3 mb-2 rounded-full border theme-border p-2 object-contain"
                  />
                </div>
                <h2
                  id="4092643905"
                  className="dynamicStyle card_title text-center"
                >
                  {data ? data['4092643905'] : 'Welcome to My World'}
                </h2>
                <p
                  id="1600091020"
                  className="dynamicStyle card_description text-center"
                >
                  {data ? data['1600091020'] : 'Welcome to My World'}
                </p>
              </div>
              <div className="box-shadow-none lg:p-4 p-2 w-full border-side1">
                <div
                  id="6465752461"
                  className="dynamicStyle flex justify-center w-full"
                >
                  <Image
                    src={data ? getImage(data['6465752461']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="Img_Preview"
                    imageClassName="lg:w-20 lg:h-20 w-16 h-16 lg:mb-3 md:mb-3 mb-2 rounded-full border theme-border p-2 object-contain"
                  />
                </div>
                <h2
                  id="5707044702"
                  className="dynamicStyle card_title text-center"
                >
                  {data ? data['5707044702'] : 'Welcome to My World'}
                </h2>
                <p
                  id="2777215142"
                  className="dynamicStyle card_description text-center"
                >
                  {data ? data['2777215142'] : 'Welcome to My World'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default FeatureCard14;
