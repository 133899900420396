import React, { useState } from "react";
import { FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa";
import { Image, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

const Footer2 = ({ pageName }) => {
  const [checked, setChecked] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);

  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  const isDarkMode = useSelector((state) => state.Loader.DarkMode);
  return (
    <section className={isDarkMode ? "bg_surface100" : "bg-secondary"}>
      <div className="container mx-auto lg:px-12 md:px-12 px-4 lg:pt-10 pt-8 lg:pb-6 pb-4">
        <div className="lg:flex md:block block justify-between lg:gap-8 md:gap-6">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <div className="my-4">
              {/* <ReactQuill
                  id="5039567522"
                  theme="bubble"
                  readOnly
                  className="card_description bubble dynamicStyle lg:mb-8 md:mb-6 mb-4"
                  value={data ? data["5039567522"] : "Lorem Ipsum"}
                /> */}
              <p
                id="4004727062"
                className="dynamicStyle  fsize14 font-semibold fsize24 text-white"
              >
                Subscribe to
              </p>
              <p
                id="4004727062"
                className="dynamicStyle  font-semibold fsize34 text-white"
              >
                Latest Updates
              </p>

              <div className="mt-8 lg:w-3/4 md:w-3/4">
                <InputText
                  value=""
                  placeholder="Enter your email address"
                  className="cust_input w-full"
                />
                <div className="mt-6">
                  <Button
                    iconPos="right"
                    icon="pi pi-arrow-right"
                    className="b_button_primary w-full text-left rounded0 px-6 py-4 "
                    label="Subscribe Now"
                  />
                </div>

                <div className="mt-4 px-2 flex gap-4">
                  <Checkbox
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                  ></Checkbox>
                  <p className="fsize14  font-medium leading-6 text-white">
                    {" "}
                    I hereby consent to the processing of the personal data that
                    i have provided and declare my agreement with the data
                    protection regulations in the <Link>
                      {" "}
                      Privacy Policy
                    </Link>{" "}
                    on the website.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="icons flex gap-8 my-4 sm-center">
                <a href="/">
                  <FaTwitter className="clr-d9d9d9" />
                </a>
                <a href="/">
                  <FaInstagram className="clr-d9d9d9" />
                </a>
                <a href="/">
                  <FaFacebook className="clr-d9d9d9" />
                </a>
              </div> */}
          </div>
          <div className="footer-menu flex justify-between lg:w-1/2 md:w-full w-full lg:mt-0 md:mt-6 mt-10 lg:px-0 md:px-0 px-5">
            <div className="">
              <h3
                id="1312720152"
                className="dynamicStyle fsize16 font-normal lh24px footertext"
              >
                Website
              </h3>
              <ul className="lg:my-4 md:my-4 my-2">
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Our product
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  New features
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Roadmap
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Beta testing
                </li>
              </ul>
            </div>
            <div className="">
              <h3
                id="0224840042"
                className="dynamicStyle fsize16 font-normal footertext"
              >
                Podcast Series
              </h3>
              <ul className="lg:my-4 md:my-4 my-2">
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Retail
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Financial services
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Media
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Gaming
                </li>
              </ul>
            </div>
            <div className="">
              <h3
                id="9588967159"
                className="dynamicStyle fsize16 font-normal footertext"
              >
                Socials
              </h3>
              <ul className="lg:my-4 md:my-4 my-2">
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Blog
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  How-to's
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Brand assets
                </li>
                <li className="fsize16 sm-fsize14 text-white lh24px py-1 liststyletype_none  lg:mb-4 md:mb-4 mb-2">
                  Events
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-8 borderbottom_primary">
          <Image
            src={getSecondaryImage(brandProfile)}
            alt="footer-logo"
            className="lg:w-1/3 md:w-1/3 w-1/2 sm-mx-auto"
          />
        </div>

        {/* <div className="text-center text-white fsize15 sm-fsize14 text-white">
          {project.poweredBy ? (
            <p>Powered by Brained</p>
          ) : (
            <p>Copyright &copy;2022 All Right reserved</p>
          )}
        </div> */}

        <div className="text-center font-normal lg:flex md:flex justify-between  text-white fsize15 sm-fsize14  items-center lg:pt-8 md:pt-8 lg:pb-16 md:pb-16 pb-4">
          <div className="flex gap-8 lg:pt-0 md:pt-0 pt-4 lg:pb-0 md:pb-0 pb-4">
            <p className="fsize16  text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer ">
              {" "}
              <Link to="/terms-conditions"> Terms & Conditions</Link>
            </p>

            <p className="fsize16 text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
          </div>

          <p>
            Curated by{" "}
            <span className="fsize34 font-medium text-white"> Accel</span>{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer2;
