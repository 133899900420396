import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const Services4 = ({ pageName }) => {
  const [collapse, setcollpase] = useState(null);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  console.log(data[6440697692], "ds");

  const handleClick = (index) => {
    if (index === null) {
      setcollpase(null);
    } else {
      setcollpase(index);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div class="">
          <p
            id="7168604627"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary theme-textColor font-medium"
          >
            {data ? data["7168604627"] : "Lorem Ipsum"}
          </p>
          <h2
            id="4325274324"
            className="dynamicStyle section_header theme-textColor mt-2"
          >
            {data ? data["4325274324"] : "Lorem Ipsum"}
          </h2>
        </div>
        <div className="mt-8">
          <div>
            {data && data["6440697692"]
              ? data["6440697692"].map((e, index) => {
                  return (
                    <div className="bordbl pb-2">
                      <div
                        className={
                          collapse === index
                            ? "py-3 lg:px-2 md:px-2 cursor-pointer w-full flex justify-around items-start"
                            : "py-3 lg:px-2 md:px-2 cursor-pointer w-full flex justify-around items-center"
                        }
                        onClick={() => handleClick(index)}
                      >
                        <div className="flex items-center gap-2 lg:w-5/12 md:w-5/12 w-11/12">
                          <div
                            className={
                              collapse === index
                                ? "lg:w-3 lg:h-3 md:w-3 md:h-3 w-2 h-2 rounded-full bg-primary"
                                : "lg:w-3 lg:h-3 md:w-3 md:h-3 w-2 h-2 rounded-full bg-dark"
                            }
                          ></div>
                          <h2
                            className={
                              collapse === index
                                ? "hero_title_0 text-primary font-semibold"
                                : "hero_title_0 text_surface800 font-semibold"
                            }
                          >
                            {e.field2}
                          </h2>
                        </div>
                        <div className="lg:w-6/12 md:w-6/12 lg:pr-5 md:pr-2 lg:block md:block hidden">
                          {collapse === index ? (
                            <>
                              <Image
                                src={e ? getImage(e.field1) : ""}
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                alt="image-service"
                                imageClassName="w-full lg:h-52 md:h-40 h-40"
                              />
                            </>
                          ) : null}
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble dynamicStyle card_description"
                            value={e.field3}
                          />
                        </div>
                        <div className="w-1/12 flex justify-end">
                          <FeatherIcon
                            icon={collapse === index ? "minus" : "plus"}
                            size="24"
                          />
                        </div>
                      </div>
                      {collapse === index ? (
                        <div className="w-full lg:px-2 md:px-2 lg:hidden md:hidden block">
                          <>
                            <Image
                              src={e ? getImage(e.field1) : ""}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt="image-service"
                              imageClassName="w-full lg:h-52 md:h-40 h-40"
                            />
                          </>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble dynamicStyle card_description"
                            value={e.field3}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : "no-card"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services4;
