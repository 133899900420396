import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
// import FeatherIcon from 'feather-icons-react';
import { Image } from 'primereact/image';

const AboutSection15 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-5/12 md:w-5/12">
            <div
              ref={elementRef}
              className={`fade-left cards-home relative rounded-2xl bg-primary lg:w-3/4 md:w-11/12 ${
                isVisible ? 'visibleleft' : ''
              } `}
            >
              <div
                id="1727455261"
                className="dynamicStyle2 lg:ml-8 md:ml-8 cards-img z-10 rounded-2xl w-full"
              >
                <Image
                  src={data ? getImage(data['1727455261']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="cards-img object-cover absolute-cards lg:absolute md:absolute z-20 rounded-2xl"
                  alt="cards"
                  style={{ transform: transform }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
                imageClassName="w-24 h-20 absolute bottom-0 left-0"
                alt="side"
              />
            </div>
          </div>
          <div className="lg:w-7/12  md:w-7/12 md:pl-10  lg:pl-0 lg:mt-0 md:mt-0 mt-6">
            <h2
              id="5060504169"
              className="dynamicStyle fsize34 md:fsize24 sm-fsize20 font-semibold text-primary cust-textColor"
            >
              {data ? data['5060504169'] : 'Message from the Chairman, SBI'}
            </h2>
            <p id="7662101065" className="card_title mt-2 dynamicStyle">
              {data ? data['7662101065'] : 'Lorem Ipsum'}
            </p>
            <ReactQuill
              id="8772849817"
              theme="bubble"
              readOnly
              className="card-description bubble dynamicStyle"
              value={data ? data['8772849817'] : 'Lorem Ipsum'}
            />

            {/* <Button
            outlined
            colorScheme="primary"
            className="dynamicStyle mt-6 textprimary cust-textColor sm-fsize14"
            size="lg"
            id=""
          >
            {data ? data[''] : 'Know More'}
            <FeatherIcon
              className="textprimary cust-textColor ml-3"
              size={18}
              icon="arrow-right"
            />
          </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection15;
