import React, { useEffect, useRef, useState } from "react";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Image } from "primereact/image";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Clients7 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h2 id="5304341642" className="dynamicStyle section_header">
            {data ? data["5304341642"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="1670160342"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["1670160342"] : "Lorem Ipsum"}
          />
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom ${isVisible ? "visiblebottom" : ""} `}
        >
          <div id="1182618352" className="dynamicStyle lg:mt-12 md:mt-10 mt-8">
            <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
              {data && data["1182618352"]
                ? data["1182618352"].map((e) => {
                    return (
                      <div>
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="client"
                          imageClassName="rounded-md object-contain lg:h-40 md:h-32 h-24 w-full"
                        />
                      </div>
                    );
                  })
                : "no-data"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients7;
