import React, { useEffect, useState } from "react";
import { Image } from "primereact/image";
import FeatherIcon from "feather-icons-react";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Rating from "react-rating";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";

const Testimonial5 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(
    (state) => state.testimonial.Testimonial
  );

  useEffect(() => {
    dispatch(getTestimonal());
  }, []);

  return (
    <>
      {getAllTestimonial ? (
        <div className="relative theme-bg testimonial lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
          <div className="prime_container">
            <div className="lg:w-2/3 md:w-3/4 w-full mx-auto text-center lg:mt-0 md:mt-0 mt-8">
              <h2 id="9466016868" className="dynamicStyle section_header">
                {data ? data["9466016868"] : "  CLIENT SUCCESS STORIES"}
              </h2>
              <ReactQuill
                id="4102193340"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle section_detail"
                value={data ? data["4102193340"] : "Welcome to My World"}
              />
            </div>

            <div className="lg:mt-16 md:mt-16 mt-12">
              <Swiper
                spaceBetween={15}
                slidesPerView={1.3}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 2.8,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2.4,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {getAllTestimonial?.data?.result.map((testimonial, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className="relative prime_card overflow-hidden card lg:mx-2 md:mx-2"
                        key={testimonial._id}
                      >
                        <div className="text-center mb-2">
                          <Image
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={
                              `${process.env.REACT_APP_STORAGE_URL}` +
                              `${testimonial.image}`
                            }
                            imageClassName="rounded-full roundetest cursor-pointer activeuser mx-auto object-cover"
                            alt={testimonial.name}
                          />
                        </div>
                        <div className="flex justify-center mt-4">
                          <Rating
                            initialRating={4}
                            readonly={true}
                            emptySymbol={
                              <FeatherIcon
                                icon="star"
                                className="mr-1 textColor-light"
                                size={22}
                              />
                            }
                            fullSymbol={
                              <FeatherIcon
                                icon="star"
                                className="mr-1 fillstroke"
                                size={22}
                              />
                            }
                            fractions={2}
                          />
                        </div>

                        {/* <h2 className="sm-fsize20 fsize24 font-semibold text-primary text-center mt-3">
  {`${testimonial.firstName}` +
    "\n" +
    `${testimonial.lastName}`}
</h2> */}
                        <h4 className="card_title_0 text-center mt-3">
                          {testimonial.name}
                        </h4>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Testimonial5;
