import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection12 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex items-center">
          <div className="lg:w-6/12 lg:pr-10 lg:mt-0 mt-4 lg:mb-0 mb-4">
            <p
              className="fsize16 md-fsize16 sm-fsize14 text-primary font-semibold dynamicStyle"
              id="5243023323"
            >
              {data ? data['5243023323'] : 'Lorem Ipsum'}
            </p>
            <h2 className="about_title dynamicStyle" id="4772448594">
              {data ? data['4772448594'] : 'Lorem Ipsum'}
            </h2>
            <ReactQuill
              id="9823441385"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data['9823441385'] : 'Lorem Ipsum'}
            />
          </div>
          <div
            ref={elementRef}
            className={`fade-right lg:w-6/12 lg:pl-10 ${
              isVisible ? 'visibleright' : ''
            } `}
          >
            <div id="8824589259" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['8824589259']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="ilm-img rounded-lg object-cover"
                alt="ilm"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection12;
