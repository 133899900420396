import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

const AboutSection14 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex gap-4 w-full">
          <div className="lg:w-1/2 md:w-full">
            <h2 id="2937376032" className="dynamicStyle about_title">
              {data ? data['2937376032'] : 'Achieve Your Goals With Us'}
            </h2>
            <ReactQuill
              id="7014789812"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={
                data
                  ? data['7014789812']
                  : 'It is a long establgned lock that a recost mall oe aistocted'
              }
            />

            <Button
              id="5460882958"
              className="dynamicStyle b_button_primary px-6 py-2 theme-btn sm-fsize14 lg:mt-6 md:mt-6 mt-4"
            >
              {data ? data['5460882958'] : 'Explore Course'}
            </Button>
          </div>
          <div className="lg:w-1/2 md:w-full lg:ml-20 lg:pt-0 md:pt-0 pt-10">
            <div className="flex w-full">
              <div className="lg:w-1/5 md:w-1/5 w-2/12">
                <p
                  id="6114415178"
                  className="dynamicStyle text-white flex items-center justify-center fsize18 lg:mt-2 md:mt-2 md-fsize18 sm-fsize14 text-center custpointwh sm-wh lg:mx-auto rounded-full bg-343434"
                >
                  {data ? data['6114415178'] : '1'}
                </p>
              </div>
              <div className="lg:w-full md:w-full w-10/12">
                <p
                  id="3509243031"
                  className="dynamicStyle fsize30 md-fsize20 sm-fsize20"
                >
                  {data ? data['3509243031'] : 'learn the latest skills'}
                </p>
                <ReactQuill
                  id="2772382643"
                  theme="bubble"
                  readOnly
                  className="card_description bubble dynamicStyle"
                  value={data ? data['2772382643'] : 'Lorem Ipsum'}
                />
              </div>
            </div>
            <div className="flex w-full lg:pt-8 md:pt-6 pt-4">
              <div className="lg:w-1/5 md:w-1/5 w-2/12">
                <p
                  id="8105447381"
                  className="dynamicStyle text-white flex items-center justify-center fsize18 lg:mt-2 md:mt-2 md-fsize18 sm-fsize14 text-center custpointwh sm-wh lg:mx-auto rounded-full bg-343434"
                >
                  {data ? data['8105447381'] : '2'}
                </p>
              </div>
              <div className="lg:w-full md:w-full w-10/12">
                <p
                  id="8137793069"
                  className="dynamicStyle fsize30 md-fsize20 sm-fsize20"
                >
                  {data ? data['8137793069'] : 'Get ready or a career'}
                </p>
                <ReactQuill
                  id="5598414986"
                  theme="bubble"
                  readOnly
                  className="card_description bubble dynamicStyle"
                  value={data ? data['5598414986'] : 'Lorem Ipsum'}
                />
              </div>
            </div>
            <div className="flex w-full lg:pt-8 md:pt-6 pt-4">
              <div className="lg:w-1/5 md:w-1/5 w-2/12">
                <p
                  id="8965725835"
                  className="dynamicStyle text-white flex items-center justify-center fsize18 lg:mt-2 md:mt-2 md-fsize18 sm-fsize14 text-center custpointwh sm-wh lg:mx-auto rounded-full bg-343434"
                >
                  {data ? data['8965725835'] : '3'}
                </p>
              </div>
              <div className="lg:w-full md:w-full w-10/12">
                <p
                  id="4417932954"
                  className="dynamicStyle fsize30 md-fsize20 sm-fsize20"
                >
                  {data ? data['4417932954'] : 'Earn a certificate'}
                </p>
                <ReactQuill
                  id="8434905103"
                  theme="bubble"
                  readOnly
                  className="card_description bubble dynamicStyle"
                  value={data ? data['8434905103'] : 'Lorem Ipsum'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection14;
