import { EditIcon } from '@chakra-ui/icons';
import {  Card, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { FaEdit } from 'react-icons/fa';

const LoyaltyProgram = () => {
  const { colorMode } = useColorMode();

  return (
    <div className="">
      <p
        className={
          colorMode === 'light'
            ? 'fsize20 md-fsize20 sm-fsize20 font-semibold text-black theme-textColor'
            : 'fsize20 md-fsize20 sm-fsize20 font-semibold cust-textColor theme-textColor'
        }
      >
        Loyalty Program
      </p>
      <Card
        className={
          colorMode === 'light'
            ? 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative theme-bg themeShadow'
            : 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative border-4e5765 theme-border-0 theme-bg themeShadow'
        }
      >
        <div className="w-full flex justify-between lg:items-center md:items-center items-start">
          <div className="">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize14 sm-fsize14 theme-textColor'
                  : 'fsize14 sm-fsize14 textColor-light theme-textColor'
              }
            >
              Available Points
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'fsize20 md-fsize18 sm-fsize18 font-medium text-black theme-textColor'
                  : 'fsize20 md-fsize18 sm-fsize18 font-medium cust-textColor theme-textColor'
              }
            >
              12000
            </p>
          </div>

          {/* <Button
            height="fit-content"
            py={2}
            px={4}
            fontWeight={400}
            className="bg-primary md-fsize14 sm-fsize12 sm-edit-btn clr-fff flex items-center gap-1"
          >
            <FaEdit size={14} />
            <span className="fsize14 sm-fsize12">Edit</span>
          </Button> */}
        </div>
      </Card>
      <div className="mt-6">
        <p
          className={
            colorMode === 'light'
              ? 'fsize18 md-fsize18 sm-fsize16 font-medium text-black theme-textColor'
              : 'fsize18 md-fsize18 sm-fsize16 font-medium cust-textColor theme-textColor'
          }
        >
          what is loyalty points?
        </p>
        <p className="fsize14 text-black textColor-light">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus
          sapiente, quae explicabo sequi facere expedita commodi! Nihil eveniet
          voluptatum voluptates?
        </p>
      </div>
      <div className="mt-6">
        <p
          className={
            colorMode === 'light'
              ? 'fsize18 md-fsize18 sm-fsize16 font-medium text-black theme-textColor'
              : 'fsize18 md-fsize18 sm-fsize16 font-medium cust-textColor theme-textColor'
          }
        >
          how to use?
        </p>
        <p className="fsize14 text-black textColor-light">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus
          sapiente, quae explicabo sequi facere expedita commodi! Nihil eveniet
          voluptatum voluptates?
        </p>
      </div>
      <div
        className={
          colorMode === 'light'
            ? 'mt-6 border-1px rounded-lg overflow-hidden theme-bg themeShadow'
            : 'mt-6 border-1px rounded-lg overflow-hidden theme-bg themeShadow'
        }
      >
        <div className="w-full flex ">
          <p className="cust-textColor w-1/2 px-3 py-1 border-1px fsize16 sm-fsize14 font-medium text-black">
            A
          </p>
          <p className="cust-textColor w-1/2 px-3 py-1 text-center border-1px">
            -
          </p>
        </div>
        <div className="w-full flex ">
          <p className="cust-textColor w-1/2 px-3 py-1 border-1px fsize16 sm-fsize14 font-medium text-black">
            B
          </p>
          <p className="cust-textColor w-1/2 px-3 py-1 text-center border-1px">
            -
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyProgram;
