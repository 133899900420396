import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation } from "swiper/modules";
import "swiper/css/effect-cards";
import { useDispatch } from "react-redux";
import {
  getAllBlogs,
  setSelectedBlog,
} from "../../../redux/blogs/blogs-actions";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const Blogs9 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const dispatch = useDispatch();
  let allBlog = useSelector((state) => state.BlogDetails.Blog);
  const handleBlog = async () => {
    try {
      await dispatch(getAllBlogs());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleBlog();
  }, []);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleBlogDetail = (blogId) => {
    dispatch(setSelectedBlog({}));
    navigate(`/blog detail/${blogId}`);
  };
  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="relative w-full">
          <div className="z-50 relative w-max mx-auto">
            <h5
              id="7087509258"
              className="fsize36 md-fsize32 sm-fsize28 text-center font-medium text-primary bg-bodySurface dynamicStyle"
            >
              / {data ? data["7087509258"] : "Lorem Ipsum"} /
            </h5>
          </div>
          <hr className="hr-line lg:block md:block hidden" />
        </div>
        <div className="lg:flex items-center gap-4 w-full lg:mt-12 md:mt-8 mt-6">
          <div className="lg:w-4/12 lg:pr-10 grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-6">
            {allBlog?.[0]?._id && (
              <>
                <Card
                  className="bg_none shadow-none bordbl cursor-pointer"
                  onClick={() => handleBlogDetail(allBlog?.[0]?._id)}
                >
                  <div className="">
                    <p className="card_description text-primary">Blog</p>
                    <h3 className="card_title_0 hover:underline">
                      {allBlog[0]?.title}
                    </h3>
                    <p
                      className="card_description lineClamp3 mt-1"
                      dangerouslySetInnerHTML={{ __html: allBlog[0]?.content }}
                    ></p>
                  </div>
                </Card>
              </>
            )}

            {allBlog?.[1]?._id && (
              <>
                <Card
                  className="bg_none shadow-none bordbl cursor-pointer"
                  onClick={() => handleBlogDetail(allBlog?.[1]?._id)}
                >
                  <div className="">
                    <p className="card_description text-primary">Blog</p>
                    <h3 className="card_title_0 hover:underline">
                      {allBlog[1]?.title}
                    </h3>
                    <p
                      className="card_description lineClamp3 mt-1"
                      dangerouslySetInnerHTML={{ __html: allBlog[1]?.content }}
                    ></p>
                  </div>
                </Card>
              </>
            )}
          </div>
          <div className="lg:w-4/12 md:w-7/12 w-full lg:mt-0 md:mt-6 mt-4 p-2">
            <Swiper
              effect={"cards"}
              observer={true}
              observeParents={true}
              modules={[EffectCards]}
              className="mySwiper threeD-swiper"
            >
              {allBlog
                ? allBlog?.map((item, index) => (
                    <SwiperSlide>
                      <Card
                        className={
                          index % 2 == 0
                            ? "bg-primary rounded-lg p-2 cursor-pointer"
                            : "bg-secondary rounded-lg p-2 cursor-pointer"
                        }
                        onClick={() => handleBlogDetail(item._id)}
                      >
                        <div className="overflow-hidden rounded-lg">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + item.image}
                            imageClassName="w-full h-56 object-cover rounded-lg custimghover"
                            alt="image"
                          />
                        </div>
                        <div className="p-2">
                          <div className="py-2">
                            <h4 className="card_title_0 hover:underline clr-fff lineClamp2">
                              {item.title}
                            </h4>
                            <p
                              className="lineClamp3 card_description clr-fff"
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></p>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  ))
                : "no-data"}
            </Swiper>
          </div>
          <div className="lg:w-4/12 lg:pl-10 grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-6 lg:mt-0 md:mt-6 mt-4">
            {allBlog?.[2]?._id && (
              <>
                <Card
                  className="bg_none shadow-none bordbl cursor-pointer"
                  onClick={() => handleBlogDetail(allBlog?.[2]?._id)}
                >
                  <div className="">
                    <p className="card_description text-primary ">Blog</p>
                    <h3 className="card_title_0 hover:underline">
                      {allBlog[2]?.title}
                    </h3>
                    <p
                      className="card_description lineClamp3 mt-1"
                      dangerouslySetInnerHTML={{ __html: allBlog[2]?.content }}
                    ></p>
                  </div>
                </Card>
              </>
            )}
            {allBlog?.[3]?._id && (
              <>
                <Card
                  className="bg_none shadow-none bordbl cursor-pointer"
                  onClick={() => handleBlogDetail(allBlog?.[3]?._id)}
                >
                  <div className="">
                    <p className="card_description text-primary ">Blog</p>
                    <h3 className="card_title_0 hover:underline">
                      {allBlog[3]?.title}
                    </h3>
                    <p
                      className="card_description lineClamp3 mt-1"
                      dangerouslySetInnerHTML={{ __html: allBlog[3]?.content }}
                    ></p>
                  </div>
                </Card>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs9;
