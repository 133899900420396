import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";

const Counter3 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8 relative">
      <div className="prime_container">
        <div className="header_align">
          <h4 id="8918022880" className="dynamicStyle section_header">
            {data ? data["8918022880"] : "Lorem ipsum"}
          </h4>
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 lg:flex grid md:grid-cols-3 grid-cols-2 justify-between lg:gap-20 md:gap-14 gap-8">
          <div
            className="flex gap-4 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2" id="9480943530">
              <Image
                src={data ? getImage(data["9480943530"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                imageClassName="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 font-semibold dynamicStyle"
                id="4160049299"
              >
                {data ? data["4160049299"] : "Lorem Ipsum"}
              </h6>
              <p className="section_detail_0 dynamicStyle" id="8030877766">
                {data ? data["8030877766"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>

          <div
            className="flex gap-3 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2" id="5933086110">
              <Image
                src={data ? getImage(data["5933086110"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                imageClassName="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 font-semibold dynamicStyle"
                id="1101354862"
              >
                {data ? data["1101354862"] : "Lorem Ipsum"}
              </h6>
              <p className="section_detail_0 dynamicStyle" id="3350569470">
                {data ? data["3350569470"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>

          <div
            className="flex gap-3 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2 " id="0206810605">
              <Image
                src={data ? getImage(data["0206810605"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                imageClassName="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 font-semibold dynamicStyle"
                id="5945423164"
              >
                {data ? data["5945423164"] : "Lorem Ipsum"}
              </h6>
              <p className="section_detail_0 dynamicStyle" id="1693671123">
                {data ? data["1693671123"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>

          <div
            className="flex gap-3 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2" id="8611275466">
              <Image
                src={data ? getImage(data["8611275466"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                imageClassName="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 font-semibold dynamicStyle"
                id="8603655362"
              >
                {data ? data["8603655362"] : "Lorem Ipsum"}
              </h6>
              <p className="section_detail_0 dynamicStyle" id="3173321626">
                {data ? data["3173321626"] : "Lorem Ipsum"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter3;
