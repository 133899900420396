import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const AboutSection61 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex gap-4">
          <div
            id="1663485604"
            className="dynamicStyle2 lg:w-1/4 md:w-1/4 w-full lg:mb-0 md:mb-0 mb-4"
          >
            <Image
              src={data ? getImage(data['1663485604']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              imageClassName="w-full lg:h-full md:h-full h-64 rounded-2xl object-cover"
            />
          </div>
          <div className="lg:w-3/4 md:w-3/4 w-full">
            <div className="text-right lg:w-5/6 md:w-5/6 w-full ml-auto">
              <h3 id="1573787604" className="dynamicStyle section_header">
                {data ? data['1573787604'] : 'no-data'}
              </h3>
              <ReactQuill
                id="3169842853"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail"
                value={data ? data['3169842853'] : 'Lorem Ipsum'}
              />
              <Button
                className="dynamicStyle theme-btn b_button_primary px-6 py-2 dynamicStyle fsize16 sm-fsize14 mt-6"
                id="5472697286"
              >
                <span onClick={() => handleClick('5472697286')}>
                  {data ? data['5472697286'] : 'View More'}
                </span>
              </Button>
            </div>
            <div className="lg:flex md:flex gap-4 mt-6">
              <div
                id="9002470417"
                className="dynamicStyle2 lg:w-1/4 md:w-1/4 w-full lg:mb-0 md:mb-0 mb-4"
              >
                <Image
                  src={data ? getImage(data['9002470417']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  imageClassName="w-full lg:h-72 md:h-64 h-52 rounded-2xl object-cover"
                />
              </div>
              <div id="7379789642"
                className="dynamicStyle2 lg:w-3/4 md:w-3/4 w-full">
                <Image
                  src={data ? getImage(data['7379789642']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  imageClassName="w-full lg:h-72 md:h-64 h-52 rounded-2xl object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection61;
