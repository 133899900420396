import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";

const AddBanner2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="relative application-h">
      <div className="dynamicStyle2 w-full" id="6704948690">
        <Image
          src={data ? getImage(data["6704948690"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="w-full application-h object-cover"
          alt="application"
        />
      </div>

      <div className="absolute top-0 left-0 flex items-center application-h w-full">
        <div className="lg:px-20 md:px-8 px-5">
          <div className="prime_container">
            <div className="lg:w-5/12 " data-aos="fade-up" data-aos-once="true">
              <small
                className="fsize16 sm-fsize14 text-left dynamicStyle"
                id="7698708597"
              >
                {data ? data["7698708597"] : "Lorem Ipsum"}
              </small>
              <h3 className="hero_title text-left dynamicStyle" id="3856192915">
                {data ? data["3856192915"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="1405252807"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["1405252807"] : "Lorem Ipsum"}
              />

              <div className="mt-6">
                <Button
                  className="sm-fsize14 dynamicStyle b_button_primary px-6 py-2"
                  id="2408499979"
                >
                  {data ? data["2408499979"] : "Lorem Ipsum"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner2;
