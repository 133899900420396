import React from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const ShoppingCart = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );

  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8">
        <div className="">
          <h2
            id="8022567851"
            className="dynamicStyle inline-block fsize24 sm-fsize20 font-semibold lg:mb-4 md:mb-3 mb-2 cust-textColor"
          >
            {data ? data['8022567851'] : 'Shopping'}
          </h2>
          <p className="fsize16 sm-fsize14 font-medium textColor-light">
            1 course in a cart
          </p>
          <div className="h-450px sm-h300px w-full lg:py-12 md:py-10 py-6 lg:px-0 md:px-0 px-5 border-primary mt-3 rounded-md">
            <div id="7246548807" className="dynamicStyle2 lg:w-1/2 mx-auto ">
              <Image
                src={data ? getImage(data['7246548807']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className=" h-250px sm-h-150px lg:w-1/2 w-2/3 mx-auto"
              />
            </div>
            <div className="text-center">
            <ReactQuill
                id="2010563484"
                theme="bubble"
                readOnly
                className="card_description bubble2 dynamicStyle mt-2"
                value={data ? data['2010563484'] : 'Lorem Ipsum'}
              />
              <Button
                id="5818795340"
                className="dynamicStyle b_button_primary px-6 py-2 fsize14"
              >
                <span onClick={() => handleClick('5818795340')}>
                  {data ? data['5818795340'] : 'Keep Shopping'}
                </span>
              </Button>
            </div>
          </div>

          <div className="w-full lg:flex md:flex  gap-8 mt-6">
            <div className="lg:w-2/3 md:w-2/3">
              <div
                className={
                  (colorMode === 'light'
                    ? 'rounded-lg cursor-pointer flex themeShadow w-full mb-6'
                    : 'cust-bgColor rounded-lg flex cursor-pointer w-full mb-6') +
                  ' ' +
                  (selectedStyle === 'Default' && colorMode !== 'dark'
                    ? 'cust-shadow'
                    : '')
                }
              >
                <div className="w-1/3 relative image-product overflow-hidden rounded-t">
                  <Image
                    className="img-h-170 sm-h-150px w-full opct"
                    // fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    // src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <div className="absolute left-0 top-0 flex items-center gap-1 p-2 bg-fff">
                    <div className="">
                      <p className="fsize12 textColor-light">4.5/5</p>
                    </div>
                    <div>
                      <FeatherIcon
                        className="filled-blk "
                        icon="star"
                        size={12}
                      />
                    </div>
                  </div>
                </div>

                <div className="p-4 w-2/3 flex flex-col justify-center">
                  <p className="mb-2 fsize18 text-black sm-fsize16 font-semibold cust-textColor">
                    Lorem Ipsum
                  </p>

                  <div className="mb-2 flex justify-between text-b2b2b2 textColor-light">
                    <p className="fsize14 font-medium">12 Lessons</p>
                    <p className=" fsize14  font-medium">3 hours</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                      ₹ 7850
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={
                  (colorMode === 'light'
                    ? 'rounded-lg cursor-pointer flex themeShadow w-full mb-6'
                    : 'cust-bgColor rounded-lg flex cursor-pointer w-full mb-6') +
                  ' ' +
                  (selectedStyle === 'Default' && colorMode !== 'dark'
                    ? 'cust-shadow'
                    : '')
                }
              >
                <div className="w-1/3 relative image-product overflow-hidden rounded-t">
                  <Image
                    className="img-h-170 sm-h-150px w-full opct"
                    // fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    // src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <div className="absolute left-0 top-0 flex items-center gap-1 p-2 bg-fff">
                    <div className="">
                      <p className="fsize12 textColor-light">4.5/5</p>
                    </div>
                    <div>
                      <FeatherIcon
                        className="filled-blk "
                        icon="star"
                        size={12}
                      />
                    </div>
                  </div>
                </div>

                <div className="p-4 w-2/3 flex flex-col justify-center">
                  <p className="mb-2 fsize18 text-black sm-fsize16 font-semibold cust-textColor">
                    Lorem Ipsum
                  </p>

                  <div className="mb-2 flex justify-between text-b2b2b2 textColor-light">
                    <p className="fsize14 font-medium">12 Lessons</p>
                    <p className=" fsize14  font-medium">3 hours</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                      ₹ 7850
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/3">
              <div
                className={
                  colorMode === 'light'
                    ? 'rounded-lg themeShadow w-full p-4'
                    : 'rounded-lg themeShadow w-full p-4 border-1px'
                }
              >
                <div className="flex justify-between pb-4 cust-textColor">
                  <p
                    id="7481988107"
                    className="dynamicStyle fsize16 sm-fsize14 font-medium"
                  >
                    {data ? data['7481988107'] : 'Price'}
                  </p>
                  <p className="fsize16 sm-fsize14 font-medium">₹1200/-</p>
                </div>
                <div className="flex justify-between lg:pb-20 md:pb-16 pb-12 bottom-border-ebebeb cust-textColor">
                  <p
                    id="2460514943"
                    className="dynamicStyle fsize16 sm-fsize14 font-medium"
                  >
                    {data ? data['2460514943'] : 'Discount'}
                  </p>
                  <p className="fsize16 sm-fsize14 font-medium">-500</p>
                </div>
                <div className="flex justify-between pb-3 pt-1 cust-textColor">
                  <p
                    id="7851552112"
                    className="dynamicStyle fsize16 sm-fsize14 font-medium"
                  >
                    {data ? data['7851552112'] : 'Total'}
                  </p>
                  <p className="fsize16 sm-fsize14 font-medium">700</p>
                </div>
                <Button
                  className='dynamicStyle w-full b_button_primary px-6 py-2 fsize14'
                  id="3565079636"
                >
                  <span onClick={() => handleClick('3565079636')}>
                    {data ? data['3565079636'] : 'Check Out'}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-16 md:mt-12 mt-8">
          <h2
            id="5666336261"
            className="dynamicStyle inline-block fsize24 sm-fsize20 font-semibold cust-textColor"
          >
            {data ? data['5666336261'] : 'Recently Wishlisted'}
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:pt-8 md:pt-6 pt-4 lg:pb-12 md:pb-10 pb-8">
            <div
              className={
                (colorMode === 'light'
                  ? 'rounded-lg cursor-pointer  themeShadow'
                  : 'cust-bgColor rounded-lg  cursor-pointer') +
                ' ' +
                (selectedStyle === 'Default' && colorMode !== 'dark'
                  ? 'cust-shadow'
                  : '')
              }
            >
              <div className="relative image-product overflow-hidden rounded-t">
                <Image
                  className="img-h-220 w-full opct"
                  // fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  // src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
                <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                  <div className="">
                    <p className="fsize14 textColor-light">4.5/5</p>
                  </div>
                  <div>
                    <FeatherIcon
                      className="filled-blk "
                      icon="star"
                      size={18}
                    />
                  </div>
                </div>
              </div>

              <div className="p-4">
                <div className="flex justify-between textColor-light text-b2b2b2">
                  <p className="fsize14 font-medium">12 Lessons</p>
                  <p className=" fsize14  font-medium">3 hours</p>
                </div>
                <div className="py-3">
                  <p className="fsize16 sm-fsize16 font-semibold cust-textColor">
                    Lorem Ipsum
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="fsize18 sm-fsize14 font-semibold textColor-light">
                    ₹ 7850
                  </p>
                  <div className="flex gap-4">
                    <FeatherIcon
                      className="textColor-light"
                      icon="heart"
                      size={20}
                    />
                    <FeatherIcon
                      className="textColor-light"
                      icon="shopping-cart"
                      size={20}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
