import React from "react";
import { useSelector } from "react-redux/es/exports";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Image } from "primereact/image";

const Services5 = ({ pageName }) => {
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <h3
            id="6256745993"
            className="dynamicStyle fsize16 md-fsize14 sm-fsize16 font-semibold text-primary"
          >
            {data ? data["6256745993"] : "Lorem Ipsum"}
          </h3>

          <h2 id="3967754473" className="dynamicStyle section_header">
            {data ? data["3967754473"] : "Lorem Ipsum"}
          </h2>

          <p id="4606371046" className="dynamicStyle section_detail">
            {data
              ? data["4606371046"]
              : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nihil voluptatem cupiditate"}
          </p>

          <div className="dynamicStyle lg:my-6 md:my-6 my-4" id="7444293727">
            <Button
              onClick={() => handleClick("7444293727")}
              className=" font-medium theme-btn  b_button_primary px-6 py-2 sm-fsize14 theme-btn buttonAnimate"
            >
              <span className="flex items-center">
                Explore tools and SDKs
                <FeatherIcon
                  className="text-primary ml-3 hvr-fff"
                  size={18}
                  icon="arrow-right"
                />
              </span>
            </Button>
          </div>
          <div id="2005654466" className="dynamicStyle2 lg:mt-12 md:mt-10 mt-8">
            <Image
              src={data ? getImage(data["2005654466"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              imageClassName="w-full h-450px md-h-350px sm-h-250px object-cover rounded-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services5;
