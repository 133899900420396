import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const Confirmation1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
      <div className="prime_container">
      <div>
        <div id="1356192759" className="dynamicStyle2">
          <Image
            src={data ? getImage(data['1356192759']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            imageClassName="w-96 h-60 mx-auto object-cover"
          />
        </div>
        <p
          id="2487669851"
          className="dynamicStyle fsize20 sm-fsize18 text_surface800 font-semibold lg:w-1/3 md:w-1/2 mx-auto my-6 text-center"
        >
          {data ? data['2487669851'] : 'Lorem ipsum'}
        </p>
        <div className="flex gap-2 justify-center">
          <Button
            id="9007515657"
            className="dynamicStyle theme-btn b_button_primary px-6 py-2 font-semibold"
          >
            {data ? data['9007515657'] : 'Lorem ipsum'}
          </Button>
          <Button
            id="3581676668"
            className="dynamicStyle theme-btn font-semibold px-6 py-2"
            outlined
          >
            {data ? data['3581676668'] : 'Lorem ipsum'}
          </Button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Confirmation1;
