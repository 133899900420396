import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const FAQSection2 = ({ pageName }) => {
          //section color start
          const [fileName, setFileName] = useState("");
          const [thisComponent, setThisComponent] = useState({});
          const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
          const selectedSection = useSelector((state) => state.cms.sectionNames);
          //sectioncolor end
          //sectioncolor start
          useEffect(() => {
            const name = new URL(import.meta.url);
            const filename = name.pathname.split("/").pop();
            console.log(filename);
            const componentName = filename.split(".")[0];
            setFileName(componentName);
        
            for (let i = 0; i < selectedSection[pageName].length; i++) {
              if (
                selectedSection[pageName][i].path.includes(componentName.split(".")[0])
              ) {
                setThisComponent(selectedSection[pageName][i]);
              }
            }
          }, [selectedSection]);
          //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-3/4 md:w-4/5 w-full mx-auto">
          <h3
            id="1946325846"
            className="section_header dynamicStyle text-center"
          >
            {data ? data['1946325846'] : 'Lorem Ipsum'}
          </h3>
          <div className="">
            <ReactQuill
              id="7461373914"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['7461373914'] : 'Lorem Ipsum'}
            />
          </div>
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12 dynamicStyle" id="8511723398">
          <Accordion multiple activeIndex={[0]}>
            {data && data['8511723398']
              ? data['8511723398'].map(e => {
                  return (
                    <AccordionTab
                      header={<h4 className="card_title_0"> {e.field1}</h4>}
                    >
                      <ReactQuill
                        id="7461373914"
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field2}
                      />
                    </AccordionTab>
                  );
                })
              : 'no-data'}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQSection2;
