import React, { useEffect, useState } from 'react';
import { Card, Checkbox, useColorMode } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { Image } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { FiFilter } from 'react-icons/fi';
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useDispatch } from 'react-redux';
import { getAllProducts } from '../../../../redux/ecomProduct/products-action';
import { getAllTags } from '../../../../redux/ecomTag/tags-action';
import { getAllCategorys } from '../../../../redux/ecomCategory/category-action';
import { getAllSubCategorys } from '../../../../redux/ecomSubCategory/subcategory-action';

const ProductList1 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();

  const [heartFill, setHeartFill] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleHeartFill = () => {
    setHeartFill(!heartFill);
  };
  const AllTagsData = useSelector(state => state.tags.Tags);
  console.log(AllTagsData, 'AllTagsData');

  const allEcommProduct = useSelector(state => state.products.Products);
  console.log(allEcommProduct, 'allEcommProduct');

  const AllCategoryData = useSelector(state => state.categorys.Categorys);
  console.log(AllCategoryData, 'AllCategoryData');

  const AllSubCategoryData = useSelector(
    state => state.subCategorys.SubCategorys
  );
  console.log(AllSubCategoryData, 'AllSubCategoryData');

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllTags());
    dispatch(getAllCategorys());
    dispatch(getAllSubCategorys());
  }, []);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const navigate = useNavigate();

  const handleProductOverview = () => {
    navigate('/ecommerce/product-overview');
  };

  const AddToWishlist = () => {
    navigate('/ecommerce/wishlist');
  };
  return (
    <>
      <div className="container mx-auto gap-8 lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8 lg:flex md:flex" style={{ background: thisComponent.sectionColor }}>
        <div
          className={
            colorMode === 'light'
              ? 'filtercard lg:w-1/4 md:w-1/4 h-100per mob-hidden themeShadow theme-bg'
              : 'filtercard-dark  border-1px lg:w-1/4 md:w-1/4 h-100per mob-hidden themeShadow theme-bg'
          }
        >
          <div className="w-full lg:p-3">
            <div className="flex justify-between items-center lg:pb-6 pb-4 border-b-2 ">
              <h2
                id="0642310982"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['0642310982'] : 'Filter'}
              </h2>
              <p
                id="1341777722"
                className="dynamicStyle fsize14 text-gray cust-textColor"
              >
                {data ? data['1341777722'] : 'Clear'}
              </p>
            </div>
            <div className="lg:mt-6 md:mt-4 lg:pb-6 md:pb-4 border-b-2">
              <h2
                id="9132507930"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['9132507930'] : 'Tag'}
              </h2>
              <div className="grid grid-cols-1 gap-1 mt-3">
                {AllTagsData &&
                AllTagsData.result &&
                AllTagsData.result.length > 0
                  ? AllTagsData.result.map(tags => {
                      return (
                        <div className="flex items-center gap-3 textColor-light">
                          <Checkbox>{tags.name}</Checkbox>
                        </div>
                      );
                    })
                  : 'no-tags'}
              </div>
            </div>
            <div className="lg:mt-6 md:mt-4 lg:pb-6 md:pb-4 border-b-2">
              <h2
                id="6665269978"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['6665269978'] : 'Category'}
              </h2>
              <div className="grid grid-cols-1 gap-1 mt-3">
                {AllCategoryData &&
                AllCategoryData.result &&
                AllCategoryData.result.length > 0
                  ? AllCategoryData.result.map(cat => {
                      return (
                        <div className="flex items-center gap-3 textColor-light">
                          <Checkbox>{cat.name}</Checkbox>
                        </div>
                      );
                    })
                  : 'no-category'}

                {/* <div className="flex items-center gap-3">
                  <Checkbox>Option 2</Checkbox>
                </div>
                <div className="flex items-center gap-3">
                  <Checkbox>Option 3</Checkbox>
                </div> */}
              </div>
            </div>
            <div className="lg:mt-6 mt-4">
              <h2
                id="7334503217"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['7334503217'] : 'Sub-Category'}
              </h2>
              <div className="grid grid-cols-1 gap-1 mt-3">
                {AllSubCategoryData &&
                AllSubCategoryData.result &&
                AllSubCategoryData.result.length > 0
                  ? AllSubCategoryData.result.map(sub => {
                      return (
                        <div className="flex items-center gap-3 textColor-light">
                          <Checkbox>{sub.name}</Checkbox>
                        </div>
                      );
                    })
                  : 'no-sub-category'}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-3/4 md:w-3/4">
          <div className="lg:flex md:flex justify-between items-center lg:mb-12 md:mb-12 mb-6">
            <div className="">
              <h2
                id="0440949719"
                className="dynamicStyle cust-textColor fsize16 font-medium"
              >
                {data ? data['0440949719'] : 'Explore The Awesome'}
              </h2>
              <p
                id="3850539159"
                className="dynamicStyle textColor-light text-primary fsize34 md-fsize24 sm-fsize20 font-semibold sm-pb-2"
              >
                {data ? data['3850539159'] : 'Recommended Product For You'}
              </p>
            </div>

            <div className="flex justify-between">
              <div className="lg:hidden md:hidden block filterr">
                <Menu>
                  <MenuButton as={Button} rightIcon={<FiFilter />}></MenuButton>
                  <MenuList>
                    <div className="filtercard w-30 sm-w-100 h-100per">
                      <div className="w-full p-3">
                        <div className="flex justify-between items-center pb-2 border-b-2 ">
                          <h2 className="fsize16 font-semibold">Filter</h2>
                          <p className="fsize12 text-gray">Clear</p>
                        </div>
                        <div className="mt-2 pb-2 border-b-2">
                          <h2 className="fsize16 font-semibold">Tag</h2>
                          <div className="grid grid-cols-1 gap-1 mt-2">
                            {AllTagsData &&
                            AllTagsData.result &&
                            AllTagsData.result.length > 0
                              ? AllTagsData.result.map(tags => {
                                  return (
                                    <div className="flex items-center gap-3">
                                      <Checkbox>{tags.name}</Checkbox>
                                    </div>
                                  );
                                })
                              : 'no-tags'}
                          </div>
                        </div>
                        <div className="mt-2 pb-2 border-b-2">
                          <h2 className="fsize16 font-semibold">Category</h2>
                          <div className="grid grid-cols-1 gap-1 mt-2">
                            {AllCategoryData &&
                            AllCategoryData.result &&
                            AllCategoryData.result.length > 0
                              ? AllCategoryData.result.map(cat => {
                                  return (
                                    <div className="flex items-center gap-3">
                                      <Checkbox>{cat.name}</Checkbox>
                                    </div>
                                  );
                                })
                              : 'no-category'}
                          </div>
                        </div>
                        <div className="mt-2 ">
                          <h2 className="fsize16 font-semibold">
                            Sub-Category
                          </h2>
                          <div className="grid grid-cols-1 gap-1 mt-2">
                            {AllSubCategoryData &&
                            AllSubCategoryData.result &&
                            AllSubCategoryData.result.length > 0
                              ? AllSubCategoryData.result.map(sub => {
                                  return (
                                    <div className="flex items-center gap-3">
                                      <Checkbox>{sub.name}</Checkbox>
                                    </div>
                                  );
                                })
                              : 'no-sub-category'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </MenuList>
                </Menu>
              </div>

              <select
                name=""
                id=""
                className={
                  colorMode === 'light'
                    ? 'select-Filter border fsize14 sm-fsize12 cust-bgColor cust-textColor bg-transparent'
                    : 'select-Filter border-1px fsize14 sm-fsize12 cust-bgColor cust-textColor bg-transparent'
                }

              >
                <option value="">Sort By</option>
                <option value="">Price</option>
                <option value="">Rate</option>
              </select>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-6 md:gap-6 gap-4">
            {allEcommProduct &&
            allEcommProduct.result &&
            allEcommProduct.result.length > 0
              ? allEcommProduct.result.map(item => {
                  return (
                    <Card
                      key={item._id}
                      className={
                        colorMode === 'light'
                          ? 'w-full product-card cursor-pointer'
                          : 'w-full product-card border-0 themeShadow theme-bg cursor-pointer'
                      }
                      onClick={handleProductOverview}
                    >
                      <div className="w-full relative">
                        <Image
                          src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                          alt="product-card-image"
                          className="product-card-image"
                        />
                        <div className="absolute top-4 right-4">
                          <FeatherIcon
                            icon="heart"
                            className="cursor-pointer"
                            fill={heartFill ? 'red' : 'white'}
                            color={heartFill ? 'red' : 'black'}
                            onClick={() => handleHeartFill()}
                          />
                        </div>
                      </div>
                      <div className="p-4">
                        <h2 className="cust-textColor fsize18 sm-fsize16 lineClamp1 lg:mb-1 md:mb-1 font-medium">
                          {item.name}
                        </h2>
                        <div className="flex items-center gap-2 lg:mb-2 cust-textColor">
                          <p className="fsize18 sm-fsize16 font-medium ">
                            {item.productAtt &&
                            item.productAtt.length > 0 &&
                            item.productAtt[0].discount
                              ? `${
                                  `${
                                    item.productAtt &&
                                    item.productAtt.length > 0
                                      ? item.productAtt[0].price
                                      : ''
                                  }` -
                                  (`${
                                    item.productAtt &&
                                    item.productAtt.length > 0
                                      ? item.productAtt[0].price
                                      : ''
                                  }` *
                                    `${
                                      item.productAtt &&
                                      item.productAtt.length > 0
                                        ? item.productAtt[0].discount
                                        : ''
                                    }`) /
                                    100
                                }`
                              : `${
                                  item.productAtt && item.productAtt.length > 0
                                    ? item.productAtt[0].price
                                    : ''
                                }`}
                          </p>

                          {item.productAtt &&
                          item.productAtt.length > 0 &&
                          item.productAtt[0].discount ? (
                            <div className="flex gap-2">
                              {' '}
                              <p className="fsize14 sm-fsize12 font-medium strikethrough">
                                {item.productAtt && item.productAtt.length > 0
                                  ? item.productAtt[0].price
                                  : ''}
                              </p>
                              <p className="fsize14 sm-fsize12 font-medium">
                                {item.productAtt && item.productAtt.length > 0
                                  ? item.productAtt[0].discount
                                  : ''}
                                % off
                              </p>{' '}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        {/* <div className="flex items-center gap-2 lg:mb-2 mb-1">
                          <p className="fsize14 font-medium">₹123456</p>
                          <p className="fsize14 md-fsize12 sm-fsize12 font-medium strikethrough">
                            ₹123456
                          </p>
                          <p className="fsize14 md-fsize12 sm-fsize12 font-medium">
                            (10% off)
                          </p>
                        </div> */}
                        <div className="flex items-center gap-2 textColor-light">
                          <FeatherIcon
                            icon="star"
                            className="cursor-pointer w-4 h-4"
                          />
                          <FeatherIcon
                            icon="star"
                            className="cursor-pointer w-4 h-4"
                          />
                          <FeatherIcon
                            icon="star"
                            className="cursor-pointer w-4 h-4"
                          />
                          <FeatherIcon
                            icon="star"
                            className="cursor-pointer w-4 h-4"
                          />
                          <FeatherIcon
                            icon="star"
                            className="cursor-pointer w-4 h-4"
                          />
                        </div>
                        <div className="w-full lg:pt-6 pt-4">
                          <Button
                            outlined
                            className='dynamicStyle fsize14 w-full b_button_primary px-6 py-2 theme-btn2'
                            id="5647630156"
                          >
                            {data ? data['5647630156'] : 'View Details'}
                          </Button>
                        </div>
                      </div>
                    </Card>
                  );
                })
              : 'no-data'}
            {/* <div
              className="w-full product-card cursor-pointer"
              onClick={handleProductOverview}
            >
              <div className="w-full relative">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="product-card-image"
                  className="product-card-image"
                />
                <div className="absolute top-4 right-4">
                  <FeatherIcon icon="heart" className="cursor-pointer" />
                </div>
              </div>
              <div className="p-4">
                <h2 className="fsize18 sm-fsize16 lineClamp1 lg:mb-1 md:mb-1 font-medium">
                  Apple Iphone 14 Pro
                </h2>
                <div className="flex items-center gap-2 lg:mb-2 mb-1">
                  <p className="fsize14 font-medium">₹123456</p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium strikethrough">
                    ₹123456
                  </p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium">
                    (10% off)
                  </p>
                </div>
                <div className="flex items-center gap-2 ">
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                </div>
                <div className=" w-full lg:pt-5 pt-3">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    className="dynamicStyle fsize14 w-full lg:py-6 sm-py-6"
                    id="5647630156"
                  >
                    {data ? data['5647630156'] : 'View Details'}
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="w-full product-card cursor-pointer"
              onClick={handleProductOverview}
            >
              <div className="w-full relative">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="product-card-image"
                  className="product-card-image"
                />
                <div className="absolute top-4 right-4">
                  <FeatherIcon icon="heart" className="cursor-pointer" />
                </div>
              </div>
              <div className="p-4">
                <h2 className="fsize18 sm-fsize16 lineClamp1 lg:mb-1 md:mb-1 font-medium">
                  Apple Iphone 14 Pro
                </h2>
                <div className="flex items-center gap-2 lg:mb-2 mb-1">
                  <p className="fsize14 font-medium">₹123456</p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium strikethrough">
                    ₹123456
                  </p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium">
                    (10% off)
                  </p>
                </div>
                <div className="flex items-center gap-2 ">
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                </div>
                <div className="w-full lg:pt-5 pt-3">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    className=" fsize14 w-full lg:py-6 sm-py-6"
                  >
                    View Details
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="w-full product-card cursor-pointer"
              onClick={handleProductOverview}
            >
              <div className="w-full relative">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="product-card-image"
                  className="product-card-image"
                />
                <div className="absolute top-4 right-4">
                  <FeatherIcon icon="heart" className="cursor-pointer" />
                </div>
              </div>
              <div className="p-4">
                <h2 className="fsize18 sm-fsize16 lineClamp1 lg:mb-1 md:mb-1 font-medium">
                  Apple Iphone 14 Pro
                </h2>
                <div className="flex items-center gap-2 lg:mb-2 mb-1">
                  <p className="fsize14 font-medium">₹123456</p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium strikethrough">
                    ₹123456
                  </p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium">
                    (10% off)
                  </p>
                </div>
                <div className="flex items-center gap-2 ">
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                </div>
                <div className="w-full lg:pt-5 pt-3">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    className=" fsize14 w-full lg:py-6 sm-py-6"
                  >
                    View Details
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="w-full product-card cursor-pointer"
              onClick={handleProductOverview}
            >
              <div className="w-full relative">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="product-card-image"
                  className="product-card-image"
                />
                <div className="absolute top-4 right-4">
                  <FeatherIcon icon="heart" className="cursor-pointer" />
                </div>
              </div>
              <div className="p-4">
                <h2 className="fsize18 sm-fsize16 lineClamp1 lg:mb-1 md:mb-1 font-medium">
                  Apple Iphone 14 Pro
                </h2>
                <div className="flex items-center gap-2 lg:mb-2 mb-1">
                  <p className="fsize14 font-medium">₹123456</p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium strikethrough">
                    ₹123456
                  </p>
                  <p className="fsize14 md-fsize12 sm-fsize12 font-medium">
                    (10% off)
                  </p>
                </div>
                <div className="flex items-center gap-2 ">
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                  <FeatherIcon icon="star" className="cursor-pointer w-4 h-4" />
                </div>
                <div className="w-full lg:pt-5 pt-3">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    className=" fsize14 w-full lg:py-6 sm-py-6"
                  >
                    View Details
                  </Button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList1;
