import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection16 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex sm-custdirection md-custdirection items-center lg:gap-10 md:gap-8 gap-5">
          <div className="lg:w-2/5 md:w-full ">
            <h2 id="3013252133" className="dynamicStyle hero_title">
              {data ? data["3013252133"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="4866111632"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["4866111632"] : "Lorem Ipsum"}
            />

            {/* <p id="0683311381" className="dynamicStyle ">
          {data ? data['0683311381'] : 'Lorem Ipsum'}
        </p> */}
            <div className="lg:pt-8 md:pt-6 pt-4">
              <Button
                className="dynamicStyle theme-btn b_button_primary px-6 py-2 rounded-full sm-fsize14"
                id="6574138877"
              >
                <span onClick={() => handleClick("6574138877")}>
                  {data ? data["6574138877"] : "Lorem Ipsum"}
                </span>
              </Button>
            </div>
            <div className="flex gap-5 pt-5">
              <div>
                <p
                  id="0062380520"
                  className="dynamicStyle fsize30 md-fsize20 sm-fsize20 font-semibold"
                >
                  {data ? data["0062380520"] : "1000+"}
                </p>
                <p id="6277973982" className="dynamicStyle fsize14">
                  {data ? data["6277973982"] : "Lorem"}
                </p>
              </div>
              <div>
                <p
                  id="0328230375"
                  className="dynamicStyle fsize30 md-fsize20 sm-fsize20 font-semibold"
                >
                  {data ? data["0328230375"] : "1500"}
                </p>
                <p id="0646053086" className="dynamicStyle fsize14">
                  {data ? data["0646053086"] : "Lorem"}
                </p>
              </div>
            </div>
          </div>
          <div
            ref={elementRef}
            className={`fade-right lg:w-3/5 md:w-full flex lg:gap-6 md:gap-5 gap-3 items-center px-4 ${
              isVisible ? "visibleright" : ""
            } `}
          >
            <div id="3686308470" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data["3686308470"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="hero-img"
                imageClassName="rounded-md h-300px sm-h-130px object-cover"
              />
            </div>
            <div className="lg:pt-20 md:pt-20 pt-12">
              <div id="1303924721" className="dynamicStyle2 w-full ">
                <Image
                  src={data ? getImage(data["1303924721"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="hero-img"
                  imageClassName="rounded-md h-300px sm-h-130px object-cover"
                />
              </div>
              <div id="6727535919" className="dynamicStyle2 w-full ">
                <Image
                  src={data ? getImage(data["6727535919"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="hero-img"
                  imageClassName="rounded-md mt-4 h-300px sm-h-130px object-cover"
                />
              </div>
            </div>
            <div>
              <div id="1367883275" className="dynamicStyle2 w-full">
                <Image
                  src={data ? getImage(data["1367883275"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="hero-img"
                  imageClassName="rounded-md h-300px sm-h-130px object-cover"
                />
              </div>
              <div id="4455136599" className="dynamicStyle2 w-full ">
                <Image
                  src={data ? getImage(data["4455136599"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="hero-img"
                  imageClassName="rounded-md mt-4 h-300px sm-h-130px object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection16;
