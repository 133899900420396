import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const EventCardSection1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' },
  ];

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
        <div className="lg:flex md:flex justify-between">
        {/* <div className="w-1/2 relative">
          <Input className="cust-textColor" placeholder="Search" />
          <FeatherIcon
            className="cust-textColor search-abs"
            size={20}
            icon="search"
          />
        </div>
        <div className="sm-wid-30 cust-textColor">
          <Select className=" sm-fsize14" placeholder="Select">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
        </div> */}
        <div className="lg:w-1/2 md:w-1/2">
          <div className="p-inputgroup flex-1">
            <InputText placeholder="Search" className="p-2 bg-fff" />
            <Button icon="pi pi-search" className="bg-primary" />
          </div>
        </div>
        <div className="lg:mt-0 md:mt-0 mt-2 flex justify-end">
          <Dropdown
            value={selectedCity}
            onChange={e => setSelectedCity(e.value)}
            options={cities}
            optionLabel="name"
            placeholder="Select a City"
            className="sm-fsize14 md:w-14rem bg_surface50 cust-shadow1"
          />
        </div>
      </div>

      <div ref={elementRef} className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12 ${isVisible ? 'visiblebottom' : ''} `}>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className='box-shadow-none themeShadow theme-bg '>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              imageClassName="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text_surface500 pr-2 font-medium fsize14 sm-fsize12">
                Mumbai
              </p>
              <p className="text_surface500 font-medium fsize14 sm-fsize12">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 text_surface700">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
    </div>
  );
};

export default EventCardSection1;
