import { CheckIcon, CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import {  Card, Divider, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { Fragment } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

const ManageSubscription = () => {
  const { colorMode } = useColorMode();

  return (
    <>
      <p
        className={
          colorMode === 'light'
            ? 'fsize20 md-fsize20 sm-fsize20 font-semibold text-black theme-textColor'
            : 'fsize20 md-fsize20 sm-fsize20 font-semibold cust-textColor theme-textColor'
        }
      >
        Manage Subscription
      </p>
      <Card
        className={
          colorMode === 'light'
            ? 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative theme-bg themeShadow'
            : 'mt-2 lg:py-4 md:py-4 py-3 lg:px-6 md:px-6 px-4 relative border-4e5765 theme-border-0 theme-bg themeShadow'
        }
      >
        <div className="w-full flex justify-between items-center">
          <div className="">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize14 sm-fsize14 theme-textColor'
                  : 'fsize14 sm-fsize14 textColor-light theme-textColor'
              }
            >
              Active Plan
            </p>
            <p className="fsize20 md-fsize18 sm-fsize18 font-medium text-black cust-textColor theme-textColor">
              &#8377;2000/{' '}
              <span className="fsize16 font-medium text-black cust-textColor theme-textColor">
                month
              </span>
            </p>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex">
              <p className="fsize18 md-fsize18 sm-fsize16 font-medium text-black cust-textColor theme-textColor">
                <span className="fsize12 pr-1 cust-textColor theme-textColor">
                  Valid Till{' '}
                </span>{' '}
                24 July 2024
              </p>
            </div>
            <Button
              height="fit-content"
              className='lg:mt-1 md:mt-1 b_button_primary px-6 py-2 md-fsize14 sm-fsize12 sm-edit-btn theme-btn2'
                 
            >
              Renew Plan
            </Button>
          </div>
        </div>
      </Card>
      <div className="mt-6">
        <p
          className={
            colorMode === 'light'
              ? 'fsize20 md-fsize20 sm-fsize20 font-semibold text-black theme-textColor'
              : 'fsize20 md-fsize20 sm-fsize20 font-semibold cust-textColor theme-textColor'
          }
        >
          Upgrade Subscripion
        </p>
        <div className="lg:flex md:flex w-full gap-6">
          <Card
            className={
              colorMode === 'light'
                ? 'mt-2 lg:w-1/2 md:w-1/2 w-full border overflow-hidden cursor-pointer theme-bg themeShadow'
                : 'mt-2 lg:w-1/2 md:w-1/2 w-full  overflow-hidden cursor-pointer border-4e5765 theme-border-0 theme-bg themeShadow'
            }
          >
            <div className="lg:p-4 md:p-4 p-4  cust-bgColor">
              <div className="md:mt-1 mb-3 flex justify-between items-start">
                <div className="">
                  <p className="text-primary textColor-light fsize14 sm-fsize14 font-medium ">
                    Basic Plan
                  </p>
                  <p
                    className={
                      colorMode === 'light'
                        ? 'fsize20 md-fsize18 sm-fsize16 font-medium text-black theme-textColor'
                        : 'fsize20 md-fsize18 sm-fsize16 font-medium cust-textColor theme-textColor'
                    }
                  >
                    &#8377; 2000
                  </p>
                </div>
                <Button
                  outlined
                  className='text-primary md-fsize14 px-6 py-2 sm-fsize12 sm-edit-btn border-primary theme-btn2'
                     
                >
                  Month Plan
                </Button>
              </div>
              <Divider />
              <div className="mt-3">
                <p
                  className={
                    colorMode === 'light'
                      ? 'fsize16 sm-fsize14 text-black cust-textColor font-medium theme-textColor'
                      : 'fsize16 sm-fsize14 cust-textColor font-medium theme-textColor'
                  }
                >
                  Plan Benefit's
                </p>
                <div className="my-2">
                  <div className="flex items-center gap-2">
                    <CheckIcon
                      boxSize={4}
                      className="clr-fff bg-primary p-1 rounded-full"
                    />
                    <p className="py-1 fsize14 sm-fsize12 text-black cust-textColor">
                      Lorem ipsum dolor sit.
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <CheckIcon
                      boxSize={4}
                      className="clr-fff bg-primary p-1 rounded-full"
                    />
                    <p className="py-1 fsize14 sm-fsize12 text-black cust-textColor">
                      Lorem ipsum dolor sit.
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <CloseIcon
                      boxSize={4}
                      className="clr-fff bg-secondary p-1 rounded-full"
                    />
                    <p className="py-1 fsize14 sm-fsize12 text-black textColor-light">
                      Lorem ipsum dolor sit.
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <CloseIcon
                      boxSize={4}
                      className="clr-fff bg-secondary p-1 rounded-full"
                    />
                    <p className="py-1 fsize14 sm-fsize12 text-black textColor-light">
                      Lorem ipsum dolor sit.
                    </p>
                  </div>
                </div>
                <Button
                  outlined
                  className='w-full text-primary border-primary theme-btn2 theme-textColor'
                     
                >
                  Select Plan
                </Button>
              </div>
            </div>
          </Card>
          <div className="relative lg:w-1/2 md:w-1/2 w-full">
            <Card
              className={
                colorMode === 'light'
                  ? 'overflow-hidden lg:mt-2 md:mt-2 mt-6 border cursor-pointer theme-bg themeShadow'
                  : 'overflow-hidden lg:mt-2 md:mt-2 mt-6 border cursor-pointer border-4e5765 theme-border-0 theme-bg themeShadow'
              }
            >
              <div className="lg:p-4 md:p-4 p-4 cust-bgColor">
                <div className="md:mt-1 mb-3 flex justify-between items-start">
                  <div className="">
                    <p className="text-primary textColor-light fsize14 sm-fsize14 font-medium">
                      Basic Plan
                    </p>
                    <p className="fsize20 md-fsize18 sm-fsize16 font-medium text-black cust-textColor">
                      &#8377; 2000
                    </p>
                  </div>
                  <Button
                    outlined
                    className='text-primary px-6 py-2 md-fsize14 sm-fsize12 sm-edit-btn border-primary theme-btn2'
                       
                  >
                    Month Plan
                  </Button>
                </div>
                <Divider />
                <div className="mt-3">
                  <p
                    className={
                      colorMode === 'light'
                        ? 'fsize16 sm-fsize14 text-black cust-textColor font-medium theme-textColor'
                        : 'fsize16 sm-fsize14 cust-textColor font-medium theme-textColor'
                    }
                  >
                    Plan Benefit's
                  </p>
                  <div className="my-2">
                    <div className="flex items-center gap-2">
                      <CheckIcon
                        boxSize={4}
                        className="clr-fff bg-primary p-1 rounded-full"
                      />
                      <p className="py-1 fsize14 sm-fsize12 text-black cust-textColor">
                        Lorem ipsum dolor sit.
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <CheckIcon
                        boxSize={4}
                        className="clr-fff bg-primary p-1 rounded-full"
                      />
                      <p className="py-1 fsize14 sm-fsize12 text-black cust-textColor">
                        Lorem ipsum dolor sit.
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <CloseIcon
                        boxSize={4}
                        className="clr-fff bg-secondary p-1 rounded-full"
                      />
                      <p className="py-1 fsize14 sm-fsize12 text-black textColor-light">
                        Lorem ipsum dolor sit.
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <CloseIcon
                        boxSize={4}
                        className="clr-fff bg-secondary p-1 rounded-full"
                      />
                      <p className="py-1 fsize14 sm-fsize12 text-black textColor-light">
                        Lorem ipsum dolor sit.
                      </p>
                    </div>
                  </div>
                  <Button
                    outlined
                    className='w-full text-primary border-primary theme-textColor theme-btn2'
                       
                  >
                    Select Plan
                  </Button>
                </div>
              </div>
            </Card>
            <div className="absolute left-0 top-0 mtop-minus flex justify-center w-full">
              <Button
                height="fit-content"
                className="b_button_primary px-6 py-2 fsize14"
              >
                Recommended Plan
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <p
          className={
            colorMode === 'light'
              ? 'fsize20 md-fsize18 sm-fsize18 font-semibold text-black theme-textColor'
              : 'fsize20 md-fsize18 sm-fsize18 font-semibold cust-textColor theme-textColor'
          }
        >
          Payment History
        </p>
        <TableContainer
          className={
            colorMode === 'light'
              ? 'mt-2 border-1px rounded-lg theme-bg themeShadow'
              : 'mt-2 border-1px rounded-lg theme-bg themeShadow'
          }
        >
          <Table variant="simple">
            <Thead className="bg-e6e6e6">
              <Tr>
                <Th className="fsize14 text-black">ID</Th>
                <Th className="fsize14 text-black">Plan</Th>
                <Th className="fsize14 text-black">Date</Th>
                <Th className="fsize14 text-black">Status</Th>
                <Th className="table-center fsize14 text-black">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td className="fsize14 text-black cust-textColor">1</Td>
                <Td className="fsize14 text-black cust-textColor">Basic</Td>
                <Td className="fsize14 text-black cust-textColor">
                  08-06-2024
                </Td>
                <Td className="fsize14 text-black cust-textColor">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'inline-block bg-e8f8e5 px-2 rounded-full  theme-bg themeShadow'
                        : 'inline-block bg-e8f8e5 px-2 rounded-full  theme-bg themeShadow'
                    }
                  >
                    <p className="text-success fsize12">Successfull</p>
                  </div>
                </Td>
                <Td className="fsize14 table-center cursor-pointer">
                  <DownloadIcon className="cust-textColor" />
                </Td>
              </Tr>
              <Tr>
                <Td className="fsize14 text-black cust-textColor">1</Td>
                <Td className="fsize14 text-black cust-textColor">Basic</Td>
                <Td className="fsize14 text-black cust-textColor">
                  08-06-2024
                </Td>
                <Td className="fsize14 text-black cust-textColor">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'inline-block bg-e8f8e5 px-2 rounded-full  theme-bg themeShadow'
                        : 'inline-block bg-e8f8e5 px-2 rounded-full  theme-bg themeShadow'
                    }
                  >
                    <p className="text-success fsize12">Successfull</p>
                  </div>
                </Td>
                <Td className="fsize14 table-center cursor-pointer">
                  <DownloadIcon className="cust-textColor" />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ManageSubscription;
