import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { Chip } from 'primereact/chip';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { ArrowRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getAllBlogs } from '../../../redux/blogs/blogs-actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Blogs = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);

  let allBlog = useSelector(state => state.BlogDetails.Blog);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-3/4 md:w-4/5 w-full mx-auto">
          <p
            id="4980097514"
            className="section_header dynamicStyle text-center"
          >
            {data ? data['4980097514'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="4718020350"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['4718020350'] : 'Welcome to My World'}
          />
          {/* <hr className="hr-blg" /> */}
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12">
          <Swiper
            spaceBetween={25}
            slidesPerView={3.2}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper p-2"
            autoplay={{
              delay: '1000',
            }}
            loop
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3.2,
                spaceBetween: 25,
              },
              1280: {
                slidesPerView: 3.2,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 3.1,
                spaceBetween: 25,
              },
              991: {
                slidesPerView: 2.8,
                spaceBetween: 25,
              },
              767: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            {allBlog
              ? allBlog.map(item => (
                  <SwiperSlide
                    ref={elementRef}
                    className={`fade-bottom   ${
                      isVisible ? 'visiblebottom' : ''
                    } `}
                  >
                    <Card className="cursor-pointer cust-shadow  themeShadow theme-bg">
                      <div id="" className="w-full ">
                        <div className="overflow-hidden bradius-card-top">
                          <Image
                            src={process.env.REACT_APP_STORAGE_URL + item.image}
                            imageClassName="w-full h-56 object-cover custimghover"
                            alt="image"
                          />
                        </div>
                        <div className="blog-abs text-center blg-hov">
                          <p className="fsize14 font-semibold">
                            <p className="">
                              {new Date(item.createdAt).toLocaleDateString(
                                'en-US',
                                {
                                  month: 'short',
                                  day: 'numeric',
                                }
                              )}
                            </p>
                          </p>
                        </div>
                      </div>
                      <div className="p-4">
                        <div className="flex justify-between items-center">
                          <p className="card_description">
                            {new Date(item.createdAt).toLocaleTimeString(
                              'en-US',
                              {
                                hour: '2-digit',
                                minute: '2-digit',
                              }
                            )}
                          </p>

                          <div className="">
                            <Chip
                              label={item.categoryID[0].categoryName}
                              className="fsize12 text-white bg-primary"
                            />
                          </div>
                        </div>
                        <div className="py-2">
                          <h4 className="card_title_0">
                            {item.title}
                          </h4>
                          <p
                            className="lineClamp3 card_description"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          ></p>
                        </div>
                        <div className="flex items-center cursor-pointer">
                          <p className="text-primary card_description">
                            Read More
                          </p>
                          <ArrowRight className="text-primary w-5 h-5 arrow-r ml-1" />
                        </div>
                      </div>
                    </Card>
                  </SwiperSlide>
                ))
              : 'no-data'}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
