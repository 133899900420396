import React, { useEffect, useRef, useState } from "react";
import { Input, Textarea, useColorMode } from "@chakra-ui/react";
import { Button } from 'primereact/button';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const LeadSection1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div>
      <div className="bg-darkprimary h-400px relative theme-bg">
        <div className="lg:w-3/5 md:w-3/5 w-full mx-auto pt-10 text-center px-5">
          <p
            id="6191883887"
            className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold clr-fff cust-textColor lg:pb-4 md:pb-4 pb-2 theme-textColor"
          >
            {data ? data["6191883887"] : "Lorem Ipsum"}
          </p>
          <ReactQuill
            id="5409545487"
            theme="bubble"
            readOnly
            className="card_description bubble dynamicStyle mt-2"
            value={data ? data["5409545487"] : "Lorem Ipsum"}
          />
        </div>
      </div>
      <div
        className={
          colorMode === "light"
            ? "lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6 w-11/12 mx-auto cust-shadow bg-fff lead-1 theme-bg themeShadow"
            : "lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6 w-11/12 mx-auto cust-shadow cust-bgColor lead-1 theme-bg themeShadow"
        }
      >
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 pb-6">
          <div className="">
            <p
              id="1852473560"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data["1852473560"] : "First Name"}
            </p>
            <Input
              type="text"
              placeholder="first name"
              className="theme-border"
            />
          </div>
          <div className="">
            <p
              id="0537206065"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data["0537206065"] : "Last Name"}
            </p>
            <Input
              type="text"
              placeholder="Last name"
              className="theme-border"
            />
          </div>
          <div className="">
            <p
              id="6488055022"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data["6488055022"] : "Email Id"}
            </p>
            <Input
              type="text"
              placeholder="Email Id"
              className="theme-border"
            />
          </div>
          <div className="">
            <p
              id="0633062628"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data["0633062628"] : "Phone Number"}
            </p>
            <Input
              type="text"
              placeholder="Phone Number"
              className="theme-border"
            />
          </div>
        </div>
        <div>
          <p
            id="2288978975"
            className="dynamicStyle dynamicStyle fsize12 pb-1 cust-textColor"
          >
            {data ? data["2288978975"] : "Lorem Ipsum"}
          </p>
          <Textarea
            name="description"
            id=""
            placeholder="Type here"
            className="theme-border"
          />
        </div>

        <div className="flex justify-center lg:mt-10 md:mt-10 mt-6">
          <Button
            className="dynamicStyle w-1/4 b_button_primary px-8 py-4 theme-btn sm-fsize10 "
            id="8343779127"
          >
            <span onClick={() => handleClick("8343779127")}>
              {data ? data["8343779127"] : "Submit"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeadSection1;
