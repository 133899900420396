import React, { useEffect, useRef, useState } from 'react';
import { Image, Card, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import Cardtext2 from '../../components/CardText2';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';

const EcomProductSection3 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8 container mx-auto" style={{ background: thisComponent.sectionColor }}>
      <div className="flex justify-between items-center lg:pb-12 md:pb-12 pb-8">
        <p
          id="0266448295"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 text-primary font-semibold cust-textColor"
        >
          {data ? data['0266448295'] : 'Lorem Ipsum'}
        </p>
        {/* <button className="text-white bg-black px-4 py-2">Explore All</button> */}
        <Button
          id="4380309015"
          className='dynamicStyle sm-fsize12 b_button_primary px-6 py-2 buttonAnimate theme-btn bdr-2px'
        >
          <span onClick={() => handleClick('4380309015')}>
            {data ? data['4380309015'] : 'Lorem Ipsum'}
          </span>
        </Button>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-6 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer  themeShadow theme-bg '
              : 'cursor-pointer themeShadow theme-bg '
          }
        >
          <div className="relative image-product overflow-hidden bradius-card-top">
            <Image
              className="img-h-350 md-h-300px sm-h250px w-full opct"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
            <div className="mt-3 img-dis">
              <div className="pb-2">
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-red"
                  icon="heart"
                  size={42}
                />
              </div>
              <div>
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-prmy"
                  icon="shopping-cart"
                  size={42}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between lg:py-4 md:py-4 py-2">
              <div>
                <p className="fsize20 sm-fsize16 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
              <div className="flex items-center gap-2 cust-textColor">
                <div>
                  <FeatherIcon icon="star" size={18} />
                </div>
                <div>
                  <p className="sm-fsize14 font-medium">4.5</p>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                ₹ 8600
              </p>
              <p className="fsize18 sm-fsize14 font-semibold clr-d9d9d9 textColor-light">
                <del>₹ 12000</del>
              </p>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer cust-shadow themeShadow theme-bg rounded'
              : 'cursor-pointer themeShadow theme-bg rounded'
          }
        >
          <div className="relative image-product overflow-hidden bradius-card-top">
            <Image
              className="img-h-350 md-h-300px sm-h250px w-full opct"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
            <div className="mt-3 img-dis">
              <div className="pb-2">
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-red"
                  icon="heart"
                  size={42}
                />
              </div>
              <div>
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-prmy"
                  icon="shopping-cart"
                  size={42}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between lg:py-4 md:py-4 py-2">
              <div>
                <p className="fsize20 sm-fsize16 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
              <div className="flex items-center gap-2 cust-textColor">
                <div>
                  <FeatherIcon icon="star" size={18} />
                </div>
                <div>
                  <p className="sm-fsize14 font-medium">4.5</p>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                ₹ 8600
              </p>
              <p className="fsize18 sm-fsize14 font-semibold clr-d9d9d9 textColor-light">
                <del>₹ 12000</del>
              </p>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer cust-shadow themeShadow theme-bg rounded'
              : 'cursor-pointer themeShadow theme-bg rounded'
          }
        >
          <div className="relative image-product overflow-hidden bradius-card-top">
            <Image
              className="img-h-350 md-h-300px sm-h250px w-full opct"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
            <div className="mt-3 img-dis">
              <div className="pb-2">
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-red"
                  icon="heart"
                  size={42}
                />
              </div>
              <div>
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-prmy"
                  icon="shopping-cart"
                  size={42}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between lg:py-4 md:py-4 py-2">
              <div>
                <p className="fsize20 sm-fsize16 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
              <div className="flex items-center gap-2 cust-textColor">
                <div>
                  <FeatherIcon icon="star" size={18} />
                </div>
                <div>
                  <p className="sm-fsize14 font-medium">4.5</p>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                ₹ 8600
              </p>
              <p className="fsize18 sm-fsize14 font-semibold clr-d9d9d9 textColor-light">
                <del>₹ 12000</del>
              </p>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer cust-shadow themeShadow theme-bg rounded'
              : 'cursor-pointer themeShadow theme-bg rounded'
          }
        >
          <div className="relative image-product overflow-hidden bradius-card-top">
            <Image
              className="img-h-350 md-h-300px sm-h250px w-full opct"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
            <div className="mt-3 img-dis">
              <div className="pb-2">
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-red"
                  icon="heart"
                  size={42}
                />
              </div>
              <div>
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-prmy"
                  icon="shopping-cart"
                  size={42}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between lg:py-4 md:py-4 py-2">
              <div>
                <p className="fsize20 sm-fsize16 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
              <div className="flex items-center gap-2 cust-textColor">
                <div>
                  <FeatherIcon icon="star" size={18} />
                </div>
                <div>
                  <p className="sm-fsize14 font-medium">4.5</p>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                ₹ 8600
              </p>
              <p className="fsize18 sm-fsize14 font-semibold clr-d9d9d9 textColor-light">
                <del>₹ 12000</del>
              </p>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer cust-shadow themeShadow theme-bg rounded'
              : 'cursor-pointer themeShadow theme-bg rounded'
          }
        >
          <div className="relative image-product overflow-hidden bradius-card-top">
            <Image
              className="img-h-350 md-h-300px sm-h250px w-full opct"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
            <div className="mt-3 img-dis">
              <div className="pb-2">
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-red"
                  icon="heart"
                  size={42}
                />
              </div>
              <div>
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-prmy"
                  icon="shopping-cart"
                  size={42}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between lg:py-4 md:py-4 py-2">
              <div>
                <p className="fsize20 sm-fsize16 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
              <div className="flex items-center gap-2 cust-textColor">
                <div>
                  <FeatherIcon icon="star" size={18} />
                </div>
                <div>
                  <p className="sm-fsize14 font-medium">4.5</p>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                ₹ 8600
              </p>
              <p className="fsize18 sm-fsize14 font-semibold clr-d9d9d9 textColor-light">
                <del>₹ 12000</del>
              </p>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer cust-shadow themeShadow theme-bg rounded'
              : 'cursor-pointer themeShadow theme-bg rounded'
          }
        >
          <div className="relative image-product overflow-hidden bradius-card-top">
            <Image
              className="img-h-350 md-h-300px sm-h250px w-full opct"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
            <div className="mt-3 img-dis">
              <div className="pb-2">
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-red"
                  icon="heart"
                  size={42}
                />
              </div>
              <div>
                <FeatherIcon
                  className="bg-fff rounded-full p-3 fill-prmy"
                  icon="shopping-cart"
                  size={42}
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between lg:py-4 md:py-4 py-2">
              <div>
                <p className="fsize20 sm-fsize16 font-medium cust-textColor">
                  Lorem Ipsum
                </p>
              </div>
              <div className="flex items-center gap-2 cust-textColor">
                <div>
                  <FeatherIcon icon="star" size={18} />
                </div>
                <div>
                  <p className="sm-fsize14 font-medium">4.5</p>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                ₹ 8600
              </p>
              <p className="fsize18 sm-fsize14 font-semibold clr-d9d9d9 textColor-light">
                <del>₹ 12000</del>
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EcomProductSection3;
