import { Card, Image, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';
import { useDispatch } from 'react-redux';
import {
  getAllProducts,
  addSingleProduct,
} from '../../../../redux/ecomProduct/products-action';
import { NavLink } from 'react-router-dom';

const EcomProductSection1 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  let selectedStyle = useSelector(state => state.cms.selectedTheme);
  const { colorMode, toggleColorMode } = useColorMode();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const [heartFill, setHeartFill] = useState(false);
  const handleHeartFill = () => {
    setHeartFill(!heartFill);
  };
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const link = 'https://example.com';
  const data = useSelector(state => state.cms.allSections[pageName]);
  const navigate = useNavigate();
  const handleProduct = () => {
    navigate('/ecommerce/products');
  };
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  // const handleProductOverview = () => {
  //   navigate('/ecommerce/product-overview');
  // };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProducts());
  }, []);
  let allProducts = useSelector(state => state.products.Products);

  console.log(allProducts, 'dsa');

  const selectSingleProduct = data => {
    dispatch(addSingleProduct(data));
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <>
      <div className="container mx-auto lg:py-16 lg:px-12 md:py-12 md:px-12 px-5 py-6" style={{ background: thisComponent.sectionColor }}>
        <div className="lg:flex md:flex justify-between items-center lg:mb-8 mb-2">
          <div className="">
            <h2
              id="8529125163"
              className={
                colorMode === 'light'
                  ? 'dynamicStyle fsize16 md-fsize16 sm-fsize14 cust-textColor font-medium text-primary'
                  : 'dynamicStyle fsize16 md-fsize16 sm-fsize14 cust-textColor font-medium text-white'
              }
            >
              {data ? data['8529125163'] : 'Product Range'}
            </h2>
            <h2
              className={
                colorMode === 'light'
                  ? 'dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold'
                  : 'dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold text-white'
              }
              id="4159698259"
            >
              {data ? data['4159698259'] : 'Product Recommended for you'}
            </h2>
          </div>

          <Button
            onClick={handleProduct}
            id="7857075365"
            className='dynamicStyle theme-btn2 b_button_primary px-6 py-2 sm-fsize14 font-semibold fsize20  mt-8px  bdr-2px theme-btn'
          >
            {data ? data['7857075365'] : 'Explore more'}
          </Button>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
        >
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 3.8,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 3.8,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3.8,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 3.8,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {allProducts && allProducts.result && allProducts.result.length > 0
              ? allProducts.result.map(e => {
                  return (
                    <SwiperSlide className="themeShadow theme-radius">
                      <NavLink
                        onClick={() => selectSingleProduct(e)}
                        to={{
                          pathname: `/product-overview/${e.name.replace(
                            /\s/g,
                            '-'
                          )}`,
                        }}
                      >
                        <Card
                        className={
                          colorMode === 'light'
                            ? 'w-full product-card'
                            : 'w-full theme-bg product-card border-0'
                        }
                        >
                          <div className="w-full relative cursor-pointer">
                            <Image
                              src={`${process.env.REACT_APP_STORAGE_URL}${e.image}`}
                              alt="product-card-image"
                              className="product-card-image"
                            />
                            <div className="absolute top-4 right-4">
                              <FeatherIcon
                                icon="heart"
                                className="cursor-pointer"
                                fill={heartFill ? 'red' : 'white'}
                                color={heartFill ? 'red' : 'black'}
                                onClick={() => handleHeartFill()}
                              />
                            </div>
                          </div>
                          <div className="p-4">
                            <h2 className="fsize18 sm-fsize16 lineClamp1 lg:mb-1 font-medium cust-textColor">
                              {e.name}
                            </h2>
                            <div className="flex items-center gap-2 lg:mb-2 cust-textColor">
                              <p className="fsize18 sm-fsize16 font-medium">
                                {e.productAtt &&
                                e.productAtt.length > 0 &&
                                e.productAtt[0].discount
                                  ? `${
                                      `${
                                        e.productAtt && e.productAtt.length > 0
                                          ? e.productAtt[0].price
                                          : ''
                                      }` -
                                      (`${
                                        e.productAtt && e.productAtt.length > 0
                                          ? e.productAtt[0].price
                                          : ''
                                      }` *
                                        `${
                                          e.productAtt &&
                                          e.productAtt.length > 0
                                            ? e.productAtt[0].discount
                                            : ''
                                        }`) /
                                        100
                                    }`
                                  : `${
                                      e.productAtt && e.productAtt.length > 0
                                        ? e.productAtt[0].price
                                        : ''
                                    }`}
                              </p>

                              {e.productAtt &&
                              e.productAtt.length > 0 &&
                              e.productAtt[0].discount ? (
                                <div className="flex gap-2">
                                  {' '}
                                  <p className="fsize14 sm-fsize12 font-medium strikethrough">
                                    {e.productAtt && e.productAtt.length > 0
                                      ? e.productAtt[0].price
                                      : ''}
                                  </p>
                                  <p className="fsize14 sm-fsize12 font-medium">
                                    {e.productAtt && e.productAtt.length > 0
                                      ? e.productAtt[0].discount
                                      : ''}
                                    % off
                                  </p>{' '}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="flex items-center lg:gap-2 md:gap-2 gap-1 sm-pb-2 textColor-light">
                              <FeatherIcon
                                icon="star"
                                className="cursor-pointer w-4 h-4"
                              />
                              <FeatherIcon
                                icon="star"
                                className="cursor-pointer w-4 h-4"
                              />
                              <FeatherIcon
                                icon="star"
                                className="cursor-pointer w-4 h-4"
                              />
                              <FeatherIcon
                                icon="star"
                                className="cursor-pointer w-4 h-4"
                              />
                              <FeatherIcon
                                icon="star"
                                className="cursor-pointer w-4 h-4"
                              />
                            </div>
                            <div className=" w-full lg:pt-5 md:pt-5 pt-3">
                              <Button
                                id="8987367807"
                                className='fsize14 b_button_primary px-6 py-2 w-full lg:py-4 md:py-3 py-2 dynamicStyle  bdr-2px theme-btn2'
                              >
                                <span onClick={() => handleClick('8987367807')}>
                                  {' '}
                                  {data ? data['8987367807'] : 'Add to cart'}
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </NavLink>
                    </SwiperSlide>
                  );
                })
              : 'no-data'}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default EcomProductSection1;
