import axios from "axios";
import apis from "../../apis/client";
import ceraApis from "../../apis/cera";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const getCms = (payload) => {
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };

      let response = await apis.cms.getClientCms(payload2);

      let data = response.data.data;
      let newData = {};
      let cssNewData = {};
      let classNewData = {};
      let allSections = {};
      let allLinks = {};
      let allSectionsCss = {};
      let allSectionsClass = {};
      let links = {};
      let pages = {};
      let pagesCss = {};
      let pagesClass = {};
      let filterPages = [];
      data.forEach((x) => {
        if (!filterPages.includes(x.pageName)) filterPages.push(x.pageName);
      });

      for (let j = 0; j < filterPages.length; j++) {
        allLinks = {};
        allSections = {};
        allSectionsCss = {};
        allSectionsClass = {};
        for (let i = 0; i < data.length; i++) {
          if (filterPages[j] === data[i].pageName) {
            // let interaldata = {};
            let cssData = {};
            let classData = {};
            if (!newData.hasOwnProperty(data[i].section)) {
              if (data[i].contentType === "Image") {
                allSections[data[i].fieldId] = data[i].content;
              } else if (data[i].loop.length) {
                allSections[data[i].fieldId] = data[i].loop ? data[i].loop : "";
              } else {
                allSections[data[i].fieldId] = data[i].content;
              }

              if (data[i].link) allLinks[data[i].fieldId] = data[i].link;
            }

            if (!cssNewData.hasOwnProperty(data[i].section)) {
              cssData[data[i].fieldId] = data[i].css ? data[i].css : "";
              cssNewData[data[i].section] = cssData;
            } else {
              cssData[data[i].fieldId] = data[i].css ? data[i].css : "";
              Object.assign(cssNewData[data[i].section], cssData);
            }

            if (!classData.hasOwnProperty(data[i].section)) {
              classData[data[i].fieldId] = data[i].class ? data[i].class : "";
              classNewData[data[i].section] = classData;
            } else {
              classData[data[i].fieldId] = data[i].class ? data[i].class : "";
              Object.assign(classNewData[data[i].section], classData);
            }

            if (data[i].fieldId) {
              allSectionsCss[data[i].fieldId] = data[i].css ? data[i].css : "";
            }
            if (data[i].fieldId) {
              allSectionsClass[data[i].fieldId] = data[i].class
                ? data[i].class
                : "";
            }

            pages[data[i].pageName] = allSections;
            pagesCss[data[i].pageName] = allSectionsCss;
            pagesClass[data[i].pageName] = allSectionsClass;
            links[data[i].pageName] = allLinks;
          }
        }
      }
      // console.log(links, 'linkss');
      if (allSections) dispatch(allSections_reducer(pages));
      if (allLinks) dispatch(updateLinks(links));
      if (allSections) dispatch(allSectionsCss_reducer(pagesCss));
      if (allSectionsClass) dispatch(allSectionsClass_reducer(pagesClass));
      if (newData) dispatch(getCms_reducer(newData));
      if (cssNewData) dispatch(cssCms_reducer(cssNewData));
      if (classNewData) dispatch(classCms_reducer(classNewData));
    } catch (error) {
      console.log(error);
    }
  };
};

export const createOtp = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      // console.log(payload);
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let headers = {
        Authorization: `Bearer ${payload.token}`,
      };

      data = await apis.cms.createOtp(payload2, { headers: headers });
    } catch (error) {
      console.log(error);
    }
    return data;
  };
};

export const createOtpCera = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      // console.log(payload);
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let headers = {
        Authorization: `Bearer ${payload.session}`,
      };

      data = await ceraApis.cms.createOtp(payload2, { headers: headers });
      localStorage.setItem("cms-session", data.data.data);
    } catch (error) {
      console.log(error);
    }
    return data;
  };
};

export const verifyOtpCera = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      // console.log(payload);
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let headers = {
        Authorization: `Bearer ${payload.session}`,
      };

      data = await ceraApis.cms.verifyOtp(payload2, { headers: headers });
      localStorage.setItem("cms-session", data.data.data.session);
    } catch (error) {
      console.log(error);
    }
    return data;
  };
};

export const verifySessionCera = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      // console.log(payload);
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("cms-session")}`,
      };

      data = await ceraApis.cms.verifySession(payload2, { headers: headers });
      if (data.data.data) data = data.data.data;
    } catch (error) {
      console.log(error);
    }
    return data;
  };
};

export const closeSessionCera = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      // console.log(payload);
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("cms-session")}`,
      };

      data = await ceraApis.cms.closeSession(payload2, { headers: headers });
    } catch (error) {
      console.log(error);
    }
    return data;
  };
};

export const verifyOtp = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        otp: payload.otp,
      };
      let headers = {
        Authorization: `Bearer ${payload.token}`,
      };

      data = await apis.cms.verifyOtp(payload2, { headers: headers });

      if (data.data) data = data.data;
    } catch (error) {
      console.log(error);
    }
    return data;
  };
};

export const verifySession = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("cmsToken")}`,
      };

      data = await apis.cms.verifySession(payload2, {
        headers: headers,
      });

      if (data.data) data = data.data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // console.log('popup');
        Swal.fire({
          title: "warning",
          text: "Current cms session has been expired",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2bc370",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            console.log("close");
            let token = localStorage.removeItem("cmsToken");
            let session = localStorage.removeItem("cms-session");
            window.location.href = window.location.href;
          }
        });
      }
    }
    return data;
  };
};

export const getSections = (payload) => {
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        projectId: process.env.REACT_APP_ID,
      };

      let { data } = await apis.cms.getSections(payload2);

      let sectionData = data.data;
      let section = {};
      let forms = {};

      for (let i = 0; i < sectionData.length; i++) {
        // console.log(sectionData[i]);
        section = {
          ...section,
          ...sectionData[i],
        };
      }
      Reflect.deleteProperty(section, "name");

      await dispatch(setRouteNames_reducer(section));
      await dispatch(getSection_reducer(section));
      await dispatch(setForms(forms));
      return section;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getElementById = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        elementId: payload.selectedElement,
        pageName: payload.pageName,
      };

      let { data } = await apis.cms.getClientCms(payload2);

      if (data && data.data.length) record = data.data;
      await dispatch(setSelectedData(data.data[0]));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // console.log('popup');
        Swal.fire({
          title: "warning",
          text: "Current cms session has been expired",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2bc370",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            // console.log('close');
            let token = localStorage.removeItem("cmsToken");
            let session = localStorage.removeItem("cms-session");
            window.location.href = window.location.href;
          }
        });
      }
    }
    return record;
  };
};

export const uploadImageToCloud = (payload) => {
  let data;
  return async (dispatch) => {
    try {
      let payload2 = {
        path: process.env.REACT_APP_COMMUNITY_NAME + "/cms",
        files: payload.file,
      };
      let headers = {
        "Content-Type": "multipart/form-data",
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };

      let response = await apis.fileUpload.uploader(payload2, {
        headers: headers,
      });

      if (payload.elementId) {
        let elementUpdate = {
          elementId: payload.elementId,
          content: response.data.data[0],
        };
        let elementImageUpdate = {
          elementId: payload.elementId,
          delete: true,
        };
        await dispatch(updateSections(elementUpdate));
        await dispatch(updateImage(elementImageUpdate));
      }

      data = response.data.data;
    } catch (error) {
      console.log(error);
    }
    return data;
  };
};

export const updateCms = (payload) => {
  return async (dispatch) => {
    try {
      let newPayload;
      if (payload.contentType === "Image") {
        let formData = new FormData();
        formData.set("contentImage", payload.image);
        formData.set("_id", payload._id);
        formData.set("path", payload.path);
        formData.set("projectName", payload.projectName);
        formData.set("pageName", payload.pageName);
        newPayload = formData;
      } else {
        newPayload = payload;
      }
      let headers = {
        "Content-Type": "multipart/form-data",
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };

      let { data } = await apis.cms.updateClient(newPayload, headers);
      console.log(data);

      if (data) await dispatch(getCms());
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // console.log('popup');
        Swal.fire({
          title: "warning",
          text: "Current cms session has been expired",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2bc370",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            // console.log('close');
            let token = localStorage.removeItem("cmsToken");
            let session = localStorage.removeItem("cms-session");
            window.location.href = window.location.href;
          }
        });
      }
    }
  };
};

export const getUser = (payload) => {
  return async (dispatch) => {
    try {
      let payload2 = {
        project: ["63f08ae168bd188782a32f1b"],
      };

      let response = await apis.cms.getUser(payload2);

      await dispatch(setUserData(response.data.data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const pixabaySearch = (payload) => {
  let record = {};
  return async (dispatch) => {
    try {
      let URL = `https://pixabay.com/api/?key=39578732-274d7b5c2f1a93b01f9792f95&q=${payload.search}&image_type=${payload.type}`;
      let { data } = await axios.get(URL).then(async (response) => {
        record = response.data;
      });

      // console.log(record);
    } catch (error) {
      console.log(error, "err");
    }
    return record;
  };
};

export const aiPrompt = (payload) => {
  let record = {};
  return async (dispatch) => {
    try {
      let URL = `https://api-builder-api.aiab.in/api/v1/Recommendation_v2`;
      let { data } = await axios
        .post(URL, { string: payload })
        .then(async (response) => {
          record = response.data;
        });

      // console.log(record);
    } catch (error) {
      console.log(error, "err");
    }
    return record;
  };
};

export const closeSession = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("cmsToken")}`,
      };

      const { data } = await apis.cms.closeSession(payload2, {
        headers: headers,
      });

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const changeIndex = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      Object.assign(payload, {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      });

      let headers = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let response = await apis.cms.updateIndex(payload, headers);

      await dispatch(getSections());
      record = response.data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // console.log('popup');
        Swal.fire({
          title: "warning",
          text: "Current cms session has been expired",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2bc370",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            // console.log('close');
            let token = localStorage.removeItem("cmsToken");
            let session = localStorage.removeItem("cms-session");
            window.location.href = window.location.href;
          }
        });
      }
    }
    return record;
  };
};

export const deleteSection = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      Object.assign(payload, {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      });
      let headers = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.cms.delete(payload, headers);

      await dispatch(getSections());
      record = data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // console.log('popup');
        Swal.fire({
          title: "warning",
          text: "Current cms session has been expired",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2bc370",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            // console.log('close');
            let token = localStorage.removeItem("cmsToken");
            let session = localStorage.removeItem("cms-session");
            window.location.href = window.location.href;
          }
        });
      }
    }
    return record;
  };
};

export const addSection = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      Object.assign(payload, {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      });

      let headers = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.cms.addSections(payload, headers);

      await dispatch(getSections());
      await dispatch(getCms());
      record = data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // console.log('popup');
        Swal.fire({
          title: "warning",
          text: "Current cms session has been expired",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2bc370",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            // console.log('close');
            let token = localStorage.removeItem("cmsToken");
            let session = localStorage.removeItem("cms-session");
            window.location.href = window.location.href;
          }
        });
      }
    }
    return record;
  };
};
export const updateSectionData = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      Object.assign(payload, {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      });

      let headers = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.cms.updateSectionData(payload, headers);
console.log(data,'checksection');

      await dispatch(setUpdateSection_Data(data));
      record = data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // console.log('popup');
        Swal.fire({
          title: "warning",
          text: "Current cms session has been expired",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2bc370",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            // console.log('close');
            let token = localStorage.removeItem("cmsToken");
            let session = localStorage.removeItem("cms-session");
            window.location.href = window.location.href;
          }
        });
      }
    }
    return record;
  };
};

const checkImport = async (masterSections) => {
  let data = await Promise.all(
    masterSections.map(async (x) => {
      try {
        if (x.path) {
          const module = await require(`../../pages/allSections${
            x.path.split(".")[1]
          }`);
          if (module.default !== undefined || module.default !== null) {
            return x;
          } else {
            return undefined;
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    })
  );
  return data.filter((section) => section !== undefined);
};

export const getmasterSections = (payload) => {
  let record = [];
  return async (dispatch) => {
    try {
      let data = await axios
        .post(
          `${process.env.REACT_APP_SERVICE_BASE_URL}sections/get`,
          // `https://cera-api.aiab.in/api/sections/get`,
          payload,
          {
            headers: {
              projectName: process.env.REACT_APP_COMMUNITY_NAME,
            },
          }
        )
        .then(async (response) => {
          record = await checkImport(response.data.data);
        });
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const mediaUpload = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let data = await axios
        .post(
          // `${process.env.REACT_APP_SERVICE_BASE_URL}sections/get`,
          `https://template-entry-api.aiab.in/api/v1/Media/Save`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "community-name": process.env.REACT_APP_COMMUNITY_NAME,
            },
          }
        )
        .then(async (response) => {
          record = response;
        });
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const mediaFetch = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let data = await axios
        .post(
          // `${process.env.REACT_APP_SERVICE_BASE_URL}sections/get`,
          `https://template-entry-api.aiab.in/api/v1/Media/fetch`,
          {},
          {
            headers: {
              "community-name": process.env.REACT_APP_COMMUNITY_NAME,
            },
          }
        )
        .then(async (response) => {
          record = response;
        });
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getMetaByPageName = (payload) => {
  return async (dispatch) => {
    try {
      let { data } = await apis.cms.getMetaByPageName(payload);
      // console.log(data, 'SEO DATA');
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCms_reducer = (data) => {
  return {
    type: "getcms",
    payload: data,
  };
};

export const getSection_reducer = (data) => {
  return {
    type: "getSections",
    payload: data,
  };
};

export const setRouteNames_reducer = (data) => {
  return {
    type: "setRouteNames",
    payload: data,
  };
};

export const setUpdateSection_Data = (data) => {
  return {
    type: "setUpdateSectionData",
    payload: data,
  };
};

export const allSections_reducer = (data) => {
  return {
    type: "setAllSections",
    payload: data,
  };
};
export const allSectionsCss_reducer = (data) => {
  return {
    type: "setAllSectionsCss",
    payload: data,
  };
};
export const allSectionsClass_reducer = (data) => {
  return {
    type: "setAllSectionsClass",
    payload: data,
  };
};

export const cssCms_reducer = (data) => {
  return {
    type: "csscms",
    payload: data,
  };
};

export const classCms_reducer = (data) => {
  return {
    type: "classcms",
    payload: data,
  };
};

export const setSelectedData = (data) => {
  return {
    type: "setSelectedData",
    payload: data,
  };
};

export const updateStore = (data) => {
  return {
    type: "updateStore",
    payload: data,
  };
};

export const updateSections = (data) => {
  return {
    type: "updateSections",
    payload: data,
  };
};

export const updateLinks = (data) => {
  return {
    type: "updateLinks",
    payload: data,
  };
};

export const updateSectionsCss = (data) => {
  return {
    type: "updateSectionsCss",
    payload: data,
  };
};

export const updateSectionsClass = (data) => {
  return {
    type: "updateSectionsClass",
    payload: data,
  };
};

export const updateImage = (data) => {
  return {
    type: "updateImage",
    payload: data,
  };
};

export const updateCssStore = (data) => {
  return {
    type: "updateCssStore",
    payload: data,
  };
};

export const setUserData = (data) => {
  return {
    type: "setUserData",
    payload: data,
  };
};

export const setCmsActive = (data) => {
  return {
    type: "setCmsActive",
    payload: data,
  };
};

export const setForms = (data) => {
  return {
    type: "setForms",
    payload: data,
  };
};

export const setThemeVariation = (data) => {
  // console.log(data);
  return {
    type: "setThemeDataNew",
    payload: data,
  };
};
export const setIsCMSOn = (data) => {
  // console.log(data);
  return {
    type: "SET_IS_CMS_ON",
    payload: data,
  };
};
