import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux/es/exports';

const EcomProductDetails3 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const images = [
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="w-full lg:flex md:flex gap-6">
          <div className="lg:w-full md:w-1/2">
            <ImageGallery items={images} />
          </div>
          <div className="lg:w-full md:w-1/2 lg:mt-0 md:mt-0 mt-4">
            <h2 className="section_header lg:mb-2 md:mb-2 mb-1">Apple Iphone 14 Pro</h2>

            <p className="section_detail_0">
              The iPhone 14 models come in blue, purple, midnight, starlight,
              and
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomProductDetails3;
