import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard45 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <p
            id="6784565083"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-semibold text-primary"
          >
            {data ? data['6784565083'] : 'Lorem ipsum'}
          </p>
          <p id="2027580780" className="dynamicStyle section_header">
            {data ? data['2027580780'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="3163391867"
            theme="bubble"
            readOnly
            className="bubble2 section_detail dynamicStyle"
            value={data ? data['3163391867'] : 'Welcome to My World'}
          />
        </div>
        <div
          id="6971699118"
          className="lg:mt-16 md:mt-16 mt-12 dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4"
        >
          {data && data['6971699118']
            ? data['6971699118'].map(e => {
                return (
                  <Card
                    className="prime_card_0 rounded-t-lg mt-28 relative cust-bgColor themeShadow theme-bg"
                    footer={
                      <div className="font-semibold sm-fsize14 bg-primary p-4 clr-fff text-center mt-6 bg-darkprimary themeShadow-insert themeShadow theme-bg theme-textColor">
                        {e.field3}
                      </div>
                    }
                  >
                    <div className="px-4 lg:mt-32 md:mt-28 mt-28">
                      <p className="card_title">{e.field1}</p>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble h-84px lineclamp-quill card_description"
                        value={e.field2}
                      />
                    </div>
                    {/* <div className="font-semibold sm-fsize14 bg-primary p-4 clr-fff text-center mt-6 bg-darkprimary themeShadow-insert themeShadow theme-bg theme-textColor">
                      {e.field3}
                    </div> */}
                    <div className="feat45-absl border-10px-primary rounded-full">
                      <Image
                        src={process.env.REACT_APP_STORAGE_URL + e.field4}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt={e.field1}
                        imageClassName="lg:w-48 lg:h-48 md:w-44 md:h-44 w-44 h-44 rounded-full object-cover"
                      />
                    </div>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard45;
