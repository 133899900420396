
import { Button } from 'primereact/button';
import React from 'react';
import FeatherIcon from 'feather-icons-react';

const Rejected = () => {
  return (
    <div>
      <div className="w-full pt-8">
        <div className="lg:flex md:flex items-baseline gap-2">
          <h2 className="fsize28 sm-fsize20 text-primary">Rejected Files</h2>
          <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
        </div>
        <div className="mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                  
                  <Button label="Download" icon="pi pi-download" className='b_button_primary px-6 py-2 w-full' />
                </div>
                <div className="w-full flex items-center gap-2">
                  <Button
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                    outlined
                  >
                    <FeatherIcon size="16" icon="eye" className="text-gray" />
                  </Button>
                  <Button
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                    outlined
                  >
                    <FeatherIcon
                      size="16"
                      icon="message-square"
                      className="text-gray"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                  
                  <Button label="Download" icon="pi pi-download" className='fsize14 b_button_primary px-6 py-2 w-full' />
                </div>
                <div className="w-full flex items-center gap-2">
                  <Button
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                    outlined
                  >
                    <FeatherIcon size="16" icon="eye" className="text-gray" />
                  </Button>
                  <Button
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                    outlined
                  >
                    <FeatherIcon
                      size="16"
                      icon="message-square"
                      className="text-gray"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                <Button label="Download" icon="pi pi-download" className='b_button_primary px-6 py-2 w-full' />
                </div>
                <div className="w-full flex items-center gap-2">
                  <Button
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                    outlined
                  >
                    <FeatherIcon size="16" icon="eye" className="text-gray" />
                  </Button>
                  <Button
                  
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                    outlined
                  >
                    <FeatherIcon
                      size="16"
                      icon="message-square"
                      className="text-gray"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                <Button label="Download" icon="pi pi-download" className='b_button_primary px-6 py-2 w-full' />
                </div>
                <div className="w-full flex items-center gap-2">
                  <Button
                   outlined
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                  >
                    <FeatherIcon size="16" icon="eye" className="text-gray" />
                  </Button>
                  <Button
                    outlined
                    borderRadius={30}
                    className="sm-pad6 px-6 py-2"
                  >
                    <FeatherIcon
                      size="16"
                      icon="message-square"
                      className="text-gray"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rejected;
