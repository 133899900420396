import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useSelector } from 'react-redux/es/exports';
import { Image } from 'primereact/image';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const GallerySection1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-3/4 md:w-4/5 w-full mx-auto">
          <p
            id="3971382466"
            className="dynamicStyle section_detail_0 text-center text-primary"
          >
            {data ? data['3971382466'] : 'Lorem Ipsum'}
          </p>
          <h2
            id="9869730702"
            className="section_header dynamicStyle text-center"
          >
            {data ? data['9869730702'] : 'Lorem Ipsum'}
          </h2>
          <div className="">
          <ReactQuill
            id="2639870322"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['2639870322'] : 'Lorem Ipsum'}
          />
          </div>
        </div>

        <div
          ref={elementRef}
          className={`fade-bottom grid lg:grid-cols-2 md:grid-cols-2 gap-6 lg:px-10 md:px-8 px-2 lg:mt-16 md:mt-16 mt-12 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <div id="1942932864" className="w-full dynamicStyle2">
            <Image
              src={data ? getImage(data['1942932864']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="imagee"
              imageClassName='w-full object-cover shadow-ffffff br-16px h-280px'
            />
          </div>
          <div id="2369443622" className="w-full dynamicStyle2">
            <Image
              src={data ? getImage(data['2369443622']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="imagee"
              imageClassName="w-full object-cover shadow-ffffff br-16px h-280px"
            />
          </div>
          <div
            id="4525831073"
            className="lg:col-span-2 md:col-span-2 w-full dynamicStyle2"
          >
            <Image
              src={data ? getImage(data['4525831073']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="imagee"
              imageClassName="w-full object-cover shadow-ffffff br-16px h-280px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection1;
