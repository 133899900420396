import React from 'react';
import { Facebook, Instagram } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Footer9 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  const isDarkMode = useSelector((state) => state.Loader.DarkMode);
  
  return (
    <div 
    // className="bg-fffaf0 theme-bg lg:py-10 md:py-10 py-6"
    className={isDarkMode ? "bg_surface100 lg:py-10 md:py-10 py-6" : "bg-secondary lg:py-10 md:py-10 py-6"}>
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <div className="web-hidden mob-pad">
          <Image
            src={getSecondaryImage(brandProfile)}
            alt="footerlog"
            className="w-48 cursor-pointer"
          />
        </div>
        <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2  md:gap-2 gap-2">
          
            <p className="cursor-pointer sm-fsize14 textColor-light"><Link to="/home">Home</Link></p>
        
          
            <p className="cursor-pointer sm-fsize14 textColor-light"> <Link to="/about">About Us</Link></p>
          
          
            <p className="cursor-pointer sm-fsize14 textColor-light"><Link to="/contact us">Contact Us</Link></p>
       

         
            <p className="cursor-pointer sm-fsize14 textColor-light"><Link to="/user-agreement">User Agreement</Link></p>
          
       
            <p className="cursor-pointer sm-fsize14 textColor-light"> <Link to="/privacy-policy">Privacy Policy</Link></p>
          
          
            <p className="cursor-pointer sm-fsize14 textColor-light"><Link to="/terms-conditions"> Terms & Conditions</Link></p>
          
        </div>
        <div className="flex items-center justify-between mx-auto lg:pt-10 md:pt-10 pt-8 lg:px-16 md:px-16 px-6 sm-gap-10">
          <div className="mob-hidden">
            <Image
              src={getSecondaryImage(brandProfile)}
              alt="footerlog"
              className="w-48 cursor-pointer"
            />
          </div>
          <div className="flex gap-4">
            <div className="cursor-pointer">
              <Facebook className="icon-w textColor-light" />
            </div>
            <div className="cursor-pointer">
              <Instagram className="icon-w textColor-light" />
            </div>
          </div>
          <div>
            <p id="0041059367" className="dynamicStyle sm-fsize12 textColor-light">
              {data ? data['0041059367'] : 'Lorem ipsum'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer9;
