import React, { useEffect, useState } from "react";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Testimonial12 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);

  return (
    <>
      {getAllTestimonial && (
        <div
          className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
          style={{ background: thisComponent.sectionColor }}
        >
          <div className="prime_container">
            <div className="lg:w-2/3 md:w-2/3 w-full mx-auto text-center">
              <h2
                id="0982090727"
                className="dynamicStyle section_header text-center"
              >
                {data ? data["0982090727"] : "Testimonial"}
              </h2>
              <ReactQuill
                id="7076631542"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle section_detail"
                value={data ? data["7076631542"] : "Welcome to My World"}
              />
            </div>
            <div className="lg:mt-16 md:mt-16 mt-12">
              <Swiper
                spaceBetween={15}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {getAllTestimonial?.data?.result.map((testimonial, index) => {
                  return (
                    <SwiperSlide>
                      <div
                        className="relative bg_none lg:mx-2 md:mx-2"
                        key={testimonial._id}
                      >
                        <div className="relative">
                          <div className="h-400px w-350px sm-wfull sm-h-250px">
                            <Image
                              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt="img"
                              imageClassName="h-400px w-350px sm-wfull sm-h-250px w-full object-cover"
                            />
                          </div>
                          <div className="absl-test12 lg:w-2/3 md:w-2/3 mar-test12">
                            <Card className="prime_card theme-bg themeShadow ">
                              <div className="lg:h-28 md:h-32 h-32">
                                <p className="card_description lineClamp5">
                                  {testimonial.testimonial}
                                </p>
                              </div>
                              <div className="flex items-center lg:gap-4 md:gap-4 gap-2 lg:pt-8 md:pt-8 pt-6">
                                <div className="lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14">
                                  <Image
                                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                    src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                    alt={testimonial.name}
                                    imageClassName="lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14 rounded-full object-cover bg-d9d9d9"
                                  />
                                </div>
                                <div className="">
                                  <h4 className="card_title_0">
                                    {testimonial.name}
                                  </h4>
                                  <p className="card_description">
                                    Event Planner
                                  </p>
                                </div>
                              </div>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonial12;
