// import axios from "axios";
import axios from 'axios';
import apis from '../../apis/client/Forms/form';

export const getAllForms = payload => {
  return async dispatch => {
    try {
      let payload = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };

      let { data } = await apis.getAllForms(payload);
      // console.log(data, 'checking');
      if (data) dispatch(setForms(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getFormSchema = payload => {
  let record;
  return async dispatch => {
    try {
      let { data } = await apis.getFormsSchema(payload);

      if (data) {
        record = data.data;
        dispatch(setFormsSchema(data.data));
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getSchemaByFormId = payload => {
  let record;
  return async dispatch => {
    try {
      let { data } = await apis.getSchemaByFormId(payload);

      if (data) {
        record = data.data;
        dispatch(setSchemaByFormId(data.data));
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getInputByFormId = payload => {
  let record;
  return async dispatch => {
    try {
      let { data } = await apis.getInputByFormId(payload);

      if (data) {
        record = data.data;
        dispatch(setInputByFormId(data.data));
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const setForms = data => {
  return {
    type: 'SET_FORMS',
    payload: data,
  };
};
export const setFormsSchema = data => {
  return {
    type: 'SET_FORMS_SCHEMA',
    payload: data,
  };
};

export const setInputByFormId = data => {
  return {
    type: 'SET_INPUT_BY_FORM_ID',
    payload: data,
  };
};

export const setSchemaByFormId = data => {
  return {
    type: 'SET_SCHEMA_BY_FORM_ID',
    payload: data,
  };
};

export const getData = (path, payload) => {
  let record;
  return async dispatch => {
    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}forms/get/${path}`,
        payload,
        {
          headers: {
            'community-name': process.env.REACT_APP_COMMUNITY_NAME,
          },
        }
      );
      // console.log(data, 'get');
      if (data) record = data.data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const createData = (path, payload) => {
  let record;
  return async dispatch => {
    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/forms/create_v1/${path}`,
        payload,
        {
          headers: {
            'community-name': process.env.REACT_APP_COMMUNITY_NAME,
          },
        }
      );

      if (data) record = data.data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
