import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Avatar from './Avatar';
import { debounce, isEmpty, uniqBy } from 'lodash';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import axios from 'axios';
import Contact from './Contact';
import {
  Input,
  Image,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  deleteChannel,
  getChannels,
  getMessage,
  getPeople,
  setContactLoadMore,
  setMessageLoadMore,
  upload,
} from '../../../../../redux/chat/chat-actions';
import { Oval } from 'react-loader-spinner';
import UploadBox from './UploadBox';
import { getSender } from '../chatLogic';
import { sendCustomEvent, ws } from '../../../../../redux/chat/chat-websocket';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmojiPicker from 'emoji-picker-react';
import { Paperclip, Send, Smile } from 'react-feather';
import { toast } from 'react-toastify';

const CONSTANT_CONTACT_PER_PAGE = 15;
const CONSTANT_CONTACT_PAGE = 0;

const CONSTANT_MESSAGE_PER_PAGE = 20;
const CONSTANT_MESSAGE_PAGE = 0;

const color = '#ed1b24';
const secondaryColor = 'grey';

export default function Chat() {
  const dispatch = useDispatch();
  let user = useSelector(state => state.AuthDetails.user);

  const [onlineUser, setOnlineUser] = useState([]);
  const [newMessageText, setNewMessageText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isUploader, setIsUploader] = useState(false);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [profile, setProfile] = useState(null);
  const [typing, setTyping] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  // const [channel, setChannel] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [Timeout, SetTimeout] = useState(true);
  const [chatLoading, setChatLoading] = useState(false);
  const typingRef = useRef();
  const selectedChannelData = useRef();

  const [contactHasMore, setContactHasMore] = useState(true);
  const [contactPage, setContactPage] = useState(CONSTANT_CONTACT_PAGE);
  const [contactPerPage, setContactPerPage] = useState(
    CONSTANT_CONTACT_PER_PAGE
  );

  const [messageHasMore, setMessageHasMore] = useState(true);
  const [messagePage, setMessagePage] = useState(CONSTANT_MESSAGE_PAGE);
  const [messagePerPage, setMessagePerPage] = useState(
    CONSTANT_MESSAGE_PER_PAGE
  );
  const [searchText, setSearchText] = useState('');
  const [contactLoading, setContactLoading] = useState(false);

  const divUnderMessages = useRef();

  const ContactLoadMore = useSelector(state => state.chat.ContactLoadMore);
  const MessageLoadMore = useSelector(state => state.chat.MessageLoadMore);

  useEffect(() => {
    const connectToWs = () => {
      if (ws) {
        ws.onmessage = e => {
          try {
            const eventData = JSON.parse(e.data);
            if (eventData.type === 'sendMessage') {
              handleMessage(eventData.data);
            }
            if (eventData.type === 'fetchOnline') {
              setOnlineUser(eventData.data.online);
            }
            if (eventData.type === 'typingStatus') {
              handleIsTyping(eventData.data);
            }
          } catch (error) {
            console.log(error);
          }
        };
      }
    };
    connectToWs();
  }, [MessageLoadMore, ContactLoadMore]);

  const onEmojiClick = event => {
    setNewMessageText(text => {
      typingHandler(text + event.emoji);
      return text + event.emoji;
    });
  };

  const handleIsTyping = data => {
    if (selectedChannelData.current?._id === data?.channelId) {
      setIsTyping(data?.status);
    }
  };

  const handleMessage = async messageData => {
    if (
      selectedChannelData.current?.users?.length > 0 &&
      messageData._id &&
      messageData.channelId &&
      selectedChannelData.current._id == messageData.channelId
    ) {
      if ('text' in messageData) {
        if (
          selectedChannelData.current?.users.some(
            chaUser => chaUser._id == messageData?.sender?._id
          )
        ) {
          let messages = uniqBy([messageData, ...MessageLoadMore], '_id');
          dispatch(setMessageLoadMore(messages));
        }
      }
    }
    if (messageData && messageData.channelId) {
      updateChannel(messageData.channelId, messageData);
    }
  };

  const typingHandler = msg => {
    setNewMessageText(msg);
    if (!ws) return;
    if (!typing) {
      setTyping(true);
      sendTypingData(selectedChannelData.current._id, true);
    }

    var timerLength = 500;

    if (typingRef.current) {
      clearTimeout(typingRef.current);
    }
    typingRef.current = setTimeout(() => {
      sendTypingData(selectedChannelData.current._id, false);

      setTyping(false);
    }, timerLength);
  };

  const sendTypingData = (channelIdTY, statusTY) => {
    let typingPayload = {
      channelId: channelIdTY,
      status: statusTY,
    };
    sendCustomEvent('typingStatus', typingPayload);
  };

  async function sendMessage(ev) {
    let updateMessages = MessageLoadMore;
    let updatedAt = new Date();
    let currentMessage = {};
    if (ev) ev.preventDefault();
    sendTypingData(selectedChannelData.current._id, false);
    if (file) {
      let formData = new FormData();
      formData.append('file', file);
      setNewMessageText('');
      let _id = Date.now();
      let newMessage = {
        file: filePreview,
        text: newMessageText,
        sender: user,
        channelId: selectedChannel._id,
        updatedAt,
        isLoading: true,
        _id: _id,
      };
      updateMessages.unshift(newMessage);
      setIsUploader(false);
      dispatch(setMessageLoadMore([newMessage, ...MessageLoadMore]));
      try {
        let data = await dispatch(upload(formData));
        if (data && data.code === 200) {
          setFile(null);
          setFilePreview(null);

          let findIndex = updateMessages.findIndex(x => x._id === _id);
          if (findIndex > -1) {
            updateMessages[findIndex].file = data.data;
            updateMessages[findIndex].isLoading = false;
            dispatch(setMessageLoadMore(updateMessages));
            currentMessage = newMessage;
            sendCustomEvent('sendMessage', {
              channelId: selectedChannel._id,
              updatedAt,
              text: newMessageText,
              file: data.data,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      sendCustomEvent('sendMessage', {
        channelId: selectedChannel._id,
        text: newMessageText,
        updatedAt,
        file,
      });

      setNewMessageText('');
      currentMessage = {
        text: newMessageText,
        sender: user,
        updatedAt,
        channelId: selectedChannel._id,
        _id: Date.now(),
      };
      dispatch(setMessageLoadMore([currentMessage, ...MessageLoadMore]));
    }
    if (currentMessage && selectedChannel._id) {
      updateChannel(selectedChannel._id, currentMessage);
    }
  }

  const updateChannel = (channelId, msg) => {
    let prev = ContactLoadMore;

    let channelIndex = prev.findIndex(ch => {
      return ch._id == channelId;
    });
    if (channelIndex >= 0) {
      let shiftedElement = prev.splice(channelIndex, 1)[0];
      shiftedElement.latestMessage = msg;
      return dispatch(setContactLoadMore([{ ...shiftedElement }, ...prev]));
    }
    return dispatch(setContactLoadMore(prev));
  };

  useEffect(() => {
    const div = divUnderMessages.current;
    if (div) {
      div.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [MessageLoadMore, isTyping]);

  const getChannel = async searchValue => {
    try {
      setContactLoading(true);
      let payload = {
        isActive: true,
        page: contactPage,
        limit: contactPerPage,
      };
      if (searchText) {
        Object.assign(payload, {
          search: searchText.trim(),
        });
      }

      let data = await dispatch(getChannels(payload));
      if (data && data.code === 200 && data.data && data.data.totalCount > 0) {
        dispatch(setContactLoadMore([...ContactLoadMore, ...data.data.result]));
        setContactHasMore(ContactLoadMore.length < data.data.totalCount);
        setContactPage(prev => prev + 1);
      } else {
        dispatch(setContactLoadMore([]));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setContactLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedChannel) && selectedChannel._id) {
      getMessages(selectedChannel);
    }
  }, [selectedChannel]);

  const resetMessage = () => {
    setMessagePage(CONSTANT_MESSAGE_PAGE);
    setMessageHasMore(true);
    dispatch(setMessageLoadMore([]));
  };

  const getMessages = async selectedChannel => {
    try {
      setChatLoading(true);
      let payload = {
        channelId: selectedChannel._id,
        page: messagePage,
        limit: messagePerPage,
      };
      let data = await dispatch(getMessage(payload));
      if (data && data.code === 200 && data.data && data.data.totalCount > 0) {
        dispatch(
          setMessageLoadMore(
            uniqBy([...MessageLoadMore, ...data.data.result], '_id')
          )
        );
        setMessageHasMore(MessageLoadMore.length < data.data.totalCount);
        if (CONSTANT_MESSAGE_PER_PAGE < data.data.totalCount) {
          setMessagePage(prev => prev + 1);
        }
      } else {
        dispatch(setMessageLoadMore([]));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChatLoading(false);
    }
  };

  function getImage(image) {
    if ((image && image.includes('blob:')) || image.includes(';base64')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const handleOnline = senderUser => {
    return onlineUser.includes(senderUser._id);
  };

  useEffect(() => {
    if (profile && profile.senderUser && profile.senderUser._id) {
      setProfile(prev => {
        let isOnline = handleOnline(prev.senderUser);
        return {
          ...prev,
          online: isOnline,
        };
      });
    }
  }, [onlineUser]);

  const handleContact = (senderUser, cha) => {
    if (
      isEmpty(senderUser) ||
      isEmpty(cha) ||
      cha?._id === selectedChannelData?.current?._id
    ) {
      return;
    }
    sendCustomEvent('fetchOnline', 'online');
    setProfile({
      senderUser: senderUser,
      channel: cha,
      online: false,
    });
    resetMessage();
    setSelectedChannel(cha);
    selectedChannelData.current = cha;
  };

  const getName = user => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    } else if (user?.firstName && !user?.lastName) {
      return user?.firstName;
    } else if (user?.name) {
      return user.name;
    }
    return 'Unknown';
  };

  const searchChat = value => {
    setSearchText(value);
    setContactLoading(true);
    setContactPage(CONSTANT_CONTACT_PAGE);
    setContactHasMore(true);
    dispatch(setContactLoadMore([]));
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(setContactLoadMore([]));
      dispatch(setMessageLoadMore([]));
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.addEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    const delayDebouncedSearch = setTimeout(() => {
      getChannel();
    }, 500);

    return () => {
      clearTimeout(delayDebouncedSearch);
      dispatch(setContactLoadMore([]));
    };
  }, [searchText]);

  const handleDeleteChannel = async channel => {
    try {
      let payload = {
        channelId: channel?._id,
      };
      const data = await dispatch(deleteChannel(payload));
      if (data?.code === 200) {
        resetMessage();
        setSelectedChannel();
        selectedChannelData.current = {};
        toast.success('Channel deleted successfully');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uniqByMessage = uniqBy(MessageLoadMore, '_id');

  return (
    <div className="flex h-screen parentChat-scroll bg-f4f5f6">
      <div className=" w-1/3 flex flex-col ">
        <div className="px-4 py-4">
          <h2 className="font-semibold mb-2 fsize20 mb  ">Chats </h2>
          <Input
            type="text"
            value={searchText}
            onChange={e => searchChat(e.target.value)}
            placeholder="Search..."
          />
        </div>
        <div className="flex justify-center items-center h-full">
          {ContactLoadMore.length <= 0 && !contactLoading ? (
            <div>No Chat Found</div>
          ) : (
            <div
              id="contactScrollableTarget"
              style={{ height: 460, overflow: 'auto', width: '100%' }}
            >
              <InfiniteScroll
                dataLength={ContactLoadMore.length || 0}
                next={getChannel}
                hasMore={contactHasMore}
                scrollThreshold={'500px'}
                loader={
                  <div className="flex justify-center">
                    <Oval
                      visible={true}
                      color={color}
                      secondaryColor={secondaryColor}
                      strokeWidth={5}
                      width="30"
                    />
                  </div>
                }
                endMessage={
                  <p className="text-center fsize12">
                    Yay! You have seen it all
                  </p>
                }
                scrollableTarget={'contactScrollableTarget'}
                className="px-4"
              >
                {ContactLoadMore &&
                  ContactLoadMore.length > 0 &&
                  ContactLoadMore.map(cha => {
                    let senderUser = getSender(user, cha.users);

                    return (
                      <div className="relative flex justify-between">
                        <Contact
                          key={cha._id}
                          user={senderUser}
                          channel={cha}
                          isTyping={isTyping}
                          selectedChannel={selectedChannel}
                          loggedInUser={user}
                          onClick={() => handleContact(senderUser, cha)}
                          selected={cha?._id === selectedChannel?._id}
                        />
                        <div className="absolute px-1 top-0 right-0">
                          <Menu width="30">
                            <MenuButton
                              px={0}
                              py={2}
                              transition="all 0.2s"
                              borderRadius="md"
                              borderWidth="0px"
                            >
                              <FeatherIcon size="20" icon="more-vertical" />
                            </MenuButton>
                            <MenuList>
                              {/* {cha?.isGroupChat === false && ( */}
                              <MenuItem
                                onClick={() => handleDeleteChannel(cha)}
                              >
                                Delete
                              </MenuItem>
                              {/* )} */}
                            </MenuList>
                          </Menu>
                        </div>
                      </div>
                    );
                  })}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col  relative w-2/3 px-2 py-6">
        <div className="flex-grow">
          {isEmpty(selectedChannel) && (
            <div className="flex h-full flex-grow items-center justify-center">
              <div className="text-gray-300">
                &larr; Select a person from the sidebar
              </div>
            </div>
          )}
          {!isEmpty(selectedChannel) && (
            <>
              {!!profile && (
                <div className="bg-secondary rounded-tl-xl rounded-tr-lg py-2 px-4">
                  <div className="flex gap-4 items-center">
                    <Avatar
                      online={
                        profile.channel && profile.channel.isGroupChat
                          ? undefined
                          : profile.online
                      }
                      user={profile.senderUser}
                      channel={profile.channel}
                    />
                    <div>
                      <h2 className="fsize14 font-semibold text-white">
                        {!profile?.channel?.isGroupChat
                          ? getName(profile?.senderUser)
                          : profile?.channel?.channelName}
                      </h2>
                      <p className="text-white fsize12">
                        {!profile?.channel?.isGroupChat
                          ? profile.online
                            ? 'Online'
                            : 'Offline'
                          : ''}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* {chatLoading ? (
                <>
                  <div className="flex justify-center h-full items-center">
                   <Oval
                      visible={true}
                      color={color}
                      secondaryColor={secondaryColor}
                      width="40"
                    />
                  </div>
                </>
              ) : */}

              <div className="relative bg-ddd px-4 chat-textscroll">
                {!isUploader ? (
                  <>
                    {uniqByMessage &&
                    uniqByMessage.length <= 0 &&
                    !chatLoading ? (
                      <>
                        <div className="flex justify-center items-center h-full">
                          No Message Found
                        </div>
                        <div>
                          {isTyping && (
                            <div className="chat-bubble absolute -bottom-4 left-2">
                              <div className="typing">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div ref={divUnderMessages}></div>
                      </>
                    ) : (
                      <>
                        <div
                          id="messageScrollableTarget"
                          className="overflow-y-scroll flex flex-col-reverse  pl-2 absolute chat-textscroll top-0 left-0 right-0 bottom-2"
                        >
                          <div ref={divUnderMessages}></div>
                          <div>
                            {isTyping && (
                              <div className="chat-bubble">
                                <div className="typing">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              </div>
                            )}
                          </div>

                          <InfiniteScroll
                            inverse={true}
                            style={{
                              display: 'flex',
                              flexDirection: 'column-reverse',
                            }}
                            dataLength={uniqByMessage.length || 0}
                            next={() => {
                              getMessages(selectedChannel);
                            }}
                            hasMore={messageHasMore}
                            scrollThreshold={'500px'}
                            loader={
                              <div className="flex justify-center">
                                <Oval
                                  visible={true}
                                  color={color}
                                  secondaryColor={secondaryColor}
                                  strokeWidth={5}
                                  width="30"
                                />
                              </div>
                            }
                            endMessage={
                              <p className="text-center fsize12">
                                Yay! You have seen it all
                              </p>
                            }
                            scrollableTarget={'messageScrollableTarget'}
                          >
                            {uniqByMessage?.length > 0 &&
                              uniqByMessage.map(message => (
                                <div
                                  key={message._id}
                                  className={
                                    message?.sender?._id === user._id
                                      ? 'text-right'
                                      : 'text-left'
                                  }
                                >
                                  <div
                                    className={
                                      'text-left inline-block px-4 py-2 my-2 rounded-md text-sm ' +
                                      (message?.sender?._id === user._id
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-white text-gray-500') +
                                      (message.file ? ' w-1/2' : '')
                                    }
                                  >
                                    {selectedChannel?.isGroupChat &&
                                      message?.sender?._id !== user._id && (
                                        <div className="flex gap-2 mb-2">
                                          <Avatar
                                            user={message?.sender}
                                            channel={selectedChannel}
                                            isGroupMessage={true}
                                          />
                                          <p>{getName(message?.sender)}</p>
                                        </div>
                                      )}
                                    {message.text}
                                    {message.file && (
                                      <div className="relative">
                                        <Image
                                          src={getImage(message.file)}
                                          className="h-full w-full object-contain"
                                        />
                                        {message.isLoading && (
                                          <div className="absolute top-0  left-0 h-40 w-full overlays flex items-center justify-center">
                                            <Oval
                                              visible={true}
                                              color={color}
                                              secondaryColor={secondaryColor}
                                              strokeWidth={5}
                                              width="25"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <div className="text-right">
                                      <sub className="pt-4">
                                        {new Date(
                                          message.updatedAt
                                        ).toLocaleTimeString([], {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })}
                                      </sub>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <UploadBox
                    closeUploader={value => {
                      setIsUploader(value);
                    }}
                    selectedFile={file => {
                      setFile(file);
                    }}
                    selectedfilePreview={preview => {
                      setFilePreview(preview);
                    }}
                    setChatFile={setFile}
                    setChatFilePreview={setFilePreview}
                  />
                )}
              </div>
              {/* } */}
            </>
          )}
        </div>
        {!isEmpty(selectedChannel) && (
          <form className="flex gap-2  p-4 w-full" onSubmit={sendMessage}>
            <input
              type="text"
              value={newMessageText}
              onChange={ev => typingHandler(ev.target.value)}
              placeholder="Type your message here"
              className="bg-white flex-grow border rounded-sm p-2"
            />

            <Popover>
              <PopoverTrigger>
                <Button className="bg-blue200 p-2 text-gray-600 rounded-sm border border-blue-200">
                  <Smile className="clr-bbb8b7" />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <EmojiPicker
                  onEmojiClick={e => {
                    onEmojiClick(e);
                  }}
                />
              </PopoverContent>
            </Popover>

            {!isUploader && (
              <label
                className="bg-blue-200 p-2 text-gray-600 cursor-pointer rounded-sm border border-blue-200"
                onClick={() => setIsUploader(true)}
              >
                <Paperclip className="clr-bbb8b7" />
              </label>
            )}

            <button
              disabled={!(newMessageText.length > 0 || file)}
              type="submit"
              className="bg-blue-500 p-2 text-white rounded-sm"
            >
              <Send className="clr-bbb8b7" />
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
