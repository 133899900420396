import React, { useEffect, useRef, useState } from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { getAllElearningCategory } from '../../../../redux/elearningProduct/products-action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';

const ElearnProductCategory1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllElearningCategory());
  }, [dispatch]);

  let allCategory = useSelector(
    state => state.elearningProduct.elearningCategory
  );
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, 'allcat');
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  // const Categories = [
  //   {
  //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //     title: 'hfjh',
  //   },
  //   {
  //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //     title: 'hfjh',
  //   },
  //   {
  //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //     title: 'hfjh',
  //   },
  //   {
  //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //     title: 'hfjh',
  //   },
  //   {
  //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //     title: 'hfjh',
  //   },
  //   {
  //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //     title: 'hfjh',
  //   },
  //   {
  //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //     title: 'hfjh',
  //   },
  // ];
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div
      className={
        (colorMode === 'light' ? 'bg-f8f8f8' : '') +
        ' ' +
        (selectedStyle && colorMode !== 'dark' ? 'theme-bg' : '')
      }
    >
      <div className="container mx-auto lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
          <h2
            id="8803931303"
            className="dynamicStyle pb-2 text-primary fsize34 md-fsize24 sm-fsize20 text-center font-semibold cust-textColor"
          >
            {data ? data['8803931303'] : 'Popular Categories'}
          </h2>
          <p
            id="6008948217"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-center textColor-light"
          >
            {data
              ? data['6008948217']
              : 'Keep up with the Topics and Trends you care about the most'}
          </p>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom lg:py-8 md:py-8 py-4 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
              820: {
                slidesPerView: 3.8,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 3.8,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1.5,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.5,
                spaceBetween: 15,
              },
              325: {
                slidesPerView: 1.5,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>

            {allCategory && allCategory.result && allCategory.result.length > 0
              ? allCategory.result.map(item => {
                  return (
                    <SwiperSlide>
                      <div
                        className={
                          (colorMode === 'light'
                            ? 'rounded-lg py-6 themeShadow  themeShadow-insert theme-radius cursor-pointer'
                            : 'cust-bgColor rounded-lg py-6 cursor-pointer') +
                          ' ' +
                          (selectedStyle === 'Default' && colorMode !== 'dark'
                            ? 'border-dark'
                            : '')
                        }
                      >
                        <Image
                          src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                          alt="img"
                          className="rounded-sm w-16 h-16  m-auto object-contain"
                        />
                        <p
                          className={
                            colorMode === 'light'
                              ? 'text-center sm-fsize14 text-primary pt-4'
                              : 'text-center sm-fsize14 text-white pt-4'
                          }
                        >
                          {item.name}
                        </p>
                      </div>
                    </SwiperSlide>
                  );
                })
              : 'no-data'}
          </Swiper>
        </div>
        <div className=" flex justify-center">
          <Button
            className={
              selectedStyle
                ? 'dynamicStyle b_button_primary px-6 py-2 rounded-full text-white theme-btn cursor-pointer fsize16 sm-fsize14 bdr-2px'
                : 'dynamicStyle b_button_primary px-6 py-2 rounded-full text-white cursor-pointer fsize16 sm-fsize14 buttonAnimate bdr-2px'
            }
            id="5445323610"
          >
            <span onClick={() => handleClick('5445323610')}>
              {data ? data['5445323610'] : 'View All'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ElearnProductCategory1;
