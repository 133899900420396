import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard39 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 className="section_header dynamicStyle" id="9946059517">
              {data ? data['9946059517'] : 'Lorem Ipsum'}
            </h2>
            <ReactQuill
              id="3094702170"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle  section_detail"
              value={data ? data['3094702170'] : 'Welcome to My World'}
            />
          </div>

          <div
            className="lg:mt-28 md:mt-24 mt-20 prime_card_container lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid lg:gap-4 gap-12 dynamicStyle"
            id="1761594813"
          >
            {data && data['1761594813']
              ? data['1761594813'].map((e, index) => {
                  return (
                    <div className="relative">
                      <Card className="prime_card">
                        <div className="lg:mt-16 md:mt-16 mt-12">
                          <h4 className=" lineClamp2 card_title">{e.field2}</h4>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble card_description"
                            value={e.field3}
                          />
                        </div>
                      </Card>
                      <div className="feature2-absminus">
                        <Image
                          src={process.env.REACT_APP_STORAGE_URL + e.field1}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          imageClassName="w-20 h-20 rounded-full object-contain"
                          alt="cards"
                        />
                      </div>
                    </div>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard39;
