import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup'; //Optional for grouping

const AboutSection50 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="relative h-ab52" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:px-20 md:px-8 px-5 flex items-center h-ab52">
        <div className="prime_container w-full">
          <div className="w-full lg:flex md:flex block lg:pr-0 md:pr-8 pr-0">
            <div className="lg:w-1/2 md:w-1/2 flex items-center">
              <div className="lg:px-10 md:px-8 px-0 lg:py-4 md:py-4 py-0">
                <p
                  id="2583013637"
                  className="dynamicStyle fsize16 md-fsize16 sm-fsize16 text-primary w-max bordb-primary2"
                >
                  {' '}
                  {data ? data['2583013637'] : 'no-data'}
                </p>
                <h5 id="9941856351" className="dynamicStyle about_title">
                  {data ? data['9941856351'] : 'no-data'}
                </h5>
                <ReactQuill
                  id="6044293955"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description mb-6"
                  value={data ? data['6044293955'] : 'Lorem Ipsum'}
                />

                <div className="flex items-center gap-4">
                  <Button
                    className="theme-btn dynamicStyle b_button_primary px-6 py-2 fsize14 sm-fsize14"
                    id="0402451134"
                  >
                    <span onClick={() => handleClick('0402451134')}>
                      {data ? data['0402451134'] : 'View More'}
                    </span>
                  </Button>
                  <Button
                    outlined
                    className="theme-btn border-primary dynamicStyle text-primary px-6 py-2 fsize14 sm-fsize14"
                    id="2498232226"
                  >
                    <span onClick={() => handleClick('2498232226')}>
                      {data ? data['2498232226'] : 'View More'}
                    </span>
                  </Button>
                </div>
                <div className="flex items-center gap-4 lg:mt-8 md:mt-8 mt-6">
                  <AvatarGroup>
                    <Avatar
                      name="Ryan Florence"
                      image="https://bit.ly/ryan-florence"
                      shape="circle"
                    />
                    <Avatar
                      name="Segun Adebayo"
                      image="https://bit.ly/sage-adebayo"
                      shape="circle"
                    />
                    <Avatar
                      name="Kent Dodds"
                      image="https://bit.ly/kent-c-dodds"
                      shape="circle"
                    />
                    <Avatar
                      name="Prosper Otemuyiwa"
                      image="https://bit.ly/prosper-baba"
                      shape="circle"
                    />
                    <Avatar
                      name="Christian Nwamba"
                      image="https://bit.ly/code-beast"
                      shape="circle"
                    />
                  </AvatarGroup>
                  <p
                    id="6743386141"
                    className="dynamicStyle fsize14 text-primary"
                  >
                    {data ? data['6743386141'] : 'no-data'}
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0 mt-8">
              <div id="9940799458" className="dynamicStyle2 w-full">
                <Image
                  src={data ? getImage(data['9940799458']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img-about"
                  imageClassName="w-full lg:h-96 md:h-80 h-40 object-cover relative lg:z-50 md:z-20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute lg:block md:block hidden top-0 right-0 h-full lg:w-3/12 md:w-3/12 bg-light-primary"></div>
    </div>
  );
};

export default AboutSection50;
