import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const EcomProductSection2 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const { colorMode, toggleColorMode } = useColorMode();
  const [check, SetCheck] = useState(1);
  const memeber = [
    { id: 1, name: 'All' },
    { id: 2, name: 'Programing' },
    { id: 3, name: 'Development' },
    { id: 4, name: 'Design' },
    { id: 5, name: 'Application' },
  ];
  let AllProduct = useSelector(state => state.products.Products);
  console.log(AllProduct.result, 'ProductDatas');
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    Aos.init();
  }, []);

  const [singleProductData, setSingleProductData] = useState({});

  const {
    isOpen: isopenprofile1,
    onOpen: onopenprofile1,
    onClose: oncloseprofile1,
  } = useDisclosure();

  const handlePopUp = product => {
    setSingleProductData(product);
    onopenprofile1();
  };

  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8" style={{ background: thisComponent.sectionColor }}>
      <Modal size="4xl" isOpen={isopenprofile1} onClose={oncloseprofile1}>
        <ModalOverlay />
        <ModalContent className="custom custom-marl">
          <ModalCloseButton className="textColor-light" />
          <div class="gap-4 lg:items-center wordf lg:pt-12 md:pt-12 pt-12 pb-6 lg:px-8 md:px-4 px-4">
            <div class="">
              <img
                class="w-full lg:h-60 md:h-52 rounded-lg object-cover"
                src={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="man"
              />
            </div>
            <div className="lg:mt-5 md:mt-3 mt-2 lg:flex md:flex items-start">
              <div className="lg:w-8/12 md:w-8/12 lg:mr-4">
                <h2 class="fsize22 md-fsize20 sm-fsize18 lg:my-1 font-bold text-black cust-textColor">
                  {singleProductData.name}
                </h2>
                <p class="fsize15 md-fsize14 textColor-light sm-fsize14 text-black font-semibold mt-1 lg:mt-2 lg:leading-7 md:leading-7 leading-6">
                  {singleProductData.description}
                </p>
              </div>
              <Card
                className={
                  colorMode === 'light'
                    ? 'lg:w-4/12 md:w-4/12 bg-fa lg:p-3 md:p-3 lg:mt-0 md:mt-0 mt-4 grid grid-cols-1 gap-3 box-shadow-none'
                    : 'border-1px lg:w-4/12 md:w-4/12 lg:p-3 md:p-3 lg:mt-0 md:mt-0 mt-4 grid grid-cols-1 gap-3'
                }
              >
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">
                    Category:
                  </p>
                  <p className="font-semibold text-dark fsize16 textColor-light">
                    {singleProductData.category &&
                      singleProductData.category.length > 0 &&
                      singleProductData.category[0][0].name}

                    {/* {singleProductData.category[0][0].name} */}
                  </p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">
                    Actual Price:
                  </p>
                  <p className="font-semibold text-dark fsize16 textColor-light strikethrough">
                    &#8377;{' '}
                    {singleProductData.productAtt &&
                      singleProductData.productAtt.length > 0 &&
                      singleProductData.productAtt[0].price}
                  </p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">
                    Discount:
                  </p>
                  <p className="font-semibold text-dark fsize16 textColor-light">
                    {singleProductData.productAtt &&
                      singleProductData.productAtt.length > 0 &&
                      singleProductData.productAtt[0].discount}
                    % off
                  </p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">
                    Discounted Price:
                  </p>
                  <p className="font-semibold text-dark fsize16 textColor-light">
                    &#8377;{' '}
                    {singleProductData.productAtt &&
                    singleProductData.productAtt.length > 0 &&
                    singleProductData.productAtt[0].discount
                      ? `${
                          `${
                            singleProductData.productAtt &&
                            singleProductData.productAtt.length > 0
                              ? singleProductData.productAtt[0].price
                              : ''
                          }` -
                          (`${
                            singleProductData.productAtt &&
                            singleProductData.productAtt.length > 0
                              ? singleProductData.productAtt[0].price
                              : ''
                          }` *
                            `${
                              singleProductData.productAtt &&
                              singleProductData.productAtt.length > 0
                                ? singleProductData.productAtt[0].discount
                                : ''
                            }`) /
                            100
                        }`
                      : `${
                          singleProductData.productAtt &&
                          singleProductData.productAtt.length > 0
                            ? singleProductData.productAtt[0].price
                            : ''
                        }`}
                  </p>
                </div>
                <div className="lg:flex md:flex items-center gap-2">
                  <p className="font-medium fsize14 cust-textColor">Review:</p>
                  <p className="font-semibold text-dark  textColor-light">
                    <FeatherIcon
                      icon="star"
                      className="cursor-pointer w-4 h-4"
                    />
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <div className="container mx-auto">
        <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
          <h4
            id="5402422785"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold text-center text-primary"
          >
            {data ? data['5402422785'] : 'Lorem ipsum'}
          </h4>
          <ReactQuill
                id="9642058335"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle mt-2"
                value={data ? data['9642058335'] : 'Lorem Ipsum'}
              />
        </div>

        <div className="lg:mt-8 md:mt-8 mt-4">
          <div className="mx-auto lg:w-8/12">
            <Swiper
              spaceBetween={25}
              breakpoints={{
                1536: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                820: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
                320: {
                  slidesPerView: 1.9,
                  spaceBetween: 12,
                },
              }}
              className="mySwiper"
            >
              {memeber &&
                memeber.map((e, i) => {
                  return (
                    <SwiperSlide key={i} onClick={() => SetCheck(e.id)}>
                      <p
                        className={
                          check == e.id
                            ? 'fsize14 theme-btn2 cust-bgColor bg-primary text-white py-2 px-2 text-center cursor-pointer'
                            : 'fsize14 theme-btn2 border-1px py-2 px-2 text-center cursor-pointer cust-textColor'
                        }
                      >
                        {e.name}
                      </p>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          <div className="lg:mt-6 md:mt-6 mt-3 lg:w-11/12 mx-auto">
            {check === 1 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  {AllProduct
                    ? AllProduct.result &&
                      AllProduct.result.length > 0 &&
                      AllProduct.result.map(product => {
                        return (
                          <div
                            className="w-full rounded-lg overflow-hidden gallerybox relative  "
                            data-aos="zoom-in"
                            data-aos-once="true"
                          >
                            <Image
                              // id="0649316072"
                              src={`${process.env.REACT_APP_STORAGE_URL}${product.image}`}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt="image"
                              className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                            />
                            <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                              <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                                {product.name}
                              </h4>
                            </div>
                            <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                              <div
                                className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                                onClick={() => {
                                  handlePopUp(product);
                                }}
                              >
                                <FeatherIcon
                                  icon="plus"
                                  className="text-primary"
                                  size="20"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : 'no-product'}
                </div>

                {/* <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </>
            ) : (
              ''
            )}
            {check === 2 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative lg:col-span-2 md:col-span-2 "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 3 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative lg:row-span-2 md:row-span-2"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="h-teams7 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 h-teams7 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 4 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative lg:col-span-2 md:col-span-2 "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {check === 5 ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6">
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  lg:row-span-2 md:row-span-2"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="h-teams7 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 h-teams7 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full rounded-lg overflow-hidden gallerybox relative  "
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    <Image
                      id="0649316072"
                      src={data ? getImage(data['0649316072']) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="image"
                      className="lg:h-80 h-52 w-full object-cover bg-d2d2d2"
                    />
                    <div className="absolute lg:bottom-8 bottom-4 z-10 text-center left-0 w-full gallery-content">
                      <h4 className="fsize30 md-fsize23 sm-fsize19 font-semibold text-white">
                        Lorem Ipsum
                      </h4>
                    </div>
                    <div className="absolute top-0 left-0 lg:h-80 h-52 z-20 w-full flex items-center justify-center plus-opacity">
                      <div
                        className="bg-white lg:w-12 lg:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onopenprofile1}
                      >
                        <FeatherIcon
                          icon="plus"
                          className="text-primary"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomProductSection2;
