import React from 'react';
import FeatherIcon from 'feather-icons-react';

const Challenges = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div className="lg:py-4 md:py-4">
        <h2 className="font-semibold fsize32 md-fsize24 sm-fsize20">
          Challenges created by me
        </h2>
        <div className="mt-6 grid gap-6 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg">
            <div className="">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="turf-name"
                className="activites_image rounded-t-lg object-cover"
              />
              <div className="borderall rounded-b-lg px-4 py-2">
                <div className="flex justify-between items-center">
                  <div className="">
                    <h2 className="fsize18 font-semibold mt-2">Turf Name</h2>
                    <div className="flex items-center">
                      <FeatherIcon
                        className="text-primary mr-1"
                        size={12}
                        icon="map-pin"
                      />
                      <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
                    </div>
                  </div>
                  <div className="bg-primary px-2 py-1 text-center rounded-lg">
                    <p className="fsize12 font-medium ">schedule:</p>
                    <p className="fsize11 text-secondary">
                      12/02/2024 06:00 PM
                    </p>
                  </div>
                </div>
                <p className="fsize12 lineClamp3 clr-7c7c7c mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  in nobis accusantium amet consectetur adipisicing elit. Illum
                  in nobis accusantium
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenges;
