import React from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux/es/exports";

const Services1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8 theme-bg">
      <div className="prime_container">
        <div className="header_align">
          <p id="0231676834" className="dynamicStyle section_header">
            {data ? data["0231676834"] : "Services"}
          </p>
          {/* <hr className="hr-blg" /> */}
        </div>
        <div
          id="4745928847"
          className="dynamicStyle lg:mt-12 md:mt-10 mt-8 grid lg:grid-cols-2 md:grid-cols-2 lg:gap-10 md:gap-8 gap-6"
        >
          {data && data["4745928847"]
            ? data["4745928847"].map((e, index) => {
                return (
                  <div className="flex gap-4" key={index}>
                    <div>
                      <FeatherIcon icon="check" size={18} className="mt-1" />
                    </div>
                    <div>
                      <p className="card_title_0">{e.field1}</p>
                      <p className="card_description">{e.field2}</p>
                    </div>
                  </div>
                );
              })
            : "no-card"}
          {/* <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1'>Lorem Ipsum</p>
            <p className='sm-fsize14'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1'>Lorem Ipsum</p>
            <p className='sm-fsize14'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1'>Lorem Ipsum</p>
            <p className='sm-fsize14'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1'>Lorem Ipsum</p>
            <p className='sm-fsize14'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1'>Lorem Ipsum</p>
            <p className='sm-fsize14'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1'>Lorem Ipsum</p>
            <p className='sm-fsize14'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1'>Lorem Ipsum</p>
            <p className='sm-fsize14'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Services1;
