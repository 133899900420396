import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
// import Select from 'react-select';
// import FeatherIcon from 'feather-icons-react';
import { useNavigate } from "react-router-dom";
import { getData } from "../../../redux/forms/forms-action";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const CareersSection = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const options = [
    { value: "search", label: "search" },
    { value: "search", label: "search" },
  ];

  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);

  // const getJobDetails = async () => {
  //   let payload = {
  //     projectId: process.env.REACT_APP_ID,
  //     projectName: process.env.REACT_APP_COMMUNITY_NAME,
  //   };
  //   let data = await dispatch(getData("careers-job_postings", payload));
  //   console.log(data, "careerscard");
  //   setCards(data.result);
  // };

  // const navigate = useNavigate();

  // const handleClick = (jobId) => {
  //   navigate(`/careers-details?ji=${jobId}`);
  // };
  // useEffect(() => {
  //   getJobDetails();
  // }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:flex md:flex block justify-between w-full items-center">
          <h4
            id="2012666979"
            className="dynamicStyle section_header text_surface800"
          >
            {data ? data["2012666979"] : "Lorem Ipsum"}
          </h4>
          {/* <Button colorScheme="primary" variant="outline" px={10}>
                  Show all
                </Button> */}
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:mt-8 md:mt-8 mt-6 gap-4">
          {/* {cards.map((card, i) => {
                return (
                  <Card key={i} className="prime_card themeShadow theme-bg">
                    <h6 className="card_title_0">
                      {card.jobtitle}
                    </h6>
                    <p className="mt-1 fsize14 sm-fsize13 text_surface400 font-medium">
                      {card.departmentname}
                    </p>
                    <p className="mt-1 mb-3 card_description text_surface500">
                      {card.joblocation}
                    </p>
                    <Button
                      onClick={() => handleClick(card._id)}
                      className="fsize14 b_button_primary px-6 py-2 theme-btn"
                    >
                      Apply
                    </Button>
                  </Card>
                );
              })} */}
          <Card className="prime_card themeShadow theme-bg">
            <h6 className="card_title_0">Job title</h6>
            <p className="mt-1 fsize14 sm-fsize13 text_surface400 font-medium">
              Department name
            </p>
            <p className="mt-1 mb-3 card_description text_surface500">
              Job location
            </p>
            <Button className="fsize14 b_button_primary px-6 py-2 theme-btn">
              Apply
            </Button>
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <h6 className="card_title_0">Job title</h6>
            <p className="mt-1 fsize14 sm-fsize13 text_surface400 font-medium">
              Department name
            </p>
            <p className="mt-1 mb-3 card_description text_surface500">
              Job location
            </p>
            <Button className="fsize14 b_button_primary px-6 py-2 theme-btn">
              Apply
            </Button>
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <h6 className="card_title_0">Job title</h6>
            <p className="mt-1 fsize14 sm-fsize13 text_surface400 font-medium">
              Department name
            </p>
            <p className="mt-1 mb-3 card_description text_surface500">
              Job location
            </p>
            <Button className="fsize14 b_button_primary px-6 py-2 theme-btn">
              Apply
            </Button>
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <h6 className="card_title_0">Job title</h6>
            <p className="mt-1 fsize14 sm-fsize13 text_surface400 font-medium">
              Department name
            </p>
            <p className="mt-1 mb-3 card_description text_surface500">
              Job location
            </p>
            <Button className="fsize14 b_button_primary px-6 py-2 theme-btn">
              Apply
            </Button>
          </Card>
        </div>

        <div className="text-center mt-9">
          <ReactQuill
            id="3893106965"
            theme="bubble"
            readOnly
            className="card_description bubble2 dynamicStyle"
            value={data ? data["3893106965"] : "Lorem Ipsum"}
          />
        </div>
      </div>
    </div>
  );
};

export default CareersSection;
