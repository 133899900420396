import React, { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const GallerySection10 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-2 md:gap-4 gap-2">
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="7154515824" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["7154515824"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="8498974714" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["8498974714"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="1231143558"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["1231143558"] : "Lorem Ipsum"}
              />
            </div>
          </div>
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="3247099678" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["3247099678"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="2645452481" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["2645452481"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="5655663940"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["5655663940"] : "Lorem Ipsum"}
              />
            </div>
          </div>
          <div class="effect-lily w-full lg:h-full md:h-full h-52 lg:row-span-2 md:row-span-2 row-span-1">
            <div
              id="7278007761"
              className="dynamicStyle2 lg:h-full md:h-full h-52"
            >
              <Image
                imageClassName="lg:h-full md:h-full h-52 w-full object-cover"
                src={data ? getImage(data["7278007761"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectone">
              <h2 id="5528497303" className="dynamicStyle card_title_0 clr-fff">
                {data
                  ? data["5528497303"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <p
                id="1609645126"
                className="card_description16px mt-1 text-left dynamicStyle"
                dangerouslySetInnerHTML={{
                  __html: data ? data["1609645126"] : "User Agreement",
                }}
              ></p>
            </div>
          </div>
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="9653950076" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["9653950076"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="5706728852" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["5706728852"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="1289171493"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["1289171493"] : "Lorem Ipsum"}
              />
            </div>
          </div>
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="2992397273" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["2992397273"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="4930264366" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["4930264366"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="8588739532"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["8588739532"] : "Lorem Ipsum"}
              />
            </div>
          </div>
          <div class="effect-lily w-full lg:h-full md:h-full h-52 lg:row-span-2 md:row-span-2 row-span-1">
            <div
              id="5034105135"
              className="dynamicStyle2 lg:h-full md:h-full h-52"
            >
              <Image
                imageClassName="lg:h-full md:h-full h-52 w-full object-cover"
                src={data ? getImage(data["5034105135"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectone">
              <h2 id="0444700899" className="dynamicStyle card_title_0 clr-fff">
                {data
                  ? data["0444700899"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <p
                id="0830385894"
                className="card_description16px mt-1 text-left dynamicStyle"
                dangerouslySetInnerHTML={{
                  __html: data ? data["0830385894"] : "User Agreement",
                }}
              ></p>
            </div>
          </div>
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="4280545418" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["4280545418"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="6860087113" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["6860087113"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="6531585731"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["6531585731"] : "Lorem Ipsum"}
              />
            </div>
          </div>
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="2399653892" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["2399653892"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="3188127528" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["3188127528"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="1395626651"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["1395626651"] : "Lorem Ipsum"}
              />
            </div>
          </div>
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="4639908143" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["4639908143"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="9779414973" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["9779414973"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="5713583731"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["5713583731"] : "Lorem Ipsum"}
              />
            </div>
          </div>
          <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
            <div id="8521521850" className="dynamicStyle2">
              <Image
                imageClassName="lg:h-80 md:h-60 h-52 w-full object-cover"
                src={data ? getImage(data["8521521850"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <div className="effectthird w-full lg:h-80 md:h-60 h-52">
              <h2 id="4524460397" className="dynamicStyle card_title clr-fff">
                {data
                  ? data["4524460397"]
                  : "We provide wide variety of fabrics prints."}
              </h2>
              <ReactQuill
                id="9485351043"
                theme="bubble"
                readOnly
                className="bubble2-w dynamicStyle card_description"
                value={data ? data["9485351043"] : "Lorem Ipsum"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection10;
