import React from "react";
import { useSelector } from "react-redux/es/exports";

const CounterSection1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="bg-ddd9d8 lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
          <div className="flex flex-col items-center justify-center">
            <p
              id="5294435441"
              className="dynamicStyle fsize60 sm-fsize30 font-bold mb-2 clr-fff"
            >
              {data ? data["5294435441"] : "Lorem Ipsum"}
            </p>
            <hr className="hr-counter1" />
            <p id="2486844721" className="dynamicStyle clr-fff card_title_0">
              {data ? data["2486844721"] : "Lorem Ipsum"}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p
              id="3786152797"
              className="dynamicStyle fsize60 sm-fsize30 font-bold mb-2 clr-fff"
            >
              {data ? data["3786152797"] : "Lorem Ipsum"}
            </p>
            <hr className="hr-counter1" />
            <p id="3461657116" className="dynamicStyle clr-fff card_title_0">
              {data ? data["3461657116"] : "Lorem Ipsum"}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p
              id="2043277298"
              className="dynamicStyle fsize60 sm-fsize30 font-bold mb-2 clr-fff"
            >
              {data ? data["2043277298"] : "Lorem Ipsum"}
            </p>
            <hr className="hr-counter1" />
            <p id="6165307808" className="dynamicStyle clr-fff card_title_0">
              {data ? data["6165307808"] : "Lorem Ipsum"}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p
              id="1362130998"
              className="dynamicStyle fsize60 sm-fsize30 font-bold mb-2 clr-fff"
            >
              {data ? data["1362130998"] : "Lorem Ipsum"}
            </p>
            <hr className="hr-counter1" />
            <p id="3631774524" className="dynamicStyle clr-fff card_title_0">
              {data ? data["3631774524"] : "Lorem Ipsum"}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection1;
