import {
  signupWithPassword,
  generateOTP,
  verifyOTP,
} from "../../../redux/auth/auth-action";
import FeatherIcon from "feather-icons-react";
import { Image, FormErrorMessage, FormControl } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { InputText } from "primereact/inputtext";
import OtherAuth from "../../auth/OtherAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import SEO from "../../../cms/SEO";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";

const Register = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [show, setShow] = React.useState(false);
  const [confirmShow, setConfirmShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleShowPass = () => setConfirmShow(!confirmShow);
  const [isShowEmailOTP, SetShowEmailOTP] = useState("");
  const [metaData, setMetaData] = useState({});

  //===========SEO============
  // useEffect(() => {
  //   const getSEO = async () => {
  //     let data = await dispatch(getMetaByPageName({ pageName: "Register" }));
  //     if (data.code === 200) {
  //       setMetaData(data.data);
  //     }
  //   };
  //   getSEO();
  // }, []);
  //===========SEO============

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
    validateField("mobile", formik.values.mobile);
  };

  const validateField = (fieldName, value) => {
    try {
      validationRules.fields[fieldName].validateSync(value, {
        abortEarly: false,
      });
      formik.setFieldError(fieldName, undefined);
      formik.setFieldTouched(fieldName, true);
      return true;
    } catch (error) {
      formik.setFieldError(fieldName, error.errors[0]);
      formik.setFieldTouched(fieldName, true);
      return false;
    }
  };

  const GetEmailOTP = async () => {
    let emailValid = validateField("email", formik.values.email);
    let mobileValid = validateField("mobile", formik.values.mobile);
    if (!(emailValid && mobileValid)) {
      return;
    }
    let payload = {
      mobile: formik.values.mobile,
      email: formik.values.email.toLowerCase(),
      type: "Register",
    };

    try {
      let data = await dispatch(generateOTP(payload));

      if (data && data.code === 200) {
        // Swal.fire({
        //   title: 'Success',
        //   text: 'OTP successfully send to your email ID',
        //   icon: 'success',
        // });
        await toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `OTP successfully send to your email ID`,
          life: 3000,
        });
        SetShowEmailOTP(true);
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: 'Error',
          //   text: data.message,
          //   icon: 'error',
          // });
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 3000,
          });
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: 'Error',
      //   text: 'Something went wrong!',
      //   icon: 'error',
      // });
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong!",
        life: 3000,
      });
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    otp: "",
    mobile: "",
    countryCode: "",
    password: "",
    confirmPassword: "",
  };

  const validationRules = Yup.object({
    firstName: Yup.string()
      .trim()
      .min(3, "First name must be at least 3 characters")
      .max(25, "First name must be at most 25 characters")
      .required("First name is required")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets is allowed"),
    lastName: Yup.string()
      .trim()
      .min(3, "Last name must be at least 3 characters")
      .max(25, "Last name must be at most 25 characters")
      .required("Last name is required")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets is allowed"),
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    mobile: Yup.string()
      // .min(10, 'Mobile number must be 10 digits')
      // .max(10, 'Mobile number must be 10 digits')
      .required("Mobile number is required"),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password"), null], "Password must match"),
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP number must be 6 digits"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.toLowerCase(),
      otp: values.otp,
      mobile: values.mobile,
      countryCode: values.countryCode,
      password: values.password,
      acceptTerms: true,
      role: "User",
    };

    try {
      let data = await dispatch(signupWithPassword(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Congratulations",
          text: "Your account has been successfully created.",
          icon: "success",
        });
        formik.handleReset();
        navigate("/user-login");
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: 'Error',
          //   text: data.message,
          //   icon: 'error',
          // });
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 3000,
          });
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: 'Error',
      //   text: 'Something went wrong!',
      //   icon: 'error',
      // });
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong!",
        life: 3000,
      });
    }
  };

  const handleTrim = async (event) => {
    await formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <>
      <SEO metaData={metaData} />
      <Toast ref={toast} position="bottom-right" />
      <section className="container mx-auto h-100 lg:px-12 md:px-8 px-5">
        <div className="lg:flex md:flex items-center h-full">
          <div
            id="5561534387"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 lg:block md:block hidden"
          >
            <Image
              maxH="600"
              src={data ? getImage(data["5561534387"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="loginImage"
              className="m-auto"
            />
          </div>
          <div className="smcust-shadow lg:w-1/2 md:w-1/2 lg:py-8 md:py-8 py-8 lg:px-12 md:px-8 px-5 ">
            <div className="">
              <p
                id="1482208496"
                className="dynamicStyle lg:text-4xl md:text-2xl text-2xl text-center font-semibold"
              >
                {data ? data["1482208496"] : "Register"}
              </p>

              <p className="  lg:text-base md:text-base text-sm text-center lg:mt-4 md:mt-4 mt-2">
                <span
                  id="6729677842"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["6729677842"] : "no-data",
                  }}
                  className="dynamicStyle text333 opacity-70 textColor-light"
                ></span>
                <span className=" underline cursor-pointer font-semibold">
                  Term of use
                </span>
                <span className="textColor-light opacity-70">and</span>
                <span className=" cursor-pointer underline font-semibold">
                  Privacy policy
                </span>
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="lg:mt-16 md:mt-8 mt-8">
                  <div className="lg:mb-6 md:mb-4 mb-4 gap-4 lg:flex md:flex">
                    <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-6">
                      <h3
                        id="7973984815"
                        className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                      >
                        {data ? data["7973984815"] : "First Name"}
                      </h3>

                      <InputText
                        name="firstName"
                        className="w-full mt-2 bg_surface50 p-inputtext"
                        placeholder="Enter your name"
                        value={formik.values.firstName}
                        invalid={
                          !!formik.errors.firstName && formik.touched.firstName
                        }
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <p className="mt-2 text-danger fsize12">
                          {formik.errors.firstName}
                        </p>
                      )}
                    </div>

                    <div className="lg:w-1/2 md:w-1/2 w-full ">
                      <h3
                        id="9742539043"
                        className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                      >
                        {data ? data["9742539043"] : "Last Name"}
                      </h3>

                      <InputText
                        name="lastName"
                        className="w-full mt-2 bg_surface50 p-inputtext"
                        placeholder="Enter your name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        invalid={
                          !!formik.errors.lastName && formik.touched.lastName
                        }
                        onBlur={handleTrim}
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <p className="mt-2 text-danger fsize12">
                          {formik.errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mb-6">
                    <h3
                      id="1075383670"
                      className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                    >
                      {data ? data["1075383670"] : "Mobile Number"}
                    </h3>
                    <FormControl
                      isInvalid={
                        !!formik.errors.mobile && formik.touched.mobile
                      }
                    >
                      <PhoneInput
                        name="mobile"
                        country={"in"}
                        enableSearch={true}
                        className="mt-2 phoneinput w-full themefamily text-black"
                        onChange={handlePhoneChange}
                      />
                      {formik.touched.mobile && formik.errors.mobile && (
                        <p className="mt-2 text-danger fsize12">
                          {formik.errors.mobile}
                        </p>
                      )}
                    </FormControl>
                  </div>

                  <div className="mb-6">
                    <h3
                      id="0009601630"
                      className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                    >
                      {data ? data["0009601630"] : "Email Address"}
                    </h3>

                    <div className="relative">
                      <InputText
                        name="email"
                        className="w-full mt-2 bg_surface50 p-inputtext"
                        placeholder="Enter your email address"
                        value={formik.values.email}
                        invalid={!!formik.errors.email && formik.touched.email}
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <p className="mt-2 text-danger fsize12">
                          {formik.errors.email}
                        </p>
                      )}

                      <Button
                        outlined
                        className="absolute right-2 top-4  px-4 py-2 fsize12"
                        onClick={GetEmailOTP}
                      >
                        Send OTP
                      </Button>
                    </div>

                    {/* {isShowEmailOTP && ( */}
                    <div className="mt-4 resendotp-t">
                      <p className=" fsize14 sm-fsize12 text333 font-semibold mb-1">
                        {"Enter OTP"}
                      </p>

                      <OTPInput
                        name="otp"
                        value={formik.values.otp}
                        onChange={(e) => {
                          formik.setFieldValue("otp", e);
                        }}
                        OTPLength={6}
                        otpType="number"
                        invalid={!!formik.errors.otp && formik.touched.otp}
                        className="numb-otp"
                        inputclassName="themefamily"
                      />
                      {formik.touched.otp && formik.errors.otp && (
                        <FormErrorMessage>{formik.errors.otp}</FormErrorMessage>
                      )}

                      {isShowEmailOTP && (
                        <div className="mt-2">
                          <ResendOTP onResendClick={() => GetEmailOTP()} />
                        </div>
                      )}
                    </div>
                    {/* )} */}
                  </div>

                  {formik.values.otp &&
                    formik.values.otp.toString().length === 6 &&
                    isShowEmailOTP && (
                      <div className="lg:flex md:flex gap-4">
                        <div className="lg:w-1/2 md:w-1/2 w-full md:mb-0 lg:mb-0 mb-4">
                          <h3
                            id="9019711980"
                            className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                          >
                            {data ? data["9019711980"] : "Password"}
                          </h3>

                          <Password
                            name="password"
                            toggleMask
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            feedback={false}
                            invalid={
                              !!formik.errors.password &&
                              formik.touched.password
                            }
                            className="mt-2 bg_surface50 prime_password"
                            placeholder="Enter your password"
                          />
                          {formik.touched.password &&
                            formik.errors.password && (
                              <p className="mt-2 text-danger fsize12">
                                {formik.errors.password}
                              </p>
                            )}
                        </div>

                        <div className="lg:w-1/2 md:w-1/2 w-full">
                          <div>
                            <h3
                              id="7017828564"
                              className="dynamicStyle lg:text-xl md:text-base text-base text333 font-semibold"
                            >
                              {data ? data["7017828564"] : "  Confirm Password"}
                            </h3>

                            <Password
                              name="confirmPassword"
                              toggleMask
                              value={formik.values.confirmPassword}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                              feedback={false}
                              invalid={
                                !!formik.errors.confirmPassword &&
                                formik.touched.confirmPassword
                              }
                              className="mt-2 bg_surface50 prime_password"
                              placeholder="Confirm the password"
                            />
                            {formik.touched.confirmPassword &&
                              formik.errors.confirmPassword && (
                                <p className="mt-2 text-danger fsize12">
                                  {formik.errors.confirmPassword}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    )}

                  <Button
                    type="submit"
                    className="dynamicStyle flex justify-center b_button_primary w-full mt-8 sm-fsize16px"
                    id="9548220046"
                  >
                    {data ? data["9548220046"] : "Register"}
                  </Button>
                </div>
              </form>
              <p className=" lg:text-base md:text-base text-sm text-center mt-4">
                <span id="4109885762" className="dynamicStyle">
                  {data ? data["4109885762"] : " Already have an Account?"}
                </span>
                <span className=" underline cursor-pointer font-semibold pl-2 textColor-light">
                  <Link to="/login">Login</Link>
                </span>
              </p>

              {/* <Box className="sm-px-30px" position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  <p id="1785147843" className="dynamicStyle">
                    {data ? data["1785147843"] : "or"}{" "}
                  </p>
                </AbsoluteCenter>
              </Box> */}

              <OtherAuth />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
