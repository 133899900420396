import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Image } from "primereact/image";
import { FaQuoteRight } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Testimonial8 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  // function getImage(image) {
  //   if (image && image.includes('blob:')) {
  //     return image;
  //   } else {
  //     return process.env.REACT_APP_STORAGE_URL + image;
  //   }
  // }

  let getAllTestimonial = useSelector((state) => state.testimonial.Testimonial);
  console.log(getAllTestimonial, "check-test");
  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div class="text-center w-70  mx-auto lg:pb-12 md:pb-12 pb-5">
          <h2 id="1767348646" class="dynamicStyle section_header">
            {data ? data["1767348646"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="3141158939"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["3141158939"] : "Welcome to My World"}
          />
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? "visiblebottom" : ""} `}
        >
          <Swiper
            spaceBetween={15}
            slidesPerView={2}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {getAllTestimonial?.data?.result.map((testimonial, index) => {
              return (
                <SwiperSlide>
                  <Card
                    className="relative prime_card lg:mx-2 md:mx-2 lg:mt-10 md:mt-12 mt-12"
                    key={testimonial._id}
                  >
                    <ReactStars count={5} size={24} activeColor="#ffd700" />
                    <div className="pt-3">
                      <h4 className="card_title">{testimonial.name}</h4>
                      <div className="flex gap-2">
                        <div className="lg:h-36 md:h-36 h-36 w-90per">
                          <p className=" card_description mt-1 lineClamp5">
                            {testimonial.testimonial}
                          </p>
                        </div>
                        {/* <span className='w-10per fsize45 text-center'> &#8221; </span> */}
                        <FaQuoteRight
                          size="23"
                          className="text-primary cust-textColor"
                        />
                      </div>
                    </div>
                    <div className="topr-abs ">
                      <Image
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                        alt={testimonial.name}
                        imageClassName="rounded-full img-wh-80 object-cover"
                      />
                    </div>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default Testimonial8;
