import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";

const Team10 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:w-2/3 md:w-2/3 mx-auto text-center">
          <h2
            id="5980802030"
            className="dynamicStyle section_header text-center"
          >
            {data ? data["5980802030"] : "Meet Our Team Member"}
          </h2>
          <ReactQuill
            id="2894572209"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["2894572209"] : "Lorem Ipsum"}
          />
        </div>
        <div
          id="3910447897"
          className="dynamicStyle grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12"
        >
          {data && data["3910447897"]
            ? data["3910447897"].map((e) => {
                return (
                  <Card className="shadow-none bg_none cursor-pointer">
                    <div className="lg:mb-4 md:mb-3 mb-2">
                      <Image
                        imageClassName="w-24 h-24 rounded-full object-contain mx-auto"
                        src={e ? getImage(e.field1) : ""}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt={e.field2}
                      />
                    </div>
                    <div className="">
                      <h4 className="card_title_0 text-center">{e.field2}</h4>
                      <p className="card_description1 mt-1 text-center">
                        {e.field3}
                      </p>
                    </div>
                  </Card>
                );
              })
            : "No Data"}
        </div>
      </div>
    </div>
  );
};

export default Team10;
