import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection43 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  console.log(data['9603251879'], 'SACHIN');

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="w-full lg:flex md:flex items-center gap-8">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <div className="lg:w-2/3 md:w-2/3">
              <p id="7668749890" className="dynamicStyle about_title">
                {data ? data['7668749890'] : 'Easy to integrate, everywhere'}
              </p>
              <ReactQuill
                id="5572062630"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data['5572062630'] : 'Lorem Ipsum'}
              />

              <div
                id="9603251879"
                className="dynamicStyle2 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-3 gap-4 w-full lg:mt-10 md:mt-10 mt-6"
              >
                {data && data['9603251879']
                  ? data['9603251879'].map(e => {
                      return (
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="about_img"
                          imageClassName="object-cover w-full h-16"
                        />
                      );
                    })
                  : 'no-data'}
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-8">
            <div
              id="3034777309"
              className="dynamicStyle grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-12 gap-6 w-full lg:mt-6 md:mt-6 mt-4"
            >
              {data && data['3034777309']
                ? data['3034777309'].map(e => {
                    return (
                      <div className="">
                        <p className="card_title">{e.field1}</p>
                        <ReactQuill
                          id="5572062630"
                          theme="bubble"
                          readOnly
                          className="bubble card_derscription"
                          value={e.field2}
                        />
                      </div>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection43;
