import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const CategoriesSection1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    Aos.init();
  });

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="lg:flex md:flex sm-reverse lg:gap-16 md:gap-8 prime_container">
        <div className="wid-70 grid grid-cols-2 lg:gap-4 md:gap-4 gap-2">
          <div id="4525094276" className="dynamicStyle2 w-full">
            <Image
              imageClassName="img-wh object-cover"
              src={data ? getImage(data["4525094276"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image-alt"
            />
          </div>
          <div id="8854343997" className="dynamicStyle2 w-full">
            <Image
              imageClassName="img-wh object-cover"
              src={data ? getImage(data["8854343997"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image-alt"
            />
          </div>
          <div id="6687611188" className="dynamicStyle2 w-full">
            <Image
              imageClassName="img-wh object-cover"
              src={data ? getImage(data["6687611188"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image-alt"
            />
          </div>
          <div id="3785248192" className="dynamicStyle2 w-full">
            <Image
              imageClassName="img-wh object-cover"
              src={data ? getImage(data["3785248192"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image-alt"
            />
          </div>
          <div id="0702681228" className="dynamicStyle2 w-full">
            <Image
              imageClassName="img-wh object-cover"
              src={data ? getImage(data["0702681228"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image-alt"
            />
          </div>
          <div id="6168663725" className="dynamicStyle2 w-full">
            <Image
              imageClassName="img-wh object-cover"
              src={data ? getImage(data["6168663725"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="image-alt"
            />
          </div>
        </div>
        <div className="wid-30 sm-wfull lg:mb-0 md:mb-0 mb-6">
          <div className="lg:px-8 md:px-4 px-6 lg:py-8 lg:py-8 py-6 bg-fffaf0">
            <p
              id="8005565455"
              className="dynamicStyle font-medium fsize16 sm-fsize14 text-primary"
            >
              {data ? data["8005565455"] : "Lorem Ipsum"}
            </p>
            <p id="4264969459" className="dynamicStyle section_header">
              {data ? data["4264969459"] : "Lorem Ipsum"}
            </p>
            <ReactQuill
              id="4826842366"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle section_detail"
              value={data ? data["4826842366"] : "Lorem Ipsum"}
            />
            {/* <ul className="disc px-4">
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            African Wax Fabrics
          </li>
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Printed Cotton Fabric
          </li>
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Tie Dye
          </li>
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Nighty Printed Cotton Fabric
          </li>
          <li className="lg:pb-4 md:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Real Wax Printed Fabrics
          </li>
        </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesSection1;
