import React from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Image } from "primereact/image";

const Services7 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <h4 id="7401408450" className="dynamicStyle section_header">
            {data ? data["7401408450"] : "Lorem Ipsum"}
          </h4>
          <ReactQuill
            id="3537365850"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["3537365850"] : "Lorem Ipsum"}
          />
        </div>
        <div id="1902505608" className="dynamicStyle lg:mt-12 md:mt-10 mt-8">
          <Swiper
            spaceBetween={25}
            slidesPerView={3.2}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="lg:p-2 md:p-2"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3.4,
                spaceBetween: 25,
              },
              1280: {
                slidesPerView: 3.4,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 3.4,
                spaceBetween: 25,
              },
              991: {
                slidesPerView: 2.8,
                spaceBetween: 25,
              },
              767: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-primary rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>

            {data && data["1902505608"]
              ? data["1902505608"].map((e, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Card
                        className="prime_card themeShadow theme-bodyColor themeShadow-insert"
                        key={index}
                      >
                        <div className="w-full">
                          <Image
                            src={e ? getImage(e.field1) : ""}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="serviec"
                            imageClassName="w-2/3 mx-auto h-36 rounded-lg"
                          />
                        </div>
                        <div className="mt-4 text-center">
                          <p className="card_title">{e.field2}</p>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble2 card_description "
                            value={e.field3}
                          />
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : "no-card"}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Services7;
