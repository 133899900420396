import {
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Image,
  useColorMode,
  Card,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { SearchIcon, CalendarIcon } from '@chakra-ui/icons';

const MyRideHistory1 = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="custride">
      <div className="lg:flex md:flex justify-between gap-8 container mx-auto lg:px-12 md:px-8 px-5 py-6">
        <div className="flex lg:gap-4 gap-2 lg:mb-0 md:mb-0 mb-4">
          <div className="relative">
            <InputGroup className="">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.500" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search anything"
                className="bdr-gray-1px md-fsize14 sm-fsize14"
              />
            </InputGroup>
          </div>
          <Button className="b_button_primary px-6 py-2 md-fsize14 sm-fsize14 theme-btn ">
            Search
          </Button>
        </div>
        <div className="flex lg:gap-4 md:gap-2 gap-2 cust-textColor">
          <Select
            placeholder="Pick-Up"
            className="clr-fff bg-a2a2a2 md-fsize14 sm-fsize14 cust-bgColor cust-textColor"
          >
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
          <Select
            placeholder="Drop-Off"
            className="bdr-gray-1px md-fsize14 sm-fsize14 pl-2 cust-bgColor cust-textColor"
          >
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
          <div className="border-left-1px lg:pl-4 pl-2">
            <Button className="b_button_primary px-6 py-2 bdr-gray-1px md-fsize14 sm-fsize14">
              All filters
            </Button>
          </div>
        </div>
      </div>

      <div
        className={
          colorMode === 'light'
            ? 'py-10 bg-efeaea theme-bg'
            : 'py-10 bg-efeaea bg-darkprimary'
        }
      >
        <div className="container mx-auto lg:px-12 md:px-8 px-5">
          <div className="flex justify-between pb-6">
            <p className="fsize24 sm-fsize20 font-semibold cust-textColor">
              Last Rides
            </p>
            <div className="cust-textColor">
              <Select
                icon={<CalendarIcon />}
                placeholder="Today"
                className="bdr-gray-1px md-fsize14 sm-fsize14 cust-bgColor cust-textColor"
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-6 md:gap-6 gap-4 lg:pb-10 md:pb-10 pb-8">
            <Card
              className={
                colorMode === 'light'
                  ? 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 bg-fff rounded-lg cust-shadow themeShadow theme-bg'
                  : 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 rounded-lg  cust-shadow themeShadow theme-bg'
              }
            >
              <div className="flex justify-between items-center">
                <p className="fsize14 text-gray-300 textColor-light">
                  Ride completed on 19 Jan 2024, 2:10 AM
                </p>
                <div>
                  <Menu>
                    <MenuButton
                      as={Button}
                      className={
                        colorMode === 'light'
                          ? 'bg-fff theme-bg'
                          : 'cust-bgColor theme-bg'
                      }
                    >
                      <FeatherIcon
                        size={18}
                        icon="more-vertical"
                        className="cursor-pointer"
                      />
                    </MenuButton>
                    <MenuList className="cust-bgColor cust-textColor">
                      <MenuItem>Download</MenuItem>
                      <MenuItem>Create a Copy</MenuItem>
                      <MenuItem>Mark as Draft</MenuItem>
                      <MenuItem>Delete</MenuItem>
                      <MenuItem>Attend a Workshop</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-2 items-center cust-textColor">
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
                <div>
                  <FeatherIcon icon="arrow-right" size={18} className="" />
                </div>
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
              </div>
              <div className="bdr-gray-1px p-3 rounded lg:my-4 md:my-4 my-2">
                <div className="flex justify-between items-center pb-4">
                  <div className="flex gap-4 items-center">
                    <div>
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-14 h-14 rounded-full"
                      />
                    </div>
                    <div>
                      <p className="font-semibold sm-fsize14 cust-textColor">
                        Mira Joshi
                      </p>
                      <p className="sm-fsize14 text-gray-300 textColor-light">
                        19 Jan 2024, 02:10 AM
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="cust-textColor">
                      <FeatherIcon icon="star" className="" size={16} />
                    </div>
                    <p className="sm-fsize14 cust-textColor">4.6</p>
                  </div>
                </div>
                <div>
                  <p className="sm-fsize14 textColor-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Odit, dicta facere cumque harum temporibus architecto quidem
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-between pb-2">
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Duration
                  </p>
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Received
                  </p>
                </div>
                <div className="flex justify-between pb-2">
                  <p className="fsize18 font-medium sm-fsize16 cust-textColor">
                    50.2 km / 5 hrs
                  </p>
                  <p className="fsize18 font-semibold sm-fsize16 cust-textColor">
                    ₹500
                  </p>
                </div>
              </div>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 bg-fff rounded-lg cust-shadow themeShadow theme-bg'
                  : 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 rounded-lg  cust-shadow themeShadow theme-bg'
              }
            >
              <div className="flex justify-between items-center">
                <p className="fsize14 text-gray-300 textColor-light">
                  Ride completed on 19 Jan 2024, 2:10 AM
                </p>
                <div>
                  <Menu>
                    <MenuButton
                      as={Button}
                      className={
                        colorMode === 'light'
                          ? 'bg-fff theme-bg'
                          : 'cust-bgColor theme-bg'
                      }
                    >
                      <FeatherIcon
                        size={18}
                        icon="more-vertical"
                        className="cursor-pointer"
                      />
                    </MenuButton>
                    <MenuList className="cust-bgColor cust-textColor">
                      <MenuItem>Download</MenuItem>
                      <MenuItem>Create a Copy</MenuItem>
                      <MenuItem>Mark as Draft</MenuItem>
                      <MenuItem>Delete</MenuItem>
                      <MenuItem>Attend a Workshop</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-2 items-center cust-textColor">
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
                <div>
                  <FeatherIcon icon="arrow-right" size={18} className="" />
                </div>
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
              </div>
              <div className="bdr-gray-1px p-3 rounded lg:my-4 md:my-4 my-2">
                <div className="flex justify-between items-center pb-4">
                  <div className="flex gap-4 items-center">
                    <div>
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-14 h-14 rounded-full"
                      />
                    </div>
                    <div>
                      <p className="font-semibold sm-fsize14 cust-textColor">
                        Mira Joshi
                      </p>
                      <p className="sm-fsize14 text-gray-300 textColor-light">
                        19 Jan 2024, 02:10 AM
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="cust-textColor">
                      <FeatherIcon icon="star" className="" size={16} />
                    </div>
                    <p className="sm-fsize14 cust-textColor">4.6</p>
                  </div>
                </div>
                <div>
                  <p className="sm-fsize14 textColor-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Odit, dicta facere cumque harum temporibus architecto quidem
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-between pb-2">
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Duration
                  </p>
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Received
                  </p>
                </div>
                <div className="flex justify-between pb-2">
                  <p className="fsize18 font-medium sm-fsize16 cust-textColor">
                    50.2 km / 5 hrs
                  </p>
                  <p className="fsize18 font-semibold sm-fsize16 cust-textColor">
                    ₹500
                  </p>
                </div>
              </div>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 bg-fff rounded-lg cust-shadow themeShadow theme-bg'
                  : 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 rounded-lg  cust-shadow themeShadow theme-bg'
              }
            >
              <div className="flex justify-between items-center">
                <p className="fsize14 text-gray-300 textColor-light">
                  Ride completed on 19 Jan 2024, 2:10 AM
                </p>
                <div>
                  <Menu>
                    <MenuButton
                      as={Button}
                      className={
                        colorMode === 'light'
                          ? 'bg-fff theme-bg'
                          : 'cust-bgColor theme-bg'
                      }
                    >
                      <FeatherIcon
                        size={18}
                        icon="more-vertical"
                        className="cursor-pointer"
                      />
                    </MenuButton>
                    <MenuList className="cust-bgColor cust-textColor">
                      <MenuItem>Download</MenuItem>
                      <MenuItem>Create a Copy</MenuItem>
                      <MenuItem>Mark as Draft</MenuItem>
                      <MenuItem>Delete</MenuItem>
                      <MenuItem>Attend a Workshop</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-2 items-center cust-textColor">
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
                <div>
                  <FeatherIcon icon="arrow-right" size={18} className="" />
                </div>
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
              </div>
              <div className="bdr-gray-1px p-3 rounded lg:my-4 md:my-4 my-2">
                <div className="flex justify-between items-center pb-4">
                  <div className="flex gap-4 items-center">
                    <div>
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-14 h-14 rounded-full"
                      />
                    </div>
                    <div>
                      <p className="font-semibold sm-fsize14 cust-textColor">
                        Mira Joshi
                      </p>
                      <p className="sm-fsize14 text-gray-300 textColor-light">
                        19 Jan 2024, 02:10 AM
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="cust-textColor">
                      <FeatherIcon icon="star" className="" size={16} />
                    </div>
                    <p className="sm-fsize14 cust-textColor">4.6</p>
                  </div>
                </div>
                <div>
                  <p className="sm-fsize14 textColor-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Odit, dicta facere cumque harum temporibus architecto quidem
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-between pb-2">
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Duration
                  </p>
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Received
                  </p>
                </div>
                <div className="flex justify-between pb-2">
                  <p className="fsize18 font-medium sm-fsize16 cust-textColor">
                    50.2 km / 5 hrs
                  </p>
                  <p className="fsize18 font-semibold sm-fsize16 cust-textColor">
                    ₹500
                  </p>
                </div>
              </div>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 bg-fff rounded-lg cust-shadow themeShadow theme-bg'
                  : 'lg:px-6 md:px-6 px-4 lg:py-4 md:py-4 py-2 rounded-lg  cust-shadow themeShadow theme-bg'
              }
            >
              <div className="flex justify-between items-center">
                <p className="fsize14 text-gray-300 textColor-light">
                  Ride completed on 19 Jan 2024, 2:10 AM
                </p>
                <div>
                  <Menu>
                    <MenuButton
                      as={Button}
                      className={
                        colorMode === 'light'
                          ? 'bg-fff theme-bg'
                          : 'cust-bgColor theme-bg'
                      }
                    >
                      <FeatherIcon
                        size={18}
                        icon="more-vertical"
                        className="cursor-pointer"
                      />
                    </MenuButton>
                    <MenuList className="cust-bgColor cust-textColor">
                      <MenuItem>Download</MenuItem>
                      <MenuItem>Create a Copy</MenuItem>
                      <MenuItem>Mark as Draft</MenuItem>
                      <MenuItem>Delete</MenuItem>
                      <MenuItem>Attend a Workshop</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-2 items-center cust-textColor">
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
                <div>
                  <FeatherIcon icon="arrow-right" size={18} className="" />
                </div>
                <p className="font-semibold fsize18 sm-fsize16">
                  Andheri West,
                </p>
              </div>
              <div className="bdr-gray-1px p-3 rounded lg:my-4 md:my-4 my-2">
                <div className="flex justify-between items-center pb-4">
                  <div className="flex gap-4 items-center">
                    <div>
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-14 h-14 rounded-full"
                      />
                    </div>
                    <div>
                      <p className="font-semibold sm-fsize14 cust-textColor">
                        Mira Joshi
                      </p>
                      <p className="sm-fsize14 text-gray-300 textColor-light">
                        19 Jan 2024, 02:10 AM
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="cust-textColor">
                      <FeatherIcon icon="star" className="" size={16} />
                    </div>
                    <p className="sm-fsize14 cust-textColor">4.6</p>
                  </div>
                </div>
                <div>
                  <p className="sm-fsize14 textColor-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Odit, dicta facere cumque harum temporibus architecto quidem
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-between pb-2">
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Duration
                  </p>
                  <p className="text-gray-300 sm-fsize14 textColor-light">
                    Received
                  </p>
                </div>
                <div className="flex justify-between pb-2">
                  <p className="fsize18 font-medium sm-fsize16 cust-textColor">
                    50.2 km / 5 hrs
                  </p>
                  <p className="fsize18 font-semibold sm-fsize16 cust-textColor">
                    ₹500
                  </p>
                </div>
              </div>
            </Card>
          </div>
          <div>
            <p className="fsize24 sm-fsize20 font-semibold lg:pb-6 md:pb-6 pb-2 cust-textColor">
              Offers
            </p>

            <div>
              <Swiper
                spaceBetween={30}
                navigation={true}
                modules={[Navigation]}
                className="h-swipertestimonial8 mySwiper lg:p-2 p-2"
                breakpoints={{
                  1536: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  820: {
                    slidesPerView: 1.3,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  425: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                  },
                  325: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                  },
                }}
              >
                <SwiperSlide
                  className={
                    colorMode === 'light'
                      ? 'cust-shadow bg-fff rounded-lg flex  theme-bg themeShadow'
                      : 'cust-shadow bg-fff rounded-lg flex cust-bgColor theme-bg themeShadow'
                  }
                >
                  <div className="w-3/5 flex items-center px-4">
                    <div>
                      <p className="fsize20 md-fsize18 sm-fsize16 font-semibold pb-1 cust-textColor">
                        Complete 10 rides a day and win ₹1,000 bonus
                      </p>
                      <p className="text-gray-300 sm-fsize14 textColor-light">
                        Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </div>
                  <div className="w-2/5 ">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="img"
                      className="rounded-r-lg w-full sm-h200px"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className={
                    colorMode === 'light'
                      ? 'cust-shadow bg-fff rounded-lg flex  theme-bg themeShadow'
                      : 'cust-shadow bg-fff rounded-lg flex cust-bgColor theme-bg themeShadow'
                  }
                >
                  <div className="w-3/5 flex items-center px-4">
                    <div>
                      <p className="fsize20 md-fsize18 sm-fsize16 font-semibold pb-1 cust-textColor">
                        Complete 10 rides a day and win ₹1,000 bonus
                      </p>
                      <p className="text-gray-300 sm-fsize14 textColor-light">
                        Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </div>
                  <div className="w-2/5 ">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="img"
                      className="rounded-r-lg w-full sm-h200px"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className={
                    colorMode === 'light'
                      ? 'cust-shadow bg-fff rounded-lg flex  theme-bg themeShadow'
                      : 'cust-shadow bg-fff rounded-lg flex cust-bgColor theme-bg themeShadow'
                  }
                >
                  <div className="w-3/5 flex items-center px-4">
                    <div>
                      <p className="fsize20 md-fsize18 sm-fsize16 font-semibold pb-1 cust-textColor">
                        Complete 10 rides a day and win ₹1,000 bonus
                      </p>
                      <p className="text-gray-300 sm-fsize14 textColor-light">
                        Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </div>
                  <div className="w-2/5 ">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="img"
                      className="rounded-r-lg w-full sm-h200px"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRideHistory1;
