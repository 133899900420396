import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-14 md:py-16 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h3
            id="4555909181"
            className="dynamicStyle fise16 md-fsize16 sm-fsize14 font-medium text-primary cust-textColor"
          >
            {data ? data['4555909181'] : 'Lorem ipsum'}
          </h3>
          <h2 id="7966782544" className="dynamicStyle section_header">
            {data ? data['7966782544'] : 'Lorem ipsum'}
          </h2>
          <div className="lg:w-2/3 md:w-3/4 mx-auto">
            <ReactQuill
              id="1418872247"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['1418872247'] : 'Lorem Ipsum'}
            />
          </div>
        </div>
        <div id="4618848862" className="dynamicStyle">
          <div className="lg:mt-16 md:mt-16 mt-12 prime_card_container grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 w-full gap-4">
            {data && data['4618848862']
              ? data['4618848862'].map(e => {
                  return (
                    <Card className="prime_card animation-duration-500 lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center themeShadow theme-bg">
                      <div className="lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center">
                        <div className="md:w-full lg:w-3/12 w-full lg:h-full md:h-auto lg:mb-0 md:mb-4 mb-4">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="image"
                            imageClassName="lg:h-32 md:h-32 h-24 w-full object-cover rounded-sm"
                          />
                        </div>
                        <div className="lg:w-9/12 md:w-full w-full lg:text-left md:text-left text-center">
                          <h2 className="text-start card_title font-semibold">
                            {e.field2}
                          </h2>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="bubble card_description"
                            value={e.field3}
                          />
                        </div>
                      </div>
                    </Card>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard3;
