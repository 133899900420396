import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection21 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="herosection21-img relative" style={{ background: thisComponent.sectionColor }}
    >
      <div id="7359342077" className="w-full dynamicStyle2">
        <Image
          src={data ? getImage(data['7359342077']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          imageClassName="herosection21-img object-cover w-full"
        />
      </div>
      <div className="absolute top-0 left-0 w-full flex items-center justify-center herosection21-img text-center">
        <div
          ref={elementRef}
          className={`fade-bottom lg:px-12 md:px-8 px-5 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <h3 id="8239850905" className="hero_title dynamicStyle">
            {data ? data['8239850905'] : 'EDUCATE'}
          </h3>
          <ReactQuill
            id="3557342361"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle lg:w-8/12 mx-auto card_description"
            value={data ? data['3557342361'] : 'Lorem Ipsum'}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection21;
