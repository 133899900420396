import { Card, Image, useColorMode } from "@chakra-ui/react";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Heart } from "react-feather";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EcomProductSection7 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const products = [
    {
      name: "Product 1",
      price: 89.0,
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "Product 2",
      price: 99.0,
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "Product 3",
      price: 79.0,
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "Product 4",
      price: 69.0,
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "Product 5",
      price: 59.0,
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "Product 6",
      price: 49.0,
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
  ];

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  const [isHeartRed, setIsHeartRed] = useState(false);

  const toggleHeart = () => {
    setIsHeartRed(!isHeartRed);
  };

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <section
      className='' style={{ background: thisComponent.sectionColor }}
    >
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <div className="text-center lg:w-2/3 md:w-2/3 w-full mx-auto lg:mb-10 md:mb-12 mb-6">
          <h2
            id="1017976020"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold clr-fff cust-textColor theme-textColor"
          >
            {data ? data["1017976020"] : "Featured Products"}
          </h2>
        </div>
        <div className="relative w-full lg:px-20 md:px-12 px-8">
          <div className="card-swiper-button-previous p-1 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="65"
              viewBox="0 0 65 65"
              stroke="currentColor"
              color="white"
              fill="currentColor"
              stroke-width="0"
              className="sm-w-2rem"
            >
              <path
                d="M16.2501 43.3334V35.2084L59.5834 35.2084V29.7917L16.2501 29.7917V21.6667L5.41673 32.5L16.2501 43.3334Z"
                fill="#fff"
              />
            </svg>
          </div>
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".card-swiper-button-previous",
              prevEl: ".card-swiper-button-next",
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 2.5,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
            }}
          >
            {/* <div className="">
              

              <div className="image-swiper-button-prev p-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65"
                  height="65"
                  viewBox="0 0 65 65"
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  className="sm-w-2rem"
                >
                  <path
                    d="M16.2501 43.3334V35.2084L59.5834 35.2084V29.7917L16.2501 29.7917V21.6667L5.41673 32.5L16.2501 43.3334Z"
                    fill="#232323"
                  />
                </svg>
              </div>
              <div className="image-swiper-button-next p-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65"
                  height="65"
                  viewBox="0 0 65 65"
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  className="sm-w-2rem"
                >
                  <path
                    d="M48.7499 21.6666V29.7916H5.41663V35.2083H48.7499V43.3333L59.5833 32.5L48.7499 21.6666Z"
                    fill="#232323"
                  />
                </svg>
              </div>
            </div> */}
            {products.map((product, index) => (
              <SwiperSlide key={index}>
                <Card
                  className={
                    colorMode === "light"
                      ? "relative px-4 lg:py-8 md:py-8 py-6 rounded-lg themeShadow theme-bg"
                      : "relative px-4 lg:py-8 md:py-8 py-6 rounded-lg themeShadow theme-bg"
                  }
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="w-full h-32 object-contain rounded-lg"
                  />
                  <div className="text-center mt-4">
                    <p className="fsize14 text-black cust-textColor font-semibold lineClamp2">
                      {product.name}
                    </p>
                    <p className="fsize16 clr-b9b9b9 textColor-light mt-2">
                      &#8377; 89.00
                    </p>
                  </div>
                  <div
                    className="absolute top-3 right-3 cursor-pointer"
                    onClick={toggleHeart}
                  >
                    <Heart
                      color={isHeartRed ? "red" : "black"}
                      style={{ fill: isHeartRed ? "red" : "none" }}
                    />
                  </div>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="card-swiper-button-next p-1 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="65"
              viewBox="0 0 65 65"
              stroke="currentColor"
              color="white"
              fill="currentColor"
              stroke-width="0"
              className="sm-w-2rem"
            >
              <path
                d="M48.7499 21.6666V29.7916H5.41663V35.2083H48.7499V43.3333L59.5833 32.5L48.7499 21.6666Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
        <div className="mt-8 text-center">
          <Button outlined className="px-6 py-2 dynamicStyle" id="9181415675">
            <span onClick={() => handleClick("9181415675")}>
              {data ? data["9181415675"] : "Go to the Shop"}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default EcomProductSection7;
