import { Input } from "@chakra-ui/react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import React from "react";

const Login2 = () => {
  return (
    <>
      {/* change-password */}
      <div className="prime_container h-100vh flex justify-center items-center ">
        <div className="lg:w-5/12 md:w-2/3 w-full mx-auto lg:px-0 md:px-0 px-5">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            imageClassName="w-24 h-24 mx-auto rounded-full"
          />
          <div className="lg:mt-8 md:mt-6 mt-4">
            <h2 className="text-center fsize36 md-fsize32 sm-fsize24 font-semibold cust-textColor">
              Change Password
            </h2>
            <p className="text-center fsize14 textColor-light">
              Reset your new password
            </p>
            <div className="mt-8">
              <Input
                placeholder="Enter New Password"
                className="mb-4 cust-textColor"
                py="6"
              />
              <Input
                placeholder="Confirm New Password"
                className="mb-6 cust-textColor"
                py="6"
              />
            </div>

            <Button
              colorScheme="primary"
              className="w-full b_button_primary px-6 py-2"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login2;
