import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const navigate = useNavigate();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <section className="theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:flex md:flex sm-reverse items-center">
        <div
          ref={elementRef}
          className={`fade-left lg:w-1/2 md:w-1/2 w-full lg:pl-20 md:pl-10 pl-6 lg:pr-0 md:pr-0 pr-6 lg:py-0 md:py-0 py-10 ${
            isVisible ? 'visibleleft' : ''
          } `}
        >
          <div className="flex items-center gap-4">
            <p
              className="lg:text-base md:text-sm theme-textColor text-smdynamicStyle"
              id="4532162874"
            >
              {data ? data['4532162874'] : 'EDUCATE'}
            </p>
            <span className="block h-2 w-2 bg-primary  rounded-full"> </span>
            <p
              className="lg:text-base md:text-sm theme-textColor text-smdynamicStyle"
              id="2494898441"
            >
              {data ? data['2494898441'] : 'CONNECT'}
            </p>
            <span className="block h-2 w-2 bg-primary rounded-full"> </span>
            <p
              className="lg:text-base md:text-sm theme-textColor text-sm dynamicStyle"
              id="8347387826"
            >
              {data ? data['8347387826'] : 'MOTIVATE'}
            </p>
          </div>

          <div className="mt-2">
            <h2
              class="lg:text-6xl text-end md:text-3xl  text-xl text-primary font-semibold dynamicStyle"
              id="2067155663"
            >
              {data ? data['2067155663'] : 'Lorem Ipsum'}
            </h2>
          </div>

          <div className="text-end lg:pl-20 lg:mt-6 md:mt-4 md:pl-0">
          <ReactQuill
                id="8645751504"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data['8645751504'] : 'Lorem Ipsum'}
              />
            <div
              // onClick={() => navigate('/coach')}
              className="lg:pr-8 md:pr-8 relative md:pl-0 lg:mt-6 md:mt-6 mt-6"
            >
              {/* <input
                className="inputsearch text-white"
                placeholder="Search for trainers....."
              />
              <div className="absolute lg:right-12 md:right-12 right-4 top11">
                <FeatherIcon
                  icon="search"
                  size={20}
                  className="text-white ml-2"
                />
              </div> */}

              <Button
                className="theme-btn rounded-full text-center dynamicStyle b_button_primary px-6 py-2"
                id="4443636199"
              >
                <span onClick={() => handleClick('4443636199')}>
                  {data ? data['4443636199'] : 'FIND TRAINER'}
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full lg:flex md:flex justify-end">
          <div id="8392435683" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['8392435683']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              imageClassName="w-full minh500px object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection1;
