import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';

const Boardofdirectors2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end
   
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <h2
          id="9045398871"
          className="dynamicStyle header_align section_header"
        >
          {data ? data['9045398871'] : 'Key Management Team'}
        </h2>

        <div
          ref={elementRef}
          className={`fade-bottom lg:mt-16 md:mt-16 mt-12 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid gap-4 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <Card className="prime_card_0 overflow-hidden themeShadow themeShadow-insert theme-bg">
            <div id="3688270895" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['3688270895']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="w-full h-40 object-contain lg:h-52"
                alt="board"
              />
            </div>
            <div className="lg:p-4 md:p-4 p-3 text-center">
              <h6 id="2207494494" className="dynamicStyle card_title_0">
                {data ? data['2207494494'] : 'Mr Dinesh Khara'}
              </h6>
              <p id="1369953031" className="dynamicStyle card_description">
                {data
                  ? data['1369953031']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </Card>
          <Card className="prime_card_0 overflow-hidden themeShadow themeShadow-insert theme-bg">
            <div id="7100296289" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['7100296289']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="w-full h-40 object-contain lg:h-52"
                alt="board"
              />
            </div>
            <div className="lg:p-4 md:p-4 p-3 text-center">
              <h6 id="9309426218" className="dynamicStyle card_title_0">
                {data ? data['9309426218'] : 'Mr Dinesh Khara'}
              </h6>
              <p id="6278829433" className="dynamicStyle card_description">
                {data
                  ? data['6278829433']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </Card>
          <Card className="prime_card_0 overflow-hidden themeShadow themeShadow-insert theme-bg">
            <div id="1330311467" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['1330311467']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="w-full h-40 object-contain lg:h-52"
                alt="board"
              />
            </div>
            <div className="lg:p-4 md:p-4 p-3 text-center">
              <h6 id="9943564981" className="dynamicStyle card_title_0">
                {data ? data['9943564981'] : 'Mr Dinesh Khara'}
              </h6>
              <p id="8513718892" className="dynamicStyle card_description">
                {data
                  ? data['8513718892']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Boardofdirectors2;
