import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HeroSection28 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const TripOptions = [
    { value: "Mumbai", label: "Mumbai" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Goa", label: "Goa" },
  ];

  const PickupOptions = [
    { value: "Feb18, 2023", label: "Feb18, 2023" },
    { value: "Feb22, 2023", label: "Feb22, 2023" },
    { value: "March24, 2023", label: "March24, 2023" },
  ];

  const PickupTimeOptions = [
    { value: "3:00 PM", label: "3:00 PM" },
    { value: "2:10 AM", label: "2:10 AM" },
    { value: "5:00 PM", label: "5:00 PM" },
  ];

  const [trip, setTrip] = useState(TripOptions[0].value);
  const [pickup, setPickup] = useState(PickupOptions[0].value);
  const [pickupTime, setPickupTime] = useState(PickupTimeOptions[0].value);

  const handleTrip = (e) => {
    setTrip(e.target.value);
  };

  const handlePickup = (e) => {
    setPickup(e.target.value);
  };

  const handlePickupTime = (e) => {
    setPickupTime(e.target.value);
  };
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end

  return (
    <section style={{ background: thisComponent.sectionColor }}>
      <div>
        <div className="relative">
          <div id="4495298554" className="dynamicStyle2">
            <Image
              src={data ? getImage(data["4495298554"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              imageClassName="relative h-450px sm-h300px w-full object-cover"
              alt="herosection"
            />
          </div>
          <div className="lg:absolute md:absolute bottom-0 left-0 w-full  minus-margintop">
            <Card className="prime_card z-10 lg:w-7/12 md:w-10/12 lg:mx-auto md:mx-auto mx-3">
              <div className=" lg:flex md:flex gap-6 justify-between items-center themeShadow">
                <div className="lg:gap-8 md:gap-8 gap-4 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2">
                  <div className="lg:pl-0 md:pl-0 pl-4">
                    <p className="h-2rem text_surface500 flex items-center fsize14">
                      Pick-up Location
                    </p>

                    <p className="mt-1 fsize16 font-medium">Pune</p>
                  </div>
                  <div className="">
                    <Dropdown
                      placeholder="Pick Up Date"
                      value={pickup}
                      onChange={handlePickup}
                      options={PickupOptions}
                    ></Dropdown>
                    <p className="mt-1 fsize16 font-medium">{pickup}</p>
                  </div>
                  <div className="">
                    <Dropdown
                      placeholder="Pick Up Date"
                      value={pickupTime}
                      onChange={handlePickupTime}
                      options={PickupTimeOptions}
                    ></Dropdown>
                    <p className="mt-1 fsize16 font-medium">{pickupTime}</p>
                  </div>
                </div>
                <div className="flex lg:justify-end justify-center lg:mt-0 md:mt-0 mt-6">
                  <Button
                    id="7930796905"
                    className="dynamicStyle b_button_primary theme-btn  sm-fsize14 px-6 py-2"
                  >
                    <span onClick={() => handleClick("7930796905")}>
                      {data ? data["7930796905"] : "Search"}
                    </span>
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="bg-secondary lg:py-16 md:py-16 py-8 ">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 lg:gap-10 md:gap-8 gap-6 lg:px-0 md:px-0 px-5 lg:mt-6 mt-4 mt-3 lg:w-7/12 md:w-10/12  mx-auto">
            <Card className="themeShadow prime_card">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                imageClassName="w-full h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="card_title_0 text-center">Lorem</p>
            </Card>
            <div className="themeShadow prime_card">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                imageClassName="w-full h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="card_title_0 text-center">Lorem</p>
            </div>
            <div className="themeShadow prime_card">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                imageClassName="w-full h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="card_title_0 text-center">Lorem</p>
            </div>
            <div className="themeShadow prime_card">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                imageClassName="w-full h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="card_title_0 text-center">Lorem</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection28;
