const initialState = {
  Podcasts: [],
  selectedPodcast: {},
  PodcastLoadMore: {},
};
const PodcastDetails = (state = initialState, action) => {
  switch (action.type) {
    case "setPodcast":
      return { ...state, Podcasts: action.payload };

    case "setSelectedPodcast":
      return { ...state, selectedPodcast: action.payload };

    case "setPodcastLoadMore":
      return { ...state, PodcastLoadMore: action.payload };
    default:
      return state;
  }
};

export default PodcastDetails;
