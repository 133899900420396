import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard62 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="">
          <p
            id="4179041710"
            className="dynamicStyle lg:w-1/2 md:w-1/2 section_header"
          >
            {data ? data['4179041710'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="3788473181"
            theme="bubble"
            readOnly
            className="dynamicStyle lg:w-1/2 md:w-2/3 border-left-1px pl-4 section_detail bubble"
            value={data ? data['3788473181'] : 'no-data'}
          />
        </div>
        <div
          id="9747466522"
          className="dynamicStyle prime_card_container grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12"
        >
          {data && data['9747466522']
            ? data['9747466522'].map(e => {
                return (
                  <Card
                    key={e._id}
                    className="bg-f4f4f4 prime_card themeShadow theme-bg"
                  >
                    <div className="flex justify-between items-start mb-4">
                      <div className="flex gap-2 items-center">
                        <Image
                          src={process.env.REACT_APP_STORAGE_URL + e.field1}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="w-14 h-14 rounded-full object-contain"
                        />
                        <div>
                          <p className="font-semibold sm-fsize14 cust-textColor">
                            {e.field2}
                          </p>
                          <p className="text-gray-400 font-light sm-fsize14 textColor-light">
                            {e.field3}
                          </p>
                        </div>
                      </div>
                      <Button className="b_button_primary theme-btn px-2  py-1 fsize12 clr-fff">
                        {e.field4}
                      </Button>
                    </div>
                    <div>
                      <h4 className="card_title">{e.field5}</h4>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="card_description bubble"
                        value={e.field6}
                      />
                    </div>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard62;
