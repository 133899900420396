import React from "react";
import { Image } from "primereact/image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { useSelector } from "react-redux";

const CardLayout4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <section className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="flex gap-4 justify-center items-center lg:mb-6 md:mb-6 mb-4">
          <hr className="hr_card4" />
          <p
            id="8677795699"
            className="dynamicStyle text-primary fsize24 sm-fsize20 font-semibold"
          >
            {data ? data["8677795699"] : "Lorem Ipsum"}
          </p>
          <hr className="hr_card4" />
        </div>
        <h2
          id="4094527700"
          className="dynamicStyle fsize40 text-center font-semibold"
        >
          {data ? data["4094527700"] : "Lorem Ipsum"}
        </h2>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 lg:gap-8 md:gap-8 gap-6 lg:mt-16 md:mt-16 mt-12">
          <div className="lg:flex md:flex gap-4 lg:mb-6 md:mb-4 mb-2">
            <div className="lg:w-2/5 md:w-2/5 w-3/5 mx-auto lg:mb-0 md:mb-0 mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="w-full h-32 rounded-lg object-cover"
              />
            </div>
            <div className="lg:w-3/5 md:w-3/5">
              <p className="fsize14 sm-fsize12 text-primary pb-1">Podcast</p>
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_title"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque."
                }
              />
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_description lineclamp-quill1"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque. dolor sit amet consectetur adipisicing elit."
                }
              />
            </div>
          </div>
          <div className="lg:flex md:flex gap-4 lg:mb-6 md:mb-4 mb-2">
            <div className="lg:w-2/5 md:w-2/5 w-3/5 mx-auto lg:mb-0 md:mb-0 mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="w-full h-32 rounded-lg object-cover"
              />
            </div>
            <div className="lg:w-3/5 md:w-3/5">
              <p className="fsize14 sm-fsize12 text-primary pb-1">Podcast</p>
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_title"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque."
                }
              />
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_description lineclamp-quill1"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque. dolor sit amet consectetur adipisicing elit."
                }
              />
            </div>
          </div>
          <div className="lg:flex md:flex gap-4 lg:mb-6 md:mb-4 mb-2">
            <div className="lg:w-2/5 md:w-2/5 w-3/5 mx-auto lg:mb-0 md:mb-0 mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="w-full h-32 rounded-lg object-cover"
              />
            </div>
            <div className="lg:w-3/5 md:w-3/5">
              <p className="fsize14 sm-fsize12 text-primary pb-1">Podcast</p>
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_title"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque."
                }
              />
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_description lineclamp-quill1"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque. dolor sit amet consectetur adipisicing elit."
                }
              />
            </div>
          </div>
          <div className="lg:flex md:flex gap-4 lg:mb-6 md:mb-4 mb-2">
            <div className="lg:w-2/5 md:w-2/5 w-3/5 mx-auto lg:mb-0 md:mb-0 mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="w-full h-32 rounded-lg object-cover"
              />
            </div>
            <div className="lg:w-3/5 md:w-3/5">
              <p className="fsize14 sm-fsize12 text-primary pb-1">Podcast</p>
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_title"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque."
                }
              />
              <ReactQuill
                id="3703285085"
                theme="bubble"
                readOnly
                className="bubble card_description lineclamp-quill1"
                // value={data ? data["3703285085"] : "Lorem Ipsum"}
                value={
                  "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, doloremque. dolor sit amet consectetur adipisicing elit."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardLayout4;
