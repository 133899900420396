import API from './repositoryFactory';

const GET = 'get';
const UPDATE = 'update';

export default {
  getBrandProfile(payload) {
    return API.post(apiBaseRoute(GET), payload);
  },
  updateBrandProfile(payload) {
    return API.post(apiBaseRoute(UPDATE), payload);
  },
};

const apiBaseRoute = route => {
  return `miniStore/brandProfile/${route}`;
};
