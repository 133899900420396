import React from 'react';
import { useState } from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import { Image, useColorMode } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const CounterSection4 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [counterOn, setCounterOn] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="lg:flex md:flex sm-reverse">
      <div
        id="6711914169"
        className="dynamicStyle2 w-55per sm-wfull flex justify-center items-center relative"
      >
        <Image
          className="w-full h-full sm-h250px"
          src={data ? getImage(data['6711914169']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt=""
        />
        <div className="w-70 sm-w-70 lg:py-0 md:py-0 py-4 absolute">
          <h2
            id="6224875039"
            className="dynamicStyle fsize40 md-fsize36 sm-fsize24 font-semibold cust-textColor text-black"
          >
            {data ? data['6224875039'] : ' Lorem Ipsum'}
          </h2>
          <ReactQuill
                id="4861314546"
                theme="bubble"
                readOnly
                className={
                  colorMode === 'dark'
                    ? 'dark-bubble bubble dynamicStyle lg:py-4 md:py-4 py-2'
                    : 'bubble dynamicStyle lg:py-4 md:py-4 py-2'
                }
                value={data ? data['4861314546'] : 'Lorem Ipsum'}
              />
        </div>
      </div>
      <div
        className={
          colorMode === 'light'
            ? 'w-45per sm-wfull bg-primary  grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2'
            : 'w-45per sm-wfull grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 cust-bgColor'
        }
      >
        <div className="lg:py-16 md:py-16 py-4 lg:px-16 md:px-4 px-4 bdr-rbot flex justify-center items-center">
          <div>
            <div className="flex items-center justify-center text-primary">
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp
                    className="font-bold fsize50 md-fsize30 sm-fsize24 text-black cust-textColor"
                    start={0}
                    end={200}
                    duration={4}
                    delay={0}
                  />
                )}
              </ScrollTrigger>
              <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 text-black cust-textColor">
                +
              </p>
            </div>
            <p
              id="0158119858"
              className="dynamicStyle text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-black textColor-light"
            >
              {data ? data['0158119858'] : 'Lorem Ipsum'}
            </p>
          </div>
        </div>
        <div className="lg:py-16 md:py-16 py-4 lg:px-16 md:px-4 px-4 bdr-bottom flex justify-center items-center">
          <div>
            <div className="flex items-center justify-center text-primary">
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp
                    className="font-bold fsize50 md-fsize30 sm-fsize24 text-black cust-textColor"
                    start={0}
                    end={100}
                    duration={4}
                    delay={0}
                  />
                )}
              </ScrollTrigger>
              <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 text-black cust-textColor">
                +
              </p>
            </div>
            <p
              id="3790807402"
              className="dynamicStyle text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-black textColor-light"
            >
              {data ? data['3790807402'] : 'Lorem Ipsum'}
            </p>
          </div>
        </div>
        <div className="lg:py-16 md:py-16 py-4 lg:px-16 md:px-4 px-4 bdr-right flex justify-center items-center">
          <div>
            <div className="flex items-center justify-center text-primary">
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp
                    className="font-bold fsize50 md-fsize30 sm-fsize24 text-black cust-textColor"
                    start={0}
                    end={83}
                    duration={4}
                    delay={0}
                  />
                )}
              </ScrollTrigger>
              <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 text-black cust-textColor">
                +
              </p>
            </div>
            <p
              id="7208831516"
              className="dynamicStyle text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-black textColor-light"
            >
              {data ? data['7208831516'] : 'Lorem Ipsum'}
            </p>
          </div>
        </div>
        <div className="lg:py-16 md:py-16 py-4 lg:px-16 md:px-4 px-4 flex justify-center items-center ">
          <div>
            <div className="flex items-center justify-center text-primary">
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp
                    className="font-bold fsize50 md-fsize30 sm-fsize24 text-black cust-textColor"
                    start={0}
                    end={285}
                    duration={4}
                    delay={0}
                  />
                )}
              </ScrollTrigger>
              <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 text-black cust-textColor">
                +
              </p>
            </div>
            <p
              id="4946379816"
              className="dynamicStyle text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-black textColor-light"
            >
              {data ? data['4946379816'] : 'Lorem Ipsum'}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection4;
