import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";

const NewsletterSection9 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="relative overflow-hidden lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <form onSubmit={formik.handleSubmit} className="">
          <div className="lg:flex md:flex items-center lg:gap-10 md:gap-6 relative">
            <div
              id="7281962542"
              className="dynamicStyle2 lg:w-1/2 md:w-1/2 h-500px md-h-400px sm-h-250px lg:mb-0 md:mb-0 mb-6"
            >
              <Image
                src={data ? getImage(data["7281962542"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-news"
                imageClassName="h-500px md-h-400px sm-h-250px w-full z-ind-1 object-cover"
              />
            </div>
            <div className="lg:w-1/2 md:w-1/2 ">
              <p id="5328164855" className="dynamicStyle hero_title">
                {data ? data["5328164855"] : "Lorem Ipsum"}
              </p>
              <ReactQuill
                id="2393093350"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["2393093350"] : "Lorem Ipsum"}
              />

              <div className="flex gap-4 mt-4">
                <div className="flex flex-col gap-2 w-8/12">
                  <InputText
                    name="email"
                    invalid={!!formik.errors.email && formik.touched.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    type="text"
                    placeholder="Email Address"
                    className="w-full bdr-0px theme-border"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="text-danger fsize12 mt-2">
                      {formik.errors.email}
                    </p>
                  )}
                </div>

                <div className="w-4/12">
                  <Button
                    className="dynamicStyle w-full b_button_primary px-6 py-3 theme-btn sm-fsize14 md-fsize14 flex justify-center"
                    id="3754250557"
                  >
                    {data ? data["3754250557"] : "Submit"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="lg:h-24 lg:w-24 md:h-20 md:w-20 rounded-full bg-primary absolute top-0 right-0 mob-hidden"></div>
            <div className="lg:h-36 lg:w-36 md:h-32 md:w-32 rounded-full border-20px absolute absl-news9-1 md-absl-news9-1 mob-hidden"></div>
            <div className="h-6 w-6 rounded-full bg-primary absolute absl-news9-2 mob-hidden"></div>
          </div>
        </form>
      </div>
      <div className="lg:h-56 lg:w-56 md:h-48 md:w-48 rounded-full border-16px absolute absl-news9-3 mob-hidden"></div>
    </div>
  );
};

export default NewsletterSection9;
