import { CheckIcon, CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { FiEye } from 'react-icons/fi';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';

const ApprovalResults = () => {
  const {
    isOpen: isOpenApprove,
    onOpen: onOpenApprove,
    onClose: onCloseApprove,
  } = useDisclosure();
  return (
    <div className="w-full pt-8">
      <Modal isOpen={isOpenApprove} onClose={onCloseApprove}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div className=""></div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="lg:flex md:flex items-baseline gap-2">
        <h2 className="fsize28 sm-fsize20 text-primary">Results</h2>
        <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
      </div>
      <div className="lg:mt-12 md:mt-8 mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
        <div className="borderall rounded-lg py-3 px-4">
          <div className="w-full relative">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="KMP"
              id=""
              className="w-1/2 mx-auto h-32 object-contain"
            />
            <div className="absolute top-right-8px p-1 flex justify-center items-center">
              <MoreVertical className="text-gray" />
            </div>
          </div>
          <div className="mt-3">
            <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
              Employe code sample draft
            </h2>
            <p className="text-gray fsize14 sm-fsize12">Microsoft word file</p>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
              <Button label="Preview" icon="pi pi-eye" className='b_button_secondary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
                <Button label="Send to Approval" icon="pi pi-check" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
               
                <Button label="Need to work" icon="pi pi-times" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
          </div>
        </div>
        <div className="borderall rounded-lg py-3 px-4">
          <div className="w-full relative">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="KMP"
              id=""
              className="w-1/2 mx-auto h-32 object-contain"
            />
            <div className="absolute top-right-8px p-1 flex justify-center items-center">
              <MoreVertical className="text-gray" />
            </div>
          </div>
          <div className="mt-3">
            <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
              Employe code sample draft
            </h2>
            <p className="text-gray fsize14 sm-fsize12">Microsoft word file</p>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
              <Button label="Preview" icon="pi pi-eye" className='b_button_secondary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Send to Approval" icon="pi pi-check" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
              <Button label="Need to work" icon="pi pi-times" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
          </div>
        </div>
        <div className="borderall rounded-lg py-3 px-4">
          <div className="w-full relative">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="KMP"
              id=""
              className="w-1/2 mx-auto h-32 object-contain"
            />
            <div className="absolute top-right-8px p-1 flex justify-center items-center">
              <MoreVertical className="text-gray" />
            </div>
          </div>
          <div className="mt-3">
            <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
              Employe code sample draft
            </h2>
            <p className="text-gray fsize14 sm-fsize12">Microsoft word file</p>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
              <Button label="Preview" icon="pi pi-eye" className='b_button_secondary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Send to Approval" icon="pi pi-check" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
              <Button label="Need to work" icon="pi pi-times" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
          </div>
        </div>
        <div className="borderall rounded-lg py-3 px-4">
          <div className="w-full relative">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="KMP"
              id=""
              className="w-1/2 mx-auto h-32 object-contain"
            />
            <div className="absolute top-right-8px p-1 flex justify-center items-center">
              <MoreVertical className="text-gray" />
            </div>
          </div>
          <div className="mt-3">
            <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
              Employe code sample draft
            </h2>
            <p className="text-gray fsize14 sm-fsize12">Microsoft word file</p>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
              <Button label="Preview" icon="pi pi-eye" className='b_button_secondary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Send to Approval" icon="pi pi-check" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className="w-full">
              <Button label="Need to work" icon="pi pi-times" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalResults;
