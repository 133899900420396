import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { Button } from 'primereact/button';
import React from 'react';

const KMPreviewer = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-18 py-6">
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="w-full lg:flex md:flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <h2 className="fsize20 sm-fsize16 text-primary" id="">
              Knowledge Mangamement Portal
            </h2>
            <EditIcon className="text-primary " />
          </div>
          <div className="">
            <Button outlined label="Add Group" icon="pi pi-plus" className='b_button_primary px-6 py-2' />
          </div>
        </div>
        <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-8">
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
        </div>
      </div>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Processes
          </h2>
          <EditIcon className="text-primary " />
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-8">
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
        </div>
      </div>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Information
          </h2>
          <EditIcon className="text-primary " />
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-8">
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Others
          </h2>
          <EditIcon className="text-primary " />
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-8">
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
          <div className="">
            <div className="relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-full h-32 object-cover rounded-lg"
              />
              <div className="absolute top-right-8px bg-f98c27 text-white rounded-full w-6 h-6 flex justify-center items-center">
                <p className="fsize12">8</p>
              </div>
            </div>

            <p className="mt-3 text-center text-primary fsize14">Assets</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KMPreviewer;
