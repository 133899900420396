import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AboutSection51 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center gap-4 w-full">
          <div
            id="5862176209"
            className="dynamicStyle2 lg:w-3/12 md:w-3/12 w-full"
          >
            <Image
              src={data ? getImage(data['5862176209']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img-about"
              imageClassName="w-full lg:h-56 md:h-40 h-40 object-cover rounded-sm"
            />
          </div>
          <div className="lg:w-6/12 md:w-6/12 lg:my-0 md:my-0 my-4">
            <p
              id="1079892595"
              className="dynamicStyle fsize16 md-fsize16 sm-fsize16 text-primary text-center"
            >
              {' '}
              {data ? data['1079892595'] : 'no-data'}
            </p>
            <h5
              id="6652409574"
              className="dynamicStyle about_title text-center"
            >
              {data ? data['6652409574'] : 'no-data'}
            </h5>
            <div className="flex justify-center gap-4">
              <Button
                className="theme-btn dynamicStyle b_button_primary px-6 py-2 fsize14 md-fsize14 sm-fsize14"
                id="8824429485"
              >
                <span onClick={() => handleClick('8824429485')}>
                  {data ? data['8824429485'] : 'View More'}
                </span>
              </Button>
            </div>
          </div>
          <div
            id="4840377203"
            className="dynamicStyle2 lg:w-3/12 md:w-3/12 w-full"
          >
            <Image
              src={data ? getImage(data['4840377203']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              imageClassName="w-full lg:h-56 md:h-40 h-40 object-cover rounded-sm"
            />
          </div>
        </div>

        <div className="flex justify-center gap-12 lg:mt-12 md:mt-4 mt-4">
          <div
            id="6163982745"
            className="dynamicStyle2 lg:w-3/12 md:w-3/12 w-full"
          >
            <Image
              src={data ? getImage(data['6163982745']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              imageClassName="w-full lg:h-56 md:h-40 h-40 object-cover rounded-sm"
            />
          </div>
          <div
            id="8376511360"
            className="dynamicStyle2 lg:w-3/12 md:w-3/12 w-full"
          >
            <Image
              src={data ? getImage(data['8376511360']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              imageClassName="w-full lg:h-56 md:h-40 h-40 object-cover rounded-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection51;
