import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Image } from "primereact/image";

const GridCard1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <Card className="box-shadow-none">
            <div
              id="3461419327"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["3461419327"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="9902554544">
                {data ? data["9902554544"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="2775932328"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["2775932328"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="box-shadow-none">
            <div
              id="3597067657"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["3597067657"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="0346300864">
                {data ? data["0346300864"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="2191999616"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["2191999616"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="box-shadow-none">
            <div
              id="1069633456"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["1069633456"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="3930927995">
                {data ? data["3930927995"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="0451369345"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["0451369345"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="box-shadow-none">
            <div
              id="8860439808"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["8860439808"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="1280992136">
                {data ? data["1280992136"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="3464263367"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["3464263367"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="box-shadow-none">
            <div
              id="5392130466"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["5392130466"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="8997519667">
                {data ? data["8997519667"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="0433352717"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["0433352717"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="box-shadow-none">
            <div
              id="0971903166"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["0971903166"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="0915657872">
                {data ? data["0915657872"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="2577898809"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["2577898809"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="box-shadow-none">
            <div
              id="6765634508"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["6765634508"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="6746840093">
                {data ? data["6746840093"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="3744599205"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["3744599205"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
          <Card className="box-shadow-none">
            <div
              id="3510250230"
              className="dynamicStyle2 w-full overflow-hidden rounded-lg"
            >
              <Image
                src={data ? getImage(data["3510250230"]) : ""}
                imageClassName="w-full h-40 rounded-lg custimghover"
                alt="women"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              />
            </div>
            <div className="p-2">
              <h3 className="card_title_0 dynamicStyle" id="0000858645">
                {data ? data["0000858645"] : "Lorem Ipsum"}
              </h3>
              <ReactQuill
                id="1568822469"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["1568822469"] : "Lorem Ipsum"}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default GridCard1;
