import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Clients9 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container ">
        <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
          <h2
            id="5145450506"
            className="dynamicStyle section_header text-center"
          >
            {data ? data['5145450506'] : 'Lorem ipsum'}
          </h2>
          <ReactQuill
            id="2254296986"
            theme="bubble"
            readOnly
            className="bubble2 section_detail dynamicStyle"
            value={data ? data['2254296986'] : 'Lorem Ipsum'}
          />
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-10 gap-6 w-full lg:mt-10 md:mt-8 mt-5">
          <div className="w-full">
            <div id="6817280223" className="dynamicStyle">
              <Image
                src={data ? getImage(data['6817280223']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2 id="6623795272" className="dynamicStyle card_title text-center">
              {data ? data['6623795272'] : 'Lorem ipsum'}
            </h2>
          </div>
          <div className="w-full">
            <div id="5804413505" className="dynamicStyle">
              <Image
                src={data ? getImage(data['5804413505']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2 id="5685179790" className="dynamicStyle card_title text-center">
              {data ? data['5685179790'] : 'Lorem ipsum'}
            </h2>
          </div>
          <div className="w-full">
            <div id="0679910146" className="dynamicStyle">
              <Image
                src={data ? getImage(data['0679910146']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2 id="7141688549" className="dynamicStyle card_title text-center">
              {data ? data['7141688549'] : 'Lorem ipsum'}
            </h2>
          </div>
          <div className="w-full">
            <div id="3913018646" className="dynamicStyle">
              <Image
                src={data ? getImage(data['3913018646']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                imageClassName="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2 id="4873360576" className="dynamicStyle card_title text-center">
              {data ? data['4873360576'] : 'Lorem ipsum'}
            </h2>
          </div>
        </div>
        <div className="lg:mt-12 md:mt-10 mt-5">
          <ReactQuill
            id="5893195741"
            theme="bubble"
            readOnly
            className="bubble2 section_detail dynamicStyle"
            value={data ? data['5893195741'] : 'Lorem Ipsum'}
          />
        </div>
      </div>
    </section>
  );
};

export default Clients9;
