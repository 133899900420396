import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import React, { useEffect, useRef, useState } from "react";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection19 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  let selectedStyle = useSelector(
    (state) => state.brandDetails.brandProfile.businessThemeVariation
  );
  // console.log(selectedStyle,'ss');
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex sm-custdirection md-custdirection items-center lg:gap-10 md:gap-8 gap-5">
          <div className="lg:w-7/12 md:w-full w-full">
            <h2
              id="4767383332"
              className="dynamicStyle theme-textColor hero_title"
            >
              {data ? data["4767383332"] : "Lorem Ipsum"}
            </h2>
            <div className="px-4 lg:py-2 md:py-2 py-1 bdr-left-3px my-4">
              <ReactQuill
                id="3085161471"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["3085161471"] : "Lorem Ipsum"}
              />
            </div>
            <div className="lg:flex md:flex gap-8 lg:py-4 md:py-3 py-3">
              <Button
                id="4515473888"
                className="theme-btn dynamicStyle b_button_primary px-6 py-2 fsize14 sm-fsize14 rounded-full"
              >
                <span onClick={() => handleClick("4515473888")}>
                  {data ? data["4515473888"] : "Lorem Ipsum"}
                </span>
              </Button>
              <div className="flex items-center gap-2 lg:mt-0 md:mt-0 mt-3">
                <AvatarGroup>
                  <Avatar
                    name="Ryan Florence"
                    image="https://bit.ly/ryan-florence"
                    shape="circle"
                  />
                  <Avatar
                    name="Segun Adebayo"
                    image="https://bit.ly/sage-adebayo"
                    shape="circle"
                  />
                  <Avatar
                    name="Kent Dodds"
                    image="https://bit.ly/kent-c-dodds"
                    shape="circle"
                  />
                  <Avatar
                    name="Prosper Otemuyiwa"
                    src="https://bit.ly/prosper-baba"
                    shape="circle"
                  />
                  <Avatar
                    name="Prosper Otemuyiwa"
                    src="https://bit.ly/prosper-baba"
                    shape="circle"
                  />
                  <Avatar
                    name="Christian Nwamba"
                    src="https://bit.ly/code-beast"
                    shape="circle"
                  />
                </AvatarGroup>
                <p id="9491129980" className="dynamicStyle fsize12">
                  {data ? data["9491129980"] : "Lorem"}
                </p>
              </div>
            </div>
            <div className="flex gap-5 ">
              <div>
                <p id="7483828874" className="dynamicStyle hero_title_0">
                  {data ? data["7483828874"] : "1000+"}
                </p>
                <p id="5276268359" className="dynamicStyle card_description">
                  {data ? data["5276268359"] : "Lorem Ipsum"}
                </p>
              </div>
              <div>
                <p id="5782125732" className="dynamicStyle hero_title_0">
                  {data ? data["5782125732"] : "1500"}
                </p>
                <p id="7355619575" className="dynamicStyle card_description">
                  {data ? data["7355619575"] : "Lorem Ipsum"}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:ml-0 md:ml-20 lg:w-5/12 md:w-full w-full lg:flex md:flex block lg:justify-end">
            <div
              ref={elementRef}
              className={`lg:mr-0 md:mr-0 mr-0 fade-right img-right-back relative rounded-2xl bg-primary lg:w-3/4 md:w-1/2 w-4/5 ${
                isVisible ? "visibleright" : ""
              } `}
            >
              <div
                id="2465301963"
                className="dynamicStyle2 img-right z-10 rounded-2xl w-full"
              >
                <Image
                  src={data ? getImage(data["2465301963"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="img-right object-cover absolute-cards lg:absolute md:absolute absolute z-20 rounded-2xl lg:w-full md:w-full w-full"
                  alt="cards"
                  style={{ transform: transform }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection19;
