import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection14 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:flex md:flex sm-reverse h-full items-center">
        <div
          ref={elementRef}
          className={`fade-left lg:px-20 lg:my-0 md:my-0 my-6 md:px-8 px-5 lg:w-3/5 md:w-3/5 flex items-center ${
            isVisible ? 'visibleleft' : ''
          } `}
        >
          <div>
            <h2 id="2154722687" className="dynamicStyle hero_title uppercase">
              {data ? data['2154722687'] : ' 700+ Expert Coaches'}
            </h2>
            {/* <h2 className="lg:text-4xl md:text-2xl uppercase text-end text-xl font-semibold">
              You Could Be One Of Them
            </h2> */}
            <ReactQuill
              id="3619909429"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['3619909429'] : 'Lorem Ipsum'}
            />
          </div>
        </div>

        <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
          <div id="6920255080" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['6920255080']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              imageClassName="object-cover w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection14;
