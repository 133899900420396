import { Button } from "primereact/button";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";

const DocumentSection1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="flex items-center w-full mb-6">
          <div className="bg-primary lg:px-5 px-3 py-2 rounded-lg ">
            <p
              id="9274650242"
              className="dynamicStyle text-white my-0 fsize16 sm-fsize12"
            >
              {data ? data["9274650242"] : "2022 - 2023"}
            </p>
          </div>
          <hr className="lg:w-10/12 md:w-9/12 w-8/12 ml-5"></hr>
        </div>
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <div id="3084092309" className="dynamicStyle2 w-20 h-20 mx-auto">
                <Image
                  src={data ? getImage(data["3084092309"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
              </div>
              <p
                id="0103120468"
                className="dynamicStyle mt-4 text-center text_surface800"
              >
                {data
                  ? data["0103120468"]
                  : "SBI Foundation Anual Report 2022 - 2023"}
              </p>

              <Button
                id="9640676071"
                className="dynamicStyle w-full mt-2 b_button_primary px-6 py-2 flex justify-center"
              >
                {data ? data["9640676071"] : " See Results"}
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <div id="7127720107" className="dynamicStyle2 w-20 h-20 mx-auto">
                <Image
                  src={data ? getImage(data["7127720107"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
              </div>
              <p
                id="0828787944"
                className="dynamicStyle mt-4 text-center text_surface800"
              >
                {data
                  ? data["0828787944"]
                  : "SBI Foundation Anual Report 2022 - 2023"}
              </p>

              <Button
                id="1685794492"
                className="dynamicStyle w-full mt-2 b_button_primary px-6 py-2 flex justify-center"
              >
                {data ? data["1685794492"] : "See Results"}
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <div id="8456747330" className="dynamicStyle2 w-20 h-20 mx-auto">
                <Image
                  src={data ? getImage(data["8456747330"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
              </div>
              <p
                id="0305128384"
                className="dynamicStyle mt-4 text-center text_surface800"
              >
                {data
                  ? data["0305128384"]
                  : "SBI Foundation Anual Report 2022 - 2023"}
              </p>

              <Button
                id="1458103843"
                className="dynamicStyle w-full mt-2 b_button_primary px-6 py-2 flex justify-center"
              >
                {data ? data["1458103843"] : "See Results"}
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <div id="4602571940" className="dynamicStyle2 w-20 h-20 mx-auto">
                <Image
                  src={data ? getImage(data["4602571940"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
              </div>
              <p
                id="5505748919"
                className="dynamicStyle mt-4 text-center text_surface800"
              >
                {data
                  ? data["5505748919"]
                  : "SBI Foundation Anual Report 2022 - 2023"}
              </p>

              <Button
                id="2352096344"
                className="dynamicStyle w-full mt-2 b_button_primary px-6 py-2 flex justify-center"
              >
                {data ? data["2352096344"] : "See Results"}
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <div id="9162962735" className="dynamicStyle2 w-20 h-20 mx-auto">
                <Image
                  src={data ? getImage(data["9162962735"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto"
                  alt="women"
                />
              </div>
              <p
                id="6411786476"
                className="dynamicStyle mt-4 text-center text_surface800"
              >
                {data
                  ? data["6411786476"]
                  : "SBI Foundation Anual Report 2022 - 2023"}
              </p>

              <Button
                id="3219363461"
                className="dynamicStyle w-full mt-2 b_button_primary px-6 py-2 flex justify-center"
              >
                {data ? data["3219363461"] : "See Results"}
              </Button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cursor-pointer border-primary rounded-lg p-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                imageClassName="w-20 h-20 mx-auto"
                alt="women"
              />
              <p className="mt-4 text-center text_surface800">
                SBI Foundation Anual Report 2022 - 2023
              </p>
              <Button className="b_button_primary px-6 py-2 w-full mt-2">
                See Results
              </Button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default DocumentSection1;
