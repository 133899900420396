import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import { TabView, TabPanel } from "primereact/tabview";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const AboutSection32 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="flex sm-reverse">
          <div className="lg:w-1/2 md:w-1/2 w-full flex items-center lg:mt-0 md:mt-0 mt-6">
            <div>
              <h3 id="9074957091" className="dynamicStyle about_title">
                {data ? data["9074957091"] : "Lorem ipsum dolor sit amet."}
              </h3>

              {/* <Tabs position="relative" isFitted variant="unstyled">
                <TabList>
                  <Tab className="font-semibold fsize22 cust-textColor">
                    Our Promise
                  </Tab>
                  <Tab className="font-semibold fsize22 cust-textColor">
                    Our Mission
                  </Tab>
                  <Tab className="font-semibold fsize22 cust-textColor">
                    Our Vision
                  </Tab>
                </TabList>
                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />
                <TabPanels>
                  <TabPanel>
                    <p className="fsize18 md-fsize16 sm-fsize14">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Veniam quisquam reprehenderit eius sit modi architecto
                      recusandae excepturi amet, quidem mollitia!
                    </p>
                  </TabPanel>
                  <TabPanel>
                    <p className="fsize18 md-fsize16 sm-fsize14">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quis neque veniam vero itaque voluptatum dicta nisi
                      temporibus minus repellendus asperiores?
                    </p>
                  </TabPanel>
                  <TabPanel>
                    <p className="fsize18 md-fsize16 sm-fsize14">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Nesciunt, facere!
                    </p>
                  </TabPanel>
                </TabPanels>
              </Tabs> */}
              {/* <TabView>
                <TabPanel header="Our Promise">
                  <p className="m-0 fsize16 md-fsize16 sm-fsize14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </TabPanel>
                <TabPanel header="Our Mission">
                  <p className="m-0 fsize16 md-fsize16 sm-fsize14">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo.
                  </p>
                </TabPanel>
                <TabPanel header="Our Vision">
                  <p className="m-0 fsize16 md-fsize16 sm-fsize14">
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident. sint occaecati
                    cupiditate non provident.
                  </p>
                </TabPanel>
              </TabView> */}
              <div className="lg:mt-8 md:mt-8 mt-4">
              <div className="mb-4">
                <p id="1058682131" className="dynamicStyle card_title_0">
                  {data ? data["1058682131"] : "Lorem Ipsum"}
                </p>
                <ReactQuill
                  id="6835575294"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description"
                  value={data ? data["6835575294"] : "Lorem Ipsum"}
                />
              </div>
              <div className="mb-4">
                <p id="2344528792" className="dynamicStyle card_title_0">
                  {data ? data["2344528792"] : "Lorem Ipsum"}
                </p>
                <ReactQuill
                  id="6508515932"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description"
                  value={data ? data["6508515932"] : "Lorem Ipsum"}
                />
              </div>
              <div className="mb-4">
                <p id="5531551137" className="dynamicStyle card_title_0">
                  {data ? data["5531551137"] : "Lorem Ipsum"}
                </p>
                <ReactQuill
                  id="2754747442"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description"
                  value={data ? data["2754747442"] : "Lorem Ipsum"}
                />
              </div>
              </div>
            </div>
          </div>
          <div className="lg:pl-4 md:pl-4 lg:w-1/2 md:w-1/2 w-full">
            <div
              id="6262036995"
              className="dynamicStyle2 h-450px sm-h250px w-full"
            >
              <Image
                src={data ? getImage(data["6262036995"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="about-img"
                imageClassName="h-450px sm-h250px w-full rounded-2xl object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection32;
