import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";

const AboutSection29 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const createDynamicTabs = () => {
    return (
      data &&
      data["5098045029"]?.map((e, i) => {
        return (
          <AccordionTab
            header={e.field1}
            disabled={e.disabled}
            className="themeShadow theme-bg cust-shadow rounded-lg"
          >
            <ReactQuill
              id="0330487753"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={e.field2}
            />
          </AccordionTab>
        );
      })
    );
  };
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex lg:gap-14 md:gap-10">
          <div className="w-45per sm-wfull lg:pr-20 lg:pb-0 md:pb-0 pb-10">
            <p id="8641401824" className="dynamicStyle about_title pb-3">
              {data ? data["8641401824"] : "Popular like by our clients"}
            </p>

            <div>
              {/* <Accordion id="5098045029" className="dynamicStyle" allowToggle>
                <div className="py-1 mb-2">
                  {data && data['5098045029']
                    ? data['5098045029'].map(e => {
                        return (
                          <AccordionItem className="cust-shadow font-medium mb-3 sm-fsize14 bg-faf9ff text-black   rounded-lg themeShadow theme-bg">
                            <h2>
                              <AccordionButton className="">
                                <Box
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  className="font-medium"
                                >
                                  {e.field1}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel
                              pb={4}
                              className="textColor-light fsize14 font-normal"
                              dangerouslySetInnerHTML={{
                                __html: e.field2,
                              }}
                            ></AccordionPanel>
                          </AccordionItem>
                        );
                      })
                    : 'no-data'}
                </div>
              </Accordion> */}
              <Accordion id="5098045029" className="dynamicStyle">
                {createDynamicTabs()}
              </Accordion>
            </div>
          </div>
          <div className="w-55per h-full sm-wfull bg-d9d9d9 px-6 py-10 rad-rev-24px theme-bg themeShadow">
            <p
              id="0185223895"
              className="dynamicStyle fsize40 md-fsize32 sm-fsize24 font-semibold"
            >
              {data ? data["0185223895"] : "What people choose more often"}
            </p>
            <ReactQuill
              id="7120175489"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle pt-2 card_description"
              value={data ? data["7120175489"] : "Lorem Ipsum"}
            />

            <div className="lg:mt-6 md:mt-6 mt-4 flex gap-4">
              <Button
                className="dynamicStyle b_button_primary px-6 py-2 fsize14 sm-fsize12 theme-btn2"
                id="6563801889"
              >
                <span onClick={() => handleClick("6563801889")}>
                  {data ? data["6563801889"] : "Learn More"}
                </span>
              </Button>
              <Button
                outlined
                className="dynamicStyle fsize14 sm-fsize12 btn-mdsize theme-btn2"
                id="8584132617"
              >
                <span onClick={() => handleClick("8584132617")}>
                  {data ? data["8584132617"] : "Learn More"}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection29;
