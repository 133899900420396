import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection21 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:flex md:flex items-center w-full" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:w-6/12 md:w-6/12 w-full bg-primary theme-bg about2-h flex items-center justify-center">
        <div id="1987668358" className="dynamicStyle2 lg:w-8/12 md:w-5/6">
          <Image
            src={data ? getImage(data['1987668358']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="about"
            imageClassName="object-contain w-full lg:h-32 md:h-20 h-16"
          />
        </div>
      </div>
      <div className="lg:w-6/12 md:w-6/12 w-full lg:relative md:relative">
        <div id="6140108478" className="w-full dynamicStyle2">
          <Image
            src={data ? getImage(data['6140108478']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            imageClassName="about2-images object-cover w-full lg:block md:block hidden"
          />
        </div>

        <div className="lg:absolute md:absolute top-0 left-0 about2-images flex items-center w-full lg:py-0 md:py-0 py-6">
          <div
            ref={elementRef}
            className={`fade-right lg:px-10 md:px-5 px-5 lg:py-0 md:py-0 py-6 ${
              isVisible ? 'visibleright' : ''
            } `}
          >
            <h3 id="7806932833" className="about_title dynamicStyle">
              {data ? data['7806932833'] : 'EDUCATE'}
            </h3>
            <ReactQuill
              id="9610572136"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle"
              value={data ? data['9610572136'] : 'Lorem Ipsum'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection21;
