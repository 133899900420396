import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection18 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12 theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container lg:flex gap-12">
        <div className="w-full lg:mb-0 md:mb-6 mb-6">
          <h2 id="8553225104" className="dynamicStyle uppercase about_title">
            {data ? data['8553225104'] : 'Lorem ipsum'}
          </h2>
          <ReactQuill
            id="2426863193"
            theme="bubble"
            readOnly
            className="card_description bubble dynamicStyle"
            value={data ? data['2426863193'] : 'Lorem Ipsum'}
          />
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-3 mt-6">
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="9356766138"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['9356766138'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="3095405367"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['3095405367'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="0816456007"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['0816456007'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="5448274608"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['5448274608'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="4111769935"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['4111769935'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="0735016315"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['0735016315'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="8513043931"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['8513043931'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="8123805840"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['8123805840'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="3393187084"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['3393187084'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="6534446282"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['6534446282'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="3269626863"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['3269626863'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
              <div className="dot"></div>
              <p
                id="8372659681"
                className="dynamicStyle fsize14 theme-textColor"
              >
                {data ? data['8372659681'] : 'Lorem ipsum'}
              </p>
            </div>
          </div>
        </div>

        <div
          ref={elementRef}
          className={`fade-right w-full ${isVisible ? 'visibleright' : ''} `}
        >
          <div id="4015173834" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['4015173834']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="section-image"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              imageClassName="w-full h-400px md-h-300px sm-h250px rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection18;
