import { ChatIcon, DownloadIcon } from '@chakra-ui/icons';
import { Avatar, useDisclosure } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { FiEye } from 'react-icons/fi';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

const RejectedFiles = () => {
  const [size, setSize] = React.useState('');
  const handleClick = newSize => {
    setSize(newSize);
    onOpenLogs();
  };
  const {
    isOpen: isOpenVersion,
    onOpen: onOpenVersion,
    onClose: onCloseVersion,
  } = useDisclosure();
  const {
    isOpen: isOpenLogs,
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  } = useDisclosure();
  return (
    <div className="w-full pt-8">
      <Modal isOpen={isOpenVersion} onClose={onCloseVersion}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="white">File Name</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <div className="py-3 px-1">
              <div className="mb-2 bg-f8f8f8 rounded-md py-3 px-2 w-full flex items-center justify-between">
                <p className="fsize16 font-medium text-primary">Version 1.0</p>
                <div className="flex gap-2">
                  <div className="bdr-f95c27 p-1 rounded-full flex justify-center items-center">
                    <DownloadIcon className="clr-f95c27" />
                  </div>
                  <div className="bdr-053c6D p-1 rounded-full flex justify-center items-center">
                    <FiEye className="clr-053c6D" />
                  </div>
                </div>
              </div>
              <div className="bg-f8f8f8 rounded-md py-3 px-2 w-full flex items-center justify-between">
                <p className="fsize16 font-medium text-primary">Version 1.0</p>
                <div className="flex gap-2">
                  <div className="bdr-f95c27 p-1 rounded-full flex justify-center items-center">
                    <DownloadIcon className="clr-f95c27" />
                  </div>
                  <div className="bdr-053c6D p-1 rounded-full flex justify-center items-center">
                    <FiEye className="clr-053c6D" />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Drawer
        isOpen={isOpenLogs}
        size={size}
        placement="right"
        onClose={onCloseLogs}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton className="text-white" />
          <DrawerHeader className="text-white">Logs</DrawerHeader>

          <DrawerBody className="bg-fa">
            <div className="py-3">
              <div className="grid grid-cols-1 gap-3">
                <div className="bg-white shadow-sm py-3 px-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                      width="36px"
                      height="36px"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">
                          has been updated by
                        </span>{' '}
                        Dealing officer
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow-sm py-3 px-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                      width="36px"
                      height="36px"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">
                          has been updated by
                        </span>{' '}
                        Dealing officer
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-6 relative">
                <hr></hr>
                <div className="absolute top-0 mt-minus left-0 flex justify-center w-full">
                  {' '}
                  <p className="text-primary bg-white fsize12 px-2">
                    Yesterday
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-3">
                <div className="bg-white shadow-sm py-3 px-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                      width="36px"
                      height="36px"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">
                          has been updated by
                        </span>{' '}
                        Dealing officer
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow-sm py-3 px-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                      width="36px"
                      height="36px"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">
                          has been updated by
                        </span>{' '}
                        Dealing officer
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-6 relative">
                <hr></hr>
                <div className="absolute top-0 mt-minus left-0 flex justify-center w-full">
                  {' '}
                  <p className="text-primary bg-white fsize12 px-2">
                    22 March 2024
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-3">
                <div className="bg-white shadow-sm py-3 px-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                      width="36px"
                      height="36px"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">
                          has been updated by
                        </span>{' '}
                        Dealing officer
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow-sm py-3 px-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                      width="36px"
                      height="36px"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">
                          has been updated by
                        </span>{' '}
                        Dealing officer
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div className="lg:flex md:flex items-baseline gap-2">
        <h2 className="fsize28 sm-fsize20 text-primary">Rejected Files</h2>
        <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
      </div>
      <div className="lg:mt-12 md:mt-8 mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
        <div className="borderall rounded-lg py-3 px-4">
          <div className="w-full relative">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="KMP"
              id=""
              className="w-1/2 mx-auto h-32 object-contain"
            />
            <div className="absolute top-right-8px p-1 flex justify-center items-center">
              <Menu>
                {/* <MoreVertical as={Button} className="text-gray" /> */}
                <MenuButton>
                  <MoreVertical as={Button} className="text-gray" />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={onOpenVersion}>Version</MenuItem>
                  <MenuItem onClick={() => handleClick('sm')} key={size}>
                    Logs
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
          <div className="mt-2">
            <h2 className="text-primary text-center fsize16 md-fsize14 sm-fsize14">
              Employe code of conduct
            </h2>
            <p className="text-gray text-center fsize14 sm-fsize12">
              Microsoft word file
            </p>
            <div className="mt-4 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
              <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
              </div>
              <div className=" cursor-pointer border px-3 py-3 rounded-full">
                <FiEye />
              </div>
              <div className=" cursor-pointer border px-3 py-2 rounded-full">
                <ChatIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectedFiles;
