import React, { useEffect, useRef, useState } from "react";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux/es/exports';

const GallerySection8 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
      <h4
        id="2295644665"
        className="section_header dynamicStyle text-center"
      >
        {data ? data['2295644665'] : 'Collections'}
      </h4>
      <div id="1075457292" className="dynamicStyle lg:mt-16 md:mt-16 mt-12">
        <Swiper
          spaceBetween={0}
          effect={'coverflow'}
          modules={[EffectCoverflow, Navigation]}
          autoplay={{
            delay: '3000',
          }}
          loop={true}
          navigation={{
            nextEl: '.image-swiper-button-nexts',
            prevEl: '.image-swiper-button-prevs',
          }}
          className="mySwiper gallery8 lg:mt-5 md:mt-5 mt-2"
          coverflowEffect={{
            rotate: -15,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          disableOnInteraction={true}
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 2.9,
              spaceBetween: 40,
            },
            768: {
              slidesPerView: 2.9,
              spaceBetween: 40,
            },
            640: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            300: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prevs cursor-pointer w-8 h-8 flex items-center justify-center bg-white rounded-full">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-nexts cursor-pointer w-8 h-8 flex items-center justify-center bg-white rounded-full">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {data && data['1075457292']
            ? data['1075457292'].map((e, index) => {
                return (
                  <SwiperSlide>
                    <Image
                      src={e ? getImage(e.field1) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      imageClassName="gallery-8-img object-cover"
                      alt="Image"
                    />
                  </SwiperSlide>
                );
              })
            : 'no-card'}
          {/* <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide> */}
        </Swiper>
      </div>
      </div>
    </div>
  );
};

export default GallerySection8;
