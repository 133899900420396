import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux/es/exports';
import { Image } from 'primereact/image';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

const GallerySection7 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex w-full justify-between">
          <h4
            id="2506326699"
            className="section_header dynamicStyle"
          >
            {data ? data['2506326699'] : 'Flagship Programs'}
          </h4>
          <Button
            id="3365033119"
            className='b_button_primary px-6 py-2'
          >
            <span onClick={() => handleClick('3365033119')}>
              {data ? data['3365033119'] : 'Lorem ipsum'}
            </span>
          </Button>
        </div>
        <div className="lg:flex md:flex grid grid-cols-1 w-full gap-4 lg:mt-16 md:mt-16 mt-12">
          <div className="lg:w-5/12 md:w-5/12 w-full">
            <div class="effect-layla w-full gallery7-img rounded-lg">
              <div id="2163247643" className="w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data['2163247643']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  imageClassName="w-full gallery7-img object-cover"
                />
              </div>

              <div className="effectforth gallery7-img w-full flex items-center">
                <p
                  id="5998806976"
                  className="dynamicStyle section_detail clr-fff"
                >
                  {data ? data['5998806976'] : 'Flagship Programs'}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-2/12 md:w-3/12 w-full">
            <div class="effect-lily w-full rounded-lg gallerysecond7-img">
              <div
                className="gradients w-full h-full dynamicStyle2"
                id="0480544188"
              >
                <Image
                  src={data ? getImage(data['0480544188']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  imageClassName="w-full h-full object-cover"
                />
              </div>

              <div className="effectone">
                <p
                  id="1909786121"
                  className="dynamicStyle section_detail clr-fff text-left z-50 relative mb-10"
                >
                  {' '}
                  {data ? data['1909786121'] : 'Flagship Programs'}
                </p>
              </div>
            </div>
            <div class="effect-lily w-full rounded-lg gallerythird7-img lg:mt-2 md:mt-2 mt-4">
              <div
                className="gradients w-full h-full dynamicStyle2"
                id="9308565674"
              >
                <Image
                  src={data ? getImage(data['9308565674']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  imageClassName="w-full h-full object-cover"
                />
              </div>

              <div className="effectone">
                <p
                  id="4186501683"
                  className="dynamicStyle section_detail clr-fff text-left z-50 relative mb-10"
                >
                  {' '}
                  {data ? data['4186501683'] : 'Flagship Programs'}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 md:w-5/12 w-full">
            <div class="effect-layla w-full gallery7-img rounded-lg">
              <div id="2361400565" className="w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data['2361400565']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  imageClassName="w-full gallery7-img object-cover"
                />
              </div>

              <div className="effectforth gallery7-img w-full flex items-center">
                <p
                  id="4056462293"
                  className="dynamicStyle section_detail clr-fff"
                >
                  {data ? data['4056462293'] : 'Flagship Programs'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection7;
