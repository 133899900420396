import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const FAQSection7 = ({ pageName }) => {
          //section color start
          const [fileName, setFileName] = useState("");
          const [thisComponent, setThisComponent] = useState({});
          const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
          const selectedSection = useSelector((state) => state.cms.sectionNames);
          //sectioncolor end
          //sectioncolor start
          useEffect(() => {
            const name = new URL(import.meta.url);
            const filename = name.pathname.split("/").pop();
            console.log(filename);
            const componentName = filename.split(".")[0];
            setFileName(componentName);
        
            for (let i = 0; i < selectedSection[pageName].length; i++) {
              if (
                selectedSection[pageName][i].path.includes(componentName.split(".")[0])
              ) {
                setThisComponent(selectedSection[pageName][i]);
              }
            }
          }, [selectedSection]);
          //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);

  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-start gap-16 w-full">
          <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-32 md:pr-24">
            <h2 id="9017299216" className="dynamicStyle section_header">
              {data ? data['9017299216'] : 'Popular like by our Clients'}
            </h2>
            <div className="mt-6 dynamicStyle" id="5592143858">
            <Accordion multiple activeIndex={[0]}>
                {data && data['5592143858']
                  ? data['5592143858'].map(e => {
                      return (
                        <AccordionTab
                        header={<h4 className="card_title_0"> {e.field1}</h4>}
                      >
                        <ReactQuill
                          id="7461373914"
                          theme="bubble"
                          readOnly
                          className="bubble card_description"
                          value={e.field2}
                        />
                      </AccordionTab>
                      );
                    })
                  : 'no-data'}
              </Accordion>
              .
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full flex lg:px-12">
            <div className="">
              <h2 id="0313697781" className="dynamicStyle section_header">
                {data ? data['0313697781'] : 'What People choose more offen'}
              </h2>
              <ReactQuill
                id="3181876654"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail"
                value={data ? data['3181876654'] : 'Lorem Ipsum'}
              />

              <div className="lg:mt-4 md:mt-3 mt-2 flex gap-4">
                {/* <Button label="Submit" className="px-8 py-4 bg-primary" /> */}
                <Button
                  id="4081039797"
                  className="b_button_primary px-6 py-2"
                >
                  <span onClick={() => handleClick('4081039797')}>
                    {data ? data['4081039797'] : 'Learn More'}
                  </span>
                </Button>
                <Button id="0893849814" outlined className="b_button_primary px-6 py-2">
                  <span onClick={() => handleClick('0893849814')}>
                    {data ? data['0893849814'] : 'Contact us'}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default FAQSection7;
