import { Button } from "primereact/button";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup"; //Optional for grouping
import { Checkbox } from "primereact/checkbox";

const PollSection1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="0895344041"
          className="dynamicStyle inline-block  text_surface600 section_header"
        >
          {data ? data["0895344041"] : "Poll Question 1"}
        </h3>
        <div className="lg:w-2/3">
          <ReactQuill
            id="8012130299"
            theme="bubble"
            readOnly
            className="section_detail bubble dynamicStyle"
            value={data ? data["8012130299"] : "Lorem Ipsum"}
          />
        </div>
        <div className="w-1/3 lg:py-8 md:py-8 py-6 flex flex-col gap-3">
          <div className="inline-flex flex-wrap gap-3">
            <Checkbox className="text_surface600"> </Checkbox>
            <label htmlFor="" className="ml-2">
              2021-22
            </label>
          </div>
          <div className="inline-flex flex-wrap gap-3">
            <Checkbox className="text_surface600"> </Checkbox>
            <label htmlFor="" className="ml-2">
              2022-23
            </label>
          </div>
          <div className="inline-flex flex-wrap gap-3">
            <Checkbox className="text_surface600"> </Checkbox>
            <label htmlFor="" className="ml-2">
              2023-24
            </label>
          </div>
        </div>
        <div className=" w-full flexjustify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup>
              <Avatar
                name="Ryan Florence"
                shape="circle"
                image="https://bit.ly/ryan-florence"
              />
              <Avatar
                name="Segun Adebayo"
                shape="circle"
                image="https://bit.ly/sage-adebayo"
              />
              <Avatar
                name="Kent Dodds"
                shape="circle"
                image="https://bit.ly/kent-c-dodds"
              />
              <Avatar
                name="Prosper Otemuyiwa"
                shape="circle"
                image="https://bit.ly/prosper-baba"
              />
              <Avatar
                name="Christian Nwamba"
                shape="circle"
                image="https://bit.ly/code-beast"
              />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text_surface600 dynamicStyle"
                id="6810007173"
              >
                {data ? data["6810007173"] : "Total votes:"}
              </p>
              <p className="fsize12 text_surface600">24</p>
            </div>
          </div>
          <div className="dynamicStyle mt-6" id="2399056665">
            <Button
              onClick={() => handleClick("2399056665")}
              className="b_button_primary px-6 py-2 theme-btn2 "
              fontWeight={400}
              borderRadius={2}
            >
              <span>{data ? data["2399056665"] : "Vote"}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollSection1;
