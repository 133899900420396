import React from "react";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const InfoList1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="info_dashed">
      <section className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
        <div className="prime_container">
          <div className="lg:flex md:flex gap-14">
            <div className="lg:mb-0 md:mb-0 mb-4 lg:w-1/2 md:w-1/2 w-full">
              <h3
                id="2763607540"
                className="dynamicStyle fsize40 md-fsize36 sm-fsize24 font-semibold"
              >
                {data ? data["2763607540"] : "Lorem Ipsum"}
              </h3>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <div className="lg:mb-10 md:mb-10 mb-6">
                  <p
                    id="6860249877"
                    className="dynamicStyle sm-fsize14 lg:pb-4 md:pb-4 pb-3"
                  >
                    {data ? data["6860249877"] : "Lorem Ipsum"}
                  </p>
                  <ReactQuill
                id="8606643357"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle"
                value={data ? data["8606643357"] : "Lorem Ipsum"}
              />
                
              </div>
                <p
                  id="3381218565"
                  className="dynamicStyle sm-fsize14 lg:pb-4 md:pb-4 pb-3"
                >
                  {data ? data["3381218565"] : "Lorem Ipsum"}
                </p>
                <ReactQuill
                id="8560111045"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle"
                value={data ? data["8560111045"] : "Lorem Ipsum"}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InfoList1;
