import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { FaFileMedical } from 'react-icons/fa';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Textarea,
  Select,
  Radio,
  RadioGroup,
  useDisclosure,
} from '@chakra-ui/react';

const Gridcards = () => {
  const [size, setSize] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState('1');
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const handleClick = newSize => {
    setSize(newSize);
    // onOpen();
  };
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-18 py-6">
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Add Folder</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody className="custborbot">
            <div className="py-3 px-1">
              <div className="">
                <label className="text-primary">Select a group</label>
                <Select placeholder="Select option">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <label className="text-primary">Select a group</label>
                <Select placeholder="Select option">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <RadioGroup onChange={setValue} value={value}>
                  <Radio value="1">Add a new document</Radio>
                  <Radio value="2" className="mt-2">
                    Update an existing document
                  </Radio>
                </RadioGroup>
                {value === '2' ? (
                  <>
                    <div className="mt-2">
                      <label className="text-primary">Select File</label>
                      <Select placeholder="Select option" className="mt-2">
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </Select>
                    </div>
                  </>
                ) : null}
                <div className="mt-3">
                  <label className="text-primary">Describe your request</label>
                  <Textarea className="mt-2" placeholder="" rows={3} />
                </div>
              </div>
              <div className="flex items-center gap-3 mt-5">
                <Button className="w-full b_button_primary px-6 py-2">
                  Submit
                </Button>
                <Button className="w-full b_button_secondary px-6 py-2">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="lg:flex md:flex justify-between items-center">
          <h2 className="fsize20 sm-fsize19 text-primary" id="">
            Knowledge Mangamement Portal
          </h2>
          
          <Button label="Make a Request" icon="pi pi-file" className="b_button_primary px-6 py-2 fsize14 lg:mt-0 md:mt-0 mt-2 sm-fsize12 rounded-100px" />
          {/* <Button
            onClick={() => handleClick('sm')}
            key={size}
            leftIcon={<FaFileMedical />}
            colorScheme="primary"
            variant="solid"
            fontWeight="400"
            className="bg-f98c27 fsize14 sm-fsize12 rounded-100px"
          >
            Make a Request
          </Button> */}
        </div>

        <div className="lg:mt-8 md:mt-6 mt-6 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Processes
          </h2>
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="lg:mb-16 md:mb-12 mb-8">
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Information
          </h2>
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div>
        <div className="flex gap-2 items-center">
          <h2 className="fsize20 sm-fsize16 text-primary" id="">
            Others
          </h2>
        </div>

        <div className="lg:mt-8 md:mt-6 mt-4 ">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="relative">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="KMP"
                    id=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <div className="absolute top-right-8px bg-secondary text-white rounded-full w-6 h-6 flex justify-center items-center">
                    <p className="fsize12">8</p>
                  </div>
                </div>

                <p className="mt-3 text-center text-primary fsize14">Assets</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Gridcards;
