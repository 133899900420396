import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "@chakra-ui/react";

const HeroSection34 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  return (
    <div
      className="py-8 relative"
      style={{ background: thisComponent.sectionColor }}
    >
      <div id="3378221256" className="dynamicStyle2">
        <Image
          src={data ? getImage(data["3378221256"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="lg:w-24 md:w-24 w-20 lg:h-24 md:h-24 h-20 lg:ml-10 md:ml-10 ml-4 object-contain"
        />
      </div>
      <div className="lg:w-4/6 md:w-5/6 mx-auto text-center mt-2 z-ind-1 px-5">
        <h1 id="0272479430" className="dynamicStyle hero_title_1">
          {data ? data["0272479430"] : "Lorem Ipsum"}
        </h1>
        <ReactQuill
          id="6004429435"
          theme="bubble"
          readOnly
          className="bubble2 dynamicStyle card_description_0 lg:w-3/4 md:w-3/4 mx-auto"
          value={data ? data["6004429435"] : "Lorem Ipsum"}
        />
      </div>
      <div className="bg-e7e7e754 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-6 md:gap-6 lg:py-6 md:py-6 py-4 lg:px-8 md:px-8 px-6 lg:my-6 md:my-6 my-4 z-ind-1">
        <div id="3742103171" className="dynamicStyle2">
          <Image
            src={data ? getImage(data["3742103171"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="w-full lg:h-36 md:h-30 h-24 object-contain"
          />
        </div>
        <div id="2675868841" className="dynamicStyle2">
          <Image
            src={data ? getImage(data["2675868841"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="w-full lg:h-36 md:h-30 h-24 object-contain"
          />
        </div>
        <div id="4734352146" className="dynamicStyle2">
          <Image
            src={data ? getImage(data["4734352146"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="w-full lg:h-36 md:h-30 h-24 object-contain"
          />
        </div>
      </div>
      <ReactQuill
        id="9319576941"
        theme="bubble"
        readOnly
        className="bubble2 dynamicStyle fsize14 lg:w-1/2 md:w-2/3 text-center mx-auto z-ind-1 px-5"
        value={data ? data["9319576941"] : "Lorem Ipsum"}
      />
      <div
        id="6192259382"
        className="dynamicStyle2 w-1/4 absl-faq6 bottom-0 left-0"
      >
        <Image
          src={data ? getImage(data["6192259382"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="w-full h-450px object-contain"
        />
      </div>
      <div
        id="8226500988"
        className="dynamicStyle2 w-1/4 absl-faq6 top-0 right-0"
      >
        <Image
          src={data ? getImage(data["8226500988"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="w-full h-450px object-contain"
        />
      </div>
    </div>
  );
};

export default HeroSection34;
