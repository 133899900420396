import React, { useEffect, useState } from 'react';
// import { Mail } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection34 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      id="3050828218"
      className="dynamicStyle2 relative h-800px md-h-700px sm-h550px"
      style={{ background: thisComponent.sectionColor }}
    >
      <Image
        src={data ? getImage(data['3050828218']) : ''}
        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        alt="img"
        imageClassName="h-800px md-h-700px sm-h550px w-full object-cover"
      />
      <div className="h-800px md-h-700px sm-h550px absolute top-0 left-0 w-full flex items-center">
        <div className="lg:w-1/2 md:w-3/5 lg:pl-20 md:pl-12 md:px-0 px-5 lg:py-20 md:py-16 py-8">
          <h2
            id="8526373002"
            className="dynamicStyle fsize48 md-fsize36 sm-fsize24 lheight48 sm-lh-24 font-light"
          >
            {data ? data['8526373002'] : 'Lorem ipsum'}
          </h2>
          <h2
            id="5882479251"
            className="dynamicStyle fsize64 md-fsize48 md-lh-48 sm-fsize32 sm-lh-46 font-semibold"
          >
            {data ? data['5882479251'] : 'Lorem ipsum dolor sit amet'}
          </h2>
          <ReactQuill
            id="5825557903"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle card_description lg:mt-4 md:mt-3 mt-2 mb-3"
            value={data ? data['5825557903'] : 'Lorem Ipsum'}
          />

          <div
            id="7300963272"
            className="dynamicStyle grid grid-cols-2 lg:gap-12 md:gap-8 gap-6 lg:mt-12 md:mt-10 mt-6"
          >
            {data && data['7300963272']
              ? data['7300963272'].map(e => {
                  return (
                    <div
                      key={e._id}
                      className="flex items-center lg:gap-4 md:gap-3 gap-2"
                    >
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="skin"
                        imageClassName="lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-contain"
                      />
                      <p className="fsize16 sm-fsize14">{e.field2}</p>
                    </div>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection34;
