import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

const AboutSection20 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div
        ref={elementRef}
        className={`fade-bottom prime_container bg-white rounded-lg ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <div className="w-full lg:relative md:relative about-h rounded-lg themeShadow overflow-hidden">
          <div id="2314653325" className="w-full dynamicStyle2">
            <Image
              src={data ? getImage(data['2314653325']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              imageClassName="about-h object-cover lg:block md:block hidden"
            />
          </div>
          <div className="lg:absolute md:absolute top-0 left-0 about-h flex items-center">
            <div className="lg:flex md:flex items-center gap-8 lg:px-10 md:px-4 px-3 lg:py-0 md:py-0 py-4">
              <div className="lg:w-5/12 md:w-5/12 w-full">
                <div id="1689943441" className="w-full dynamicStyle2">
                  <Image
                    src={data ? getImage(data['1689943441']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="about"
                    imageClassName="object-cover w-full about-images shadow"
                  />
                </div>
              </div>
              <div className="lg:w-7/12 md:w-7/12 w-full lg:mt-0 md:mt-0 mt-4">
                <h3 id="7129321340" className="about_title dynamicStyle">
                  {data ? data['7129321340'] : 'EDUCATE'}
                </h3>
                <ReactQuill
                  id="0610823032"
                  theme="bubble"
                  readOnly
                  className="card_description bubble dynamicStyle"
                  value={data ? data['0610823032'] : 'Lorem Ipsum'}
                />
                <Button
                  id="2707186619"
                  className="border text-dark sm-fsize13 dynamicStyle mt-6 px-6 py-2"
                >
                  <span onClick={() => handleClick('2707186619')}>
                    {data ? data['2707186619'] : 'Lorem ipsum'}
                  </span>
                  <FeatherIcon
                    icon="arrow-right"
                    size="18"
                    className="textColor-light text-dark ml-1"
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full lg:p-4 p-3 lg:block md:block hidden bg-primary theme-bg"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection20;
