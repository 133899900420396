import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/auth/auth-action';
import MyProfile from './components/MyProfile';
import MyBooking from './components/MyBooking';
import ManageSubscription from './components/ManageSubscription';
import LoyaltyProgram from './components/LoyaltyProgram';
import ManageAccount3 from './components/ManageAccount3';
import HelpSupport3 from './components/HelpSupport3';

const Profile4 = () => {
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();
  let userData = useSelector(state => state.AuthDetails.user);
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const btnRef = React.useRef();

  const menuItems = [
    {
      id: 1,
      name: 'My Profile',
      icon: 'user',
    },
    {
      id: 2,
      name: 'My Booking',
      icon: 'book',
    },
    {
      id: 3,
      name: 'Manage Subscription',
      icon: 'tag',
    },
    {
      id: 4,
      name: 'Loyalty Program',
      icon: 'tag',
    },
    {
      id: 5,
      name: 'Manage Accounts',
      icon: 'settings',
    },
    {
      id: 6,
      name: 'Help & Support',
      icon: 'headphones',
    },
  ];

  const [selectedItem, setSelectedItem] = useState(menuItems[0].name);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = item => {
    getQuery();
    setSelectedItem(item);
  };

  const getQuery = () => {
    let getSelectedMenu = searchParams.get('sm');
    if (
      menuItems.some(
        item =>
          item.name?.toLowerCase() === getSelectedMenu?.toLocaleLowerCase()
      )
    ) {
      setSelectedItem(getSelectedMenu);
    }
  };

  const handleLogout = async () => {
    if (userData && userData.email) {
      let payload = {
        email: userData.email,
      };
      try {
        const data = await dispatch(logout(payload));
        if (data && data.code == 200) {
          await Swal.fire({
            title: 'Success',
            text: `Logged out successfully`,
            icon: 'success',
            timer: '2500',
          });
          Navigate('/');
        } else {
          if (data.message) {
            Swal.fire({
              title: 'Error',
              text: data.message,
              icon: 'error',
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <section className="container mx-auto lg:px-4 md:px-4 lg:py-6  md:py-6 py-6 px-5">
      <div className="lg:hidden zindex_menu md:hidden block text-right mb-2">
        <Menu>
          <MenuButton
            px={0}
            py={2}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="0px"
          >
            Profile <ChevronDownIcon />
          </MenuButton>
          <MenuList>
            <MenuItem p={0}>
              <div className="lg:flex md:flex justify-between  w-full">
                <div className=" w-full ">
                  <div className=" ">
                    <ul className="py-0">
                      {menuItems.map((item, index) => (
                        <li
                          key={index}
                          className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                            selectedItem === item.name
                              ? 'selected text-primary font-semibold'
                              : ''
                          }`}
                          onClick={() => handleClick(item.name)}
                        >
                          <FeatherIcon icon={item.icon} className="w-4 h-4" />
                          {item.name}
                        </li>
                      ))}
                      <li
                        onClick={handleLogout}
                        className="py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 "
                      >
                        <FeatherIcon icon="log-out" className="w-4 h-4" />
                        Logout
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </MenuItem>
          </MenuList>
        </Menu>
        <Drawer
          isOpen={isOpenDrawer}
          placement="right"
          onClose={onCloseDrawer}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            {/* <DrawerHeader>Options</DrawerHeader> */}
            <DrawerBody className="sm-drawer-padding">
              <div className="lg:flex md:flex justify-between  w-full">
                <div className=" w-full ">
                  <div className=" ">
                    <ul className="py-6">
                      {menuItems.map((item, index) => (
                        <li
                          key={index}
                          className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                            selectedItem === item.name
                              ? 'selected text-primary font-semibold'
                              : ''
                          }`}
                          onClick={() => handleClick(item.name)}
                        >
                          <FeatherIcon icon={item.icon} className="w-4 h-4" />
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
      <div
        className={
          colorMode === 'light'
            ? 'lg:flex md:flex justify-between w-full'
            : 'lg:flex md:flex justify-between w-full'
        }
      >
        <div
          className={
            colorMode === 'light'
              ? 'rounded-l-lg lg:w-1/4 bg-fafafa height-fit border-right-f0f0f0 mob-hidden md:w-2/6 w-full p-2 lg:mb-0 md:mb-0 mb-4 theme-bg themeShadow'
              : 'cust-bgColor rounded-l-lg lg:w-1/4 height-fit border-right-4e5765  mob-hidden md:w-2/6 w-full p-2 lg:mb-0 md:mb-0 mb-4 theme-bg themeShadow'
          }
        >
          <p
            className={
              colorMode === 'light'
                ? 'theme-textColor fsize20 md-fsize20 sm-fsize20 font-semibold text-black cust-textColor'
                : 'theme-textColor fsize20 md-fsize20 sm-fsize20 font-semibold text-black cust-textColor'
            }
          >
            Manager Account
          </p>
          <div className="border-1px rounded-lg mt-2">
            <ul className="py-0 rounded-lg overflow-hidden">
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  className={`py-3 px-6 mb-0  cursor-pointer flex items-center fsize16 font-medium text-gray gap-3 ${
                    selectedItem === item.name
                      ? 'selected bg-primary clr-fff cust-textColor theme-bg themeShadow themeShadow-insert theme-textColor'
                      : ''
                  }`}
                  onClick={() => handleClick(item.name)}
                >
                  <FeatherIcon icon={item.icon} className="w-4 h-4" />
                  {item.name}
                </li>
              ))}
              <li
                onClick={handleLogout}
                className="py-3 px-6 mb-0 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 "
              >
                <FeatherIcon icon="log-out" className="w-4 h-4" />
                Logout
              </li>
            </ul>
          </div>
        </div>
        <div
          className={
            colorMode === 'light'
              ? 'rounded-r-lg rounded-b-lg lg:w-3/4 md:w-4/6 bg-fafafa height-fit w-full p-2 lg:mb-0 md:mb-0 mb-4 theme-bg themeShadow'
              : 'cust-bgColor rounded-r-lg rounded-b-lg lg:w-3/4 md:w-4/6 height-fit w-full p-2 lg:mb-0 md:mb-0 mb-4 theme-bg themeShadow'
          }
        >
          {selectedItem === 'My Profile' && <MyProfile />}
          {selectedItem === 'My Booking' && <MyBooking />}
          {selectedItem === 'Manage Subscription' && <ManageSubscription />}
          {selectedItem === 'Loyalty Program' && <LoyaltyProgram />}
          {selectedItem === 'Manage Accounts' && <ManageAccount3 />}
          {selectedItem === 'Help & Support' && <HelpSupport3 />}
        </div>
      </div>
    </section>
  );
};

export default Profile4;
