import API from "../repositoryFactory";

const get = "podcast";

const getPodcast = (route) => {
  return `forms/get/${route}`;
};

export default {
  getAllPodcasts(payload) {
    return API.post(getPodcast(get), payload);
  },
};
