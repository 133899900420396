import React from "react";
import FeatherIcon from "feather-icons-react";
import { Image, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Timeline2 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:py-16 md:py-12 lg:px-12 md:px-8 py-8 px-4">
      <div className="lg:w-2/3 md:w-2/3 w-full text-center mx-auto">
        <h4
          id="1134363129"
          className={
            colorMode === "light"
              ? "dynamicStyle fsize32 md-fsize28 sm-fsize20 font-semibold lg:mb-4 md:mb-4 mb-2"
              : "dynamicStyle fsize32 md-fsize28 sm-fsize20 font-semibold lg:mb-4 md:mb-4 mb-2 cust-textColor"
          }
        >
          {data ? data["1134363129"] : "Lorem, ipsum"}
        </h4>
        <ReactQuill
          id="4478439697"
          theme="bubble"
          readOnly
          className="card_description bubble dynamicStyle mt-2"
          value={data ? data["4478439697"] : "Lorem Ipsum"}
        />
      </div>
      <div className="mt-12">
        <div className="flex items-center gap-6 lg:w-3/5 md:w-4/5 w-full mb-10">
          <div
            id="9179509516"
            className="dynamicStyle2 lg:w-2/5 md:w-2/5 w-full"
          >
            <Image
              src={data ? getImage(data["9179509516"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-250px sm-h-150px w-full timeline2-img"
            />
          </div>
          <div className="lg:w-3/5 md:w-3/5 w-full pl-14">
            <p
              id="6200447218"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
            >
              {data ? data["6200447218"] : "Lorem, ipsum"}
            </p>
            <ReactQuill
              id="4251213368"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle mt-2"
              value={data ? data["4251213368"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="flex items-center gap-6 lg:w-3/5 md:w-4/5 w-full mb-10 ml-auto">
          <div
            id="6243866448"
            className="dynamicStyle2 lg:w-2/5 md:w-2/5 w-full"
          >
            <Image
              src={data ? getImage(data["6243866448"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-250px sm-h-150px w-full timeline2-img"
            />
          </div>
          <div className="lg:w-3/5 md:w-3/5 w-full pl-14">
            <p
              id="4626631556"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
            >
              {data ? data["4626631556"] : "Lorem, ipsum"}
            </p>
            <ReactQuill
              id="1989076256"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle mt-2"
              value={data ? data["1989076256"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="flex items-center gap-6 lg:w-3/5 md:w-4/5 w-full mb-10">
          <div
            id="4755669188"
            className="dynamicStyle2 lg:w-2/5 md:w-2/5 w-full"
          >
            <Image
              src={data ? getImage(data["4755669188"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-250px sm-h-150px w-full timeline2-img"
            />
          </div>
          <div className="lg:w-3/5 md:w-3/5 w-full pl-14">
            <p
              id="5641479226"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
            >
              {data ? data["5641479226"] : "Lorem, ipsum"}
            </p>
            <ReactQuill
              id="4857206642"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle mt-2"
              value={data ? data["4857206642"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="flex items-center gap-6 lg:w-3/5 md:w-4/5 w-full mb-10 ml-auto">
          <div
            id="0590311474"
            className="dynamicStyle2 lg:w-2/5 md:w-2/5 w-full"
          >
            <Image
              src={data ? getImage(data["0590311474"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-250px sm-h-150px w-full timeline2-img"
            />
          </div>
          <div className="lg:w-3/5 md:w-3/5 w-full pl-14">
            <p
              id="4180753764"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
            >
              {data ? data["4180753764"] : "Lorem, ipsum"}
            </p>
            <ReactQuill
              id="4543532754"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle mt-2"
              value={data ? data["4543532754"] : "Lorem Ipsum"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline2;
