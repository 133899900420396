import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { useDispatch } from "react-redux";
import { getTestimonal } from "../../../redux/testimonial/testimonial-action";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Testimonial4 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const allTestimonials = useSelector((state) => state.testimonial.Testimonial);
  console.log(allTestimonials, "checktetsimonial");

  useEffect(() => {
    dispatch(getTestimonal());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <>
      {allTestimonials ? (
        <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
          <div className="prime_container">
            <div className="text-center lg:w-3/5 md:w-4/5 mx-auto flex flex-col justify-center">
              <p
                id="3294533169"
                className="dynamicStyle text-primary fsize16 sm-fsize14 font-medium  cust-textColor"
              >
                {data ? data["3294533169"] : "Lorem Ipsum"}
              </p>
              <h2 id="3925329493" className="dynamicStyle section_header">
                {data ? data["3925329493"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="8454975100"
                theme="bubble"
                readOnly
                className="bubble2 dynamicStyle section_detail"
                value={data ? data["8454975100"] : "Welcome to My World"}
              />
            </div>

            <div
              ref={elementRef}
              className={`lg:mt-16 md:mt-16 mt-12 fade-bottom w-60 md-wid-100 sm-w-full mx-auto ${
                isVisible ? "visiblebottom" : ""
              } `}
            >
              <Swiper
                spaceBetween={15}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {allTestimonials?.data?.result.map((item, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className="relative prime_card lg:mx-2 md:mx-2 "
                        key={index}
                      >
                        <div className="theme_insertShadow theme-radius lg:flex md:flex md:gap-2 block">
                          <div className="w-30 sm-wfull flex flex-col items-center justify-center">
                            <div className="testimonial-div ">
                              <Image
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                                alt={item.name}
                                imageClassName="testimonial-img rounded-full object-cover bg-d9d9d9 mx-auto"
                              />
                            </div>
                            <div className="w-full text-center">
                              <h4 className="card_title">{item.name}</h4>
                            </div>
                          </div>
                          <div className="w-70 sm-wfull lg:h-32 md:h-32 h-32">
                            <p className="card_description lineClamp5">
                              {item.testimonial}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Testimonial4;
