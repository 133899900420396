import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

const FeatureCard33 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    Aos.init();
  });

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex block w-full">
            <div id="0447619267" className="dynamicStyle2 lg:w-4/12 md:w-4/12">
              <Image
                src={data ? getImage(data['0447619267']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                imageClassName="h-450px sm-h250px w-full rounded-lg object-cover"
                alt="img"
              />
            </div>
            <div className="lg:w-8/12 md:w-8/12 lg:ml-8 md:ml-6 lg:mt-0 md:mt-0 mt-4">
              <h4 className="section_header dynamicStyle" id="3362985580">
                {data ? data['3362985580'] : 'About Us'}
              </h4>
              <p id="9917964327" className="dynamicStyle section_detail">
                {data ? data['9917964327'] : 'About Us'}
              </p>
              <div
                id="9283014762"
                className="lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid gap-6 mt-12 dynamicStyle"
              >
                {data && data['9283014762']
                  ? data['9283014762'].map((e, index) => {
                      return (
                        <div className="">
                          <div className="">
                            <Image
                              src={e ? getImage(e.field1) : ''}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              imageClassName="w-12 h-12 mb-4 object-contain"
                              alt="img"
                            />
                          </div>
                          <h4 className="card_title_0">{e.field2}</h4>
                          <p className="card_description">{e.field3}</p>
                        </div>
                      );
                    })
                  : 'no-card'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard33;
