import {
  Checkbox,
  Image,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourses } from '../../../../redux/elearningProduct/products-action';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';
import FeatherIcon from 'feather-icons-react';
import { getAllCategorys } from '../../../../redux/ecomCategory/category-action';
import { getAllSubCategorys } from '../../../../redux/ecomSubCategory/subcategory-action';
import { FiFilter } from 'react-icons/fi';
import {  useNavigate } from 'react-router-dom';

const AllCategories = ({ pageName }) => {
    const navigate = useNavigate()
  const elementRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);

  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );

  const AllCategoryData = useSelector(state => state.categorys.Categorys);
  console.log(AllCategoryData, 'AllCategoryData');

  const AllSubCategoryData = useSelector(
    state => state.subCategorys.SubCategorys
  );

  let allCourse = useSelector(state => state.elearningProduct.Course);
  console.log(allCourse);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCourses());
    dispatch(getAllCategorys());
    dispatch(getAllSubCategorys());
  }, []);
  

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    return () => {
      cleanp1();
    };
  }, []);

  const handleWishList = () => {
    navigate("/elearning-wishlist")
  }

  const handleCart = () => {
    navigate("/elearning-shoppingcart")
  }

  
  return (
    <>
      <div className="container mx-auto gap-8 lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8 flex ">
        <div
        className={
            colorMode === 'light' ? 'filtercard lg:w-1/4 md:w-1/4 h-100per mob-hidden' : 'border rounded-sm lg:w-1/4 md:w-1/4 h-100per mob-hidden'
        }
        >
          <div className="w-full lg:p-3">
            <div className=" lg:pb-6 md:pb-4 border-b-2">
              <h2 id="" className="cust-textColor fsize18 font-semibold">
                Category
              </h2>
              <div className="grid grid-cols-1 gap-1 mt-3">
                {AllCategoryData &&
                AllCategoryData.result &&
                AllCategoryData.result.length > 0
                  ? AllCategoryData.result.map(cat => {
                      return (
                        <div className="flex items-center gap-3 textColor-light">
                          <Checkbox>{cat.name}</Checkbox>
                        </div>
                      );
                    })
                  : 'no-category'}
              </div>
            </div>
            <div className="lg:mt-6 mt-4">
              <h2 id="" className="cust-textColor fsize18 font-semibold">
                Sub-Category
              </h2>
              <div className="grid grid-cols-1 gap-1 mt-3">
                {AllSubCategoryData &&
                AllSubCategoryData.result &&
                AllSubCategoryData.result.length > 0
                  ? AllSubCategoryData.result.map(sub => {
                      return (
                        <div className="flex items-center gap-3 textColor-light">
                          <Checkbox>{sub.name}</Checkbox>
                        </div>
                      );
                    })
                  : 'no-sub-category'}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-3/4 md:w-3/4">
          <div className="">
            <div className="lg:flex md:flex justify-between items-center lg:mb-8 md:mb-6 mb-4">
              <h2 id='3993100239' className="dynamicStyle fsize28 md-fsize24 sm-fsize20 font-medium cust-textColor ">
              {data ? data['3993100239'] : 'Latest Search'}
              </h2>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 lg:pb-18 md:pb-16 pb-12">
              {allCourse && allCourse.result && allCourse.result.length > 0
                ? allCourse.result.map((course, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          (colorMode === 'light'
                            ? 'rounded-lg cursor-pointer themeShadow'
                            : 'themeShadow theme-bg rounded-lg  cursor-pointer')
                        }
                      >
                        <div className="relative image-product overflow-hidden rounded-t">
                          <Image
                            className="img-h-220 w-full opct"
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                            alt="img"
                          />
                          <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                            <div className="">
                              <p className="fsize14 textColor-light">4.5/5</p>
                            </div>
                            <div>
                              <FeatherIcon
                                className="filled-blk "
                                icon="star"
                                size={18}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <div className="flex justify-between  text-b2b2b2">
                            <p className="fsize14 font-medium">12 Lessons</p>
                            <p className=" fsize14  font-medium">
                              {course.totalhours} hours
                            </p>
                          </div>
                          <div className="py-3">
                            <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1 lineClamp1">
                              {course['course name']}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                              ₹ {course['actual rate']}/-
                            </p>
                            <div className="flex gap-4">
                              <FeatherIcon
                                className=" cust-textColor"
                                icon="heart"
                                size={20}
                                onClick={()=>handleWishList()}
                              />
                              <FeatherIcon
                                className="cust-textColor "
                                icon="shopping-cart"
                                size={20}
                                onClick={()=>handleCart()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
          <div className="">
            <div className="lg:flex md:flex justify-between items-center lg:mb-8 md:mb-6 mb-4">
            <h2 id='7935788765' className="dynamicStyle fsize28 md-fsize24 sm-fsize20 font-medium cust-textColor ">
              {data ? data['7935788765'] : 'Popular Coures'}
              </h2>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 lg:pb-18 md:pb-16 pb-12">
              {allCourse && allCourse.result && allCourse.result.length > 0
                ? allCourse.result.map((course, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          (colorMode === 'light'
                            ? 'rounded-lg cursor-pointer  themeShadow'
                            : 'cust-bgColor rounded-lg  cursor-pointer') +
                          ' ' +
                          (selectedStyle === 'Default' && colorMode !== 'dark'
                            ? 'cust-shadow'
                            : '')
                        }
                      >
                        <div className="relative image-product overflow-hidden bradius-card-top">
                          <Image
                            className="img-h-220 w-full opct"
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                            alt="img"
                          />
                          <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                            <div className="">
                              <p className="fsize14 textColor-light">4.5/5</p>
                            </div>
                            <div>
                              <FeatherIcon
                                className="filled-blk "
                                icon="star"
                                size={18}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <div className="flex justify-between  text-b2b2b2">
                            <p className="fsize14 font-medium">12 Lessons</p>
                            <p className=" fsize14  font-medium">
                              {course.totalhours} hours
                            </p>
                          </div>
                          <div className="py-3">
                            <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1 lineClamp1">
                              {course['course name']}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                              ₹ {course['actual rate']}/-
                            </p>
                            <div className="flex gap-4">
                              <FeatherIcon
                                className=" cust-textColor"
                                icon="heart"
                                size={20}
                              />
                              <FeatherIcon
                                className="cust-textColor "
                                icon="shopping-cart"
                                size={20}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
          <div className="">
            <div className="lg:flex md:flex justify-between items-center lg:mb-8 md:mb-6 mb-4">
            <h2 id='8135346237' className="dynamicStyle fsize28 md-fsize24 sm-fsize20 font-medium cust-textColor ">
              {data ? data['8135346237'] : 'All Course'}
              </h2>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8">
              {allCourse && allCourse.result && allCourse.result.length > 0
                ? allCourse.result.map((course, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          (colorMode === 'light'
                            ? 'rounded-lg cursor-pointer  themeShadow'
                            : 'cust-bgColor rounded-lg  cursor-pointer') +
                          ' ' +
                          (selectedStyle === 'Default' && colorMode !== 'dark'
                            ? 'cust-shadow'
                            : '')
                        }
                      >
                        <div className="relative image-product overflow-hidden rounded-t">
                          <Image
                            className="img-h-220 w-full opct"
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                            alt="img"
                          />
                          <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                            <div className="">
                              <p className="fsize14 textColor-light">4.5/5</p>
                            </div>
                            <div>
                              <FeatherIcon
                                className="filled-blk "
                                icon="star"
                                size={18}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <div className="flex justify-between  text-b2b2b2">
                            <p className="fsize14 font-medium">12 Lessons</p>
                            <p className=" fsize14  font-medium">
                              {course.totalhours} hours
                            </p>
                          </div>
                          <div className="py-3">
                            <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1 lineClamp1">
                              {course['course name']}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                              ₹ {course['actual rate']}/-
                            </p>
                            <div className="flex gap-4">
                              <FeatherIcon
                                className=" cust-textColor"
                                icon="heart"
                                size={20}
                              />
                              <FeatherIcon
                                className="cust-textColor "
                                icon="shopping-cart"
                                size={20}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCategories;
