import { START_LOADING, STOP_LOADING,SET_DARKMODE } from "./loader-action";

const initialState = {
    loading: false,
    DarkMode:false
}

const LoadingReducer = (state=initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, loading: true}
        case STOP_LOADING:
            return { ...state, loading: false}   
    
            case SET_DARKMODE:
                return { ...state, DarkMode: action.payload}   
        
        default:
            return state;
    }

}

export default LoadingReducer;