import React, { useEffect, useRef, useState } from 'react';
import { Image, Input, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

const Discount3 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const elementRef = useRef(null);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div>
      <div
        className='bg-primary h-300px sm-h-250px relative flex justify-center items-center theme-bg themeShadow' style={{ background: thisComponent.sectionColor }}
      >
        <div className="lg:w-3/5 md:w-3/5 w-full mx-auto text-center px-5">
          <p
            id="4326679851"
            className="dynamicStyle fsize32 md-fsize28 sm-fsize20 font-semibold clr-fff cust-textColor lg:pb-4 md:pb-4 pb-2 theme-textColor"
          >
            {data ? data['4326679851'] : 'Lorem Ipsum'}
          </p>
        </div>
      </div>
      <div
        className={
          colorMode === 'light'
            ? 'lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6 w-11/12 mx-auto cust-shadow bg-fff discount-3 rounded-md theme-bg themeShadow'
            : 'lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6 w-11/12 mx-auto cust-shadow cust-bgColor discount-3 rounded-md theme-bg themeShadow'
        }
      >
        <div className="lg:flex md:flex lg:gap-10 md:gap-6">
          <div className="lg:w-3/5 md:w-3/5 w-full">
            <p
              id="7558928073"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold mb-4 cust-textColor"
            >
              {data ? data['7558928073'] : 'Lorem Ipsum'}
            </p>
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="fsize20 sm-fsize16 font-semibold cust-textColor">
                    Product 1
                  </p>
                  <div className="flex gap-2 items-center py-1 ">
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <p className="text-gray fsize14 textColor-light">
                      4.0 review
                    </p>
                  </div>
                  <p className="text-gray fsize14 font-semibold textColor-light">
                    QTY x 2
                  </p>
                </div>
                <div>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="lg:w-28 lg:h-28 md::w-28 md:h-28 w-24 h-24 rounded-lg"
                  />
                </div>
              </div>
            </div>
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="fsize20 sm-fsize16 font-semibold cust-textColor">
                    Product 2
                  </p>
                  <div className="flex gap-2 items-center py-1 ">
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <p className="text-gray fsize14 textColor-light">
                      4.0 review
                    </p>
                  </div>
                  <p className="text-gray fsize14 font-semibold textColor-light">
                    QTY x 2
                  </p>
                </div>
                <div>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="lg:w-28 lg:h-28 md::w-28 md:h-28 w-24 h-24 rounded-lg"
                  />
                </div>
              </div>
            </div>
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="fsize20 sm-fsize16 font-semibold cust-textColor">
                    Product 3
                  </p>
                  <div className="flex gap-2 items-center py-1 ">
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <FeatherIcon
                      icon="star"
                      className="text-gray textColor-light"
                      size={14}
                    />
                    <p className="text-gray fsize14 textColor-light">
                      4.0 review
                    </p>
                  </div>
                  <p className="text-gray fsize14 font-semibold textColor-light">
                    QTY x 2
                  </p>
                </div>
                <div>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className="lg:w-28 lg:h-28 md::w-28 md:h-28 w-24 h-24 rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-2/5 md:w-2/5 w-full bdr-gray-1px px-8 py-6  lg:mt-0 md:mt-0 mt-8">
            <div className="flex justify-between gap-4">
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">
                Cost
              </p>
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">-</p>
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">
                2000
              </p>
            </div>
            <div className="flex justify-between gap-4 lg:py-10 md:py-8 py-6">
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">
                Coupon Discount
              </p>
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">-</p>
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">
                -150
              </p>
            </div>
            <div className="flex justify-between gap-4">
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">
                Tax
              </p>
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">-</p>
              <p className="fsize18 sm-fsize16 font-medium cust-textColor">
                00
              </p>
            </div>
            <hr className="bdr-bottom my-6" />
            <div className="flex justify-between">
              <p className="fsize18 font-semibold cust-textColor">Total</p>
              <p className="fsize18 font-semibold cust-textColor">₹1850</p>
            </div>
            <div className="lg:flex  gap-4 my-4">
              <div className="lg:w-3/5 w-full lg:mb-0 mb-3">
                <Input
                  type="text"
                  placeholder="Coupon Code"
                  className="theme-border sm-fsize14 bdr-gray-1px "
                />
              </div>
              <div className="lg:w-2/5 w-full ">
                <Button
                  className='dynamicStyle w-full b_button_primary px-8 py-4 theme-btn sm-fsize10  '
                >
                  Apply
                </Button>
              </div>
            </div>
            <div>
              <Button
                className='dynamicStyle w-full b_button_primary px-8 py-4 theme-btn sm-fsize10 mt-6'
              >
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discount3;
