import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Image} from 'primereact/image'
import { useSelector } from "react-redux";
import { Autoplay, Navigation } from 'swiper/modules';

const Testimonial7 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  return (
    <div className="px-12 container m-auto" style={{ background: thisComponent.sectionColor }}>
      <div class="text-center pt-5">
        <span class="fsize30 sm-fsize14 text-primary">Testimonial </span>
        <h2 class="fsize24 font-semibold mt-2 ">
          Some humble reviews given by our investors
        </h2>
      </div>
      <Swiper
        spaceBetween={30}
        navigation={true}
        modules={[Navigation]}
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          820: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1.2,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1.2,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="flex w-full mt-8 ">
            <div className="bg-primary">
              <div className="px-28 py-16">
                <h2 className="font-semibold  fsize30 pt-3">
                  Help us improve our productivity
                </h2>
                <p className="fsize16 sm-fsize14 lg:mt-8 md:mt-1 mt-4 p-lineheight">
                  The long barrow was built on land previously inhabited in the
                  Mesolithic period. It consisted of a sub-rectangular earthen
                  tumulus, estimated to have been 15 metres (50 feet) in length,
                  with a chamber built from sarsen.
                </p>
                <div className="lg:mt-6 md:mt-1 mt-6">
                  <h4 className="font-semibold text-primary text-md">
                    Jay Parmar
                  </h4>
                  <span className="text-xs text-515151">CEO, Buzz</span>
                </div>
              </div>
            </div>
            <div className="w-full h-full">
              <Image
                imageClassName="responsive h-400px w-full object-cover"
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex w-full mt-8 ">
            <div className="bg-primary">
              <div className="px-28 py-16">
                <h2 className="font-semibold  fsize30 pt-3">
                  Help us improve our productivity
                </h2>
                <p className="fsize16 sm-fsize14 lg:mt-8 md:mt-1 mt-4 p-lineheight">
                  The long barrow was built on land previously inhabited in the
                  Mesolithic period. It consisted of a sub-rectangular earthen
                  tumulus, estimated to have been 15 metres (50 feet) in length,
                  with a chamber built from sarsen.
                </p>
                <div className="lg:mt-6 md:mt-1 mt-6">
                  <h4 className="font-semibold text-primary text-md">
                    Jay Parmar
                  </h4>
                  <span className="text-xs text-515151">CEO, Buzz</span>
                </div>
              </div>
            </div>
            <div className="w-full h-full">
              <Image
                imageClassName="responsive h-400px w-full object-cover"
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex w-full mt-8 ">
            <div className="bg-primary">
              <div className="px-28 py-16">
                <h2 className="font-semibold  fsize30 pt-3">
                  Help us improve our productivity
                </h2>
                <p className="fsize16 sm-fsize14 lg:mt-8 md:mt-1 mt-4 p-lineheight">
                  The long barrow was built on land previously inhabited in the
                  Mesolithic period. It consisted of a sub-rectangular earthen
                  tumulus, estimated to have been 15 metres (50 feet) in length,
                  with a chamber built from sarsen.
                </p>
                <div className="lg:mt-6 md:mt-1 mt-6">
                  <h4 className="font-semibold text-primary text-md">
                    Jay Parmar
                  </h4>
                  <span className="text-xs text-515151">CEO, Buzz</span>
                </div>
              </div>
            </div>
            <div className="w-full h-full">
              <Image
                imageClassName="responsive h-400px w-full object-cover"
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
export default Testimonial7;