import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Addbanner6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="pb-28">
      <div id="7853233455" className="dynamicStyle2 h-350px relative w-full">
        <Image
          src={data ? getImage(data["7853233455"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          imageClassName="h-350px w-full"
        />
        <div className="absl6-text">
          <p
            id="0540318716"
            className="dynamicStyle fsize16 sm-fsize14 text-primary font-semibold py-2"
          >
            {data ? data["0540318716"] : "Lorem Ipsum"}
          </p>
          <p id="5929410239" className="dynamicStyle section_header">
            {data ? data["5929410239"] : "Lorem ipsum dolor sit amet."}
          </p>
          <ReactQuill
            id="2112556652"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["2112556652"] : "Lorem Ipsum"}
          />
        </div>
        <div className="grid grid-cols-4 lg:gap-6 md:gap-6 gap-2 lg:px-20 md:px-14 px-2 lg:w-3/4 w-full absl6-cards">
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center theme_insertShadow theme-bg theme-border-0">
            <div>
              <p id="6643842590" className="dynamicStyle hero_title">
                {data ? data["6643842590"] : "324+"}
              </p>
              <p id="3714027548" className="dynamicStyle card_title">
                {data ? data["3714027548"] : "Our Clients"}
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center theme_insertShadow theme-bg theme-border-0">
            <div>
              <p id="4349401570" className="dynamicStyle hero_title">
                {data ? data["4349401570"] : "324+"}
              </p>
              <p id="3012648253" className="dynamicStyle card_title">
                {data ? data["3012648253"] : "Our Clients"}
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center theme_insertShadow theme-bg theme-border-0">
            <div>
              <p id="6526303637" className="dynamicStyle hero_title">
                {data ? data["6526303637"] : "324+"}
              </p>
              <p id="0160897624" className="dynamicStyle card_title">
                {data ? data["0160897624"] : "Our Clients"}
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center theme_insertShadow theme-bg theme-border-0">
            <div>
              <p id="4377684219" className="dynamicStyle hero_title">
                {data ? data["4377684219"] : "324+"}
              </p>
              <p id="5747298989" className="dynamicStyle card_title">
                {data ? data["5747298989"] : "Our Clients"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addbanner6;
