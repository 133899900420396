import { ProgressBar } from "primereact/progressbar";
import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";

const PollSection4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="6238936678"
          className="dynamicStyle inline-block section_header"
        >
          {data ? data["6238936678"] : "Poll Question 2"}
        </h3>
        <ReactQuill
          id="2066868935"
          theme="bubble"
          readOnly
          className="section_detail bubble dynamicStyle lg:w-2/3"
          value={data ? data["2066868935"] : "Lorem Ipsum"}
        />
        <div className="lg:py-8 md:py-8 py-6 lg:w-1/2 md:w-1/2">
          <div className="flex gap-4 mb-4">
            <div className="w-full">
              <p className="mb-2 text_surface600">2023 - 2024</p>
              <ProgressBar
                style={{ height: "20px" }}
                value={80}
                className="rounded-full themeShadow"
              />
            </div>
          </div>
          <div className="flex gap-4 mb-4">
            <div className="w-full">
              <p className="mb-2 text_surface600">2024 - 2025</p>
              <ProgressBar
                style={{ height: "20px" }}
                value={55}
                className="rounded-full themeShadow"
              />
            </div>
          </div>
          <div className="flex gap-4 mb-4">
            <div className="w-full">
              <p className="mb-2 text_surface600">2025 - 2026</p>
              <ProgressBar
                style={{ height: "20px" }}
                value={20}
                className="rounded-full themeShadow"
              />
            </div>
          </div>
        </div>
        <div className="mt-0 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup>
              <Avatar
                name="Ryan Florence"
                shape="circle"
                image="https://bit.ly/ryan-florence"
              />
              <Avatar
                name="Segun Adebayo"
                shape="circle"
                image="https://bit.ly/sage-adebayo"
              />
              <Avatar
                name="Kent Dodds"
                shape="circle"
                image="https://bit.ly/kent-c-dodds"
              />
              <Avatar
                name="Prosper Otemuyiwa"
                shape="circle"
                image="https://bit.ly/prosper-baba"
              />
              <Avatar
                name="Christian Nwamba"
                shape="circle"
                image="https://bit.ly/code-beast"
              />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text_surface600 dynamicStyle"
                id="7991342936"
              >
                {data ? data["7991342936"] : "Total votes:"}
              </p>
              <p className="fsize12 text_surface600">24</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollSection4;
