import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux/es/exports';
import { Image } from 'primereact/image';

const ContactDetails2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <section className="theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div id="3679652801" className="dynamicStyle2 lg:w-2/12 md:w-2/12">
            <Image
              src={data ? getImage(data['3679652801']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              imageClassName="lg:w-40 w-16 lg:h-40 h-16 object-contain sm-mx-auto"
              alt="Img_Preview"
            />
          </div>
          <div className="lg:w-10/12 md:w-10/12">
            <p
              id="7812946110"
              className="dynamicStyle fsize20 sm-fsize15 lg:mt-0 mt-3 theme-textColor"
            >
              {data
                ? data['7812946110']
                : 'In case of any queries, please reach out to'}
            </p>
            <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4 mt-4">
              <div className="flex lg:items-center md:items-start items-center gap-3">
                <div
                  id="6678315068"
                  className="dynamicStyle2 lg:w-16 lg:h-16 w-8 h-8 object-contain wid-20per sm-wid-10per"
                >
                  <Image
                    src={data ? getImage(data['6678315068']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    imageClassName="lg:w-16 lg:h-16 w-8 h-8 object-contain"
                    alt="Img_Preview"
                  />
                </div>
                <div className="wid-80per sm-wid-90per">
                  <p
                    id="7624405467"
                    className="dynamicStyle fsize14 sm-fsize12 theme-textColor"
                  >
                    {data ? data['7624405467'] : '011-430-92248 (Ext:303)'}
                  </p>
                </div>
              </div>
              <div className="flex lg:items-center md:items-start items-center gap-3">
                <div
                  id="0101126984"
                  className="dynamicStyle2 lg:w-16 lg:h-16 w-8 h-8 object-contain wid-20per sm-wid-10per"
                >
                  <Image
                    src={data ? getImage(data['0101126984']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    imageClassName="lg:w-16 lg:h-16 w-8 h-8 object-contain"
                    alt="Img_Preview"
                  />
                </div>
                <div className="wid-80per sm-wid-90per">
                  <p
                    id="1790104035"
                    className="dynamicStyle fsize14 sm-fsize12 theme-textColor"
                  >
                    {data
                      ? data['1790104035']
                      : '(Monday to Friday - 10:00 AM to 6:00 PM)'}
                  </p>
                </div>
              </div>
              <div className="flex lg:items-center md:items-start items-center gap-3">
                <div
                  id="4144656065"
                  className="dynamicStyle2 lg:w-16 lg:h-16 w-8 h-8 object-contain wid-20per sm-wid-10per"
                >
                  <Image
                    src={data ? getImage(data['4144656065']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    imageClassName="lg:w-16 lg:h-16 w-8 h-8 object-contain"
                    alt="Img_Preview"
                  />
                </div>
                <div className="wid-80per sm-wid-90per">
                  <p
                    id="5476270844"
                    className="dynamicStyle fsize14 sm-fsize12 theme-textColor"
                  >
                    {data
                      ? data['5476270844']
                      : 'sbiscolarship@buddy4study.com'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="p-2 bg-primary"></div>
    </section>
  );
};

export default ContactDetails2;
