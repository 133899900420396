import { Image } from "primereact/image";
import React from "react";
import { useSelector } from "react-redux";

const CategoriesSection2 = ({ pageName }) => {
  const categories = [
    {
      id: 1,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 1",
    },
    {
      id: 2,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 2",
    },
    {
      id: 3,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 3",
    },
    {
      id: 4,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 4",
    },
    {
      id: 5,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 5",
    },
    {
      id: 6,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 6",
    },
    {
      id: 7,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 7",
    },
    {
      id: 8,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 8",
    },
    {
      id: 9,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 9",
    },
    {
      id: 10,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 10",
    },
    {
      id: 11,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 11",
    },
    {
      id: 12,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      name: "Category 12",
    },
  ];

  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <section className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <h2 id="4866160095" className="dynamicStyle section_header">
            {data ? data["4866160095"] : "Popular Category"}
          </h2>
        </div>
        <div className="w-full grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 lg:gap-8 md:gap-8 gap-4 lg:mt-12 md:mt-10 mt-8 lg:px-12 md:px-8">
          {categories.map((category, index) => {
            return (
              <div
                key={index}
                className="onHoverShadow themeShadow themeShadow-insert rounded-lg p-4 cursor-pointer"
              >
                <Image
                  src={category.imageUrl}
                  alt={category.name}
                  imageClassName="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-lg mx-auto"
                />
                <p className="fsize14 mt-3 text-center">{category.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CategoriesSection2;
