import { DownloadIcon } from '@chakra-ui/icons';
import { Button } from 'primereact/button';
import React from 'react';
import { FiEye, FiLock, FiUsers } from 'react-icons/fi';
import FeatherIcon from 'feather-icons-react';
import { MoreVertical } from 'react-feather';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import UploaderBox from '../../../../../../../components/UploaderBox';

const Userrequest = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Add Folder</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div className="py-3 px-1">
              <div className="">
                <label className="text-primary">File Name</label>
                <Input placeholder="Enter" className="mt-2" />
              </div>
              <div className="mt-3">
                <label className="text-primary">
                  Select Approver (Select Maximum 2)
                </label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <label className="text-primary">
                  Select Reviewer (Optional)
                </label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <label className="text-primary">Select Co-author</label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-5">
                <UploaderBox accept="image/*" />
              </div>
              <div className="flex items-center gap-3 mt-5">
                <Button className="w-full b_button_primary px-6 py-2">
                  Submit
                </Button>
                <Button className="w-full b_button_secondary px-6 py-2">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="w-full pt-8">
        <div className="lg:flex md:flex items-baseline gap-2">
          <h2 className="fsize28 sm-fsize20 text-primary">User Request File</h2>
          <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
        </div>
        <div className="mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          <div className="borderall rounded-lg py-3 px-4 relative">
            <div className="w-full ">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div>
                <div className="mt-3 flex gap-2 items-center justify-center w-full">
                  <div className="w-full">
                  <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
                  </div>
                  <div className="w-full flex items-center gap-2">
                    <Button
                      outlined
                      borderRadius={30}
                      className="sm-pad6 px-6 py-2"
                    >
                      <FeatherIcon size="16" icon="eye" className="text-gray" />
                    </Button>
                    <Button
                      outlined
                      borderRadius={30}
                      className="sm-pad6 px-6 py-2"
                    >
                      <FeatherIcon
                        size="16"
                        icon="message-square"
                        className="text-gray"
                      />
                    </Button>
                  </div>
                </div>
                <Button
                 outlined
                  onClick={onOpen}
                  className="text-primary fsize14 mt-3 w-full px-6 py-2"
                >
                  Upload Changes
                </Button>
              </div>
            </div>
            <div className="absolute top-0 right-0 mt-3">
              <Menu>
                <MenuButton as={Button} colorScheme="transparent" h={2} p={0}>
                  <MoreVertical className="text-primary" />
                </MenuButton>
                <MenuList>
                  <MenuItem className="fsize14 px-10">Version</MenuItem>
                  <MenuItem className="fsize14 px-10">Logs</MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userrequest;
