import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard23 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex block justify-center items-center">
            <div className="lg:w-4/12 md:w-4/12 w-full lg:pr-5 md:pr-4 pr-0">
              <h5 id="8231509598" className="dynamicStyle section_header">
                {data ? data["8231509598"] : "Lorem Ipsum"}
              </h5>
              <ReactQuill
                id="0295825076"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail lineclamp-quill"
                value={data ? data["0295825076"] : "Welcome to My World"}
              />
              <div className="flex items-center gap-2 mt-4 lg:w-3/4 md:w-3/4 w-full">
                <div className="w-1/5 gap-3 flex items-center justify-center">
                  <FeatherIcon
                    icon="arrow-right"
                    className="clr-fff flex bg-primary rounded-full p-2"
                    size="30"
                  />
                </div>
                <p
                  id="3333338865"
                  className="dynamicStyle w-4/5 textColor-light fsize16 md-fsize14 sm-fsize13 text-gray uppercase font-semibold"
                >
                  {data ? data["3333338865"] : "Lorem Ipsum"}
                </p>
              </div>
            </div>
            <div
              className="lg:w-8/12 md:w-8/12 w-full lg:mt-0 md:mt-0 mt-4 prime_card_container dynamicStyle"
              id="7427041232"
            >
              <Swiper
                spaceBetween={15}
                slidesPerView={3}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 2.8,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2.4,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {data && data["7427041232"]
                  ? data["7427041232"].map((e, index) => {
                      return (
                        <SwiperSlide>
                          <Card
                            className="relative prime_card_0 overflow-hidden card lg:mx-2 md:mx-2"
                            key={index}
                          >
                            <div className="relative feature23 ">
                              <Image
                                src={e ? getImage(e.field1) : ""}
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                alt={e.field2}
                                imageClassName="lg:h-72 md:h-52 h-52 w-full object-cover"
                              />
                              <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full features"></div>
                              <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full lg:p-6 mg:p-4 p-3 flex items-end">
                                <div className="">
                                  <h3 className="card_title_0">{e.field2}</h3>
                                  <div className="featurecardhover">
                                    <ReactQuill
                                      theme="bubble"
                                      readOnly
                                      className="bubble-w card_description lineclamp-quill"
                                      value={e.field3}
                                    />
                                    <div className="flex items-center gap-2 mt-2">
                                      <p
                                        id=""
                                        className="clr-fff cust-textColor fsize13 md-fsize13 sm-fsize13"
                                      >
                                        {e.field4}
                                      </p>
                                      <FeatherIcon
                                        icon="arrow-right"
                                        className="clr-fff flex"
                                        size="20"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </SwiperSlide>
                      );
                    })
                  : "no-card"}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard23;
