import React from "react";
import { useSelector } from "react-redux";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const DownloadSection4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container lg:flex md:flex items-center lg:gap-10 md:gap-6">
        <div
          id="3350966812"
          className="dynamicStyle2 lg:w-4/12 md:w-4/12 w-full"
        >
          <Image
            src={data ? getImage(data["3350966812"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img-download"
            imageClassName="h-400px md-h-350px sm-h-250px w-full lg:mb-0 md:mb-0 mb-4 object-cover"
          />
        </div>
        <div className="lg:w-8/12 md:w-8/12 w-full">
          <div className="lg:mb-8 mb-4">
            <p id="6029140121" className="dynamicStyle hero_title">
              {data ? data["6029140121"] : "Lorem Ipsum"}
            </p>
            <ReactQuill
              id="1840104074"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data["1840104074"] : "Lorem Ipsum"}
            />
          </div>
          <div className="flex items-center lg:gap-8 gap-4">
            <div id="2369614681" className="dynamicStyle2 lg:w-3/12 w-4/12">
              <Image
                src={data ? getImage(data["2369614681"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-download"
                imageClassName="lg:h-48 md:h-48 h-40 w-full object-cover"
              />
            </div>
            <div className="lg:w-9/12 w-8/12">
              <ReactQuill
                id="5730417540"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle mb-4 card_description"
                value={data ? data["5730417540"] : "Lorem Ipsum"}
              />

              <div className="flex items-center gap-2">
                <FaGooglePlay size={22} className="text_surface500" />
                <FaApple size={26} className="text_surface500" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection4;
