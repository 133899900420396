import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Image } from "primereact/image";

const EcomProductCategory1 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let allTeam = useSelector((state) => state.Teams.Teams);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center w-full">
          <div className="lg:w-1/3 md:w-4/12 w-full lg:mb-0 md:mb-0 mb-4">
            <h3 id="0933014268" className="dynamicStyle section_header">
              {data ? data["0933014268"] : "Explore by Category"}
            </h3>
            <ReactQuill
              id="2724694630"
              theme="bubble"
              readOnly
              className="card_description bubble dynamicStyle mt-2"
              value={data ? data["2724694630"] : "Lorem Ipsum"}
            />
            <p className="mt-4 text-primary section_detail_0">
              <span
                id="2494375053"
                className="dynamicStyle flex items-center cursor-pointer"
              >
                {data ? data["2494375053"] : "All Categories"}
                <FeatherIcon
                  className="text-primary ml-2"
                  size={18}
                  icon="arrow-right"
                />
              </span>
            </p>
          </div>
          <div
            id="5584288495"
            className="dynamicStyle lg:w-3/4 md:w-8/12 w-full"
          >
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              className="mySwiper"
              breakpoints={{
                1536: {
                  slidesPerView: 2.4,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 2.4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2.4,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 1.8,
                  spaceBetween: 15,
                },
                820: {
                  slidesPerView: 1.8,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 1.8,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 18,
                },
                425: {
                  slidesPerView: 1.5,
                  spaceBetween: 18,
                },
                325: {
                  slidesPerView: 1.5,
                  spaceBetween: 18,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>

              {data && data["5584288495"]
                ? data["5584288495"].map((e, index) => {
                    return (
                      <SwiperSlide className="">
                        <Card className="prime_card">
                          <Image
                            imageClassName="lg:h-60 md:h-52 h-52 w-full lg:mb-3 mb-2 rounded-lg"
                            src={e ? getImage(e.field1) : ""}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                          />
                          <p className="card_title_0">{e.field2}</p>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : "no-card"}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomProductCategory1;
