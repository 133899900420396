import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard55 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex gap-4 items-center">
          <p
            id="9975713486"
            className="dynamicStyle section_header lg:w-1/2 md:w-1/2"
          >
            {data ? data['9975713486'] : 'Lorem ipsum'}
          </p>
          <div className="lg:w-1/2 md:w-1/2">
            <ReactQuill
              id="1843762620"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle  section_detail_0"
              value={data ? data['1843762620'] : 'Welcome to My World'}
            />
          </div>
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
          <Card className="prime_card themeShadow theme-bg">
            <div className="flex items-center gap-3 mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="w-10 h-10 rounded-full object-contain"
              />
              <p className="theme-textColor fsize16">John</p>
              <p className="px-1 fsize12 text-primary bg-ec rounded-md leading-5 theme-textColor themeShadow theme-bg">
                Host
              </p>
            </div>
            <p className="section_detail theme-textColor">
              Lorem ipsum dolor sit amet. dolor sit amet
            </p>
            <div className="flex items-center gap-2 lg:mt-14 md:mt-12 mt-10">
              <FeatherIcon
                icon="map-pin"
                size={20}
                className="cursor-pointer theme-textColor"
              />
              <p className="card_title_0 theme-textColor">Seoul</p>
            </div>
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <div className="flex items-center gap-3 mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="w-10 h-10 rounded-full object-contain"
              />
              <p className="theme-textColor fsize16">John</p>
              <p className="px-1 fsize12 text-primary bg-ec rounded-md leading-5 theme-textColor themeShadow theme-bg">
                Host
              </p>
            </div>
            <p className="section_detail theme-textColor">
              Lorem ipsum dolor sit amet. dolor sit amet
            </p>
            <div className="flex items-center gap-2 lg:mt-14 md:mt-12 mt-10">
              <FeatherIcon
                icon="map-pin"
                size={20}
                className="cursor-pointer theme-textColor"
              />
              <p className="card_title_0 theme-textColor">Seoul</p>
            </div>
          </Card>
          <Card className="prime_card themeShadow theme-bg">
            <div className="flex items-center gap-3 mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                imageClassName="w-10 h-10 rounded-full object-contain"
              />
              <p className="theme-textColor fsize16">John</p>
              <p className="px-1 fsize12 text-primary bg-ec rounded-md leading-5 theme-textColor themeShadow theme-bg">
                Host
              </p>
            </div>
            <p className="section_detail theme-textColor">
              Lorem ipsum dolor sit amet. dolor sit amet
            </p>
            <div className="flex items-center gap-2 lg:mt-14 md:mt-12 mt-10">
              <FeatherIcon
                icon="map-pin"
                size={20}
                className="cursor-pointer theme-textColor"
              />
              <p className="card_title_0 theme-textColor">Seoul</p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard55;
