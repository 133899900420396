import React, { useState } from 'react';
import { Box, Image, Input, Select, Switch } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import BookingPortal from '../../components/Forms/BookingPortal';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorMode,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from '@chakra-ui/react';
import { Calendar } from 'react-calendar';

const BookingPortal6 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const [value, onChange] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTimezone, setselectedTimezone] = useState('');

  const oncloseBooking = () => {
    console.log('data');
  };
  const { colorMode, toggleColorMode } = useColorMode();

  const steps = [
    { title: '', description: '' },
    { title: '', description: '' },
    { title: '', description: '' },
  ];
  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  return (
    <div>
      <div className=" w-full lg:px-12 md:px-8 px-5">
        <div className="container mx-auto">
          <div className="lg:flex md:flex ">
            <div className="lg:w-3/5 md:w-1/2 w-full lg:py-10 md:py-10 py-6">
              <div className="text-left lg:pr-10 pr-3 ">
                <h4
                    id="2566778317"
                  className="dynamicStyle fsize28 md-fsize26 sm-fsize23 font-semibold pb-2 bdr-btm cust-textColor"
                >
                  {data ? data['2566778317'] : 'Lorem Ipsum'}
                </h4>
                <div className="lg:py-8 md:py-8 py-4 flex gap-4">
                  <Stepper index={1} orientation="vertical" gap="0">
                    <Step>
                      <StepIndicator>
                        <StepNumber>1</StepNumber>
                      </StepIndicator>
                      <div className="mb-6">
                        <p className="text-gray fsize18 sm-fsize16 font-medium cust-textColor">
                          Choose options
                        </p>
                        <div className="flex gap-4 mt-4">
                          <div className=" relative rounded-lg bdr-blue">
                            <Image
                              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt="img"
                              className="h-28 lg:w-44 md:w-44 w-42 rounded-lg"
                            />
                            <p className="absolute top-2 right-3">Pickup</p>
                          </div>
                          <div className=" relative borderfeature rounded-lg">
                            <Image
                              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt="img"
                              className="h-28 lg:w-44 md:w-44 w-42 rounded-lg"
                            />
                            <p className="absolute top-2 right-3">Store Drop</p>
                          </div>
                        </div>
                      </div>
                      <StepSeparator />
                    </Step>
                    <Step>
                      <StepIndicator>
                        <StepNumber className="cust-textColor">2</StepNumber>
                      </StepIndicator>
                      <div
                        className={
                          colorMode === 'light'
                            ? ' bg-f4f5f6 borderfeature bdr-rad-8px themeShadow'
                            : 'borderfeature cust-bgColor themeShadow'
                        }
                      >
                        <Calendar
                          className="w-full textColor-light"
                          value={value}
                        />
                      </div>
                      <StepSeparator />
                    </Step>

                    <Step>
                      <StepIndicator className="mt-6">
                        <StepNumber className="cust-textColor">3</StepNumber>
                      </StepIndicator>
                      <div
                        className={
                          colorMode === 'light'
                            ? 'borderfeature bg-f4f5f6 rounded-lg mt-6 themeShadow'
                            : 'borderfeature rounded-lg mt-6 cust-bgColor themeShadow'
                        }
                      >
                        <p className="text-gray p-2 bdr-btm cust-textColor">
                          Time Select
                        </p>
                        <div className="flex flex-wrap gap-4 p-4">
                          <Button
                            className='theme-btn b_button_secondary px-6 py-2 rounded-full sm-fsize14 rounded-lg'
                          >
                            12:00 PM
                          </Button>
                          <Button
                            className='theme-btn b_button_primary px-6 py-2 rounded-full sm-fsize14 rounded-lg'
                          >
                            1:00 PM
                          </Button>
                          <Button
                            height="40px"
                            outlined
                            className="theme-btn px-6 py-2 rounded-full sm-fsize14 rounded-lg"
                            px={6}
                          >
                            2:00 PM
                          </Button>
                          <Button
                            height="40px"
                           outlined
                            className="theme-btn px-6 py-2 rounded-full sm-fsize14 rounded-lg"
                            px={6}
                          >
                            3:00 PM
                          </Button>
                          <Button
                            height="40px"
                            outlined
                            className="theme-btn px-6 py-2 rounded-full sm-fsize14 rounded-lg"
                            px={6}
                          >
                            4:00 PM
                          </Button>
                          <Button
                            height="40px"
                            className='theme-btn b_button_primary px-6 py-2 rounded-full sm-fsize14 rounded-lg'
                          >
                            5:00 PM
                          </Button>
                          <Button
                            height="40px"
                            outlined
                            className="theme-btn px-6 py-2 rounded-full sm-fsize14 rounded-lg"
                            px={6}
                          >
                            6:00 PM
                          </Button>
                        </div>
                      </div>
                      <StepSeparator />
                    </Step>
                  </Stepper>
                </div>
              </div>
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'lg:w-2/5 md:w-1/2 w-full px-8 bg-f4f5f6 lg:py-10 md:py-10 py-8'
                  : 'lg:w-2/5 md:w-1/2 w-full px-8 lg:py-10 md:py-10 py-8'
              }
            >
              <div
                className={
                  colorMode === 'light'
                    ? 'p-2 bg-fff rounded-md theme-bg themeShadow'
                    : 'p-2 cust-bgColor rounded-md theme-bg themeShadow'
                }
              >
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-32 w-full rounded-md"
                />
                <div className="flex gap-2 items-center py-4">
                  <p className="fsize14 font-semibold text-secondary cust-textColor">
                    Say
                  </p>
                  <FeatherIcon
                    icon="star"
                    className="cust-textColor "
                    size={16}
                  />
                  <p className="cust-textColor">4.5</p>
                </div>
                <div className="flex gap-2 items-center">
                  <FeatherIcon
                    icon="map-pin"
                    className="cust-textColor text-secondary textColor-light"
                    size={16}
                  />
                  <p className="fsize14 font-semibold textColor-light">
                    Akurli Road
                  </p>
                  <p className="fsize12 text-gray textColor-light">• 10Km</p>
                </div>
              </div>
              <p
                className={
                  colorMode === 'light'
                    ? 'bg-fff rounded-md p-3 my-6 text-center font-medium sm-fsize14 theme-bg themeShadow'
                    : 'cust-bgColor cust-textColor rounded-md p-3 my-6 text-center font-medium sm-fsize14 theme-bg themeShadow'
                }
              >
                Estimated Days: 5 Days
              </p>

              <div className="">
                <div
                  className={
                    colorMode === 'light' ? 'pb-2 bdr-btm' : 'pb-2 bdr-btm'
                  }
                >
                  <div className="flex justify-between">
                    <p className="dynamicStyle cust-textColor font-semibold">
                      Services
                    </p>
                    <p className="dynamicStyle cust-textColor font-semibold">
                      Amount
                    </p>
                  </div>
                </div>
                <div
                  className={
                    colorMode === 'light' ? 'pt-4 bdr-btm' : 'pt-4 bdr-btm'
                  }
                >
                  <div className="flex justify-between mb-4">
                    <p className="fsize16 sm-fsize14 font-semibold text-gray textColor-light">
                      Lorem
                    </p>
                    <p className="fsize16 sm-fsize14 font-semibold textColor-light">
                      ₹500
                    </p>
                  </div>
                  <div className="flex justify-between mb-4">
                    <p className="fsize16 sm-fsize14 font-semibold text-gray textColor-light">
                      Lorem
                    </p>
                    <p className="fsize16 sm-fsize14 font-semibold textColor-light">
                      ₹500
                    </p>
                  </div>
                  <div className="flex justify-between mb-4">
                    <p className="fsize16 sm-fsize14 font-semibold text-gray textColor-light">
                      Lorem
                    </p>
                    <p className="fsize16 sm-fsize14 font-semibold textColor-light">
                      ₹500
                    </p>
                  </div>
                  <div className="flex justify-between mb-4">
                    <p className="fsize16 sm-fsize14 font-semibold text-gray textColor-light">
                      Lorem
                    </p>
                    <p className="fsize16 sm-fsize14 font-semibold textColor-light">
                      ₹500
                    </p>
                  </div>
                </div>
                <div className="py-4">
                  <div className="flex gap-4 my-4 items-center">
                    <Input
                      placeholder="coupon code"
                      className={
                        colorMode === 'light'
                          ? 'fsize14 bg-fff theme-bg themeShadow'
                          : 'fsize14 cust-bgColor theme-bg themeShadow'
                      }
                      size="lg"
                    />
                    <Button
                      height="48px"
                      className='fsize14 dynamicStyle theme-btn px-6 py-2' 
                    >
                      Apply
                    </Button>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <p className="font-semibold cust-textColor">
                    Use Existing Point
                  </p>
                  <div className="flex gap-2 items-center">
                    <p className="fsize15 sm-fsize13 text-secondary font-semibold cust-textColor">
                      448
                    </p>
                    <Switch colorScheme="blue" size="lg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div 
      className={
        colorMode === 'light'
          ? 'cust-shadow1 py-4 lg:px-12 md:px-8 px-5 theme-bg themeShadow'
          : 'cust-shadow1 py-4 lg:px-12 md:px-8 px-5 theme-bg themeShadow cust-bgColor'
      }
      >
        <div className="lg:flex md:flex gap-8 justify-end items-center container mx-auto">
          <div className="flex justify-center gap-4">
            <FeatherIcon
              icon="shopping-cart"
              className="cust-textColor "
              size={24}
            />
            <p className="sm-fsize14 cust-textColor">5 Added</p>
          </div>
          <div className="text-center lg:my-0 md:my-0 my-4">
            <p className="font-semibold fsize18 sm-fsize16 text-secondary cust-textColor">
              ₹2000
            </p>
            <p className="fsize14 sm-fsize12 text-gray textColor-light">
              Estimated Cost
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              height="50px"
              className='theme-btn b_button_primary px-8 py-2 rounded-full sm-fsize14 rounded-lg'
              onClick={onOpen}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPortal6;
