import { useSelector } from 'react-redux/es/exports';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

const AboutSection40 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, 'ss');
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <Card className="prime_card">
          <div className="lg:flex md:flex items-center gap-4">
            <div className="lg:w-7/12 md:w-7/12 w-full">
              <div className="">
                <h5 id="9330040048" className="dynamicStyle about_title">
                  {data ? data['9330040048'] : 'no-data'}
                </h5>
                <p
                  id="2141823330"
                  className="dynamicStyle card_description lineClamp3 "
                >
                  {data ? data['2141823330'] : 'no-data'}
                </p>
                <div className="lg:flex md:flex items-center justify-around gap-4 mt-8">
                  <div>
                    <div id="6700498815" className="dynamicStyle2">
                      <Image
                        imageClassName="h-16 w-16 rounded-full mx-auto object-contain"
                        src={data ? getImage(data['6700498815']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                      />
                    </div>
                    <p
                      id="7891090984"
                      className="dynamicStyle card_title_0 mt-3 text-center"
                    >
                      {data ? data['7891090984'] : 'no-data'}
                    </p>
                    <p
                      id="4178089656"
                      className="dynamicStyle card_description text-center"
                    >
                      {data ? data['4178089656'] : 'no-data'}
                    </p>
                  </div>
                  <div className="lg:mt-0 md:mt-0 mt-4">
                    <div id="7942490143" className="dynamicStyle2">
                      <Image
                        imageClassName="h-16 w-16 rounded-full mx-auto object-contain"
                        src={data ? getImage(data['7942490143']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                      />
                    </div>
                    <p
                      id="6985962579"
                      className="dynamicStyle card_title_0 mt-3 text-center"
                    >
                      {data ? data['6985962579'] : 'no-data'}
                    </p>
                    <p
                      id="0046416845"
                      className="dynamicStyle card_description text-center"
                    >
                      {data ? data['0046416845'] : 'no-data'}
                    </p>
                  </div>
                  <div className="lg:mt-0 md:mt-0 mt-4">
                    <div id="3008591417" className="dynamicStyle2">
                      <Image
                        imageClassName="h-16 w-16 rounded-full mx-auto object-contain"
                        src={data ? getImage(data['3008591417']) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                      />
                    </div>
                    <p
                      id="6518124073"
                      className="dynamicStyle card_title_0 mt-3 text-center"
                    >
                      {data ? data['6518124073'] : 'no-data'}
                    </p>
                    <p
                      id="9859121973"
                      className="dynamicStyle card_description text-center"
                    >
                      {data ? data['9859121973'] : 'no-data'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-5/12 md:w-5/12 w-full lg:mt-0 md:mt-0 mt-8">
              <div id="1562786983" className="dynamicStyle2">
                <Image
                  imageClassName="h-400px mdh-300px sm-h-250px w-full object-cover"
                  src={data ? getImage(data['1562786983']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AboutSection40;
