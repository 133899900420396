import React from "react";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";

const NewsletterSection13 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const dispatch = useDispatch();

  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <Card className="prime_card_0">
          <div className="lg:flex md:flex items-center overflow-hidden gap-8">
            <form
              onSubmit={formik.handleSubmit}
              className="lg:w-8/12 md:w-3/5 w-full lg:px-12 md:px-6 px-5 lg:py-0 md:py-0 py-5"
            >
              <p id="2876255246" className="dynamicStyle hero_title">
                {data ? data["2876255246"] : "Lorem Ipsum"}
              </p>
              <p id="8456263818" className="dynamicStyle card_description">
                {data ? data["8456263818"] : "Lorem Ipsum"}
              </p>

              <InputText
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type="text"
                invalid={!!formik.errors.email && formik.touched.email}
                placeholder="Email"
                className="w-full lg:mt-6 md:mt-3 mt-2 theme-border sm-fsize14 theme-border theme-bg"
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-danger fsize12 mt-2">
                  {formik.errors.email}
                </p>
              )}
            </form>
            <div className="lg:w-4/12 md:w-2/5 bg-ec w-full lg:px-12 lg:py-12 md:px-8 md:py-8 px-4 py-4">
              <div id="2651013520" className="dynamicStyle">
                {data && data["2651013520"]
                  ? data["2651013520"].map((e) => {
                      return (
                        <div className="flex items-start gap-2 mb-2">
                          <FeatherIcon
                            icon="check"
                            className="flex text-dark"
                            size="20"
                          />
                          <p className="fsize16 sm-fsize14 my-0 text-dark">
                            {e.field1}
                          </p>
                        </div>
                      );
                    })
                  : "no-data"}
              </div>

              <Button
                type="submit"
                className="dynamicStyle flex justify-center w-full rounded-lg lg:mt-6 md:mt-4 mt-4 b_button_primary px-6 py-2 theme-btn sm-fsize14 md-fsize14"
                id="1849980421"
              >
                {data ? data["1849980421"] : "Submit"}
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewsletterSection13;
