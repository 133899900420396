import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  pixabaySearch,
  verifyOtp,
  verifyOtpCera,
} from "../redux/cms/cms-actions";
import OtpInput from "react-otp-input";
import { createOtp, createOtpCera } from "../redux/cms/cms-actions";
import { Button } from "primereact/button";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// const otpDialog = () => {
export default (props) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpReceived, setIsOtpReceived] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  async function mount() {
    let token = localStorage.getItem("cmsToken");
    let session = localStorage.getItem("cms-session");
    if (isOtpSent && !isOtpReceived && !isAuthenticated && token && !session) {
      let data = await dispatch(createOtp(props));
      if (data) setIsOtpReceived(true);
    }

    if (isOtpSent && !isOtpReceived && !isAuthenticated && !token && session) {
      let payload = { session: session };
      let data = await dispatch(createOtpCera(payload));
      if (data) setIsOtpReceived(true);
    }

    if (otp.length === 6 && isOtpReceived && !isAuthenticated) {
      let payload = {
        otp: otp,
        token: props.token,
      };
      let data;
      if (!token && session) {
        let { data: verify } = await dispatch(verifyOtpCera(payload));
        console.log(verify, "verrify");
        data = verify.data;
      } else if (token && !session) {
        let { data: verify } = await dispatch(verifyOtp(payload));
        data = verify;
      }
      console.log(data);
      if (data.error && (data.error === 403 || data.error === 401)) {
        Swal.fire({
          title: "OTP!",
          text: `${data.messsage}`,
          icon: "error",
          confirmButtonColor: "#2bc370",
        });
        setOtp("");
      } else if (data.success) {
        setIsAuthenticated(true);
        setIsOtpReceived(false);
        if (!token && session) {
          let tempurl = window.location.href.split("/cera/?");
          window.location.href = tempurl[0];
        }
        if (token && !session) {
          localStorage.setItem("cmsToken", data.token);
          let tempurl = window.location.href.split("/");
          let url = window.location.href.replace(
            "/" + tempurl[tempurl.length - 1],
            ""
          );
          window.location.href = url;
        }
      } else {
        Swal.fire({
          title: "OTP!",
          text: "Authentication Failed",
          icon: "error",
          confirmButtonColor: "#2bc370",
        });
        setOtp("");
      }
    }
  }
  mount();
  return (
    <div className="px-8 py-6">
      <div className="flex items-center justify-center gap-2 my-4 c-otpInput c-inputsize">
        {!isOtpSent ? (
          <Button
            label="Send OTP"
            outlined
            className="py-2 px-4"
            onClick={() => setIsOtpSent(true)}
          ></Button>
        ) : (
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            separator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        )}
      </div>
    </div>
  );
};

// export default otpDialog;
