import React from 'react';
import { Box, Skeleton, SkeletonText } from '@chakra-ui/react';

const SkeletonCardImageandText3 = () => {
  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-8 gap-6">
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton height="160" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white" cla>
        <Skeleton height="160" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
      <Box padding="6" boxShadow="lg" bg="white" cla>
        <Skeleton height="160" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="2" />
      </Box>
    </div>
  );
};

export default SkeletonCardImageandText3;
