import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  FormControl,
  useColorMode,
  Image,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { addNewsletter } from '../../../redux/newsletter/newsletter-actions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const NewsletterSection11 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const initialValues = {
    email: '',
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, 'Email must be at least 5 characters')
      .max(50, 'Email must be at most 100 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
  });

  const handleOnSubmit = async values => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: 'Newsletter',
          text: `Subscribed to newsletter`,
          icon: 'success',
          timer: '2500',
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div
      className={
        colorMode === 'light'
          ? 'lg:flex md:flex items-center bg-secondary theme-bg'
          : 'lg:flex md:flex items-center theme-bg'
      }
    >
      <div
        id="1866920689"
        className="dynamicStyle2 h-400px sm-h250px lg:w-5/12 md:w-5/12 w-full lg:mb-0 md:mb-0 mb-4"
      >
        <Image
          src={data ? getImage(data['1866920689']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="h-400px sm-h250px w-full"
        />
      </div>
      <div className="lg:w-7/12 md:w-7/12 w-full lg:px-10 md:px-8 px-5 py-6">
        <div className="lg:w-10/12 w-full">
          <div className="lg:mb-12 md:mb-10 mb-6">
            <p
              id="9622545121"
              className="dynamicStyle fsize32 md-fsize28 sm-fsize20 font-semibold pb-3 clr-fff lg:leading-10 md:leading-8 leading-5 cust-textColor theme-textColor"
            >
              {data ? data['9622545121'] : 'Lorem Ipsum'}
            </p>
            <ReactQuill
              id="4974429242"
              theme="bubble"
              readOnly
              className="bubble-w dynamicStyle mb-2"
              value={data ? data['4974429242'] : 'Lorem Ipsum'}
            />
          </div>
          <form onSubmit={formik.handleSubmit} className="flex gap-4 mt-2">
            <div className="relative w-9/12">
              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
              >
                <Input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  type="text"
                  size="lg"
                  placeholder="Email"
                  className="w-full bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
                />
                <FeatherIcon
                  className="absolute top-3 right-6 text-gray cust-textColor"
                  size={20}
                  icon="search"
                />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage className="textwhite">
                    {formik.errors.email}
                  </FormErrorMessage>
                )}
              </FormControl>
            </div>

            <Button
              type="submit"
              size="lg"
              className='dynamicStyle w-3/12 b_button_primary px-6 py-2 theme-btn sm-fsize14 md-fsize14'
              id="6293316404"
            >
              {data ? data['6293316404'] : 'Submit'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection11;
