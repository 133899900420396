import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection10 = ({ pageName }) => {
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    const cleanp2 = handleScroll(elementRef2, setIsVisible2);

    return () => {
      cleanp1();
      cleanp2();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="theme-bg lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex gap-4 block">
          <div
            ref={elementRef}
            className={`fade-left lg:w-1/3 md:w-full w-full  lg:flex flex-col justify-center lg:px-4 lg:mb-0 md:mb-6 mb-6 ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <h1 id="9631188310" className="dynamicStyle hero_title">
              {data ? data['9631188310'] : 'Lorem ipsum generated'}
            </h1>
            <p
              id="6813605919"
              className="dynamicStyle card_description theme-textColor"
            >
              {data ? data['6813605919'] : 'Lorem ipsum generated'}
            </p>
          </div>
          <div
            id="4567987237"
            className="dynamicStyle2 lg:w-1/3 md:w-full w-full h-680px md-h-450 sm-h-250px"
          >
            <Image
              src={data ? getImage(data['4567987237']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              imageClassName="br-40px lg:w-full md:w-2/3 w-full mx-auto h-full md-h-450 object-cover"
            />
          </div>
          <div
            ref={elementRef2}
            className={`fade-right lg:w-1/3 md:w-full w-full  lg:flex flex-col justify-center lg:px-4 lg:mt-0 md:mt-4 mt-4 ${
              isVisible ? 'visibleright' : ''
            } `}
          >
            <ReactQuill
              id="7703235926"
              theme="bubble"
              readOnly
              className="bubble card_description dynamicStyle"
              value={data ? data['7703235926'] : 'Lorem Ipsum'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection10;
