import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection20 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <div className="herosection20-img relative" style={{ background: thisComponent.sectionColor }}>
      <div id="9468849523" className="w-full dynamicStyle2">
        <Image
          src={data ? getImage(data['9468849523']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          imageClassName="herosection20-img object-cover "
        />
      </div>
      <div className="absolute top-0 left-0 w-full flex items-center justify-center herosection20-img text-center">
        <div
          ref={elementRef}
          className={`fade-bottom lg:px-12 md:px-8 px-5 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <h3 id="0786727363" className="hero_title dynamicStyle">
            {data ? data['0786727363'] : 'EDUCATE'}
          </h3>
          <ReactQuill
            id="5789578239"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle lg:w-8/12 mx-auto card_description"
            value={data ? data['5789578239'] : 'Lorem Ipsum'}
          />
          <div className="lg:mt-8 md:mt-8 mt-6 flex items-center lg:gap-3 gap-2 justify-center">
            <Button
              id="3946890428"
              borderRadius={0}
              className="dynamicStyle theme-btn sm-fsize13 px-6 py-2"
              outlined
            >
              <span onClick={() => handleClick('3946890428')}>
                {data ? data['3946890428'] : 'Lorem ipsum'}
              </span>
            </Button>
            <Button
              id="9729220378"
              className="b_button_primary px-6 py-2 theme-btn dynamicStyle"
            >
              <span onClick={() => handleClick('9729220378')}>
                {data ? data['9729220378'] : 'Lorem ipsum'}
              </span>
              <FeatherIcon icon="arrow-right" size="18" className=" ml-1" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection20;
