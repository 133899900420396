import React, { useEffect, useState } from 'react';
import { Mail } from 'react-feather';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection33 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="relative h-800px md-h-700px sm-h550px" style={{ background: thisComponent.sectionColor }}>
      <div id="4434300980" className="dynamicStyle2">
        <Image
          src={data ? getImage(data['4434300980']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="about-section"
          imageClassName="h-800px md-h-700px sm-h550px w-full object-cover"
        />
      </div>
      <div className="h-800px md-h-700px sm-h550px absolute top-0 left-0 w-full flex items-center">
        <div className="lg:w-1/2 md:w-9/12 lg:pl-20 md:pl-12 md:px-0 px-5 lg:py-20 md:py-16 py-8">
          <h2
            id="2540904796"
            className="dynamicStyle fsize48 md-fsize36 sm-fsize24 lheight48 sm-lh-24 font-light"
          >
            {data ? data['2540904796'] : 'CONTACT'}
          </h2>
          <h2
            id="0345129371"
            className="dynamicStyle fsize64 md-fsize48 md-lh-48 sm-fsize32 sm-lh-46 font-semibold"
          >
            {data ? data['0345129371'] : 'NINTH AVENUE'}
          </h2>
          <ReactQuill
            id="3432837490"
            theme="bubble"
            readOnly
            className="bubble card_description dynamicStyle lg:mt-4 md:mt-3 mt-2 mb-3"
            value={data ? data['3432837490'] : 'Lorem Ipsum'}
          />

          <div
            className="bg-primary inline-block px-6 py-2 rounded-sm dynamicStyle"
            id="4563443883"
          >
            <span
              onClick={() => handleClick('4563443883')}
              className="flex items-center gap-1 clr-fff"
            >
              <Mail className="h-4" />
              {data ? data['4563443883'] : 'abc@abc.com'}
            </span>
          </div>
          <div className="grid grid-cols-2 gap-6 lg:mt-12 md:mt-10 mt-6">
            <div className="">
              <h2 className="card_title dynamicStyle" id="8901705131">
                {data ? data['8901705131'] : 'Title'}
              </h2>

              <ReactQuill
                id="1373062413"
                theme="bubble"
                readOnly
                className="bubble  card_description dynamicStyle"
                value={data ? data['1373062413'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="">
              <h2 className="card_title dynamicStyle" id="7205738697">
                {data ? data['7205738697'] : 'Title'}
              </h2>

              <ReactQuill
                id="8881344881"
                theme="bubble"
                readOnly
                className="bubble  card_description dynamicStyle"
                value={data ? data['8881344881'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="">
              <h2 className="card_title dynamicStyle" id="1197209835">
                {data ? data['1197209835'] : 'Title'}
              </h2>

              <ReactQuill
                id="2049600438"
                theme="bubble"
                readOnly
                className="bubble  card_description dynamicStyle"
                value={data ? data['2049600438'] : 'Lorem Ipsum'}
              />
            </div>
            <div className="">
              <h2 className="card_title dynamicStyle" id="8288609570">
                {data ? data['8288609570'] : 'Title'}
              </h2>

              <ReactQuill
                id="4137971000"
                theme="bubble"
                readOnly
                className="bubble  card_description dynamicStyle"
                value={data ? data['4137971000'] : 'Lorem Ipsum'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection33;
