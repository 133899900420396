import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { InputText } from "primereact/inputtext";

const NewsletterSection16 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="relative overflow-hidden theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:w-3/5 md:w-3/5 w-full mx-auto text-center">
          <h4 id="8125210393" className="dynamicStyle section_header">
            {data ? data["8125210393"] : "Lorem Ipsum"}
          </h4>
          <ReactQuill
            id="8951070390"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["8951070390"] : "Lorem Ipsum"}
          />
          <form onSubmit={formik.handleSubmit} className="flex gap-2 mt-6">
            <div className="w-9/12">
              <InputText
                name="email"
                value={formik.values.email}
                invalid={!!formik.errors.email && formik.touched.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type="text"
                size="lg"
                placeholder="Email"
                className="w-full theme-border sm-fsize14 theme-border theme-bg"
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-danger text-left fsize12 mt-2">
                  {formik.errors.email}
                </p>
              )}
            </div>

            <div className="w-3/12">
              <Button
                className="dynamicStyle w-full b_button_primary px-6 py-3 flex justify-center theme-btn sm-fsize14 md-fsize14"
                id="8541092098"
              >
                {data ? data["8541092098"] : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="lg:h-36 lg:w-36 md:h-36 md:w-36 rounded-full border-26px absolute absl-news16-top mob-hidden"></div>
      <div className="lg:h-36 lg:w-36 md:h-36 md:w-36 rounded-full border-26px absolute absl-news16-bot mob-hidden"></div>
    </div>
  );
};

export default NewsletterSection16;
