import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import Marquee from 'react-fast-marquee';
import { Card } from 'primereact/card';

const Clients1 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container ">
        <div
          ref={elementRef}
          className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
        >
          <div id="7724235249" className="dynamicStyle">
            <Marquee loop={0} autoFill={true}>
              {data && data['7724235249']
                ? data['7724235249'].map(e => {
                    return (
                      <Card className='prime_card mx-2'>
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img"
                          imageClassName="rounded-md object-contain lg:h-40 md:h-32 h-24"
                        />
                      </Card>
                    );
                  })
                : 'no-data'}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients1;
