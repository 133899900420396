import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { InputText } from "primereact/inputtext";

const NewsletterSection15 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="lg:flex md:flex items-center gap-8">
          <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-4">
            <p id="3625044128" className="dynamicStyle section_header">
              {data ? data["3625044128"] : "Lorem Ipsum"}
            </p>
            <ReactQuill
              id="7528980374"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle section_detail"
              value={data ? data["7528980374"] : "Lorem Ipsum"}
            />
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="lg:w-1/2 md:w-1/2 w-full"
          >
            <div className="mb-4">
              <InputText
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                invalid={!!formik.errors.email && formik.touched.email}
                type="text"
                placeholder="Email"
                className="w-full bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
              />
              {formik.touched.email && formik.errors.email && (
                <p className="text-danger fsize12 mt-2">
                  {formik.errors.email}
                </p>
              )}
            </div>
            <Button
              type="submit"
              size="lg"
              className="dynamicStyle w-full b_button_primary px-6 py-3 theme-btn sm-fsize14 md-fsize14 flex justify-center"
              id="5824522301"
            >
              {data ? data["5824522301"] : "Submit"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection15;
