const initialState = {
  sections: {},
  css: {},
  class: {},
  links: {},
  sectionNames: {},
  routeNames: [],
  allSections: {},
  allSectionsCss: {},
  allSectionsClass: {},
  files: {},
  forms: {},
  selectedTheme: "",
  isCMSOn: false,
  sectionColor: {},
};
const Cms_reducer = (state = initialState, action) => {
  // console.log(action, 'cms reducer');
  switch (action.type) {
    case "getcms":
      return { ...state, sections: action.payload };
    case "csscms":
      return { ...state, css: action.payload };

    case "setSelectedData":
      return {
        ...state,
        selectedData: action.payload,
        orignalSelectedData: action.payload,
      };
    case "updateStore":
      const updatepages = {
        ...state.sections,

        [action.payload.section]: {
          ...state.sections[action.payload.section],
          [action.payload.elementId]: action.payload.content,
        },
      };

      return {
        ...state,

        sections: updatepages,
      };
    case "updateCssStore":
      const updatecss = {
        ...state.css,

        [action.payload.section]: {
          ...state.css[action.payload.section],
          [action.payload.elementId]: action.payload.css,
        },
      };

      return {
        ...state,

        css: updatecss,
      };
    case "updateSections":
      const updateSections = {
        ...state.allSections,

        [action.payload.pageName]: {
          ...state.allSections[action.payload.pageName],
          [action.payload.elementId]: action.payload.content,
        },
      };

      return {
        ...state,

        allSections: updateSections,
      };

    case "updateSectionsCss":
      const updateSectionsCss = {
        ...state.allSectionsCss,
        [action.payload.pageName]: {
          ...state.allSectionsCss[action.payload.pageName],
          [action.payload.elementId]: action.payload.css,
        },
      };

      return {
        ...state,

        allSectionsCss: updateSectionsCss,
      };
    case "updateSectionsClass":
      const updateSectionsClass = {
        ...state.allSectionsClass,
        [action.payload.pageName]: {
          ...state.allSectionsClass[action.payload.pageName],
          [action.payload.elementId]: action.payload.class,
        },
      };

      return {
        ...state,

        allSectionsClass: updateSectionsClass,
      };
    case "updateImage":
      if (action.payload.delete) {
        const updateImage = {
          ...state.files,
        };
        delete updateImage[action.payload.elementId];
        return {
          ...state,

          files: updateImage,
        };
      } else {
        const updateImage = {
          ...state.files,

          [action.payload.elementId]: action.payload.file,
        };

        return {
          ...state,

          files: updateImage,
        };
      }
    case "getSections":
      return { ...state, sectionNames: action.payload };

    case "setRouteNames":
      let routeNames = [...new Set(Object.keys(action.payload))];
      return { ...state, routeNames: routeNames };

    case "setUpdateSectionData":
      return { ...state, sectionColor: action.payload };

    case "setUserData":
      return { ...state, userData: action.payload };
    case "setCmsActive":
      return { ...state, cmsActive: action.payload };
    case "setAllSections":
      return { ...state, allSections: action.payload };
    case "updateLinks":
      return { ...state, links: action.payload };
    case "setAllSectionsCss":
      return { ...state, allSectionsCss: action.payload };
    case "setAllSectionsClass":
      return { ...state, allSectionsClass: action.payload };
    case "setForms":
      return { ...state, forms: action.payload };
    case "setThemeDataNew":
      // console.log(action.payload);
      return { ...state, selectedTheme: action.payload };

    case "SET_IS_CMS_ON":
      // console.log(action.payload);
      return { ...state, isCMSOn: action.payload };
    default:
      return state;
  }
};

export default Cms_reducer;
