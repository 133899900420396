import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";


const FeatureCard63 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="">
          <p
            id="9370585103"
            className="dynamicStyle lg:w-1/2 md:w-1/2 section_header"
          >
            {data ? data['9370585103'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="7787449384"
            theme="bubble"
            readOnly
            className="dynamicStyle lg:w-1/2 md:w-3/4 border-left-1px pl-4 section_detail bubble"
            value={data ? data['7787449384'] : 'no-data'}
          />
        </div>
        <div
          id="9137902136"
          className="lg:mt-16 md:mt-16 mt-12 dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4"
        >
          {data && data['9137902136']
            ? data['9137902136'].map(e => {
                return (
                  <Card
                    key={e._id}
                    className="p-4 themeShadow theme-bg prime_card"
                  >
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL + e.field1}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt={e.field2}
                      imageClassName="w-14 h-14 rounded-full mx-auto mb-6 object-contain"
                    />
                    <h3 className="card_title text-center">{e.field2}</h3>
                    <ReactQuill
                      theme="bubble"
                      readOnly
                      className="card_description bubble2"
                      value={e.field3}
                    />
                    <Button className="py-2 bg-primary theme-btn sm-fsize14 clr-fff rounded-lg w-full mt-4 flex justify-center">
                      {e.field4}
                    </Button>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard63;
