
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VanillaTilt from 'vanilla-tilt';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard12 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  // const items = [
  //   {
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

  //     title: 'Creativity',

  //     description:
  //       'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
  //   },

  //   {
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

  //     title: 'Creativity 1',

  //     description:
  //       'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
  //   },

  //   {
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

  //     title: 'Creativity 2',

  //     description:
  //       'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
  //   },
  // ];
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.item-card2'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-14 md:py-16 py-8" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 id="9440199575" className="dynamicStyle section_header">
              {data ? data['9440199575'] : 'Welcome to my world'}
            </h2>
            <ReactQuill
              id="9148210071"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data['9148210071'] : 'Welcome to My World'}
            />
          </div>

          <div
            id="0478935476"
            className="dynamicStyle w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12"
          >
            {data && data['0478935476']
              ? data['0478935476'].map(e => {
                  return (
                    <Card
                      className="item-card2 prime_card_0 bg-fff text-center themeShadow"
                      data-tilt
                    >
                      <div className="lg:w-1/4 md:w-2/4 w-1/2 mx-auto">
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="lg:w-20 lg:h-20 w-16 h-16 mx-auto rounded-full lg:mb-3 md:mb-3 mb-2 object-cover"
                        />
                      </div>

                      <div className="">
                        <h3 className="card_title">{e.field2}</h3>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="bubble2 card_description"
                          value={e.field3}
                        />
                      </div>
                    </Card>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard12;
