import { Card, useColorMode } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const SubscriptionPlan3 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <div
      className={
        colorMode === 'light'
          ? 'bg-f4f5f6 lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
          : 'lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
      }
    >
      <div className="container mx-auto">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6">
          <h2 id="1557296121"
          className="dynamicStyle fsize36 md-fsize28 sm-fsize24 font-medium lg:mb-6 md:mb-6 mb-4 cust-textColor">
            {data ? data['1557296121'] : 'Pricing Plans'}
          </h2>
        </div>

        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 lg:mt-16 md:mt-12 mt-6">
          <Card className="bg-white rounded-xl p-8 transition3 hover-mt20px shad">
            <h3 className="br-bottom-c8ccd4 font-medium fsize30 sm-fsize22 pb-3 cust-textColor ">
              Hourly
            </h3>

            <p className="lg:mt-5 md:mt-5 mt-2 mb-2 font-medium cust-textColor ">
              <span className="clr-80db66 fsize54 pr-3 sm-fsize40">$99</span>/
              Per Hour
            </p>

            <ul className="m-0 p-0">
              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block ">
                  <FaTimes />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <Button
                className="mt-6 sm-fsize14 buttonAnimate bdr-2px b_button_primary px-6 py-2"
              >
                Start Now
              </Button>
            </ul>
          </Card>

          <Card className="bg-white rounded-xl p-8 transition3 hover-mt20px shad">
            <h3 className="br-bottom-c8ccd4 font-medium fsize30 sm-fsize22 pb-3 cust-textColor ">
              Project Basic
            </h3>

            <p className="lg:mt-5 md:mt-5 mt-2 mb-2 font-medium cust-textColor ">
              <span className="clr-80db66 fsize54 pr-3 sm-fsize40">$99</span>/
              Per Hour
            </p>

            <ul className="m-0 p-0">
              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block ">
                  <FaTimes />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <Button
                className="mt-6 sm-fsize14 buttonAnimate bdr-2px b_button_primary px-6 py-2"
              >
                Start Now
              </Button>
            </ul>
          </Card>

          <Card className="bg-white rounded-xl p-8 transition3 hover-mt20px shad">
            <h3 className="br-bottom-c8ccd4 font-medium fsize30 sm-fsize22 pb-3 cust-textColor ">
              Monthly
            </h3>

            <p className="lg:mt-5 md:mt-5 mt-2 mb-2 font-medium cust-textColor ">
              <span className="clr-80db66 fsize54 pr-3 sm-fsize40">$99</span>/
              Per Hour
            </p>

            <ul className="m-0 p-0">
              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block clr-80db66">
                  <FaCheck />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <li className="flex items-center mb-2">
                <span className="inline-block ">
                  <FaTimes />
                </span>

                <p className="mb-0 ml-5 cust-textColor sm-fsize14">
                  One time contract
                </p>
              </li>

              <Button
                className="mt-6 sm-fsize14 buttonAnimate bdr-2px b_button_primary px-6 py-2"
              >
                Start Now
              </Button>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan3;
