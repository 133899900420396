import React from 'react';
import { FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Footer2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  let project = useSelector(state => state.userDetails.project);
 
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }

  const isDarkMode = useSelector((state) => state.Loader.DarkMode);
  
  return (
    <section
    className={isDarkMode ? "bg_surface100" : "bg-secondary"}
    >
      <div className="container mx-auto lg:px-12 md:px-12 px-4 lg:pt-10 pt-8 lg:pb-6 pb-4">
        <div className="lg:flex md:block block justify-between lg:gap-8 md:gap-6">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <div className="w-250px tb-wfull sm-wfull">
              <a href="/">
                <Image
                  src={getSecondaryImage(brandProfile)}
                  alt="footer-logo"
                  className="w-10rem sm-mx-auto"
                />
              </a>
              <div className="my-4">
              <ReactQuill
                id="5039567522"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle lg:mb-8 md:mb-6 mb-4"
                value={data ? data['5039567522'] : 'Lorem Ipsum'}
              />
               
                <span
                  id="4004727062"
                  className="dynamicStyle theme-textColor clr-d9d9d9 fsize14 sm-center"
                >
                  {data ? data['4004727062'] : 'Lorem ipsum'}
                </span>
              </div>
              <div className="icons flex gap-8 my-4 sm-center">
                <a href="/">
                  <FaTwitter className="clr-d9d9d9" />
                </a>
                <a href="/">
                  <FaInstagram className="clr-d9d9d9" />
                </a>
                <a href="/">
                  <FaFacebook className="clr-d9d9d9" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-menu flex justify-between lg:w-1/2 md:w-full w-full lg:mt-0 md:mt-6 mt-6 lg:px-0 md:px-0 px-5">
            <div className="">
              <h3
                id="1312720152"
                className="dynamicStyle fsize16 font-semibold lh24px theme-textColor"
              >
                {data ? data['1312720152'] : 'Lorem ipsum'}
              </h3>
              <ul className="lg:my-4 md:my-4 my-2">
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Our product</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">New features</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Roadmap</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Beta testing</li>
              </ul>
            </div>
            <div className="">
              <h3
                id="0224840042"
                className="dynamicStyle fsize16 font-semibold theme-textColor"
              >
                {data ? data['0224840042'] : 'Lorem ipsum'}
              </h3>
              <ul className="lg:my-4 md:my-4 my-2">
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Retail</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">
                  Financial services
                </li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Media</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Gaming</li>
              </ul>
            </div>
            <div className="">
              <h3
                id="9588967159"
                className="dynamicStyle fsize16 font-semibold theme-textColor"
              >
                {data ? data['9588967159'] : 'Lorem ipsum'}
              </h3>
              <ul className="lg:my-4 md:my-4 my-2">
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Blog</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">How-to's</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Brand assets</li>
                <li className="fsize16 sm-fsize14 theme-textColor lh24px py-1">Events</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center text-white fsize15 sm-fsize14 theme-textColor">
          {project.poweredBy ? (
            <p>Powered by Brained</p>
          ) : (
            <p>Copyright &copy;2022 All Right reserved</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Footer2;
