import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard5 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <p
              className="font-medium text-primary fsize16 sm-fsize14 dynamicStyle cust-textColor"
              id="1059803787"
            >
              {data ? data['1059803787'] : 'Lorem Ipsum'}
            </p>
            <h2 className="section_header dynamicStyle" id="5182522828">
              {data ? data['5182522828'] : 'Lorem Ipsum'}
            </h2>
          </div>

          <div id="2474479136" className="dynamicStyle ">
            <div
              ref={elementRef}
              className={`fade-bottom lg:mt-16 md:mt-16 mt-12 prime_card_container grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 ${
                isVisible ? 'visiblebottom' : ''
              } `}
            >
              {data && data['2474479136']
                ? data['2474479136'].map(e => {
                    return (
                      <Card className="w-full flex flex-col items-center prime_card">
                        <div className="w-full flex justify-center">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt={e.field2}
                            imageClassName="object-contain card-img lg:mb-6 md:mb-4 mb-3 rounded-sm"
                          />
                        </div>

                        <h3 className="card_title text-center">{e.field2}</h3>
                        <ReactQuill
                          theme="bubble"
                          readOnly
                          className="card_description bubble2 lineClamp4 dynamicStyle"
                          value={e.field3}
                        />
                      </Card>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard5;
