import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from 'react-icons/fa';
import { useSelector } from 'react-redux/es/exports';

const Footer14 = ({pageName}) => {
    const data = useSelector(state => state.cms.allSections[pageName]);

    let brandProfile = useSelector(state => state.brandDetails.brandProfile);
    function getSecondaryImage(value) {
      return value
        ? value.alternateLogo
          ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
          : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
    }
    const isDarkMode = useSelector((state) => state.Loader.DarkMode);

  return (
    <div 
    // className="bg-secondary cust-bgColor text-white theme-bg"
    className={isDarkMode ? "bg_surface100" : "bg-secondary"}
    >
      <div className="lg:px-12 md:px-10 px-4 lg:pt-14 md:pt-12 pt-6 lg:pb-8 md:pb-8 pb-6">
     
        
          <div className="lg:w-3/4 md:w-full w-full">
          <div className="lg:flex md:flex flex w-full items-start lg:justify-start md:justify-start justify-center lg:gap-10 md:gap-10 gap-6">
              {' '}
              <p
                id="7547036499"
                className="dynamicStyle clr-fff fsize16 md-fsize14 sm-fsize12 cust-textColor"
              >
                {data ? data['7400024021'] : 'Home'} 
              </p>
              <p id="7400024021" className="dynamicStyle clr-fff fsize16 md-fsize14 sm-fsize12 cust-textColor">
              {data ? data['7400024021'] : 'Stories'} 
              </p>
              <p id="6169945269" className="dynamicStyle clr-fff fsize16 md-fsize14 sm-fsize12 cust-textColor">
              {data ? data['6169945269'] : 'Work'} 
              </p>
              <p id="0856279367" className="dynamicStyle clr-fff fsize16 md-fsize14 sm-fsize12 cust-textColor">
              {data ? data['0856279367'] : 'Price'} 
              </p>
          
          </div>
        </div>
      </div>
      <div className="w-full flex lg:flex-row md:flex-row flex-col items-center justify-between lg:gap-8 md:gap-8 gap-4  lg:px-12 md:px-10 px-4 lg:py-6 md:py-6 py-4">
        {' '}
        <div className="flex lg:justify-start md:justify-start justify-center">
            <a href="/">
              <Image
                src={getSecondaryImage(brandProfile)}
                alt="footer-logo"
                className="lg:h-20 md:h-20 h-16 lg:w-40 md:w-20 w-12 object-contain"
              />
            </a>
          </div>
   
        <div className="flex gap-6">
          <div className="cursor-pointer">
          <a href="/">
            <FaFacebookF className="clr-fff fsize18 md-fsize14 sm-fsize12" />
            </a>
          </div>
          <div className="cursor-pointer">
          <a href="/">
            <FaInstagram className="clr-fff fsize18 md-fsize14 sm-fsize12" />
            </a>
          </div>
          <div className="cursor-pointer">
          <a href="/">
            <FaTwitter className="clr-fff fsize18 md-fsize14 sm-fsize12" />
            </a>
          </div>
          <div className="cursor-pointer">
          <a href="/">
            <FaLinkedin className="clr-fff fsize18 md-fsize14 sm-fsize12" />
            </a>
          </div>
        </div>
        <div>
        <p id="3323325629"
          className="dynamicStyle lg:px-10 clr-fff cust-textColor fsize16 md-fsize14 sm-fsize12">
          {data ? data['3323325629'] : '&#169; 2021 Estatery.All rights reserved'} 
        </p>
        </div>
      </div>
    </div>
  )
}

export default Footer14
