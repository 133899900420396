import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Clients8 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container ">
        <div className="w-full flex justify-between items-center">
          <h4 id="9571479701" className="dynamicStyle section_header">
            {data ? data["9571479701"] : "Our Clients"}
          </h4>
          <Button
            id="2759568028"
            className="dynamicStyle b_button_primary px-6 py-2"
          >
            <span onClick={() => handleClick("2759568028")}>
              {data ? data["2759568028"] : "View All"}
            </span>
          </Button>
        </div>
        <div
          className="lg:mt-12 md:mt-12 mt-8 grid lg:grid-cols-6 md:grid-cols-4 grid-cols-1 gap-4 dynamicStyle"
          id="0626247804"
        >
          {data && data["0626247804"]
            ? data["0626247804"].map((e) => {
                return (
                  <Card className="prime_card">
                    <div className="">
                      <Image
                        src={e ? getImage(e.field1) : ""}
                        alt="cat_Img"
                        imageClassName="w-24 h-24 mx-auto object-contain"
                      />
                      <p className="card_title_0 mt-2 text-center">
                        {e.field2}
                      </p>
                    </div>
                  </Card>
                );
              })
            : "no-data"}
        </div>
      </div>
    </section>
  );
};

export default Clients8;
