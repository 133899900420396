import { Image } from "primereact/image";
import React from "react";
import { useSelector } from "react-redux/es/exports";

const AddBanner4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="flex justify-center items-center lg:mb-12 md:mb-12 mb-6">
          <div className="text-center">
            <p
              id="0316623467"
              className="dynamicStyle fsize16 sm-fsize14 font-medium text-primary"
            >
              {" "}
              {data ? data["0316623467"] : "Get Best Offers"}
            </p>
            <h2 id="5473550386" className="dynamicStyle section_header">
              {data ? data["5473550386"] : "Explore The Awesome"}
            </h2>
          </div>
        </div>
        <div id="9145811232" className="dynamicStyle2 w-full">
          <Image
            src={data ? getImage(data["9145811232"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="Ad-image"
            imageClassName="object-cover w-full h-450px sm-h-350px br-16px"
          />
        </div>
      </div>
    </div>
  );
};

export default AddBanner4;
