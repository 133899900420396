import React, { useEffect, useRef, useState } from 'react';
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const DownloadApplication1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
     <div className="lg:flex md:flex sm-reverse">
          <div className="w-55per sm-wfull lg:px-0 md:px-0 px-5 flex justify-center items-center ">
            <div ref={elementRef} className={`fade-left w-70 lg:py-0 md:py-0 py-4 ${isVisible ? 'visibleleft' : ''} `}>
              <h2
                className="fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor text-primary dynamicStyle"
                id="6425033537"
              >
                {data ? data["6425033537"] : "Lorem Ipsum"}
              </h2>
              <ReactQuill
                id="2904696009"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle lg:py-4 md:py-3 py-2"
                value={data ? data['2904696009'] : 'Lorem Ipsum'}
              />
              <div className="flex lg:pr-12 gap-4 pt-4">
                <div
                  className="w-full rounded-lg dynamicStyle2"
                  id="5212338132"
                >
                  <Image
                    className="w-full lg:h-16 md:h-16 h-16 rounded-lg"
                    src={data ? getImage(data["5212338132"]) : ""}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  />
                </div>
                <div
                  className="w-full rounded-lg dynamicStyle2"
                  id="3453040343"
                >
                  <Image
                    className="w-full lg:h-16 md:h-16 h-16 rounded-lg"
                    src={data ? getImage(data["3453040343"]) : ""}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-45per sm-wfull">
            <div id="6824785583" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data["6824785583"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-500px sm-h300px"
                alt="img"
              />
            </div>
          </div>
        </div>
    </section>
  );
};

export default DownloadApplication1;
