import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const DownloadSection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="lg:flex md:flex gap-10 prime_container">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-10">
          <h2
            id="8306110480"
            className="dynamicStyle hero_title text_surface800"
          >
            {data ? data["8306110480"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="4221517875"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle card_description"
            value={data ? data["4221517875"] : "Lorem Ipsum"}
          />

          <div className="lg:mt-8 md:mt-8 mt-6 flex gap-4 items-center">
            <Button
              px={8}
              className="dynamicStyle bdr-2px theme-btn b_button_primary px-6 py-2 sm-fsize14"
              id="7936009163"
            >
              <span onClick={() => handleClick("7936009163")}>
                {data ? data["7936009163"] : "Submit"}
              </span>
            </Button>
            <p
              id="8361098483"
              className="dynamicStyle fsize14 font-medium cursor-pointer text_surface800"
            >
              {data ? data["8361098483"] : "Lorem Ipsum"}
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <h2
            id="0622370334"
            className="dynamicStyle hero_title text_surface800"
          >
            {data ? data["0622370334"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="0743696124"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle card_description"
            value={data ? data["0743696124"] : "Lorem Ipsum"}
          />

          <div className="lg:mt-8 md:mt-8 mt-6 mb-6 flex gap-4 items-center">
            <Button
              px={8}
              className="dynamicStyle bdr-2px theme-btn b_button_primary px-6 py-2 sm-fsize14"
              id="0239507018"
            >
              <span onClick={() => handleClick("0239507018")}>
                {data ? data["0239507018"] : "Submit"}
              </span>
            </Button>
            <p
              id="2626330557"
              className="dynamicStyle fsize14 font-medium cursor-pointer text_surface800"
            >
              {data ? data["2626330557"] : "Lorem Ipsum"}
            </p>
          </div>

          <p
            id="7141222080"
            className="dynamicStyle mb-4 text_surface800 cursor-pointer"
          >
            {data ? data["7141222080"] : "Lorem Ipsum"}
          </p>
          <p
            id="8199201431"
            className="dynamicStyle mb-4 text_surface800 cursor-pointer"
          >
            {data ? data["8199201431"] : "Lorem Ipsum"}
          </p>
          <p
            id="3056310241"
            className="dynamicStyle mb-4 text_surface800 cursor-pointer"
          >
            {data ? data["3056310241"] : "Lorem Ipsum"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection3;
