import React, { Fragment, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {
  SetHeaderBackgroundColor,
  SetLogoWidth,
} from "../../../redux/headerSetting/header-action";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";

import { FaBars } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Image } from "primereact/image";
import { logout } from "../../../redux/auth/auth-action";
import { Avatar } from "primereact/avatar";

import { updateBrandProfile } from "../../../redux/brandDetails/bd-actions";
import headerSetting from "../../../redux/headerSetting/header-reducer";
import { Card } from "primereact/card";
const Header1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let menuData = useSelector((state) => state.menuState.menu);
  let userData = useSelector((state) => state.AuthDetails.user);
  let headerBackgroundColor = useSelector(
    (state) => state.headerSetting.headerBackgroundColor
  );
  let headerLogoWidth = useSelector(
    (state) => state.headerSetting.headerLogoWidth
  );
  console.log(headerBackgroundColor, "checkm");

  const menuLeft = useRef(null);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  const [navbar, setnavbar] = useState(0);

  const setclick = () => setnavbar(false);

  const customHeader = (
    <div className="flex gap-2 align-items-center">
      <Avatar image={getPrimaryImage(brandProfile)} shape="circle" />
      {/* <span className="font-bold">Amy Elsner</span> */}
    </div>
  );

  const btnRef = React.useRef();

  const handleColor = (event) => {
    dispatch(SetHeaderBackgroundColor(event.target.value));
  };

  const updateHeaderColor = () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
      headerColor: headerBackgroundColor,
    };
    console.log(payload, "paloddd");
    dispatch(updateBrandProfile(payload));
  };

  // const sachin = e => {
  //   dispatch(SetLogoWidth(e.target.value));
  // };

  const [logoWidth, setLogoWidth] = useState([2]);

  const handleLogoWidthChange = (newWidth) => {
    console.log(newWidth[0], "newWidth");
    setLogoWidth(newWidth[0]);
  };

  const subMenuOption = (subMenu) => {
    let sm = subMenu.map((sm) => ({
      label: sm.menuName,
      command: () => handleSubMenu(sm),
    }));
    console.log(sm, "resss");
    return sm;
  };
  const handleSubMenu = (event) => {
    navigate(`/${event.pageData[0].page}`);
  };
  const getImage = (image) => {
    if (image.includes("data:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };
  if (window.location.pathname !== "/home") return null; 
  return (
    <Card
      className="prime_navbar neu-header"
      // style={{ background: `${headerBackgroundColor}` }}
    >
      <div className="flex items-center justify-between ">
        <a href="/">
          <Image
            src={getPrimaryImage(brandProfile)}
            alt="logo"
            // style={{ width: `${logoWidth}rem` }}
            imageClassName="object-contain w-24 h-8"
          />
        </a>

        <div className="items-center justify-center hidden gap-4 lg:flex md:hidden lg:gap-8 md:gap-4">
          {menuData
            ? menuData.map((e, i) => {
                return (
                  <div key={i}>
                    {e.menutype === "page" && e.subMenu.length < 1 ? (
                      <NavLink
                        to={`/${e.pageData[0].page}`}
                        onClick={() => setclick(e)}
                        className="font-medium cursor-pointer "
                      >
                        <p className="md-fsize14">{e.menuName}</p>
                      </NavLink>
                    ) : (
                      ""
                    )}

                    {e.menutype === "url" && e.subMenu.length < 1 ? (
                      <NavLink
                        // to={`/${e.pageData[0].page}`}
                        onClick={() => setclick(e)}
                      >
                        <p
                          className={
                            navbar === e
                              ? "menuHover font-semibold cursor-pointer"
                              : "cust-textColor font-medium cursor-pointer "
                          }
                        >
                          {e.menuName}sssss
                        </p>
                      </NavLink>
                    ) : (
                      ""
                    )}

                    {e.menutype === "page" && e.subMenu.length > 1 ? (
                      <>
                        {" "}
                        <Menu
                          model={subMenuOption(e.subMenu)}
                          popup
                          ref={menuLeft}
                          id="popup_menu_left"
                        />
                        <Button
                          className="pad-0 font-medium"
                          onClick={(event) => menuLeft.current.toggle(event)}
                          aria-controls="popup_menu_left"
                          aria-haspopup
                        >
                          {" "}
                          {e.menuName}
                          <ChevronDownIcon />
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            : ""}
        </div>
        <div className="block lg:hidden md:block">
          <Button ref={btnRef} onClick={() => setMobileSidebar(true)}>
            <FaBars />
          </Button>
          <Sidebar
            position="right"
            visible={mobileSidebar}
            onHide={() => setMobileSidebar(false)}
            header={customHeader}
          >
            <div className="relative h-full ">
              <div className="mt-2 lg:mt-4">
                {menuData
                  ? menuData.map((e, i) => {
                      return (
                        <div key={i}>
                          {e.menutype === "page" && e.subMenu.length < 1 ? (
                            <NavLink
                              to={`/${e.pageData[0].page}`}
                              onClick={() => setclick(e)}
                              className="font-medium cursor-pointer"
                            >
                              <p className="mb-4">{e.menuName}</p>
                            </NavLink>
                          ) : (
                            ""
                          )}

                          {e.menutype === "page" && e.subMenu.length > 1 ? (
                            <>
                              <h2 className="font-semibold fsize16">
                                {" "}
                                {e.menuName}
                              </h2>

                              <Menu
                                model={subMenuOption(e.subMenu)}
                                className="borderleft"
                              />

                              {/* <Button
                                  icon="pi pi-align-left"
                                  className="mr-2"
                                  onClick={event =>
                                    menuLeft.current.toggle(event)
                                  }
                              
                                 
                                >
                                  {' '}
                                  {e.menuName}
                                  <ChevronDownIcon />
                                </Button> */}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>

            {/* {userData && userData._id && userData.token ? (
                    <button
                      onClick={() => handleLogout()}
                      className="flex items-center"
                    >
                      {' '}
                      <FeatherIcon className="mr-2" icon="log-out" size={18} />
                      Logout
                    </button>
                  ) : (
                    <Button
                      px="8"
                      colorScheme="primary"
                      onClick={() => {
                        navigate('/login');
                        onClose();
                      }}
                      className="px-8 py-2 mt-4 font-semibold text-white bg-secondary"
                    >
                      Sign In
                    </Button>
                  )} */}

            {/* <button
                    onClick={() => navigate('/login')}
                    className="px-8 py-2 mt-4 font-semibold text-white bg-secondary"
                  >
                    Sign In
                  </button> */}
          </Sidebar>
        </div>
        <div className="hidden gap-2 lg:flex md:hidden">
          {userData && userData._id && userData.token ? (
            <a href="/user-profile" className="flex">
              <div className="flex items-center gap-2 mob-hidden">
                <Avatar
                  name="profile"
                  size="sm"
                  src={
                    userData.userImage
                      ? getImage(userData.userImage)
                      : "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  }
                />
                <h2 className="font-semibold">{userData.firstName}</h2>
              </div>
            </a>
          ) : (
            <div className="flex gap-2">
              <Button
                outlined
                onClick={() => {
                  navigate("/login");
                }}
                className=" font-semibold lg:block md:block mob-hidden px-6 py-2"
              >
                Login
              </Button>
              <Button
                onClick={() => {
                  navigate("/register");
                }}
                className="lg:block md:block mob-hidden b_button_primary px-6 py-2"
              >
                Register
              </Button>
            </div>
          )}
        </div>

        {/* <button
            onClick={() => navigate('/login')}
            className="hidden px-6 py-4 font-semibold text-white bg-secondary lg:px-8 md:px-8 lg:py-2 md:py-2 lg:block md:block"
          >
            Sign In
          </button> */}
      </div>
    </Card>
  );
};

export default Header1;
