import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux/es/exports";
import FeatherIcon from "feather-icons-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard40 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="px-5 py-12 lg:px-20 md:px-8 lg:py-20 md:py-16" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="header_align">
            <h2 className="section_header dynamicStyle" id="1136616886">
              {data ? data["1136616886"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="8670326645"
              theme="bubble"
              readOnly
              className="bubble2 dynamicStyle section_detail"
              value={data ? data["8670326645"] : "Welcome to My World"}
            />
          </div>

          <div
            className="mt-12 lg:mt-16 md:mt-16 prime_card_container dynamicStyle"
            id="3277830282"
          >
            <Swiper
              spaceBetween={15}
              slidesPerView={3}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper"
              autoplay={{
                delay: "1000",
              }}
              loop
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                991: {
                  slidesPerView: 2.8,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 2.4,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {data && data["3277830282"]
                ? data["3277830282"].map((e, index) => {
                    return (
                      <SwiperSlide>
                        <Card
                          className="relative overflow-hidden prime_card card lg:mx-2 md:mx-2"
                          key={e._id}
                        >
                          <div className="">
                            <Image
                              src={process.env.REACT_APP_STORAGE_URL + e.field1}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              imageClassName="w-10 h-10 rounded-lg lg:w-12 lg:h-12 object-contain"
                              alt={e.field2}
                            />
                          </div>
                          <div className="mt-6">
                            <h4 className="card_title lineClamp2">
                              {e.field2}
                            </h4>
                            <ReactQuill
                              theme="bubble"
                              readOnly
                              className="bubble card_description"
                              value={e.field3}
                            />
                          </div>
                          <div className="flex justify-end mt-3">
                            <div className="flex items-center gap-1">
                              <p className="fsize14 text_surface500">
                                {e.field4}
                              </p>
                              <FeatherIcon
                                icon="arrow-right"
                                size="16"
                                className="text_surface500"
                              />
                            </div>
                          </div>
                          <div className="absolute top-0 right-0">
                            <div className="px-4 py-1 bg-primary">
                              <p className="fsize12 clr-fff cust-textColor">
                                {e.field5}
                              </p>
                            </div>
                          </div>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : "no-card"}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard40;
