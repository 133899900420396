import React, { useEffect, useRef, useState } from "react";
import { Image } from "@chakra-ui/react";
import { Button } from 'primereact/button';
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "../../components/fadeeffect/FadeEffect";
import {
  getAllCourses,
  selectedCourse,
} from "../../../../redux/elearningProduct/products-action";
import { useDispatch } from "react-redux";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const EcomProductSection4 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const getAllCourse = useSelector((state) => state.elearningProduct.Course);
  console.log(getAllCourse, "getAllCourse");
  const selectedCourseData = useSelector(
    (state) => state.elearningProduct.selectedCourse
  );
  console.log(selectedCourseData, "selectedCourseData");
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);
  const navigate = useNavigate();
  const link = "https://example.com";
  let selectedStyle = useSelector(
    (state) => state.brandDetails.brandProfile.businessThemeVariation
  );
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const handleProduct = (course) => {
    navigate("/elearning-productdetails");
    dispatch(selectedCourse(course));
  };

  return (
    <div className="" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8 container mx-auto">
        <div className="w-60 sm-wfull mx-auto text-center lg:mb-8 md:mb-8 mb-4">
          <h2
            id="5439610828"
            className="dynamicStyle font-semibold text-primary cust-textColor fsize34 md-fsize24 sm-fsize20  relative inline-block"
          >
            {data ? data["5439610828"] : "Lorem Ipsum"}
          </h2>
          <ReactQuill
            id="8249626393"
            theme="bubble"
            readOnly
            className="card_description bubble dynamicStyle mt-2"
            value={data ? data["8249626393"] : "Lorem Ipsum"}
          />
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? "visiblebottom" : ""} `}
        >
          <Swiper
            spaceBetween={15}
            slidesPerView={3}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 2.8,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2.4,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {getAllCourse?.result.map((course, index) => {
              return (
                <SwiperSlide>
                  <Card
                    className="relative prime_card_0 bg_none card lg:mx-2 md:mx-2"
                    key={index}
                  >
                    <div
                      className="rounded-lg cursor-pointer theme-radius themeShadow"
                      onClick={() => handleProduct(course)}
                    >
                      <div className="relative image-product overflow-hidden rounded-t theme-productDesign">
                        <Image
                          className="img-h-220 w-full opct themeimg-radius"
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                          alt="img"
                        />
                        <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                          <div className="">
                            <p className="fsize14 textColor-light">4.5/5</p>
                          </div>
                          <div>
                            <FeatherIcon
                              className="filled-blk "
                              icon="star"
                              size={18}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-4">
                        <div className="flex justify-between  text-b2b2b2">
                          <p className="fsize14 font-medium">12 Lessons</p>
                          <p className=" fsize14  font-medium">
                            {course.totalhours} hours
                          </p>
                        </div>
                        <div className="py-3">
                          <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1">
                            {course["course name"]}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                            ₹ {course["actual rate"]}/-
                          </p>
                          <div className="flex gap-4">
                            <FeatherIcon
                              className=" cust-textColor"
                              icon="heart"
                              size={20}
                            />
                            <FeatherIcon
                              className="cust-textColor "
                              icon="shopping-cart"
                              size={20}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="flex justify-center pt-12">
          <Button
            className="dynamicStyle theme-btn b_button_primary px-6 py-2 bdr-2px"
            id="1415956570"
          >
            <span onClick={() => handleClick("1415956570")}>
              {data ? data["1415956570"] : "Lorem Ipsum"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EcomProductSection4;
