import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard34 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex block w-full">
            <div className="lg:w-6/12 md:w-6/12">
              <p
                id="6107312666"
                className="fsize16 md-fsize16 sm-fsize14 font-medium dynamicStyle textColor-light"
              >
                {data ? data['6107312666'] : 'Educate'}
              </p>
              <h4 className="section_header dynamicStyle" id="6523519167">
                {data ? data['6523519167'] : 'Educate'}
              </h4>
              <ReactQuill
                id="6862677492"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle section_detail"
                value={data ? data['6862677492'] : 'Welcome to My World'}
              />
              <div
                id="5318899910"
                className="lg:grid-cols-3 md:grid-cols-2 grid-cols-2 grid gap-4 lg:mt-6 mt-4 dynamicStyle"
              >
                {data && data['5318899910']
                  ? data['5318899910'].map((e, index) => {
                      return (
                        <div className="">
                          <h4 className="fsize24 md-fsize20 sm-fsize18 mt-2 font-semibold text_surface800">
                            {e.field1}
                          </h4>
                          <p className="fsize16 md-fsize14 sm-fsize13 text-gray font-medium mt-1 text_surface500">
                            {e.field2}
                          </p>
                        </div>
                      );
                    })
                  : 'no-card'}
              </div>
              <div className="flex items-center gap-4 mt-8">
                <Button
                  id="7515261107"
                  className="dynamicStyle b_button_primary px-6 py-2 sm-fsize13 "
                >
                  {' '}
                  <span onClick={() => handleClick('7515261107')}>
                    {data ? data['7515261107'] : 'Lorem ipsum'}
                  </span>
                </Button>
                <Button
                  id="1564273441"
                  className="fsize14 text-primary px-6 py-2"
                  outlined
                >
                  {' '}
                  <span onClick={() => handleClick('1564273441')}>
                    {data ? data['1564273441'] : 'Lorem ipsum'}
                  </span>
                </Button>
              </div>
            </div>
            <div className="lg:w-6/12 md:w-6/12 lg:ml-8 md:ml-6 lg:mt-0 md:mt-0 mt-8">
              <div
                className="lg:grid-cols-3 md:grid-cols-3 grid-cols-2 grid gap-4 dynamicStyle"
                id="3597321164"
              >
                {data && data['3597321164']
                  ? data['3597321164'].map((e, index) => {
                      return (
                        <div className="h-24 w-full">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            imageClassName="h-24 w-full object-cover"
                            alt="img"
                          />
                        </div>
                      );
                    })
                  : 'no-card'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard34;
