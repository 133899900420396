import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const FAQSection4 = ({ pageName }) => {
          //section color start
          const [fileName, setFileName] = useState("");
          const [thisComponent, setThisComponent] = useState({});
          const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
          const selectedSection = useSelector((state) => state.cms.sectionNames);
          //sectioncolor end
          //sectioncolor start
          useEffect(() => {
            const name = new URL(import.meta.url);
            const filename = name.pathname.split("/").pop();
            console.log(filename);
            const componentName = filename.split(".")[0];
            setFileName(componentName);
        
            for (let i = 0; i < selectedSection[pageName].length; i++) {
              if (
                selectedSection[pageName][i].path.includes(componentName.split(".")[0])
              ) {
                setThisComponent(selectedSection[pageName][i]);
              }
            }
          }, [selectedSection]);
          //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="">
          <p
            id="9324618255"
            className="dynamicStyle text-primary section_detail_0"
          >
            {data ? data['9324618255'] : 'Start With The Basics'}
          </p>
          <p id="7637493496" className="dynamicStyle section_header">
            {data ? data['7637493496'] : 'Frequently Asked Questions'}
          </p>
        </div>

        <div className="lg:mt-12 md:mt-12 mt-10">
          <TabView>
            <TabPanel
              header={
                <h2
                  id="9470494581"
                  className="card_title_0 dynamicStyle text-center"
                >
                  {data
                    ? data['9470494581']
                    : 'Questions Thats Comes in your Mind'}
                </h2>
              }
            >
              <div className="dynamicStyle" id="8058383966">
                {data && data['8058383966']
                  ? data['8058383966'].map(e => {
                      return (
                        <div className="lg:mb-5 md:mb-4 mb-3">
                          <h4 className="card_title"> {e.field1}</h4>
                          <ReactQuill
                            id="7461373914"
                            theme="bubble"
                            readOnly
                            className="bubble card_description"
                            value={e.field2}
                          />
                        </div>
                      );
                    })
                  : 'no-data'}
              </div>
            </TabPanel>
            <TabPanel
              header={
                <h2
                  id="3437786096"
                  className="card_title_0 dynamicStyle text-center"
                >
                  {data
                    ? data['3437786096']
                    : 'Questions Thats Comes in your Mind'}
                </h2>
              }
            >
              <div className="dynamicStyle" id="5953142856">
                {data && data['5953142856']
                  ? data['5953142856'].map(e => {
                      return (
                        <div className="lg:mb-5 md:mb-4 mb-3">
                          <h4 className="card_title"> {e.field1}</h4>
                          <ReactQuill
                            id="7461373914"
                            theme="bubble"
                            readOnly
                            className="bubble card_description"
                            value={e.field2}
                          />
                        </div>
                      );
                    })
                  : 'no-data'}
              </div>
            </TabPanel>
            <TabPanel
              header={
                <h2
                  id="4570298388"
                  className="card_title_0 dynamicStyle text-center"
                >
                  {data
                    ? data['4570298388']
                    : 'Questions Thats Comes in your Mind'}
                </h2>
              }
            >
              <div className="dynamicStyle" id="3544756391">
                {data && data['3544756391']
                  ? data['3544756391'].map(e => {
                      return (
                        <div className="lg:mb-5 md:mb-4 mb-3">
                          <h4 className="card_title"> {e.field1}</h4>
                          <ReactQuill
                            id="7461373914"
                            theme="bubble"
                            readOnly
                            className="bubble card_description"
                            value={e.field2}
                          />
                        </div>
                      );
                    })
                  : 'no-data'}
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default FAQSection4;
