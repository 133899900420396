import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { createContact } from '../../../redux/marcom/marcom-action';
import 'sweetalert2/src/sweetalert2.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Contactform5 = ({ pageName }) => {
  const dispatch = useDispatch();

  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  const data = useSelector(state => state.cms.allSections[pageName]);
  const handleClick = id => {
    const isHttpsLink = ``;
    CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  const initialValues = {
    name: '',
    email: '',
    description: '',
  };

  const validationRules = Yup.object({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .max(25, 'Name must be at most 25 characters')
      .required('Name is required')
      .matches(/^[a-zA-Z ]*$/, 'Only alphabets is allowed'),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, 'Email must be at most 50 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    // mobile: Yup.string()
    // .min(10, 'Mobile number must be 10 digits')
    // .max(10, 'Mobile number must be 10 digits')
    // .required('Mobile number is required'),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),

    description: Yup.string()
      .trim()
      .min(3, 'Message must be at least 3 characters')
      .max(100, 'Message must be at most 100 characters')
      .required('Message is required'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      //  mobile: values.mobile,
      description: values.description,
      //  countryCode: values.countryCode,
    };
    try {
      let data = await dispatch(createContact(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: `Will get back to you shortly`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Will get back to you shortly`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error('Something went wrong!');
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h3
            id="0285449509"
            className="dynamicStyle theme-textColor section_header"
          >
            {data ? data['0285449509'] : 'GET IN TOUCH'}
          </h3>
          <hr class="hr-layout2"></hr>
          <ReactQuill
            id="1522815469"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['1522815469'] : 'Lorem Ipsum'}
          />
        </div>

        <div className="lg:w-1/2 md:w-3/4 w-full mx-auto flex flex-col justify-center lg:my-10 md:my-10 my-6">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full lg:flex md:flex lg:gap-6 md:gap-4 gap-4 mb-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                <InputText
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  invalid={!!formik.errors.name && formik.touched.name}
                  placeholder="Your Name*"
                  className="form-control theme-border-0 fsize16 md-fsize14 sm-fsize14 bg_surface50 themeShadow"
                />
                {formik.touched.name && formik.errors.name && (
                  <p className="text-danger fsize12 mt-2">
                    {formik.errors.name}
                  </p>
                )}
              </div>

              <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-4">
                <InputText
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  invalid={!!formik.errors.email && formik.touched.email}
                  placeholder="Your Email*"
                  className="form-control theme-border-0 fsize16 md-fsize14 sm-fsize14 bg_surface50 themeShadow"
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-danger fsize12 mt-2">
                    {formik.errors.email}
                  </p>
                )}
              </div>
            </div>

            <div className="mb-6">
              <InputTextarea
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                invalid={
                  !!formik.errors.description && formik.touched.description
                }
                placeholder="Your Message*"
                className="form-control  fsize16 md-fsize14 sm-fsize14 bg_surface50 themeShadow"
              />
              {formik.touched.description && formik.errors.description && (
                <p className="text-danger fsize12 mt-2">
                  {formik.errors.description}
                </p>
              )}
            </div>

            <Button
              type="submit"
              className="theme-btn2 w-full b_button_primary py-3 font-semibold flex justify-center sm-fsize14"
              id="6686146883"
            >
              <span
                className="flex items-center"
                onClick={() => handleClick('6686146883')}
              >
                {data ? data['6686146883'] : 'Submit'}
              </span>
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contactform5;
