import { Image } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React, { useState, useRef, useEffect } from 'react';
import GoogleMap from 'google-maps-react-markers'; // Import the GoogleMap component
import FeatherIcon from 'feather-icons-react';

const MapSection1 = () => {
  const coordinates = [
    { lat: 45.4046987, lng: 12.2472504, name: 'Place 1' },
    { lat: 46.2046987, lng: 13.2472504, name: 'Place 2' },
    // Add more coordinates as needed
  ];
  const mapOptions = {
    // Define your map options here
  };
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const [markers, setMarkers] = useState([]);
  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
    // Create markers
    const newMarkers = coordinates.map(({ lat, lng, name }) => {
      const marker = new maps.Marker({
        position: { lat, lng },
        map,
        title: name,
      });
      marker.addListener('click', () =>
        onMarkerClick({ markerId: name, lat, lng })
      );
      return marker;
    });
    setMarkers(newMarkers);
  };

  const onMarkerClick = ({ markerId, lat, lng }) => {
    console.log('This is ->', markerId);
    // inside the map instance you can call any google maps method
    mapRef.current.setCenter({ lat, lng });
  };

  useEffect(() => {
    // Clean up markers on unmount
    return () => {
      markers.forEach(marker => marker.setMap(null));
    };
  }, [markers]);

  return (
    <div className="lg:flex md:flex w-full">
      <div className="lg:w-3/12 md:w-6/12 py-4 px-5">
        <h4 className="fsize26 md-fsize24 sm-fsize22 font-semibold text-dark textColor-light cust-textColor">
          Search Result
        </h4>
        <p className="fsize14 text-gray textColor-light mt-1">
          Lorem ipsum is a placeholder text commonly used to demonstrate.
        </p>

        <div className="mt-6">
          <div className="rounded-lg border overflow-hidden">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-full h-32"
            />
            <div className="p-3">
              <h6 className="fsize18 font-semibold text-dark textColor-light cust-textColor">
                Bright Position
              </h6>
              <p className="fsize14 text-gray textColor-light mt-1 lineClamp1">
                Lorem ipsum is
              </p>
              <div className="flex items-start gap-1 mt-1">
                <FeatherIcon
                  icon="map-pin"
                  className="textColor-light text-dark flex mt-1"
                  size={14}
                />
                <p className="fsize12 text-gray textColor-light">
                  Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                </p>
              </div>
              <div className="flex items-center gap-2 mt-1">
                <FeatherIcon icon="star" className="text-dark flex textColor-light" size={14} />
                <p className="fsize12 text-gray textColor-light">
                  4.3{' '}
                  <span className="font-semibold text-dark textColor-light">45 Reviews</span>
                </p>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <Button
                  className="fsize12 mini-btn b_button_primary px-6 py-2"
                  borderRadius={50}
                >
                  Direction
                </Button>
                <div className="border-dark rounded-full p-1">
                  <FeatherIcon
                    icon="share-2"
                    size={16}
                    className="cust-textColor"
                  />
                </div>
                <div className="border-dark rounded-full p-1">
                  <FeatherIcon
                    icon="heart"
                    size={16}
                    className="cust-textColor"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 overflow-auto pb-5 h-400px">
            <div className="rounded-lg border overflow-hidden lg:flex items-center mb-4">
              <div className="lg:w-4/12 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-36 flex"
                />
              </div>
              <div className="lg:px-3 md:px-2 px-2 lg:py-0 md:py-3 py-3 lg:w-8/12 w-full">
                <h6 className="fsize18 font-semibold text-dark textColor-light cust-textColor">
                  Bright Position
                </h6>
                <p className="fsize14 text-gray textColor-light mt-1">Lorem ipsum is</p>
                <div className="flex items-start gap-2 mt-1">
                  <FeatherIcon
                    icon="map-pin"
                    className="textColor-light text-dark flex mt-1"
                    size={14}
                  />
                  <p className="fsize12 text-gray textColor-light">
                    Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                  </p>
                </div>
                <div className="flex items-center gap-2 mt-1">
                  <FeatherIcon
                    icon="star"
                    className="text-dark flex textColor-light"
                    size={14}
                  />
                  <p className="fsize12 text-gray textColor-light">
                    4.3{' '}
                    <span className="font-semibold text-dark textColor-light">45 Reviews</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded-lg border overflow-hidden lg:flex items-center mb-4">
              <div className="lg:w-4/12 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-36 flex"
                />
              </div>
              <div className="lg:px-3 md:px-2 px-2 lg:py-0 md:py-3 py-3 lg:w-8/12 w-full">
                <h6 className="fsize18 font-semibold text-dark textColor-light cust-textColor">
                  Bright Position
                </h6>
                <p className="fsize14 text-gray textColor-light mt-1">Lorem ipsum is</p>
                <div className="flex items-start gap-2 mt-1">
                  <FeatherIcon
                    icon="map-pin"
                    className="textColor-light text-dark flex mt-1"
                    size={14}
                  />
                  <p className="fsize12 text-gray textColor-light">
                    Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                  </p>
                </div>
                <div className="flex items-center gap-2 mt-1">
                  <FeatherIcon
                    icon="star"
                    className="text-dark flex textColor-light"
                    size={14}
                  />
                  <p className="fsize12 text-gray textColor-light">
                    4.3{' '}
                    <span className="font-semibold text-dark textColor-light">45 Reviews</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded-lg border overflow-hidden lg:flex items-center mb-4">
              <div className="lg:w-4/12 w-full">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="w-full h-36 flex"
                />
              </div>
              <div className="lg:px-3 md:px-2 px-2 lg:py-0 md:py-3 py-3 lg:w-8/12 w-full">
                <h6 className="fsize18 font-semibold text-dark textColor-light cust-textColor">
                  Bright Position
                </h6>
                <p className="fsize14 text-gray textColor-light mt-1">Lorem ipsum is</p>
                <div className="flex items-start gap-2 mt-1">
                  <FeatherIcon
                    icon="map-pin"
                    className="textColor-light text-dark flex mt-1"
                    size={14}
                  />
                  <p className="fsize12 text-gray textColor-light">
                    Lorem ipsum to demonstrate Lorem ipsum to demonstrate.
                  </p>
                </div>
                <div className="flex items-center gap-2 mt-1">
                  <FeatherIcon
                    icon="star"
                    className="text-dark flex textColor-light"
                    size={14}
                  />
                  <p className="fsize12 text-gray textColor-light">
                    4.3{' '}
                    <span className="font-semibold text-dark textColor-light">45 Reviews</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-9/12 md:w-6/12">
        <>
          <GoogleMap
            className="h-100vh sm-h-350px"
            apiKey="AIzaSyBaGHp3hW_TgoyCcbkuUogkIQqzolYdpmc"
            defaultCenter={{ lat: 45.4046987, lng: 12.2472504 }}
            defaultZoom={5}
            options={mapOptions}
            mapMinHeight="100vh"
            onGoogleApiLoaded={onGoogleApiLoaded}
            onChange={map => console.log('Map moved', map)}
          />
        </>
      </div>
    </div>
  );
};

export default MapSection1;
