import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard43 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 py-12 px-5  md:px-8 lg:py-20 md:py-16" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="">
          <h2 id="4022065724" className="dynamicStyle section_header">
            {data ? data['4022065724'] : 'Lorem ipsum'}
          </h2>
          <ReactQuill
            id="8941581476"
            theme="bubble"
            readOnly
            className="bubble dynamicStyle section_detail"
            value={data ? data['8941581476'] : 'Welcome to My World'}
          />
        </div>
        <div
          id="3204119794"
          className="dynamicStyle grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-16 md:mt-16 mt-12"
        >
          {data && data['3204119794']
            ? data['3204119794'].map((e, index) => {
                return (
                  <Card className="prime_card_0 rounded-2xl overflow-hidden w-full lg:h-96 h-80 relative theme-border">
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL + e.field1}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt={e.field2}
                      imageClassName="w-full lg:h-96 h-80 object-cover"
                    />
                    <p className="card_title text-center absolute left-0 bottom-0 w-full theme-textColor theme-bg themeShadow">
                      {e.field2}
                    </p>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard43;
