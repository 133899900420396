import React, { useEffect, useState } from 'react';
import { Badge } from "@chakra-ui/react";
import { Image } from "primereact/image";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllBlogs, getBlogs } from "../../../redux/blogs/blogs-actions";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const CardImage = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  const [isLoading, setIsLoading] = useState(false);

  // console.log('blogdata', allBlog);
  useEffect(() => {
    handleGetAllBlog();
  }, []);

  const handleGetAllBlog = async () => {
    try {
      setIsLoading(true);
      let payload = {
        isActive: true,
        offset: 0,
        limit: 12,
      };
      await dispatch(getBlogs(payload));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlogDetail = (blogId) => {
    navigate(`/blog detail/${blogId}`);
  };

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
    style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="mx-auto text-center lg:w-2/3 md:w-2/3">
          <h2
            id="0579988455"
            className="dynamicStyle section_header"
          >
            {data ? data["0579988455"] : "BLOGS"}
          </h2>
          <ReactQuill
            id="8568575289"
            theme="bubble"
            readOnly
            className="card_description bubble dynamicStyle mt-2"
            value={data ? data["8568575289"] : "Lorem Ipsum"}
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {Blogs && Blogs.result && Blogs.result.length > 0 ? (
              <Swiper
                spaceBetween={15}
                slidesPerView={4}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  1280: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  991: {
                    slidesPerView: 2.8,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2.4,
                    spaceBetween: 15,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                  <svg
                    stroke="currentColor"
                    color="white"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </div>
                {Blogs?.result.map((e, index) => {
                  return (
                    <SwiperSlide>
                      <div
                        className="relative prime_card_0 bg_none card lg:mx-2 md:mx-2"
                        key={index}
                      >
                        <div
                          className="cursor-pointer themeShadow theme-bg"
                          onClick={() => handleBlogDetail(e._id)}
                        >
                          <div className="overflow-hidden rounded-xl">
                            <Image
                              height="275px"
                              src={process.env.REACT_APP_STORAGE_URL + e.image}
                              alt="heroImage"
                              imageClassName="w-full custimghover object-cover rounded-xl sm-h250px md-h200px"
                            />
                          </div>
                          <div className="p-2 lg:mt-4 md:mt-4">
                            <div className="flex items-center justify-between mb-4 ">
                              <Badge
                                borderRadius="full"
                                px="3"
                                py="0.5"
                                className="bg-B2F5EA card-badge-blue"
                              >
                                {e.categoryID &&
                                e.categoryID.length > 0 &&
                                e.categoryID[0].categoryName
                                  ? e.categoryID[0].categoryName
                                  : ""}
                              </Badge>
                              <p className="text-gray text-sm">
                                {new Date(e.createdAt).toDateString()}
                              </p>
                            </div>

                            <h4 className="font-semibold text-lg lineClamp2 cust-textColor">
                              {e.title}
                            </h4>
                            <ReactQuill
                              theme="bubble"
                              readOnly
                              className="card_description bubble dynamicStyle"
                              value={e.content}
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <div className="flex justify-center align-center">
                No Blog Found
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardImage;
