import { useSelector } from 'react-redux/es/exports';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';

const AboutSection44 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <h5 id="5631669592" className="dynamicStyle about_title">
              {data ? data['5631669592'] : 'no-data'}
            </h5>
            <p
              id="2591786740"
              className="dynamicStyle section_detail lineClamp3 "
            >
              {data ? data['2591786740'] : 'no-data'}
            </p>
            <div
              className="mt-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 dynamicStyle"
              id="7642001994"
            >
              {data && data['7642001994']
                ? data['7642001994'].map(e => {
                    return (
                      <div>
                        <h6 className="fsize32 sm-fsize22 text-gray font-semibold">
                          {e.field1}
                        </h6>
                        <h2 className="mt-1 card_title">{e.field2}</h2>
                        <p
                          className="
                        
                        
                        card_description"
                        >
                          {e.field3}
                        </p>
                      </div>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 flex gap-6 w-full lg:mt-0 md:mt-0 mt-10">
            <div className="w-1/2 lg:mt-20 md:mt-16 mt-8">
              <div id="0434541007" className="dynamicStyle2">
                <Image
                  imageClassName="lg:h-80 md:h-44 h-44 w-full rounded-lg object-cover"
                  src={data ? getImage(data['0434541007']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img-card"
                />
              </div>
              <div id="7642423064" className="dynamicStyle2 mt-4">
                <Image
                  imageClassName="lg:h-52 md:h-40 h-40 w-full rounded-lg object-cover"
                  src={data ? getImage(data['7642423064']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img-card"
                />
              </div>
            </div>
            <div className="w-1/2">
              <div id="4336554812" className="dynamicStyle2">
                <Image
                  imageClassName="lg:h-60 md:h-40 h-40 w-full rounded-lg object-cover"
                  src={data ? getImage(data['4336554812']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285960.jpg`}
                  alt="img-card"
                />
              </div>
              <div id="7090174168" className="dynamicStyle2 mt-4">
                <Image
                  imageClassName="lg:h-52 md:h-40 h-40 w-full rounded-lg object-cover"
                  src={data ? getImage(data['7090174168']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img-card"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection44;
