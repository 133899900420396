import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  Input,
  InputGroup,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";

const NewsletterSection5 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <form
          onSubmit={formik.handleSubmit}
          className="lg:w-3/5 md:w-4/5 w-full mx-auto text-center"
        >
          <p id="3136572118" className="dynamicStyle section_header">
            {data ? data["3136572118"] : "Lorem Ipsum"}
          </p>
          <p id="2826385445" className="dynamicStyle section_detail">
            {data
              ? data["2826385445"]
              : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias excepturi provident doloribus natus. "}
          </p>
          <div className="flex items-center lg:gap-3 md:gap-3 gap-2 lg:mt-5 md:mt-4 mt-3">
            <FormControl
              isInvalid={!!formik.errors.email && formik.touched.email}
            >
              <InputGroup size="lg" className="mx-auto my-6 lg:w-4/5 w-full">
                <Input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  pr="4.5rem"
                  placeholder="Enter your email address"
                  className="h45px fsize14 md-fsize14 sm-fsize12"
                />
              </InputGroup>
              {formik.touched.email && formik.errors.email && (
                <FormErrorMessage className="textwhite">
                  {formik.errors.email}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
              type="submit"
              className="h45px b_button_primary px-4 py-2 fsize16 md-fsize14 sm-fsize12 theme-btn"
              id="3023293995"
            >
              {data ? data["3023293995"] : "Contact Me"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsletterSection5;
