import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const StepGuide1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <h2 id="6463419553" className="dynamicStyle section_header">
            {data ? data["6463419553"] : "Lorem ipsum"}
          </h2>
          <ReactQuill
            id="0331940414"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["0331940414"] : "Lorem Ipsum"}
          />
        </div>
        <div
          id="3426868396"
          className="dynamicStyle grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-6 lg:mt-12 md:mt-10 mt-8"
        >
          {data && data["3426868396"]
            ? data["3426868396"].map((e, index) => {
                return (
                  <div key={index}>
                    <p className="hero_title">{e.field1}</p>
                    <Card className=" theme-bg themeShadow prime_card step1">
                      <div className="flex items-center gap-2 lg:mb-4 md:mb-4 mb-2">
                        <Image
                          src={getImage(e.field2)}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img-step"
                          imageClassName="lg:h-12 lg:w-12 md:h-12 md:w-12 h-10 w-10 rounded-md"
                        />
                        <p className="card_title_0">{e.field3}</p>
                      </div>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field4}
                      />
                    </Card>
                  </div>
                );
              })
            : "no-card"}
        </div>
      </div>
    </div>
  );
};

export default StepGuide1;
