import { Card } from "primereact/card";
import React from "react";

function QueuingSystem1() {
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <section className="prime_container">
        <Card className="prime_card_0">
          <div className="grid lg:grid-cols-3 md:grid-cols-3 ">
            <div>
              <h2 className="bg-primary text-white px-8 py-6 fsize18 font-semibold  borderright">
                Scheduled{" "}
              </h2>
              <div className=" p-4">
                <p className="text_surface800">Now</p>
                <h2 className="text-6xl text-primary font-semibold">1001</h2>
              </div>

              <div className="">
                <div className="p-6 py-4">
                  <h2 className="font-medium text_surface800">Next in Queue</h2>
                </div>

                <div className="my-4 px-6">
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>

                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>

                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                </div>
              </div>
            </div>

            <div>
              <h2 className="bg-primary text-white px-8 py-6 fsize18 font-semibold  borderright">
                Walk-in{" "}
              </h2>
              <div className=" p-4">
                <p className="text_surface800">Now</p>
                <h2 className="text-6xl text-primary font-semibold">2001</h2>
              </div>

              <div className="">
                <div className="px-6 py-4">
                  <h2 className="font-medium text_surface800">Next in Queue</h2>
                </div>

                <div className="my-4 px-6">
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>

                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                </div>
              </div>
            </div>

            <div>
              <h2 className="bg-primary text-white px-8 py-6 fsize18 font-semibold">
                Pickup{" "}
              </h2>
              <div className=" p-4">
                <p className="text_surface800">Now</p>
                <h2 className="text-6xl text-primary font-semibold">3001</h2>
              </div>

              <div className="">
                <div className="px-6 py-4">
                  <h2 className="font-medium text_surface800">Next in Queue</h2>
                </div>

                <div className="my-4 px-6">
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>

                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>

                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                  <h2 className="font-semibold card_title_0 pb-2">1002</h2>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <p className="mt-16 py-2 text-primary fsize14 font-medium">
          Watch here for you number
        </p>
      </section>
    </div>
  );
}

export default QueuingSystem1;
