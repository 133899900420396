import React from 'react';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { useSelector } from 'react-redux/es/exports';

const Confirmation2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
      <div className="prime_container">
      <div>
        <div id="0870211257" className="dynamicStyle2">
          <Image
            src={data ? getImage(data['0870211257']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            imageClassName="w-96 h-60 mx-auto object-cover"
          />
        </div>
        <div className="lg:w-1/3 md:w-1/2 mx-auto my-6">
          <p
            id="3535436849"
            className="dynamicStyle sm-fsize14 font-semibold text-center text-primary cust-textColor"
          >
            {data ? data['3535436849'] : 'Lorem ipsum'}
          </p>
          <p
            id="9836317902"
            className="dynamicStyle fsize14 sm-fsize12 text_surface800 text-center"
          >
            {data ? data['9836317902'] : 'Lorem ipsum'}
          </p>
        </div>
        <div className="flex gap-2 justify-center">
          <Button
            id="0295485631"
            className="dynamicStyle b_button_primary px-6 py-2 theme-btn font-semibold"
          >
            {data ? data['0295485631'] : 'Lorem ipsum'}
          </Button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Confirmation2;
