import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { useDispatch } from "react-redux";

import {
  getAllPodcasts,
  setSelectedPodcast,
} from "../../../redux/podcast/podcast-action";
import FeatherIcon from "feather-icons-react";
const PodcastCard = ({ item, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  console.log(videoRef, "videoRef");

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setPlaying(true);
      // videoRef.current.removeAttribute("poster");
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.load();
      setPlaying(false);
      // videoRef.current.setAttribute("Poster");
    }
  };
  const handlePodcastDetail = (podcastId) => {
    dispatch(setSelectedPodcast(podcastId));
    navigate(`/podcast-detail/${podcastId}`);
  };
  return (
    <Card
      className="rounded-lg overflow-hidden"
      onClick={() => handlePodcastDetail(item)}
    >
      <div className="relative">
        {/* <Image
                      src={process.env.REACT_APP_STORAGE_URL + item.image}
                      imageClassName="w-full lg:h-80 md:h-60 h-56 object-cover filter-b4"
                      alt="image"
                    /> */}

        <video
          muted
          ref={videoRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="w-full lg:h-80 md:h-60 h-56 cursor-pointer object-cover"
          poster={`${process.env.REACT_APP_STORAGE_URL}${item.imagethumbnail}`}
        >
          <source
            type="video/mp4"
            src={`${process.env.REACT_APP_STORAGE_URL}${item?.videoupload2}`}
          />
        </video>
        {/* <video
                      muted
                      controls
                      ref={videoRef}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className="w-full lg:h-80 md:h-60 h-56 cursor-pointer"
                      source
                      src={`${process.env.REACT_APP_STORAGE_URL}seedtoscale/undefined/video-CooxvLquK13PRkNCQUiUq-1725860442`}
                    ></video> */}

        {/* <div className="absolute bottom-0 right-0 bordleft px-2 py-4">
          <div className="">
            <h4 className="fsize14 font-semibold clr-fff">Lorem Ipsum</h4>
            <h4 className="fsize12 clr-fff">Lorem Ipsum</h4>
          </div>
        </div> */}
        <div className="absolute bottom-0 left-0 minus-absl lg:ml-4 md:ml-3 ml-2">
          <div
            className={
              playing
                ? "cursor-pointer flex items-center bg-primary justify-center lg:w-12 lg:h-12 md:w-10 md:h-10 w-10 h-10 rounded-full play_border"
                : "cursor-pointer flex items-center bg-white justify-center lg:w-12 lg:h-12 md:w-10 md:h-10 w-10 h-10 rounded-full "
            }
          >
            <FeatherIcon
              icon={playing ? "pause" : "play"}
              size={16}
              className={
                playing ? "text-white flex" : "text-black play_fill flex"
              }
            />
          </div>
        </div>
      </div>
      <div
        className={
          index % 2 == 0 ? "bg-primary p-4 h-24" : "bg-secondary p-4 h-24"
        }
      >
        <div className="pt-4">
          <h4 className="card_title_0 clr-fff lineClamp2">{item.title}</h4>
        </div>
      </div>
    </Card>
  );
};

const PodcastSection2 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);

  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  const dispatch = useDispatch();

  let allPodcast = useSelector((state) => state.Podcast.Podcasts);
  console.log(allPodcast, "allPodcast");

  const handlePodcast = async () => {
    try {
      await dispatch(getAllPodcasts());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlePodcast();
  }, []);

  // function getImage(image) {
  //   if (image && image.includes("blob:")) {
  //     return image;
  //   } else {
  //     return process.env.REACT_APP_STORAGE_URL + image;
  //   }
  // }

  // const videoRef = useRef(null);

  // const handleMouseEnter = () => {
  //   if (videoRef.current) {
  //     const playPromise = videoRef.current.play();

  //     if (playPromise !== undefined) {
  //       playPromise
  //         .then(() => {
  //           console.log("Auto play starts");
  //         })
  //         .catch((error) => {
  //           console.log("Playback prevented", error);
  //         });
  //     }
  //   }
  // };

  // const handleMouseLeave = () => {
  //   if (videoRef.current) {
  //     videoRef.current.pause();
  //     videoRef.current.currentTime = 0;
  //   }
  // };

  // const videoURL = `${process.env.REACT_APP_STORAGE_URL}${item.video}`;

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="relative w-full">
          <div className="z-50 relative w-max">
            <h5
              id="6601656811"
              className="fsize36 md-fsize32 sm-fsize28 text-center font-medium text-primary bg-bodySurface dynamicStyle"
            >
              / {data ? data["6601656811"] : "Lorem Ipsum"} /
            </h5>
          </div>
          <hr className="hr-line lg:block md:block hidden" />
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 lg:mt-12 md:mt-8 mt-6">
          {allPodcast
            ? allPodcast?.map((item, index) => (
                <PodcastCard item={item} index={index} />
                // <Card className="rounded-lg overflow-hidden">
                //   <div className="relative">
                //     {/* <Image
                //       src={process.env.REACT_APP_STORAGE_URL + item.image}
                //       imageClassName="w-full lg:h-80 md:h-60 h-56 object-cover filter-b4"
                //       alt="image"
                //     /> */}

                //     <video
                //       muted
                //       controls
                //       ref={videoRef}
                //       onMouseEnter={handleMouseEnter}
                //       onMouseLeave={handleMouseLeave}
                //       className="w-full lg:h-80 md:h-60 h-56 cursor-pointer"
                //       poster={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                //     >
                //       <source
                //         type="video/mp4"
                //         src={`${process.env.REACT_APP_STORAGE_URL}${item?.video}`}
                //       >
                //         {" "}
                //       </source>
                //     </video>
                //     {/* <video
                //       muted
                //       controls
                //       ref={videoRef}
                //       onMouseEnter={handleMouseEnter}
                //       onMouseLeave={handleMouseLeave}
                //       className="w-full lg:h-80 md:h-60 h-56 cursor-pointer"
                //       source
                //       src={`${process.env.REACT_APP_STORAGE_URL}seedtoscale/undefined/video-CooxvLquK13PRkNCQUiUq-1725860442`}
                //     ></video> */}

                //     <div className="absolute bottom-0 right-0 bordleft px-2 py-4">
                //       <div className="">
                //         <h4 className="fsize14 font-semibold clr-fff">
                //           Lorem Ipsum
                //         </h4>
                //         <h4 className="fsize12 clr-fff">Lorem Ipsum</h4>
                //       </div>
                //     </div>
                //     <div className="absolute bottom-0 left-0 minus-absl lg:ml-4 md:ml-3 ml-2">
                //       <div className="cursor-pointer flex items-center bg-white justify-center lg:w-12 lg:h-12 md:w-10 md:h-10 w-10 h-10 rounded-full">
                //         <FeatherIcon
                //           icon="play"
                //           size={16}
                //           className="text-primary flex"
                //         />
                //       </div>
                //     </div>
                //   </div>
                //   <div
                //     className={
                //       index % 2 == 0
                //         ? "bg-primary p-4 h-24"
                //         : "bg-secondary p-4 h-24"
                //     }
                //   >
                //     <div className="pt-4">
                //       <h4 className="card_title_0 clr-fff lineClamp2">
                //         {item.title}
                //       </h4>
                //     </div>
                //   </div>
                // </Card>
              ))
            : "no-data"}
        </div>
      </div>
    </div>
  );
};

export default PodcastSection2;
