import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const Services6 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <div className="header_align">
          <p id="2449322198" className="dynamicStyle section_header">
            {data ? data["2449322198"] : "Lorem"}
          </p>
          <ReactQuill
            id="3747154202"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["3747154202"] : "Lorem Ipsum"}
          />
        </div>
        <div className="lg:flex md:flex items-start lg:gap-12 md:gap-6 w-full lg:mt-12 md:mt-10 mt-8">
          <div
            id="7427807967"
            className="dynamicStyle2 lg:w-/12 md:w-1/2 w-full"
          >
            <Image
              src={data ? getImage(data["7427807967"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="service_image"
              imageClassName="object-contain"
            />
          </div>
          <div
            id="7534097216"
            className="dynamicStyle lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-6"
          >
            {data && data["7534097216"]
              ? data["7534097216"].map((e, index) => {
                  return (
                    <Card
                      className="prime_card w-full lg:mb-6 md:mb-6 mb-4 theme-bodyColor themeShadow-insert themeShadow"
                      key={index}
                    >
                      <p className="card_title">{e.field1}</p>
                      <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field2}
                      />
                    </Card>
                  );
                })
              : "no-data"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services6;
