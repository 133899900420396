import { combineReducers } from "redux";
import Cms_reducer from "./cms/cms-reducer";
import BD_reducer from "./brandDetails/bd-reducer";
import Menu_reducer from "./menu/menu-reducer";
import Marcom_reducer from "./marcom/marcom-reducer";
import AuthDetails from "./auth/auth-reducer";
import BlogDetails from "./blogs/blogs-reducer";
import userDetails from "./userDetails/user-reducer";
import newsletter from "./newsletter/newsletter-reducer";
import gateways from "./payment/gateway-reducer";
import stripe from "./payment/stripe-reducer";
import chat from "./chat/chat-reducer";
import testimonial from "./testimonial/testimonial-reducer";
import loginGateway from "./loginGateway/loginGateway-reducer";
import Forms from "./forms/forms-reducer";
import ecom_Product from "./ecomProduct/products-reducer";
import elearningProduct from "./elearningProduct/products-reducer";
import Aiassist from "./Aiassist/Aiassist-reducer";
import ecom_Tags from "./ecomTag/tags-reducer";
import ecom_Category from "./ecomCategory/category-reducer";
import ecom_Sub_Category from "./ecomSubCategory/subCategory-reducer";
import pages_reducer from "./pages/pages-reducer";
import AllPortfolioDetails from "./portfolio/portfolio-reducer";
import Teams from "./teams/teams-reducer";
import LoadingReducer from "./Loader/loader-reducer";
import CustomRootReducer from "./custom/customRootReducer";
import headerSetting from "./headerSetting/header-reducer";
import Podcast from "./podcast/podcast-reducer";

const rootReducer = combineReducers({
  //CUSTOM
  ...CustomRootReducer,
  cms: Cms_reducer,
  brandDetails: BD_reducer,
  menuState: Menu_reducer,
  contact: Marcom_reducer,
  AuthDetails: AuthDetails,
  BlogDetails: BlogDetails,
  userDetails: userDetails,
  newsletter: newsletter,
  gateways: gateways,
  stripe: stripe,
  chat: chat,
  testimonial: testimonial,
  loginGateway: loginGateway,
  Forms: Forms,
  products: ecom_Product,
  elearningProduct: elearningProduct,
  Aiassist: Aiassist,
  tags: ecom_Tags,
  categorys: ecom_Category,
  subCategorys: ecom_Sub_Category,
  pages: pages_reducer,
  portfolio: AllPortfolioDetails,
  Teams: Teams,
  Loader: LoadingReducer,
  headerSetting: headerSetting,
  Podcast: Podcast,
});

export default rootReducer;
