import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import UserEffect from "./usereeffect/UserEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const AboutSection6 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section className="relative chairman-h" style={{ background: thisComponent.sectionColor }}>
      <div id="3411776711" className="dynamicStyle2 w-full cus-featuredynamic">
        <Image
          src={data ? getImage(data["3411776711"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="w-full chairman-h object-cover lg:block hidden"
          alt="chairman-h"
        />
      </div>
      <div className="lg:absolute top-0 left-0 w-full">
        <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-16 py-8">
          <div className="flex cust-reverse  items-center prime_container">
            <div className="lg:w-7/12  md:w-7/12 lg:pr-12 md:pr-10 lg:mt-0 md:mt-0 mt-6">
              <h2 id="5516522235" className="dynamicStyle about_title">
                {data ? data["5516522235"] : "Lorem Ipsum"}
              </h2>
              <p
                id="0893052102"
                className="fsize22 sm-fsize12 text_surface500  dynamicStyle"
              >
                {data ? data["0893052102"] : "Lorem Ipsum"}
              </p>
              <ReactQuill
                id="0652394764"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description mt-3"
                value={data ? data["0652394764"] : "Lorem Ipsum"}
              />

              <Button
                outlined
                className="sm-fsize14 lg:mt-8 md:mt-8 mt-6 dynamicStyle theme-btn px-6 py-2"
                id="8489854633"
              >
                <span
                  className="flex items-center"
                  onClick={() => handleClick("8489854633")}
                >
                  {data ? data["8489854633"] : "Lorem"}

                  <FeatherIcon
                    className="text-primary ml-3"
                    size={18}
                    icon="arrow-right"
                  />
                </span>
              </Button>
            </div>
            <div className="lg:w-5/12 lg:flex lg:justify-around md:w-5/12 ">
              <div
                ref={elementRef}
                className={`fade-right cards-home relative rounded-2xl bg-primary lg:w-3/4 md:w-11/12 ${
                  isVisible ? "visibleright" : ""
                } `}
              >
                <div
                  id="3607127858"
                  className="dynamicStyle2 lg:ml-8 md:ml-8  cards-img  z-10 rounded-2xl w-full"
                >
                  <Image
                    src={data ? getImage(data["3607127858"]) : ""}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    imageClassName="cards-img object-cover absolute-cards lg:absolute md:absolute z-20 rounded-2xl"
                    alt="cards"
                    style={{ transform: transform }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png"
                  imageClassName="w-24 h-20 absolute bottom-0 left-0"
                  alt="side"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection6;
