import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Button } from "primereact/button";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const Jobs2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h2
          id="1075196871"
          className="dynamicStyle inline-block section_header lg:pb-6 md:pb-6 pb-4"
        >
          {data ? data["1075196871"] : "Lorem"}
        </h2>
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList className="gap-4">
            <Tab>
              <span id="2717724227">{data ? data["2717724227"] : "Tab 1"}</span>
            </Tab>
            {/* <Tab >
              Tab 2
            </Tab>
            <Tab >
              Tab 2
            </Tab> */}
          </TabList>
          <TabPanels pt={4}>
            <TabPanel px={0}>
              <div
                id="4676086343"
                className="dynamicStyle w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:mt-8 md:mt-8 mt-6"
              >
                {data && data["4676086343"]
                  ? data["4676086343"].map((e) => {
                      return (
                        <Card className="prime_card theme-bg themeShadow">
                          <div className="relative">
                            <Image
                              src={e ? getImage(e.field1) : ""}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt="jobs"
                              imageClassName="jobs_card1_image rounded-md"
                            />
                            <div className="absolute top-0 right-0 flex gap-2 p-2">
                              <div className="clr-fff glass1 rounded-full w-6 h-6">
                                <FeatherIcon
                                  icon="share-2"
                                  size={12}
                                  className="p-1 w-6 h-6 m-auto"
                                />
                              </div>
                              <div className="clr-fff glass1 rounded-full w-6 h-6">
                                <FeatherIcon
                                  icon="bookmark"
                                  size={12}
                                  className="p-1 w-6 h-6 m-auto"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="pt-3">
                            <h2 className="card_title">{e.field2}</h2>
                            <ReactQuill
                              theme="bubble"
                              readOnly
                              className="card_description bubble dynamicStyle"
                              value={e.field3}
                            />
                            <Button
                              fontWeight={400}
                              className="flex justify-center b_button_primary w-full fisze14 mt-6 themeShadow theme-bg px-6 py-2"
                            >
                              {e.field4}
                            </Button>
                          </div>
                        </Card>
                      );
                    })
                  : "No Data"}
              </div>
            </TabPanel>
            {/* <TabPanel px={0}>
              <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-4">
                <Card className="p-3 ">
                  <div className="relative">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="jobs"
                      imageClassName="jobs_card1_image rounded-md"
                    />
                    <div className="absolute top-0 right-0 flex gap-2 p-2">
                      <div className="clr-fff glass1 rounded-full w-6 h-6">
                        <FeatherIcon
                          icon="share-2"
                          size={12}
                          className="p-1 w-6 h-6 m-auto"
                        />
                      </div>
                      <div className="clr-fff glass1 rounded-full w-6 h-6">
                        <FeatherIcon
                          icon="bookmark"
                          size={12}
                          className="p-1 w-6 h-6 m-auto"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    <h2
                      className='fsize18 md-fsize16 sm-fsize14 text-primary font-medium'
                    >
                      Title
                    </h2>
                    <p
                      className='pt-1 fsize14 md-fsize14 sm-fsize12 lineClamp3 text-black'
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Consequuntur iusto, placeat atque nisi quisquam facilis
                      nam eveniet debitis minus repellendus!
                    </p>
                    <Button
                      fontWeight={400}
                      className='clr-fff w-full fisze14 mt-6 flex justify-center'
                    >
                      Apply
                    </Button>
                  </div>
                </Card>
              </div>
            </TabPanel>
            <TabPanel px={0}>
              <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-4">
                <Card className="p-3 ">
                  <div className="relative">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="jobs"
                      imageClassName="jobs_card1_image rounded-md"
                    />
                    <div className="absolute top-0 right-0 flex gap-2 p-2">
                      <div className="clr-fff glass1 rounded-full w-6 h-6">
                        <FeatherIcon
                          icon="share-2"
                          size={12}
                          className="p-1 w-6 h-6 m-auto"
                        />
                      </div>
                      <div className="clr-fff glass1 rounded-full w-6 h-6">
                        <FeatherIcon
                          icon="bookmark"
                          size={12}
                          className="p-1 w-6 h-6 m-auto"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-3">
                    <h2
                      className='fsize18 md-fsize16 sm-fsize14 text-primary font-medium'
                    >
                      Title
                    </h2>
                    <p
                      className='pt-1 fsize14 md-fsize14 sm-fsize12 lineClamp3 text-black'
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Consequuntur iusto, placeat atque nisi quisquam facilis
                      nam eveniet debitis minus repellendus!
                    </p>
                    <Button
                      fontWeight={400}
                      className='bg-primary clr-fff w-full fisze14 mt-6 themeShadow theme-bg'
                    >
                      Apply
                    </Button>
                  </div>
                </Card>
              </div>
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Jobs2;
