import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";

import FeatherIcon from "feather-icons-react";

const NewsletterSection7 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="theme-bg lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <form onSubmit={formik.handleSubmit}>
          <div className="mx-auto flex lg:flex-row md:flex-row flex-col lg:gap-8 md:gap-8 gap-2">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <h4 id="7269813286" className="dynamicStyle section_header">
                {data ? data["7269813286"] : "Lorem Ipsum"}
              </h4>
              <p id="9374299254" className="dynamicStyle section_detail">
                {data ? data["9374299254"] : "Lorem Ipsum"}
              </p>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full flex items-center lg:gap-3 md:gap-3 gap-2 lg:mt-5 md:mt-4 mt-0">
              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
              >
                <InputGroup
                  size="lg"
                  className="mx-auto lg:my-6 md:my-6 my-3 lg:w-4/5 w-full"
                >
                  <Input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    borderRadius={"0px"}
                    outline={"0px"}
                    pr="4.5rem"
                    _focusVisible={"none"}
                    size="md"
                    placeholder="Enter your email address"
                    className="fsize14 md-fsize14 theme-border-0 theme-bg sm-fsize12 border-0  bg-fff cust-bgColor themeShadow"
                  />
                  <InputRightElement width="4.5rem">
                    <FeatherIcon
                      size={"18px"}
                      className="cust-textColor search-abs my-0.5"
                      icon="search"
                    />
                  </InputRightElement>
                </InputGroup>
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage className="textwhite">
                    {formik.errors.email}
                  </FormErrorMessage>
                )}
              </FormControl>

              <Button
                type="submit"
                className="dynamicStyle b_button_primary px-6 py-2 fsize14 sm-fsize12 theme-btn"
                id="3259375058"
              >
                {data ? data["3259375058"] : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsletterSection7;
