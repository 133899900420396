import { useSelector } from 'react-redux/es/exports';
// import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from 'primereact/image';
import { TabView, TabPanel } from 'primereact/tabview';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const AboutSection41 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:flex md:flex sm-reverse" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:w-7/12 md:w-6/12 w-full">
        <p
          id="3635107156"
          className="dynamicStyle about_title lg:px-12 md:px-12 px-5 lg:py-12 md:py-8 py-6"
        >
          {data ? data['3635107156'] : 'Lorem ipsum dolor sit amet'}
        </p>
        {/* <div className="about41">
          <Tabs className="">
            <TabList mb="1em" className="tablist-border sm-mb-0">
              <Tab
                id="6661690003"
                className="dynamicStyle bdr-right-d9d0d0 card_title_0"
              >
                {data ? data['6661690003'] : 'Lorem'}
              </Tab>
              <Tab id="5318398245" className="dynamicStyle card_title_0">
                {data ? data['5318398245'] : 'Lorem'}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <p className="lg:px-8 md:px-6 card_description">
                  Our Mission "Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Sed corrupti quam alias possimus totam,
                  natus excepturi praesentium nobis consequuntur aliquid placeat
                  similique adipisci, nihil quidem officia, modi voluptatibus
                  dicta dolor."
                </p>
              </TabPanel>
              <TabPanel>
                <p className="lg:px-8 md:px-6 card_description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                  corrupti quam alias possimus totam, natus excepturi
                  praesentium nobis consequuntur aliquid placeat similique
                  adipisci, nihil quidem officia, modi voluptatibus dicta dolor.
                </p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div> */}
         <div id="9389886313"
            className="dynamicStyle lg:px-12 md:px-12 px-5">
            <TabView>
            {data && data['9389886313']
              ? data['9389886313'].map((e, index) => {
                  return (
                <TabPanel header={
                  <p>{e.field1}</p>
                } >
                    <ReactQuill
                        theme="bubble"
                        readOnly
                        className="bubble card_description"
                        value={e.field2}
                      />
                </TabPanel>
                );
              })
            : 'no-card'}
            
                {/* <TabPanel header="Header II">
                    <p className="m-0">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, 
                        eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui 
                        ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                    </p>
                </TabPanel>
                <TabPanel header="Header III">
                    <p className="m-0">
                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                        culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. 
                        Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                    </p>
                </TabPanel> */}
            </TabView>
        </div>
      </div>
      <div id="2579223440" className="dynamicStyle2 lg:w-5/12 md:w-6/12 w-full">
        <Image
          src={data ? getImage(data['2579223440']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img-about"
          imageClassName="w-full h-600px sm-h-250px object-cover"
        />
      </div>
    </div>
  );
};

export default AboutSection41;
