import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  InputGroup,
  Input,
  InputLeftElement,
  Textarea,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import FeatherIcon from 'feather-icons-react';

const Userrequest = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div className="p-7 w-9/12 mx-auto">
              <div className="text-center mt-5">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="approved"
                  className="w-20 h-20 mx-auto"
                />
                <h5 className="text-secondary mt-3 fsize20 font-semibold">
                  File Approved
                </h5>
              </div>

              <div className="mt-5">
                <Button colorScheme="primary" className="w-full">
                  Ok
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Reason</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div className="py-6">
              <div className="">
                <Textarea placeholder="Reason" rows={4} />
              </div>

              <div className="mt-5 flex items-center gap-2">
                <Button className="w-full b_button_primary px-6 py-2">
                  Submit
                </Button>
                <Button className="w-full b_button_primary px-6 py-2">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="lg:flex md:flex  items-center justify-between mb-5 gap-2">
        <h2 className="fsize25 sm-fsize19 font-semibold text-primary" id="">
          Request File
        </h2>
        <div className="lg:flex md:flex items-center gap-3 lg:w-7/12 md:w-6/12 w-full lg:mt-0 md:mt-0 mt-3">
          <div className="lg:w-7/12 md:w-6/12 w-full">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input type="tel" placeholder="Enter" />
            </InputGroup>
          </div>
          <div className="lg:w-5/12 md:w-6/12 w-full flex items-center gap-2 lg:mt-0 md:mt-0 mt-3">
            <Select placeholder="Select option">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
            <Select placeholder="Select option">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
          </div>
        </div>
      </div>
      <TableContainer mt={5}>
        <Table variant="simple" style={{ overflow: 'auto' }}>
          <Thead>
            <Tr>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Sr No.
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                File Name
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Request Date
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Request Type
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Request By
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Roles
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Description
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                File type
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Assign Author
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                25/12/2023
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                24/12/2024 (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Business user
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                7:18pm to 8:00pm (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                new File Request
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                millimetres (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <div className="flex gap-2 items-center">
                  <div
                    className="w-10 h-10 flex justify-center items-center rounded-md bg-light"
                    onClick={onOpen}
                  >
                    <FeatherIcon
                      icon="check"
                      className="clr-04bd00"
                      size="18"
                    />
                  </div>
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-redlight">
                    <FeatherIcon icon="x" className="clr-f60000" size="18" />
                  </div>
                </div>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                25/12/2023
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                24/12/2024 (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Business user
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                7:18pm to 8:00pm (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                new File Request
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                millimetres (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-light">
                    <FeatherIcon
                      icon="check"
                      className="clr-04bd00"
                      size="18"
                    />
                  </div>
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-redlight">
                    <FeatherIcon icon="x" className="clr-f60000" size="18" />
                  </div>
                </div>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                25/12/2023
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                24/12/2024 (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Business user
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                7:18pm to 8:00pm (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                new File Request
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                millimetres (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-light">
                    <FeatherIcon
                      icon="check"
                      className="clr-04bd00"
                      size="18"
                    />
                  </div>
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-redlight">
                    <FeatherIcon icon="x" className="clr-f60000" size="18" />
                  </div>
                </div>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                25/12/2023
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                24/12/2024 (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Business user
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                7:18pm to 8:00pm (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                new File Request
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                millimetres (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-light">
                    <FeatherIcon
                      icon="check"
                      className="clr-04bd00"
                      size="18"
                    />
                  </div>
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-redlight">
                    <FeatherIcon icon="x" className="clr-f60000" size="18" />
                  </div>
                </div>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                25/12/2023
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                24/12/2024 (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Raj Shetye
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Business user
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                7:18pm to 8:00pm (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                new File Request
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                millimetres (mm)
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-light">
                    <FeatherIcon
                      icon="check"
                      className="clr-04bd00"
                      size="18"
                    />
                  </div>
                  <div className="w-10 h-10 flex justify-center items-center rounded-md bg-redlight">
                    <FeatherIcon icon="x" className="clr-f60000" size="18" />
                  </div>
                </div>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Userrequest;
