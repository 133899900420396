import React from "react";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  FormControl,
  useColorMode,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const NewsletterSection6 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Newsletter",
          text: `Subscribed to newsletter`,
          icon: "success",
          timer: "2500",
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div
      className={
        colorMode === "light"
          ? "bg-secondary h-400px flex justify-center items-center theme-bg"
          : "h-400px flex justify-center items-center theme-bg"
      }
    >
      <form onSubmit={formik.handleSubmit} className="text-center px-5">
        <h3
          id="6313250779"
          className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold clr-fff cust-textColor"
        >
          {data ? data["6313250779"] : "Lorem Ipsum"}
        </h3>
        <ReactQuill
          id="6866391405"
          theme="bubble"
          readOnly
          className="bubble2-w dynamicStyle pb-4"
          value={data ? data["6866391405"] : "Lorem Ipsum"}
        />

        <div className="relative w-4/5 mx-auto">
          <FormControl
            isInvalid={!!formik.errors.email && formik.touched.email}
          >
            <Input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={handleTrim}
              placeholder="Enter Your Email"
              className=" theme_insertShadow rounded-100px bg-fff"
            />
            <div className="w-10 h-10 bg-secondary absolute right-0 top-0 flex justify-center items-center rounded-full z-10">
              <FeatherIcon
                className="text-primary cust-textColor  "
                size={20}
                icon="send"
              />
            </div>
            {formik.touched.email && formik.errors.email && (
              <FormErrorMessage className="textwhite">
                {formik.errors.email}
              </FormErrorMessage>
            )}
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default NewsletterSection6;
