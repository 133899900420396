import {
  Avatar,
  WrapItem,
  Textarea,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
} from "@chakra-ui/react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
// import {
//   getAllBlogs,
//   getSingleBlogs,
//   setSelectedBlog,
// } from "../../../redux/blogs/blogs-actions";
import FeatherIcon from "feather-icons-react";
import { FaShareAlt } from "react-icons/fa";
// import ShareProfileSection2 from "./ShareProfileSection2";
import { CopyIcon, LinkIcon } from "@chakra-ui/icons";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const PodcastDetail1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { blogId } = useParams();
  const dispatch = useDispatch();
  // let allBlog = useSelector((state) => state.BlogDetails.Blog);
  let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  let selectedPodcast = useSelector((state) => state.Podcast.selectedPodcast);
  console.log(selectedPodcast, "red");

  // const [selectedPodcast, setSelectedBlog] = useState({});
  const [blogHTMLData, setBlogHTMLData] = useState("");
  const [blogLink, setBlogLink] = useState(
    `${process.env.REACT_APP_FRONTEND_URL}/blog%20detail/${blogId}`
  );
  const [copied, setCopied] = useState(false);

  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();

  useEffect(() => {
    // fetchBlogData();
    setBlogLink(window.location.href);
  }, [blogId]);

  // const fetchBlogData = async () => {
  //   let { data } = await dispatch(getSinglePodcast({ blogId: blogId }));
  //   console.log(data);
  //   if (data && data.totalCount > 0 && data.result && data.result.length > 0) {
  //     console.log(data);
  //     dispatch(setSelectedBlog(data.result[0]));
  //     console.log(selectedPodcast, "Blogs.result");
  //     setBlogHTMLData(data.result[0].content);
  //   }
  // };
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  return (
    <section
      className="lg:px-20 md:px-8 px-5 lg:py-12 md:py-12 py-6"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <Modal isOpen={isOpenShare} onClose={onCloseShare}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <div className="mt-6 mb-2">
                <div className="flex justify-center ">
                  {/* <ShareProfileSection2 blogId={blogId} /> */}
                </div>
                <InputGroup size="md">
                  <Input pr="4.5rem" placeholder="Copy Link" value={blogLink} />
                  <InputRightElement width="4.5rem" mr={2}>
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => {
                        setCopied(true);
                        setTimeout((x) => {
                          setCopied(false);
                        }, 500);
                        navigator.clipboard.writeText(blogLink);
                      }}
                      className="b_button_primary px-4 py-2"
                    >
                      {copied ? "Copied" : "Copy"}
                      <LinkIcon ml={1} className="fsize12" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
        {/* banner */}
        <div className="">
          <div className="lg:flex md:flex  prime_container   justify-center items-center ">
            <div className="text-center">
              {/* <p className="text-white theme-textColor fsize12 pb-2">
              {selectedPodcast && selectedPodcast.createdAt
                ? new Date(selectedPodcast.createdAt).toDateString()
                : ""}
            </p> */}
              <h2 className=" lg:text-3xl md:text-2xl uppercase text-end   text-xl font-semibold">
                {selectedPodcast && selectedPodcast?.title
                  ? selectedPodcast?.title
                  : ""}
              </h2>
            </div>

            {/* <div className="lg:w-2/5 md:w-2/4 lg:flex md:flex justify-end w-full">
            <Image
              src={
                `${process.env.REACT_APP_STORAGE_URL}` +
                `${
                  selectedPodcast && selectedPodcast.videothumbnail
                    ? selectedPodcast.videothumbnail
                    : ""
                }`
              }
              alt="heroImage"
              imageClassName=" w-full sm-h250px minh450px object-cover"
            />
          </div> */}
          </div>
        </div>

        {selectedPodcast?.video?.length > 0 ? (
          <div className="lg:px-8 md:px-8 lg:py-8 md:py-8 py-4">
            <video
              controls
              className="w-full podcats_video"
              poster={`${process.env.REACT_APP_STORAGE_URL}${selectedPodcast?.image}`}
              muted
            >
              <source
                src={`${process.env.REACT_APP_STORAGE_URL}${selectedPodcast?.video}`}
                type="video/mp4"
              />
            </video>
          </div>
        ) : (
          ""
        )}

        {selectedPodcast?.videourl?.length > 0 ? (
          <div className="lg:px-8 md:px-8 lg:py-8 md:py-8 py-4">
            <iframe
              className="w-full podcats_video"
              src={selectedPodcast?.videourl}
              poster={`${process.env.REACT_APP_STORAGE_URL}${selectedPodcast?.image}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        ) : (
          ""
        )}

        {/* post */}
        <div className="relative lg:mt-12 md:mt-12 mt-6">
          <div className=" block zindex9 relative ">
            {/* write here */}
            <div className="quill">
              <div className="flex justify-between items-center w-full">
                <h2 className="font-semibold lg:text-2xl md:text-2xl cust-textColor">
                  {selectedPodcast?.title ? selectedPodcast?.title : ""}
                </h2>
                {/* <div className="">
                <FaShareAlt
                  className="fsize18 cursor-pointer"
                  onClick={onOpenShare}
                />
              </div> */}
              </div>

              <ReactQuill
                theme="bubble"
                readOnly
                value={selectedPodcast?.description}
                onChange={setBlogHTMLData}
              />
              {/* <p
              dangerouslySetInnerHTML={{ __html: selectedPodcast.content }}
              className=" lg:text-base md:text-base text-sm  opacity-70 lg:mt-4 md:mt-4 mt-2"
            ></p> */}
              {/* {selectedPodcast && selectedPodcast.embedLink && (
              <div
                className="lg:mt-4 md:mt-4 mt-2"
                dangerouslySetInnerHTML={{
                  __html: selectedPodcast.embedLink
                    ? selectedPodcast.embedLink
                    : "",
                }}
              ></div>
            )} */}
            </div>

            <div className="mt-8">
              <hr />
              {/* <div className="flex gap-4 mt-4">
              <FeatherIcon
                icon="thumbs-up"
                className="text-gray cursor-pointer"
              />
              <FeatherIcon
                icon="message-circle"
                className="text-gray cursor-pointer"
              />
              <FeatherIcon
                icon="share-2"
                className="text-gray cursor-pointer"
              />
            </div>

            <div className="flex gap-4 mt-8">
              <WrapItem>
                <Avatar name="Jon Doe"></Avatar>
              </WrapItem>
              <div className="w-full">
                {" "}
                <Textarea
                  placeholder="Please write here...."
                  className="h-120px"
                />
                <div className="flex justify-end mt-6">
                  <Button colorScheme="primary" px="8" py="5">
                    Comment{" "}
                  </Button>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PodcastDetail1;
