import React, { useEffect, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import {
  getAllBlogs,
  setSelectedBlog,
} from "../../../redux/blogs/blogs-actions";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Blog6 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  let navigate = useNavigate();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let allBlog = useSelector((state) => state.BlogDetails.Blog);
  console.log(allBlog, "allBlog");

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);

  const dispatch = useDispatch();

  const handleBlogDetail = (blogId) => {
    dispatch(setSelectedBlog({}));
    console.log(blogId, "blogId");
    navigate(`/blog detail/${blogId}`);
  };

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container lg:flex md:flex items-center">
        <div className="lg:w-4/12 md:w-5/12 w-full lg:pr-4 md:pr-2 pr-0">
          <div className="">
            <h4 id="3291948034" className="dynamicStyle section_header">
              {data ? data["3291948034"] : "Lorem Ipsum"}
            </h4>
            <ReactQuill
              id="8840418582"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle section_detail"
              value={data ? data["8840418582"] : "Lorem Ipsum"}
            />
          </div>
        </div>
        <div className="lg:w-8/12 md:w-7/12 w-full lg:mt-0 md:mt-0 mt-4">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            className="mySwiper lg:p-4 md:p-0 p-2"
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1.4,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 1.4,
                spaceBetween: 30,
              },
              820: {
                slidesPerView: 1.4,
                spaceBetween: 30,
              },
              505: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {allBlog?.length > 0
              ? allBlog.map((item) => (
                  <SwiperSlide>
                    <div className="relative gradients rounded-lg overflow-hidden">
                      <Image
                        src={`${process.env.REACT_APP_STORAGE_URL}${item?.image}`}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        imageClassName="object-cover lg:h-96 md:h-80 h-80 rounded-lg"
                      />

                      <div className="absolute bottom-0 left-0 w-full p-4 z-20">
                        <div className="">
                          <h4 className="clr-fff card_title_0 lineClamp2">
                            {item?.title}
                          </h4>
                          <div
                            className="flex items-center gap-2 cursor-pointer"
                            onClick={() => handleBlogDetail(item?._id)}
                          >
                            <p className="card_description text-primary">
                              Read More
                            </p>
                            <FeatherIcon
                              icon="arrow-right"
                              size="16"
                              className="text-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : "no-data"}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Blog6;
