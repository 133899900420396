import React, { useEffect, useRef, useState } from "react";
import { Image, useColorMode } from "@chakra-ui/react";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const StorySection1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:w-3/4 md:w-3/4 w-full mx-auto px-5 text-center lg:mb-6 mb-4">
          <h3
            id="3554401837"
            className={
              colorMode === "light"
                ? "dynamicStyle fsize32 sm-fsize20 font-semibold pb-2"
                : "dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor"
            }
          >
            {data ? data["3554401837"] : "Lorem Ipsum"}
          </h3>
          <ReactQuill
            id="5923432447"
            theme="bubble"
            readOnly
            className="card_description bubble dynamicStyle mt-2"
            value={data ? data["5923432447"] : "Lorem Ipsum"}
          />
        </div>
        <div id="5566755037" className="dynamicStyle">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data["5566755037"]
              ? data["5566755037"].map((e) => {
                  return (
                    <SwiperSlide className="">
                      <div className="bdr-1px-dcdcdc p-3 rounded-2xl cust-bgColor theme-border">
                        <div
                          className={
                            colorMode === "light"
                              ? "cust-shadow1 rounded-xl overflow-hidden themeShadow"
                              : "cust-shadow1 rounded-xl overflow-hidden bg-darkprimary themeShadow"
                          }
                        >
                          <Image
                            src={e ? getImage(e.field1) : ""}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                            className="h-36 w-full"
                          />
                          <div className="flex justify-between">
                            <div className="w-1/2 flex justify-center py-2 bdr-right-dcdcdc">
                              <div className="text-center">
                                <p
                                  className={
                                    colorMode === "light"
                                      ? "pb-1 text-gray sm-fsize14"
                                      : "pb-1 text-gray sm-fsize14 cust-textColor"
                                  }
                                >
                                  {e.field2}
                                </p>
                                <p
                                  className={
                                    colorMode === "light"
                                      ? "sm-fsize14"
                                      : "sm-fsize14 textColor-light"
                                  }
                                >
                                  {e.field3}
                                </p>
                              </div>
                            </div>
                            <div className="w-1/2 flex justify-center py-2">
                              <div className="text-center">
                                <p
                                  className={
                                    colorMode === "light"
                                      ? "pb-1 text-gray sm-fsize14"
                                      : "pb-1 text-gray sm-fsize14 cust-textColor"
                                  }
                                >
                                  {e.field4}
                                </p>
                                <p
                                  className={
                                    colorMode === "light"
                                      ? "sm-fsize14"
                                      : "sm-fsize14 textColor-light"
                                  }
                                >
                                  {e.field5}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:py-4 md:py-3 py-2">
                          <p
                            className={
                              colorMode === "light"
                                ? "fsize18 sm-fsize16 font-semibold"
                                : "fsize18 sm-fsize16 font-semibold cust-textColor"
                            }
                          >
                            {e.field6}
                          </p>
                          <p
                            className={
                              colorMode === "light"
                                ? "font-medium sm-fsize14"
                                : "font-medium sm-fsize14 textColor-light"
                            }
                          >
                            {e.field7}
                          </p>
                          <ReactQuill
                            theme="bubble"
                            readOnly
                            className="card_description bubble dynamicStyle mt-2"
                            value={e.field8}
                          />
                        </div>
                        <div className="">
                          <Button
                            className="dynamicStyle w-full theme-btn sm-fsize14 md-fsize14 px-6 py-2"
                            outlined
                          >
                            {e.field9}
                          </Button>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : "no-data"}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default StorySection1;
