import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';

const AboutSection46 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <p id="7257977815" className="dynamicStyle section_header">
            {data ? data['7257977815'] : 'Lorem Ipsum'}
          </p>
          <p id="1131192814" className="dynamicStyle section_detail">
            {data ? data['1131192814'] : 'Lorem ipsum dolor sit amet.'}
          </p>
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12 lg:flex md:flex sm-reverse gap-10">
          <div className="flex gap-4 lg:w-3/5 md:w-3/5 w-full relative">
            <div>
              <div className="flex gap-4 mb-8">
                <div className="w-3/12">
                  <p id="1056772080" className="dynamicStyle card_title_0">
                    {data ? data['1056772080'] : 'Lorem Ipsum'}
                  </p>
                </div>
                <div className="w-1/12">
                  <FeatherIcon
                    icon="disc"
                    className="z-10 bg-fff cust-bgColor rounded-full "
                    size={26}
                  />
                </div>
                <div className="w-8/12">
                  <ReactQuill
                    id="6763300952"
                    theme="bubble"
                    readOnly
                    className="bubble dynamicStyle card_description"
                    value={data ? data['6763300952'] : 'Lorem Ipsum'}
                  />
                </div>
              </div>
              <div className="flex gap-4 mb-8">
                <div className="w-3/12">
                  <p id="5633850994" className="dynamicStyle card_title_0">
                    {data ? data['5633850994'] : 'Lorem Ipsum'}
                  </p>
                </div>
                <div className="w-1/12">
                  <FeatherIcon
                    icon="disc"
                    className="z-10 bg-fff cust-bgColor rounded-full "
                    size={26}
                  />
                </div>
                <div className="w-8/12">
                  <ReactQuill
                    id="7966649543"
                    theme="bubble"
                    readOnly
                    className="bubble dynamicStyle card_description"
                    value={data ? data['7966649543'] : 'Lorem Ipsum'}
                  />
                </div>
              </div>
              <div className="flex gap-4 mb-8">
                <div className="w-3/12">
                  <p id="6880372188" className="dynamicStyle card_title_0">
                    {data ? data['6880372188'] : 'Lorem Ipsum'}
                  </p>
                </div>
                <div className="w-1/12">
                  <FeatherIcon
                    icon="disc"
                    className="z-10 bg-fff cust-bgColor rounded-full "
                    size={26}
                  />
                </div>
                <div className="w-8/12">
                  <ReactQuill
                    id="5283461815"
                    theme="bubble"
                    readOnly
                    className="bubble dynamicStyle card_description"
                    value={data ? data['5283461815'] : 'Lorem Ipsum'}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-3/12">
                  <p id="9435761408" className="dynamicStyle card_title_0">
                    {data ? data['9435761408'] : 'Lorem Ipsum'}
                  </p>
                </div>
                <div className="w-1/12">
                  <FeatherIcon
                    icon="disc"
                    className="z-10 bg-fff cust-bgColor rounded-full "
                    size={26}
                  />
                </div>
                <div className="w-8/12">
                  <ReactQuill
                    id="9294758570"
                    theme="bubble"
                    readOnly
                    className="bubble dynamicStyle card_description"
                    value={data ? data['9294758570'] : 'Lorem Ipsum'}
                  />
                </div>
              </div>
            </div>
            <div className="about46-vert-line md-about46-vert-line sm-about46-vert-line"></div>
          </div>
          <div className="lg:w-2/5 md:w-2/5 w-full flex items-center">
            <div id="5698585037" className="dynamicStyle2 h-350px w-full">
              <Image
                src={data ? getImage(data['5698585037']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-about"
                imageClassName="h-350px w-full rounded-xl object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection46;
