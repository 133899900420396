import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Mail, Phone } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

const AboutSection7 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container lg:flex md:flex lg:gap-6 md:gap-2 gap-4">
        <div
          className="lg:w-1/2 md:w-1/2 pb-8"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <p
            id="1096538094"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary textColor-light font-medium"
          >
            {data ? data['1096538094'] : 'Lorem Ipsum'}
          </p>
          <p id="1622512764" className="dynamicStyle about_title">
            {data ? data['1622512764'] : 'Lorem Ipsum'}
          </p>
          <ReactQuill
            id="1051677193"
            theme="bubble"
            readOnly
            className="dark-bubble lg:pb-8 md:pb-6 pb-4 fsize16 textColor-light bubble dynamicStyle"
            value={data ? data['1051677193'] : 'Lorem Ipsum'}
          />

          <Button
            className="dynamicStyle b_button_primary px-6 py-2 textwhite theme-btn fsize14 sm-fsize12"
            id="2195935300"
          >
            <span onClick={() => handleClick('2195935300')}>
              {data ? data['2195935300'] : 'READ MORE'}
            </span>
          </Button>
        </div>
        <Card className="lg:w-1/4 md:w-1/4 sm-mb-6 prime_card_0 overflow-hidden themeShadow theme-bg">
          <div id="2798158275" className="dynamicStyle2 w-full">
            <Image
              imageClassName="lg:h-80 md:h-66 h-56 sm-w-full object-cover"
              src={data ? getImage(data['2798158275']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div className="p-4">
            <p id="2187447847" className="dynamicStyle card_title_0">
              {data
                ? data['2187447847']
                : 'We provide wide variety of fabrics prints.'}
            </p>
            <div className="flex items-center gap-2 pt-2">
              <Phone
                size={18}
                className="sm-wid text-primary textColor-light"
              />
              <p
                id="2177972079"
                className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary textColor-light"
              >
                <a href="tel:+971 54 377 9204">
                  {data ? data['2177972079'] : '+971 54 377 9204'}
                </a>
              </p>
            </div>
            <div className="flex items-center gap-2 pt-2">
              <Mail size={18} className="sm-wid text-primary textColor-light" />
              <p
                id="8570414603"
                className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary textColor-light"
              >
                <a href="mailto:Example@gmail.com">
                  {data ? data['8570414603'] : 'Example@gmail.com'}
                </a>
              </p>
            </div>
          </div>
        </Card>
        <Card className="lg:w-1/4 md:w-1/4 sm-pb-6 prime_card_0 overflow-hidden themeShadow theme-bg">
          <div id="2607730231" className="dynamicStyle2 w-full">
            <Image
              imageClassName=" lg:h-80 md:h-66 h-56 sm-w-full object-cover"
              src={data ? getImage(data['2607730231']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div className="p-4">
            <p id="4132870535" className="dynamicStyle card_title_0">
              {data
                ? data['4132870535']
                : 'We provide wide variety of fabrics prints.'}
            </p>
            <div className="flex items-center gap-2 pt-2">
              <Phone size={18} className="sm-wid text-primary" />
              <p
                id="2121777879"
                className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary textColor-light"
              >
                <a href="tel:+971 54 377 9204">
                  {data ? data['2121777879'] : '+971 54 377 9204'}
                </a>
              </p>
            </div>
            <div className="flex items-center gap-2 pt-2">
              <Mail size={18} className="sm-wid text-primary" />
              <p
                id="9393274878"
                className="dynamicStyle cursor-pointer sm-fsize14 md-fsize14 text-primary textColor-light"
              >
                <a href="mailto:Example@gmail.com">
                  {data ? data['9393274878'] : 'Example@gmail.com'}
                </a>
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AboutSection7;
