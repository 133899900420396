import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux/es/exports";
import { Image } from "primereact/image";

const Distributers1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className="relative impact-h">
      <div id="5534918774" className="dynamicStyle2 cus-featuredynamic w-full">
        <Image
          src={data ? getImage(data["5534918774"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="w-full impact-h object-cover"
          alt="impact"
        />
      </div>

      <div className="absolute top-0 left-0 lg:py-12 md:py-12 py-8 lg:px-0 md:px-0 px-5 w-full">
        <div className="prime_container">
          <h3
            id="6742103064"
            className="dynamicStyle section_header clr-fff text-center"
          >
            {data ? data["6742103064"] : "Lorem Ipsum"}
          </h3>

          <div className="lg:w-8/12 lg:mt-12 md:mt-10 mt-8 flex flex-wrap justify-center mx-auto">
            <div
              className="mx-auto lg:w-1/3 md:w-1/3 w-1/2 mb-5"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div
                id="6982373375"
                className="dynamicStyle2 w-20 h-20 mx-auto shadow rounded-full"
              >
                <Image
                  src={data ? getImage(data["6982373375"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto shadow rounded-full"
                  alt="community"
                />
              </div>
              <p
                id="9920234288"
                className="dynamicStyle clr-fff text-center lg:mt-5 mt-3 card_title_0"
              >
                {data ? data["9920234288"] : "Lives Impacted"}
              </p>
              <h4
                id="8009363179"
                className="dynamicStyle fsize30 sm-fsize20 lg:mt-2 font-semibold text-white text-center"
              >
                {data ? data["8009363179"] : "Over 2.44 crore"}
              </h4>
            </div>

            <div className="mx-auto lg:w-1/3 md:w-1/3 w-1/2 mb-5 border-side">
              <div
                id="0519048446"
                className="dynamicStyle2 w-20 h-20 mx-auto shadow rounded-full"
              >
                <Image
                  src={data ? getImage(data["0519048446"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto shadow rounded-full"
                  alt="community"
                />
              </div>
              <p
                id="1324089304"
                className="dynamicStyle clr-fff text-center lg:mt-5 mt-3 card_title_0"
              >
                {data ? data["1324089304"] : "Lives Projects"}
              </p>
              <h4
                id="8242387679"
                className="dynamicStyle fsize30 sm-fsize20 lg:mt-2 font-semibold text-white text-center"
              >
                {data ? data["8242387679"] : "150+"}
              </h4>
            </div>

            <div
              className="mx-auto lg:w-1/3 md:w-1/3 w-1/2 mb-5"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div
                id="0302400557"
                className="dynamicStyle2 w-20 h-20 mx-auto shadow rounded-full"
              >
                <Image
                  src={data ? getImage(data["0302400557"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  imageClassName="w-20 h-20 mx-auto shadow rounded-full"
                  alt="community"
                />
              </div>
              <p
                id="9490114547"
                className="dynamicStyle clr-fff text-center lg:mt-5 mt-3 card_title_0"
              >
                {data ? data["9490114547"] : "7 Union Territories"}
              </p>
              <h4
                id="5085520238"
                className="dynamicStyle fsize30 sm-fsize20 lg:mt-2 font-semibold text-white text-center"
              >
                {data ? data["5085520238"] : "28 States"}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Distributers1;
