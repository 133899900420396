import {
  Image,
  Input,
  useColorMode,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';
import { useSelector } from 'react-redux';

const Login2 = ({pageName}) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  
  return (
    <>      
      <div className="container mx-auto h-100vh flex justify-center items-center ">
        <div className="lg:w-5/12 md:w-2/3 w-full mx-auto lg:px-0 md:px-0 px-5">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <div className="lg:mt-8 md:mt-6 mt-4">
            <h2 id='1012133048' className="dynamicStyle text-center fsize36 md-fsize32 sm-fsize24 font-semibold cust-textColor">
            {data ? data['1012133048'] : 'Welcome Back'}
            </h2>
            <p  className="text-center fsize14 textColor-light">
              <span id='8721266761' dynamicStyle>{data ? data['8721266761'] : 'New to the platform?'}</span>{' '}
              <span id='8405388673' className="dynamicStyle cursor-pointer fsize14 font-medium cust-textColor">
              {data ? data['8405388673'] : 'Please register here'}
              </span>
            </p>
            <div className="mt-8"></div>
            <Input placeholder="Email ID" className="mb-4 cust-textColor" py="6" />
            <Input placeholder="Password" className="cust-textColor" py="6" />
            <p className="fsize14 my-6 textColor-light">
              <span id='5807100720' className='dynamicStyle'>{data ? data['5807100720'] : 'Forgot Password?'}</span>{' '}
              <span id='0318575192' className="dynamicStyle fsize14 font-medium cursor-pointer cust-textColor">
              {data ? data['0318575192'] : 'Click Here'}
              </span>
            </p>
            <Button id='0937087573' className="dynamicStyle w-full b_button_primary px-6 py-2">
            {data ? data['0937087573'] : 'Sign In'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login2;
