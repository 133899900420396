import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import Aos from "aos";
import "aos/dist/aos.css";
const FeatureCard54 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <p id="6613790045" className="dynamicStyle section_header">
            {data ? data['6613790045'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="7400089543"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['7400089543'] : 'Welcome to My World'}
          />
        </div>
        <div className="lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
          <Card className="prime_card">
            <AvatarGroup size="sm" max={4} className="mb-4">
              <Avatar
                name="Ryan Florence"
                src="https://bit.ly/ryan-florence"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Segun Adebayo"
                src="https://bit.ly/sage-adebayo"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Kent Dodds"
                src="https://bit.ly/kent-c-dodds"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Christian Nwamba"
                src="https://bit.ly/code-beast"
                size="medium"
                shape="circle"
              />
            </AvatarGroup>
            <div>
              <p className="card_description theme-textColor">
                Reading Task
              </p>
              <p className="card_title">
                Lorem ipsum dolor sit amet. sit amet dolor .
              </p>
              <p className="card_description theme-textColor">
                2 hours 40 minutes
              </p>
              {/* <Button className="bg-primary mt-4 flex_rating theme-btn sm-fsize14 clr-fff px-2 py-1">
                <FeatherIcon
                  icon="play"
                  size={18}
                  className="cursor-pointer clr-fff mr-1 fill-current theme-textColor"
                />
                Play
              </Button> */}
              <Button
                label="Play"
                icon="pi pi-play"
                className="b_button_primary mt-4 flex_rating theme-btn sm-fsize14 clr-fff px-4 py-2"
              />
            </div>
          </Card>
          <Card className="prime_card">
            <AvatarGroup size="sm" max={4} className="mb-4">
              <Avatar
                name="Ryan Florence"
                src="https://bit.ly/ryan-florence"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Segun Adebayo"
                src="https://bit.ly/sage-adebayo"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Kent Dodds"
                src="https://bit.ly/kent-c-dodds"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Christian Nwamba"
                src="https://bit.ly/code-beast"
                size="medium"
                shape="circle"
              />
            </AvatarGroup>
            <div>
              <p className="card_description theme-textColor">
                Reading Task
              </p>
              <p className="card_title">
                Lorem ipsum dolor sit amet. sit amet dolor .
              </p>
              <p className="card_description theme-textColor">
                2 hours 40 minutes
              </p>
              {/* <Button className="bg-primary mt-4 theme-btn sm-fsize14 clr-fff px-2 py-1">
                <FeatherIcon
                  icon="play"
                  size={18}
                  className="cursor-pointer clr-fff mr-1 fill-current theme-textColor"
                />
                Play
              </Button> */}
              <Button
                label="Play"
                icon="pi pi-play"
                className="b_button_primary mt-4 flex_rating theme-btn sm-fsize14 clr-fff px-4 py-2"
              />
            </div>
          </Card>
          <Card className="prime_card">
            <AvatarGroup size="sm" max={4} className="mb-4">
              <Avatar
                name="Ryan Florence"
                src="https://bit.ly/ryan-florence"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Segun Adebayo"
                src="https://bit.ly/sage-adebayo"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Kent Dodds"
                src="https://bit.ly/kent-c-dodds"
                size="medium"
                shape="circle"
              />
              <Avatar
                name="Christian Nwamba"
                src="https://bit.ly/code-beast"
                size="medium"
                shape="circle"
              />
            </AvatarGroup>
            <div>
              <p className="card_description  theme-textColor">
                Reading Task
              </p>
              <p className="card_title ">
                Lorem ipsum dolor sit amet. sit amet dolor .
              </p>
              <p className="card_description  theme-textColor">
                2 hours 40 minutes
              </p>
              {/* <Button className="bg-primary mt-4 theme-btn sm-fsize14 clr-fff px-2 py-1">
                <FeatherIcon
                  icon="play"
                  size={18}
                  className="cursor-pointer clr-fff mr-1 fill-current theme-textColor"
                />
                Play
              </Button> */}
              <Button
                label="Play"
                icon="pi pi-play"
                className="b_button_primary mt-4 flex_rating theme-btn sm-fsize14 clr-fff px-4 py-2"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard54;
