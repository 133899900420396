import React, { useEffect, useRef, useState } from 'react';
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Aos from "aos";
import "aos/dist/aos.css";


const FeatureCard65 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const cards = [
    {
      title: "Heading",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut distinctio voluptas velit rem eos, ut alias omnis odio adipisci soluta.",
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      imageAlt: "Image alt for card 1",
    },
    {
      title: "Heading",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut distinctio voluptas velit rem eos, ut alias omnis odio adipisci soluta.",
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      imageAlt: "Image alt for card 2",
    },
    {
      title: "Heading",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut distinctio voluptas velit rem eos, ut alias omnis odio adipisci soluta.",
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      imageAlt: "Image alt for card 3",
    },
    {
      title: "Heading",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut distinctio voluptas velit rem eos, ut alias omnis odio adipisci soluta.",
      image:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
      imageAlt: "Image alt for card 4",
    },
  ];

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <h2 id="4775904072" className="dynamicStyle section_header">
            {data ? data["4775904072"] : "Lorem ipsum"}
          </h2>
          <ReactQuill
            id="0330487753"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data["4489049874"] : "Lorem Ipsum"}
          />
        </div>
        <div className="lg:mt-12 md:mt-10 mt-8 prime_card_container grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
          {cards.map((card, index) => {
            return (
              <Card className="prime_card" key={index}>
                <Image
                  src={card.image}
                  alt={card.imageAlt}
                  style={{ borderRadius: "20px" }}
                  imageClassName="w-6 h-6 block mb-4 object-contain rounded-sm"
                />
                <h2 className="card_title">{card.title}</h2>
                <p className="card_description">{card.description}</p>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard65;
