import React, { useEffect, useState } from 'react';
import { Check, Feather } from 'react-feather';
import FeatherIcon from 'feather-icons-react';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabView, TabPanel } from 'primereact/tabview';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/exports';
// import {
//   getAllTeamMembers,
//   getAllDealTerms,
//   getAllFaqs,
// } from '../../../redux/forms/forms-action';
const EcomProductDetails2 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const dispatch = useDispatch();
  // const [cardData, setCardData] = useState([]);
  // const [search, setSearch] = useState();
  // const getTableDetails = async () => {
  //   let payload = {
  //     projectId: process.env.REACT_APP_ID,
  //     projectName: process.env.REACT_APP_COMMUNITY_NAME,
  //     paginated: true,
  //   };
  //   if (search) Object.assign(payload, { searchBy: search });
  //   let data = await dispatch(getData("team_members", payload));
  //   setCardData(data.result);
  // };
  // useEffect(() => {
  //   // getTableDetails();
  //   dispatch(getAllTeamMembers());
  //   dispatch(getAllDealTerms());
  //   dispatch(getAllFaqs());
  // }, []);

  let selectedData = useSelector(state => state.Forms.selectedCard);

  const AllTeamMembers = useSelector(state => state.Forms.TeamMembers);
  const DealTerm = useSelector(state => state.Forms.DealTerms);
  const Faq = useSelector(state => state.Forms.Faqs);
  console.log(Faq, 'selected');

  const data = useSelector(state => state.cms.allSections[pageName]);
  // function getImage(image) {
  //   if (image && image.includes('blob:')) {
  //     return image;
  //   } else {
  //     return process.env.REACT_APP_STORAGE_URL + image;
  //   }
  // }

  return (
    <div className="lg:px-12 md:px-8 px-5 container m-auto my-8" style={{ background: thisComponent.sectionColor }}>
      <div className="lg:flex md:flex gap-4">
        <div className="lg:w-2/6 md:w-2/6 lg:mb-0 md:mb-0 mb-8">
          <Card className="bg_none shadow-none">
            <div>
              <h2 className="fsize30 sm-fsize20 font-bold cust-textColor">
                {selectedData && selectedData['company name']}
              </h2>
              <div className="flex gap-4 pt-2">
                <div className="fsize14 textprimary cust-textColor">
                  <FeatherIcon icon="map-pin" className="mr-1" size={16} />
                  {selectedData && selectedData.companylocation}
                </div>
                {/* <Button className="fsize14 textprimary">E-commerce</Button> */}
              </div>
              <div className="lg:pt-8 md:pt-8 pt-6">
                <div className="flex gap-2 textColor-light">
                  <Check size="15" />
                  <p className="fsize14 sm-fsize13 ">
                    Done 1770 aution in last 2 years.
                  </p>
                </div>
                <div className="flex gap-2 lg:pt-6 md:pt-6 pt-4 textColor-light">
                  <Check size="15" />
                  <p className="fsize14 sm-fsize13 ">
                    Done 1770 aution in last 2 years.
                  </p>
                </div>
                <div className="flex gap-2 lg:pt-6 md:pt-6 pt-4 textColor-light">
                  <Check size="15" />
                  <p className="fsize14 sm-fsize13 ">
                    Done 1770 aution in last 2 years.
                  </p>
                </div>
              </div>
              <div className="flex gap-10 pt-8">
                <div className="text-start">
                  <p className="fsize14 text-gray mb-1 cust-textColor">
                    Startup Stage
                  </p>
                  <p className="font-medium sm-fsize13 textColor-light">
                    {selectedData && selectedData.stageOfStartup}
                  </p>
                </div>
                <div className="text-start">
                  <p className="fsize14 text-gray mb-1 cust-textColor">
                    Funding Type
                  </p>
                  <p className="font-medium sm-fsize13 textColor-light">
                    {selectedData && selectedData.typeOfFunding}
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="lg:w-4/6 md:w-4/6  lg:px-4 md:px-4">
          <div className="w-full bg-f4f5f6 themeShadow theme-bg">
            <Image
              src={`${process.env.REACT_APP_STORAGE_URL}${
                selectedData && selectedData['company logo']
              } `}
              alt=""
              className="h-200px w-full object-contain"
            />
          </div>
          {/* <div className="pt-4">
            <h2 className="fsize30 font-bold">About</h2>
            <p className="fsize16 text-gray pt-2">
              {selectedData.companydescription}
            </p>
          </div> */}

          <Card className="prime_card">
            <TabView>
              <TabPanel
                header={
                  <p
                    id="2409734759"
                    className="dynamicStyle lg:px-4 md:px-4 px-4 sm-fsize12"
                  >
                    {data ? data['2409734759'] : 'Pitch'}
                  </p>
                }
              >
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                  {AllTeamMembers &&
                  AllTeamMembers.result &&
                  AllTeamMembers.result.length > 0
                    ? AllTeamMembers.result.map((item, index) => {
                        return (
                          <div className="">
                            <Image
                              className="w-full object-cover rounded-t-lg"
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image} `}
                              alt="img"
                            />
                            <div className="py-4">
                              <h2 className="font-semibold fsize16">
                                {item['full name']}
                              </h2>
                              <p className="fsize12 text-gray">
                                {item['job title']}
                              </p>

                              <p className="fsize12 text-gray pt-4">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </div>
              </TabPanel>
              <TabPanel
                header={
                  <p className="fsize14 text-gray lg:leading-7 md:leading-7 leading-6 textColor-light">
                    {selectedData && selectedData.companydescription}
                  </p>
                }
              >
                {DealTerm && DealTerm.result && DealTerm.result.length > 0
                  ? DealTerm.result.map(e => {
                      return (
                        <div className="  rounded-sm ">
                          <div className="flex justify-between pb-2">
                            {' '}
                            <h2 className="font-semibold md-fsize14 sm-fsize13 cust-textColor">
                              MINIMUM INVESTMENT VALUE
                            </h2>
                            <h2 className="font-semibold md-fsize14 sm-fsize13 cust-textColor">
                              {e['minimum value']}
                            </h2>
                          </div>
                          <hr />
                          <div className="flex justify-between pb-2 pt-4">
                            {' '}
                            <h2 className="font-semibold md-fsize14 sm-fsize13 cust-textColor">
                              VALUATION
                            </h2>
                            <h2 className="font-semibold md-fsize14 sm-fsize13 cust-textColor">
                              {e.valuation}
                            </h2>
                          </div>
                          <hr />
                          <div className="flex justify-between pb-2 pt-4">
                            {' '}
                            <h2 className="font-semibold md-fsize14 sm-fsize14 cust-textColor">
                              TARGET
                            </h2>
                            <h2 className="font-semibold md-fsize14 sm-fsize14 cust-textColor">
                              {e.target}
                            </h2>
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </TabPanel>
              <TabPanel
                header={
                  <p className="fsize14 text-gray lg:leading-7 md:leading-7 leading-6 textColor-light">
                    {selectedData && selectedData.companydescription}
                  </p>
                }
              >
                <div className="w-full ">
                  <Accordion activeIndex={0}>
                    {Faq && Faq.result && Faq.result.length > 0
                      ? Faq.result.map((faq, index) => (
                          <AccordionTab
                            header={
                              <h2 className="font-semibold md-fsize14 sm-fsize13 cust-textColor">
                                {faq.question}
                              </h2>
                            }
                          >
                            <p className="fsize14 md-fsize12 sm-fsize12">
                              {faq.answer}
                            </p>
                          </AccordionTab>
                        ))
                      : ''}
                  </Accordion>
                </div>
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default EcomProductDetails2;
