import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection18 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="relative h-400px sm-h-250px bg-primary theme-bg" style={{ background: thisComponent.sectionColor }}>
      <div className="absolute top-0 w-full h-400px sm-h-250px lg:flex md:flex items-center flex justify-center lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-4">
        <div
          ref={elementRef}
          className={`fade-bottom w-full flex flex-col justify-center items-center ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          <h3
            id="7231264687"
            className="dynamicStyle hero_title text-center clr-fff"
          >
            {data ? data['7231264687'] : 'Lorem ipsum'}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default HeroSection18;
