import {
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import React from 'react';
import { Card, Image } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react';

const BookingList1 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <section className="container mx-auto lg:px-12 md:px-4 px-5 lg:py-12 md:8 py-6">
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt={2} />
          <DrawerHeader >Filter</DrawerHeader>

          <DrawerBody>
            <div className="pb-5">
              <div>
                <div className="flex items-center justify-between">
                  <h4 className="fsize20 text-dark my-0 font-semibold cust-textColor">
                    Filters
                  </h4>
                  <p className="fsize12 text-dark textColor-light">Clear All</p>
                </div>
                <div className="mt-4 grid grid-cols-1 gap-5 lg:px-4">
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 2
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 3
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 4
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 5
                  </Checkbox>
                  <p className="fsize13 textColor-light">+ 8 more</p>
                </div>
              </div>
          <div className="mt-6">
            <div className="flex items-center justify-between">
              <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                Rent Raange
              </h4>
            </div>
            <div className="mt-4 gap-5 lg:px-4">
              <div className="pr-3">
                <RangeSlider defaultValue={[0, 100]} min={0} max={90} step={10}>
                  <RangeSliderTrack colorScheme="primary">
                    <RangeSliderFilledTrack colorScheme="primary" />
                  </RangeSliderTrack>
                  <RangeSliderThumb boxSize={4} index={1} />
                </RangeSlider>
              </div>
              <div className="flex items-center justify-between mt-2">
                <p className="fsize12 text-dark textColor-light">₹2000</p>
                <p className="fsize12 text-dark textColor-light">₹25,000</p>
              </div>
            </div>
          </div>
              <div className="mt-6">
                <div className="flex items-center justify-between">
                  <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                    Category 1
                  </h4>
                  <p className="fsize12 text-dark textColor-light">Clear</p>
                </div>
                <div className="mt-4 grid grid-cols-1 gap-5 lg:px-4">
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 2
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 3
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 4
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 5
                  </Checkbox>
                  <p className="fsize13 textColor-light">+ 8 more</p>
                </div>
              </div>
              <div className="mt-6">
                <div className="flex items-center justify-between">
                  <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                    Category 2
                  </h4>
                  <p className="fsize12 text-dark textColor-light">Clear</p>
                </div>
                <div className="mt-4 grid grid-cols-1 gap-5 lg:px-4">
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 2
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 3
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 4
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 5
                  </Checkbox>
                  <p className="fsize13 textColor-light">+ 8 more</p>
                </div>
              </div>
              <div className="mt-6">
                <div className="flex items-center justify-between">
                  <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                    Category 3
                  </h4>
                  <p className="fsize12 text-dark textColor-light">Clear</p>
                </div>
                <div className="mt-4 grid grid-cols-1 gap-5 lg:px-4">
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 2
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 3
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 4
                  </Checkbox>
                  <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                    Sub Category 5
                  </Checkbox>
                  <p className="fsize13 textColor-light">+ 8 more</p>
                </div>
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div className="">
        <InputGroup size="lg" className='cust-textColor'>
          <Input pr="5.5rem" placeholder="Seach Here..." />
          <InputRightElement width="7.5rem">
            <Button h="2.20rem" size="lg" className='b_button_primary px-6 py-2' fontWeight={400} fontSize={14}>
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
        <div className="mt-4 lg:flex md:flex grid grid-cols-1 items-center lg:gap-4 md:gap-4 gap-2">
          <Checkbox size="md" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='cust-textColor'>
            Posted By Owner
          </Checkbox>
          <Checkbox size="md" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='cust-textColor'>
            Posted By Agent
          </Checkbox>
        </div>
      </div>
      <div className="w-full lg:flex md:flex gap-4 lg:mt-12 md:mt-8 mt-2">
        <div className="lg:hidden md:hidden flex justify-end">
          <Button className='b_button_primary px-6 py-2' p={0} onClick={onOpen}>
            <FeatherIcon icon="filter" size="18" />
          </Button>
        </div>
        <div className="lg:w-3/12 md:w-3/12 w-full lg:block md:block hidden pr-5">
          <div>
            <div className="flex items-center justify-between">
              <h4 className="fsize24 text-dark my-0 font-semibold cust-textColor">Filters</h4>
              <p className="fsize12 text-dark textColor-light">Clear All</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-5 lg:px-4">
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 2
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 3
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 4
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 5
              </Checkbox>
              <p className="fsize13 textColor-light">+ 8 more</p>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex items-center justify-between">
              <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                Rent Raange
              </h4>
            </div>
            <div className="mt-6 gap-5 lg:px-4">
              <div className="pr-3">
                <RangeSlider defaultValue={[0, 100]} min={0} max={90} step={10}>
                  <RangeSliderTrack colorScheme="primary">
                    <RangeSliderFilledTrack colorScheme="primary" />
                  </RangeSliderTrack>
                  <RangeSliderThumb boxSize={4} index={1} />
                </RangeSlider>
              </div>
              <div className="flex items-center justify-between mt-2">
                <p className="fsize12 text-dark textColor-light">₹2000</p>
                <p className="fsize12 text-dark textColor-light">₹25,000</p>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex items-center justify-between">
              <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                Category 1
              </h4>
              <p className="fsize12 text-dark textColor-light">Clear</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-5 lg:px-4">
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 2
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 3
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 4
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 5
              </Checkbox>
              <p className="fsize13 textColor-light">+ 8 more</p>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex items-center justify-between">
              <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                Category 2
              </h4>
              <p className="fsize12 text-dark textColor-light">Clear</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-5 lg:px-4">
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 2
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 3
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 4
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 5
              </Checkbox>
              <p className="fsize13 textColor-light">+ 8 more</p>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex items-center justify-between">
              <h4 className="fsize18 text-dark my-0 font-semibold cust-textColor">
                Category 3
              </h4>
              <p className="fsize12 text-dark textColor-light">Clear</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-5 lg:px-4">
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 2
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 3
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 4
              </Checkbox>
              <Checkbox size="lg" colorScheme={colorMode === 'light' ? "primary" : "gray"} className='textColor-light'>
                Sub Category 5
              </Checkbox>
              <p className="fsize13 textColor-light">+ 8 more</p>
            </div>
          </div>
        </div>
        <div className="lg:w-9/12 md:w-9/12 w-full lg:pl-10 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 items-start lg:gap-8 md:gap-4 gap-6 lg:mt-0 md:mt-0 mt-4">
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="rounded-lg theme-bg themeShadow cursor-pointer">
            <div className="relative">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="rounded-t-lg w-full h-48"
              />
              <div className="absolute category-angle ">
                <div className="bg-primary cust-bgColor borderRadius-3sides py-1 px-4 flex items-center gap-1">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize10 font-semibold clr-fff cust-textColor">
                    bedroom
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'category-angle-left'
                      : 'category-angle-left-dark'
                  }
                ></div>
              </div>
            </div>
            <div className="p-3">
              <div className="bottom-border-e2e8f0">
                <div className="flex justify-between items-center">
                  <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                    2,095
                    <span className="fsize10 clr-f4f5f6 textColor-light">
                      /month
                    </span>
                  </p>

                  <div className="p-2 border rounded-full">
                    <FeatherIcon
                      icon="heart"
                      className="w-4 h-4 cust-textColor"
                    />
                  </div>
                </div>
                <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                  Lorem Ipsum
                </p>
                <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                  Green Valley, So Road, MH
                </p>
              </div>
              <div className="py-3">
                <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      3 beds
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      2 bathrooms
                    </p>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex items-center gap-2 py-2 rounded-full'
                        : ' flex items-center gap-2 py-2 rounded-full'
                    }
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="objext-contain w-6 h-6"
                    />
                    <p className="fsize14 font-semibold cust-textColor">
                      5*7 m<sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default BookingList1;
