import { Stack } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { ProgressBar } from "primereact/progressbar";

const PollSection5 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="1840635012"
          className="dynamicStyle inline-block section_header"
        >
          {data ? data["1840635012"] : "Lorem Ipsum"}
        </h3>
        <ReactQuill
          id="9004885384"
          theme="bubble"
          readOnly
          className="section_detail bubble dynamicStyle lg:w-2/3"
          value={data ? data["9004885384"] : "Lorem Ipsum"}
        />
        <div className="lg:py-8 md:py-8 py-6  lg:w-1/2 md:w-1/2">
          <Stack className="relative lg:mb-6 md:mb-6 mb-4 ">
            <ProgressBar
              colorScheme="gray"
              className="rounded-full themeShadow"
              style={{ height: "42px" }}
              value={50}
            />
            <div className="flex items-center justify-between w-full px-5 absolute top-2.5 left-0">
              <div>
                <p className="text_surface600 fsize15 sm-fsize14">2023-2024</p>
              </div>
              <div>
                <p className="text_surface600 fsize15 sm-fsize14">50%</p>
              </div>
            </div>
          </Stack>
          <Stack className="relative lg:mb-6 md:mb-6 mb-4 ">
            <ProgressBar
              colorScheme="gray"
              className="rounded-full themeShadow"
              style={{ height: "42px" }}
              value={60}
            />
            <div className="flex items-center justify-between w-full px-5 absolute top-2.5 left-0">
              <div>
                <p className="text_surface600 fsize15 sm-fsize14">2024-2025</p>
              </div>
              <div>
                <p className="text_surface600 fsize15 sm-fsize14">60%</p>
              </div>
            </div>
          </Stack>
          <Stack className="relative ">
            <ProgressBar
              colorScheme="gray"
              className="rounded-full themeShadow"
              style={{ height: "42px" }}
              value={72}
            />
            <div className="flex items-center justify-between w-full px-5 absolute top-2.5 left-0">
              <div>
                <p className="text_surface600 fsize15 sm-fsize14">2025-2026</p>
              </div>
              <div>
                <p className="text_surface600 fsize15 sm-fsize14">72%</p>
              </div>
            </div>
          </Stack>
        </div>
        <div className="mt-0 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-3">
              <AvatarGroup>
                <Avatar
                  name="Ryan Florence"
                  shape="circle"
                  image="https://bit.ly/ryan-florence"
                />
                <Avatar
                  name="Segun Adebayo"
                  shape="circle"
                  image="https://bit.ly/sage-adebayo"
                />
                <Avatar
                  name="Kent Dodds"
                  shape="circle"
                  image="https://bit.ly/kent-c-dodds"
                />
                <Avatar
                  name="Prosper Otemuyiwa"
                  shape="circle"
                  image="https://bit.ly/prosper-baba"
                />
                <Avatar
                  name="Christian Nwamba"
                  shape="circle"
                  image="https://bit.ly/code-beast"
                />
              </AvatarGroup>
            </div>
            <div className="flex gap-1">
              <p
                className="fsize12 text_surface600 textColor-light dynamicStyle"
                id="9289728588"
              >
                {data ? data["9289728588"] : "Total votes:"}
              </p>
              <p className="fsize12 text_surface600 textColor-light">24</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollSection5;
