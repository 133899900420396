import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Card } from 'primereact/card';
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard53 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="header_align">
          <p id="2340746310" className="dynamicStyle section_header">
            {data ? data['2340746310'] : 'Lorem ipsum'}
          </p>
          <ReactQuill
            id="9779424305"
            theme="bubble"
            readOnly
            className="bubble2 dynamicStyle section_detail"
            value={data ? data['9779424305'] : 'Welcome to My World'}
          />
        </div>

        <div className="lg:mt-16 md:mt-16 mt-12 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
          <Card className="relative prime_card_0">
            <video controls className="w-full lg:h-72 md:h-72 h-64 rounded-xl">
              <source src="" type="video/mp4" />
            </video>
            <div className="bg-primary absolute bottom-0 left-0 p-2 w-full rounded-b-lg cust-bgColor">
              <p id="5792424898" className="dynamicStyle card_description">
                {data ? data['5792424898'] : 'Lorem ipsum'}
              </p>
              <p id="3281967053" className="dynamicStyle card_title_0">
                {data ? data['3281967053'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="absl7-text">
              <FeatherIcon
                icon="play-circle"
                size={30}
                className="cursor-pointer clr-fff"
              />
            </div>
          </Card>
          <Card className="relative prime_card_0">
            <video controls className="w-full lg:h-72 md:h-72 h-64 rounded-xl">
              <source src="" type="video/mp4" />
            </video>
            <div className="bg-primary absolute bottom-0 left-0 p-2 w-full rounded-b-lg cust-bgColor">
              <p id="5792424898" className="dynamicStyle card_description">
                {data ? data['5792424898'] : 'Lorem ipsum'}
              </p>
              <p id="3281967053" className="dynamicStyle card_title_0">
                {data ? data['3281967053'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="absl7-text">
              <FeatherIcon
                icon="play-circle"
                size={30}
                className="cursor-pointer clr-fff"
              />
            </div>
          </Card>
          <Card className="relative prime_card_0">
            <video controls className="w-full lg:h-72 md:h-72 h-64 rounded-xl">
              <source src="" type="video/mp4" />
            </video>
            <div className="bg-primary absolute bottom-0 left-0 p-2 w-full rounded-b-lg cust-bgColor">
              <p id="5792424898" className="dynamicStyle card_description">
                {data ? data['5792424898'] : 'Lorem ipsum'}
              </p>
              <p id="3281967053" className="dynamicStyle card_title_0">
                {data ? data['3281967053'] : 'Lorem ipsum'}
              </p>
            </div>
            <div className="absl7-text">
              <FeatherIcon
                icon="play-circle"
                size={30}
                className="cursor-pointer clr-fff"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard53;
