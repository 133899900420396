import { Image } from "primereact/image";
import React, { useEffect, useRef, useState } from "react";
import { ArrowRight } from "react-feather";
import { useSelector } from "react-redux/es/exports";

const GridCard2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <p
          id="5539013178"
          className="dynamicStyle font-semibold text-primary textColor-light"
        >
          {data ? data["5539013178"] : "Lorem Ipsum"}
        </p>
        <p id="5941461009" className="dynamicStyle section_header">
          {data ? data["5941461009"] : "Lorem Ipsum generated"}
        </p>
        <div className="lg:mt-12 md:mt-10 mt-8 w-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-x-4 gap-y-8">
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="alt-image"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
              <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="alt-image"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary textColor-light sm-fsize14">Enquire</p>
              <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="alt-image"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
              <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="alt-image"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
              <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="alt-image"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
              <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
            </div>
          </div>
          <div className="">
            <div className="overflow-hidden">
              <Image
                imageClassName="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="alt-image"
              />
            </div>
            <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
              190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
              Fabric)
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
              Multicolor • Cotton • Made in India
            </p>
            <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
            <div className="flex lg:w-1/4 cursor-pointer">
              <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
              <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridCard2;
