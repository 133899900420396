import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup"; //Optional for grouping
import { Checkbox } from "primereact/checkbox";

const PollSection3 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="prime_container">
        <h3
          id="6103710288"
          className="dynamicStyle inline-block  text_surface600 section_header"
        >
          {data ? data["6103710288"] : "Lorem Ipsum"}
        </h3>
        <ReactQuill
          id="3279787129"
          theme="bubble"
          readOnly
          className="section_detail bubble dynamicStyle lg:w-2/3"
          value={data ? data["3279787129"] : "Lorem Ipsum"}
        />
        <div className="lg:py-8 md:py-8 py-6 w-full flex flex-col gap-3 lg:w-1/3 md:w-1/2">
          <div className="inline-flex flex-wrap gap-3 border1 py-2 px-6 rounded-full">
            <Checkbox className="text_surface600"> </Checkbox>
            <label htmlFor="" className="ml-2">
              2021-22
            </label>
          </div>
          <div className="inline-flex flex-wrap gap-3 border1 py-2 px-6 rounded-full">
            <Checkbox className="text_surface600"> </Checkbox>
            <label htmlFor="" className="ml-2">
              2022-23
            </label>
          </div>
          <div className="inline-flex flex-wrap gap-3 border1 py-2 px-6 rounded-full">
            <Checkbox className="text_surface600"> </Checkbox>
            <label htmlFor="" className="ml-2">
              2023-24
            </label>
          </div>
        </div>
        <div className="mt-4 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup>
              <Avatar
                name="Ryan Florence"
                shape="circle"
                image="https://bit.ly/ryan-florence"
              />
              <Avatar
                name="Segun Adebayo"
                shape="circle"
                image="https://bit.ly/sage-adebayo"
              />
              <Avatar
                name="Kent Dodds"
                shape="circle"
                image="https://bit.ly/kent-c-dodds"
              />
              <Avatar
                name="Prosper Otemuyiwa"
                shape="circle"
                image="https://bit.ly/prosper-baba"
              />
              <Avatar
                name="Christian Nwamba"
                shape="circle"
                image="https://bit.ly/code-beast"
              />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text_surface600 dynamicStyle"
                id="1305092389"
              >
                {data ? data["1305092389"] : "Total votes:"}
              </p>
              <p className="fsize12 text_surface600">24</p>
            </div>
          </div>
          {/* <Button
            className="bg-primary cust-bgColor theme-btn clr-fff"
            fontWeight={400}
            borderRadius={2}
          >
            Vote
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default PollSection3;
