import React from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import FeatherIcon from "feather-icons-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";

const Calendar1 = () => {
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="lg:flex md:flex w-full prime_container">
        <div className="lg:w-7/12 md:w-1/2 w-full lg:pr-10 md:pr-4">
          <div className="">
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".image-swiper-button-next2",
                prevEl: ".image-swiper-button-prev2",
              }}
              className="p-1 bg-fa rounded-lg"
              breakpoints={{
                1536: {
                  slidesPerView: 8,
                  spaceBetween: 10,
                },
                1280: {
                  slidesPerView: 8,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 8,
                  spaceBetween: 10,
                },
                991: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
                820: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
                325: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev2 bg-white rounded-full p-1 cursor-pointer fsize12">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="13px"
                  width="13px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next2 bg-white rounded-full p-1 cursor-pointer fsize12">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="13px"
                  width="13px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              <SwiperSlide>
                <div className="p-2 bg-dark text-center rounded-lg">
                  <h6 className="fsize14 sm-fsize12 text-white">8</h6>
                  <h6 className="fsize14 sm-fsize12 text-white">FRI</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">9</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">10</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">11</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">12</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">13</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">14</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">15</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">16</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                  <h6 className="fsize16 sm-fsize13 text-gray">17</h6>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="pt-4 pb-8">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-4">
              <div className="rounded-lg border lg:p-3 md:p-3 p-2">
                <p className="fsize14 sm-fsize12 text-gray">
                  9:00 PM - 10:00PM
                </p>
                <div className="flex items-center gap-1 mt-1">
                  <FeatherIcon icon="clock" size="14" />
                  <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
                </div>
              </div>
              <div className="rounded-lg border lg:p-3 md:p-3 p-2">
                <p className="fsize14 sm-fsize12 text-gray">
                  9:00 PM - 10:00PM
                </p>
                <div className="flex items-center gap-1 mt-1">
                  <FeatherIcon icon="clock" size="14" />
                  <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
                </div>
              </div>
              <div className="rounded-lg border lg:p-3 md:p-3 p-2">
                <p className="fsize14 sm-fsize12 text-gray">
                  9:00 PM - 10:00PM
                </p>
                <div className="flex items-center gap-1 mt-1">
                  <FeatherIcon icon="clock" size="14" />
                  <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
                </div>
              </div>
              <div className="rounded-lg border lg:p-3 md:p-3 p-2">
                <p className="fsize14 sm-fsize12 text-gray">
                  9:00 PM - 10:00PM
                </p>
                <div className="flex items-center gap-1 mt-1">
                  <FeatherIcon icon="clock" size="14" />
                  <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
                </div>
              </div>
              <div className="rounded-lg border lg:p-3 md:p-3 p-2">
                <p className="fsize14 sm-fsize12 text-gray">
                  9:00 PM - 10:00PM
                </p>
                <div className="flex items-center gap-1 mt-1">
                  <FeatherIcon icon="clock" size="14" />
                  <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
                </div>
              </div>
              <div className="rounded-lg border lg:p-3 md:p-3 p-2">
                <p className="fsize14 sm-fsize12 text-gray">
                  9:00 PM - 10:00PM
                </p>
                <div className="flex items-center gap-1 mt-1">
                  <FeatherIcon icon="clock" size="14" />
                  <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 flex items-center gap-6 bordbl">
            <div className="flex items-center gap-2">
              <FeatherIcon
                icon="clock"
                className="text-gray border rounded-sm p-1"
              />
              <p className="fsize16 text-gray font-medium">Booked Slots</p>
            </div>
            <div className="flex items-center gap-2">
              <FeatherIcon
                icon="clock"
                className="text-gray border rounded-sm p-1"
              />
              <p className="fsize16 text-gray font-medium">Available Slots</p>
            </div>
          </div>
          <div className="mt-4 grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 items-center gap-6">
            <div>
              <h5 className="card_title">Type of Session</h5>
              <div className="flex items-center gap-4 mt-2">
                <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                  <Button className="text-black">Online</Button>
                </div>
                <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                  <Button className="text-black">Offline</Button>
                </div>
              </div>
            </div>
            <div>
              <h5 className="card_title">Select Location</h5>
              <div className="flex items-center gap-4 mt-2">
                <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                  <Button className="text-black">GYM</Button>
                </div>
                <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                  <Button className="text-black">OUTSIDE</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-5/12 md:w-1/2 w-full lg:pl-0 md:pl-4 pl-0 lg:mt-0 md:mt-0 mt-6">
          <Card className="prime_card">
            <div className="flex justify-center">
              <div>
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="booking"
                  imageClassName="lg:w-32 lg:h-32 md:w-32 md:h-32 w-24 h-24 rounded-full mx-auto"
                />
                <h4 className="card_title_0 mt-4 text-center capitalize">
                  Manish Sharma
                </h4>
                <p className="card_description text-center capitalize">
                  Lorem Ipsum
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-6">
              <div className="flex gap-3 items-center">
                <label className="fsize14 sm-fsize12 w-1/3">
                  Booking Time:
                </label>
                <InputText placeholder="Enter" type="date" className="w-2/3" />
              </div>
              <div className="flex gap-3 items-center">
                <label className="fsize14 sm-fsize12 w-1/3">
                  Booking Date:
                </label>
                <InputText placeholder="Enter" type="time" className="w-2/3" />
              </div>
              <div className="flex gap-3 items-center">
                <label className="fsize14 sm-fsize12 w-1/3">
                  Section Type:
                </label>
                <InputText placeholder="Enter" className="w-2/3" />
              </div>
            </div>
            <div className="mt-8">
              <Button className="w-full px-6 py-3 flex justify-center sm-fsize14 b_button_primary">
                Book Appointtment
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Calendar1;
