import React, { useEffect, useState, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import {
  loginwithPassword,
  createMFA,
  verifyMFA,
  ToggleAccount,
  generateOTP,
  SaveBasicProfileDetails,
} from '../../../redux/auth/auth-action';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import FeatherIcon from 'feather-icons-react';
import { Checkbox } from 'primereact/checkbox';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import OTPInput, { ResendOTP } from 'otp-input-react';
import OtherAuth from '../../auth/OtherAuth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
// import { getMetaByPageName } from '../../../redux/cms/cms-actions';
import SEO from '../../../cms/SEO';

const Login = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  let userData = useSelector(state => state.AuthDetails.user);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [show, setShow] = useState(false);
  const [MFA, setMFA] = useState(0);
  const handleClick = () => setShow(!show);
  const [otp, setOTP] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [mfaPrompt, setMfaPrompt] = useState(false);
  const [qrImage, setQrImage] = useState('');
  const [OTPActivate, setOTPActivate] = useState('');
  const [isActivating, setIsActivating] = useState(false);

  //===========SEO============
  useEffect(() => {
    //   const getSEO = async () => {
    //     let data = await dispatch(getMetaByPageName({ pageName: "Login" }));
    //     if (data.code === 200) {
    //       setMetaData(data.data);
    //     }
    //   };
    //   getSEO();

    setMFA(0);
  }, []);
  //===========SEO============

  const initialValues = {
    email: '',
    password: '',
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, 'Email must be at least 5 characters')
      .max(50, 'Email must be at most 100 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      email: values.email.toLowerCase(),
      password: values.password,
      isVerified: isVerified,
    };
    try {
      let data = await dispatch(loginwithPassword(payload));

      if (data && data.code == 200) {
        // toast.current.show({
        //   severity: 'success',
        //   summary: 'Success',
        //   detail: `Welcome back ${data.data.userAuth.firstName}`,
        //   life: 3000,
        // });
        data.data.userAuth.mfaSecret != '' && data.data.userAuth.mfaPrompt
          ? setMFA(1)
          : data.data.userAuth.isMfaActive && data.data.userAuth.mfaSecret != ''
          ? setMFA(4)
          : !data.data.userAuth.isMfaActive &&
            data.data.userAuth.mfaSecret != ''
          ? navigate('/')
          : data.data.userAuth.mfaPrompt
          ? setMFA(1)
          : navigate('/');
        if (!data.data.userAuth.isMfaActive) {
          Swal.fire({
            title: 'Success',
            text: `Welcome back ${data.data.userAuth.firstName}`,
            icon: 'success',
            timer: '2500',
          });
        }
        formik.handleReset();
        // navigate('/');
      } else {
        if (data.message === 'Account is deactivated' && data.code === 401) {
          return await GetEmailOTP();
        }
        if (data.message) {
          // Swal.fire({
          //   title: 'Error',
          //   text: data.message,
          //   icon: 'error',
          // });
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: data.message,
            life: 3000,
          });
        }
      }
      setOTP();
    } catch (error) {
      // Swal.fire({
      //   title: 'Error',
      //   text: 'Something went wrong!',
      //   icon: 'error',
      // });

      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong!',
        life: 3000,
      });
    }
  };

  const handleCreateMFA = async values => {
    try {
      let payload = {
        email: userData.email,
      };
      const data = await dispatch(createMFA(payload));
      setQrImage(data.data.qr);
      setMFA(2);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMFA_Update = async values => {
    try {
      let payload = {
        mfaPrompt: !mfaPrompt,
      };
      mfaPrompt && (await dispatch(SaveBasicProfileDetails(payload)));
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerify = async values => {
    try {
      let payload = {
        email: userData.email,
        token: otp,
        login: true,
      };
      const data = await dispatch(verifyMFA(payload));

      if (data.status) {
        setVerified(data.status);
        navigate('/ ');
        // Swal.fire({
        //   title: 'Success',
        //   text: `Welcome back ${userData.firstName}`,
        //   icon: 'success',
        //   timer: '2500',
        // });
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `Welcome back ${userData.firstName}`,
          life: 3000,
        });
      } else {
        // Swal.fire({
        //   title: 'Error',
        //   text: 'Invalid OTP',
        //   icon: 'error',
        // });
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Invalid OTP',
          life: 3000,
        });
      }
      setOTP();
    } catch (error) {
      console.log(error);
    }
  };

  const ActivateOtp = async () => {
    try {
      console.log('ActivateOtp');
      if (!formik.values.password) {
        return;
      }

      let payload = {
        email: formik.values.email.toLowerCase(),
        password: formik.values.password,
        otp: OTPActivate,
      };

      let data = await dispatch(ToggleAccount(payload, 'Activate'));
      if (data && data.code == 200) {
        setOTPActivate('');
        // navigate("/login");
        await handleOnSubmit(formik.values);
        // await Swal.fire({
        //   title: 'Success',
        //   text: `Account activated successfully`,
        //   icon: 'success',
        //   timer: '2500',
        // });

        await toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `Account activated successfully`,
          life: 3000,
        });
        // toast.success(`Account activated successfully`);
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: 'Error',
          //   text: data.message,
          //   icon: 'error',
          // });

          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: data.message,
            life: 3000,
          });
          // toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: 'Error',
      //   text: 'Something went wrong!',
      //   icon: 'error',
      // });

      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong!',
        life: 3000,
      });
      // toast.error("Something went wrong!");
    }
  };

  const GetEmailOTP = async () => {
    // let emailValid = validateField("email", formik.values.email);
    // let mobileValid = validateField("mobile", formik.values.mobile);
    if (!formik.values.email) {
      return;
    }
    let payload = {
      // mobile: formik.values.mobile,
      email: formik.values.email.toLowerCase(),
      type: 'AccountActivation',
    };

    try {
      // setIsActivating(true);
      let data = await dispatch(generateOTP(payload));
      if (data && data.code === 200) {
        // Swal.fire({
        //   title: 'Account is Deactivated',
        //   text: 'OTP successfully sent to your email ID',
        //   icon: 'success',
        //   timer: '2500',
        // });

        toast.current.show({
          severity: 'Account is Deactivated',
          summary: 'Success',
          detail: `OTP successfully sent to your email ID`,
          life: 3000,
        });
        // toast.success("OTP successfully sent to your email ID");
        setIsActivating(true);
      } else {
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
          // toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: 'Error',
      //   text: 'Something went wrong!',
      //   icon: 'error',
      // });

      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong!',
        life: 3000,
      });
      // toast.error("Something went wrong!");w
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <>
      <SEO metaData={metaData} />
      <Toast ref={toast} position="bottom-right" />
      <section className="container px-5 mx-auto lg:h-screen md:h-screen lg:px-12 md:px-8">
        <div className="items-center h-full gap-4 lg:flex md:flex">
          <div
            id="4805490279"
            className="hidden dynamicStyle2 lg:w-1/2 md:w-1/2 lg:block md:block"
          >
            <Image
              maxH="600"
              src={data ? getImage(data['4805490279']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="loginImage"
              imageClassName="m-auto"
            />
          </div>

          {MFA == 0 ? (
            <div className="px-5 py-8 smcust-shadow lg:w-1/2 md:w-1/2 lg:py-4 md:py-4 lg:px-12 md:px-8 ">
              <div className="">
                <h1
                  fontSize="4xl"
                  id="4130656747"
                  className="text-2xl font-semibold text-center dynamicStyle lg:text-4xl md:text-2xl"
                >
                  {data ? data['4130656747'] : 'LOGIN'}
                </h1>

                <p className="mt-2 text-sm text-center lg:text-base md:text-base lg:mt-4 md:mt-4">
                  <span
                    id="1084915749"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['1084915749'] : 'no-data',
                    }}
                    className="dynamicStyle text333 opacity-70"
                  ></span>
                  <span className="font-semibold underline cursor-pointer">
                    Term of use
                  </span>
                  <span className="opacity-70">and</span>
                  <span className="font-semibold underline cursor-pointer">
                    Privacy policy
                  </span>
                </p>

                <form onSubmit={formik.handleSubmit}>
                  <div className="mt-8 lg:mt-16 md:mt-8">
                    <div className="mb-4 lg:mb-6 md:mb-4">
                      <h3
                        id="7829710743"
                        className="text-base font-semibold dynamicStyle lg:text-xl md:text-base text333"
                      >
                        {data ? data['7829710743'] : 'Email Address'}
                      </h3>

                      <InputText
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                        invalid={!!formik.errors.email && formik.touched.email}
                        className="w-full mt-2 bg_surface50 p-inputtext"
                        placeholder="Enter your email address"
                      />
                      {formik.touched.email && formik.errors.email && (
                        <p className="mt-2 text-danger fsize12">
                          {formik.errors.email}
                        </p>
                      )}
                    </div>

                    <div>
                      <h3
                        id="9378435345"
                        fontSize="xl"
                        className="text-base font-semibold dynamicStyle lg:text-xl md:text-base text333 "
                      >
                        {data ? data['9378435345'] : 'Password'}
                      </h3>
                      <Password
                        name="password"
                        toggleMask
                        value={formik.values.password}
                        feedback={false}
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                        invalid={
                          !!formik.errors.password && formik.touched.password
                        }
                        className="mt-2 bg_surface50 prime_password"
                        placeholder="Enter your password"
                      />

                      {/* <div
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        
                        {show ? (
                          <FeatherIcon
                            icon="eye"
                            size={16}
                            onClick={() => {
                              handleClick();
                            }}
                          />
                        ) : (
                          <FeatherIcon
                            size={16}
                            icon="eye-off"
                            onClick={() => {
                              handleClick();
                            }}
                          />
                        )}
                      </div> */}

                      {formik.touched.password && formik.errors.password && (
                        <p className="mt-2 text-danger fsize12">
                          {formik.errors.password}
                        </p>
                      )}
                    </div>
                    {isActivating ? (
                      <>
                        <p className="mt-4 fsize14">
                          Please enter the OTP sent on your email to re-activate
                          your account.
                        </p>
                        <div className="pt-2">
                          <OTPInput
                            name="otp"
                            value={OTPActivate}
                            onChange={e => {
                              setOTPActivate(e);
                            }}
                            OTPLength={6}
                            otpType="number"
                            className="numb-otp"
                            inputclassName="themefamily"
                          />
                          <div className="mt-2">
                            <ResendOTP onResendClick={() => GetEmailOTP()} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="pt-4">
                        <span className="underline cursor-pointer fsize15 custcolor">
                          <Link to="/forgot password"> Forgot Password?</Link>
                        </span>
                      </div>
                    )}
                    {isActivating ? (
                      <div>
                        <Button
                          isDisabled={
                            OTPActivate.toString().length === 6 ? false : true
                          }
                          className="w-full mt-8 sm-fsize16px b_button_primary"
                          size="lg"
                          onClick={() => ActivateOtp()}
                        >
                          Activate
                        </Button>
                      </div>
                    ) : (
                      <Button
                        className="flex justify-center w-full mt-8 dynamicStyle b_button_primary sm-fsize16px buttonAnimate bdr-2px"
                        id="6896927244"
                        type="submit"
                      >
                        {data ? data['6896927244'] : 'Login'}
                      </Button>
                    )}
                  </div>
                </form>

                <p className="mt-4 text-sm text-center lg:text-base md:text-base">
                  <span id="5028680478" className="dynamicStyle">
                    {data ? data['5028680478'] : 'Create a New Account?'}
                  </span>
                  <span className="pl-2 font-semibold underline cursor-pointer">
                    <Link to="/register">Register</Link>
                  </span>
                </p>

                {/* <Box className="sm-px-30px" position="relative" padding="10">
                    <Divider />
                    <AbsoluteCenter bg="white" px="4">
                      <p id="8012950153" className="dynamicStyle">
                        
                        {data ? data['8012950153'] : 'or'}
                      </p>
                    </AbsoluteCenter>
                  </Box> */}

                <OtherAuth />
              </div>
            </div>
          ) : MFA == 1 ? (
            <div className="flex items-center justify-center lg:w-1/2 md:w-1/2">
              <div className="w-full">
                <div className="flex items-center gap-2 mb-4">
                  <Checkbox onChange={e => setMfaPrompt(e.target.checked)} />
                  <p className="">
                    Don't show this setup again.
                    {/* You can setup MFA from your
                  profile settings. */}
                  </p>
                </div>
                <div className="flex gap-4">
                  <Button
                    onClick={() => {
                      handleCreateMFA();
                    }}
                    className="b_button_primary px-6 py-2"
                  >
                    Setup MFA
                  </Button>
                  <Button
                    onClick={() => {
                      handleMFA_Update();
                    }}
                    className="b_button_primary px-6 py-2"
                  >
                    Skip
                  </Button>
                </div>
              </div>
            </div>
          ) : MFA == 2 ? (
            <div className="flex items-center justify-center lg:w-1/2 md:w-1/2">
              <div>
                <div className="mb-6">
                  <Image
                    maxH="180"
                    src={qrImage}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="loginImage"
                    className="m-auto"
                  />
                </div>
                <OTPInput
                  name="otp"
                  value={otp}
                  onChange={e => {
                    setOTP(e);
                  }}
                  OTPLength={6}
                  otpType="number"
                  className="numb-otp"
                  inputclassName="themefamily"
                />
                <div className="flex gap-2 mt-6">
                  <Button
                  className="b_button_primary px-6 py-2"
                    onClick={() => otp.length == 6 && handleVerify()}
                  >
                    Verify
                  </Button>
                  <Button
                    className="b_button_primary px-6 py-2"
                    onClick={() => {
                      setMFA(1);
                    }}
                  >
                    Skip
                  </Button>
                </div>
              </div>
            </div>
          ) : MFA == 4 ? (
            <div className="flex items-center justify-center w-full lg:w-1/2 md:w-1/2">
              <div>
                <h2 className="mb-4 font-semibold fsize20">Enter OTP</h2>
                <OTPInput
                  name="otp"
                  value={otp}
                  onChange={e => {
                    setOTP(e);
                  }}
                  OTPLength={6}
                  otpType="number"
                  className="numb-otp"
                  inputclassName="themefamily"
                />
                <Button
                  className="flex items-center mt-6 b_button_primary px-6 py-2"
                  onClick={() => otp.length == 6 && handleVerify()}
                >
                  Verify
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  );
};

export default Login;
