import React from 'react';
import { Box, Skeleton, SkeletonText } from '@chakra-ui/react';

const SkeletonCard3ImageandText3 = () => {
  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-6 gap-8 ">
      <Box padding="6" boxShadow="lg" bg="white">
        <Skeleton
          width="120px"
          height="120px"
          margin="auto"
          borderRadius={60}
        />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
      <Box
        padding="6"
        boxShadow="lg"
        bg="white"
        className="lg:block md:block hidden"
      >
        <Skeleton
          width="120px"
          height="120px"
          margin="auto"
          borderRadius={60}
        />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
      <Box
        padding="6"
        boxShadow="lg"
        bg="white"
        className="lg:block md:hidden hidden"
      >
        <Skeleton
          width="120px"
          height="120px"
          margin="auto"
          borderRadius={60}
        />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
    </div>
  );
};

export default SkeletonCard3ImageandText3;
