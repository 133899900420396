import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';

const AboutSection4 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="w-full lg:flex gap-8">
          <div className="lg:w-1/2  w-full">
            <div
              ref={elementRef}
              className={`fade-left w-full h-full ${
                isVisible ? 'visibleleft' : ''
              } `}
            >
              <div id="7347724826" className="dynamicStyle2">
                <Image
                  src={data ? getImage(data['7347724826']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="about"
                  imageClassName="object-cover w-full h-full md-h-350px br-28px border-top12px"
                  style={{ transform: transform }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 lg:mb-0 md:mt-6 mt-6 w-full">
            <p
              id="4101440900"
              className="dynamicStyle lg:pt-0 md:pt-0 pt-4 uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-primary"
            >
              {data ? data['4101440900'] : 'Lorem Ipsum'}
            </p>
            <h2 id="4160252202" className="dynamicStyle about_title">
              {data ? data['4160252202'] : 'Lorem Ipsum'}
            </h2>
            <ReactQuill
              id="8330960685"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle card_description"
              value={data ? data['8330960685'] : 'Lorem Ipsum'}
            />

            <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-4 mt-6">
              <Card className="prime_card themeShadow theme-bg">
                <h2 id="3124594170" className="dynamicStyle card_title">
                  {data ? data['3124594170'] : 'When We Started'}
                </h2>
                <ReactQuill
                  id="9329055318"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description"
                  value={data ? data['9329055318'] : 'Lorem Ipsum'}
                />
              </Card>
              <Card className="prime_card themeShadow theme-bg">
                <h2 id="9613322167" className="dynamicStyle card_title">
                  {data ? data['9613322167'] : 'Lorem Ipsum'}
                </h2>
                <ReactQuill
                  id="0112669977"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description"
                  value={data ? data['0112669977'] : 'Lorem Ipsum'}
                />
              </Card>
              <Card className="prime_card themeShadow theme-bg">
                <h2 id="3145495928" className="dynamicStyle card_title">
                  {data ? data['3145495928'] : 'Lorem Ipsum'}
                </h2>
                <ReactQuill
                  id="3975286171"
                  theme="bubble"
                  readOnly
                  className="bubble dynamicStyle card_description"
                  value={data ? data['3975286171'] : 'Lorem Ipsum'}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection4;
