import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection24 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="relative dynamicStyle2" id="2663152187" style={{ background: thisComponent.sectionColor }}> 
      <Image
        src={data ? getImage(data["2663152187"]) : ""}
        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        alt="elearning-banner"
        imageClassName="h-400px sm-h300px w-full"
      />
      <div className="absolute top-0 left-0 h-400px sm-h300px w-full flex justify-start items-center">
        <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12">
          <div className="prime_container">
            <h2 className="hero_title dynamicStyle" id="0052917247">
              {data ? data["0052917247"] : "Lorem Ipsum"}
            </h2>
            <ReactQuill
              id="6659004738"
              theme="bubble"
              readOnly
              className="bubble card_description"
              value={data ? data["6659004738"] : "Lorem Ipsum"}
            />

            <div className="lg:mt-8 md:mt-8 mt-6">
              <Button
                className="sm-fsize14 theme-btn b_button_primary px-6 py-2 dynamicStyle"
                id="2681484761"
              >
                <span onClick={() => handleClick("2681484761")}>
                  {data ? data["2681484761"] : "Register to Join"}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection24;
