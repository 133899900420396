import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useSelector } from "react-redux/es/exports";
import "swiper/css/pagination";
import FeatherIcon from "feather-icons-react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const HerosectionSwiper1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
        //section color start
        const [fileName, setFileName] = useState("");
        const [thisComponent, setThisComponent] = useState({});
        const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
        const selectedSection = useSelector((state) => state.cms.sectionNames);
        //sectioncolor end
        //sectioncolor start
        useEffect(() => {
          const name = new URL(import.meta.url);
          const filename = name.pathname.split("/").pop();
          console.log(filename);
          const componentName = filename.split(".")[0];
          setFileName(componentName);
      
          for (let i = 0; i < selectedSection[pageName].length; i++) {
            if (
              selectedSection[pageName][i].path.includes(componentName.split(".")[0])
            ) {
              setThisComponent(selectedSection[pageName][i]);
            }
          }
        }, [selectedSection]);
        //sectioncolor end
  return (
    <section className="direction" style={{ background: thisComponent.sectionColor }}>
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        observer={true}
        observeParents={true}
        className="mySwiper"
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="relative home-h">
            <div
              id="6073987465"
              className="dynamicStyle2 cus-bannerdynamic w-full"
            >
              <Image
                src={data ? getImage(data["6073987465"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                imageClassName="w-full home-h object-cover"
              />
            </div>
            <div className="absolute top-0 left-0 flex items-center home-h w-full">
              <div className="lg:px-20 md:px-8 px-5">
                <div className="prime_container">
                  <div className="lg:w-6/12">
                    <small
                      className="fsize23 sm-fsize15 text-left text-white dynamicStyle"
                      id="8037593494"
                    >
                      {data ? data["8037593494"] : "Lorem Ipsum"}
                    </small>
                    <h2 className="hero_title dynamicStyle" id="2610796810">
                      {data ? data["2610796810"] : "Lorem Ipsum"}
                    </h2>

                    <ReactQuill
                      id="9889936518"
                      theme="bubble"
                      readOnly
                      className="bubble2 dynamicStyle card_description"
                      value={data ? data["9889936518"] : "Lorem Ipsum"}
                    />

                    <Button
                      outlined
                      className="mt-6 sm-fsize14 dynamicStyle px-6 py-2"
                      id="7507140849"
                    >
                      {data ? data["7507140849"] : "Lorem Ipsim"}
                      <FeatherIcon
                        className="text-white ml-3"
                        size={18}
                        icon="arrow-right"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative home-h">
            <div
              id="7567896530"
              className="dynamicStyle2 cus-bannerdynamic w-full"
            >
              <Image
                src={data ? getImage(data["7567896530"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                className="w-full home-h object-cover"
              />
            </div>
            <div className="absolute top-0 left-0 flex items-center home-h w-full">
              <div className="lg:px-20 md:px-8 px-5">
                <div className="prime_container">
                  <div className="lg:w-6/12">
                    <small
                      className="fsize23 sm-fsize15 text-left text-white dynamicStyle"
                      id="9312501427"
                    >
                      {data
                        ? data["9312501427"]
                        : "STAY INFORMED WITH OUR LATEST ARTICLES"}
                    </small>
                    <h2 className="hero_title dynamicStyle" id="8558692349">
                      {data
                        ? data["8558692349"]
                        : "STAY INFORMED WITH OUR LATEST ARTICLES"}
                    </h2>

                    <ReactQuill
                      id="1551296061"
                      theme="bubble"
                      readOnly
                      className="bubble2 dynamicStyle card_description"
                      value={data ? data["1551296061"] : "Lorem Ipsum"}
                    />

                    <Button
                      outlined
                      className="mt-6 sm-fsize14 dynamicStyle px-6 py-2"
                      id="4172522824"
                    >
                      {data
                        ? data["4172522824"]
                        : "STAY INFORMED WITH OUR LATEST ARTICLES"}
                      <FeatherIcon
                        className="text-white ml-3"
                        size={18}
                        icon="arrow-right"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HerosectionSwiper1;
