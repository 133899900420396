import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";
import { Card } from "primereact/card";

const HelpSupport1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <>
      <div className="h-300px sm-h-250px flex items-center justify-center bg-primary theme-bg themeShadow">
        <div className="header_align">
          <h3 id="8674476617" className="dynamicStyle section_header clr-fff">
            {data ? data["8674476617"] : "Lorem Ipsum"}
          </h3>
          <ReactQuill
            id="8563695398"
            theme="bubble"
            readOnly
            className="section_detail bubble2 dynamicStyle"
            value={data ? data["8563695398"] : "Lorem Ipsum"}
          />
        </div>
      </div>
      <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
        <div className="prime_container">
          <div id="6445661510" className="dynamicStyle">
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              className="mySwiper"
              breakpoints={{
                1536: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                820: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                325: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {data && data["6445661510"]
                ? data["6445661510"].map((e) => {
                    return (
                      <SwiperSlide>
                        <Card className="prime_card themeShadow theme-bg">
                          <Image
                            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                            alt="img-help"
                            imageClassName="h-20 w-20 mb-4 mx-auto"
                          />
                          <div className="text-center">
                            <p className="card_title">UI/UX</p>
                            <p className="card_description">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit.
                            </p>
                            <p className="mt-6 font-semibold">Read more</p>
                          </div>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : "no-data"}
            </Swiper>
          </div>
          <div className="header_align lg:pt-14 md:pt-10 pt-8">
            <h3 id="9278240054" className="dynamicStyle section_header">
              {data ? data["9278240054"] : "Lorem ipsum"}
            </h3>
            <ReactQuill
              id="7011425847"
              theme="bubble"
              readOnly
              className="section_detail bubble2 dynamicStyle"
              value={data ? data["7011425847"] : "Lorem Ipsum"}
            />
          </div>
          <div className="lg:pt-16 md:pt-12 pt-8 ">
            <Accordion id="5872416536" className="dynamicStyle" allowToggle>
              {data && data["5872416536"]
                ? data["5872416536"].map((e) => {
                    return (
                      <AccordionItem
                        mb={4}
                        className="bg-dcdcdc theme-bg themeShadow theme-border-0"
                      >
                        {({ isExpanded }) => (
                          <>
                            <h2>
                              <AccordionButton className="cust-textColor">
                                <Box as="span" flex="1" p={1} textAlign="left">
                                  {e.field1}
                                </Box>
                                {isExpanded ? (
                                  <MinusIcon fontSize="12px" />
                                ) : (
                                  <AddIcon fontSize="12px" />
                                )}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel
                              pb={4}
                              dangerouslySetInnerHTML={{
                                __html: e.field2,
                              }}
                              className="sm-fsize14"
                            ></AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    );
                  })
                : "no-data"}
            </Accordion>
          </div>
        </div>
      </div>

      <div className="h-32 bg-dcdcdc relative themeShadow theme-bg">
        <div className="help1-absl sm-help1-absl">
          <div className="lg:flex md:flex justify-between gap-10 items-center bg-fff lg:py-6 md:py-6 py-4 lg:px-6 md:px-6 px-4 cust-shadow rounded-lg themeShadow theme-bg">
            <div className="lg:mb-0 md:mb-0 mb-4">
              <p id="7704852264" className="dynamicStyle card_title_0">
                {data ? data["7704852264"] : "Lorem Ipsum"}
              </p>
              <ReactQuill
                id="3632002229"
                theme="bubble"
                readOnly
                className="card_description bubble dynamicStyle"
                value={data ? data["3632002229"] : "Lorem Ipsum"}
              />
            </div>
            <div className="">
              <Button
                className="dynamicStyle w-full b_button_primary px-6 py-2 theme-btn sm-fsize14 md-fsize14"
                id="4174864605"
              >
                <span onClick={() => handleClick("4174864605")}>
                  {data ? data["4174864605"] : "Submit"}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpSupport1;
