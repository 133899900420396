import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Input,
  Image,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { FiFilter } from 'react-icons/fi';
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useDispatch } from 'react-redux';
import { getAllProducts } from '../../../../redux/ecomProduct/products-action';
import { getAllTags } from '../../../../redux/ecomTag/tags-action';
import { getAllCategorys } from '../../../../redux/ecomCategory/category-action';
import { getAllSubCategorys } from '../../../../redux/ecomSubCategory/subcategory-action';
import ReactPaginate from 'react-paginate';

const ProductList3 = ({ pageName }) => {
      //section color start
      const [fileName, setFileName] = useState("");
      const [thisComponent, setThisComponent] = useState({});
      const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
      const selectedSection = useSelector((state) => state.cms.sectionNames);
      //sectioncolor end
      //sectioncolor start
      useEffect(() => {
        const name = new URL(import.meta.url);
        const filename = name.pathname.split("/").pop();
        console.log(filename);
        const componentName = filename.split(".")[0];
        setFileName(componentName);
    
        for (let i = 0; i < selectedSection[pageName].length; i++) {
          if (
            selectedSection[pageName][i].path.includes(componentName.split(".")[0])
          ) {
            setThisComponent(selectedSection[pageName][i]);
          }
        }
      }, [selectedSection]);
      //sectioncolor end
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();

  const [heartFill, setHeartFill] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleHeartFill = () => {
    setHeartFill(!heartFill);
  };
  const AllTagsData = useSelector(state => state.tags.Tags);
  console.log(AllTagsData, 'AllTagsData');

  const allEcommProduct = useSelector(state => state.products.Products);
  console.log(allEcommProduct, 'allEcommProduct');

  const AllCategoryData = useSelector(state => state.categorys.Categorys);
  console.log(AllCategoryData, 'AllCategoryData');

  const AllSubCategoryData = useSelector(
    state => state.subCategorys.SubCategorys
  );
  console.log(AllSubCategoryData, 'AllSubCategoryData');

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllTags());
    dispatch(getAllCategorys());
    dispatch(getAllSubCategorys());
  }, []);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const navigate = useNavigate();

  const handleProductOverview = () => {
    navigate('/ecommerce/product-overview');
  };

  const AddToWishlist = () => {
    navigate('/ecommerce/wishlist');
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="border-0">
          <ModalHeader className="bg-fff">Sedan Cart</ModalHeader>
          <ModalCloseButton className="mt-2 cust-shadow" />
          <ModalBody >
            <div className='bdr-top-1px py-6'>
              <div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'flex gap-3 themeShadow mb-4 py-2'
                      : 'flex gap-3 themeShadow mb-4 py-2'
                  }
                >
                  <div className="w-1/4">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="img"
                      className="w-20 h-20 rounded-md bdr-1px-dcdcdc"
                    />
                  </div>
                  <div className="w-3/4">
                    <div className="flex justify-between items-center">
                      <div>
                        <p
                          className={
                            colorMode === 'light'
                              ? 'font-semibold fsize18 sm-fsize16 text-gray-400'
                              : 'font-semibold fsize18 sm-fsize16 cust-textColor'
                          }
                        >
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="p-2 bg-f4f4f4 rounded-md cursor-pointer">
                        <FeatherIcon
                          icon="trash-2"
                          className="cust-textColor textred"
                          size={18}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'flex gap-3 themeShadow mb-4 py-2'
                      : 'flex gap-3 themeShadow mb-4 py-2'
                  }
                >
                  <div className="w-1/4">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="img"
                      className="w-20 h-20 rounded-md bdr-1px-dcdcdc"
                    />
                  </div>
                  <div className="w-3/4">
                    <div className="flex justify-between items-center">
                      <div>
                        <p
                          className={
                            colorMode === 'light'
                              ? 'font-semibold fsize18 sm-fsize16 text-gray-400'
                              : 'font-semibold fsize18 sm-fsize16 cust-textColor'
                          }
                        >
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="p-2 bg-f4f4f4 rounded-md cursor-pointer">
                        <FeatherIcon
                          icon="trash-2"
                          className="cust-textColor textred"
                          size={18}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'flex gap-3 themeShadow mb-4 py-2'
                      : 'flex gap-3 themeShadow mb-4 py-2'
                  }
                >
                  <div className="w-1/4">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="img"
                      className="w-20 h-20 rounded-md bdr-1px-dcdcdc"
                    />
                  </div>
                  <div className="w-3/4">
                    <div className="flex justify-between items-center">
                      <div>
                        <p
                          className={
                            colorMode === 'light'
                              ? 'font-semibold fsize18 sm-fsize16 text-gray-400'
                              : 'font-semibold fsize18 sm-fsize16 cust-textColor'
                          }
                        >
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="p-2 bg-f4f4f4 rounded-md cursor-pointer">
                        <FeatherIcon
                          icon="trash-2"
                          className="cust-textColor textred"
                          size={18}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-6'>
                <p className="fsize20 sm-fsize18 font-semibold text-secondary">
                  Add Suggested Services
                </p>
                <div className="grid grid-cols-3 gap-6 mt-4">
                  <div>
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="h-24 w-full mb-2 rounded-xl"
                    />
                    <p className="text-gray-400">
                      Dragon PPF <span className="text-black">₹149</span>
                    </p>
                  </div>
                  <div>
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="h-24 w-full mb-2 rounded-xl"
                    />
                    <p className="text-gray-400">
                      Dragon PPF <span className="text-black">₹149</span>
                    </p>
                  </div>
                  <div>
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="h-24 w-full mb-2 rounded-xl"
                    />
                    <p className="text-gray-400">
                      Dragon PPF <span className="text-black">₹149</span>
                    </p>
                  </div>
                  <div>
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt=""
                      className="h-24 w-full mb-2 rounded-xl"
                    />
                    <p className="text-gray-400">
                      Dragon PPF <span className="text-black">₹149</span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
              <p className='bg-primary clr-fff rounded-md p-2 text-center'>Estimated Days: 5 Days</p>
              <Button
              className="theme-btn b_button_secondary px-6 py-2 rounded-full sm-fsize14 rounded-lg w-full mt-10"
            >
              Proceed
            </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8" style={{ background: thisComponent.sectionColor }}>
        <div className="lg:flex md:flex gap-4">
          <div
            className={
              colorMode === 'light'
                ? 'filtercard1 lg:w-1/4 md:w-1/4 h-100per mob-hidden themeShadow theme-bg'
                : 'filtercard-dark1  border-1px lg:w-1/4 md:w-1/4 h-100per mob-hidden cust-bgColor themeShadow theme-bg'
            }
          >
            <div className="w-full">
              <div
                className={
                  colorMode === 'light'
                    ? 'flex justify-between items-center lg:px-6 px-4 bdr-bottom-e9e9ed'
                    : 'flex justify-between items-center lg:px-6 px-4 bdr-bottom-e9e9ed cust-bgColor'
                }
              >
                <h2
                  id="1632304012"
                  className="dynamicStyle fsize18 font-semibold cust-textColor py-3"
                >
                  {data ? data['1632304012'] : 'Filter'}
                </h2>
                <div className="pl-4 py-3 bdr-left-f7f6f6">
                  <FeatherIcon
                    icon="refresh-ccw"
                    className="cust-textColor "
                    size={20}
                  />
                </div>
              </div>

              <div
                className={
                  colorMode === 'light'
                    ? 'py-4 lg:px-6 px-4 bg-fdf3f3 theme-bg'
                    : 'py-4 lg:px-6 px-4 cust-bgColor theme-bg'
                }
              >
                <h2
                  id="4677201159"
                  className="dynamicStyle fsize18 font-semibold cust-textColor"
                >
                  {data ? data['4677201159'] : 'Category'}
                </h2>
                <div className="grid grid-cols-1 gap-2 mt-3">
                  {AllCategoryData &&
                  AllCategoryData.result &&
                  AllCategoryData.result.length > 0
                    ? AllCategoryData.result.map(cat => {
                        return (
                          <div className="flex items-center gap-3 textColor-light text-black">
                            <Checkbox size="lg">{cat.name}</Checkbox>
                          </div>
                        );
                      })
                    : 'no-category'}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 flex justify-between items-center">
            <div className="lg:hidden md:hidden block filterr">
              <Menu>
                <MenuButton as={Button} rightIcon={<FiFilter />}></MenuButton>
                <MenuList>
                  <div className="w-full">
                    <div className="flex justify-between items-center lg:px-6 px-4 bdr-bottom-e9e9ed">
                      <h2
                        id="1632304012"
                        className="dynamicStyle fsize16 font-semibold cust-textColor py-3"
                      >
                        {data ? data['1632304012'] : 'Filter'}
                      </h2>
                      <div className="pl-4 py-3 sm-bdr-left-f7f6f6">
                        <FeatherIcon
                          icon="refresh-ccw"
                          className="cust-textColor "
                          size={20}
                        />
                      </div>
                    </div>

                    <div
                      className={
                        colorMode === 'light'
                          ? 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed bg-fdf3f3 theme-bg'
                          : 'py-4 lg:px-6 px-4 bdr-bottom-e9e9ed bg-fdf3f3 cust-bgColor theme-bg'
                      }
                    >
                      <h2
                        id="4677201159"
                        className="dynamicStyle fsize16 font-semibold cust-textColor"
                      >
                        {data ? data['4677201159'] : 'Category'}
                      </h2>
                      <div className="grid grid-cols-1 gap-2 mt-3">
                        {AllCategoryData &&
                        AllCategoryData.result &&
                        AllCategoryData.result.length > 0
                          ? AllCategoryData.result.map(cat => {
                              return (
                                <div className="flex items-center gap-3 textColor-light text-black">
                                  <Checkbox size="lg">{cat.name}</Checkbox>
                                </div>
                              );
                            })
                          : 'no-category'}

                        {/* <div className="flex items-center gap-3">
                  <Checkbox>Option 2</Checkbox>
                </div>
                <div className="flex items-center gap-3">
                  <Checkbox>Option 3</Checkbox>
                </div> */}
                      </div>
                    </div>
                  </div>
                </MenuList>
              </Menu>
            </div>
            <div className="lg:hidden md:hidden block">
              <p className="font-semibold fsize18 sm-fsize16 text-secondary cust-textColor">
                Listed Product : 200
              </p>
            </div>
          </div>
          <div className="lg:w-3/4 md:w-3/4">
            <div className="flex justify-end items-center mob-hidden">
              <p className="font-semibold fsize18 sm-fsize16 text-secondary py-3 cust-textColor">
                Listed Product : 200
              </p>
            </div>
            <div className="">
              <div className="mt-4">
                <p className="fsize24 sm-fsize20 font-semibold cust-textColor">
                  Car Cleaning
                </p>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 my-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <p className="fsize24 sm-fsize20 font-semibold cust-textColor">
                  Car Cleaning
                </p>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 my-6">
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      colorMode === 'light'
                        ? 'flex gap-3 rounded-md cust-bgf5f5f5 p-3 themeShadow'
                        : 'flex gap-3 rounded-md cust-bgColor p-3 themeShadow'
                    }
                  >
                    <div className="w-1/4">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="w-20 h-20 rounded-md"
                      />
                    </div>
                    <div className="w-3/4">
                      <div className="flex justify-between mb-2">
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          Lorem
                        </p>
                        <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                          ₹500
                        </p>
                      </div>
                      <div className="pl-5">
                        <ul>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor sit amet
                          </li>
                          <li className="text-gray fsize14 sm-fsize12 textColor-light">
                            Lorem ipsum dolor
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="font-semibold text-secondary fsize14 sm-fsize12 cursor-pointer cust-textColor">
                          View More
                        </p>
                        <FeatherIcon
                          icon="plus"
                          className={
                            colorMode === 'light'
                              ? 'font-semibold bg-fff text-secondary rounded-sm p-1'
                              : 'cust-textColor font-semibold cust-bgColor rounded-sm p-1'
                          }
                          size={24}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex md:flex gap-8 justify-end items-center mt-4 cust-shadow1 py-4">
          <div className="flex justify-center gap-4">
            <FeatherIcon
              icon="shopping-cart"
              className="cust-textColor "
              size={24}
            />
            <p className="sm-fsize14 cust-textColor">5 Added</p>
          </div>
          <div className="text-center lg:my-0 md:my-0 my-4">
            <p className="font-semibold fsize18 sm-fsize16 text-secondary cust-textColor">
              ₹2000
            </p>
            <p className="fsize14 sm-fsize12 text-gray textColor-light">
              Estimated Cost
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              className="theme-btn b_button_primary px-6 py-2 rounded-full sm-fsize14 rounded-lg"
              onClick={onOpen}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList3;
