import React, { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { useSelector } from "react-redux";

const GallerySection13 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

   //section color start
   const [fileName, setFileName] = useState("");
   const [thisComponent, setThisComponent] = useState({});
   const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
   const selectedSection = useSelector((state) => state.cms.sectionNames);
   //sectioncolor end
   //sectioncolor start
   useEffect(() => {
     const name = new URL(import.meta.url);
     const filename = name.pathname.split("/").pop();
     console.log(filename);
     const componentName = filename.split(".")[0];
     setFileName(componentName);
 
     for (let i = 0; i < selectedSection[pageName].length; i++) {
       if (
         selectedSection[pageName][i].path.includes(componentName.split(".")[0])
       ) {
         setThisComponent(selectedSection[pageName][i]);
       }
     }
   }, [selectedSection]);
   //sectioncolor end

  return (
    <section className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:w-2/3 md:w-3/4 w-full mx-auto text-center">
          <h2
            id="6425311576"
            className="dynamicStyle section_detail_0 text-primary"
          >
            {data ? data["6425311576"] : "Collections"}
          </h2>
          <h4 id="6823883269" className="section_header dynamicStyle">
            {data ? data["6823883269"] : "Collections"}
          </h4>
        </div>
        <div
          id="2017369988"
          className="lg:mt-16 md:mt-16 mt-12 prime_card_container dynamicStyle"
        >
          <Swiper
            spaceBetween={15}
            slidesPerView={3}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper"
            autoplay={{
              delay: "1000",
            }}
            loop
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 2.8,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2.4,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data["2017369988"]
              ? data["2017369988"].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className="relative prime_card_0 card lg:mx-2 md:mx-2"
                        key={index}
                      >
                        <div className="relative gradient h-96 overflow-hidden rounded-lg">
                          <Image
                            src={e ? getImage(e.field3) : ""}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="gallery"
                            imageClassName="object-cover h-96 rounded-lg"
                          />
                          <div className="absolute z-20 bottom-0 left-0 p-4">
                            <h5 className="card_title clr-fff">{e.field1}</h5>
                            <div className="flex items-center gap-2 mt-1">
                              <div>
                                <Image
                                  src={e ? getImage(e.field4) : ""}
                                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                  alt="gallery"
                                  imageClassName="w-4 h-4 rounded-full"
                                />
                              </div>
                              <p className="clr-fff card_description">
                                {e.field2}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : "no-card"}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default GallerySection13;
