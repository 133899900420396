import { useSelector } from 'react-redux/es/exports';
import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

const AboutSection45 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-5 md:pr-2">
            <h5 id="1446299267" className="dynamicStyle about_title">
              {data ? data['1446299267'] : 'no-data'}
            </h5>
            <p id="1735953414" className="dynamicStyle card_description">
              {data ? data['1735953414'] : 'no-data'}
            </p>
            <div
              className="mt-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-4 md:gap-4 gap-4 dynamicStyle"
              id="7903609975"
            >
              {data && data['7903609975']
                ? data['7903609975'].map(e => {
                    return (
                      <Card className="prime_card text-center">
                        <h6 className="about_title ">{e.field1}</h6>
                        <p className="mt-2 card_title">{e.field2}</p>
                      </Card>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 flex lg:pl-5 md:pl-2 w-full lg:mt-0 md:mt-0 mt-10">
            <div id="7497822953" className="dynamicStyle2">
              <Image
                imageClassName="h-580px md-h-450px w-full rounded-lg object-cover"
                src={data ? getImage(data['7497822953']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img-about"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection45;
