import React from 'react';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider';
import { useSelector } from 'react-redux/es/exports';

const TransformSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
      <p
        id="8241725692"
        className="dynamicStyle lg:w-2/3 ms:w-3/4 w-full mx-auto fsize34 md-fsize24 sm-fsize20 font-semibold text-center lg:mb-12 md:mb-12 mb-6 text-primary cust-textColor"
      >
        {data ? data['8241725692'] : 'Portfolio'}
      </p>

      <div className="relative">
        <ReactCompareSlider
          className="h-500px sm-h-350px"
          itemOne={
            <ReactCompareSliderImage
              className="abcd"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="image"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              className="xyz"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              alt="image"
            />
          }
        />

        <div className="absl-transform md-absl-transform sm-absl-transform text-center text-white">
          <p id="4183819579"
        className="dynamicStyle fsize22 sm-fsize16 font-semibold pb-2">{data ? data['4183819579'] : 'Project'}</p>
          <p id="7428596561"
        className="dynamicStyle fsize32 sm-fsize20 font-semibold">{data ? data['7428596561'] : 'Mono Redesign'}</p>
        </div>
      </div>
    </div>
  );
};

export default TransformSection1;
