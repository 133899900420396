import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection22 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <div className="herosection22-img lg:relative md:relative " style={{ background: thisComponent.sectionColor }}>
      <div id="1255513914" className="w-full dynamicStyle2 ">
        <Image
          src={data ? getImage(data["1255513914"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          imageClassName="herosection22-img object-cover lg:block md:block hidden"
        />
      </div>
      <div className="lg:absolute md:absolute top-0 left-0 w-full flex items-center herosection22-img ">
        <div className="lg:px-20 md:px-8 px-5 lg:py-0 md:py-0 py-8">
          <div className="lg:flex md:flex items-center prime_container">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <h3 id="4367369696" className="hero_title dynamicStyle">
                {data ? data["4367369696"] : "EDUCATE"}
              </h3>
              <ReactQuill
                id="0342978196"
                theme="bubble"
                readOnly
                className="bubble dynamicStyle card_description"
                value={data ? data["0342978196"] : "Lorem Ipsum"}
              />

              <div className="lg:mt-8 md:mt-6 mt-4">
                <Button
                  id="0396505882"
                  className="dynamicStyle sm-fsize13 theme-btn b_button_primary px-6 py-3 rounded-lg"
                >
                  <span onClick={() => handleClick("0396505882")}>
                    {data ? data["0396505882"] : "Lorem ipsum"}
                  </span>
                  <FeatherIcon icon="arrow-right" size="18" className=" ml-1" />
                </Button>
              </div>
            </div>
            <div
              ref={elementRef}
              className={`fade-right lg:w-1/2 md:w-1/2 w-full lg:pl-10 md:pl-5 pl-0 lg:mt-0 md:mt-0 mt-6 ${
                isVisible ? "visibleright" : ""
              } `}
            >
              <div id="9226713447" className="w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data["9226713447"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="hero"
                  imageClassName="heroside-img "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection22;
