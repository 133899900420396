import React, { useState } from 'react';
import { Image, Input, useDisclosure } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react';
import { Radio, RadioGroup, Select } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { FiCheck, FiEye, FiLock, FiUsers } from 'react-icons/fi';

const Assets = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState('1');
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Add Folder</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div className="py-3 px-1">
              <div className="">
                <label className="text-primary">Folder Name</label>
                <Input placeholder="Enter" className="mt-2" />
              </div>
              <div className="mt-3">
                <RadioGroup onChange={setValue} value={value}>
                  <Stack direction="row">
                    <Radio value="1">Public</Radio>
                    <Radio value="2">Private</Radio>
                  </Stack>
                </RadioGroup>
                {value === '2' ? (
                  <>
                    <div className="mt-2">
                      <label className="text-primary">Select Department</label>
                      <Select placeholder="Select option" className="mt-2">
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </Select>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="flex items-center gap-3 mt-5">
                <Button className="w-full b_button_primary px-6 py-2">
                  Submit
                </Button>
                <Button className="w-full b_button_secondary px-6 py-2">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="lg:px-12 md:px-8 px-5 lg:py-10 py-8 container mx-auto">
        <div className="w-full">
          <div className="lg:flex md:flex items-baseline gap-2">
            <h2 className="fsize28 sm-fsize20 text-primary">Assets</h2>
            <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
          </div>
          <div className="lg:mt-12 md:mt-8 mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-6 gap-4">
            <div className="borderall rounded-lg py-3 px-4">
              <div className="w-full relative">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-1/2 mx-auto h-32 object-contain"
                />
                <div className="absolute top-right-8px p-2 m-1 rounded-full bg-primary flex justify-center items-center">
                  <FiCheck className="textwhite" />
                </div>
                <div className="absolute top-0 left-0 ">
                  <p className="text-primary fsize14">v-1.0.0</p>
                </div>
              </div>
              <div className="mt-3">
                <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                  Employe code of conduct
                </h2>
                <p className="text-gray fsize14 sm-fsize12">
                  Microsoft word file
                </p>
                <div className="mt-3 flex gap-2 items-center justify-center w-full">
                  <div className="w-full">
                  <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
                  </div>
                  <div className="w-full">
                  <Button label="Preview" icon="pi pi-eye" className='b_button_secondary fsize14 px-6 py-2 w-full' />
                  </div>
                </div>
              </div>
            </div>
            <div className="borderall rounded-lg py-3 px-4">
              <div className="w-full relative">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-1/2 mx-auto h-32 object-contain"
                />
                <div className="absolute top-right-8px p-2 m-1 rounded-full bg-primary flex justify-center items-center">
                  <FiCheck className="textwhite" />
                </div>
                <div className="absolute top-0 left-0 ">
                  <p className="text-primary fsize14">v-1.0.0</p>
                </div>
              </div>
              <div className="mt-3">
                <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                  Employe code of conduct
                </h2>
                <p className="text-gray fsize14 sm-fsize12">
                  Microsoft word file
                </p>
                <div className="mt-3 flex gap-2 items-center justify-center w-full">
                  <div className="w-full">
                  <Button label="Download" icon="pi pi-download" className='b_button_primary fsize14 px-6 py-2 w-full' />
                  </div>
                  <div className="w-full">
                  <Button label="Preview" icon="pi pi-eye" className='b_button_secondary fsize14 px-6 py-2 w-full' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
