import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Image } from "primereact/image";

const AboutSection10 = ({ pageName }) => {
    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
    }, [selectedSection]);
    //sectioncolor end
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="lg:relative lg:h-100vh" style={{ background: thisComponent.sectionColor }}>
      <div id="6329253597" className="dynamicStyle2 cus-hbanner w-full">
        <Image
          src={data ? getImage(data["6329253597"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          imageClassName="h-100vh lg:block hidden w-full object-cover"
          alt="nurtings"
        />
      </div>

      <div className="lg:absolute top-0 left-0 w-full lg:h-100vh flex items-center">
        <div className="lg:px-20 md:px-8 px-5 lg:py-16 md:py-12 py-8">
          <div className="prime_container">
            <div className="flex cust-reverse items-center">
              <div className="lg:w-6/12 md:w-6/12 w-full lg:mt-0 mt-4 lg:pr-10">
                <small
                  className="fsize16 sm-fsize14 text-primary font-medium dynamicStyle"
                  id="5997002658"
                >
                  {data ? data["5997002658"] : "Lorem Ipsum"}
                </small>
                <h2 className="about_title dynamicStyle" id="0288510504">
                  {data ? data["0288510504"] : "Lorem Ipsum"}
                </h2>
                <ReactQuill
                  id="0473913753"
                  theme="bubble"
                  readOnly
                  className="card_description bubble dynamicStyle"
                  value={data ? data["0473913753"] : "Lorem Ipsum"}
                />
              </div>
              <div className="lg:w-6/12 md:w-6/12 w-full">
                <div
                  ref={elementRef}
                  className={`fade-right cus-hbanner w-full ${
                    isVisible ? "visibleright" : ""
                  } `}
                >
                  <div id="2181540252" className="dynamicStyle2">
                    <Image
                      src={data ? getImage(data["2181540252"]) : ""}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      imageClassName="map-img object-cover"
                      alt="map"
                      style={{ transform: transform }}
                      onMouseMove={handleMouseMove}
                      onMouseLeave={handleMouseLeave}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection10;
