import {
  Card,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorMode,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import { Button } from 'primereact/button';
import {
  CreateNewPassword,
  logout,
  ToggleAccount,
} from '../../../../redux/auth/auth-action';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ThreeDots } from 'react-loader-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

const ManageAccount = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  let userDetail = useSelector(state => state.AuthDetails.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPasswordShow, setCurrentPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [deactivatePasswordShow, setDeactivatePasswordShow] = useState(false);
  const [deletePasswordShow, setDeletePasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deactivateField, setDeactivateField] = useState(false);

  const [deactivatePassword, setDeactivatePassword] = useState('');
  const [deletePassword, setDeletePassword] = useState('');

  const handleCurrentPasswordShow = () =>
    setCurrentPasswordShow(state => !state);
  const handlePasswordShow = () => setPasswordShow(state => !state);
  const handleConfirmPasswordShow = () =>
    setConfirmPasswordShow(state => !state);
  const handleDeactivatePasswordShow = () =>
    setDeactivatePasswordShow(state => !state);
  const handleDeletePasswordShow = () => setDeletePasswordShow(state => !state);

  const initialValues = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  };

  const validationRules = Yup.object({
    currentPassword: Yup.string()
      // .min(8, "Current password must be at least 8 characters")
      .required('Current password is required'),
    password: Yup.string()
      .min(8, 'New password must be at least 8 characters')
      .required('New password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm password must match'),
  });

  const handleOnSubmit = async values => {
    let payload = {
      email: userDetail.email.toLowerCase(),
      password: values.currentPassword,
      newPassword: values.password,
    };
    try {
      setIsLoading(true);
      let data = await dispatch(CreateNewPassword(payload));
      console.log(data);
      if (data && data.code == 200) {
        await Swal.fire({
          title: 'Password reset successfully',
          text: `Please Login again`,
          icon: 'success',
          timer: '2500',
        });
        const data = await dispatch(
          logout({ email: userDetail.email.toLowerCase() })
        );
        formik.handleReset();
      } else {
        console.log(data);
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeactivateAccount = async () => {
    if (deactivatePassword !== '') {
      Swal.fire({
        // title: "warning",
        text: 'Are you sure, you want to deactivate your account?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2bc370',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          if (!deactivatePassword) {
            return;
          }
          let payload = {
            email: userDetail.email.toLowerCase(),
            password: deactivatePassword,
          };
          try {
            let data = await dispatch(ToggleAccount(payload));
            console.log(data);
            if (data && data.code == 200) {
              await Swal.fire({
                title: 'Success',
                text: 'Account deactivated successfully',
                icon: 'success',
                timer: '2500',
              });
              formik.handleReset();
              navigate('/login');
            } else {
              if (data.message) {
                Swal.fire({
                  title: 'Error',
                  text: data.message,
                  icon: 'error',
                });
              }
            }
          } catch (error) {
            Swal.fire({
              title: 'Error',
              text: 'Something went wrong!',
              icon: 'error',
            });
          }
        }
      });
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:p-6 md:p-6 border-1px rounded-3px">
      <div className="bottom-border-ebebeb">
        <h2 className="fsize20 font-semibold pb-4 clr-606060 cust-textColor">
          Manage Account
        </h2>
      </div>
      <div className="w-full lg:flex md:flex gap-4 py-6">
        <Card
          className={
            colorMode === 'light'
              ? 'lg:w-full border rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4'
              : 'lg:w-full rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4'
          }
        >
          <h2 className="fsize16 font-semibold cust-textColor">
            Deactivate account
          </h2>
          <p class="fsize12 clr-848484 my-1 textColor-light">
            Account Deactivation will not delete your account it will hide your
            profile from public domain until you will not activate it yourself.
          </p>
          <div className="mt-4">
            {deactivateField ? (
              <>
                <InputGroup>
                  <Input
                    autoComplete={false}
                    name="deactivatePassword"
                    value={deactivatePassword}
                    onChange={e => setDeactivatePassword(e.target.value)}
                    onBlur={e => setDeactivatePassword(e.target.value.trim())}
                    type={deactivatePasswordShow ? 'text' : 'password'}
                    placeholder="Enter your password"
                    className="mb-2"
                  />
                  <InputRightElement paddingTop="0">
                    <div
                      onClick={() => {
                        handleDeactivatePasswordShow();
                      }}
                    >
                      {deactivatePasswordShow ? (
                        <FeatherIcon icon="eye" size={16} />
                      ) : (
                        <FeatherIcon size={16} icon="eye-off" />
                      )}
                    </div>
                  </InputRightElement>
                </InputGroup>
                <div className="flex gap-2 justify-end">
                  {/* <p
                    className="text-primary cursor-pointer fsize12 font-semibold"
                    onClick={() => handleDeactivateAccount()}
                  >
                    Submit
                  </p> */}
                  <Button
                    outlined
                    className="b_button_secondary px-6 py-2 hoverr cursor-pointer fsize12 font-semibold"
                    onClick={() => setDeactivateField(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleDeactivateAccount()}
                    className={
                      deactivatePassword !== ''
                        ? 'b_button_primary px-6 py-2 border-primary cursor-pointer fsize12 font-semibold'
                        : 'b_button_primary px-6 py-2 border-primary cursor-nodrop fsize12 font-semibold'
                    }
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <p
                className="text-primary cursor-pointer fsize12 font-semibold"
                // onClick={() => handleDeactivateAccount()}
                onClick={() => setDeactivateField(true)}
              >
                Deactivate
              </p>
            )}
          </div>
        </Card>

        {/* <Card
          className={
            colorMode === "light"
              ? "lg:w-full border rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4"
              : "lg:w-full rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4"
          }
        >
          <h2 className="fsize16 font-semibold cust-textColor">
            Delete account
          </h2>
          <p class="fsize12 clr-848484 my-1 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
            ratione perspiciatis eveniet itaque eos voluptates nihil reiciendis
            quod alias velit!
          </p>
          <div className="mt-4">
            <p className="text-primary fsize12 font-semibold cust-textColor">
              Delete
            </p>
          </div>
        </Card> */}
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="bottom-border-ebebeb">
          <h2 className="fsize20 font-semibold pb-4 clr-606060 cust-textColor">
            Manage Password
          </h2>
        </div>
        <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 py-6">
          <FormControl
            isInvalid={
              !!formik.errors.currentPassword && formik.touched.currentPassword
            }
          >
            <FormLabel className="fsize14 font-medium text-black cust-textColor">
              Enter Current Password
            </FormLabel>
            <InputGroup>
              <Input
                autoComplete={false}
                name="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type={currentPasswordShow ? 'text' : 'password'}
                placeholder="Enter your current password"
                className={
                  colorMode === 'light'
                    ? 'bg-fff fsize14'
                    : 'cust-textColor fsize14'
                }
              />
              <InputRightElement paddingTop="0">
                <div
                  onClick={() => {
                    handleCurrentPasswordShow();
                  }}
                >
                  {currentPasswordShow ? (
                    <FeatherIcon icon="eye" size={16} />
                  ) : (
                    <FeatherIcon size={16} icon="eye-off" />
                  )}
                </div>
              </InputRightElement>
            </InputGroup>
            {formik.touched.currentPassword &&
              formik.errors.currentPassword && (
                <FormErrorMessage>
                  {formik.errors.currentPassword}
                </FormErrorMessage>
              )}
          </FormControl>
          <FormControl
            isInvalid={!!formik.errors.password && formik.touched.password}
          >
            <FormLabel className="fsize14 font-medium text-black cust-textColor">
              Enter New Password
            </FormLabel>
            <InputGroup>
              <Input
                autoComplete={false}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                placeholder="Enter your new password"
                type={passwordShow ? 'text' : 'password'}
                className={
                  colorMode === 'light'
                    ? 'bg-fff fsize14'
                    : 'cust-textColor fsize14'
                }
              />
              <InputRightElement paddingTop="0">
                <div
                  onClick={() => {
                    handlePasswordShow();
                  }}
                >
                  {passwordShow ? (
                    <FeatherIcon icon="eye" size={16} />
                  ) : (
                    <FeatherIcon size={16} icon="eye-off" />
                  )}
                </div>
              </InputRightElement>
            </InputGroup>
            {formik.touched.password && formik.errors.password && (
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isInvalid={
              !!formik.errors.confirmPassword && formik.touched.confirmPassword
            }
          >
            <FormLabel className="fsize14 font-medium text-black cust-textColor">
              Confirm New Password
            </FormLabel>
            <InputGroup>
              <Input
                autoComplete={false}
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type={confirmPasswordShow ? 'text' : 'password'}
                placeholder="Enter your new password again"
                className={
                  colorMode === 'light'
                    ? 'bg-fff fsize14'
                    : 'cust-textColor fsize14'
                }
              />
              <InputRightElement paddingTop="0">
                <div
                  onClick={() => {
                    handleConfirmPasswordShow();
                  }}
                >
                  {confirmPasswordShow ? (
                    <FeatherIcon icon="eye" size={16} />
                  ) : (
                    <FeatherIcon size={16} icon="eye-off" />
                  )}
                </div>
              </InputRightElement>
            </InputGroup>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <FormErrorMessage>
                  {formik.errors.confirmPassword}
                </FormErrorMessage>
              )}
          </FormControl>
          <div className="flex items-center lg:pt-4 md:pt-4">
            <p className="fsize14 md-fsize12 sm-fsize12 cust-textColor fweight-500 mr-1">
              forgot your password?
            </p>
            <span className="text-primary textColor-light font-semibold fsize14 cursor-pointer">
              <Link to="/forgot password">Click here</Link>
            </span>
          </div>
        </div>
        <div className="lg:mt-4 md:mt-4 mt-1">
          <Button
            spinner={<ThreeDots visible={true} color="white" radius={3} />}
            isLoading={isLoading}
            type="submit"
            className='sm-update-button fsize16 b_button_primary px-6 py-2'
               
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ManageAccount;
