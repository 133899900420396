import { useSelector } from "react-redux/es/exports";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import { getData } from '../../../redux/forms/forms-action';
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureDetailCard1 = ({ pageName }) => {
  const elementRef1 = useRef(null);
  const elementRef2 = useRef(null);

  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);

  // const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }
  function errorImage(e) {
    e.target.src = `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
  }
  const getCardDetails = async () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
      paginated: true,
    };
    if (search) Object.assign(payload, { searchBy: search });
    // let data = await dispatch(getData("startup_registration", payload));
    // console.log(data, 'cards');
    setCards(data.result);
  };

  // const searchBy = (e) => {
  //   console.log(e);
  // };

  useEffect(() => {
    getCardDetails();
    const cleanp1 = handleScroll(elementRef1, setIsVisible1);
    const cleanp2 = handleScroll(elementRef2, setIsVisible2);

    return () => {
      cleanp1();
      cleanp2();
    };
  }, []);

  const menuRight = useRef(null);
  const toast = useRef(null);
  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Refresh",
        },
        {
          label: "Export",
        },
      ],
    },
  ];

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end

  return (
    <section>
      <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
        <div className="prime_container">
          <div className="lg:flex md:flex lg:gap-8 md:gap-8 gap-4 mb-6">
            <div className="p-inputgroup flex-1">
              <InputText placeholder="Search" className="p-2 bg-fff" />
              <Button icon="pi pi-search" className="bg-primary" />
            </div>
            {/* <Menu>
              <MenuButton
                className="flex gap-2 menu_width180px md-fsize14 sm-fsize13 sm-px-10px"
                as={Button}
                rightIcon={<FiFilter />}
              >
                Filter & Sort
              </MenuButton>
              <MenuList>
                <MenuItem>Live Deal</MenuItem>
                <MenuItem>Closed Deal</MenuItem>
              </MenuList>
            </Menu> */}
            <div className="lg:mt-0 md:mt-0 mt-4 flex justify-end">
              <Toast ref={toast}></Toast>
              <Menu
                model={items}
                popup
                ref={menuRight}
                id="popup_menu_right"
                popupAlignment="right"
              />
              <Button
                label="Filter & Sort"
                icon="pi pi-filter"
                className="b_button_primary sm-fsize14 px-4"
                onClick={(event1) => menuRight.current.toggle(event1)}
                aria-controls="popup_menu_right"
              />
            </div>
            {/* <Menu>
          <MenuButton
            className="c-flex gap-2 menu_width180px"
            as={Button}
            rightIcon={<FiFilter />}
          >
            <span>Filters & Sort</span>
          </MenuButton>
          <MenuList>
            <div className="py-2 px-4 ">
              <p className="fsize14 mb-2">Filter by Live Deal</p>
              <p className="fsize14 mb-2">Filter by Closed Deal</p>
            </div>
          </MenuList>
        </Menu> */}
          </div>

          <TabView className="tab-p tab-p1">
            <TabPanel header="Live Deal">
              <div
                ref={elementRef1}
                className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 gap-10 mt-8 ${
                  isVisible1 ? "visiblebottom" : ""
                } `}
              >
                <Card className="prime_card">
                  <div className="mb-6 flex justify-center">
                    <Image
                      imageClassName="object-cover lg:h-24 lg:w-24 w-20 h-20"
                      // src={getImage(card['company logo'])}
                      src={data ? getImage(data[""]) : ""}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="Img_Preview"
                      onError={(event) => errorImage(event)}
                    />
                  </div>
                  <div className="flex justify-evenly items-center ">
                    <p className="card_title">
                      {/* {card['company name']} */}Company Name
                    </p>
                    {/* <p className="text-gray cust-textColor">{card["typeOfFunding"]}</p> */}
                  </div>
                  <div className="text-center">
                    <p className="card_description">
                      {/* {card['companydescription']} */}company Description
                    </p>
                  </div>
                  <div className="flex justify-center gap-8 mt-6">
                    <div className="text-center">
                      <p className="fsize14 text-gray cust-textColor mb-1">
                        Stage
                      </p>
                      {/* <p className="font-medium  cust-textColor">{card['stageOfStartup']}</p> */}
                      <p className="font-medium  cust-textColor">Test</p>
                    </div>
                    <div className="text-center">
                      <p className="fsize14 text-gray cust-textColor mb-1">
                        Round Size
                      </p>
                      {/* <p className="font-medium  cust-textColor">{card['fundingamount']}</p> */}
                      <p className="font-medium  cust-textColor">45</p>
                    </div>
                    <div className="text-center">
                      <p className="fsize14 text-gray cust-textColor mb-1">
                        Team
                      </p>
                      <AvatarGroup>
                        <Avatar
                          image="/images/avatar/amyelsner.png"
                          shape="circle"
                        />
                        <Avatar
                          image="/images/avatar/asiyajavayant.png"
                          shape="circle"
                        />

                        <Avatar label="+2" shape="circle" />
                      </AvatarGroup>
                    </div>
                  </div>
                  <div className="pt-6 flex justify-center gap-8">
                    <Button
                      className="fsize14 text-primary outline-btn px-6 py-2"
                      outlined
                    >
                      Automobile
                    </Button>
                    <Button
                      className="fsize14 text-primary outline-btn px-6 py-2"
                      outlined
                    >
                      E-commerce
                    </Button>
                  </div>
                </Card>
              </div>
            </TabPanel>
            <TabPanel header="Closed Deal">
              <div
                ref={elementRef1}
                className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 gap-10 mt-8 ${
                  isVisible1 ? "visiblebottom" : ""
                } `}
              >
                <Card className="prime_card2">
                  <div className="h-24 mb-6 flex justify-center">
                    <Image
                      imageClassName="object-cover"
                      // src={getImage(card['company logo'])}
                      src={data ? getImage(data[""]) : ""}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="img"
                      onError={(event) => errorImage(event)}
                    />
                  </div>
                  <div className="flex justify-evenly items-center ">
                    <p className="card_title">
                      {/* {card['company name']} */}Company Name
                    </p>
                    {/* <p className="text-gray cust-textColor">{card["typeOfFunding"]}</p> */}
                  </div>
                  <div className="text-center">
                    <p className="card_description">
                      {/* {card['companydescription']} */}company Description
                    </p>
                  </div>
                  <div className="flex justify-center gap-8 mt-6">
                    <div className="text-center">
                      <p className="fsize14 text-gray cust-textColor mb-1">
                        Stage
                      </p>
                      {/* <p className="font-medium  cust-textColor">{card['stageOfStartup']}</p> */}
                      <p className="font-medium  cust-textColor">Test</p>
                    </div>
                    <div className="text-center">
                      <p className="fsize14 text-gray cust-textColor mb-1">
                        Round Size
                      </p>
                      {/* <p className="font-medium  cust-textColor">{card['fundingamount']}</p> */}
                      <p className="font-medium  cust-textColor">45</p>
                    </div>
                    <div className="text-center">
                      <p className="fsize14 text-gray cust-textColor mb-1">
                        Team
                      </p>
                      <AvatarGroup>
                        <Avatar
                          image="/images/avatar/amyelsner.png"
                          shape="circle"
                        />
                        <Avatar
                          image="/images/avatar/asiyajavayant.png"
                          shape="circle"
                        />

                        <Avatar label="+2" shape="circle" />
                      </AvatarGroup>
                    </div>
                  </div>
                  <div className="pt-6 flex justify-center gap-8">
                    <Button
                      className="fsize14 text-primary outline-btn px-6 py-2"
                      outlined
                    >
                      Automobile
                    </Button>
                    <Button
                      className="fsize14 text-primary outline-btn px-6 py-2"
                      outlined
                    >
                      E-commerce
                    </Button>
                  </div>
                </Card>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </section>
  );
};

export default FeatureDetailCard1;
