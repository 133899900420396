import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import FeatherIcon from "feather-icons-react";
import { Image } from "primereact/image";
import Aos from "aos";
import "aos/dist/aos.css";

const FeatureCard64 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

    //section color start
    const [fileName, setFileName] = useState("");
    const [thisComponent, setThisComponent] = useState({});
    const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
    const selectedSection = useSelector((state) => state.cms.sectionNames);
    //sectioncolor end
    //sectioncolor start
    useEffect(() => {
      const name = new URL(import.meta.url);
      const filename = name.pathname.split("/").pop();
      console.log(filename);
      const componentName = filename.split(".")[0];
      setFileName(componentName);
  
      for (let i = 0; i < selectedSection[pageName].length; i++) {
        if (
          selectedSection[pageName][i].path.includes(componentName.split(".")[0])
        ) {
          setThisComponent(selectedSection[pageName][i]);
        }
      }
      Aos.init();
    }, [selectedSection]);
    //sectioncolor end
    
  return (
    <div className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12" style={{ background: thisComponent.sectionColor }}>
      <div className="prime_container">
        <div className="">
          <p
            id="7895583335"
            className="dynamicStyle lg:w-1/2 md:w-1/2 section_header"
          >
            {data ? data["7895583335"] : "Lorem ipsum"}
          </p>
          <ReactQuill
            id="9057491381"
            theme="bubble"
            readOnly
            className="dynamicStyle lg:w-1/2 md:w-3/4 border-left-1px pl-4 bubble section_detail"
            value={data ? data["9057491381"] : "no-data"}
          />
        </div>
        <div id="8034651356" className="dynamicStyle lg:mt-16 md:mt-16 mt-12">
          <Swiper
            spaceBetween={30}
            className="mySwiper container relative overflow-x-hidden"
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev pl-2 cursor-pointer cust-textColor">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next pr-2 cursor-pointer cust-textColor">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data["8034651356"]
              ? data["8034651356"].map((e) => {
                  return (
                    <SwiperSlide>
                      <div className="flex">
                        <div className="p-4 bg_surface100 rounded-l-xl w-7/12 theme_insertShadow">
                          <p className="card_title">{e.field1}</p>
                          <div className="flex justify-between items-center mt-10">
                            <div className="flex gap-2">
                              <FeatherIcon
                                icon="star"
                                size={14}
                                className="cursor-pointer theme-textColor"
                              />
                              <FeatherIcon
                                icon="star"
                                size={14}
                                className="cursor-pointer theme-textColor"
                              />
                              <FeatherIcon
                                icon="star"
                                size={14}
                                className="cursor-pointer theme-textColor"
                              />
                              <FeatherIcon
                                icon="star"
                                size={14}
                                className="cursor-pointer theme-textColor"
                              />
                              <FeatherIcon
                                icon="star"
                                size={14}
                                className="cursor-pointer theme-textColor"
                              />
                            </div>
                            <FeatherIcon
                              icon="bookmark"
                              size={20}
                              className="cursor-pointer theme-textColor"
                            />
                          </div>
                        </div>
                        <div className="w-5/12">
                          <Image
                            src={e ? getImage(e.field2) : ""}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                            imageClassName="w-full rounded-r-xl object-cover"
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : "No Data"}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard64;
