import React from "react";
import { useSelector } from "react-redux";
import { Image, useColorMode } from "@chakra-ui/react";

import { Link, useNavigate } from "react-router-dom";
const Footer1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);
  const navigate = useNavigate();
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  const isDarkMode = useSelector((state) => state.Loader.DarkMode);
  // if (window.location.pathname === "/login") return null;
  // if (window.location.pathname === "/register") return null;
  // if (window.location.pathname === "/forgot password") return null;
  return (
    <section className={isDarkMode ? "bg_surface100" : "bg-secondary"}>
      <div className=" lg:px-12 md:px-12 px-4 lg:py-10 py-8">
        <div className="lg:flex w-full items-center">
          <div
            id=""
            className="w-5/12 lg:block md:flex justify-center sm-mx-auto"
          >
            <Image
              src={getSecondaryImage(brandProfile)}
              className="w-10rem h-20 object-contain"
              alt="footer-logo"
            />
          </div>
          <div className="lg:flex grid md:grid-cols-3 grid-cols-2 gap-2 lg:mt-0 mt-4 justify-between items-center lg:w-9/12 md:w-full w-full">
            <p
              className="cursor-pointer fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2"
            >
              <Link to="/home">Home</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
            <Link to="/about">About Us</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/contact us">Contact Us</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/user-agreement">User Agreement</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            <p className="fsize16 theme-textColor text-white sm-fsize14 lg:py-4 md:py-4 py-2 cursor-pointer">
              {" "}
              <Link to="/terms-conditions"> Terms & Conditions</Link>
            </p>
          </div>
        </div>
        <div className="lg:flex w-full justify-between md:mt-4 mt-8 lg:mt-12 items-center">
          {/* <div className="flex items-center gap-3">
            <div id="6074700231" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data["6074700231"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 object-contain"
                alt="footer-logo"
              />
            </div>
            <div id="0979181830" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data["0979181830"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 object-contain"
                alt="footer-logo"
              />
            </div>
            <div id="9496329671" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data["9496329671"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 object-contain"
                alt="footer-logo"
              />
            </div>
            <div id="4171537432" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data["4171537432"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4 object-contain"
                alt="footer-logo"
              />
            </div>
          </div> */}
          <div className="lg:mt-0  md:lg-mt-0 mt-4">
            {project.poweredBy ? (
              <p className="fsize14 text-white sm-fsize14 ">
                powered by BRAINED
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer1;
