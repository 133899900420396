import React, { useEffect, useState } from "react";
import { Image } from "primereact/image";
import { useSelector } from "react-redux/es/exports";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Team13 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:flex md:flex sm-reverse lg:gap-12 md:gap-8 gap-6">
          <div
            id="7468639871"
            className="dynamicStyle lg:w-7/12 md:w-7/12 w-full grid lg:grid-cols-3 items-start md:grid-cols-2 grid-cols-1 gap-8"
          >
            {data && data["7468639871"]
              ? data["7468639871"].map((e) => {
                  return (
                    <div className="px-3 py-5 text-center bg-light-primary theme-bg themeShadow rounded-lg">
                      <div className="lg:mb-4 md:mb-3 mb-2">
                        <Image
                          src={e ? getImage(e.field1) : ""}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt={e.field2}
                          imageClassName="lg:w-24 lg:h-24 md:w-24 object-contain md:h-24 w-20 h-20 mx-auto"
                        />
                      </div>
                      <h4 className="card_title_0 text-center">{e.field2}</h4>
                      <p className="card_description mt-1 text-center">
                        {e.field3}
                      </p>
                    </div>
                  );
                })
              : "No Data"}
          </div>
          <div className="lg:w-5/12 md:w-5/12 w-full">
            <h2 id="0048165676" className="dynamicStyle section_header">
              {data ? data["0048165676"] : "Team Structure"}
            </h2>
            <ReactQuill
              id="7260519633"
              theme="bubble"
              readOnly
              className="bubble dynamicStyle section_detail_0"
              value={data ? data["7260519633"] : "Lorem Ipsum"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team13;
