import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Button } from "primereact/button";
import { handleScroll } from "./fadeeffect/FadeEffect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";

const AboutSection1 = ({ pageName }) => {
  //section color start
  const [fileName, setFileName] = useState("");
  const [thisComponent, setThisComponent] = useState({});
  const sectionColor = useSelector((state) => state.cms.sectionColor[pageName]);
  const selectedSection = useSelector((state) => state.cms.sectionNames);
  //sectioncolor end
  //sectioncolor start
  useEffect(() => {
    const name = new URL(import.meta.url);
    const filename = name.pathname.split("/").pop();
    console.log(filename);
    const componentName = filename.split(".")[0];
    setFileName(componentName);

    for (let i = 0; i < selectedSection[pageName].length; i++) {
      if (
        selectedSection[pageName][i].path.includes(componentName.split(".")[0])
      ) {
        setThisComponent(selectedSection[pageName][i]);
      }
    }
  }, [selectedSection]);
  //sectioncolor end
  const elementRef1 = useRef(null);
  const elementRef2 = useRef(null);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");
      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef1, setIsVisible1);
    const cleanp2 = handleScroll(elementRef2, setIsVisible2);

    return () => {
      cleanp1();
      cleanp2();
    };
  }, []);

  return (
    <div
      className="lg:px-20 md:px-8 px-5 lg:py-20 md:py-16 py-12"
      style={{ background: thisComponent.sectionColor }}
    >
      <div className="prime_container">
        <div className="lg:flex md:flex gap-4 block lg:px-12 md:px-8 px-0">
          <div
            ref={elementRef1}
            className={`fade-left lg:w-1/3 md:w-1/3 w-full  lg:flex md:flex flex-col justify-center lg:px-4 md:px-4 px-0 ${
              isVisible1 ? "visibleleft" : ""
            } `}
          >
            <p
              id="9612360112"
              className="dynamicStyle fsize18 sm-fsize16 mb-2 text-primary cust-textColor letter-spacing4px font-semibold"
            >
              {data ? data["9612360112"] : "Lorem ipsum"}
            </p>
            <ReactQuill
              id="2158226426"
              theme="bubble"
              readOnly
              className="mt-1 fsize16 bubble mb-2 card_description dynamicStyle"
              value={data ? data["2158226426"] : "Lorem Ipsum"}
            />
          </div>

          <div
            id="2345270135"
            className="dynamicStyle2 lg:w-1/3 md:w-1/3 w-full h-680px md-h-400px sm-h-350px lg:px-0 md:px-0 px-5 lg:mt-0 md:mt-0 mt-4"
          >
            <Image
              src={data ? getImage(data["2345270135"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              imageClassName="br-40px w-full h-full object-cover bg-ddd9d8 border-6px"
            />
          </div>

          <div
            ref={elementRef2}
            className={`fade-right lg:w-1/3 md:w-1/3 w-full  lg:flex md:flex flex-col justify-center lg:px-4 md:px-4 px-6 ${
              isVisible2 ? "visibleright" : ""
            } `}
          >
            <p
              id="6527064395"
              className="cust-textColor fsize56 lheight md-fsize26 sm-fsize18 text-primary font-semibold lg:mb-4 md:mb-0 dynamicStyle"
            >
              {data ? data["6527064395"] : "Lorem ipsum generate"}
            </p>
            <ReactQuill
              id="0610906983"
              theme="bubble"
              readOnly
              className="bubble lg:mb-6 md:mb-6 mb-4 card_description dynamicStyle"
              value={data ? data["0610906983"] : "Lorem Ipsum"}
            />
            <Button
              className="dynamicStyle w-max theme-btn b_button_primary px-6 py-2 w-2102793610max"
              id="2724617783"
            >
              <span
                onClick={() => handleClick("2724617783")}
                className="inline-block"
              >
                {data ? data["2724617783"] : "Enquire Now"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection1;
